import { Disclosure } from "@headlessui/react"
import { ChevronDownIcon } from "@heroicons/react/outline"
import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { Consents, MyConsents, SubscriptionList } from "../../../Utils/Entities"
import {
  fetchApi,
  patchApi,
  postApi,
  postApiAnonymous,
} from "../../../Utils/Api.utils"
import { useAccount, useMsal } from "@azure/msal-react"
import HeaderText from "../../common/text/Header"
import { classNames } from "../../common/graphichs/misc"
import {
  InteractionRequiredAuthError,
  InteractionStatus,
} from "@azure/msal-browser"
import { loginRequest } from "../../security/authConfig"
import { useMutation, useQuery } from "react-query"
import LoadingData from "../../common/graphichs/LoadingData"
import { idnr } from "@navikt/fnrvalidator"

type FormValues = {
  id: string
  consentStatementId: string
  consentGiven: boolean
  proofOfConsent: string
  gdprRequestOrigin: number
  gdprRequestType: number
}

const Consent = props => {
  const [subList, setSubList] = useState<SubscriptionList[]>()
  // Id for "medlemsbetingelser og vedteker"
  const uneditableId = "a64a79f9-8452-ec11-8c62-000d3a4b43dd"

  const {
    register,
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormValues>()

  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})

  const SubListQuery = useQuery(
    "sublists",
    () =>
      fetchApi(
        process.env.GATSBY_API_URL + "/SubscriptionList",
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: data => {
        setSubList(data)
      },
      onError: _error => {},
    }
  )

  const mySubListsQuery = useQuery(
    "mySublist",
    () =>
      fetchApi(
        process.env.GATSBY_API_URL + "/Contacts/SubscriptionLists",
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: data => {},
      onError: _error => {},
    }
  )

  const allConsentsQuery = useQuery(
    "consents",
    () =>
      fetchApi(
        process.env.GATSBY_API_URL + "/Consents",
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: data => {},
      onError: _error => {},
    }
  )

  const myConsentsQuery = useQuery(
    "myConsents",
    () =>
      fetchApi(
        process.env.GATSBY_API_URL + "/Contacts/Consents",
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: data => {},
      onError: _error => {},
    }
  )

  const consentList: string[] = []

  myConsentsQuery?.data?.forEach(c => {
    // add consent if true
    if (c.consentStatus == true) {
      consentList.push(c.consentStatementId)
    }
  })

  let consentEdit: string[] = []
  consentList.forEach(c => {
    consentEdit.push(c)
  })

  const addListIdArray: string[] = []
  const removeListId: string[] = []

  const checkboxChanged = event => {
    if (event.target.checked) {
      consentEdit.push(event.target.id)
    } else if (!event.target.checked) {
      var index = consentEdit.indexOf(event.target.value)
      if (index !== -1) {
        consentEdit.splice(index, 1)
      }
    }
  }

  const changeMember = event => {
    if (event.target.checked) {
      if (!mySubListsQuery.data?.some(s => s.listId === event.target.id)) {
        addListIdArray.push(event.target.id)
        removeListId.splice(event.target.id)
      }
    } else {
      if (mySubListsQuery.data?.some(s => s.listId === event.target.id)) {
        removeListId.push(event.target.id)
        addListIdArray.splice(event.target.id)
      }
    }
  }

  const mutationUpdateConsents = useMutation(
    input => {
      return postApi(
        `${process.env.GATSBY_API_URL}/Consents`,
        input,
        account,
        inProgress,
        instance
      )
    },
    {
      onSuccess: data => {
        setTimeout(() => {
          window.location.reload()
        }, 3000)
      },
      onError: _error => {},
      onSettled: () => {},
    }
  )

  const mutationUpdateSubList = useMutation(
    input => {
      return patchApi(
        `${process.env.GATSBY_API_URL}/SubscriptionList`,
        input,
        account,
        inProgress,
        instance
      )
    },
    {
      onSuccess: data => {
        setTimeout(() => {
          window.location.reload()
        }, 3000)
      },
      onError: _error => {},
      onSettled: () => {},
    }
  )

  const updateConsents = async event => {
    consentEdit.sort()
    consentList.sort()
    if (consentEdit != consentList) {
    }

    let removed = consentList.filter(x => !consentEdit.includes(x))

    let added = consentEdit.filter(x => !consentList.includes(x))

    const editConsentRequest = input => {
      mutationUpdateConsents.mutate(input)
    }

    if (removed.length < 1 && added.length < 1) {
    } else {
      removed.forEach(rId => {
        const removeConsent = {
          consentStatementId: rId,
          consentGiven: false,
          // Behandle samtykke
          gdprRequestType: 778380001,
          // Portal
          gdprRequestOrigin: 778380003,
        }
        editConsentRequest(removeConsent)
      })

      added.forEach(aId => {
        const addedConsent = {
          consentStatementId: aId,
          consentGiven: true,
          // Behandle samtykke
          gdprRequestType: 778380001,
          // Portal
          gdprRequestOrigin: 778380003,
        }

        editConsentRequest(addedConsent)
      })
    }

    const editListMember = input => {
      mutationUpdateSubList.mutate(input)
    }

    let listUpdate = {}

    if (addListIdArray.length > 0 || removeListId.length > 0) {
      if (addListIdArray.length > 0 && removeListId.length > 0) {
        listUpdate = {
          AddMemberList: addListIdArray,
          RemoveMemberList: removeListId,
        }
      } else if (removeListId.length === 0) {
        listUpdate = {
          AddMemberList: addListIdArray,
        }
      } else {
        listUpdate = {
          RemoveMemberList: removeListId,
        }
      }

      editListMember(listUpdate)
    }
  }

  return allConsentsQuery.isLoading || myConsentsQuery.isLoading ? (
    <LoadingData />
  ) : (
    <div className="max-w-3xl mx-auto divide-y-2 divide-gray-200 mb-6">
      <HeaderText text="Samtykker" />
      <form
        className="mt-6
                  space-y-6
                  
                  bg-white
                  rounded-lg
                  shadow
                  px-5
                  py-6
                  sm:px-6"
        action="#"
        method="POST"
        onSubmit={handleSubmit(updateConsents)}
      >
        {allConsentsQuery.data?.map(samtykke => {
          if (samtykke?.handlingType)
            return (
              <Disclosure as="div" key={samtykke.id} className="pl-4 pt-6">
                {({ open }) => (
                  <>
                    <dt className="text-lg">
                      <Disclosure.Button
                        key={samtykke.id}
                        className="text-left w-full flex justify-between items-start text-gray-400"
                      >
                        <span className="font-medium text-gray-900">
                          {samtykke.name}
                        </span>
                        <div className="ml-auto mr-4">
                          <input
                            // {...register("id")}
                            type="checkbox"
                            id={samtykke.id}
                            name={samtykke.name}
                            value={samtykke.id}
                            // medlemsbetingelser disabled (alltid på dersom man er medlem)
                            disabled={samtykke.id === uneditableId}
                            defaultChecked={
                              consentList.indexOf(samtykke.id) > -1
                            }
                            onChange={checkboxChanged}
                            className="text-meny-600 relative justify-end inline-flex flex-shrink-0 h-6 w-11 border-2 border-gray-300 rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
                          />
                        </div>
                        <span className="ml-6 md:ml-10 h-7 flex items-center">
                          <ChevronDownIcon
                            className={classNames(
                              open ? "-rotate-180" : "rotate-0",
                              "h-6 w-6 transform"
                            )}
                            aria-hidden="true"
                          />
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <div>
                        <span className="flex-grow flex flex-col">
                          <span className="text-sm text-gray-600">
                            {samtykke.description}
                          </span>
                        </span>
                      </div>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            )
        })}

        {SubListQuery.data?.map(samtykke => {
          // if (samtykke?.handlingType)
          return (
            <Disclosure as="div" key={samtykke.id} className="pl-4 pt-6">
              {({ open }) => (
                <>
                  <dt className="text-lg">
                    <Disclosure.Button
                      key={samtykke.id}
                      className="text-left w-full flex justify-between items-start text-gray-400"
                    >
                      <span className="font-medium text-gray-900">
                        {samtykke.name}
                      </span>
                      <div className="ml-auto mr-4">
                        <input
                          // {...register("id")}
                          type="checkbox"
                          id={samtykke.id}
                          name={samtykke.name}
                          value={samtykke.id}
                          defaultChecked={mySubListsQuery.data?.some(
                            s => s.listId === samtykke.id
                          )}
                          onChange={changeMember}
                          className="text-meny-600 relative justify-end inline-flex flex-shrink-0 h-6 w-11 border-2 border-gray-300 rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
                        />
                      </div>
                      <span className="ml-6 md:ml-10 h-7 flex items-center">
                        <ChevronDownIcon
                          className={classNames(
                            open ? "-rotate-180" : "rotate-0",
                            "h-6 w-6 transform"
                          )}
                          aria-hidden="true"
                        />
                      </span>
                    </Disclosure.Button>
                  </dt>
                  <Disclosure.Panel as="dd" className="mt-2 pr-12">
                    <div>
                      <span className="flex-grow flex flex-col">
                        <span className="text-sm text-gray-600">
                          {samtykke.description}
                        </span>
                      </span>
                    </div>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          )
        })}

        <div className="flex justify-center py-4">
          <button
            type="submit"
            className="ml-1 inline-flex justify-center py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-md text-white bg-meny-700 hover:bg-meny-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-600"
          >
            Oppdater samtykker
          </button>
        </div>
      </form>
    </div>
  )
}

export default Consent
