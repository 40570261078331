import { InteractionType } from "@azure/msal-browser"
import { MsalAuthenticationTemplate } from "@azure/msal-react"
import { navigate } from "gatsby"
import React from "react"
import { Loading } from "../../../../Utils/Loading"
import { ErrorComponent } from "../../../common/graphichs/ErrorComponent"
import MyPageLayout from "../../../common/layouts/myPageLayout"
import Consent from "../../../mypagecomponents/contacinfo/Consent"
import { loginRequest } from "../../../security/authConfig"

const GetConsentsContent = ({ location }) => {
  return (
    <MyPageLayout props="">
      <Consent />

      <div className="pt-5 flex justify-end">
        <button
          type="button"
          className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-meny-700 hover:bg-meny-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
          onClick={() =>
            navigate("/app/medlemskap/applications/avsluttarbeidsforhold/", {
              state: {
                consentLiabality: location.state.consentLiabality,
                retired: true,
              },
            })
          }
        >
          Neste
        </button>
      </div>
    </MyPageLayout>
  )
}

const GetConsents = ({ location }) => {
  const authRequest = {
    ...loginRequest,
  }
  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      <GetConsentsContent location={location} />
    </MsalAuthenticationTemplate>
  )
}

export default GetConsents
