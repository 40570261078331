import { Disclosure } from "@headlessui/react"
import { ChevronDownIcon } from "@heroicons/react/solid"
import React from "react"
import { classNames } from "../graphichs/misc"

const ContentBox = ({ props, children }) => {
  const header = props?.header
  const key = props?.key
  const defaultOpen = props?.defaultOpen || false
  return (
    <dl className="mt-6 space-y-6 divide-y divide-gray-200 bg-white rounded-lg shadow px-5 py-6 sm:px-6">
      <Disclosure
        defaultOpen={defaultOpen}
        as="div"
        key={key}
        className="pt-6 pl-2 sm:pl-4"
      >
        {({ open }) => (
          <>
            <dt className="text-lg">
              <Disclosure.Button
                key={key}
                className="text-left w-full flex justify-between items-start text-gray-400"
              >
                <span className="font-medium text-gray-900">{header}</span>
                <span className="ml-6 h-7 flex items-center">
                  <ChevronDownIcon
                    className={classNames(
                      open ? "-rotate-180" : "rotate-0",
                      "h-6 w-6 transform"
                    )}
                    aria-hidden="true"
                  />
                </span>
              </Disclosure.Button>
            </dt>
            <Disclosure.Panel as="dd" className="mt-2 pr-12">
              {children}
            </Disclosure.Panel>{" "}
          </>
        )}
      </Disclosure>
    </dl>
  )
}

export default ContentBox
