import { ResponseEnum } from "../../../Utils/Types"
import {
  CityOptionsetEnum,
  PayerType,
  Roles,
  StatusAttendee,
} from "./helpers/Event"

export type EventType = {
  eventId?: string
  businessUnitId?: string
  allowCustomAgenda?: boolean
  allowAnonymousRegistrations?: boolean
  autoregisterWaitlistItems?: boolean
  description?: string
  endDate?: string
  eventFormat?: number
  startDate?: string
  timeZone?: number
  timezoneName?: string
  eventLanguage?: Number
  maxCapacity?: number
  isCapacityRestricted?: boolean
  eventName?: string
  publicEventUrl?: string
  readableEventId: string
  showAutomaticRegistrationCheckbox?: boolean
  image?: string
  showWaitlist?: boolean
  building?: Building
  room?: Room
  enableCaptcha?: boolean
  customFields?: CustomFields
  eventType?: number
  eventTypeObject?: EventClasification
}

export type Building = {
  id?: string
  accessibleToilets?: boolean
  additionalFacilities?: string
  addressComposite?: string
  addressLine1?: string
  city?: string
  country?: string
  description?: string
  disabledAccess?: boolean
  disabledParking?: boolean
  email?: string
  name?: string
  postalCode?: string
  stateProvince?: string
  telephone1?: string
  website?: string
  publicTelephoneAvailable?: boolean
  wifiAvailable?: boolean
  wifiPassword?: string
  wifiSSID?: string
}

export type Room = {
  id?: string
  description?: string
  disabledAccess?: boolean
  name?: string
}

export type CustomFields = {
  showincalender?: boolean
  amounthours?: number
  stopWebsiteRegistrationson?: string
  eventType?: OptionSetType
  projectno?: string
  city?: { value?: CityOptionsetEnum }
  stopWebsiteCancellationon?: string
  calendarContent: string
}

export type SessionCustomFields = {
  startDate: string
  endDate: string
  attendance?: { value: number }
  amounthours: number
  stopwebsiteregistrationson: string
  projectno: string
  city?: { value?: CityOptionsetEnum }
  stopWebsiteCancellationon?: string
  sessionNo?: Number
  calendarContent: string
}

export type EventParticipants = {
  contactId: string
  contactName: string
  accountId: string
  accountName: string
}

export type SessionType = {
  id: string
  camerasPermitted?: number
  detailedDescription?: string
  endTime?: string
  language?: number
  name?: string
  recordingsPermitted?: number
  sessionFormat?: number
  sessionObjectives?: string
  sessionPreRequisites?: string
  sessionsummary?: string
  sessionType?: number
  startType?: string
  userEligibleToRegister?: boolean
  alreadyRegistered?: boolean
  building?: Building
  room?: Room
  speakers?: Speaker[]
  customFields?: SessionCustomFields
  passes?: Array<Pass>
}

export type Pass = {
  passId?: string
  eventId?: string
  price?: number
  numberOfPassesSold?: number
  numberOfPassesLeft?: number
  passName?: string
  currencySymbol?: string
  session?: SessionType[]
}

export type Speaker = {
  customFields?: CustomFields
  id?: string
  about?: number
  accomplishments?: string
  blog?: string
  email?: string
  linkedin?: string
  name?: string
  publications?: string
  title?: string
  twitter?: string
  website?: string
  imageUrl?: string
  sessions?: SessionType[]
}

export type CustomRegistrationField = {
  customFields?: CustomFields
  customRegistrationFieldId: string
  text?: string
  type?: number
  isRequired?: boolean
  choices?: string
}

export enum FieldEnum {
  SimpleText = 100000000,
  Boolean = 100000001,
  ChooseMore = 100000002,
  ChooseOne = 100000003,
}

export type OptionSetType = {
  id: string
  logicalName: string
  name: string
}

export type WorkplaceType = {
  id: string
  name: string
}

export type OtherPayerType = {
  id: OtherPayerFields
  payload: string
}

export type CompanyPayerType = {
  id: CompanyPayerFields
  payload: string
}

export type UpsertPayerType = {
  paymentType?: number
  invoicecompanyname?: string
  invoiceorganizationno?: string
  invoiceaddressline1?: string
  customerpurchaseno?: string
  billto_name?: string
  billto_accont?: string
  zip?: string
}

export type CompanyPayerFields = "invoiceRef1" | "invoiceRef2"

export type OtherPayerFields =
  | "name"
  | "orgNr"
  | "invoiceAdr"
  | "invoiceRef1"
  | "invoiceRef2"
  | "zipCode"

export type SessionToPassType = {
  sessionId: string
  passMembership: PassMembership[]
}

export type PassMembership = {
  passId: string
  membershipId: string[]
}

export type RegistrationRequestType = {
  attendees?: Array<CustomAttendee>
  // hipObject?: VerifyCaptchaInputRequest
}

export type VerifyCaptchaInputRequest = {
  flowId?: string
  solution?: string
  token?: string
  clientIp?: string
  type?: string
}

export type CustomAttendee = {
  passId?: string
  payerType?: PayerType
  payerInfo?: PayerInfo
  registrationReason?: string
  responses?: Array<CustomRegistrationFieldResponse>
  attendeeSessions?: Array<AttendeeSession>
}

export type PayerInfo = {
  name?: string
  orgNr?: string
  invoiceAdr?: string
  invoiceRef1?: string
  invoiceRef2?: string
  zipCode?: string
}

export type CustomRegistrationFieldResponse = {
  id?: string
  value?: string
}

export type AttendeeSession = {
  sessionId?: string
}

export type EventRegistration = {
  createdfromapi?: boolean
  primaryrole?: Number
  billtoContactname?: string
  iscanceled?: boolean
  statuscode?: Number
  publishingstate?: Number
  amounthourssum?: Number
  area?: Number
  internalregistrationstatus?: Number
  webinarregistrationid?: string
  companysize?: Number
  registrationoperation?: string
  timezoneruleversionnumber?: Number
  eventid?: string
  correlationid?: string
  memo?: string
  attendance?: Number
  modifiedon?: string
  statecode?: Number
  registrationstatus?: string
  registrationnotificationseen?: boolean
  contactid?: string
  timescheckedin?: Number
  customerpurchaseno?: string
  industry?: Number
  yearsinindustry?: Number
  customerid?: string
  createdon?: string
  amounthourssumState?: Number
  utcconversiontimezonecode?: Number
  participantspecialistprogramid?: string
  importsequencenumber?: Number
  invoiceaddressZipcodeid?: string
  invoiceaddressline1?: string
  registersessions?: boolean
  qrcode?: string
  eventregistrationid?: string
  invoicecompanyname?: string
  amounthourssumDate?: string
  invoiceorganizationno?: string
  name?: string
  statusattendee?: StatusAttendee
  sessionRegistrations?: SessionRegistrationCrm[]
  eventCrm?: EventCrm
  customerContact?: EventRegContact
  customerAccount?: EventRegAccount
  zipCode?: ZipCode
  employerName?: string
}

// export type ZipCode = {

// }

export type SessionRegistrationCrm = {
  event?: string
  timezoneruleversionnumber?: Number
  registrationid?: string
  statuscode?: Number
  statusattendee?: Number
  createdfromapi?: boolean
  name?: string
  sessionregistrationid?: string
  publishingstate?: Number
  registrationoperation?: string
  createdon?: string
  registrationstatus?: string
  primaryrole?: Number
  webinarregistrationid?: string
  source?: Number
  sessionid?: string
  utcconversiontimezonecode?: Number
  registrationnotificationseen?: boolean
  importsequencenumber?: Number
  contactid?: string
  iscanceled?: boolean
  timescheckedin?: Number
  statecode?: Number
  modifiedon?: string
  sessionCrm?: SessionCrm
}

export type Checkin = {
  sessionregistration?: string
  transactioncurrencyid?: string
  utcconversiontimezonecode?: Number
  verified?: boolean
  checkinstatus?: Number
  checkintype?: Number
  event?: string
  contact?: string
  sessionattended?: string
  name?: string
  statecode?: Number
  numberofquestionsasked?: Number
  registrationid?: string
  amounthours?: Number
  sessioncode?: string
  viewingdurationinmins?: Number
  checkintime?: string
  createdon?: string
  modifiedon?: string
  registrationidfromqr?: string
  audiencetype?: Number
  checkinid?: string
  purchasedpassesid?: string
  checkouttime?: string
  participantspecialistprogramid?: string
  statuscode?: Number
  importsequencenumber?: Number
  sessiontype?: Number
  absencedetails?: string
  eventRegistration?: EventRegistration
  sessionRegistration?: SessionRegistrationCrm
  eventTypeData: EventClasification
}

export type CheckinAdmin = {
  id?: string
  checkinType?: Number
  eventRegistrationId?: string
  eventId?: string
  sessionRegistrationId?: string
  sessionId?: string
  checkinStatus?: Number
  checkinStatusName?: string
  ownerId?: string
  contactId?: string
  amountHours?: Number
  absenceDetails?: string
}

export type EventAdminCRM = {
  id: string
  readableEventId: string
  name: string
  startDate: Date
  endDate: Date
  sessionCount: number
  buildingId: string
  building: {
    id: string
    name: string
    capacity: number
    address: string
  }
  eventTypeId: string
  eventType: {
    id: string
    name: string
    registrationType: number
  }
  amountHours: number
  myEngagementRole: string
  cityName: string
  city: CityOptionsetEnum
  checkinCompleted: boolean
}

export type SpeakerRequest = {
  id: string
  name: string
  event: EventAdminCRM
  eventId: string
  session: SessionCRM
  sessionId: string
  speaker: SpeakerCRM
  speakerId: string
  //  role: Roles
  roleName: string
  comment: string
  response: ResponseEnum
  approved: boolean
  amountHoursCourseLeader: number // decimal
  amountHoursLecturer: number // decimal
  cityName: string
}

export type EventCrm = {
  readableeventid?: string
  eventid?: string
  description?: string
  eventenddate?: string
  eventformat?: Number
  eventstartdate?: string
  eventtimezone?: Number
  eventtimezonename?: string
  eventtype?: Number
  language?: Number
  maxnumberofregistrations?: Number
  name?: string
  publiceventurl?: string
  enablecaptcha?: Boolean
  showincalender?: Boolean
  amounthours?: Number
  stopwebsiteregistrationson?: string
  stopwebsitecancellationon?: string
  eventtypeid?: string
  building?: BuildingCrm
  room?: RoomCrm
  city?: CityOptionsetEnum
  speakerEngagementIds?: string[]
  calendarContent?: string
  eventTypeObject?: {
    registrationType?: Number
  }
}

export type SessionCrm = {
  sessionid?: string
  cameraspermitted?: Number
  detaileddescription?: string
  endtime?: string
  language?: Number
  name?: string
  recordingspermitted?: Number
  sessionformat?: Number
  sessionobjectives?: string
  sessionprerequisites?: string
  sessionsummary?: string
  sessiontype?: Number
  starttime?: string
  building?: BuildingCrm
  room?: string
  recordingforproducersandspeakers?: Boolean
  amountHours: number
  speakerEngagementIds?: string[]
  calendarContent: string
  projectno: string
  stopwebsitecancellationon: string
  stopwebsiteregistrationson: string
  city: CityOptionsetEnum
}

export type MyEventsType = {
  checkin?: Array<Checkin>
  eventRegistration?: Array<EventRegistration>
  speakerEngagement?: Array<SpeakerEngagement>
}

export type SpeakerEngagement = {
  id?: string
  sessionId?: string
  eventId?: string
  name?: string
  speaker?: SpeakerCRM
  role?: Roles
}

export type SessionCRM = {
  sessionid: string
  eventId: string
  cameraspermitted: Number
  detaileddescription: string
  endtime: string
  language: Number
  name: string
  recordingspermitted: Number
  sessionformat: Number
  sessionobjectives: string
  sessionprerequisites: string
  sessionsummary: string
  sessiontype: Number
  starttime: string
  attendance: number
  recordingforproducersandspeakers: boolean
  building: {
    buildingid: string
    accessibletoilets: Number
    additionalfacilities: string
    addresscomposite: string
    addressline1: string
    addressline3: string
    city: string
    country: string
    description: string
    email: string
    name: string
    postalcode: string
    stateprovince: string
    website: string
    wifiavailable: Number
    wifipassword: string
    wifissid: string
  }
  roomCrm: {
    description: string
    roomid: string
    name: string
    disabledaccess: Number
  }
  myEngagementRole: string
  city: number
  cityName: string
  event: {
    id: string
    name: string
  }
  projectNo: string
  checkinCompleted: boolean
}
export type EventRegContact = {
  firstname?: string
  lastname?: string
}

export type EventRegAccount = {
  name?: string
}

export type BuildingCrm = {
  buildingid?: string
  accessibletoilets?: Number
  additionalfacilities?: string
  addresscomposite?: string
  addressline1?: string
  addressline3?: string
  city?: string
  country?: string
  description?: string
  email?: string
  name?: string
  postalcode?: string
  stateprovince?: string
  website?: string
  wifiavailable?: Number
  wifipassword?: string
  wifissid?: string
}

export type RoomCrm = {
  description?: string
  roomid?: string
  name?: string
  disabledaccess?: Number
}

export type SpeakerCRM = {
  id?: string
  contactId?: string
  speakerCost?: number
  contact: {
    id: string
    firstName: string
    lastName: string
    mobilePhone: string
    emailAddress1: string
  }
  name: string
}

export type ContactOtherPayer = {
  name?: string
  orgNr?: string
  invoiceAdr?: string
  zipCode?: ZipCode
}

export type ZipCode = {
  id: string
  postalPlace?: string
  postalCode?: string
}

export type EventClasification = {
  id?: string
  name?: string
  registrationType?: Number
  eventCategory?: EventCategory
}

export type EventCategory = {
  id?: string
  name?: string
}
