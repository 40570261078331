import React, { useEffect, useState } from "react"
import EventAccoridon from "./EventAccordion"
import { EventType } from "./EventType"
import { CityOptionsetValueToString } from "./helpers/Event"

const EventAccordionGroup = (props: {
  sortOn: string
  events: Array<EventType>
}) => {
  type eventListType = Array<{ header: string; content: Array<EventType> }>

  const [events, setEvents] = useState<eventListType>([])

  function MakeList() {
    const today = Date.now()
    const temp: eventListType = []

    props.events.map(item => {
      const header = GetHeader(item, props.sortOn)

      if (temp.findIndex(x => x.header === header) === -1) {
        if (item.startDate !== undefined) {
          if (Date.parse(item.startDate) >= today) {
            temp.push({
              header: header,
              content: [item],
            })
          }
        }
      } else {
        temp.map(x => {
          if (x.header === header) {
            if (
              item.startDate !== undefined &&
              Date.parse(item.startDate) >= today
            ) {
              x.content.push(item)
            }
          }
        })
      }
    })
    setEvents(temp)
  }

  useEffect(() => {
    MakeList()
  }, [props.sortOn, props.events])

  return (
    <div>
      {/* {props.events.map(item => (
        <EventAccoridon {...item} key={item.eventId}></EventAccoridon>
      ))} */}
      {events.map(itemList => (
        <div key={itemList.header}>
          <div className="flex flex-col items-center">
            <div className="max-w-3xl w-full">
              <h2 className="mt-8 text-1xl font-bold text-meny-700">
                {itemList.header}
              </h2>
              <div className="w-full max-w-3xl h-px bg-gray-200 mb-4"></div>
            </div>
          </div>
          <div className="max-w-3xl mx-auto divide-y divide-gray-200 rounded-lg overflow-hidden mb-6">
            {itemList.content.map(item => (
              <EventAccoridon {...item} key={item.eventId} />
            ))}
          </div>
        </div>
      ))}
    </div>
  )
}

const GetHeader = (item: EventType, sortOn: string) => {
  switch (sortOn) {
    case "date":
      return item.startDate?.split("T")[0].split("-")[0] || ""
    case "registrationDate":
      return (
        item.customFields?.stopWebsiteRegistrationson
          ?.split("T")[0]
          .split("-")[0] || ""
      )
    case "city":
      return CityOptionsetValueToString(item.customFields?.city?.value)
    case "name":
      return item.eventName?.charAt(0).toLowerCase() || ""
    case "eventCategori":
      return item.customFields?.eventType?.name || "Uten kategori"
    default:
      return item.startDate?.split("T")[0].split("-").reverse().join("/") || ""
  }
}

export default EventAccordionGroup
