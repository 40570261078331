import { useAccount, useMsal } from "@azure/msal-react"
import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { fetchApi, postApi } from "../../../../Utils/Api.utils"
import ReactDatePicker from "react-datepicker"
import FileUpload from "../../document/FileUpload"
import { navigate } from "gatsby"
import { ErrorMessage } from "@hookform/error-message"
import AddPracticeHours from "../AddPracticeHours"
import { toast } from "react-toastify"
import HelperText from "../../../common/text/HelperText"
import NotificationDialog from "../../../common/graphichs/NotificationDialog"
import ConfirmationDialog from "../../../common/graphichs/ConfirmationDialog"
import { cyanButton } from "../../../../styles/tailwindClasses"
import {
  Activity,
  PracticeHour,
  SpecialistProgram,
} from "../../../../Utils/Types"

const PracticeCertificateEdit = props => {
  const program: SpecialistProgram = props.program
  const activity: Activity = props.activity
  const queryClient = useQueryClient()
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const [activityComment, setActivityComment] = useState(activity.comment)
  const [startDate, setStartDate] = useState(activity.fromDate)
  const [practiceTypes, setPracticeTypes] = useState<any[]>()
  const [selectePracticeType, SetSelectedPracticeType] = useState(
    activity.practiceTypeId
  )
  const [originalPracticeHours, setOriginalPracticeHours] = useState<
    PracticeHour[]
  >([])
  const [practiceHoursStartedEditing, setPracticeHoursStartedEditing] =
    useState(false)
  const [earliestDate, setEarliestDate] = useState(activity.fromDate)
  const [latestDate, setLatestDate] = useState(activity.fromDate)
  const [sumPractice, setSumPractice] = useState(activity.sumPractice)
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [onConfirmationOk, setOnConfirmationOk] = useState(false)
  const [practiceHours, setPracticeHours] = useState([])

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm()

  const userPracticeHoursQuery = useQuery(
    "userPracticeHoursData",
    () =>
      fetchApi(
        process.env.GATSBY_API_URL + "/PracticeHours/" + activity.id,
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: data => {
        setOriginalPracticeHours(data)
      },
      onError: _error => {},
    }
  )

  useQuery(
    "specialitiesDataActive",
    () =>
      fetchApi(
        process.env.GATSBY_API_URL + "/Specialities/all/active",
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      enabled: !!program?.specialityId,
      onSuccess: data => {
        if (data.length > 0 && program?.specialityId !== undefined) {
          const filteredData = data.filter(x => x.id == program?.specialityId)
          if (filteredData[0].practiceTypeList?.length > 0) {
            const practiceTypeList = filteredData[0].practiceTypeList.sort(
              (a, b) => a.name.localeCompare(b.name)
            )
            setPracticeTypes(practiceTypeList)
          }
        }
      },
      onError: _error => {},
    }
  )

  useQuery(
    "practiceTypesData",
    () =>
      fetchApi(
        process.env.GATSBY_API_URL + "/PracticeTypes/all",
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: data => {
        if (program?.specialityId == undefined) {
          const practiceTypeList = data.sort((a, b) =>
            a.name.localeCompare(b.name)
          )
          setPracticeTypes(practiceTypeList)
        }
      },
      onError: _error => {},
    }
  )

  const CalculateSumPractice = value => {
    const sum = sumPractice + value
    setSumPractice(parseFloat(sum))
  }

  const onConfirmation = e => {
    if (e) {
      setOnConfirmationOk(true)
    }
  }

  //   const prePostActivity = async (data, e) => {
  //     if (practiceHoursStartedEditing && !onConfirmationOk) {
  //       setShowConfirmation(true)
  //       return
  //     }

  //     patchActivity(data)
  //   }

  const patchActivity = async data => {
    data.id = activity.id
    data.comment = activityComment
    data.fromDate = earliestDate
    data.toDate = latestDate

    if (selectePracticeType != "0") data.practiceTypeId = selectePracticeType

    if (selectePracticeType == "0") {
      toast.warning("Vennligst velg praksistype!", {
        position: "top-center",
        autoClose: 10000,
      })
      return
    }

    // if (practiceHours.length == 0) {
    //   toast.warning("Vennligst legg til praksisdetaljer!", {
    //     position: "top-center",
    //     autoClose: 10000,
    //   })
    //   return
    // }

    props.setShowLoadingData(true)
    props.mutationUpdateActivity.mutateAsync(data).then(activityId => {
      //   setShowLoadingData(false)
      //   if (practiceHours.length > 0) {
      //     practiceHours.forEach(practiceHour => {
      //       practiceHour.activitySpecialistProgramId = activityId
      //       props.mutationCreatePracticeHours.mutate(practiceHour)
      //     })
      //   }
    })
  }

  return (
    <div>
      <div>
        {/* {showConfirmation && (
          <ConfirmationDialog
            onConfirmation={onConfirmation}
            setShowConfirmation={setShowConfirmation}
            subject="Obs!"
            description="Du har en påbegynt en registrering av praksisdetaljer som ikke er lagret. Klikk Bekreft for å sende inn eller avbryt for å fullføre registrering av praksisdetaljer."
            canCondfirm={true}
            showDesc={true}
          />
        )} */}
      </div>
      <form method="PATCH" onSubmit={handleSubmit(patchActivity)}>
        <div className="grid grid-cols-6 gap-6">
          <div className="col-span-6 sm:col-span-3">
            <label
              htmlFor="courseProviderAccountId"
              className="block text-sm font-medium text-gray-700"
            >
              Praksistype*
            </label>
            <select
              id="courseProviderAccountId"
              name="courseProviderAccountId"
              onChange={e => SetSelectedPracticeType(e.target.value)}
              value={selectePracticeType}
              defaultValue={activity.practiceTypeId}
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-meny-700 focus:border-meny-500 sm:text-sm"
            >
              <option disabled selected value="0">
                --velg--
              </option>

              {practiceTypes?.map(a => (
                <option key={a.id} value={a.id}>
                  {a.name}
                </option>
              ))}
            </select>
          </div>

          <div className="col-span-6 sm:col-span-3">
            <label
              htmlFor="sumPractice"
              className="block text-sm font-medium text-gray-700"
            >
              Sum praksis
            </label>
            <input
              {...register("sumPractice")}
              readOnly
              type="text"
              value={sumPractice}
              //   defaultValue={sumPractice}
              name="sumPractice"
              id="sumPractice"
              className="mt-1 bg-gray-300 focus:ring-meny-700 focus:border-meny-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            />
          </div>

          <div className="col-span-6">
            <label
              htmlFor="comment"
              className="block text-sm font-medium text-gray-700"
            >
              Kommentar
            </label>
            <div className="mt-1">
              <textarea
                id="comment"
                {...register("comment", {
                  required: false,
                  maxLength: {
                    value: 2000,
                    message: "Maks antall tegn er 2000",
                  },
                })}
                onChange={e => {
                  setActivityComment(e.target.value)
                }}
                rows={3}
                className="shadow-sm focus:ring-meny-700 focus:border-meny-500 block w-full sm:text-sm border border-gray-300 rounded-md"
                placeholder="Kommentar"
                defaultValue={activity.comment}
              />
              <ErrorMessage
                errors={errors}
                name="comment"
                render={({ message }) => (
                  <p className="text-sm text-red-600">{message}</p>
                )}
              />
            </div>
            <HelperText helpertext="Skriv inn type praksis dersom du ikke finner den i listen over praksistyper." />
          </div>
        </div>

        <div className="mt-8 border-t flex justify-end">
          <button
            type="submit"
            className="ml-3 mt-6 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-meny-600 hover:bg-meny-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
          >
            Oppdater
          </button>
        </div>
      </form>
    </div>
  )
}

export default PracticeCertificateEdit
