const attendanceValueToString = (value: number | undefined): string => {
  switch (value) {
    case 1:
      return "Fysisk"
    case 2:
      return "Digitalt"
    default:
      return "Ukjent"
  }
}

const CityOptionsetValueToString = (value?: CityOptionsetEnum): string => {
  if (!value) return "Ukjent"

  switch (value) {
    case CityOptionsetEnum.Oslo:
      return "Oslo"
    case CityOptionsetEnum.Gardermoen:
      return "Gardermoen"
    case CityOptionsetEnum.Trondheim:
      return "Trondheim"
    case CityOptionsetEnum.Bergen:
      return "Bergen"
    default:
      return "Ukjent"
  }
}

const RolesOptionsetToString = (value: Roles | undefined): string => {
  switch (value) {
    case 292460000:
      return "Kursleder"
    case 292460001:
      return "Foredragsholder"
    default:
      return ""
  }
}

enum CityOptionsetEnum {
  Bergen = 292460000,
  Gardermoen = 292460001,
  Oslo = 292460002,
  Trondheim = 292460003,
}

enum EventRegistrationTypeEnum {
  optional = 778380000,
  requireAll = 778380001,
  noSessions = 778380002,
}

enum PayerType {
  privatePayer = 0,
  companyPayer = 1,
  otherPayer = 2,
}

enum CheckinType {
  Delmøteinnsjekking = 100000000,
  Arrangementsinnsjekking = 100000001,
}

enum StatusAttendee {
  Påmeldt_web = 778380000,
  Til_behandling = 778380001,
  Venteliste = 778380002,
  Deltager = 778380003,
  Avmeldt_etter_frist = 778380004,
  Avmeldt_gyldig_fravær = 292460000,
  Ikke_oppmøtt = 778380005,
  Oppmøtt = 292460000,
  Delvis_oppmøtt = 292460001,
  Ikke_godkjent_kurs = 292460002,
  Avlyst = 292460003,
}

enum Roles {
  Kursleder = 292460000,
  Foredragsholder = 292460001,
}

const StatusAttendeeArr: Array<{ key: StatusAttendee; status: string }> = [
  { key: 778380000, status: "Påmeldt web" },
  { key: 778380001, status: "Til behandling" },
  { key: 778380002, status: "Venteliste" },
  { key: 778380003, status: "Deltager" },
  { key: 778380004, status: "Avmeldt etter frist" },
  { key: 292460000, status: "Avmeldt gyldig fravær" },
  { key: 778380005, status: "Ikke oppmøtt" },
  { key: 292460000, status: "Oppmøtt" },
  { key: 292460001, status: "Delvis oppmøtt" },
  { key: 292460002, status: "Ikke godkjent kurs" },
  { key: 292460003, status: "Avlyst" },
]

const StatusAttendeeToString = (
  status: StatusAttendee | undefined
): string | undefined => {
  if (status === undefined) return undefined
  return StatusAttendeeArr.find(item => item.key === status)?.status
}

export {
  attendanceValueToString,
  EventRegistrationTypeEnum,
  PayerType,
  CheckinType,
  StatusAttendee,
  Roles,
  StatusAttendeeToString,
  CityOptionsetEnum,
  CityOptionsetValueToString,
  RolesOptionsetToString,
}
