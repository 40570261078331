import { useAccount, useMsal } from "@azure/msal-react"
import { AnnotationIcon } from "@heroicons/react/outline"
import React, { useEffect, useState } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { fetchApi, postApi } from "../../../Utils/Api.utils"
import { RemoveUnderScore } from "../../../Utils/FormatHelper"
import LoadingData from "../../common/graphichs/LoadingData"
import { PlusSmIcon as PlusSmIconOutline } from "@heroicons/react/outline"
import { Link, navigate } from "gatsby"
import { cyanButton } from "../../../styles/tailwindClasses"

const Activities = ({ program, specialityEducation }) => {
  const queryClient = useQueryClient()
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const [activities, setActivities] = useState<any[]>()

  const programSpecializationActivitiesQuery = useQuery(
    "programSpecializationActivitiesData-" + program.id,
    () =>
      fetchApi(
        process.env.GATSBY_API_URL +
          "/SpecializationActivities/Program/" +
          program.id,
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: data => {
        setActivities(data)
      },
      onError: _error => {},
    }
  )

  return programSpecializationActivitiesQuery.isLoading ? (
    // // "Laster inn..."
    <LoadingData />
  ) : programSpecializationActivitiesQuery.isError ? (
    programSpecializationActivitiesQuery.error.message
  ) : (
    <div className=" border shadow-sm border-gray-200 rounded-lg pt-8 pb-12 px-4 sm:pt-8 sm:px-6 lg:px-0 lg:pl-8 lg:pr-14">
      <div className="max-w-lg mx-auto lg:mx-0">
        <p className="mb-4 text-2xl font-extrabold text-gray-900 sm:text-3xl">
          Programløpets aktiviteter
        </p>
        {program.approveStatus != 778380000 ? (
          <>
            <Link
              to="/app/kurs/specialityeducation/newactivity"
              state={{
                program: program,
                specialityEducation: specialityEducation,
              }}
              className={cyanButton + " inline-flex items-center "}
              activeClassName="active-link"
            >
              <PlusSmIconOutline className="h-6 w-6" aria-hidden="true" />
              Legg til aktivitet
            </Link>
          </>
        ) : null}
        <dl className="mt-12 space-y-10">
          {programSpecializationActivitiesQuery.data?.map(activity => {
            return (
              <>
                {activity.status !== 2 ? (
                  <div key={activity.name} className="relative">
                    <dt
                      onClick={e =>
                        navigate("/app/kurs/specialityeducation/activity", {
                          state: { program: program, activity: activity },
                        })
                      }
                    >
                      <div className="absolute h-12 w-12 bg-meny-600 cursor-pointer rounded-md flex items-center justify-center hover:bg-meny-500">
                        <AnnotationIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </div>

                      {/* <p className="ml-16 text-lg leading-6 font-medium text-gray-900">{activity.name}</p> */}

                      <p className="ml-16 text-lg leading-6 font-medium text-gray-900 cursor-pointer">
                        {activity.activityType == "292460000"
                          ? "Ekstern kurs"
                          : null}
                        {activity.activityType == "292460001"
                          ? "Praksisattest"
                          : null}
                        {activity.activityType == "292460003"
                          ? "Skriftlig arbeid"
                          : null}
                        {activity.activityType == "292460004"
                          ? "Veiledningsattest"
                          : null}
                        {activity.activityType == "292460002"
                          ? "Veiledningskontrakt"
                          : null}
                        {activity.activityType == "292460005"
                          ? "Godkjenning veileder"
                          : null}
                        {activity.activityType == "292460006"
                          ? "Vedlikeholdsaktivitet"
                          : null}
                        {activity.activityType == "292460007"
                          ? "Egenaktivitet"
                          : null}
                        {activity.activityType == "292460008"
                          ? "Konsulentsyklus"
                          : null}
                      </p>
                    </dt>
                    {/* Godkjent */}
                    {activity.approveStatus === 778380000 ? (
                      <dd className="mt-2 ml-16 ">
                        <span className="inline-flex rounded-full bg-green-300 px-2 text-xs font-semibold leading-5 text-black">
                          {RemoveUnderScore(activity.approveStatusName)}
                        </span>
                      </dd>
                    ) : null}
                    {/* Til godkjenning */}
                    {activity.approveStatus === 778380001 ? (
                      <dd className="mt-2 ml-16 ">
                        <span className="inline-flex rounded-full bg-yellow-300 px-2 text-xs font-semibold leading-5 text-black">
                          {RemoveUnderScore(activity.approveStatusName)}
                        </span>
                      </dd>
                    ) : null}
                    {/* Avslag */}
                    {activity.approveStatus === 778380002 ? (
                      <dd className="mt-2 ml-16 ">
                        <span className="inline-flex rounded-full bg-red-300 px-2 text-xs font-semibold leading-5 text-black">
                          {RemoveUnderScore(activity.approveStatusName)}
                        </span>
                      </dd>
                    ) : null}
                    {/* Påbegynt */}
                    {activity.approveStatus === 778380003 ? (
                      <dd className="mt-2 ml-16 ">
                        <span className="inline-flex rounded-full bg-blue-300 px-2 text-xs font-semibold leading-5 text-black">
                          {RemoveUnderScore(activity.approveStatusName)}
                        </span>
                      </dd>
                    ) : null}

                    {/* Ikke fullført registrering */}
                    {activity.approveStatus === 292460000 ? (
                      <dd className="mt-2 ml-16 ">
                        <span className="inline-flex rounded-full bg-orange-300 px-2 text-xs font-semibold leading-5 text-black">
                          {RemoveUnderScore(activity.approveStatusName)}
                        </span>
                      </dd>
                    ) : null}
                    <dd className="mt-2 ml-16 text-base text-gray-600">
                      {activity.comment}
                    </dd>
                  </div>
                ) : null}
              </>
            )
          })}
        </dl>
      </div>
    </div>
  )
}

export default Activities
