import React from "react"
import { CompanyPayerFields } from "../../../mypagecomponents/event/EventType"
import { LableDataComp, lableDataType } from "./LableDataComp"

const CompanyPayer = (props: {
  payerData: Array<{ id: string; payload?: string }>
  setPayerData: (data: Array<{ id: string; payload?: string }>) => void
}) => {
  const onChange = (id: CompanyPayerFields, e: any) => {
    const newData = { id: id, payload: e.target.value }

    const newPayerData = props.payerData.map(item =>
      item.id === id ? newData : item
    )

    props.setPayerData(newPayerData)
  }

  const labels: Array<lableDataType> = [
    { displayName: "Fakturaref. 1 / PO:*", dataName: "invoiceRef1" },
    { displayName: "Fakturaref. 2/ Kontaktperson:", dataName: "invoiceRef2" },
  ]

  return (
    <div className="space-y-4">
      <div className="grid grid-cols-1 md:grid-cols-2 my-6">
        <label className="block text-gray-700 mr-4">Din arbeidsgiver:</label>
        <div className="relative">
          {props.payerData.find(item => item.id === "name")?.payload || (
            <p className="text-red-600 font-semibold">
              legg til arbeidsgiver på profilen din
            </p>
          )}
        </div>
      </div>

      {labels.map(label => (
        <LableDataComp
          key={label.dataName}
          {...label}
          // @ts-ignore
          onChange={onChange}
          defaultValue={
            props.payerData.find(item => item.id === label.dataName)?.payload
          }
        />
      ))}
    </div>
  )
}

export default CompanyPayer
