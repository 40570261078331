import React from "react"
import { ZipDropdownSearch } from "../../../common/inputFields/dropdownSearch"
import {
  CompanyPayerFields,
  OtherPayerFields,
} from "../../../mypagecomponents/event/EventType"

export type lableDataType = {
  displayName?: string
  defaultValue?: string
  dataName: OtherPayerFields | CompanyPayerFields
  onChange?: (id: OtherPayerFields | CompanyPayerFields, e: any) => void
}

const LableDataComp = (props: lableDataType) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2">
      <label className="block text-gray-700 mr-4">{props.displayName}</label>
      <div className="relative">
        <input
          defaultValue={props.defaultValue}
          type="text"
          className="w-full"
          onChange={e => props.onChange!(props.dataName, e)}
        />
      </div>
    </div>
  )
}

const LableZipDataComp = (props: lableDataType) => {
  // e.target.value
  return (
    <div className="grid grid-cols-1 md:grid-cols-2">
      <label className="block text-gray-700 mr-4">{props.displayName}</label>
      <div className="relative">
        <ZipDropdownSearch
          defaultZip={props.defaultValue}
          setZip={item =>
            props.onChange!(props.dataName, { target: { value: item } })
          }
        />
      </div>
    </div>
  )
}

export { LableDataComp, LableZipDataComp }
