import { InteractionType } from "@azure/msal-browser"
import {
  MsalAuthenticationTemplate,
  useAccount,
  useMsal,
} from "@azure/msal-react"
import nb from "date-fns/locale/nb"
import { navigate } from "gatsby"
import React, { useState } from "react"
import ReactDatePicker, { registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { Controller, useForm } from "react-hook-form"
import { useMutation, useQuery } from "react-query"
import { toast } from "react-toastify"
import { fetchApi, patchApi } from "../../../../Utils/Api.utils"
import { Employment } from "../../../../Utils/Entities"
import { Loading } from "../../../../Utils/Loading"
import { ErrorComponent } from "../../../common/graphichs/ErrorComponent"
import LoadingData from "../../../common/graphichs/LoadingData"
import MyPageLayout2 from "../../../common/layouts/myPageLayout2"
import { loginRequest } from "../../../security/authConfig"
import RetiredKeepEmploymentsForm from "../../../mypagecomponents/membership/applications/RetiredKeepEmployments/RetiredKeepEmployments"
import EndDateOnEmploymentsForm from "../../../mypagecomponents/work/EndDateOnEmploymentsForm"
registerLocale("nb", nb)

export type formValues = {
  workId: string
  endDate: Date
}

const EndAllEmployementsContent = () => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({})

  const [employment, setEmployment] = useState<Employment>()

  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const [showLoadingData, setShowLoadingData] = useState<boolean>(false)
  const [endAllEmployments, setEndAllEmployments] = useState<string>("")
  const [employments, setEmployments] = useState<Employment[]>([])
  const [employmentId, setEmploymentId] = useState<string>("")
  const [timer, setTimer] = useState<number>(2500)

  const employmentQuery = useQuery(
    "employment",
    () =>
      fetchApi(
        process.env.GATSBY_API_URL + "/Contacts/Employments",
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onError: _error => {},
    }
  )

  const employmentsData = (employmentQuery.data as Employment[])?.filter(
    item => !item.endDate || new Date(item.endDate) > new Date()
  )

  const UpdateEmployment = useMutation<unknown, unknown, Partial<Employment>>(
    data => {
      console.log("UpdateEmployment", data)
      return patchApi(
        `${process.env.GATSBY_API_URL}/Employments/${data?.id}`,
        data,
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: data => {
        toast.success("Arbeidsforholdet er oppdatert")
        if (endAllEmployments === "No") {
          updateStatus.mutate()
        } else {
          window.setTimeout(() => {
            navigate("/app")
          }, 2500)
        }
      },
      onError: _error => {
        setShowLoadingData(false)
        toast.error("Det har skjedd en feil, prøv igjen senere", {
          position: "top-center",
          autoClose: 6000,
        })
      },
      onSettled: () => {},
    }
  )

  const updateStatus = useMutation(
    () => {
      return patchApi(
        `${process.env.GATSBY_API_URL}/Contacts/me`,
        {
          professionallyActiveStatus: 292460001,
        },
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: data => {
        window.setTimeout(() => {
          navigate("/app")
        }, 2500)
      },
      onError: _error => {
        setShowLoadingData(false)
        toast.error("Det har skjedd en feil, prøv igjen senere", {
          position: "top-center",
          autoClose: 6000,
        })
      },
      onSettled: () => {},
    }
  )

  return showLoadingData || employmentQuery.isLoading ? (
    <LoadingData />
  ) : (
    <MyPageLayout2 props="Rediger Arbeidsforhold">
      <div className="space-y-8 md:space-y-4">
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
          <label
            htmlFor="endAllEmployments"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Vil du fortsette dine arbeidsforhold?:
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <select
              defaultValue={""}
              onChange={option => setEndAllEmployments(option.target.value)}
              className=" disabled:bg-gray-200 disabled:text-opacity-50 block max-w-sm w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
            >
              <option value="" disabled>
                -- Velg et svar --
              </option>
              <option value="Yes">Ja</option>
              <option value="No">Nei</option>
            </select>
          </div>
        </div>
        {endAllEmployments === "No" && (
          <EndDateOnEmploymentsForm
            employments={employmentsData}
            onSave={data => {
              UpdateEmployment.mutateAsync(data)
            }}
          />
        )}
        {endAllEmployments === "Yes" && (
          <RetiredKeepEmploymentsForm
            employments={employmentsData}
            onSave={data => {
              UpdateEmployment.mutateAsync(data)
            }}
          />
        )}
      </div>
    </MyPageLayout2>
  )
}
// export default EditWork

const EndAllEmployements = location => {
  const authRequest = {
    ...loginRequest,
  }
  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      <EndAllEmployementsContent />
    </MsalAuthenticationTemplate>
  )
}

export default EndAllEmployements
