import { Link } from "gatsby"
import React, { useEffect, useState } from "react"
import {
  DateTimeToDate,
  DateTimeToDateTime,
  DateTimeToNorwegian,
  DateTimeToTime,
} from "../../../Utils/DateFormater"
import { Accordion } from "../../common/accordion"
import ContentBox from "../../common/inputFields/ContentBox"
import { SessionCRM } from "../event/EventType"

const MySession = props => {
  const session: SessionCRM = props.session
  const key = session?.sessionid
  const header = session?.name
  // const startDate = DateTimeToNorwegian(new Date(session?.starttime))
  // const endDate = DateTimeToNorwegian(new Date(session?.endtime))
  let content = {
    key: key,
    header: header,
  }

  const [accordionSubtitle, setAccordionSubtitle] = useState("")

  const [access, setAccess] = useState<boolean>(false)

  useEffect(() => {
    const today = new Date()

    const startDate = DateTimeToDate(session?.starttime)
    const endDate = DateTimeToDate(session?.endtime)

    if (new Date(session?.endtime) < today) {
      const diffDays = (today, end) =>
        Math.ceil(Math.abs(end - today) / (1000 * 60 * 60 * 24))

      const difference = diffDays(today, new Date(session?.endtime))

      // if session was more than three months ago
      if (difference > 90) {
        setAccess(false)
      } else {
        setAccess(true)
      }
    } else setAccess(true)

    if (startDate === endDate) {
      const startTime = DateTimeToTime(session.starttime)
      const endTime = DateTimeToTime(session.endtime)

      setAccordionSubtitle(startDate)
    } else {
      const startDateTime = DateTimeToDate(session.starttime)
      const endDateTime = DateTimeToDate(session.endtime)

      setAccordionSubtitle(startDateTime + " - " + endDateTime)
    }
  }, [session?.starttime, session?.endtime])

  return (
    <>
      <Accordion title={session?.name} subTitle={accordionSubtitle}>
        <>
          <div className="sm:grid sm:grid-cols-2 gap-3 mb-4">
            <p className="text-base text-gray-600 sm:col-span-1">
              Arrangement: {session?.event?.name}
            </p>
            <p className="text-base text-gray-600 sm:col-span-1">
              Min rolle: {session?.myEngagementRole}
            </p>

            <p className="text-base text-gray-600 sm:col-span-1">
              Prosjektnummer: {session?.projectNo}
            </p>

            {session?.building?.name != undefined ? (
              <p className="text-base text-gray-600 sm:col-span-1 ">
                Sted: {session?.building?.name}
              </p>
            ) : (
              <p className="text-base text-gray-600 sm:col-span-1 ">
                Sted: Sted ikke valgt enda
              </p>
            )}

            {session?.starttime != undefined ? (
              <p className="text-base text-gray-600 sm:col-span-1">
                Startdato: {DateTimeToDateTime(session?.starttime)}
              </p>
            ) : (
              <p className="text-base text-gray-600 sm:col-span-1">
                Startdato:
              </p>
            )}
            {session?.city != undefined ? (
              <p className="text-base text-gray-600 sm:col-span-1">
                By: {session?.cityName}
              </p>
            ) : (
              <p className="text-base text-gray-600 sm:col-span-1">
                By: By ikke valgt enda
              </p>
            )}
            {session?.endtime != undefined ? (
              <p className="text-base text-gray-600 sm:col-span-1">
                Sluttdato: {DateTimeToDateTime(session?.endtime)}
              </p>
            ) : (
              <p className="text-base text-gray-600 sm:col-span-1">
                Sluttdato:
              </p>
            )}
            {session?.checkinCompleted ? (
              <p className="text-base text-gray-600 sm:col-span-1">
                Oppmøte utført: Ja
              </p>
            ) : (
              <p className="text-base text-gray-600 sm:col-span-1">
                Oppmøte utført: Nei
              </p>
            )}
          </div>
          <div className="sm:grid sm:grid-cols-2 gap-3 mb-4">
            {/* Kurstype 'Ingen delmøter' */}

            <div className="sm:col-span-1 mb-3">
              <Link
                to={"/app/kurs/administrasjon/delmotedeltager"}
                state={{ session: session }}
                className="ml-0 inline-flex justify-end py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-md text-white bg-meny-700 hover:bg-meny-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
              >
                {" "}
                Les mer
              </Link>
            </div>
          </div>
        </>
      </Accordion>
    </>
  )
}

export default MySession
