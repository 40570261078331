import React, { Fragment, useEffect, useRef, useState } from "react"
import { Dialog, Transition } from "@headlessui/react"
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  CheckIcon,
  TrendingDownIcon,
  TrendingUpIcon,
} from "@heroicons/react/outline"
import FileUpload from "../document/FileUpload"
import DocumentList from "../document/DocumentList"
import { useMutation, useQuery, useQueryClient } from "react-query"
import {
  fetchApi,
  patchApi,
  postGetDocumentApi,
} from "../../../Utils/Api.utils"
import { useAccount, useMsal } from "@azure/msal-react"
import { classNames } from "../../common/graphichs/misc"
import { RemoveHtml, RemoveHtmlWithLines } from "../../../Utils/FormatHelper"
import { navigate } from "gatsby"
import { cyanButton } from "../../../styles/tailwindClasses"

const MessageRead = ({ message, setOpenMessageModal }) => {
  const queryClient = useQueryClient()
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const [documents, setDocuments] = useState([])
  const [open, setOpen] = useState(true)
  const cancelButtonRef = useRef(null)
  const [incident, setIncident] = useState([])

  const mutationSetMessageRead = useMutation(
    () => {
      return patchApi(
        process.env.GATSBY_API_URL + "/Messages",
        {
          id: message.id,
          isRead: true,
        },
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: data => {
        queryClient.invalidateQueries(["userMessagesData"])
        queryClient.invalidateQueries(["userMessagesCountData"])
      },
      onError: _error => {},
      onSettled: () => {},
    }
  )

  useEffect(() => {
    mutationGetDocuments.mutate()
    if (message.direction && !message.isRead) mutationSetMessageRead.mutate()
  }, [])

  const mutationGetDocuments = useMutation(
    () => {
      return postGetDocumentApi(
        process.env.GATSBY_API_URL + "/Documents/Regarding/Get",
        {
          PortalMessageId: message.id,
        },
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: data => {
        setDocuments(data)
      },
      onError: _error => {},
      onSettled: () => {},
    }
  )

  const cancelModal = () => {
    setOpen(false)
    setOpenMessageModal(false)
  }

  const userIncidentsIdQuery = useQuery(
    "userIncidentsData",
    () =>
      fetchApi(
        process.env.GATSBY_API_URL + "/Incidents/" + message.regardingId,
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      enabled: message.regardingEntity === "incident",
      onSuccess: data => {
        setIncident(data)
      },
      onError: _error => {},
    }
  )

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        // onClose={() => setOpen(false)}
        onClose={() => {}}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div>
                <div className=" flex flex-row justify-between ">
                  {message.direction ? (
                    <span
                      className={classNames(
                        "bg-green-500",
                        "h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white"
                      )}
                    >
                      <TrendingDownIcon
                        className="w-5 h-5 text-white"
                        aria-hidden="true"
                      />
                    </span>
                  ) : (
                    <span
                      className={classNames(
                        "bg-gray-500",
                        "h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white"
                      )}
                    >
                      <TrendingUpIcon
                        className="w-5 h-5 text-white"
                        aria-hidden="true"
                      />
                    </span>
                  )}
                  {message.regardingEntity === "incident" ? (
                    <button
                      className={cyanButton}
                      onClick={e =>
                        navigate("/app/dialog/sak", {
                          state: {
                            incidentid: message.regardingId,
                            incidenttitle: incident.title,
                          },
                        })
                      }
                    >
                      Se sak
                    </button>
                  ) : null}
                </div>
                <div className="mt-3 text-center sm:mt-5">
                  <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 font-medium text-gray-900"
                  >
                    {message.subject}
                  </Dialog.Title>
                  <div className="mt-2">
                    <div
                      className="text-md text-gray-600 text-left"
                      dangerouslySetInnerHTML={{ __html: message.description }}
                    />
                  </div>
                </div>
                <div className="mt-3 text-center sm:mt-5">
                  <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 font-medium text-gray-900"
                  >
                    Dokumenter
                  </Dialog.Title>
                  <div className="mt-2">
                    <DocumentList
                      documents={documents}
                      mutationGetDocuments={mutationGetDocuments}
                      canDelete={false}
                    />
                  </div>
                </div>
              </div>
              <div className="sm:grid-flow-row-dense">
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700 sm:mt-0 sm:col-start-1 sm:text-sm"
                  onClick={cancelModal}
                  ref={cancelButtonRef}
                >
                  Lukk
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default MessageRead
