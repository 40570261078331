import React from "react"
import { EventParticipants } from "../../EventType"

export type ParticipantListType = {
  participant: Array<EventParticipants>
}

const ParticipantsList = (props: ParticipantListType) => {
  return (
    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
      <table className="min-w-full divide-y divide-gray-300">
        <thead className="bg-meny-700 text-meny-50">
          <tr>
            <th
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-6"
            >
              Navn
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold table2:hidden"
            >
              Arbeidsgiver
            </th>
          </tr>
        </thead>

        {props.participant.map((participant, index) => {
          return (
            <tbody key={index} className="divide-y divide-gray-200 bg-white">
              <tr className="hover:cursor-pointer hover:bg-meny-50">
                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                  {participant?.contactName}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-600 table2:hidden">
                  {participant.accountName}
                </td>
              </tr>
            </tbody>
          )
        })}
      </table>
    </div>
  )
}

export default ParticipantsList
