import { navigate } from "gatsby"
import React from "react"

export const BackButton = () => {
  return (
    <div>
      <button
        type="button"
        className="inline-flex items-center justify-center bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-600 sm:w-auto"
        onClick={() => navigate(-1)}
      >
        Tilbake
      </button>
    </div>
  )
}
