import { useAccount, useMsal } from "@azure/msal-react"
import { navigate } from "gatsby"
import React, { useEffect, useState } from "react"
import { useMutation, useQueryClient } from "react-query"
import { deleteApi } from "../../../../Utils/Api.utils"
import LoadingData from "../../../common/graphichs/LoadingData"
import ModalWithHeader from "../../../common/modal/ModalWithHeader"
import { EventRegistration } from "../../../mypagecomponents/event/EventType"
import { EventRegistrationTypeEnum } from "../../../mypagecomponents/event/helpers/Event"

const CancelEvent = (props: EventRegistration) => {
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const [isLoading, setIsLoading] = useState(false)

  const [showModal, setShowModal] = useState(false)
  const [canCancel, setCanCancel] = useState(false)

  const queryClient = useQueryClient()

  const deleteEventRegistation = useMutation<any, any, string>(
    eventRegistationId => {
      return deleteApi(
        process.env.GATSBY_API_URL +
          "/Events/cancelEventRegistation/" +
          eventRegistationId,
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: () => {
        console.log("deleted, redirecting...")
        navigate("/app/kurs/minekurs")
        queryClient.invalidateQueries("myEvents")
      },
      onSettled: () => {
        setIsLoading(false)
      },
    }
  )

  const onClick = () => {
    setIsLoading(true)
    setShowModal(false)
    deleteEventRegistation.mutate(props.eventregistrationid || "")
  }

  useEffect(() => {
    if (props.eventCrm?.stopwebsitecancellationon) {
      const today = Date.now()
      const cancelDate = new Date(
        props.eventCrm?.stopwebsitecancellationon
      ).getTime()

      setCanCancel(
        today <= cancelDate &&
          props.eventCrm.eventtype !== EventRegistrationTypeEnum.optional
      )
    } else {
      setCanCancel(
        props.eventCrm?.eventtype !== EventRegistrationTypeEnum.optional
      )
    }
  }, [props.eventCrm?.stopwebsitecancellationon])

  return (
    <>
      {isLoading && <LoadingData subject="Melder av kurs..." />}
      {showModal && (
        <ModalWithHeader setModalOpen={setShowModal} header="Avmedling">
          <p>Er du sikker på at du vil melde deg av dette kurset?</p>
          <div className="w-full flex flex-row justify-center mt-8">
            <button
              onClick={() => {
                setShowModal(false)
              }}
              className="w-fit ml-1 inline-flex justify-end py-2 px-8 border border-gray-200 shadow-sm text-md font-medium rounded-md text-black bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
            >
              Avbryt
            </button>
            <button
              onClick={onClick}
              className="w-fit ml-1 inline-flex justify-end py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-md text-white bg-red-700 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-700"
            >
              Avmeld kurs
            </button>
          </div>
        </ModalWithHeader>
      )}
      <div>
        {canCancel ? (
          <button
            onClick={() => {
              setShowModal(true)
            }}
            className="w-fit ml-1 inline-flex justify-end py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-md text-white bg-red-700 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-700"
          >
            Avmeld kurs
          </button>
        ) : null}
      </div>
    </>
  )
}

export default CancelEvent
