export enum SpecialistProgramApproveStatusEnum {
  Godkjent = 778380000,
  Til_godkjenning = 778380001,
  Avslag = 778380002,
  Påbegynt = 778380003,
  Ikke_fullført_registrering = 292460000,
}

export enum ActivityTypeEnum {
  Eksternt_kurs = 292460000,
  Praksisattest = 292460001,
  Skriftlig_arbeid = 292460003,
  Veiledningsattest = 292460004,
  Veiledningskontrakt = 292460002,
  Vedlikeholdsaktivitet = 292460006,
  Godkjenningavveileder = 292460005,
  Egenaktivitet = 292460007,
  Konsulentsyklus = 292460008,
}

export enum MaintenanceActivityTypeEnum {
  ParticipationCongress = 292460000,
  ParticipationCourse = 292460001,
  OtherActivity = 292460002,
}

export enum SupervisorIsSpecialistEnum {
  Specialist = 292460000,
  NotSpesialist = 292460001,
  SemiCompletedSpesialist = 292460002,
}

export enum TypeWrittenReportEnum {
  ProfessionalWork = 292460000,
  DevelopmentWork = 292460001,
  ResearchWork = 292460002,
}

export enum ReasonDispensationEnum {
  A = 292460000,
  B = 292460001,
  C = 292460002,
  F = 292460003,
  D = 292460004,
}

export enum StateEnum {
  Aktiv = 0,
  Inaktiv = 1,
}

export enum SpecialistEducationStatusEnum {
  Aktiv = 1,
  Inaktiv = 2,
}

export enum ApproveStatusEnum {
  Påbegynt = 292460000,
  Til_godkjenning = 778380000,
  Godkjent = 778380001,
  Avslag = 778380002,
}

export enum Levels {
  Mastergrad = 1,
  Bachelorgrad = 2,
  PhD = 3,
  Profesjonsstudiet_i_psykologi = 4,
  Årsstudium_annet = 5,
}

export enum EducationTypes {
  Grunnutdanning_og_annen_utdanning = 1,
  Spesialistutdanning = 2,
}

export enum StatusExpenseEnum {
  Påbegynt = 1,
  Levert = 292460001,
  Innsendt = 2,
  Godkjent = 292460002,
  Til_utbetaling = 292460004,
  Utbetalt = 292460003,
}

export enum ExpenseTypeEnum {
  Flyreise = 292460000,
  Kilometergodtgjørelse = 292460001,
  Andre_transportmidler = 292460002,
  Andre_utlegg = 292460003,
}

export enum TypePracticeEnum {
  Ledelse_og_lederutvikling = 292460000,
  Organisasjonsutvikling = 292460001,
  Veiledning_og_utviklingsarbeid = 292460002,
  Personalutvikling = 292460003,
  Konflikthåndtering = 292460004,
  Arbeidsmiljøspørsmål = 292460005,
  Konsultasjon,
  _ovenfor_individer,
  _gruppeprosesser_og_på_organisasjonsnivå = 292460006,
  Bemanning,
  _seleksjon_og_rekruttering = 292460007,
  Annen_type_aktivitet = 292460008,
  Teamutvikling = 292460009,
}

export enum ConsultingsCyclePhaseEnum {
  Midtveis = 292460000,
  Mot_slutten = 292460001,
}
