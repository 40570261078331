import React from "react"
import ContentBox from "../../../../common/inputFields/ContentBox"
import AddNewEmploymentAccordionContent from "./AddNewEmploymentAccordionContent"
import { useFormContext } from "react-hook-form"
import { FormValues } from "./FlowToOrdinaryTypes"

function AddNewEmploymentAccordion() {
  const [, setReRender] = React.useState(false)

  const { setValue, getValues } = useFormContext<FormValues>() // retrieve all hook methods

  let content = {
    key: "newEmployer",
    header: "Legg til ny arbeidsgiver",
    defaultOpen: true,
  }

  const addNewEmployeeOnClick = () => {
    const isNewEmploymentOld = getValues("isNewEmployment")
    const isNewEmploymentNew = !isNewEmploymentOld

    setValue("isNewEmployment", isNewEmploymentNew)

    if (!isNewEmploymentNew) {
      setValue("newEmployment", undefined)
    }

    // this is to force a re-render of the component
    setReRender(prev => !prev)
  }

  return (
    <div>
      {!getValues("isNewEmployment") && (
        <div className="flex w-full justify-end">
          <button
            type="button"
            onClick={addNewEmployeeOnClick}
            className="disabled:opacity-50 disabled:bg-meny-600 ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-meny-700 hover:bg-meny-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
          >
            Legg til ny arbeidsgiver
          </button>
        </div>
      )}
      {getValues("isNewEmployment") && (
        <>
          <ContentBox props={content}>
            <div>
              <AddNewEmploymentAccordionContent />
              <button
                type="button"
                onClick={addNewEmployeeOnClick}
                className="disabled:opacity-50 disabled:bg-meny-600 ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-meny-700 hover:bg-meny-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
              >
                Avbryt
              </button>
            </div>
          </ContentBox>
        </>
      )}
    </div>
  )
}

export default AddNewEmploymentAccordion
