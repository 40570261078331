import React, { useEffect, useState } from "react"
import { StringParam, useQueryParam } from "use-query-params"
import { DropDown } from "../../../common/dropDown"
import { EventType, SessionType } from "../EventType"
import { CityOptionsetEnum } from "../helpers/Event"
import { filterType } from "./FilterType"

const FilterBy = (props: filterType) => {
  const [month, setMonth] = useQueryParam("month", StringParam)
  const [year, setYear] = useQueryParam("year", StringParam)
  const [eventCategori, setEventCategori] = useQueryParam("cat", StringParam)
  const [eventType, setEventType] = useQueryParam("type", StringParam)
  const [sessionPhase, setSessionPhase] = useQueryParam("nr", StringParam)
  const [searchText, setSearchText] = useQueryParam("query", StringParam)
  const [sessionSearchText, setSessionSearchText] = useQueryParam(
    "sessionQuery",
    StringParam
  )
  const [city, setCity] = useQueryParam("city", StringParam)
  const [sessionCity, setSessionCity] = useQueryParam(
    "sessionCity",
    StringParam
  )

  const [sessionMonth, setSessionMonth] = useQueryParam(
    "sessionMonth",
    StringParam
  )
  const [sessionYear, setSessionYear] = useQueryParam(
    "sessionYear",
    StringParam
  )

  // const [city, setCity] = useState<string>()
  const [localFilteredEvents, setLocalFilteredEvents] = useState<EventType[]>(
    []
  )

  const [localFilteredSessions, setLocalFilteredSessions] = useState<
    SessionType[]
  >([])

  useEffect(() => {
    if (!month) setMonth("alle")
    if (!year) setYear("alle")
    if (!eventCategori) {
      setEventCategori("alle")
    } else {
      if (props.setCategoriFilter) props.setCategoriFilter(eventCategori)
      populateTypeOptions(eventCategori)
    }

    if (!eventType) setEventType("alle")
    if (!sessionSearchText) setSessionSearchText("")
    if (!sessionPhase) setSessionPhase("alle")
    else {
      handlePhaseChange(sessionPhase)
    }

    if (!sessionMonth) setSessionMonth("alle")
    if (!sessionYear) setSessionYear("alle")
    if (!sessionCity) setSessionCity("alle")

    if (!city) setCity("alle")
    // TODO:
    // Hvis sessionPhase er valgt skal alle søkekriterier baseres på kurs, ikke arrangement

    if (!searchText) setSearchText("")
  }, [props.refilterTrigger])

  const [monthOptions, setMonthOptions] =
    useState<Array<{ id: string; label: string; disabled?: boolean }>>(
      monthOptionsData
    )
  const [sessionMonthOptions, setSessionMonthOptions] =
    useState<Array<{ id: string; label: string; disabled?: boolean }>>(
      monthOptionsData
    )
  const [yearOptions, setYearOptions] = useState<
    Array<{ id: string; label: string; disabled?: boolean }>
  >([])
  const [sessionYearOptions, setSessionYearOptions] = useState<
    Array<{ id: string; label: string; disabled?: boolean }>
  >([])
  const [catergoriOptions, setCatergoriOptions] = useState<
    Array<{ id: string; label: string; disabled?: boolean }>
  >([])
  const [typeOptions, setTypeOptions] = useState<
    Array<{ id: string; label: string; disabled?: boolean }>
  >([])
  const [phaseOptions, setPhaseOptions] = useState<
    Array<{ id: string; label: string; disabled?: boolean }>
  >([])
  const [cityOptions, setCityOptions] =
    useState<Array<{ id: string; label: string; disabled?: boolean }>>(
      cityOptionsData
    )

  const [sessionCityOptions, setSessionCityOptions] =
    useState<Array<{ id: string; label: string; disabled?: boolean }>>(
      cityOptionsData
    )

  useEffect(() => {
    setMonthEnabled()
  }, [props.refilterTrigger, year])

  useEffect(() => {
    setSessionMonthEnabled()
  }, [props.refilterTrigger, sessionYear])

  useEffect(() => {
    populateYearOptions()
    populateCategriOptions()
    populateCityOptions()
  }, [props.refilterTrigger])

  useEffect(() => {
    const applyFilter = () => {
      const filterDate = Date.parse(`${year}-${Number(month) + 1}-01`)

      let filterdData
      if (sessionPhase === "alle") {
        filterdData = props.events
          .filter(event => {
            // Filter by date (month and year)
            // if (sessionPhase == "alle") {

            return (
              // (filterDate <= Date.parse(event.endDate || "") &&
              //   filterDate >= Date.parse(event.startDate || "") &&
              //   filterDate !== NaN) ||

              (month === "alle" ||
                new Date(event.startDate || "").getMonth().toString() ===
                  month) &&
              (year === "alle" ||
                new Date(event.startDate || "").getFullYear().toString() ===
                  year)
            )
          })
          .filter(event => {
            // Filter by event category and type
            return (
              eventCategori === "alle" ||
              (event.eventTypeObject?.eventCategory?.name === eventCategori &&
                (event?.eventTypeObject?.name === eventType ||
                  eventType === "alle"))
            )
          })
          .filter(event => {
            // filter by search text
            return event.eventName
              ?.toLowerCase()
              .includes((searchText || "").toLowerCase())
          })
          .filter(event => {
            // filter ended events
            if (!event.endDate) return false
            const endDate = Date.parse(event.endDate)
            const today = Date.now()

            if (!event.startDate) return false
            const fromDate = Date.parse(event.startDate)

            return endDate > today && fromDate >= today
          })
          .filter(event => {
            // filter by city
            return (
              city === "alle" ||
              event.customFields?.city?.value === parseInt(city || "")
            )
          })
      } else {
        filterdData = props.events

          .filter(event => {
            // Filter by event category and type
            return (
              eventCategori === "alle" ||
              (event.eventTypeObject?.eventCategory?.name === eventCategori &&
                (event?.eventTypeObject?.name === eventType ||
                  eventType === "alle"))
            )
          })
          // .filter(event => {
          //   // filter by search text
          //   return event.eventName
          //     ?.toLowerCase()
          //     .includes((searchText || "").toLowerCase())
          // })
          .filter(event => {
            // filter ended events
            if (!event.endDate) return false
            const endDate = Date.parse(event.endDate)
            const today = Date.now()

            return endDate > today
          })
        // .filter(event => {
        //   const sessions = props?.session?.find(
        //     session => session.readableEventId === event.readableEventId
        //   )?.session

        //   const filteredSessions = sessions?.filter(s => {
        //     return (
        //       (sessionMonth === "alle" ||
        //         new Date(s?.customFields?.startDate || "")
        //           .getMonth()
        //           .toString() === sessionMonth) &&
        //       (sessionYear === "alle" ||
        //         new Date(s?.customFields?.startDate || "")
        //           .getFullYear()
        //           .toString() === sessionYear)
        //     )
        //   })
        //   // ?.filter(s => {
        //   //   return (
        //   //     sessionCity === "alle" ||
        //   //     s.customFields?.city?.value === parseInt(sessionCity || "")
        //   //   )
        //   // })

        //   return filteredSessions && filteredSessions.length > 0
        // })
      }

      setLocalFilteredEvents(filterdData)
    }

    applyFilter()
  }, [
    props.refilterTrigger,
    month,
    year,
    // sessionMonth,
    // sessionYear,
    // sessionCity,
    eventCategori,
    sessionPhase,
    eventType,
    searchText,
    city,
  ])

  useEffect(() => {
    const filterDate = Date.parse(`${year}-${Number(month) + 1}-01`)
    let allSessions: Array<SessionType>

    if (sessionPhase !== "alle") {
      // props.session?.forEach(s => {
      //   s.session.forEach(singleSession => {
      //     allSessions.push(singleSession)
      //   })
      // })
    }

    let filteredSessions:
      | Array<{ readableEventId: string; session: Array<SessionType> }>
      | undefined
    if (sessionPhase === "alle") {
      filteredSessions = props.session?.map(item => {
        return {
          ...item,
          session: item.session.filter(session => {
            return (
              sessionPhase === "alle" &&
              Date.parse(session.customFields?.startDate || "") >= Date.now()
            )
          }),
        }
      })
    } else {
      filteredSessions = props.session?.map(item => {
        return {
          ...item,
          session: item.session
            .filter(session => {
              return (
                session.customFields?.sessionNo?.toString() === sessionPhase &&
                Date.parse(session.customFields?.startDate || "") >=
                  Date.now() &&
                (sessionCity === "alle" ||
                  session.customFields?.city?.value ===
                    parseInt(sessionCity || "")) &&
                session.name
                  ?.toLowerCase()
                  .includes((sessionSearchText || "").toLowerCase())
              )
            })
            .filter(session => {
              return (
                (sessionMonth === "alle" ||
                  new Date(session?.customFields?.startDate || "")
                    .getMonth()
                    .toString() === sessionMonth) &&
                (sessionYear === "alle" ||
                  new Date(session?.customFields?.startDate || "")
                    .getFullYear()
                    .toString() === sessionYear)
              )
            }),
        }
      })
    }

    props.setFilteredSession(filteredSessions)
  }, [
    sessionPhase,
    props.refilterTrigger,
    sessionCity,
    sessionSearchText,
    sessionYear,
    sessionMonth,
  ])

  useEffect(() => {
    props.setFilteredEvents(localFilteredEvents)
  }, [localFilteredEvents])

  const setMonthEnabled = () => {
    setMonthOptions(prev => {
      return prev.map(option => {
        if (year === "alle" || year === new Date().getFullYear().toString()) {
          return parseInt(option.id) < new Date().getMonth()
            ? { ...option, disabled: true }
            : { ...option, disabled: false }
        }
        return { ...option, disabled: false }
      })
    })
  }

  const setSessionMonthEnabled = () => {
    setSessionMonthOptions(prev => {
      return prev.map(option => {
        if (
          sessionYear === "alle" ||
          sessionYear === new Date().getFullYear().toString()
        ) {
          return parseInt(option.id) < new Date().getMonth()
            ? { ...option, disabled: true }
            : { ...option, disabled: false }
        }
        return { ...option, disabled: false }
      })
    })
  }

  const populateCityOptions = () => {
    const cities: Array<string> = []

    props.events.map(item => {
      if (item.customFields?.city?.value !== undefined) {
        const itemCity = item.customFields.city.value.toString()
        if (cities.indexOf(itemCity) == -1) cities.push(itemCity)
      }
    })

    setCityOptions(prev => {
      return prev.map(option => {
        if (option.id !== "alle") {
          return cities.indexOf(option.id) === -1
            ? { ...option, disabled: true }
            : { ...option, disabled: false }
        }

        return { ...option, disabled: false }

        // return { ...option, disabled: false }
      })
    })

    const sessionCities: Array<string> = []
    props.session?.map(item => {
      item?.session?.map(s => {
        if (s?.customFields?.city?.value !== undefined) {
          const itemCity = s.customFields?.city.value.toString()
          if (sessionCities.indexOf(itemCity) == -1)
            sessionCities.push(itemCity)
        }
      })
    })
    // console.log(sessionCities)
    setSessionCityOptions(prev => {
      return prev.map(option => {
        if (option.id !== "alle") {
          return sessionCities.indexOf(option.id) === -1
            ? { ...option, disabled: true }
            : { ...option, disabled: false }
        }

        return { ...option, disabled: false }

        // return { ...option, disabled: false }
      })
    })
  }

  const populateYearOptions = () => {
    const years: Array<string> = []

    props.events.map(item => {
      const itemYear = item.startDate?.split("T")[0].split("-")[0] || ""
      if (years.indexOf(itemYear) === -1) {
        years.push(itemYear)
      }
    })
    years.sort((a, b) => parseInt(a) - parseInt(b))

    setYearOptions([
      { id: "alle", label: "Vis alle" },
      ...years.map(item => {
        return { id: item, label: item }
      }),
    ])

    const sessionYear: Array<string> = []
    props?.session?.map(item => {
      item.session.map(s => {
        const itemYear =
          s.customFields?.startDate?.split("T")[0].split("-")[0] || ""
        if (sessionYear.indexOf(itemYear) === -1) {
          sessionYear.push(itemYear)
        }
      })
    })
    sessionYear.sort((a, b) => parseInt(a) - parseInt(b))
    setSessionYearOptions([
      { id: "alle", label: "Vis alle" },
      ...sessionYear.map(item => {
        return { id: item, label: item }
      }),
    ])
  }

  const populateCategriOptions = () => {
    const categories: Array<string> = []
    props.events.map(item => {
      const itemCategori = item.eventTypeObject?.eventCategory?.name || ""
      if (itemCategori && categories.indexOf(itemCategori) === -1) {
        categories.push(itemCategori)
      }
    })
    categories.sort((a, b) => parseInt(a) - parseInt(b))

    setCatergoriOptions([
      { id: "alle", label: "Vis alle" },
      ...categories.map(item => {
        return { id: item, label: item }
      }),
    ])
  }

  const populateTypeOptions = (eventCategory: string) => {
    const eventTypes = props.events
      .filter(
        item => item.eventTypeObject?.eventCategory?.name === eventCategory
      )
      .map(item => {
        return {
          id: item.eventTypeObject?.name || "",
          label: item.eventTypeObject?.name || "",
        }
      })

    eventTypes.sort((a, b) => parseInt(a.id) - parseInt(b.id))

    const uniqueEventTypes = eventTypes.filter(
      (item, index, arr) =>
        arr.findIndex(item2 => item2.id === item.id) === index
    )

    setTypeOptions([{ id: "alle", label: "Vis alle" }, ...uniqueEventTypes])
  }

  // populate phase options
  useEffect(() => {
    const populatePhaseOptions = () => {
      const availibleEventIds = localFilteredEvents.map(
        item => item.readableEventId
      )

      const availibleSessions =
        props.session?.filter(
          item =>
            availibleEventIds.findIndex(
              eventId => eventId === item.readableEventId
            ) !== -1
        ) || []

      const availiblePhases = availibleSessions
        .flatMap(item =>
          item.session.map(
            session => session.customFields?.sessionNo?.toString() || ""
          )
        )
        .filter((value, index, self) => self.indexOf(value) === index)
        .sort((a, b) => Number(a) - Number(b))

      setPhaseOptions([
        { id: "alle", label: "Vis alle" },
        ...availiblePhases.map(item => {
          return { id: item, label: item }
        }),
      ])
    }

    populatePhaseOptions()
  }, [localFilteredEvents, props.session, eventType, eventCategori])

  const handleMonthChange = (e: string) => {
    setMonth(e)
    if (year === "alle" && e !== "alle")
      setYear(new Date().getFullYear().toString())
  }

  const handleSessionMonthChange = (e: string) => {
    setSessionMonth(e)
    if (sessionYear === "alle" && e !== "alle")
      setSessionYear(new Date().getFullYear().toString())
  }

  const handleYearChange = (e: string) => {
    setYear(e)

    if (e === new Date().getFullYear().toString()) {
      if (parseInt(month || "alle") < new Date().getMonth()) setMonth("alle")
    } else if (e === "alle") setMonth("alle")
  }

  const handleSessionYearChange = (e: string) => {
    setSessionYear(e)

    if (e === new Date().getFullYear().toString()) {
      if (parseInt(month || "alle") < new Date().getMonth()) setMonth("alle")
    } else if (e === "alle") setSessionMonth("alle")
  }

  const handleEventCategoriChange = (e: string) => {
    populateTypeOptions(e)

    setEventType("alle")
    setSessionPhase("alle")
    setEventCategori(e)

    handlePhaseChange("alle")

    if (props.setCategoriFilter) {
      props.setCategoriFilter(e)
    }

    // if (props.setPhaseFilter) {
    //   props.setPhaseFilter("alle")
    // }
  }

  const handleEventTypeChange = (e: string) => {
    setSessionPhase("alle")
    handlePhaseChange("alle")
    // if (props.setPhaseFilter) {
    //   props.setPhaseFilter("alle")
    // }
    setEventType(e)
  }

  const handleCityChange = (e: string) => {
    setCity(e)
  }

  const handleSessionCityChange = (e: string) => {
    setSessionCity(e)
  }

  const handlePhaseChange = (e: string) => {
    setSessionPhase(e)
    if (e !== "alle") {
      // if (year !== "alle") setSessionYear(year)
      // if (month !== "alle") setSessionMonth(month)
      setYear("alle")
      setMonth("alle")
      setCity("alle")
      setSearchText("")
    } else {
      setSessionCity("alle")
      setSessionMonth("alle")
      setSessionYear("alle")
      setSessionSearchText("")
    }
    // if (props.setPhaseFilter) {
    //   props.setPhaseFilter(e)
    // }
  }

  const resetFilter = () => {
    setSessionPhase("alle")
    setEventType("alle")
    setEventCategori("alle")
    setMonth("alle")
    setYear("alle")
    setCity("alle")
    setSessionMonth("alle")
    setSessionYear("alle")
    setSessionCity("alle")
    setSearchText("")
    setSessionSearchText("")
    populateCategriOptions()

    if (props.setCategoriFilter) {
      props.setCategoriFilter("alle")
    }
    // if (props.setPhaseFilter) {
    //   props.setPhaseFilter("alle")
    // }
  }

  return (
    <section className="mb-8">
      {sessionPhase === "alle" ? (
        <div className="flex justify-center">
          <div className="space-x-4 max-w-3xl w-full flex justify-center">
            <div className="relative w-full z-40">
              <label htmlFor="">Måned:</label>
              <DropDown
                options={monthOptions}
                setParentValue={handleMonthChange}
                parentValue={
                  monthOptions.find(item => item.id === month)?.label
                }
                defaultParent="Vis alle"
              />
            </div>
            <div className="relative w-full z-40">
              <label htmlFor="">År:</label>
              <DropDown
                options={yearOptions}
                setParentValue={handleYearChange}
                parentValue={yearOptions.find(item => item.id === year)?.label}
                defaultParent="Vis alle"
              />
            </div>
            <div className="relative w-full z-40">
              <label htmlFor="">By:</label>
              <DropDown
                options={cityOptions}
                setParentValue={handleCityChange}
                parentValue={cityOptions.find(item => item.id === city)?.label}
                defaultParent="Vis alle"
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="flex justify-center">
          <div className="space-x-4 max-w-3xl w-full flex justify-center">
            <div className="relative w-full z-40">
              <label htmlFor="">Måned:</label>
              <DropDown
                options={sessionMonthOptions}
                setParentValue={handleSessionMonthChange}
                parentValue={
                  sessionMonthOptions.find(item => item.id === sessionMonth)
                    ?.label
                }
                defaultParent="Vis alle"
              />
            </div>
            <div className="relative w-full z-40">
              <label htmlFor="">År:</label>
              <DropDown
                options={sessionYearOptions}
                setParentValue={handleSessionYearChange}
                parentValue={
                  sessionYearOptions.find(item => item.id === sessionYear)
                    ?.label
                }
                defaultParent="Vis alle"
              />
            </div>
            <div className="relative w-full z-40">
              <label htmlFor="">By:</label>
              <DropDown
                options={sessionCityOptions}
                setParentValue={handleSessionCityChange}
                parentValue={
                  sessionCityOptions.find(item => item.id === sessionCity)
                    ?.label
                }
                defaultParent="Vis alle"
              />
            </div>
          </div>
        </div>
      )}
      {/* <div className="flex justify-center my-6">
        <div className="relative w-full flex flex-col max-w-3xl justify-center z-30">
          <label htmlFor="">By:</label>
          <DropDown
            options={cityOptions}
            parentValue={cityOptions.find(item => item.id === city)?.label}
            setParentValue={handleCityChange}
            defaultParent="Vis alle"
          />
        </div>
      </div> */}
      <div className="flex justify-center my-6">
        <div className="relative w-full flex flex-col max-w-3xl justify-center z-30">
          <label htmlFor="">Kurs kategori:</label>
          <DropDown
            options={catergoriOptions}
            parentValue={
              catergoriOptions.find(item => item.label === eventCategori)?.label
            }
            setParentValue={handleEventCategoriChange}
            defaultParent={
              (eventCategori === "alle" ? "Vis alle" : eventCategori) ||
              undefined
            }
          />
        </div>
      </div>
      {eventCategori !== "alle" && (
        <div className="flex justify-center my-6">
          <div className="relative w-full flex flex-col max-w-3xl justify-center z-20">
            <label htmlFor="">Kurs type:</label>
            <DropDown
              options={typeOptions}
              parentValue={
                typeOptions.find(item => item.id === eventType)?.label
              }
              setParentValue={handleEventTypeChange}
              defaultParent={
                (eventType === "alle" ? "Vis alle" : eventType) || undefined
              }
            />
          </div>
        </div>
      )}
      {eventCategori !== "alle" && ( //phaseOptions.length > 2 && (
        <div className="flex justify-center my-6">
          <div className="relative w-full flex flex-col max-w-3xl justify-center z-10">
            <label htmlFor="">Kurs/Samlingsnr:</label>
            <DropDown
              options={phaseOptions}
              setParentValue={handlePhaseChange}
              parentValue={
                phaseOptions.find(item => item.id === sessionPhase)?.label
              }
              defaultParent={
                sessionPhase === "alle" ? "Vis alle" : sessionPhase || undefined
              }
            />
          </div>
        </div>
      )}
      {sessionPhase === "alle" ? (
        <div className="flex justify-center mt-6">
          <div className="relative w-full flex flex-col max-w-3xl justify-center z-0">
            <label htmlFor="">Søk:</label>
            <input
              type="text"
              onChange={e => setSearchText(e.target.value)}
              value={searchText || ""}
            />
          </div>
        </div>
      ) : (
        <div className="flex justify-center mt-6">
          <div className="relative w-full flex flex-col max-w-3xl justify-center z-0">
            <label htmlFor="">Søk:</label>
            <input
              type="text"
              onChange={e => setSessionSearchText(e.target.value)}
              value={sessionSearchText || ""}
            />
          </div>
        </div>
      )}
      <div className="flex justify-center">
        <div className="w-full max-w-3xl">
          <button className="text-blue-700 underline" onClick={resetFilter}>
            Tilbakestill filter
          </button>
        </div>
      </div>
    </section>
  )
}

const monthOptionsData: Array<{
  id: string
  label: string
  disabled?: boolean
}> = [
  { id: "alle", label: "Vis alle", disabled: false },
  { id: "0", label: "Januar", disabled: false },
  { id: "1", label: "Februar", disabled: false },
  { id: "2", label: "Mars", disabled: false },
  { id: "3", label: "April", disabled: false },
  { id: "4", label: "Mai", disabled: false },
  { id: "5", label: "Juni", disabled: false },
  { id: "6", label: "Juli", disabled: false },
  { id: "7", label: "August", disabled: false },
  { id: "8", label: "September", disabled: false },
  { id: "9", label: "Oktober", disabled: false },
  { id: "10", label: "November", disabled: false },
  { id: "11", label: "Desember", disabled: false },
]

const cityOptionsData: Array<{
  id: string
  label: string
  disabled?: boolean
}> = [
  { id: "alle", label: "Vis alle", disabled: false },
  { id: "292460000", label: "Bergen", disabled: false }, // value = 292460000
  { id: "292460001", label: "Gardermoen", disabled: false }, // value = 292460001
  { id: "292460002", label: "Oslo", disabled: false }, // value = 292460002
  { id: "292460003", label: "Trondheim", disabled: false }, // value = 292460003
]

export default FilterBy
