import { useAccount, useMsal } from "@azure/msal-react"
import React, { useState } from "react"
import { useMutation } from "react-query"
import { postGetDocumentApi } from "../../../Utils/Api.utils"
import HeaderText from "../../common/text/Header"
import DocumentList from "../document/DocumentList"
import FileUpload from "../document/FileUpload"

const EventDocuments = props => {
  const [documentsDownLoad, setDocumentsDownLoad] = useState([])
  const [documents, setDocuments] = useState([])
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})

  const event = props.event

  const mutationGetDocuments = useMutation(
    () => {
      return postGetDocumentApi(
        process.env.GATSBY_API_URL + "/Documents/Regarding/Get",
        {
          EventId: event?.id,
        },
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: data => {
        setDocumentsDownLoad(data)
      },
      onError: _error => { },
      onSettled: () => { },
    }
  )
  return (
    <div>
      {" "}
      <div className="mt-6">
        <HeaderText text={"Kursmateriell"} />
        <p className="mt-2 text-sm text-gray-600"></p>
        {/* <DocumentList
          documents={documentsDownLoad}
          mutationGetDocuments={mutationGetDocuments}
          canDelete={true}
        /> */}
        <p className="mt-2 text-sm text-gray-600"></p>
        <FileUpload
          entityId={event?.id}
          entityName={"msevtmgt_event"}
          entityId2={null}
          entityName2={null}
          entityId3={null}
          entityName3={null}
          mutationGetDocuments={mutationGetDocuments}
          setDocuments={setDocuments}
          isRequired={false}
          isReturn={false}
        />
      </div>
    </div>
  )
}

export default EventDocuments
