import { InteractionType } from "@azure/msal-browser"
import {
  MsalAuthenticationTemplate,
  useAccount,
  useMsal,
} from "@azure/msal-react"
import axios from "axios"
import { Link, navigate } from "gatsby"
import React, { useEffect, useState } from "react"
import { registerLocale } from "react-datepicker"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { toast } from "react-toastify"
import {
  fetchApi,
  patchApi,
  patchApiNoObject,
  postApi,
  postGetDocumentApi,
} from "../../../Utils/Api.utils"
import { Loading } from "../../../Utils/Loading"
import { ErrorComponent } from "../../common/graphichs/ErrorComponent"
import LoadingData from "../../common/graphichs/LoadingData"
import { classNames } from "../../common/graphichs/misc"
import QuestionMark from "../../common/graphichs/QuestionMark"
import MyPageLayout from "../../common/layouts/myPageLayout"
import HoverTextBox from "../../common/misc/HoverTextBox"
import HoverTextBoxNoIcon from "../../common/misc/HoverTextBoxNoIcon"
import HeaderText from "../../common/text/Header"
import HelperText from "../../common/text/HelperText"
import DocumentList from "../../mypagecomponents/document/DocumentList"
import {
  CheckinAdmin,
  EventRegistration,
} from "../../mypagecomponents/event/EventType"
import EventDocuments from "../../mypagecomponents/eventAdmin/EventDocuments"
import { loginRequest } from "../../security/authConfig"

const EventRegistrationListContent = props => {
  const event = props.props
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})

  const [documents, setDocuments] = useState([])

  const [access, setAccess] = useState<boolean>(false)
  const [showLoadingData, setShowLoadingData] = useState<boolean>(false)
  const [detailsMissing, setDetailsMissing] = useState<boolean>(false)
  const [disableButton, setDisableButton] = useState<boolean>(false)
  const [eventRegistrations, setEventRegistrations] = useState<
    EventRegistration[]
  >([])

  const [disableCheck, setDisableCheck] = useState<boolean>(false)

  const [checkins, setCheckins] = useState<CheckinAdmin[]>([])

  const queryClient = useQueryClient()

  useEffect(() => {
    const today = new Date()

    if (new Date(event?.endDate) < today) {
      const diffDays = (today, end) =>
        Math.ceil(Math.abs(end - today) / (1000 * 60 * 60 * 24))

      const difference = diffDays(today, new Date(event?.endDate))

      // if session was more than three months ago
      if (difference > 90) {
        setAccess(false)
      } else {
        setAccess(true)
      }
    } else setAccess(true)
  }, [event?.startDate, event?.endDate])

  const eventQuery = useQuery(
    "eventInfo",
    async () =>
      fetchApi(
        process.env.GATSBY_API_URL + `/EventAdmin/singleEvent/${event?.id}`,
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: data => {},
      onError: _error => {},
    }
  )

  const mutationGetDocument = useMutation(
    () => {
      return postGetDocumentApi(
        process.env.GATSBY_API_URL + "/Documents/NamedUsers/Get",
        {
          EventId: event.id,
        },
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: data => {
        setDocuments(data)
      },
      onError: _error => {},
      onSettled: () => {},
    }
  )

  const [isLoading, setIsLoading] = useState<boolean>()

  const eventRegistrationsQuery = useQuery(
    "eventRegistrations",
    async () =>
      fetchApi(
        process.env.GATSBY_API_URL +
          `/EventAdmin/eventRegistrations?id=${event?.id}`,
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: data => {
        setEventRegistrations(data)
      },
    }
  )

  const checkinQuery = useQuery(
    "eventCheckins",
    async () =>
      fetchApi(
        process.env.GATSBY_API_URL + `/EventAdmin/${event?.id}/EventCheckin`,
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: data => {
        setCheckins(data)
        if (
          data?.findIndex(
            i => i.checkinStatus == 778380001 && i.absenceDetails == null
          ) > -1
        ) {
          setDetailsMissing(true)
        }
        // setShowLoadingData(false)
      },
    }
  )

  const checkinCompleted = useMutation(
    async () => {
      return patchApi(
        process.env.GATSBY_API_URL + `/EventAdmin/eventCheckinOk/${event?.id}`,
        {},
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: data => {
        // queryClient.invalidateQueries("eventCheckins")
        // queryClient.invalidateQueries("eventRegistrations")
        // setIsLoading(false)
        toast.success("Oppmøteregistreringen er fullført.", {
          position: "top-center",
          autoClose: 5000,
        })
        window.setTimeout(() => {
          window.location.reload()
        }, 4000)
        // refreshPage()
        // setDisableCheck(true)
      },
      onError: _error => {
        setIsLoading(false)
      },
      onSettled: () => {},
    }
  )

  useEffect(() => {
    setIsLoading(!eventRegistrationsQuery.isSuccess || !checkinQuery.isSuccess)
    mutationGetDocument.mutate()
  }, [eventRegistrationsQuery.isSuccess, checkinQuery.isSuccess])

  const [contactList, setContactList] = useState<string[]>([])
  const [contactListPartially, setContactListPartially] = useState<string[]>([])

  const updateList = event => {
    if (event.target.checked) {
      setContactList(list => [...list, event?.target?.value])
    } else {
      setContactList(contactList.filter(id => id != event?.target?.value))
    }
  }

  const updateListPartially = event => {
    if (event.target.checked) {
      setContactListPartially(list => [...list, event?.target?.value])
    } else {
      setContactListPartially(
        contactListPartially.filter(id => id != event?.target?.value)
      )
    }
  }

  const finished = () => {
    if (detailsMissing) {
      toast.warning("Fraværsdetaljer mangler!", {
        position: "top-center",
        autoClose: 10000,
      })
      return
    }
    setIsLoading(true)

    checkinCompleted.mutate()
  }

  const mutationPostCheckin = useMutation(
    data => {
      return postApi(
        process.env.GATSBY_API_URL + `/EventAdmin/checkin`,
        data,
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: data => {
        // refreshPage()
        toast.success("Oppmøtet er nå registrert!", {
          position: "top-center",
          autoClose: 5000,
        })
        window.setTimeout(() => {
          queryClient.invalidateQueries("eventCheckins")
          setShowLoadingData(false)
          setDisableButton(false)
        }, 3000)
        // refetch()
      },
      onError: _error => {},
      onSettled: () => {},
    }
  )

  const refreshPage = () => {
    setShowLoadingData(false)
    setDisableButton(false)
    window.setTimeout(() => {
      window.location.reload()
    }, 1000)
  }

  const postCheckin = () => {
    let checkinList: CheckinAdmin[] = []
    if (contactList.length > 0) {
      setDisableButton(true)
      contactList.forEach(contact => {
        const index = eventRegistrations?.findIndex(
          i => i.contactid === contact
        )
        if (index > -1) {
          setShowLoadingData(true)
          const registration = eventRegistrations[index]
          const data: CheckinAdmin = {
            eventRegistrationId: registration?.eventregistrationid,
            checkinType: 100000001,
            // Oppmøtt
            checkinStatus: 778380000,
            amountHours: event?.amountHours,
          }

          checkinList.push(data)
        }
      })
    }
    if (contactListPartially.length > 0) {
      setDisableButton(true)
      contactListPartially.forEach(contact => {
        const index = eventRegistrations?.findIndex(
          i => i.contactid === contact
        )
        if (index > -1) {
          setShowLoadingData(true)
          const registration = eventRegistrations[index]
          const data: CheckinAdmin = {
            eventRegistrationId: registration?.eventregistrationid,
            checkinType: 100000001,
            // Delvis oppmøtt
            checkinStatus: 778380001,
            amountHours: event?.amountHours,
          }

          checkinList.push(data)
          // postApi(
          //   process.env.GATSBY_API_URL + `/EventAdmin/singleCheckin`,
          //   data,
          //   account,
          //   inProgress,
          //   instance
          // ).then(response => {
          //   // window.setTimeout(() => {
          //   //   window.location.reload()
          //   // }, 1500)
          // })

          // mutationPostCheckin.mutate(data)
          // console.log("Counter fra delvis loop: " + counter)
        }
      })
    }

    if (checkinList.length === contactList.length + contactListPartially.length)
      mutationPostCheckin.mutate(checkinList)
    // if (contactList.length > 0 || contactListPartially.length > 0) {
    //   checkinCompleted.mutate()
    // }
  }

  return isLoading ? (
    <LoadingData />
  ) : showLoadingData ? (
    <LoadingData subject="Oppretter" />
  ) : (
    <MyPageLayout props="Deltagerliste">
      <HeaderText text={event?.name} />

      <div className="mt-8 mx-1 flex flex-col">
        {access ? (
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <HelperText
                helpertext={`Fyll først ut for oppmøtt/delvis oppmøtt. De som ikke får kryss på
        oppmøtt eller delvis oppmøtt blir regnet som ikke oppmøtt. Når du har
        fylt ut oppmøtt/delvis oppmøtt på alle som har møtt klikker du på
        knappen nederst, "Registrer oppmøte"`}
              />
              <HelperText
                helpertext={`Deretter må du klikke på hver
        enkelt som er delvis oppmøtt og registrere riktig antall timer og
        fraværsdetaljer. Det er også mulig å endre mellom oppmøtt/delvis oppmøtt
        hvis du krysset av feil i første omgang, da må du klikke deg inn på den enkelte og gjøre endringen. Når du har fullført dette
        klikker du til slutt på knappen "Fullfør oppmøte". Merk at
        du ikke kan gjøre endringer etter å ha fullført.`}
              />
              <div className="mt-4 overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-meny-700 text-meny-50">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-6"
                      >
                        Navn
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm table1:hidden font-semibold"
                      >
                        Primær rolle
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold table2:hidden"
                      >
                        Arbeidsgiver
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold"
                      >
                        Oppmøtestatus
                      </th>
                      {eventQuery?.data?.checkinCompleted ? (
                        <></>
                      ) : (
                        <>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold"
                          >
                            Oppmøtt
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold"
                          >
                            Delvis oppmøtt
                          </th>
                        </>
                      )}
                      {/* <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold"
                      ></th> */}
                    </tr>
                  </thead>

                  {eventRegistrationsQuery?.data?.map(registration => {
                    return (
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {checkins?.findIndex(
                          i => i.contactId === registration?.contactid
                        ) > -1 ? (
                          <tr
                            key={registration?.contactid}
                            className={classNames(
                              !eventQuery?.data?.checkinCompleted
                                ? "hover:cursor-pointer hover:bg-meny-50"
                                : ""
                            )}
                            onClick={e => {
                              if (!eventQuery?.data?.checkinCompleted) {
                                navigate("/app/kurs/administrasjon/rediger", {
                                  state: {
                                    checkin:
                                      checkins[
                                        checkins?.findIndex(
                                          i =>
                                            i.contactId ===
                                            registration?.contactid
                                        )
                                      ],
                                  },
                                })
                              }
                            }}
                          >
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                              {registration?.contact?.firstName}{" "}
                              {registration?.contact?.lastName}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-600 table1:hidden">
                              {registration?.primaryRoleName}
                            </td>
                            {/* {registration?.contact?.mainEmployer?.name !==
                              null ||
                            registration?.contact?.mainEmployer?.name !==
                              undefined ? ( */}
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-600 table2:hidden">
                              {registration?.contact?.mainEmployer?.name}
                            </td>
                            {/* ) : (
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-600 table2:hidden">
                                {registration?.employerName}
                              </td>
                            )} */}
                            <td
                              className={classNames(
                                checkins[
                                  checkins?.findIndex(
                                    i => i.contactId === registration?.contactid
                                  )
                                ]?.checkinStatus == 778380001 &&
                                  checkins[
                                    checkins?.findIndex(
                                      i =>
                                        i.contactId === registration?.contactid
                                    )
                                  ]?.absenceDetails == null
                                  ? "text-red-600"
                                  : "text-gray-600",
                                "whitespace-nowrap px-3 py-4 text-sm "
                              )}
                            >
                              {checkins[
                                checkins?.findIndex(
                                  i => i.contactId === registration?.contactid
                                )
                              ]?.checkinStatus == 778380001 &&
                              checkins[
                                checkins?.findIndex(
                                  i => i.contactId === registration?.contactid
                                )
                              ]?.absenceDetails !== null ? (
                                <HoverTextBoxNoIcon
                                  text={
                                    checkins[
                                      checkins?.findIndex(
                                        i =>
                                          i.contactId ===
                                          registration?.contactid
                                      )
                                    ]?.absenceDetails
                                  }
                                  row={checkins[
                                    checkins?.findIndex(
                                      i =>
                                        i.contactId === registration?.contactid
                                    )
                                  ]?.checkinStatusName?.replaceAll("_", " ")}
                                />
                              ) : (
                                <>
                                  {checkins[
                                    checkins?.findIndex(
                                      i =>
                                        i.contactId === registration?.contactid
                                    )
                                  ]?.checkinStatusName?.replaceAll("_", " ")}
                                </>
                              )}
                            </td>
                            {eventQuery?.data?.checkinCompleted ? (
                              <></>
                            ) : (
                              <>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-600 ">
                                  <input
                                    id={registration?.contactid}
                                    onChange={updateList}
                                    value={registration}
                                    checked={
                                      checkins[
                                        checkins?.findIndex(
                                          i =>
                                            i.contactId ===
                                            registration?.contactid
                                        )
                                      ]?.checkinStatus == 778380000 ||
                                      contactList?.includes(
                                        registration?.contactid
                                      )
                                    }
                                    disabled={true}
                                    type="checkbox"
                                    className="focus:ring-meny-700 h-4 w-4 text-gray-600 border-gray-300 rounded bg-gray-100"
                                  />
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-600 ">
                                  <input
                                    id={"do" + registration?.contactid}
                                    onChange={updateListPartially}
                                    value={registration?.contactid}
                                    checked={
                                      checkins[
                                        checkins?.findIndex(
                                          i =>
                                            i.contactId ===
                                            registration?.contactid
                                        )
                                      ]?.checkinStatus == 778380001 ||
                                      contactListPartially?.includes(
                                        registration?.contactid
                                      )
                                    }
                                    disabled={true}
                                    type="checkbox"
                                    className="focus:ring-meny-700 h-4 w-4 text-gray-600 border-gray-300 rounded bg-gray-100"
                                  />
                                </td>
                              </>
                            )}
                            {/* {checkinQuery.data[
                              checkinQuery?.data?.findIndex(
                                i => i.contactId === registration?.contactid
                              )
                            ]?.checkinStatus == 778380001 &&
                            checkinQuery.data[
                              checkinQuery?.data?.findIndex(
                                i => i.contactId === registration?.contactid
                              )
                            ]?.absenceDetails == null ? (
                              <>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-red-500 sm:hidden">
                                  Mangler
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-red-500 mob:hidden">
                                  Fraværsdetaljer mangler
                                </td>
                              </>
                            ) : (
                              <td></td>
                            )} */}
                          </tr>
                        ) : (
                          <tr
                            key={registration?.contactid}
                            // className="
                          >
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                              {registration?.contact?.firstName}{" "}
                              {registration?.contact?.lastName}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-600 table1:hidden">
                              {registration?.primaryRoleName}
                            </td>
                            {/* {registration?.contact?.mainEmployer?.name !==
                              null ||
                            registration?.contact?.mainEmployer?.name !==
                              undefined ? ( */}
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-600 table2:hidden">
                              {registration?.contact?.mainEmployer?.name}
                            </td>
                            {/* ) : (
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-600 table2:hidden">
                                {registration?.employerName}
                              </td>
                            )} */}
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-600">
                              Ikke oppmøtt
                            </td>
                            {eventQuery?.data?.checkinCompleted ? (
                              <></>
                            ) : (
                              <>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-600 ">
                                  <input
                                    id={"o" + registration?.contactid}
                                    onChange={updateList}
                                    value={registration.contactid}
                                    defaultChecked={false}
                                    disabled={
                                      contactListPartially.findIndex(
                                        i => i === registration?.contactid
                                      ) > -1
                                    }
                                    type="checkbox"
                                    className="focus:ring-meny-700 h-4 w-4 text-meny-600 border-gray-300 rounded "
                                  />
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-600 ">
                                  <input
                                    id={"do" + registration?.contactid}
                                    onChange={updateListPartially}
                                    value={registration?.contactid}
                                    defaultChecked={false}
                                    disabled={
                                      contactList.findIndex(
                                        i => i === registration?.contactid
                                      ) > -1
                                    }
                                    type="checkbox"
                                    className="focus:ring-meny-700 h-4 w-4 text-meny-600 border-gray-300 rounded"
                                  />
                                </td>
                              </>
                            )}
                            <td></td>
                          </tr>
                        )}
                      </tbody>
                    )
                  })}
                </table>
              </div>

              {/* <div className="mt-6 flex justify-center"> */}
              {eventQuery?.data?.checkinCompleted ? (
                <div className="mt-4 flex justify-center">
                  <HelperText
                    helpertext={"Oppmøteregistreringen er fullført."}
                  />
                </div>
              ) : (
                <>
                  <div className="mt-6 flex justify-center">
                    {/* // <div className="sm:grid sm:grid-cols-4 gap-3 mb-4">
                    // <div className="sm:col-span-1"> */}
                    <button
                      onClick={postCheckin}
                      disabled={disableButton}
                      className="inline-flex justify-end py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-md text-white bg-meny-700 hover:bg-meny-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
                    >
                      Registrer oppmøte
                    </button>
                  </div>
                  {/* // </div>
                    // <div className="sm:col-span-1 sm:col-start-4 mr-0 mt-2"> */}
                  {checkins?.length > 0 ? (
                    <div className="mt-4 flex justify-center">
                      <button
                        onClick={finished}
                        className="inline-flex justify-end py-2 px-10 border border-transparent shadow-sm text-md font-medium rounded-md text-white bg-green-700 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-700"
                      >
                        Fullfør oppmøte
                      </button>
                    </div>
                  ) : (
                    <></>
                  )}
                  {/* </div>
                    <div />
                   </div> */}
                </>
              )}
            </div>
          </div>
        ) : (
          // </div>
          <></>
        )}
        <div className="mt-4">
          <HeaderText text="Vedlegg" />
          <DocumentList
            documents={documents}
            mutationGetDocuments={mutationGetDocument}
            canDelete={false}
          />

          <EventDocuments event={event} />
        </div>
        <div className="flex justify-center">
          <p className="mt-3 max-w-md mx-auto text-base text-gray-600 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
            Gå tilbake til oversikten over{" "}
            <Link
              to="/app/kurs/administrasjon"
              className="text-meny-700 font-bold hover:text-meny-600"
            >
              mine kurs
            </Link>
            .
          </p>
        </div>
      </div>
    </MyPageLayout>
  )
}

const EventRegistrationList = ({ location }) => {
  const authRequest = {
    ...loginRequest,
  }
  const event = location?.state?.event
  // console.log(location.state)
  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      <EventRegistrationListContent props={event} />
    </MsalAuthenticationTemplate>
  )
}

export default EventRegistrationList
