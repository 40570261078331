import React from "react"
import InnmeldingProvider from "../../mypagecomponents/event/innmelding/dataStorage"
import InnmeldingSection from "../../mypagecomponents/event/innmelding/InnmeldingSection"
import MyPageLayout from "../../common/layouts/myPageLayout"

const Registration = () => {
  return (
    <MyPageLayout props="Kurspåmelding">
      <InnmeldingProvider>
        <InnmeldingSection />
      </InnmeldingProvider>
    </MyPageLayout>
  )
}

export default Registration
