import { useAccount, useMsal } from "@azure/msal-react"
import React, { useEffect, useState } from "react"
import { useMutation, useQueryClient } from "react-query"
import { toast } from "react-toastify"
import { cyanButton } from "../../../styles/tailwindClasses"
import { patchApi, postApi, postGetDocumentApi } from "../../../Utils/Api.utils"
import { Incidents, SpecialistProgram } from "../../../Utils/Types"
import GetHelpText from "../../common/text/GetHelpText"
import HelperText from "../../common/text/HelperText"
import DocumentList from "../document/DocumentList"
import FileUpload from "../document/FileUpload"
import PracticeCertificate from "./activities/PracticeCertificate"
import PracticeCertificateDocumentsUpload from "./PracticeCertificateDocumentsUpload"

const NewPracticeCertificateActivity = ({
  program,
  setFirstStep,
  setSecondStep,
  setDisableActivitySelect,
  setShowLoadingData,
}) => {
  const queryClient = useQueryClient()
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  // const [specialityActivityId, setSpecialityActivityId] = useState("")
  // const program: SpecialistProgram = props.program
  const [showDocumentComponent, setShowDocumentComponent] =
    useState<boolean>(false)

  const [success, setIsSuccess] = useState<boolean>(false)

  const educationId = program.specialistEducation?.id

  type progActInc = {
    programId: string
    activityId: string
    incidentId: string
  }

  const [listOfIds, setListOfIds] = useState<progActInc[]>([])

  useEffect(() => {
    if (success) {
      setFirstStep("complete")
      setSecondStep("current")
    }
  }, [success])

  // setIncidentTitle("Godkjenning av praksistimer for spesialistutdanning")

  // Add all new activityids to array
  // on document upload --> add document and incident for all new activityIds and update state for activities (max 3)
  return (
    <div className="mt-5 md:mt-0 md:col-span-2">
      {!showDocumentComponent ? (
        <PracticeCertificate
          program={program}
          setShowDocumentComponent={setShowDocumentComponent}
          setListOfIds={setListOfIds}
          setDisableActivityDeselect={setDisableActivitySelect}
          setSuccess={setIsSuccess}
          // mutationCreateIncident={mutationCreateIncident}
          // mutationCreateActivity={mutationCreateActivity}
          // mutationCreatePracticeHours,
          // setComment={}
          // setIncidentTitle,
          setShowLoadingData={setShowLoadingData}
        />
      ) : null}
      {showDocumentComponent ? (
        <PracticeCertificateDocumentsUpload
          // mutationUpdateSpesActivity={mutationUpdateSpesActivity}
          listOfIds={listOfIds}
          educationId={educationId}
          program={program}
          setShowLoadingData={setShowLoadingData}
        />
      ) : null}
    </div>
  )
}

export default NewPracticeCertificateActivity
