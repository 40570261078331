import {
  ActivityTypeEnum,
  ApproveStatusEnum,
  ConsultingsCyclePhaseEnum,
  EducationTypes,
  Levels,
  MaintenanceActivityTypeEnum,
  ReasonDispensationEnum,
  SpecialistEducationStatusEnum,
  SpecialistProgramApproveStatusEnum,
  StateEnum,
  SupervisorIsSpecialistEnum,
  TypePracticeEnum,
  TypeWrittenReportEnum,
} from "./Enums"

export type FormValues = {
  id: string
  firstName: string
  lastName: string
  socialSecurityNumber: string
  emailAddress1: string
  gender: number
  customerTypeCode: number
  hprNumber: string
  address1Line1: string
  address1Line2: string
  address1Line3: string
  address1City: string
  address1PostalCode: string
  address1CountryCode: string
  description: string
  retired: boolean
  reasonUnsub: string
  caseTypeCode: number
  missingNationalID: boolean
}

export type FormVal = {
  id: string
  firstName: string
  lastName: string
  emailAddress1: string
  gender: number
  customerTypeCode: number
  hprNumber: string
  address1Line1: string
  address1Line2: string
  address1Line3: string
  address1City: string
  address1PostalCode: string
  address1CountryCode: string
  placeOfEducation: string
  alternativePlaceOfEducation: string
}

export type ConsentValue = {
  id: string
  description: string
  handlingType: boolean
  name: string
  myConsent: boolean
}

export type Countries = {
  countryName: string
  countryCode: string
}

export type TimeLineCommentVal = {
  id: string
  title: string
  message: string
}

export type NewIncidentVal = {
  id: string
  title: string
  description: string
  caseTypeCode: number
}

export type Contact = {
  id?: string
  firstName?: string
  lastName?: string
  emailAddress1?: string
  mobilePhone?: string
  telephone1?: string
  gender?: Genders
  genderName?: string
  customerTypeCode?: CustomerTypes
  customerTypeName?: string
  hprNumber?: string
  socialSecurityNumber?: string
  updatedOnWeb?: string
  address1Line1?: string
  address1Line2?: string
  address1Line3?: string
  address1City?: string
  address1PostalCode?: string
  address1CountryName?: string
  address1CountryCode?: string
  address1CountryCodeId?: string
  address1Country?: Country
  address1ZipCodeId?: string
  address1ZipCode?: ZipCode
  activeMemebershipId?: string
  mainMembership?: Subscription
  mainMembershipCategoryId?: string
  mainMembershipCategoryName?: string
  nonEmployed?: boolean
  licenseStartDate?: Date
  description: string
  courseProvider: string
  erpNumber: string
  accountNo: string
  professionallyActiveStatus: number
}

export type Country = {
  id?: string
  countryName?: string
  countryCode?: string
}

export type ZipCode = {
  id?: string
  postalCode?: string
  postalPlace?: string
}

export type Subscription = {
  id?: string
  subscriptionType?: SubscriptionTypes
  subscriptionTypeName?: string
  customerId?: string
  membershipCategoryId?: string
  parentOrganizationUnitId?: string
  organizationUnitId?: string
  membershipCategory?: MembershipCategory
  parentOrganizationUnit?: OrganizationUnit
  organizationUnit?: OrganizationUnit
  liabilityInsurance?: boolean
  startDate?: string
  endDate?: string
}

export type MembershipCategory = {
  id?: string
  name?: string
}

export type OrganizationUnit = {
  id?: string
  name?: string
}

export enum CustomerTypes {
  Abonnent = 7,
  Medlem = 1,
  Ikke_medlem = 2,
  Potensielt_medlem = 6,
  Under_innmelding = 3,
  Under_utmelding = 4,
  Bedriftskontakt = 5,
}

export enum Genders {
  Kvinne = 125_600_000,
  Mann = 125_600_001,
  Valgfri = 125_600_002,
}

export enum SubscriptionTypes {
  Medlemskap = 778380000,
  Abonnement_tidsskrift = 778380004,
  Ansvarsforsikring = 778380005,
}

export enum PrimaryRole {
  Utvalgsmedlem = 292460000,
  Deltaker = 100000000,
  Journalist = 100000001,
  Sponsor = 100000003,
  Utstiller = 100000004,
  Annet = 100000002,
}

export enum ResponseEnum {
  Godtatt = 292460000,
  Tentativ = 292460001,
  Avslått = 292460002,
}

export type Incidents = {
  Id: string
  Title: string
  TicketNumber: string
  Description: string
  CaseTypeCode: 1
  CaseTypeName: string
  CustomerId: string
  CaseOriginCode: number
  CaseOriginName: string
  ApproveStatus: number
  ApproveStatusName: string
  SubscriptionType: number
  SubscriptionTypeName: string
  OrganizationId: string
  MembershipCategory: string
  InvoiceFrequency: number
  InvoiceFrequencyName: string
  description: string
  WorkSituation: number
  WorkSituationName: string
  State: number
  StateName: string
  Status: string
  StatusName: string
  retired: boolean
  reasonUnsub: string
  courseTitle: string
  courseTheme: string
  courseTargetGroup: string
  courseGoal: string
  courseOutcome: string
  courseContent: string
  courseContactPerson: string
  companyName: string
  orgNr: string
  Email: string
  Address: string
  Responsible: string
  AmountHours: number
}

export interface MyEmployments {
  id: string
  contact: string
  employer: string
  employerAlternative: string
  workPlace: string
  workPlaceAlternative: string
  role: number
  roleName: string
  position: string
  positionAlternative: string
  partTimePosition: number
  startDate: Date
  endDate: Date
  privatePractitioners: boolean
}

export type Employer = {
  id: string
  name: string
  invoiceRecipient: string
}

export type NewValueIncident = {
  Id: string
  Title: string
  TicketNumber: string
  Description: string
  CaseTypeCode: 1
  CaseTypeName: string
  CustomerId: string
  CaseOriginCode: number
  CaseOriginName: string
  ApproveStatus: number
  ApproveStatusName: string
  SubscriptionType: number
  SubscriptionTypeName: string
  OrganizationId: string
  MembershipCategory: string
  InvoiceFrequency: number
  InvoiceFrequencyName: string
  description: string
  WorkSituation: number
  WorkSituationName: string
  State: number
  StateName: string
  Status: string
  StatusName: string
  retired: boolean
  reasonUnsub: string
  membershipId: string
  subscriptionMagazine: boolean
  continueInsurence: boolean
  continueMembership: boolean
}

export type Activity = {
  id?: string
  name?: string
  comment?: string
  supervisorText?: string
  supervisorNo?: string
  maintenanceActivityText?: string
  courseName?: string
  contactId?: string
  specialistProgramId?: string
  practiceTypeId?: string
  specialityId?: string
  educationId?: string
  courseProviderAccountName?: string
  contactIdSupervisor?: string
  supervisorSpeciality?: string
  externalCourse?: string
  approveStatus?: SpecialistProgramApproveStatusEnum
  approveStatusName?: string
  activityType?: ActivityTypeEnum
  maintenanceActivityType?: MaintenanceActivityTypeEnum
  supervisorIsSpecialist?: SupervisorIsSpecialistEnum
  typeWrittenReport?: TypeWrittenReportEnum
  titleWrittenReport?: string
  attachmentCount?: number
  fromDate?: Date
  toDate?: Date
  practiceWeeks?: number // decimal
  amountHours?: number // decimal
  hoursPhysicalGroupGuideance?: number // decimal
  hoursGuidence?: number // decimal
  hoursPhysicalidIvidualGuideance?: number // decimal
  hoursDigitalIdividualGuideance?: number // decimal
  directObservation?: number // decimal
  hoursDigitalGroupGuideance?: number
  sumPractice?: number
  reasonDispensation?: ReasonDispensationEnum
  reasonDispensationText?: string
  superVisorExperience?: number
  reasonForSupervisor?: string
  approvedSupervisorInfo?: string
  approvedSupervisor?: boolean
  supervisorSpecialityText?: string
  status?: SpecialistEducationStatusEnum
  statusName?: string
  stateCode?: StateEnum
  courseProviderAccountId?: string
  practiceHours1?: Array<PracticeHour>
  practiceHours2?: Array<PracticeHour>
  practiceHours3?: Array<PracticeHour>
  typePracticeConsulting?: TypePracticeEnum
  consultingsCyclePhase?: ConsultingsCyclePhaseEnum
  startDate1?: Date
  startDate2?: Date
  startDate3?: Date
  endDate1?: Date
  endDate2?: Date
  endDate3?: Date
}

export type SpecialistProgram = {
  id: string
  name: string
  contactId: string
  specialityId: string
  specializationActivityList: Array<Activity>
  approveStatus: SpecialistProgramApproveStatusEnum
  approveStatusName: string
  stateCode: StateEnum
  statusCode: SpecialistEducationStatusEnum
  requirementsSpecialistEducationId: string
  requirementsSpecialistEducation: RequirementsSpecialistEducation
  fromDate: Date
  toDate: Date
  sumCourseParticipation: number
  sumInternalCourseParticipation: number // decimal
  sumExternalCourses: number // decimal
  sumPracsis: number // decimal
  sumGuidence: number // decimal
  sumHoursDigitalIdividualGuideance: number // decimal
  sumHoursDigitalGroupGuideance: number // decimal
  hoursPhysicalIdividualGuideance: number // decimal
  sumHoursPhysicalGroupGuideance: number // decimal
  sumMaintenanceCourses: number // decimal
  sumMaintenanceOther: number
  sumOwnActivities: number // decimal
  specialistEducation: SpecialistEducation
  programType: {
    id: string
    name: string
  }
}

export type SpecialistEducation = {
  id: string
  name: string
  contactId: string
  specialistProgramList: Array<SpecialistProgram>
  approveStatus: ApproveStatusEnum
  approveStatusName: string
  stateCode: StateEnum
  statusCode: SpecialistEducationStatusEnum
  levelOfEducation: Levels
  levelOfEducationName: string
  educationType: EducationTypes
  educationTypeName: string
  placeOfEducationId: string
  alternativePlaceOfEducation: string
  titleOfThesis: string
  grade: string
  endDateOfEducation: Date
  startDateOfEducation: Date
  endDateOfEducationApproval: Date
  startDateOfEducationApproval: Date
  countryName: string
  countryCode: string
  countryId: string
}

export type RequirementsSpecialistEducation = {
  id: string
  name: string
  textCources: string
  textGuidence: string
  textPractice: string
  courseParticipation: number // decimal
  pracsis: number // decimal
  guidence: number // decimal
  maintenanceCourses: number // decimal
  maintenanceOther: number // decimal
  sumHoursDigitalIdividualGuideance: number // decimal
  sumHoursDigitalGroupGuideance: number // decimal
  hoursPhysicalIdividualGuideance: number // decimal
  sumHoursPhysicalGroupGuideance: number // decimal
  textMaintenance: string
  hoursOwnActivity: number // decimal
  textOwnActivity: string
}

export type PracticeHour = {
  id: string
  activitySpecialistProgramId: string
  name: string
  fromDate: Date
  toDate: Date
  percentagePracticePosition: number // decimal
  diffMonths: number // decimal
  practice: number // decimal
}

export type EuropesyCertificate = {
  id: string
  name: string
  typeOfSertificate: number
  fieldsOfPractice: number
  registrationNumber: string
  approvedFrom: Date
  approvedTo: Date
}

export type WorkPlace = {
  id: string
  name: string
  employer: boolean
  workplace: boolean
  employerAndWorkplace: boolean
}

export type Position = {
  id: string
  jobbTitle: string
}

export type Tariff = {
  name: string
  positions: Array<Position>
}

export type Accounts = {
  id: string
  name: string
  parentEmployerId: string
  workPlaces: WorkPlace
  tariff: Tariff
  employer: boolean
  workplace: boolean
  accountNumber: string
  countryCodeId: string
  address1_Line1: string
  address1_Line2: string
  zipCodeId: string
}
