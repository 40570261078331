import { useQuery } from "react-query"
import { fetchApi } from "../../Utils/Api.utils"
import { useAccount, useMsal } from "@azure/msal-react"

const useApi = <T>(endPoint: string) => {
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})

  const query = useQuery<T>(endPoint, () =>
    fetchApi(
      process.env.GATSBY_API_URL + endPoint,
      account,
      inProgress,
      instance
    ).then(res => res.data)
  )

  return query
}

export default useApi
