import { useAccount, useMsal } from "@azure/msal-react"
import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { fetchApi, postApi } from "../../../../Utils/Api.utils"
import ReactDatePicker from "react-datepicker"
import FileUpload from "../../document/FileUpload"
import { navigate } from "gatsby"
import { Switch } from "@headlessui/react"
import { classNames } from "../../../common/graphichs/misc"
import { ErrorMessage } from "@hookform/error-message"
import { toast } from "react-toastify"
import HelperText from "../../../common/text/HelperText"
import {
  isSpecialistMethods,
  reasonDispensation,
  reasonDispensation1,
  reasonDispensation2,
  whyDispensation,
  YesNoRadio,
} from "../../../../Utils/optionSet"
import { SpecialistProgram, Activity } from "../../../../Utils/Types"

// type ActivityTpe = {
//   program
//   activity
//   mutationUpdateActivity
//   setComment
//   setIncidentTitle
//   setShowLoadingData
//   showActivityButtons
// }

const SupervisorsApprovalEdit = props => {
  const program: SpecialistProgram = props.program
  const activity: Activity = props.activity

  console.log(activity.reasonDispensation)

  console.log(program)
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const [selectedSupervisorSpeciality, SetSelectedSupervisorSpeciality] =
    useState(activity.supervisorSpeciality)
  const [approvedSupervisor, setApprovedSupervisor] = useState(
    activity.approvedSupervisor
  )
  const [ApprovedSupervisorInfo, SetApprovedSupervisorInfo] = useState("")
  const [isSpecialist, setIsSpecialist] = useState<string>(
    activity.supervisorIsSpecialist.toString()
  )
  const [whyReasonDispensation, setWhyReasonDispensation] = useState("0")
  const [reasonDispensation, setReasonDispensation] = useState(
    activity.reasonDispensation.toString() || "0"
  )
  const [superviserSpeciality, setSuperviserSpeciality] = useState<any[]>()
  const [showReasons1, setShowReasons1] = useState<boolean>(false)
  const [showReasons2, setShowReasons2] = useState<boolean>(false)
  const [showReason3, setShowReason3] = useState<boolean>(false)
  const {
    register,
    handleSubmit,
    reset,
    control,
    setError,
    formState: { errors },
  } = useForm()

  useQuery(
    "specialitiesData",
    () =>
      fetchApi(
        process.env.GATSBY_API_URL + "/Specialities/all/active",
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: data => {
        setSuperviserSpeciality(data)
      },
      onError: _error => {},
    }
  )

  useEffect(() => {
    if (
      activity.reasonDispensation === 292460002 ||
      activity.reasonDispensation === 292460003
    ) {
      setShowReasons2(true)
    } else if (
      activity.reasonDispensation === 292460001 ||
      activity.reasonDispensation === 292460000
    ) {
      setShowReasons1(true)
    } else setShowReason3(true)
    //   setDefaultWhyReason
  }, [activity])

  const showReasonDispensation = e => {
    if (e === "3") {
      setReasonDispensation("292460004")
      setShowReasons1(false)
      setShowReasons2(false)
      setShowReason3(true)
    } else if (e === "2") {
      setShowReasons2(true)
      setShowReasons1(false)
      setShowReason3(false)
    } else {
      setShowReasons1(true)
      setShowReasons2(false)
      setShowReason3(false)
    }
    setWhyReasonDispensation(e)
  }

  const showSpeciality = e => {
    setIsSpecialist(e)
    SetSelectedSupervisorSpeciality("0")
  }

  const showApprovedSupervisorInfo = e => {
    if (e === "0") {
      setApprovedSupervisor(false)
      SetApprovedSupervisorInfo("")
    }

    if (e === "1") setApprovedSupervisor(true)
  }

  const OnChangeSupervisorSpeciality = e => SetSelectedSupervisorSpeciality(e)

  const patchActivity = async data => {
    console.log("Kjører patch!")
    // data.approveStatus = 292460000 //Ikke fullført registrering
    data.id = activity.id
    if (!approvedSupervisor) data.approvedSupervisorInfo = ""
    data.supervisorIsSpecialist = isSpecialist

    // replace , with .
    data.superVisorExperience = data.superVisorExperience.replace(",", ".")

    if (
      (whyReasonDispensation === "1" || whyReasonDispensation === "2") &&
      reasonDispensation === "0"
    ) {
      toast.warning("Obligatoriske felt mangler!", {
        position: "top-center",
        autoClose: 10000,
      })
      return
    }
    // if (isSpecialist) {
    if (selectedSupervisorSpeciality === "0" && isSpecialist === "292460000") {
      toast.warning("Obligatoriske felt mangler!", {
        position: "top-center",
        autoClose: 10000,
      })
      return
    }
    data.supervisorIsSpecialist = isSpecialist
    // }
    // else {
    //   toast.warning("Obligatoriske felt mangler!", {
    //     position: "top-center",
    //     autoClose: 10000,
    //   })
    //   return
    // }
    if (reasonDispensation != "0") data.reasonDispensation = reasonDispensation
    if (approvedSupervisor) data.approvedSupervisor = true
    if (selectedSupervisorSpeciality != "0")
      data.supervisorSpeciality = selectedSupervisorSpeciality

    props.setShowLoadingData(true)
    console.log(data)
    props.mutationUpdateActivity.mutate(data)
  }

  return (
    <div>
      <form method="PATCH" onSubmit={handleSubmit(patchActivity)}>
        <div className="grid grid-cols-6 gap-6">
          <div className="col-span-6 sm:col-span-3">
            <label
              htmlFor="amountHours"
              className="block text-sm font-medium text-gray-700"
            >
              Antall timer*
            </label>
            <input
              {...register("amountHours", {
                required: {
                  value: true,
                  message: "Obligatorisk felt.",
                },
                pattern: {
                  value: /^[0-9]+$/,
                  message: "Kun tall er tillatt.",
                },
              })}
              type="text"
              defaultValue={activity.amountHours}
              name="amountHours"
              id="amountHours"
              className="mt-1 focus:ring-meny-700 focus:border-meny-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            />
            <ErrorMessage
              errors={errors}
              name="amountHours"
              render={({ message }) => (
                <p className="text-sm text-red-600">{message}</p>
              )}
            />
          </div>

          <div className="col-span-6 sm:col-span-3">
            <label
              htmlFor="supervisorNo"
              className="block text-sm font-medium text-gray-700"
            >
              Veileders HPRno*
            </label>
            <input
              {...register("supervisorNo", {
                required: {
                  value: true,
                  message: "Obligatorisk felt.",
                },
                pattern: {
                  value: /^[0-9]+$/,
                  message: "Kun tall er tillatt",
                },
                minLength: {
                  value: 7,
                  message: "HPR nr kan kun være mellom 7 og 9 siffer",
                },
                maxLength: {
                  value: 9,
                  message: "HPR nr kan kun være mellom 7 og 9 siffer",
                },
              })}
              type="text"
              name="supervisorNo"
              defaultValue={activity.supervisorNo}
              id="supervisorNo"
              className="mt-1 focus:ring-meny-700 focus:border-meny-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            />
            <ErrorMessage
              errors={errors}
              name="supervisorNo"
              render={({ message }) => (
                <p className="text-sm text-red-600">{message}</p>
              )}
            />
          </div>

          <div className="col-span-6 sm:col-span-3">
            <label
              htmlFor="supervisorText"
              className="block text-sm font-medium text-gray-700"
            >
              Veileders navn*
            </label>
            <input
              {...register("supervisorText", {
                required: { value: true, message: "Obligatorisk felt" },
                maxLength: { value: 100, message: "Maks tegn er 100" },
              })}
              type="text"
              name="supervisorText"
              id="supervisorText"
              defaultValue={activity.supervisorText}
              className="mt-1 focus:ring-meny-700 focus:border-meny-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            />
            <ErrorMessage
              errors={errors}
              name="supervisorText"
              render={({ message }) => (
                <p className="text-sm text-red-600">{message}</p>
              )}
            />
          </div>

          <div className="col-span-6">
            <p
              // htmlFor="employerAlternative"
              className="block text-sm italic m-2 font-normal sm:col-span-2 text-meny-700 sm:mt-px sm:pt-2 sm:col-start-3"
            ></p>

            <div>
              <label className="text-base font-medium text-gray-900">
                Hvorfor søkes det dispensasjon?*
              </label>
              {/* <p className="text-sm leading-5 text-gray-500">Her kommer en hjelpe tekst.</p> */}
              <fieldset className="mt-4">
                <legend className="sr-only">whyDispensation</legend>
                <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                  {/* {whyDispensation.map(whyDispensation => ( */}
                  <>
                    <div key={"1"} className="flex items-center">
                      <input
                        id={"1"}
                        name="whyDispensation"
                        type="radio"
                        onChange={e => showReasonDispensation(e.target.id)}
                        defaultChecked={
                          activity.reasonDispensation === 292460001 ||
                          activity.reasonDispensation === 292460000
                        }
                        className="focus:ring-meny-700 h-4 w-4 text-indigo-600 border-gray-300"
                      />
                      <label
                        htmlFor={"1"}
                        className="ml-3 block text-sm font-medium text-gray-700"
                      >
                        {"Ingen spesialiteter tilgjengelig"}
                      </label>
                    </div>
                    <div key={"2"} className="flex items-center">
                      <input
                        id={"2"}
                        name="whyDispensation"
                        type="radio"
                        onChange={e => showReasonDispensation(e.target.id)}
                        defaultChecked={
                          activity.reasonDispensation === 292460002 ||
                          activity.reasonDispensation === 292460003
                        }
                        className="focus:ring-meny-700 h-4 w-4 text-indigo-600 border-gray-300"
                      />
                      <label
                        htmlFor={"2"}
                        className="ml-3 block text-sm font-medium text-gray-700"
                      >
                        {
                          "Ingen spesialister med riktig spesialitet tilgjengelig"
                        }
                      </label>
                    </div>
                    <div key={"3"} className="flex items-center">
                      <input
                        id={"3"}
                        name="whyDispensation"
                        type="radio"
                        onChange={e => showReasonDispensation(e.target.id)}
                        defaultChecked={
                          activity.reasonDispensation === 292460004
                        }
                        className="focus:ring-meny-700 h-4 w-4 text-indigo-600 border-gray-300"
                      />
                      <label
                        htmlFor={"3"}
                        className="ml-3 block text-sm font-medium text-gray-700"
                      >
                        {"Andre grunner (spesifiser)"}
                      </label>
                    </div>
                    {/* ) : null} */}
                  </>
                  {/* ))} */}
                </div>
              </fieldset>
            </div>
          </div>

          <div className="col-span-6">
            {showReasons1 ? (
              <>
                <fieldset className="mt-4">
                  <legend className="sr-only">Notification method</legend>
                  <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                    {reasonDispensation1.map(reasonDispensation => (
                      <>
                        {reasonDispensation.title != "Ingen" ? (
                          <div
                            key={reasonDispensation.id}
                            className="flex items-center"
                          >
                            <input
                              id={reasonDispensation.id}
                              name="reasonDispensation"
                              type="radio"
                              onChange={e => setReasonDispensation(e.target.id)}
                              defaultChecked={
                                reasonDispensation.id ===
                                activity.reasonDispensation.toString()
                              }
                              className="focus:ring-meny-700 h-4 w-4 text-indigo-600 border-gray-300"
                            />
                            <label
                              htmlFor={reasonDispensation.id}
                              className="ml-3 block text-sm font-medium text-gray-700"
                            >
                              {reasonDispensation.title}
                            </label>
                          </div>
                        ) : null}
                      </>
                    ))}
                  </div>
                </fieldset>
              </>
            ) : null}
          </div>

          <div className="col-span-6">
            {showReasons2 ? (
              <>
                <fieldset className="mt-4">
                  <legend className="sr-only">Notification method</legend>
                  <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                    {reasonDispensation2.map(reasonDispensation => (
                      <>
                        {reasonDispensation.title != "Ingen" ? (
                          <div
                            key={reasonDispensation.id}
                            className="flex items-center"
                          >
                            <input
                              id={reasonDispensation.id}
                              name="reasonDispensation"
                              type="radio"
                              onChange={e => setReasonDispensation(e.target.id)}
                              defaultChecked={
                                reasonDispensation.id ===
                                activity.reasonDispensation.toString()
                              }
                              className="focus:ring-meny-700 h-4 w-4 text-indigo-600 border-gray-300"
                            />
                            <label
                              htmlFor={reasonDispensation.id}
                              className="ml-3 block text-sm font-medium text-gray-700"
                            >
                              {reasonDispensation.title}
                            </label>
                          </div>
                        ) : null}
                      </>
                    ))}
                  </div>
                </fieldset>
              </>
            ) : null}
          </div>

          <div className="col-span-6">
            {showReason3 ? (
              <>
                <label
                  htmlFor="reasonDispensationText"
                  className="block text-sm font-medium text-gray-700"
                >
                  Andre grunner
                </label>
                <div className="mt-1">
                  <textarea
                    {...register("reasonDispensationText", {
                      required: false,
                      maxLength: {
                        value: 250,
                        message: "Maks tegn er 250",
                      },
                    })}
                    id="reasonDispensationText"
                    name="reasonDispensationText"
                    rows={3}
                    className="shadow-sm focus:ring-meny-700 focus:border-meny-500 block w-full sm:text-sm border border-gray-300 rounded-md"
                    placeholder="Spesifiser"
                    defaultValue={activity.reasonDispensationText}
                  />
                </div>
                <HelperText helpertext="Spesifiser" />
                <ErrorMessage
                  errors={errors}
                  name="reasonDispensationText"
                  render={({ message }) => (
                    <p className="text-sm text-red-600">{message}</p>
                  )}
                />
              </>
            ) : null}
          </div>

          <div className="col-span-6 sm:col-span-6">
            <p
              // htmlFor="employerAlternative"
              className="block text-sm italic m-2 font-normal sm:col-span-2 text-meny-700 sm:mt-px sm:pt-2 sm:col-start-3"
            ></p>

            <div>
              <label className="text-base font-medium text-gray-900">
                Er veileder spesialist?*
              </label>
              {/* <p className="text-sm leading-5 text-gray-500">Her kommer en hjelpe tekst.</p> */}
              <fieldset className="mt-4">
                <legend className="sr-only">Notification method</legend>
                <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                  {isSpecialistMethods.map(specialist => (
                    <>
                      {specialist.title != "Ingen" ? (
                        <div key={specialist.id} className="flex items-center">
                          <input
                            id={specialist.id}
                            name="specialist"
                            type="radio"
                            onChange={e => showSpeciality(e.target.id)}
                            defaultChecked={
                              specialist.id ===
                              activity.supervisorIsSpecialist.toString()
                            }
                            className="focus:ring-meny-700 h-4 w-4 text-indigo-600 border-gray-300"
                          />
                          <label
                            htmlFor={specialist.id}
                            className="ml-3 block text-sm font-medium text-gray-700"
                          >
                            {specialist.title}
                          </label>
                        </div>
                      ) : null}
                    </>
                  ))}
                </div>
              </fieldset>
            </div>
          </div>
          <div className="col-span-6 sm:col-span-3">
            {isSpecialist == "292460000" ? (
              <>
                <label
                  htmlFor="courseProviderAccountId"
                  className="block text-sm font-medium text-gray-700"
                >
                  Veileders spesialitet*
                </label>
                <select
                  id="courseProviderAccountId"
                  name="courseProviderAccountId"
                  onChange={e => OnChangeSupervisorSpeciality(e.target.value)}
                  value={selectedSupervisorSpeciality}
                  defaultValue={activity?.supervisorSpeciality}
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-meny-700 focus:border-meny-500 sm:text-sm"
                >
                  <option disabled selected value="0">
                    --velg--
                  </option>

                  {superviserSpeciality?.map(a => (
                    <option key={a.id} value={a.id}>
                      {a.name}
                    </option>
                  ))}
                </select>
              </>
            ) : null}
          </div>

          <div className="col-span-6">
            {isSpecialist == "292460002" ? (
              <>
                <label
                  htmlFor="supervisorSpecialityText"
                  className="block text-sm font-medium text-gray-700"
                >
                  Spesifiser
                </label>
                <div className="mt-1">
                  <textarea
                    {...register("supervisorSpecialityText", {
                      required: false,
                    })}
                    id="supervisorSpecialityText"
                    name="supervisorSpecialityText"
                    rows={3}
                    className="shadow-sm focus:ring-meny-700 focus:border-meny-500 block w-full sm:text-sm border border-gray-300 rounded-md"
                    placeholder="Spesifiser"
                    defaultValue={""}
                  />
                </div>
                <HelperText helpertext="Spesifiser" />
              </>
            ) : null}
          </div>

          <div className="col-span-6">
            {program?.programType?.id ==
              "7a958356-c5a6-ec11-983f-000d3aae55a4" ||
            program?.programType?.id ==
              "ee9bb462-c5a6-ec11-983f-000d3aae55a4" ||
            program?.programType?.id ==
              "e542c068-c5a6-ec11-983f-000d3aae55a4" ? ( // Fellesprogram || Obligatorisk program || Valgfritt program
              <>
                {program?.programType?.id ==
                "7a958356-c5a6-ec11-983f-000d3aae55a4" ? ( // Fellesprogram
                  <label
                    htmlFor="superVisorExperience"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Hvor lang arbeidserfaring har veileder i det praksisfeltet
                    søker arbeider i (ant årsverk)?*
                  </label>
                ) : null}
                {program?.programType?.id ==
                  "ee9bb462-c5a6-ec11-983f-000d3aae55a4" ||
                program.id == "e542c068-c5a6-ec11-983f-000d3aae55a4" ? ( //Obligatorisk program || Valgfritt program
                  <label
                    htmlFor="superVisorExperience"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Hvor lang arbeidserfaring har veileder i praksis godkjent
                    for det aktuelle obligatoriske eller valgfrie programmet
                    (ant årsverk)?*
                  </label>
                ) : null}
                <div className="mt-1">
                  <input
                    {...register("superVisorExperience", {
                      required: {
                        value: true,
                        message: "Obligatorisk felt.",
                      },
                      pattern: {
                        // value: /^\d{1,}([\.\,]\d{0,1})?$/,
                        // value: /^([0-9]|[1-4]\d)([\.\,]\d{0,1})?$/,
                        value:
                          /^(?=.)(?:(?:(?:0|[1-4]?\d)?(?:[,.]\d{1})?)|50(?:[.,]0?)?)?$/,
                        message:
                          "Oppgitt antall år 0-50 med en desimal er tillatt.",
                      },
                      // minLength: {
                      //   value: 0,
                      //   message: "Oppgitt antall år 0-50",
                      // },
                      // maxLength: {
                      //   value: 2,
                      //   message: "Oppgitt antall år 0-50",
                      // },
                    })}
                    type="text"
                    name="superVisorExperience"
                    id="superVisorExperience"
                    defaultValue={activity.superVisorExperience}
                    className="mt-1 focus:ring-meny-700 focus:border-meny-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
                <HelperText helpertext="Arbeidserfaring" />
                <ErrorMessage
                  errors={errors}
                  name="superVisorExperience"
                  render={({ message }) => (
                    <p className="text-sm text-red-600">{message}</p>
                  )}
                />
              </>
            ) : null}
          </div>

          <div className="col-span-6">
            <label
              htmlFor="comment"
              className="block text-sm font-medium text-gray-700"
            >
              Kommentar
            </label>
            <div className="mt-1">
              <textarea
                {...register("comment", { required: false })}
                id="comment"
                name="comment"
                rows={4}
                className="shadow-sm focus:ring-meny-700 focus:border-meny-500 block w-full sm:text-sm border border-gray-300 rounded-md"
                placeholder="Spesifiser"
                defaultValue={activity.comment}
              />
            </div>
            <HelperText helpertext="Detaljer om erfaring" />
          </div>

          <div className="col-span-6">
            <label
              htmlFor="reasonForSupervisor"
              className="block text-sm font-medium text-gray-700"
            >
              Begrunn kort hvorfor du har valgt denne veilederen
            </label>
            <div className="mt-1">
              <textarea
                {...register("reasonForSupervisor", {
                  required: false,
                  maxLength: {
                    value: 400,
                    message: "Maks tegn er 400",
                  },
                })}
                id="reasonForSupervisor"
                name="reasonForSupervisor"
                rows={4}
                className="shadow-sm focus:ring-meny-700 focus:border-meny-500 block w-full sm:text-sm border border-gray-300 rounded-md"
                placeholder="Spesifiser"
                defaultValue={activity.reasonForSupervisor}
              />
            </div>
            <HelperText helpertext="Årsak til valgt veileder" />
            <ErrorMessage
              errors={errors}
              name="reasonForSupervisor"
              render={({ message }) => (
                <p className="text-sm text-red-600">{message}</p>
              )}
            />
          </div>

          <div className="col-span-6">
            <p
              // htmlFor="employerAlternative"
              className="block text-sm italic m-2 font-normal sm:col-span-2 text-meny-700 sm:mt-px sm:pt-2 sm:col-start-3"
            >
              Har du tidligere fått godkjent veileder etter søknad?
            </p>
            {/* <Switch
                            checked={approvedSupervisor}
                            onChange={showApprovedSupervisorInfo}
                            className={classNames(
                                approvedSupervisor ? "bg-meny-600" : "bg-gray-900",
                                "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
                            )}
                        >
                            <span
                                aria-hidden="true"
                                className={classNames(
                                    approvedSupervisor ? "translate-x-5" : "translate-x-0",
                                    "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                                )}
                            />
                        </Switch> */}
            <fieldset className="mt-4">
              <legend className="sr-only">whyDispensation</legend>
              <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                <>
                  <div key={"0"} className="flex items-center">
                    <input
                      id={"0"}
                      name="YesNo"
                      type="radio"
                      onChange={e => showApprovedSupervisorInfo(e.target.id)}
                      defaultChecked={!activity.approvedSupervisor}
                      className="focus:ring-meny-700 h-4 w-4 text-indigo-600 border-gray-300"
                    />
                    <label
                      htmlFor={"0"}
                      className="ml-3 block text-sm font-medium text-gray-700"
                    >
                      {"Nei"}
                    </label>
                  </div>
                  <div key={"1"} className="flex items-center">
                    <input
                      id={"1"}
                      name="YesNo"
                      type="radio"
                      onChange={e => showApprovedSupervisorInfo(e.target.id)}
                      defaultChecked={activity.approvedSupervisor}
                      className="focus:ring-meny-700 h-4 w-4 text-indigo-600 border-gray-300"
                    />
                    <label
                      htmlFor={"1"}
                      className="ml-3 block text-sm font-medium text-gray-700"
                    >
                      {"Ja"}
                    </label>
                  </div>
                </>
              </div>
            </fieldset>
          </div>

          {approvedSupervisor ? (
            <div className="col-span-6">
              <label
                htmlFor="courseProviderAccountName"
                className="block text-sm font-medium text-gray-700"
              >
                Skriv kort til hvilket program, hvilken veileder og antall timer
              </label>
              <input
                type="text"
                {...register("approvedSupervisorInfo", {
                  pattern: {
                    value: /^[-',.A-Za-zÆØÅæøå0-9 ]*$/i,
                    message:
                      "Kun bokstaver, tall og tegnene ' . - , er tillatt.",
                  },
                  maxLength: {
                    value: 250,
                    message: "Maks tegn er 250",
                  },
                })}
                // onChange={e => SetApprovedSupervisorInfo(e.target.value)}
                name="approvedSupervisorInfo"
                id="approvedSupervisorInfo"
                autoComplete="approvedSupervisorInfo"
                defaultValue={activity.approvedSupervisorInfo}
                className="mt-1 focus:ring-meny-700 focus:border-meny-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
              <ErrorMessage
                errors={errors}
                name="approvedSupervisorInfo"
                render={({ message }) => (
                  <p className="text-sm text-red-600">{message}</p>
                )}
              />
            </div>
          ) : null}
        </div>

        <div className="mt-8 border-t flex justify-end">
          <button
            type="submit"
            className="ml-3 mt-6 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-meny-600 hover:bg-meny-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
          >
            Oppdater
          </button>
        </div>
      </form>
    </div>
  )
}

export default SupervisorsApprovalEdit
