import React, { useEffect, useState } from "react"
import { Link, navigate } from "gatsby"

import { classNames } from "../common/graphichs/misc"
import { useQuery } from "react-query"
import { fetchApi } from "../../Utils/Api.utils"
import {
  MsalAuthenticationTemplate,
  useAccount,
  useIsAuthenticated,
  useMsal,
} from "@azure/msal-react"
import { Loading } from "../../Utils/Loading"
import { ErrorComponent } from "../common/graphichs/ErrorComponent"
import { InteractionType } from "@azure/msal-browser"
import { loginRequest } from "../security/authConfig"
import { Contact } from "../../Utils/Entities"
import LoadingData from "../common/graphichs/LoadingData"
import { landingPageActions } from "../../Utils/Tabs"
import MyPageLayout from "../common/layouts/myPageLayout"
import MyPageLayout2 from "../common/layouts/myPageLayout2"
import MainLayout from "../common/layouts/mainLayout"
import HomeLayout from "../common/layouts/HomeLayout"
import { whiteButton } from "../../styles/tailwindClasses"

const HomeContent = () => {
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const [contact, setContact] = useState<Contact>()
  const [isMember, setIsMember] = useState(false)
  const isAuthenticated = useIsAuthenticated()

  const webRoleInfo = useQuery(
    "webRoleData",
    () =>
      fetchApi(
        `${process.env.GATSBY_API_URL}/Contacts/Roles`,
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      enabled: !!isAuthenticated,
      onSuccess: data => {
        const role = data.some(r => r.role === 292460001) //Medlem

        if (role) setIsMember(true)
      },
      onError: _error => { },
    }
  )

  const userInfoQuery = useQuery(
    "userInfo",
    () =>
      fetchApi(
        process.env.GATSBY_API_URL + "/Contacts/me",
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: data => {
        setContact(data)
      },
      onError: _error => { },
    }
  )

  return userInfoQuery.isLoading ? (
    // // "Laster inn..."
    <LoadingData />
  ) : userInfoQuery.isError ? (
    userInfoQuery.error.message
  ) : (
    <HomeLayout>
      <div className="h-screen pt-16 flex flex-col bg-gray-200">
        <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
            <h1 className="sr-only">Profile</h1>
            {/* Main 3 column grid */}
            <div className="grid grid-cols-1 gap-4 items-start lg:grid-cols-3 lg:gap-8">
              {/* Left column */}
              <div className="grid grid-cols-1 gap-4 lg:col-span-4">
                {/* Welcome panel */}
                <section aria-labelledby="profile-overview-title">
                  <div className="rounded-lg bg-white overflow-hidden shadow">
                    <h2 className="sr-only" id="profile-overview-title">
                      Profile Overview
                    </h2>
                    <div className="bg-white p-6">
                      <div className="sm:flex sm:items-center sm:justify-between">
                        <div className="sm:flex sm:space-x-5">
                          {/* <div className="flex-shrink-0">
                                                    <img className="mx-auto h-20 w-20 rounded-full" src={user.imageUrl} alt="" />
                                                </div> */}
                          <div className="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
                            <p className="text-sm font-medium text-gray-600">
                              Velkommen tilbake,
                            </p>
                            <p className="text-xl font-bold text-gray-900 sm:text-2xl">
                              {userInfoQuery.data.firstName +
                                " " +
                                userInfoQuery.data.lastName}
                            </p>
                            {/* <p className="text-sm font-medium text-gray-600">{user.role}</p> */}
                          </div>
                        </div>
                        <div className="mt-5 flex justify-center sm:mt-0">
                          <Link
                            to="/app/profil"
                            className={
                              whiteButton + " flex justify-center items-center "
                            }
                            activeClassName="active-link"
                          >
                            Vis profil
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                {/* Actions panel */}
                <section aria-labelledby="quick-links-title">
                  <div className="rounded-lg bg-gray-200 overflow-hidden shadow divide-y divide-gray-200 sm:divide-y-0 sm:grid sm:grid-cols-3 sm:gap-px">
                    <h2 className="sr-only" id="quick-links-title">
                      Quick links
                    </h2>
                    {landingPageActions.map((action, actionIdx) => (
                      <div
                        key={action.name}
                        className={classNames(
                          actionIdx === 0
                            ? "rounded-tl-lg rounded-tr-lg sm:rounded-tr-none"
                            : "",
                          actionIdx === 1 ? "sm:rounded-tr-lg" : "",
                          actionIdx === landingPageActions.length - 2
                            ? "sm:rounded-bl-lg"
                            : "",
                          actionIdx === landingPageActions.length - 1
                            ? "rounded-bl-lg rounded-br-lg sm:rounded-bl-none"
                            : "",
                          "relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-cyan-500"
                        )}
                      >
                        <div>
                          <span
                            className={classNames(
                              action.iconBackground,
                              action.iconForeground,
                              "rounded-lg inline-flex p-3 ring-4 ring-white"
                            )}
                          >
                            <action.icon
                              className="h-6 w-6"
                              aria-hidden="true"
                            />
                          </span>
                        </div>
                        <div className="mt-8">
                          <h3 className="text-lg font-medium">
                            <a
                              href={action.href}
                              className="focus:outline-none"
                            >
                              {/* Extend touch target to entire panel */}
                              <span
                                className="absolute inset-0"
                                aria-hidden="true"
                              />
                            </a>
                            {isMember && action.href.includes("medlemskap/nymedlemskap") ?
                              <>
                                <Link
                                  to="medlemskap/minemedlemskap"
                                  className="focus:outline-none"
                                  activeClassName="active-link"
                                >
                                  <span
                                    className="absolute inset-0"
                                    aria-hidden="true"
                                  />
                                  {action.name}
                                </Link>
                              </>
                              :
                              <>
                                <Link
                                  to={action.href}
                                  className="focus:outline-none"
                                  activeClassName="active-link"
                                >
                                  <span
                                    className="absolute inset-0"
                                    aria-hidden="true"
                                  />
                                  {action.name}
                                </Link>
                              </>
                            }

                          </h3>
                          <p className="mt-2 text-sm text-gray-600">
                            {/* Doloribus dolores nostrum quia qui natus officia
                            quod et dolorem. Sit repellendus qui ut at
                            blanditiis et quo et molestiae. */}
                          </p>
                        </div>
                        <span
                          className="pointer-events-none absolute top-6 right-6 text-gray-300 group-hover:text-meny-600"
                          aria-hidden="true"
                        >
                          <svg
                            className="h-6 w-6"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                          >
                            <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                          </svg>
                        </span>
                      </div>
                    ))}
                  </div>
                </section>
              </div>
            </div>
          </div>
        </main>
      </div>
    </HomeLayout>
  )
}

const Home = () => {
  const authRequest = {
    ...loginRequest,
  }
  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      <HomeContent />
    </MsalAuthenticationTemplate>
  )
}

export default Home
