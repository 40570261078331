import React from "react"

const TableText = (props: {
  children: React.ReactNode
  className?: string
}) => (
  <th
    className={
      "text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 " + props.className
    }
  >
    {props.children}
  </th>
)

export default TableText
