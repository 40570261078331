import React, { useState } from "react"
import { useQuery } from "react-query"
import { fetchApiAnonymous } from "../../../../Utils/Api.utils"
import DropdownSearch, { optionsType } from "./dropdownSearch"

type zipType = {
  id: string
  postalCode: string
  postalPlace: string
}

export type ZipDropdownSearchType = {
  defaultZip?: string
  setZip: (zip: string) => void
}

const ZipDropdownSearch = (props: ZipDropdownSearchType) => {
  const [zip, setZip] = useState<Array<optionsType>>([])
  const [searchString, setSearchString] = useState<string>(
    props.defaultZip || ""
  )
  const [selectedZip, setSelectedZip] = useState<optionsType | undefined>(
    !!props.defaultZip ? { label: props.defaultZip, id: "search" } : undefined
  )

  useQuery<Array<zipType>>(
    ["zipSearch-" + searchString, searchString],
    async () =>
      fetchApiAnonymous(
        process.env.GATSBY_API_URL +
          `/Addresses/PostalCodesSearch/${
            searchString === "" ? "null" : searchString
          }`
      ).then(res => res.data),
    {
      // enabled: searchString.length >= 2,
      onSuccess: data => {
        const optionSetData: Array<optionsType> = !data
          ? []
          : data?.map(item => ({
              id: item.id,
              label: item.postalCode,
              subLabel: item.postalPlace,
            }))

        if (selectedZip?.id === "search") {
          const zip = data.find(item => item.postalCode === props.defaultZip)

          setSelectedZip({
            id: zip?.id || "",
            label: zip?.postalCode || "",
            subLabel: zip?.postalPlace,
          })
        }

        setZip(optionSetData)
      },
    }
  )

  const changeSelectedZip = (item: optionsType) => {
    setSelectedZip(item)

    if (props.setZip) props.setZip(item.label)
  }

  return (
    <div className="z-20 relative">
      <DropdownSearch
        options={zip}
        onChange={setSearchString}
        onSelect={changeSelectedZip}
        defaultSelect={props.defaultZip ? selectedZip : undefined}
      />
      {selectedZip ? <div>{selectedZip.subLabel}</div> : <div></div>}
    </div>
  )
}

export default ZipDropdownSearch
