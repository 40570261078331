import { Link } from "gatsby"
import React from "react"
import HeaderText from "../../../common/text/Header"

const Unsubscribe = () => {
  return (
    <div className="max-w-3xl mx-auto divide-y-2 divide-gray-200">
      <HeaderText text="Utmeldingsskjema" />
      <form
        className="mt-6
                  space-y-6
                  
                  bg-white
                  rounded-lg
                  shadow
                  px-5
                  py-6
                  sm:px-6"
        method="POST"
      >
        <label
          htmlFor=""
          className="text-center font-medium text-md text-meny-700 sm:text-2xl"
        >
          Utmelding
        </label>
        <h1>
          <p>
            Ønsker du å melde deg ut av foreningen, sender du en søknad til
            medlemsservice. Utmeldingen blir da behandlet ihht Lover for Norsk
            psykologforening.
          </p>
        </h1>
        <h2>
          Lover for Norsk psykologforening (Vedtatt 24. november 1978 og med
          endringer i 1980, 1981, 1982, 1983, 1987, 1989, 1991, 1993, 1995,
          1998, 2001, 2004, 2007, 2010, 2013, 2016 og 2019)
        </h2>
        <hr />
        <div>
          <label htmlFor="" className="text-lg text-meny-700">
            §15. Opphør av medlemskap
          </label>
          <p>
            Utmelding av foreningen skjer skriftlig til sentralstyret med 3
            måneders varsel.
          </p>
        </div>
        <div className="flex justify-center py-4">
          <div>
            <Link to="/app/medlemskap/applications/CloseMembership">
              <button className="ml-1 inline-flex justify-center py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-md text-white bg-meny-700 hover:bg-meny-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-600">
                Søk her
              </button>
            </Link>
          </div>
        </div>
        <div>
          <label htmlFor="" className="text-lg text-meny-700">
            §2. Medlemskap
          </label>
          <p>
            Når en fagetisk sak er reist mot et medlem, kan vedkommende ikke
            melde seg ut av foreningen før saken er ferdigbehandlet.
          </p>
        </div>
        <br />
        <ul>
          <li>
            &bull;&nbsp; Sjekk våre &nbsp;
            <Link
              to="/app/medlemskap/applications/rules"
              className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
            >
              retningslinjer for nedsettelse og betaling av kontingent
            </Link>
          </li>
        </ul>
      </form>
    </div>
  )
}

export default Unsubscribe
