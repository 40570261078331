import React, { createContext, useContext, useEffect, useMemo, useState } from "react"
import MyPageLayout2 from "../../common/layouts/myPageLayout2"
import ProfileInfo from "../../mypagecomponents/membership/ProfileInfo"
import { memberShipTabs } from "../../../Utils/Tabs"
import { classNames } from "../../common/graphichs/misc"
import { MemberType, MemberTypes } from "../../../Utils/optionSet"
import SendMembership from "../../mypagecomponents/membership/SendMembership"
import WorkingConditions from "../../mypagecomponents/membership/WorkingConditions"
import { changeMembershipTab, resetMembershipTab } from "../../mypagecomponents/membership/helpers"
import EducationInfo from "../../mypagecomponents/membership/EducationInfo"
import { loginRequest } from "../../security/authConfig"
import { MsalAuthenticationTemplate } from "@azure/msal-react"
import { InteractionType } from "@azure/msal-browser"
import { ErrorComponent } from "../../common/graphichs/ErrorComponent"
import { Loading } from "../../../Utils/Loading"
import { Contact } from "../../../Utils/Entities"

const NewMemberContent = () => {
  const [membershipCurrentTabId, setMembershipCurrentTabId] = useState(0)
  const [memberTypeParent, setMemberParent] = useState(MemberTypes[1])
  const setTabId = async value => setMembershipCurrentTabId(value)

  const [educationData, setEducationData] = useState({})
  const [documents, setDocuments] = useState([])
  const [employmentData, setemploymentData] = useState({})

  useEffect(() => {
    resetMembershipTab()
    changeMembershipTab(0)
    setTabId(0)
  }, [])

  return (
    <>
      <MyPageLayout2 props="Innmeldingsskjema">
        <div className="sm:block">
          <div className="border-b border-gray-200">
            <ol
              role="list"
              className="space-y-4 md:flex md:space-y-0 md:space-x-8 justify-center"
            >
              {memberShipTabs.map((tab, tabIdx) => (
                <li key={tab.id} className="pl-10" hidden={!tab.show}>
                  <button
                    key={tab.name}
                    className={classNames(
                      tab.current
                        ? "border-meny-500 text-meny-500"
                        : "border-transparent text-gray-800 ",
                      tabIdx === 0 ? "" : "",
                      tabIdx === memberShipTabs.length - 1 ? "" : "",
                      "group pl-4 py-2 flex flex-col border-l-4 border-meny-800 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4"
                    )}
                    aria-current={tab.current ? "page" : undefined}
                  >
                    <span>{tab.name}</span>
                  </button>
                </li>
              ))}
            </ol>
          </div>
        </div>

        <div className="pt-5 flex justify-end"></div>

        <div>
          {membershipCurrentTabId === 0 ? <ProfileInfo setTabId={setTabId} setMemberParent={setMemberParent} memberTypeParent={memberTypeParent} /> : null}
          {membershipCurrentTabId === 1 ? <EducationInfo setEducationData={setEducationData} setDocuments={setDocuments} setTabId={setTabId} memberTypeParent={memberTypeParent} /> : null}
          {membershipCurrentTabId === 2 ? <WorkingConditions setemploymentData={setemploymentData} setTabId={setTabId} memberTypeParent={memberTypeParent} /> : null}
          {membershipCurrentTabId === 3 ? <SendMembership educationData={educationData} documents={documents} employmentData={employmentData} setTabId={setTabId} memberTypeParent={memberTypeParent} /> : null}
        </div>
      </MyPageLayout2>
    </>
  )
}

const NewMember = () => {
  const authRequest = {
    ...loginRequest,
  }
  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      <NewMemberContent />
    </MsalAuthenticationTemplate>
  )
}

export default NewMember
