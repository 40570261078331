import { useAccount, useMsal } from "@azure/msal-react"
import React, { useEffect, useState } from "react"
import { useMutation, useQueryClient } from "react-query"
import {
  deleteApi,
  fetchDownLoadFileApi,
  postGetDocumentApi,
} from "../../../Utils/Api.utils"
import { ExpenseReportLine } from "../../../Utils/Entities"
import { RemoveUnderScore } from "../../../Utils/FormatHelper"
import LoadingData from "../../common/graphichs/LoadingData"
import ModalWithHeader from "../../common/modal/ModalWithHeader"

const ReportLine = props => {
  const expenseReportLine: ExpenseReportLine = props.line
  const canEdit = props.canEdit
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const [documentToDownLoad, setDocument] = useState(null)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [isUpdating, setIsUpdating] = useState<boolean>(false)

  const queryClient = useQueryClient()

  const mutationGetDocument = useMutation(
    () => {
      return postGetDocumentApi(
        process.env.GATSBY_API_URL + "/Documents/Regarding/Get",
        {
          ExpenseReportLineId: expenseReportLine.id,
        },
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: data => {
        if (data.length == 1) setDocument(data[0])
      },
      onError: _error => {},
      onSettled: () => {},
    }
  )

  useEffect(() => {
    mutationGetDocument.mutate()
  }, [])

  const mutationDeleteDocument = useMutation(
    document => {
      return deleteApi(
        process.env.GATSBY_API_URL +
          "/Documents/Portal/Delete/" +
          document.blobId,
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: () => {
        // queryClient.invalidateQueries(["expenserepLines"])
        setIsUpdating(false)
      },
      onError: _error => {
        setIsUpdating(false)
      },
      onSettled: () => {},
    }
  )

  let documentName = "FileName"

  const mutationDownload = useMutation(
    e => {
      documentName = e.title
      return fetchDownLoadFileApi(
        process.env.GATSBY_API_URL + "/Documents/Download/" + e.blobId,
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: blob => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]))
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", documentName)

        // Append to html link element page
        document.body.appendChild(link)

        // Start download
        link.click()

        // Clean up and remove the link
        link.parentNode.removeChild(link)
      },
      onError: _error => {},
      onSettled: () => {},
    }
  )

  // const onFileDownLoad = e => mutationDownload.mutate(e)
  const downloadFile = e => {
    mutationDownload.mutate(e)
  }

  const deleteConfirmation = () => {
    setShowModal(true)
  }

  const onDelete = () => {
    setShowModal(false)
    setIsUpdating(true)
    mutationDelete.mutate(expenseReportLine.id)
  }

  const mutationDelete = useMutation(
    id => {
      return deleteApi(
        process.env.GATSBY_API_URL + "/ExpenseReportlines/" + id,
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["expenserepLines"])
        if (documentToDownLoad) {
          mutationDeleteDocument.mutate(documentToDownLoad)
        } else setIsUpdating(false)
      },
      onError: _error => {
        setIsUpdating(false)
      },
      onSettled: () => {},
    }
  )
  return isUpdating ? (
    <LoadingData subject="Oppdaterer" />
  ) : (
    // <div>

    <tr
      key={expenseReportLine.id}
      // className={classNames(
      //   true ? "hover:bg-meny-50 hover:cursor-pointer" : ""
      // )}
      // onClick={() => {
      //   // if (showEdit) {
      // }}
    >
      {showModal && (
        <ModalWithHeader setModalOpen={setShowModal} header="Slett">
          <p className="mb-2">
            Er du sikker på at du vil slette reiseregningslinjen?
          </p>

          <div className="w-full flex flex-row justify-center mt-8">
            <button
              onClick={() => {
                setShowModal(false)
              }}
              className="w-fit ml-1 inline-flex justify-end py-2 px-8 border border-gray-200 shadow-sm text-md font-medium rounded-md text-black bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
            >
              Avbryt
            </button>
            <button
              onClick={onDelete}
              type="submit"
              className="w-fit ml-1 inline-flex justify-end py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-md text-white bg-red-700 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-700"
            >
              Slett
            </button>
          </div>
        </ModalWithHeader>
      )}
      <td className="px-3 pl-4 py-4 text-sm text-gray-600 ">
        {expenseReportLine.name}
      </td>
      <td className="hidden lg:table-cell px-3 py-4 text-sm text-gray-600">
        {RemoveUnderScore(expenseReportLine.expenseTypeName)}
      </td>
      {/* <td className="hidden px-3 py-4 text-sm text-gray-600 sm:table-cell">
                      {line.mileageType}
                    </td> */}
      <td className="hidden lg:table-cell px-3 py-4 text-sm text-gray-600">
        {expenseReportLine.mileageAmount}
      </td>
      <td className="px-3 py-4 text-sm text-gray-600">
        {expenseReportLine.sumAmountMileage}
      </td>

      <td className="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
        {documentToDownLoad ? (
          <button
            type="button"
            onClick={e => downloadFile(documentToDownLoad)}
            className="bg-white rounded-md font-medium text-meny-600 hover:text-meny-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10"
              />
            </svg>
          </button>
        ) : null}
      </td>

      {canEdit ? (
        <td className="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
          <button
            type="button"
            onClick={deleteConfirmation}
            className="bg-white rounded-md font-medium text-meny-600 hover:text-meny-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
              />
            </svg>
          </button>
        </td>
      ) : null}
    </tr>
    // </div>
  )
}

export default ReportLine
