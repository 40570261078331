import { InteractionType } from "@azure/msal-browser"
import {
  MsalAuthenticationTemplate,
  useAccount,
  useMsal,
} from "@azure/msal-react"
import { Link, navigate } from "gatsby"
import React, { useEffect, useState } from "react"
import { useMutation, useQueryClient } from "react-query"
import { cyanButton, whiteButton } from "../../../styles/tailwindClasses"
import { patchApi, postApi, postGetDocumentApi } from "../../../Utils/Api.utils"
import { ActivityTypeEnum } from "../../../Utils/Enums"
import { Loading } from "../../../Utils/Loading"
import { activityPropType } from "../../../Utils/PropTypes"
import { SpecialistProgram, Activity } from "../../../Utils/Types"
import { ErrorComponent } from "../../common/graphichs/ErrorComponent"
import LoadingData from "../../common/graphichs/LoadingData"
import MyPageLayoutEducation from "../../common/layouts/MyPageLayoutEducation"
import GetHelpText from "../../common/text/GetHelpText"
import HelperText from "../../common/text/HelperText"
import DocumentList from "../../mypagecomponents/document/DocumentList"
import FileUpload from "../../mypagecomponents/document/FileUpload"
import ExternalCourse from "../../mypagecomponents/specialityeducation/activities/ExternalCourse"
import GuidanceActivity from "../../mypagecomponents/specialityeducation/activities/GuidanceActivity"
import GuidanceCertificate from "../../mypagecomponents/specialityeducation/activities/GuidanceCertificate"
import GuidanceContract from "../../mypagecomponents/specialityeducation/activities/GuidanceContract"
import Personal from "../../mypagecomponents/specialityeducation/activities/Personal"
import PracticeCertificate from "../../mypagecomponents/specialityeducation/activities/PracticeCertificate"
import SupervisorsApproval from "../../mypagecomponents/specialityeducation/activities/SupervisorsApproval"
import WrittenWork from "../../mypagecomponents/specialityeducation/activities/WrittenWork"
import ExternalCourseEdit from "../../mypagecomponents/specialityeducation/EditActivities/ExternalCourseEdit"
import GuidanceCertificateEdit from "../../mypagecomponents/specialityeducation/EditActivities/GuidanceCertificateEdit"
import GuidanceContractEdit from "../../mypagecomponents/specialityeducation/EditActivities/GuidanceContractEdit"
import MaintenanceActivityEdit from "../../mypagecomponents/specialityeducation/EditActivities/MaintanenceActivityEdit"
import PersonalEdit from "../../mypagecomponents/specialityeducation/EditActivities/PersonalEdit"
import PracticeCertificateEdit from "../../mypagecomponents/specialityeducation/EditActivities/PracticeCertificateEdit"
import SupervisorsApprovalEdit from "../../mypagecomponents/specialityeducation/EditActivities/SuperVisorsApprovalEdit"
import WrittenWorkEdit from "../../mypagecomponents/specialityeducation/EditActivities/WrittenWorkEdit"
import { loginRequest } from "../../security/authConfig"
import ConsultantEdit from "../../mypagecomponents/specialityeducation/EditActivities/ConsultantEdit"

const EditSpecializationActivityContent = props => {
  const activity: Activity = props.activity
  const program: SpecialistProgram = props.program
  const queryClient = useQueryClient()
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const [selectedActivityType, setSelectedActivityType] =
    useState<ActivityTypeEnum>()
  const [comment, setComment] = useState("")
  const [incidentTitle, setIncidentTitle] = useState("")

  const [showLoadingData, setShowLoadingData] = useState(false)
  const [showActivityButtons, setShowActivityButtons] = useState(true)

  useEffect(() => {
    setSelectedActivityType(activity.activityType)
  }, [activity])

  const mutationUpdateActivity = useMutation(
    data => {
      return patchApi(
        process.env.GATSBY_API_URL + "/SpecializationActivities",
        data,
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: () => {
        setShowLoadingData(false)
        navigate(-1)
        // setShowDocumentComponent(false)
        // mutationUpdateIncident.mutate()
      },
      onError: _error => {
        console.log("Feil: " + _error)
      },
      onSettled: () => {},
    }
  )

  const mutationCreatePracticeHours = useMutation(
    data => {
      return postApi(
        process.env.GATSBY_API_URL + "/PracticeHours",
        data,
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: data => {},
      onError: _error => {},
      onSettled: () => {},
    }
  )

  return (
    <div>
      <div>{showLoadingData && <LoadingData subject="Laster..." />}</div>
      <MyPageLayoutEducation props="Endre Spesialiseringsaktivitet">
        <button
          onClick={() => navigate(-1)}
          className={
            whiteButton +
            " inline-flex items-center justify-center focus:ring-offset-2 sm:w-auto "
          }
        >
          Tilbake
        </button>

        <div className="space-y-6 mt-8">
          {/* {selectedActivityType === "292460005" ? ( */}
          {/* <VisualStep steps={steps} /> */}
          {/* ) : null} */}
          <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
            <div className="mt-5 md:mt-0 md:col-span-2">
              <div>
                {selectedActivityType == 292460000 ? (
                  <ExternalCourseEdit
                    mutationUpdateActivity={mutationUpdateActivity}
                    setShowLoadingData={setShowLoadingData}
                    program={program}
                    activity={activity}
                  />
                ) : null}
                {selectedActivityType === 292460001 ? (
                  <PracticeCertificateEdit
                    program={program}
                    activity={activity}
                    mutationUpdateActivity={mutationUpdateActivity}
                    mutationCreatePracticeHours={mutationCreatePracticeHours}
                    setIncidentTitle={setIncidentTitle}
                    setShowLoadingData={setShowLoadingData}
                  />
                ) : null}
                {selectedActivityType === 292460003 ? (
                  <WrittenWorkEdit
                    program={program}
                    mutationUpdateActivity={mutationUpdateActivity}
                    setShowLoadingData={setShowLoadingData}
                    activity={activity}
                  />
                ) : null}
                {selectedActivityType === 292460004 ? (
                  <GuidanceCertificateEdit
                    mutationUpdateActivity={mutationUpdateActivity}
                    activity={activity}
                    setShowLoadingData={setShowLoadingData}
                    showActivityButtons={showActivityButtons}
                  />
                ) : null}
                {selectedActivityType === 292460002 ? (
                  <GuidanceContractEdit
                    activity={activity}
                    mutationUpdateActivity={mutationUpdateActivity}
                    setShowLoadingData={setShowLoadingData}
                  />
                ) : null}
                {selectedActivityType === 292460006 ? (
                  <MaintenanceActivityEdit
                    activity={activity}
                    mutationUpdateActivity={mutationUpdateActivity}
                    setShowLoadingData={setShowLoadingData}
                  />
                ) : null}
                {selectedActivityType === 292460005 ? (
                  <SupervisorsApprovalEdit
                    mutationUpdateActivity={mutationUpdateActivity}
                    activity={activity}
                    setShowLoadingData={setShowLoadingData}
                    program={program}
                  />
                ) : null}
                {selectedActivityType === 292460007 ? (
                  <PersonalEdit
                    mutationUpdateActivity={mutationUpdateActivity}
                    activity={activity}
                    setShowLoadingData={setShowLoadingData}
                  />
                ) : null}
                {selectedActivityType === 292460008 ? (
                  <ConsultantEdit
                    mutationUpdateActivity={mutationUpdateActivity}
                    activity={activity}
                    setShowLoadingData={setShowLoadingData}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}
      </MyPageLayoutEducation>
    </div>
  )
}

const EditSpecializationActivity = ({ location }) => {
  const authRequest = {
    ...loginRequest,
  }
  const activity: Activity = location.state.activity
  const program: SpecialistProgram = location.state.program
  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      <EditSpecializationActivityContent
        activity={activity}
        program={program}
      />
    </MsalAuthenticationTemplate>
  )
}

export default EditSpecializationActivity
