import { useAccount, useMsal } from "@azure/msal-react"
import { navigate } from "gatsby"
import React, { useEffect, useRef, useState } from "react"
import { useMutation, useQueryClient } from "react-query"
import { toast } from "react-toastify"
import { cyanButton } from "../../../styles/tailwindClasses"
import { patchApi, postApi, postGetDocumentApi } from "../../../Utils/Api.utils"
import LoadingData from "../../common/graphichs/LoadingData"
import GetHelpText from "../../common/text/GetHelpText"
import HelperText from "../../common/text/HelperText"
import DocumentList from "../document/DocumentList"
import Modal from "../../common/modal/Modal"
import DocumentListMultipleActivities from "../document/DocumentListMultipleActivities"

const PracticeCertificateDocumentsUpload = props => {
  const queryClient = useQueryClient()
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const [documents, setDocuments] = useState([])
  const [selectedFiles, setSelectedFiles] = useState([])
  const drop = useRef(null)
  const [disableButton, setDisableButton] = useState<boolean>(false)
  const [isDocumentRequired, setIsDocumentRequired] = useState<boolean>(true)
  const [showLoadingData, setShowLoadingData] = useState(false)
  const [showLoadingOnUpdate, setShowLoadingOnUpdate] = useState<boolean>(false)
  const [showModal, setShowModal] = useState(false)
  const [documentsWithId, setDocumentsWithId] = useState([])
  const [count, setCount] = useState<number>(0)

  // console.log(
  //   props.program.programType.id === "ee9bb462-c5a6-ec11-983f-000d3aae55a4" ||
  //     props.program.programType.id === "e542c068-c5a6-ec11-983f-000d3aae55a4"
  // )

  type progActInc = {
    programId: string
    activityId: string
    incidentId: string
  }

  const listOfIds: progActInc[] = props.listOfIds

  const mutationUpdateSpesActivityAndIncident = useMutation(
    data => {
      return patchApi(
        process.env.GATSBY_API_URL + "/SpecializationActivities/practiceCert",

        data,

        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: () => {
        window.setTimeout(() => {
          navigate("/app/kurs/specialityeducation/program", {
            state: { program: props.program },
          })
        }, 5000)
      },
      onError: _error => {
        setShowLoadingOnUpdate(false)
      },
      onSettled: () => {},
    }
  )

  const mutationUploadDocumets = useMutation(
    formData => {
      return postApi(
        process.env.GATSBY_API_URL + "/Documents",
        formData,
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: data => {
        setSelectedFiles([])
        console.log(mutationGetDocuments)
        if (mutationGetDocuments !== null) mutationGetDocuments.mutate()

        setDisableButton(false)
        setDocumentsWithId(prevResultArray => [
          ...prevResultArray,
          ...data.map(item => ({ ...item, count })),
        ])
      },
      onError: _error => {
        setDisableButton(false)
      },
      onSettled: () => {
        setShowLoadingData(false)
      },
    }
  )

  const PreUploadFiles = () => {
    if (selectedFiles.length == 0) {
      toast.warning("Vennligst velg en eller flere filer!", {
        position: "top-center",
      })
      return
    }
    setCount(count + 1)
    listOfIds.forEach(idObject => {
      UploadFiles(idObject)
    })
  }

  const UploadFiles = idObject => {
    const formData = new FormData()
    Array.from(selectedFiles).forEach(file =>
      formData.append("arrayOfFiles", file)
    )

    formData.append("EntityName", "incident")
    formData.append("entityName2", "pp_activityspecialistprogram")
    formData.append("entityName3", "pp_education")
    formData.append("EntityId3", props.educationId)

    setDisableButton(true)
    setShowLoadingData(true)

    formData.append("EntityId", idObject.incidentId)
    formData.append("EntityId2", idObject.activityId)

    console.log("act id: ", idObject.activityId)
    console.log("inc id: ", idObject.incidentId.toString())
    mutationUploadDocumets.mutate(formData)
  }

  const onFileChange = e => {
    setSelectedFiles(Array.from(e.target.files))
  }

  const handleDragOver = e => {
    e.preventDefault()
    e.stopPropagation()
    setSelectedFiles(Array.from(e.dataTransfer.files))
  }

  const handleDrop = e => {
    e.preventDefault()
    e.stopPropagation()
    setSelectedFiles(Array.from(e.dataTransfer.files))
    if (e.dataTransfer.files.length > 0) {
      setIsDocumentRequired(false)
      return
    }
  }

  useEffect(() => {
    drop?.current?.addEventListener("dragover", handleDragOver)
    drop?.current?.addEventListener("drop", handleDrop)

    return () => {
      drop?.current?.removeEventListener("dragover", handleDragOver)
      drop?.current?.removeEventListener("drop", handleDrop)
    }
  }, [])

  const PatchIncidentAndActivity = async e => {
    e.preventDefault()
    if (documents?.length == 0) {
      toast.warning("Vennligst last opp en eller flere filer!", {
        position: "top-center",
        autoClose: 10000,
      })

      return
    } else {
      setShowModal(true)
    }
  }

  const PatchIncidentAndActivityAfterCheck = async e => {
    e.preventDefault()
    if (documents?.length == 0) {
      toast.warning("Vennligst last opp en eller flere filer!", {
        position: "top-center",
        autoClose: 10000,
      })

      return
    }

    listOfIds.forEach(idObject => {
      setShowLoadingOnUpdate(true)
      mutationUpdateSpesActivityAndIncident.mutate(idObject)
    })
  }

  const mutationGetDocuments = useMutation(
    () => {
      return postGetDocumentApi(
        process.env.GATSBY_API_URL + "/Documents/Regarding/Get",
        {
          ActivitySpecialistProgramId: listOfIds[0].activityId,
        },
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: data => {
        setDocuments(data)
      },
      onError: _error => {},
      onSettled: () => {},
    }
  )

  return (
    <div>
      {showModal && (
        <Modal setModalOpen={setShowModal}>
          {props.program.programType.id ===
          "7a958356-c5a6-ec11-983f-000d3aae55a4" ? (
            <p>
              Vennligst bekreft at du har lastet opp tjenestebevis før du
              leverer. Søknader uten tjenestebevis vil få avslag.
            </p>
          ) : (
            <p>
              Vennligst bekreft at du har lastet opp både praksisattest og
              tjenestebevis før du leverer.
            </p>
          )}
          <div className="w-full flex flex-row justify-around mt-8">
            <button
              onClick={() => {
                setShowModal(false)
              }}
              className="w-fit ml-1 inline-flex justify-end py-2 px-8 border border-gray-200 shadow-sm text-md font-medium rounded-md text-black bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
            >
              Gå tilbake
            </button>
            <button
              onClick={PatchIncidentAndActivityAfterCheck}
              className="w-fit ml-1 inline-flex justify-end py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-md text-white bg-meny-700 hover:bg-meny-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
            >
              Bekreft
            </button>
          </div>
        </Modal>
      )}
      <HelperText
        helpertext={
          "Last opp dokumentasjon og send inn aktiviteten under boksen."
        }
      />
      <GetHelpText number={1002} />
      <p className="mt-2 text-sm text-gray-600"></p>
      <p className="mt-2 text-sm text-gray-600"></p>
      <div className="mt-2">
        {/* <DocumentList
          documents={documents}
          mutationGetDocuments={mutationGetDocuments}
          canDelete={true}
        /> */}
        <DocumentListMultipleActivities
          documents={documents}
          mutationGetDocuments={mutationGetDocuments}
          canDelete={true}
          documentsWithId={documentsWithId}
          setShowLoadingData={props.setShowLoadingData}
        />
      </div>
      <div>
        <div>
          {showLoadingData && <LoadingData subject="Laster opp fil(er)..." />}
          {showLoadingOnUpdate && <LoadingData subject="Oppdaterer.." />}
        </div>
        <div className="sm:col-span-6" ref={drop}>
          <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
            <div className="space-y-1 text-center">
              {selectedFiles.length > 0 ? (
                <ul
                  role="list"
                  className="border border-gray-200 rounded-md divide-y divide-gray-200"
                >
                  {selectedFiles.map(file => (
                    <li
                      key={file.name}
                      className="pl-3 pr-4 py-3 flex items-center justify-between text-sm"
                    >
                      <div className="ml-4 flex-shrink-0 flex space-x-4">
                        <p>Filnavn: {file.name}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              ) : (
                <svg
                  className="mx-auto h-12 w-12 text-gray-400"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 48 48"
                  aria-hidden="true"
                >
                  <path
                    d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}
              <div className="flex text-sm text-gray-600">
                <label
                  htmlFor="file-upload"
                  className="relative cursor-pointer bg-meny-700 rounded-md font-medium text-white hover:bg-meny-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-meny-600"
                >
                  <span>Last opp fil(er)</span>
                  <input
                    // hidden
                    required={isDocumentRequired}
                    id="file-upload"
                    name="file-upload"
                    type="file"
                    multiple
                    onChange={e => onFileChange(e)}
                    className="sr-only"
                  />
                </label>
                <p className="pl-1">eller dra og slip</p>
              </div>
              <p className="text-xs text-gray-600">
                TXT, PDF, DOCX, XLSX, PPTX opp til 30MB
              </p>
            </div>
          </div>
        </div>

        <div className="flex p-2 space-x-4 justify-end">
          <button
            className="px-4 py-2 text-white bg-green-500 rounded-md "
            type="button"
            disabled={disableButton}
            onClick={PreUploadFiles}
          >
            Last opp
          </button>
        </div>
      </div>
      <div className="pt-5">
        <div className="flex justify-center">
          <button
            type="button"
            onClick={e => PatchIncidentAndActivity(e)}
            className={cyanButton + " inline-flex items-center "}
          >
            Send Inn
          </button>
        </div>
      </div>
    </div>
  )
}

export default PracticeCertificateDocumentsUpload
