import { InteractionType } from "@azure/msal-browser"
import {
  MsalAuthenticationTemplate,
  useAccount,
  useMsal,
} from "@azure/msal-react"
import { ChevronDownIcon } from "@heroicons/react/outline"
import { Link, navigate } from "gatsby"
import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useMutation, useQueryClient } from "react-query"
import { patchApi, postApi, postGetDocumentApi } from "../../../Utils/Api.utils"
import { Loading } from "../../../Utils/Loading"
import {
  spesialistActivityTypes,
  spesialistActivityTypesMaintenance,
} from "../../../Utils/optionSet"
import { ErrorComponent } from "../../common/graphichs/ErrorComponent"
import MyPageLayout from "../../common/layouts/myPageLayout"
import MyPageLayoutEducation from "../../common/layouts/MyPageLayoutEducation"
import { loginRequest } from "../../security/authConfig"
import ExternalCourse from "../../mypagecomponents/specialityeducation/activities/ExternalCourse"
import PracticeCertificate from "../../mypagecomponents/specialityeducation/activities/PracticeCertificate"
import WrittenWork from "../../mypagecomponents/specialityeducation/activities/WrittenWork"
import GuidanceCertificate from "../../mypagecomponents/specialityeducation/activities/GuidanceCertificate"
import GuidanceContract from "../../mypagecomponents/specialityeducation/activities/GuidanceContract"
import GuidanceActivity from "../../mypagecomponents/specialityeducation/activities/GuidanceActivity"
import AddPracticeHours from "../../mypagecomponents/specialityeducation/AddPracticeHours"
import LoadingData from "../../common/graphichs/LoadingData"
import { cyanButton, whiteButton } from "../../../styles/tailwindClasses"
import SupervisorsApproval from "../../mypagecomponents/specialityeducation/activities/SupervisorsApproval"
import DocumentList from "../../mypagecomponents/document/DocumentList"
import FileUpload from "../../mypagecomponents/document/FileUpload"
import { toast } from "react-toastify"
import HelperText from "../../common/text/HelperText"
import { Steps } from "../../common/Steps"
import VisualStep from "../../common/Progress/VisualStep"
import { StepsType } from "../../common/Steps/StepsType"
import GetHelpText from "../../common/text/GetHelpText"
import Personal from "../../mypagecomponents/specialityeducation/activities/Personal"
import NewPracticeCertificateActivity from "../../mypagecomponents/specialityeducation/NewPracticeCertificateActivity"
import SpecialityEducation from "./SpecialityEducation"
import Consultant from "../../mypagecomponents/specialityeducation/activities/Consultant"
import Modal from "../../common/modal/Modal"
import FileUploadToMultipleCertificates from "../../mypagecomponents/document/FileUploadToMultipleCertificates"
import DocumentListMultipleActivities from "../../mypagecomponents/document/DocumentListMultipleActivities"

const NewSpecializationActivityContent = ({ location }) => {
  const queryClient = useQueryClient()
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const [selectedActivityType, setSelectedActivityType] = useState("0")
  const [specialityActivityId, setSpecialityActivityId] = useState("")
  const [incidentId, setIncidentId] = useState("")
  const [comment, setComment] = useState("")
  const [incidentTitle, setIncidentTitle] = useState("")
  const [documents, setDocuments] = useState([])
  const [filteredSpesialistActivityTypes, setFilteredSpesialistActivityTypes] =
    useState([])
  const [showLoadingData, setShowLoadingData] = useState(false)
  // default = Godkjenning av spesialiseringsaktivitet
  const [subjectId, setSubjectId] = useState<string>(
    "ad3bcfb0-eea5-ec11-983f-000d3aae55a4"
  )
  const [showDocumentComponent, setShowDocumentComponent] = useState(false)
  const [showActivityButtons, setShowActivityButtons] = useState(true)
  const [disableActivitySelect, setDisableActivitySelect] = useState(false)

  const [firstStep, setFirstStep] = useState("current")
  const [secondStep, setSecondStep] = useState("upcoming")
  const [firstStepCompleted, setFirstStepCompleted] = useState<boolean>(false)
  const [secondStepCompleted, setSecondStepCompleted] = useState<boolean>(false)
  const [currentStep, setCurrentStep] = useState(0)
  const [showModal, setShowModal] = useState(false)
  const [listOfIds, setListOfIds] = useState([])
  const [documentsWithId, setDocumentsWithId] = useState([])

  useEffect(() => {
    // Vedlikehold
    if (
      location.state.program?.programType.id ==
      "c0c4d26e-c5a6-ec11-983f-000d3aae55a4"
    )
      setSelectedActivityType("292460006")

    setFilteredSpesialistActivityTypes(spesialistActivityTypes)

    if (
      location.state.program?.programType.id !==
      "ee9bb462-c5a6-ec11-983f-000d3aae55a4"
    ) {
      // Obligatorisk program
      const filtered = spesialistActivityTypes.filter(
        r => r.id !== 292460003 && r.id !== 292460007 && r.id !== 292460008
      )
      setFilteredSpesialistActivityTypes(filtered) // Skriftlig arbeid
    } else {
      if (
        location.state.program?.requirementsSpecialistEducation?.id !==
        "0d95b3c1-e8d4-ec11-a7b5-000d3a4a5585"
      ) {
        // Psykoterapi
        const filtered = spesialistActivityTypes.filter(r => r.id !== 292460007)
        setFilteredSpesialistActivityTypes(filtered) //Skriftlig arbeid og Egenaktivitet
      }
      if (
        location.state.program?.requirementsSpecialistEducation?.id !==
        "5735cd4e-e9d4-ec11-a7b5-000d3a4a5585"
      ) {
        // Organisasjonspsykologi
        const filtered = spesialistActivityTypes.filter(r => r.id !== 292460008)
        setFilteredSpesialistActivityTypes(filtered) //Skriftlig arbeid og Egenaktivitet
      }
    }
  }, [location])

  const steps: StepsType = [
    {
      id: "1",
      name: "Detaljer",
      status: firstStep,
      completed: firstStepCompleted,
    },
    {
      id: "2",
      name: "Send inn ",
      status: secondStep,
      completed: secondStepCompleted,
    },
  ]

  const mutationCreateIncident = useMutation(
    data => {
      return postApi(
        process.env.GATSBY_API_URL + "/Incidents",
        {
          title: incidentTitle,
          subjectId: subjectId,
          caseTypeCode: 8,
          caseOriginCode: 3,
          status: 292460000, //Kladd
          state: 0,
          approveStatus: 292460000, //Ikke fullført registrering
          comment: comment,
          educationId: location.state.program.specialistEducation?.id,
          activitySpecialistProgramId: data,
          specialistProgramId: location.state.program?.id,
        },
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: data => {
        setDisableActivitySelect(true)
        setIncidentId(data)
        setShowActivityButtons(false)
        setShowDocumentComponent(true)
        setFirstStep("complete")
        setSecondStep("current")
        console.log("Incident")

        queryClient.invalidateQueries([
          "programSpecializationActivitiesData-" + location.state.program?.id,
        ])
        queryClient.invalidateQueries([
          "educationSpecializationActivitiesData-" +
            location.state.specialityEducation?.id,
        ])
        setShowLoadingData(false)
      },
      onError: _error => {
        setShowActivityButtons(true)
        setShowDocumentComponent(false)
      },
      onSettled: () => {},
    }
  )

  type guidanceCertificateObject = {
    id: string
    comment: string
    educationId: string
    specialistProgramId: string
  }

  const mutationCreateGuidanceCertificateIncident = useMutation(
    data => {
      return postApi(
        process.env.GATSBY_API_URL + "/Incidents",
        {
          title: incidentTitle,
          subjectId: subjectId,
          caseTypeCode: 8,
          caseOriginCode: 3,
          status: 292460000, //Kladd
          state: 0,
          approveStatus: 292460000, //Ikke fullført registrering
          comment: data.comment,
          educationId: data.educationId,
          activitySpecialistProgramId: data.id,
          specialistProgramId: data.specialistProgramId,
        },
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: (data, variables) => {
        setDisableActivitySelect(true)
        setIncidentId(data)
        setShowActivityButtons(false)
        setShowDocumentComponent(true)
        setFirstStep("complete")
        setSecondStep("current")
        console.log("Incident")
        const createList = {
          incidentId: data,
          activityId: variables.id,
          educationId: variables.educationId,
        }
        setListOfIds(list => [...list, createList])

        queryClient.invalidateQueries([
          "programSpecializationActivitiesData-" + location.state.program?.id,
        ])
        queryClient.invalidateQueries([
          "educationSpecializationActivitiesData-" +
            location.state.specialityEducation?.id,
        ])
        setShowLoadingData(false)
      },
      onError: _error => {
        setShowActivityButtons(true)
        setShowDocumentComponent(false)
      },
      onSettled: () => {},
    }
  )

  const mutationCreateAcitivity = useMutation(
    data => {
      return postApi(
        process.env.GATSBY_API_URL + "/SpecializationActivities",
        data,
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: (data, variables) => {
        setSpecialityActivityId(data)
        // Eksternt kurs
        if (selectedActivityType === "292460000")
          setSubjectId("eccca80d-dcb0-ec11-9840-000d3abf775d")

        if (selectedActivityType === "292460004") {
          const guidanceCertificateObject: guidanceCertificateObject = {
            id: data,
            comment: variables.comment,
            educationId: variables.educationId,
            specialistProgramId: variables.specialistProgramId,
          }
          mutationCreateGuidanceCertificateIncident.mutate(
            guidanceCertificateObject
          )
        } else {
          mutationCreateIncident.mutate(data)
        }
      },
      onError: _error => {
        setShowLoadingData(false)
      },
      onSettled: () => {},
    }
  )

  const mutationGetDocuments = useMutation(
    () => {
      return postGetDocumentApi(
        process.env.GATSBY_API_URL + "/Documents/Regarding/Get",
        {
          ActivitySpecialistProgramId: specialityActivityId,
        },
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: data => {
        setDocuments(data)
      },
      onError: _error => {},
      onSettled: () => {},
    }
  )

  const mutationUpdateSpesActivity = useMutation(
    () => {
      return patchApi(
        process.env.GATSBY_API_URL + "/SpecializationActivities",
        {
          id: specialityActivityId,
          approveStatus: 778380001, //Til godkjenning
        },
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: () => {
        setShowDocumentComponent(false)
        mutationUpdateIncident.mutate()
      },
      onError: _error => {},
      onSettled: () => {},
    }
  )

  //Patch multiple acitivties
  const mutationUpdateSpesActivities = useMutation(
    data => {
      return patchApi(
        process.env.GATSBY_API_URL + "/SpecializationActivities",
        {
          id: data.activityId,
          approveStatus: 778380001, //Til godkjenning
        },
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: (data, variables) => {
        setShowDocumentComponent(false)
        mutationUpdateIncidents.mutate(variables)
      },
      onError: _error => {},
      onSettled: () => {},
    }
  )

  const mutationUpdateIncident = useMutation(
    () => {
      return patchApi(
        process.env.GATSBY_API_URL + "/Incidents",
        {
          id: incidentId,
          status: 1, //Pågår,
          approveStatus: 778380001, // Til godkjenning
        },
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: () => {
        navigate("/app/kurs/specialityeducation/program", {
          state: { program: location.state.program },
        })
      },
      onError: _error => {},
      onSettled: () => {},
    }
  )

  //Patch multiple incidents
  const mutationUpdateIncidents = useMutation(
    data => {
      return patchApi(
        process.env.GATSBY_API_URL + "/Incidents",
        {
          id: data.incidentId,
          status: 1, //Pågår,
          approveStatus: 778380001, // Til godkjenning
        },
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: () => {
        navigate("/app/kurs/specialityeducation/program", {
          state: { program: location.state.program },
        })
      },
      onError: _error => {},
      onSettled: () => {},
    }
  )

  const PatchIncidentAndActivity = async e => {
    e.preventDefault()
    if (documents.length == 0) {
      toast.warning("Vennligst last opp en eller flere filer!", {
        position: "top-center",
        autoClose: 10000,
      })

      return
    }
    mutationUpdateSpesActivity.mutate()
  }

  //If the system have to patch multiple incidents and acitivites
  const PatchIncidentsAndActivities = async e => {
    e.preventDefault()
    if (documents.length == 0) {
      toast.warning("Vennligst last opp en eller flere filer!", {
        position: "top-center",
        autoClose: 10000,
      })

      return
    }
    listOfIds.forEach(idObject => {
      mutationUpdateSpesActivities.mutate(idObject)
    })
  }

  return (
    <div>
      <div>{showLoadingData && <LoadingData subject="Laster..." />}</div>
      <MyPageLayoutEducation props="Ny Spesialiseringsaktivitet">
        <Link
          to="/app/kurs/specialityeducation/program"
          state={{ program: location.state.program }}
          className={
            whiteButton +
            " inline-flex items-center justify-center focus:ring-offset-2 sm:w-auto "
          }
          activeClassName="active-link"
        >
          Tilbake
        </Link>

        <div className="space-y-6 mt-8">
          {/* {selectedActivityType === "292460005" ? ( */}
          <VisualStep steps={steps} />
          {/* ) : null} */}
          <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
            <div className="md:grid md:grid-cols-3 md:gap-6">
              <div className="md:col-span-1">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Aktivitetstype
                </h3>
                <div className="mt-1.5 relative">
                  <select
                    id="actyvitytype"
                    name="actyvitytype"
                    disabled={disableActivitySelect}
                    defaultValue={selectedActivityType}
                    onChange={e => setSelectedActivityType(e.target.value)}
                    className="appearance-none block w-full border-gray-300  border-transparent rounded-md pl-3 pr-10 py-2 text-base focus:outline-none focus:ring-meny-700 focus:border-meny-500 sm:text-sm"
                    value={selectedActivityType}
                  >
                    <option disabled selected value="0">
                      -- Aktivitetstype --
                    </option>
                    {location.state.program?.programType.id ==
                    "c0c4d26e-c5a6-ec11-983f-000d3aae55a4" ? (
                      <>
                        {spesialistActivityTypesMaintenance?.map(a => (
                          <option key={a.id} value={a.id}>
                            {a.name}
                          </option>
                        ))}
                      </>
                    ) : (
                      <>
                        {filteredSpesialistActivityTypes?.map(a => (
                          <option key={a.id} value={a.id}>
                            {a.name}
                          </option>
                        ))}
                      </>
                    )}
                  </select>
                  <div className="pointer-events-none absolute inset-y-0 right-0 px-2 flex items-center">
                    <ChevronDownIcon
                      className="h-4 w-4 text-white"
                      aria-hidden="true"
                    />
                  </div>
                </div>
              </div>
              {selectedActivityType == "292460001" ? (
                <NewPracticeCertificateActivity
                  // specialityEducation={location.state.specialityEducation}
                  program={location.state.program}
                  setFirstStep={setFirstStep}
                  setSecondStep={setSecondStep}
                  setShowLoadingData={setShowLoadingData}
                  setDisableActivitySelect={setDisableActivitySelect}
                />
              ) : (
                <div className="mt-5 md:mt-0 md:col-span-2">
                  {showActivityButtons ? (
                    <div>
                      {selectedActivityType == "292460000" ? (
                        <ExternalCourse
                          program={location.state.program}
                          mutationCreateAcitivity={mutationCreateAcitivity}
                          setComment={setComment}
                          setIncidentTitle={setIncidentTitle}
                          setShowLoadingData={setShowLoadingData}
                          showActivityButtons={showActivityButtons}
                        />
                      ) : null}
                      {/* {selectedActivityType === "292460001" ? (
                      <PracticeCertificate
                        program={location.state.program}
                        mutationCreateAcitivity={mutationCreateAcitivity}
                        // mutationCreatePracticeHours={
                        //   mutationCreatePracticeHours
                        // }
                        setComment={setComment}
                        setIncidentTitle={setIncidentTitle}
                        setShowLoadingData={setShowLoadingData}
                        showActivityButtons={showActivityButtons}
                      />
                    ) : null} */}
                      {selectedActivityType === "292460003" ? (
                        <WrittenWork
                          program={location.state.program}
                          mutationCreateAcitivity={mutationCreateAcitivity}
                          setComment={setComment}
                          setIncidentTitle={setIncidentTitle}
                          setShowLoadingData={setShowLoadingData}
                          showActivityButtons={showActivityButtons}
                        />
                      ) : null}
                      {selectedActivityType === "292460004" ? (
                        <GuidanceCertificate
                          program={location.state.program}
                          mutationCreateAcitivity={mutationCreateAcitivity}
                          setComment={setComment}
                          setIncidentTitle={setIncidentTitle}
                          setShowLoadingData={setShowLoadingData}
                          showActivityButtons={showActivityButtons}
                        />
                      ) : null}
                      {selectedActivityType === "292460002" ? (
                        <GuidanceContract
                          program={location.state.program}
                          mutationCreateAcitivity={mutationCreateAcitivity}
                          setComment={setComment}
                          setIncidentTitle={setIncidentTitle}
                          setShowLoadingData={setShowLoadingData}
                          showActivityButtons={showActivityButtons}
                        />
                      ) : null}
                      {selectedActivityType === "292460006" ? (
                        <GuidanceActivity
                          program={location.state.program}
                          mutationCreateAcitivity={mutationCreateAcitivity}
                          setComment={setComment}
                          setIncidentTitle={setIncidentTitle}
                          setShowLoadingData={setShowLoadingData}
                          showActivityButtons={showActivityButtons}
                        />
                      ) : null}
                      {selectedActivityType === "292460005" ? (
                        <SupervisorsApproval
                          program={location.state.program}
                          mutationCreateAcitivity={mutationCreateAcitivity}
                          setComment={setComment}
                          setIncidentTitle={setIncidentTitle}
                          setShowLoadingData={setShowLoadingData}
                          showActivityButtons={showActivityButtons}
                        />
                      ) : null}
                      {selectedActivityType === "292460007" ? (
                        <Personal
                          program={location.state.program}
                          mutationCreateAcitivity={mutationCreateAcitivity}
                          setComment={setComment}
                          setIncidentTitle={setIncidentTitle}
                          setShowLoadingData={setShowLoadingData}
                          showActivityButtons={showActivityButtons}
                        />
                      ) : null}
                      {selectedActivityType === "292460008" ? (
                        <Consultant
                          program={location.state.program}
                          mutationCreateAcitivity={mutationCreateAcitivity}
                          setComment={setComment}
                          setIncidentTitle={setIncidentTitle}
                          setShowLoadingData={setShowLoadingData}
                          showActivityButtons={showActivityButtons}
                        />
                      ) : null}
                    </div>
                  ) : null}

                  {showDocumentComponent ? (
                    <div>
                      {/* Hjelpetekst på konsulentsyklus */}
                      {selectedActivityType === "292460008" ? (
                        <>
                          <GetHelpText number={1007} />
                        </>
                      ) : (
                        <HelperText
                          helpertext={
                            "Last opp dokumentasjon og send inn aktiviteten under boksen."
                          }
                        />
                      )}

                      {/* Hjelpetekst på praksisattest */}
                      {selectedActivityType === "292460001" ? (
                        <>
                          <GetHelpText number={1002} />
                        </>
                      ) : (
                        <></>
                      )}
                      <p className="mt-2 text-sm text-gray-600"></p>
                      <p className="mt-2 text-sm text-gray-600"></p>
                      {selectedActivityType === "292460004" ? (
                        <>
                          {" "}
                          <div className="mt-2">
                            <DocumentListMultipleActivities
                              documents={documents}
                              mutationGetDocuments={mutationGetDocuments}
                              canDelete={true}
                              documentsWithId={documentsWithId}
                              setShowLoadingData={setShowLoadingData}
                            />
                          </div>
                          <FileUploadToMultipleCertificates
                            entityArray={listOfIds}
                            entityName={"incident"}
                            entityName2={"pp_activityspecialistprogram"}
                            entityName3={"pp_education"}
                            mutationGetDocuments={mutationGetDocuments}
                            setDocuments={null}
                            isRequired={true}
                            isReturn={false}
                            setDocumentsWithId={setDocumentsWithId}
                          />
                          <div className="pt-5">
                            <div className="flex justify-center">
                              <button
                                type="button"
                                onClick={e => PatchIncidentsAndActivities(e)}
                                className={
                                  cyanButton + "inline-flex items-center "
                                }
                              >
                                Send Inn
                              </button>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="mt-2">
                            <DocumentList
                              documents={documents}
                              mutationGetDocuments={mutationGetDocuments}
                              canDelete={true}
                            />
                          </div>
                          <FileUpload
                            entityId={incidentId}
                            entityName={"incident"}
                            entityId2={specialityActivityId}
                            entityName2={"pp_activityspecialistprogram"}
                            entityId3={
                              location.state.program?.specialistEducation?.id
                            }
                            entityName3={"pp_education"}
                            mutationGetDocuments={mutationGetDocuments}
                            setDocuments={null}
                            isRequired={true}
                            isReturn={false}
                          />
                          <div className="pt-5">
                            <div className="flex justify-center">
                              <button
                                type="button"
                                onClick={e => PatchIncidentAndActivity(e)}
                                className={
                                  cyanButton + "inline-flex items-center "
                                }
                              >
                                Send Inn
                              </button>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  ) : null}
                </div>
              )}
            </div>
          </div>
        </div>
      </MyPageLayoutEducation>
    </div>
  )
}

const NewSpecializationActivity = ({ location }) => {
  const authRequest = {
    ...loginRequest,
  }
  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      <NewSpecializationActivityContent location={location} />
    </MsalAuthenticationTemplate>
  )
}

export default NewSpecializationActivity
