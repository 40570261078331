import { useAccount, useMsal } from "@azure/msal-react"
import React, { useEffect, useState } from "react"
import { fetchApi } from "../../../Utils/Api.utils"
import { Representatives } from "../../../Utils/Entities"

const Tillitsvalgt = props => {
  const workPlace = props?.workPlace
  const employer = props?.employer

  const [empTV, setEmpTV] = useState<Representatives[]>()
  const [placeTV, setPlaceTV] = useState<Representatives[]>()

  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})

  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    const getEmpRep = async () => {
      fetchApi(
        `${process.env.GATSBY_API_URL}/Accounts/${employer}/Representatives`,
        account,
        inProgress,
        instance
      )
        .then(response => {
          setEmpTV(response.data)
          setIsLoading(false)
        })
        .catch(error => {})
    }
    const getPlaceRep = async () => {
      fetchApi(
        `${process.env.GATSBY_API_URL}/Accounts/${workPlace}/Representatives`,
        account,
        inProgress,
        instance
      )
        .then(response => {
          setPlaceTV(response.data)
          setIsLoading(false)
        })
        .catch(error => {})
    }
    if (employer !== undefined) {
      getEmpRep()
    }
    if (workPlace !== undefined) {
      getPlaceRep()
    }
  }, [])

  //   console.log(empTV?.(1).unionPositionInBusinessName)
  //   if (empTV)
  //     console.log(empTV[1].unionPositionInBusinessName.replaceAll("_", " "))

  return isLoading ? (
    <p className="text-meny-700 italic">Laster...</p>
  ) : (
    <div className="mt-8 flex flex-col">
      <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          {(workPlace === undefined || placeTV?.length === 0) &&
          (empTV?.length === 0 || employer === undefined) ? (
            <p className="text-meny-700 italic">
              Virksomhetene har foreløpig ingen tillitsvalgte.
            </p>
          ) : (
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-meny-700 text-meny-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-6"
                    >
                      Navn
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm mob:hidden font-semibold table2:hidden"
                    >
                      Rolle
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold "
                    >
                      Mobil
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold table1:hidden"
                    >
                      E-postadresse
                    </th>

                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold mob:hidden"
                    >
                      Virksomhet
                    </th>
                  </tr>
                </thead>
                {empTV ? (
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {empTV.map(e => (
                      <tr key={e.contact?.mobilePhone}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {e?.contact?.firstName} {e?.contact?.lastName}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-600 table2:hidden">
                          {e?.unionPositionInBusinessName?.replaceAll("_", " ")}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-600">
                          {e?.contact?.mobilePhone}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-600 table1:hidden ">
                          {e?.contact?.emailAddress1}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-600 mob:hidden">
                          {e?.account?.name}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                ) : (
                  <div></div>
                )}
                {placeTV ? (
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {placeTV.map(e => (
                      <tr key={e.contact?.mobilePhone}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {e?.contact?.firstName} {e?.contact?.lastName}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-600 table2:hidden">
                          {e?.unionPositionInBusinessName?.replaceAll("_", " ")}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-600">
                          {e?.contact?.mobilePhone}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-600 table1:hidden">
                          {e?.contact?.emailAddress1}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-600 mob:hidden">
                          {e?.account?.name}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                ) : (
                  <div></div>
                )}
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Tillitsvalgt
