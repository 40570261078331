import { useAccount, useMsal } from "@azure/msal-react"
import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { useMutation, useQueryClient } from "react-query"
import { postApi } from "../../../../Utils/Api.utils"
import ReactDatePicker from "react-datepicker"
import FileUpload from "../../document/FileUpload"
import { navigate } from "gatsby"
import { typeWrittenWork } from "../../../../Utils/optionSet"
import HelperText from "../../../common/text/HelperText"
import { DropDown } from "../../../common/dropDown"
import { toast } from "react-toastify"
import { Activity, SpecialistProgram } from "../../../../Utils/Types"
import { ErrorMessage } from "@hookform/error-message"
const WrittenWorkEdit = props => {
  const program: SpecialistProgram = props.program
  const activity: Activity = props.activity
  const queryClient = useQueryClient()
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const [activityComment, setActivityComment] = useState(activity.comment)
  const [startDate, setStartDate] = useState(activity.fromDate)
  const [selectedTypeWrittenWork, SetSelectedTypeWrittenWork] = useState(
    activity.typeWrittenReport
  )
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm()

  const OnChangeTypeWrittenWork = e => SetSelectedTypeWrittenWork(e)

  const patchActivity = async data => {
    data.id = activity.id
    data.fromDate = startDate
    data.comment = activityComment

    data.typeWrittenReport = selectedTypeWrittenWork
    console.log(data)
    props.setShowLoadingData(true)
    props.mutationUpdateActivity.mutate(data)
  }

  return (
    <div>
      <form method="PATCH" onSubmit={handleSubmit(patchActivity)}>
        <div className="grid grid-cols-6 gap-6">
          <div className="col-span-6">
            <label
              htmlFor="first-name"
              className="block text-sm font-medium text-gray-700"
            >
              Fra dato
            </label>
            <ReactDatePicker
              id="startDate"
              locale="nb"
              showYearDropdown
              selected={Date.parse(startDate)}
              onChange={(date: Date) => setStartDate(date)}
              dateFormat="dd.MM.yyyy"
              className="block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
            />
          </div>

          <div className="col-span-6 sm:col-span-3">
            <label
              htmlFor="titleWrittenReport"
              className="block text-sm font-medium text-gray-700"
            >
              Tittel*
            </label>
            <input
              {...register("titleWrittenReport", {
                required: { value: true, message: "Obligatorisk felt." },
              })}
              type="text"
              required
              defaultValue={activity.titleWrittenReport}
              name="titleWrittenReport"
              id="titleWrittenReport"
              className="mt-1 focus:ring-meny-700 focus:border-meny-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            />
            <ErrorMessage
              errors={errors}
              name="titleWrittenReport"
              render={({ message }) => (
                <p className="text-sm text-red-600">{message}</p>
              )}
            />
          </div>

          <div className="col-span-6 sm:col-span-3">
            <label
              htmlFor="typeWrittenReport"
              className="block text-sm font-medium text-gray-700"
            >
              Type*
            </label>
            <select
              id="typeWrittenReport"
              name="typeWrittenReport"
              onChange={e => OnChangeTypeWrittenWork(e.target.value)}
              value={selectedTypeWrittenWork}
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-meny-700 focus:border-meny-500 sm:text-sm"
            >
              {/* <option disabled selected value="0">
                --velg--
              </option> */}

              {typeWrittenWork?.map(a => (
                <option
                  key={a.id}
                  value={a.id}
                  defaultChecked={a.id === activity?.typeWrittenReport}
                >
                  {a.name}
                </option>
              ))}
            </select>
          </div>

          <div className="col-span-6">
            <label
              htmlFor="comment"
              className="block text-sm font-medium text-gray-700"
            >
              Kommentar
            </label>
            <div className="mt-1">
              <textarea
                id="comment"
                {...register("comment", {
                  required: false,
                  maxLength: {
                    value: 2000,
                    message: "Maks antall tegn er 2000",
                  },
                })}
                onChange={e => {
                  setActivityComment(e.target.value)
                }}
                rows={3}
                className="shadow-sm focus:ring-meny-700 focus:border-meny-500 block w-full sm:text-sm border border-gray-300 rounded-md"
                placeholder="Kommentar"
                defaultValue={activity.comment}
              />
              <ErrorMessage
                errors={errors}
                name="comment"
                render={({ message }) => (
                  <p className="text-sm text-red-600">{message}</p>
                )}
              />
            </div>
            <HelperText helpertext="Skriftlig arbeid skal leveres 1 gang pr. spesialitet, og leveres på Obligatorisk program." />
          </div>
        </div>

        <div className="mt-8 border-t flex justify-end">
          <button
            type="submit"
            className="ml-3 mt-6 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-meny-600 hover:bg-meny-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
          >
            Oppdater
          </button>
        </div>
      </form>
    </div>
  )
}

export default WrittenWorkEdit
