import { Fragment, useEffect, useState } from "react"
import { Menu, Transition } from "@headlessui/react"
import { ChevronDownIcon } from "@heroicons/react/solid"
import React from "react"
import { DropDownType } from "./DropDownType"

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

const DropDown = (props: DropDownType) => {
  const [parent, setParent] = useState(props.defaultParent || "Velg")

  const OptionChosen = e => {
    setParent(
      props.options.find(item => item.id === e.target.value)?.label ||
        props.defaultParent ||
        "Velg"
    )
    props.setParentValue(e.target.value)
  }

  useEffect(() => {
    if (props.defaultParent !== undefined) setParent(props.defaultParent)
    if (!props.parentValue) return
    setParent(props.parentValue)
  }, [props.parentValue])

  return (
    <Menu
      as="div"
      className="shadow-sm focus:ring-meny-700 focus:border-meny-500 block w-full sm:text-sm border-gray-300 rounded-md"
    >
      <div>
        <Menu.Button className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-meny-700">
          {parent}
          <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="w-full origin-top-right absolute right-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1 w-full">
            {props.options.map(item => (
              <Menu.Item key={item.id}>
                {({ active }) => (
                  <button
                    type="button"
                    value={item.id}
                    onClick={e => OptionChosen(e)}
                    disabled={item.disabled}
                    className={classNames(
                      active && !item.disabled
                        ? "bg-gray-100 text-gray-900"
                        : "text-gray-700",
                      item.disabled ? "opacity-50 cursor-not-allowed" : "",
                      "block px-4 py-2 text-sm w-full"
                    )}
                  >
                    {item.label}
                  </button>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

export default DropDown
