import React, { Fragment, useState } from "react"
import { Popover, Transition } from "@headlessui/react"

export type HoverTextBoxType = {
    text: string
    icon: React.FC
}

const HoverTextBox = (props: HoverTextBoxType) => {
    const [openDropdown, setOpenDropdown] = useState(false)
    const [mouseOverButton, setMouseOverButton] = useState(false)

    const timeoutDuration = 200
    let timeoutButton

    const show = openDropdown && mouseOverButton

    const onMouseEnterButton = () => {
        clearTimeout(timeoutButton)
        setOpenDropdown(true)
        setMouseOverButton(true)
    }

    const onMouseLeaveButton = () => {
        timeoutButton = setTimeout(() => setMouseOverButton(false), timeoutDuration)
    }

    return (
        <Popover className="relative h-5">
            {({ open }) => (
                <>
                    <Popover.Button
                        onMouseEnter={onMouseEnterButton}
                        onMouseLeave={onMouseLeaveButton}
                        className={`
                ${open ? "" : "text-opacity-90"}
                `}
                    >
                        <span>
                            {/* <svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM8.00001 5C7.63113 5 7.3076 5.19922 7.13318 5.50073C6.85664 5.97879 6.24491 6.14215 5.76685 5.86561C5.28879 5.58906 5.12543 4.97733 5.40197 4.49927C5.91918 3.60518 6.88833 3 8.00001 3C9.65687 3 11 4.34315 11 6C11 7.30622 10.1652 8.41746 9.00001 8.82929V9C9.00001 9.55228 8.5523 10 8.00001 10C7.44773 10 7.00001 9.55228 7.00001 9V8C7.00001 7.44772 7.44773 7 8.00001 7C8.5523 7 9.00001 6.55228 9.00001 6C9.00001 5.44772 8.5523 5 8.00001 5ZM8 13C8.55228 13 9 12.5523 9 12C9 11.4477 8.55228 11 8 11C7.44772 11 7 11.4477 7 12C7 12.5523 7.44772 13 8 13Z"
                  fill="#485156"
                />
              </svg> */}
                            <props.icon />
                        </span>
                    </Popover.Button>
                    <Transition
                        show={show}
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                    >
                        <Popover.Panel className="absolute right-0 z-10  max-w-sm px-4 transform sm:px-0 lg:max-w-1xl">
                            <div className="w-80">
                                <div className="inline-flex flex-col space-y-2 items-start justify-start h-full">
                                    <div className="w-full" />
                                    <div className="inline-flex space-x-4 items-start justify-start w-full p-4 bg-white shadow rounded-lg">
                                        <div className="flex space-x-3 items-start justify-start flex-1">
                                            {/* <props.icon /> */}
                                            <div className="inline-flex flex-col space-y-1 items-start justify-start flex-1 pt-0.5">
                                                <p className="w-full text-sm leading-tight text-gray-400 text-center">{props.text}</p>
                                            </div>
                                        </div>
                                        {/* <div className="bg-white rounded-md">
                                            <props.icon />
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </Popover.Panel>
                    </Transition>
                </>
            )}
        </Popover>
    )
}

export default HoverTextBox
