import { InteractionType } from "@azure/msal-browser"
import {
  MsalAuthenticationTemplate,
  useAccount,
  useMsal,
} from "@azure/msal-react"
import { Link } from "gatsby"
import React, { useEffect, useState } from "react"
import { useQuery } from "react-query"
import { fetchApi, postApi } from "../../../Utils/Api.utils"
import { Loading } from "../../../Utils/Loading"
import { ErrorComponent } from "../../common/graphichs/ErrorComponent"
import MyPageLayout from "../../common/layouts/myPageLayout"
import HeaderText from "../../common/text/Header"
import { loginRequest } from "../../security/authConfig"

const AdminCheckinContent = props => {
  const session = props.props
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})

  let contactList: string[] = []

  const [isLoading, setIsLoading] = useState<boolean>()

  const sessionRegistrationsQuery = useQuery("sessionRegistrations", async () =>
    fetchApi(
      process.env.GATSBY_API_URL +
      `/EventAdmin/sessionRegistrations?id=${session?.sessionid}`,
      account,
      inProgress,
      instance
    ).then(res => res.data)
  )

  useEffect(() => {
    setIsLoading(!sessionRegistrationsQuery.isSuccess)
  }, [sessionRegistrationsQuery.isSuccess])

  const updateList = event => {
    if (event.target.checked) contactList.push(event?.target?.id)
    else {
      const index = contactList.indexOf(event.target.id)
      if (index > -1) contactList.splice(index, 1)
    }
    const reg = event.target.value
  }

  const postCheckin = () => {
    const post = contactId => {
      const data = {}
      postApi(
        process.env.GATSBY_API_URL + `/EventAdmin/checkin`,
        data,
        account,
        inProgress,
        instance
      )
    }
  }

  return (
    <MyPageLayout props="Registrer oppmøte">
      <HeaderText text={session?.name} />
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-meny-700 text-meny-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-6"
                    >
                      Navn
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm mob:hidden font-semibold table2:hidden"
                    >
                      Primær rolle
                    </th>

                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold mob:hidden"
                    >
                      Status
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold"
                    >
                      Innsjekk
                    </th>
                  </tr>
                </thead>

                {sessionRegistrationsQuery?.data?.map(registration => {
                  return (
                    <tbody className="divide-y divide-gray-200 bg-white">
                      <tr key={registration?.contactid}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {registration?.contact?.firstName}{" "}
                          {registration?.contact?.lastName}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-600 table2:hidden">
                          {registration?.primaryRoleName}
                        </td>

                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-600 mob:hidden">
                          {registration?.attendeeName?.replaceAll("_", " ")}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-600 ">
                          <input
                            id={registration?.contactid}
                            onChange={updateList}
                            value={registration}
                            type="checkbox"
                            className="focus:ring-meny-700 h-4 w-4 text-meny-600 border-gray-300 rounded"
                          />
                        </td>
                      </tr>
                    </tbody>
                  )
                })}
              </table>
            </div>
          </div>
        </div>
        <div className="flex justify-center">
          <p className="mt-3 max-w-md mx-auto text-base text-gray-600 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
            Gå tilbake til oversikten over{" "}
            <Link
              to="/app/kurs/administrasjon"
              className="text-meny-700 font-bold hover:text-meny-600"
            >
              mine kurs
            </Link>
            .
          </p>
        </div>
      </div>
    </MyPageLayout>
  )
}

const AdminCheckin = ({ location }) => {
  const authRequest = {
    ...loginRequest,
  }
  const session = location?.state?.session

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      <AdminCheckinContent props={session} />
    </MsalAuthenticationTemplate>
  )
}

export default AdminCheckin
