import React from "react"
import { MultipleChoiceType } from "./InputFieldsType"

const MultipleChoice = (props: MultipleChoiceType) => {
  const onChange = (e: any, name: string) => {
    if (e.target.checked) {
      props.control("add", name)
    } else {
      props.control("remove", name)
    }
  }

  return (
    <div className="flex flex-col">
      <span className="font-bold">
        {props.text + (props.isRequired ? "* " : " ")}{" "}
      </span>
      {props.Choices.map(item => (
        <div key={item}>
          <input
            type="checkbox"
            onChange={e => onChange(e, item)}
            defaultChecked={props.selected?.includes(item)}
          />
          <span> {item} </span>
        </div>
      ))}
      <div className="h-8"></div>
    </div>
  )
}

export default MultipleChoice
