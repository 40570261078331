import { InteractionType } from "@azure/msal-browser"
import {
  MsalAuthenticationTemplate,
  useAccount,
  useMsal,
} from "@azure/msal-react"
import { CheckIcon } from "@heroicons/react/outline"
import { Link } from "gatsby"
import React, { useState } from "react"
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
  useQueryClient,
} from "react-query"
import { cyanButton } from "../../../styles/tailwindClasses"
import { fetchApi } from "../../../Utils/Api.utils"
import { RemoveUnderScore } from "../../../Utils/FormatHelper"
import { Loading } from "../../../Utils/Loading"
import { ErrorComponent } from "../../common/graphichs/ErrorComponent"
import LoadingData from "../../common/graphichs/LoadingData"
import MyPageLayoutEducation from "../../common/layouts/MyPageLayoutEducation"
import GetHelpText from "../../common/text/GetHelpText"
import EducationInfo from "../../mypagecomponents/specialityeducation/EducationInfo"
import Program from "../../mypagecomponents/specialityeducation/Program"
// import Programs from "../../mypagecomponents/specialityeducation/Programs.tsx.NOTINUSE"
import { loginRequest } from "../../security/authConfig"

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

const SpecialityEducationContent = () => {
  const queryClient = useQueryClient()
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const [specialityEducations, setSpecialityEducations] = useState([])
  const [SelectedSpecialityEducation, setSelectedSpecialityEducation] =
    useState([])
  const [showPrograms, setShowPrograms] = useState(false)
  const [specialities, setSpecialities] = useState([])
  const [speciality, setSpeciality] = useState()
  const [programs, setPrograms] = useState<any[]>()
  const [canCreatePrograms, setCanCreatePrograms] = useState(false)
  const [refetchDoc, setRefetchDoc] = useState(false)

  const specialitiesQuery = useQuery(
    "specialitiesData",
    () =>
      fetchApi(
        process.env.GATSBY_API_URL + "/Specialities/all",
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: data => {
        // console.log("0")
        // console.log(data)
        setSpecialities(data)
      },
      onError: _error => {},
    }
  )

  const userSpecialityEducationsQuery = useQuery(
    "userSpecialityEducationsData",
    () =>
      fetchApi(
        process.env.GATSBY_API_URL + "/SpecialistEducations/me",
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      enabled: !!specialities?.length,
      onSuccess: data => {
        // console.log("1")
        // console.log(data)
        setSpecialityEducations(data)

        if (data?.length > 0) {
          // console.log(data?.length)
          setShowPrograms(true)
          setSelectedSpecialityEducation(data[0])
          setRefetchDoc(true)

          const filteredData = specialities.filter(
            x => x.id == data[0].speciality?.id
          )

          if (filteredData.length > 0) setSpeciality(filteredData[0])
        }
      },
      onError: _error => {},
    }
  )

  const programsQuery = useQuery(
    "programTypesData",
    () =>
      fetchApi(
        process.env.GATSBY_API_URL + "/ProgramTypes/all",
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: data => {
        // console.log("3.1")
        // console.log(data)
        setPrograms(data)
      },
      onError: _error => {},
    }
  )

  const userProgramsQuery = useQuery(
    "userProgramsData-" + SelectedSpecialityEducation?.id,
    () =>
      fetchApi(
        process.env.GATSBY_API_URL +
          "/SpecialistPrograms/me/" +
          SelectedSpecialityEducation?.id,
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      enabled: !!SelectedSpecialityEducation?.id,
      onSuccess: data => {
        // console.log("3.2")
        // console.log(data)
        setPrograms(data)
        setCanCreatePrograms(true)
      },
      onError: _error => {},
    }
  )

  const onchange = e => {
    setCanCreatePrograms(false)

    const selectedEducation = userSpecialityEducationsQuery.data.filter(
      x => x.id === e
    )

    if (selectedEducation.length > 0) {
      setRefetchDoc(true)
      setShowPrograms(true)
      setSelectedSpecialityEducation(selectedEducation[0])
      const filteredData = specialities.filter(
        x => x.id == selectedEducation[0].speciality?.id
      )

      if (filteredData.length == 1) setSpeciality(filteredData[0])
      queryClient.invalidateQueries([
        "userProgramsData -" + selectedEducation[0]?.id,
      ])
    }
  }

  return userSpecialityEducationsQuery.isLoading ? (
    // // "Laster inn..."
    <LoadingData />
  ) : userSpecialityEducationsQuery.isError ? (
    userSpecialityEducationsQuery.error.message
  ) : (
    <div>
      <MyPageLayoutEducation props="Spesialistutdanning">
        <div className="bg-white rounded-lg shadow px-5 py-6 sm:px-6">
          {specialityEducations.length === 0 ? (
            <>
              <h1 className="text-3xl font-extrabold text-gray-900 sm:text-center">
                Velkommen til spesialistutdanning.
              </h1>
              <p className="mt-5 text-xl text-gray-600 sm:text-center">
                Starten på en spesialistutdanning initieres ved at du melder deg
                på Fellesprogrammet. De øvrige programløp kan du initiere selv
                eller ved å melde deg på et kurs innenfor obligatorisk eller
                valgfritt program. Praksis og veiledning kan godkjennes fra
                autorisasjonsdato.
              </p>
              <p className="mt-5 text-xl text-gray-600 sm:text-center">
                <Link
                  to="https://www.psykologforeningen.no/medlem/kurs-og-utdanning/block-forsideblokk-tosaker/spesialistutdanningen2"
                  className={
                    cyanButton +
                    " px-4 inline-flex items-center justify-center "
                  }
                  activeClassName="active-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Info
                </Link>
              </p>
              <p className="mt-5 text-xl text-gray-600 sm:text-center">
                Start spesialistutdanning{" "}
                <Link
                  to="/app/kurs/kurskalender?cat=Fellesprogram"
                  className="text-meny-700 font-bold hover:text-meny-600"
                >
                  Kurskalender
                </Link>
                .
              </p>
            </>
          ) : (
            <>
              <h1 className="text-3xl font-extrabold text-gray-900 sm:text-center">
                Programløp oversikt
              </h1>
              <p className="mt-5 text-xl text-gray-600 sm:text-center">
                Velg spesialistutdanning under for å få oversikt over dine
                programløp og velg ett av disse for å laste opp aktiviteter og
                dokumentasjon. For å melde deg på kurs går du inn i kurskalender
              </p>
            </>
          )}
          <div className="max-w-md mx-auto sm:max-w-3xl">
            <div className="mt-10">
              <ul
                role="list"
                className="mt-4 grid grid-cols-1 gap-4 sm:grid-cols-2"
              >
                {specialityEducations.map(
                  (specialityEducation, specialityEducationId) => (
                    <li key={specialityEducationId}>
                      <button
                        onClick={e => onchange(specialityEducation.id)}
                        type="button"
                        className="group p-2 w-full flex items-center justify-between rounded-full hover:bg-blue-300
                                            outline outline-black border shadow-sm space-x-3 text-left focus:outline-none
                                             focus:ring-2 focus:ring-offset-2 focus:ring-blue-600"
                      >
                        <span className="min-w-0 flex-1 flex items-center space-x-3">
                          <span className="block flex-shrink-0">
                            <CheckIcon />
                          </span>
                          <span className="block min-w-0 flex-1">
                            <span className="block text-sm font-medium text-gray-900 truncate">
                              Spesialistutdanning{" "}
                              {specialityEducation.speciality?.name}
                            </span>
                            {specialityEducation.approveStatus === 292460000 ? (
                              <span className="inline-flex rounded-full bg-blue-300 px-2 text-xs font-semibold leading-5 text-black">
                                {RemoveUnderScore(
                                  specialityEducation.approveStatusName
                                )}
                              </span>
                            ) : null}
                            {specialityEducation.approveStatus === 778380000 ? (
                              <span className="inline-flex rounded-full bg-yellow-300 px-2 text-xs font-semibold leading-5 text-black">
                                {RemoveUnderScore(
                                  specialityEducation.approveStatusName
                                )}
                              </span>
                            ) : null}
                            {specialityEducation.approveStatus === 778380001 ? (
                              <span className="inline-flex rounded-full bg-green-300 px-2 text-xs font-semibold leading-5 text-black">
                                {RemoveUnderScore(
                                  specialityEducation.approveStatusName
                                )}
                              </span>
                            ) : null}
                            {specialityEducation.approveStatus === 778380002 ? (
                              <span className="inline-flex rounded-full bg-red-300 px-2 text-xs font-semibold leading-5 text-black">
                                {RemoveUnderScore(
                                  specialityEducation.approveStatusName
                                )}
                              </span>
                            ) : null}
                          </span>
                        </span>
                      </button>
                    </li>
                  )
                )}
              </ul>
            </div>
          </div>
          <div className="max-w-7xl mx-auto py-24 px-4 sm:px-6 lg:px-8">
            <div className="sm:flex sm:flex-col sm:align-center">
              <EducationInfo
                setRefetchDoc={setRefetchDoc}
                refetchDoc={refetchDoc}
                SelectedSpecialityEducation={SelectedSpecialityEducation}
                specialityEducations={specialityEducations}
              />
            </div>
            {showPrograms ? (
              // <Programs
              //   SelectedSpecialityEducation={SelectedSpecialityEducation}
              //   speciality={speciality}
              // />
              <div>
                <div className="mt-8 space-y-4 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 lg:max-w-4xl lg:mx-auto xl:max-w-none xl:mx-0 xl:grid-cols-4">
                  <>
                    {speciality?.programList?.map(program => (
                      <>
                        {program.id ==
                        "7a958356-c5a6-ec11-983f-000d3aae55a4" ? ( // Fellesprogram
                          <Program
                            SelectedSpecialityEducation={
                              SelectedSpecialityEducation
                            }
                            myPrograms={programs}
                            programid={program.id}
                            name={program.name}
                            canCreate={false}
                            setCanCreatePrograms={setCanCreatePrograms}
                          />
                        ) : null}
                        {program.id ==
                        "ee9bb462-c5a6-ec11-983f-000d3aae55a4" ? ( // Obligatorisk program
                          <Program
                            SelectedSpecialityEducation={
                              SelectedSpecialityEducation
                            }
                            myPrograms={programs}
                            programid={program.id}
                            name={program.name}
                            canCreate={canCreatePrograms}
                            setCanCreatePrograms={setCanCreatePrograms}
                          />
                        ) : null}
                        {program.id ==
                        "e542c068-c5a6-ec11-983f-000d3aae55a4" ? ( // Valgfritt program
                          <Program
                            SelectedSpecialityEducation={
                              SelectedSpecialityEducation
                            }
                            myPrograms={programs}
                            programid={program.id}
                            name={program.name}
                            canCreate={canCreatePrograms}
                            setCanCreatePrograms={setCanCreatePrograms}
                          />
                        ) : null}
                        {SelectedSpecialityEducation.approveStatus ===
                          778380001 &&
                        program.id == "c0c4d26e-c5a6-ec11-983f-000d3aae55a4" ? ( // Vedlikehold, 778380001 == Godkjent
                          <Program
                            SelectedSpecialityEducation={
                              SelectedSpecialityEducation
                            }
                            myPrograms={programs}
                            programid={program.id}
                            name={program.name}
                            canCreate={false}
                            setCanCreatePrograms={setCanCreatePrograms}
                          />
                        ) : null}
                      </>
                    ))}
                  </>
                  <>
                    {speciality === undefined ? (
                      <>
                        {programsQuery?.data?.map(program => (
                          <>
                            {program.id ==
                            "7a958356-c5a6-ec11-983f-000d3aae55a4" ? ( // Fellesprogram
                              <Program
                                SelectedSpecialityEducation={
                                  SelectedSpecialityEducation
                                }
                                myPrograms={programs}
                                programid={program.id}
                                name={program.name}
                                canCreate={false}
                                setCanCreatePrograms={setCanCreatePrograms}
                              />
                            ) : null}
                            {program.id ==
                            "ee9bb462-c5a6-ec11-983f-000d3aae55a4" ? ( // Obligatorisk program
                              <Program
                                SelectedSpecialityEducation={
                                  SelectedSpecialityEducation
                                }
                                myPrograms={programs}
                                programid={program.id}
                                name={program.name}
                                canCreate={canCreatePrograms}
                                setCanCreatePrograms={setCanCreatePrograms}
                              />
                            ) : null}
                            {program.id ==
                            "e542c068-c5a6-ec11-983f-000d3aae55a4" ? ( // Valgfritt program
                              <Program
                                SelectedSpecialityEducation={
                                  SelectedSpecialityEducation
                                }
                                myPrograms={programs}
                                programid={program.id}
                                name={program.name}
                                canCreate={canCreatePrograms}
                                setCanCreatePrograms={setCanCreatePrograms}
                              />
                            ) : null}
                            {/* {program.id == "c0c4d26e-c5a6-ec11-983f-000d3aae55a4" ? ( // Vedlikehold
                                        <Program SelectedSpecialityEducation={SelectedSpecialityEducation} myPrograms={programs} programid={program.id} name={program.name} canCreate={true} />
                                    ) : null} */}
                          </>
                        ))}
                      </>
                    ) : null}
                  </>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </MyPageLayoutEducation>
    </div>
  )
}

const SpecialityEducation = () => {
  const authRequest = {
    ...loginRequest,
  }
  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      <QueryClientProvider client={queryClient}>
        <SpecialityEducationContent />
      </QueryClientProvider>
    </MsalAuthenticationTemplate>
  )
}

export default SpecialityEducation
