import { InteractionType } from "@azure/msal-browser"
import {
  MsalAuthenticationTemplate,
  useAccount,
  useMsal,
} from "@azure/msal-react"
import { Link, navigate } from "gatsby"
import React, { useEffect, useState } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { toast } from "react-toastify"
import {
  fetchApi,
  patchApi,
  postApi,
  postGetDocumentApi,
} from "../../../Utils/Api.utils"
import { Loading } from "../../../Utils/Loading"
import { ErrorComponent } from "../../common/graphichs/ErrorComponent"
import LoadingData from "../../common/graphichs/LoadingData"
import { classNames } from "../../common/graphichs/misc"
import MyPageLayout from "../../common/layouts/myPageLayout"
import HeaderText from "../../common/text/Header"
import HelperText from "../../common/text/HelperText"
import DocumentList from "../../mypagecomponents/document/DocumentList"
import DocumentListSessions from "../../mypagecomponents/document/DocumentListSessions"
import {
  CheckinAdmin,
  SessionCRM,
  SessionRegistrationCrm,
} from "../../mypagecomponents/event/EventType"
import SessionDocuments from "../../mypagecomponents/eventAdmin/SessionDocuments"
import { loginRequest } from "../../security/authConfig"

const SessionRegistrationListContent = props => {
  const session = props.props
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const [isLoading, setIsLoading] = useState<boolean>()
  const [detailsMissing, setDetailsMissing] = useState<boolean>(false)
  const [disableButton, setDisableButton] = useState<boolean>(false)
  const [showLoadingData, setShowLoadingData] = useState<boolean>(false)
  const [sessionRegistrations, setSessionRegistrations] = useState<
    SessionRegistrationCrm[]
  >([])

  const [access, setAccess] = useState<boolean>(false)

  const [sessionRefetch, setSessionRefetch] = useState<SessionCRM>()

  const [documents, setDocuments] = useState([])

  const queryClient = useQueryClient()

  useEffect(() => {
    const today = new Date()

    if (new Date(session?.endtime) < today) {
      const diffDays = (today, end) =>
        Math.ceil(Math.abs(end - today) / (1000 * 60 * 60 * 24))

      const difference = diffDays(today, new Date(session?.endtime))

      // if session was more than three months ago
      if (difference > 90) {
        setAccess(false)
      } else {
        setAccess(true)
      }
    } else setAccess(true)
  }, [session?.starttime, session?.endtime])

  const mutationGetDocument = useMutation(
    () => {
      return postGetDocumentApi(
        process.env.GATSBY_API_URL + "/Documents/NamedUsers/Get",
        {
          EventId: session.eventId,
          SessionId: session?.sessionid,
        },
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: data => {
        setDocuments(data)
      },
      onError: _error => {},
      onSettled: () => {},
    }
  )

  const sessionRegistrationsQuery = useQuery(
    "sessionRegistrations",
    async () =>
      fetchApi(
        process.env.GATSBY_API_URL +
          `/EventAdmin/sessionRegistrations?id=${session?.sessionid}`,
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: data => {
        setSessionRegistrations(data)
      },
    }
  )

  const checkinQuery = useQuery(
    "sessionCheckins",
    async () =>
      fetchApi(
        process.env.GATSBY_API_URL +
          `/EventAdmin/${session?.sessionid}/SessionCheckin`,
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: data => {
        if (
          data?.findIndex(
            i => i.checkinStatus == 778380001 && i.absenceDetails == null
          ) > -1
        ) {
          setDetailsMissing(true)
        }
      },
    }
  )

  const [contactList, setContactList] = useState<string[]>([])
  const [contactListPartially, setContactListPartially] = useState<string[]>([])

  const updateList = event => {
    if (event.target.checked) {
      setContactList(list => [...list, event?.target?.value])
    } else {
      setContactList(contactList.filter(id => id != event?.target?.value))
    }
  }

  const updateListPartially = event => {
    if (event.target.checked) {
      setContactListPartially(list => [...list, event?.target?.value])
    } else {
      setContactListPartially(
        contactListPartially.filter(id => id != event?.target?.value)
      )
    }
  }

  useEffect(() => {
    setIsLoading(
      !sessionRegistrationsQuery.isSuccess || !checkinQuery.isSuccess
    )
    mutationGetDocument.mutate()
  }, [sessionRegistrationsQuery.isSuccess, checkinQuery.isSuccess])

  const sessionQuery = useQuery(
    "sessionInfo",
    async () =>
      fetchApi(
        process.env.GATSBY_API_URL +
          `/EventAdmin/singleSession/${session.sessionid}`,
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: data => {
        setSessionRefetch(data)
      },
      onError: _error => {},
    }
  )
  const checkinCompleted = useMutation(
    async () => {
      return patchApi(
        process.env.GATSBY_API_URL +
          `/EventAdmin/sessionCheckinOk/${session.sessionid}`,
        {},
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: data => {
        toast.success("Oppmøteregistreringen er fullført.", {
          position: "top-center",
          autoClose: 5000,
        })
        window.setTimeout(() => {
          window.location.reload()
        }, 4000)
      },
      onError: _error => {
        setIsLoading(false)
      },
      onSettled: () => {},
    }
  )

  const mutationPostCheckin = useMutation(
    data => {
      return postApi(
        process.env.GATSBY_API_URL + `/EventAdmin/checkin`,
        data,
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: data => {
        toast.success("Oppmøtet er nå registrert!", {
          position: "top-center",
          autoClose: 5000,
        })
        window.setTimeout(() => {
          queryClient.invalidateQueries("sessionCheckins")
          setShowLoadingData(false)
          setDisableButton(false)
        }, 3000)
      },
      onError: _error => {},
      onSettled: () => {},
    }
  )

  const refreshPage = () => {
    setShowLoadingData(false)
    setDisableButton(false)
    window.setTimeout(() => {
      window.location.reload()
    }, 1000)
  }

  const postCheckin = () => {
    let checkinList: CheckinAdmin[] = []
    if (contactList.length > 0) {
      setDisableButton(true)
      contactList.forEach(contact => {
        const index = sessionRegistrations?.findIndex(
          i => i.contactid === contact
        )
        if (index > -1) {
          setShowLoadingData(true)
          const registration = sessionRegistrations[index]
          const data: CheckinAdmin = {
            eventRegistrationId: registration?.registrationid,
            checkinType: 100000000,
            sessionRegistrationId: registration?.sessionregistrationid,
            // Oppmøtt
            checkinStatus: 778380000,
            amountHours: session?.amountHours,
          }

          checkinList.push(data)
        }
      })
    }

    if (contactListPartially.length > 0) {
      setDisableButton(true)
      contactListPartially.forEach(contact => {
        const index = sessionRegistrations?.findIndex(
          i => i.contactid === contact
        )
        if (index > -1) {
          setShowLoadingData(true)
          const registration = sessionRegistrations[index]
          const data: CheckinAdmin = {
            eventRegistrationId: registration?.registrationid,
            checkinType: 100000000,
            sessionRegistrationId: registration?.sessionregistrationid,
            // Delvis oppmøtt
            checkinStatus: 778380001,
            amountHours: session?.amountHours,
          }

          checkinList.push(data)
        }
      })
    }
    if (checkinList.length === contactList.length + contactListPartially.length)
      mutationPostCheckin.mutate(checkinList)
  }
  const finished = () => {
    if (detailsMissing) {
      toast.warning("Fraværsdetaljer mangler!", {
        position: "top-center",
        autoClose: 10000,
      })
      return
    }
    setIsLoading(true)

    checkinCompleted.mutate()
  }

  return isLoading ? (
    <LoadingData />
  ) : showLoadingData ? (
    <LoadingData subject="Oppretter" />
  ) : (
    <MyPageLayout props="Deltagerliste">
      <HeaderText text={session?.name} />
      <div className="mt-8 mx-1 flex flex-col">
        {access ? (
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <HelperText
                helpertext={`Fyll først ut for oppmøtt/delvis oppmøtt. De som ikke får kryss på
        oppmøtt eller delvis oppmøtt blir regnet som ikke oppmøtt. Når du har
        fylt ut oppmøtt/delvis oppmøtt på alle som har møtt klikker du på
        knappen nederst, "Registrer oppmøte"`}
              />
              <HelperText
                helpertext={`Deretter må du klikke på hver
        enkelt som er delvis oppmøtt og registrere riktig antall timer og
        fraværsdetaljer. Det er også mulig å endre mellom oppmøtt/delvis oppmøtt
        hvis du krysset av feil i første omgang, da må du klikke deg inn på den enkelte og gjøre endringen. Når du har fullført dette
        klikker du til slutt på knappen "Fullfør oppmøte". Merk at
        du ikke kan gjøre endringer etter å ha fullført.`}
              />
              <div className="mt-4 overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300 table2:max-w-screen-sm">
                  <thead className="bg-meny-700 text-meny-50">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-6"
                      >
                        Navn
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm table1:hidden font-semibold"
                      >
                        Primær rolle
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold table2:hidden"
                      >
                        Arbeidsgiver
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold table2:hidden"
                      >
                        Oppmøtestatus
                      </th>
                      {sessionQuery?.data?.checkinCompleted ? (
                        <></>
                      ) : (
                        <>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold"
                          >
                            Oppmøtt
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold"
                          >
                            Delvis oppmøtt
                          </th>
                        </>
                      )}
                      {/* <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold"
                      ></th> */}
                    </tr>
                  </thead>

                  {sessionRegistrationsQuery?.data?.map(registration => {
                    return (
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {/* {checkinQuery?.data?.findIndex(
                            i => i.contactId === registration?.contactid
                          ) > -1 ? () : ()} */}
                        {checkinQuery?.data?.findIndex(
                          i => i.contactId === registration?.contactid
                        ) > -1 ? (
                          <tr
                            key={registration?.contactid}
                            className={classNames(
                              !sessionQuery?.data?.checkinCompleted
                                ? "hover:cursor-pointer hover:bg-meny-50"
                                : ""
                            )}
                            onClick={e => {
                              if (!sessionQuery?.data?.checkinCompleted) {
                                navigate("/app/kurs/administrasjon/rediger", {
                                  state: {
                                    checkin:
                                      checkinQuery?.data[
                                        checkinQuery?.data?.findIndex(
                                          i =>
                                            i.contactId ===
                                            registration?.contactid
                                        )
                                      ],
                                  },
                                })
                              }
                            }}
                          >
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                              {registration?.contact?.firstName}{" "}
                              {registration?.contact?.lastName}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-600 table1:hidden">
                              {registration?.primaryRoleName}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-600 table2:hidden">
                              {registration?.contact?.mainEmployer?.name}
                            </td>
                            <td
                              className={classNames(
                                checkinQuery.data[
                                  checkinQuery?.data?.findIndex(
                                    i => i.contactId === registration?.contactid
                                  )
                                ]?.checkinStatus == 778380001 &&
                                  checkinQuery.data[
                                    checkinQuery?.data?.findIndex(
                                      i =>
                                        i.contactId === registration?.contactid
                                    )
                                  ]?.absenceDetails == null
                                  ? "text-red-600"
                                  : "text-gray-600",
                                "whitespace-nowrap px-3 py-4 text-sm table2:hidden"
                              )}
                            >
                              {checkinQuery.data[
                                checkinQuery?.data?.findIndex(
                                  i => i.contactId === registration?.contactid
                                )
                              ]?.checkinStatusName?.replaceAll("_", " ")}
                            </td>
                            {sessionQuery?.data?.checkinCompleted ? (
                              <></>
                            ) : (
                              <>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-600 ">
                                  <input
                                    id={"o" + registration?.contactid}
                                    onChange={updateList}
                                    value={registration?.contactid}
                                    checked={
                                      checkinQuery.data[
                                        checkinQuery?.data?.findIndex(
                                          i =>
                                            i.contactId ===
                                            registration?.contactid
                                        )
                                      ]?.checkinStatus == 778380000
                                    }
                                    disabled={true}
                                    type="checkbox"
                                    className="focus:ring-meny-700 h-4 w-4 mx-2 text-gray-600 border-gray-300 bg-gray-100 rounded"
                                  />
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-600 ">
                                  <input
                                    id={"do" + registration?.contactid}
                                    onChange={updateListPartially}
                                    value={registration?.contactid}
                                    checked={
                                      checkinQuery.data[
                                        checkinQuery?.data?.findIndex(
                                          i =>
                                            i.contactId ===
                                            registration?.contactid
                                        )
                                      ]?.checkinStatus == 778380001
                                    }
                                    disabled={true}
                                    type="checkbox"
                                    className="focus:ring-meny-700 h-4 w-4 text-gray-600 bg-gray-100 border-gray-300 rounded"
                                  />
                                </td>
                              </>
                            )}
                          </tr>
                        ) : (
                          <tr
                            key={registration?.contactid}
                            // className="
                          >
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                              {registration?.contact?.firstName}{" "}
                              {registration?.contact?.lastName}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-600 table1:hidden">
                              {registration?.primaryRoleName}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-600 table2:hidden">
                              {registration?.contact?.mainEmployer?.name}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-600 table2:hidden">
                              Ikke oppmøtt
                            </td>
                            {sessionQuery?.data?.checkinCompleted ? (
                              <></>
                            ) : (
                              <>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-600 ">
                                  <input
                                    id={"o" + registration?.contactid}
                                    onChange={updateList}
                                    value={registration.contactid}
                                    defaultChecked={false}
                                    disabled={
                                      contactListPartially.findIndex(
                                        i =>
                                          i ===
                                          registration?.contactid.toString()
                                      ) > -1
                                    }
                                    type="checkbox"
                                    className="focus:ring-meny-700 h-4 w-4 mx-2 text-meny-600 border-gray-300 rounded"
                                  />
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-600 ">
                                  <input
                                    id={"do" + registration?.contactid}
                                    onChange={updateListPartially}
                                    value={registration?.contactid}
                                    defaultChecked={false}
                                    disabled={
                                      contactList.findIndex(
                                        i =>
                                          i ===
                                          registration?.contactid.toString()
                                      ) > -1
                                    }
                                    type="checkbox"
                                    className="focus:ring-meny-700 h-4 w-4 text-meny-600 border-gray-300 rounded"
                                  />
                                </td>
                              </>
                            )}
                          </tr>
                        )}
                      </tbody>
                    )
                  })}
                </table>
              </div>
              {sessionQuery?.data?.checkinCompleted ? (
                <div className="mt-4 flex justify-center">
                  <HelperText
                    helpertext={"Oppmøteregistreringen er fullført."}
                  />
                </div>
              ) : (
                <>
                  <div className="mt-6 flex justify-center">
                    {/* // <div className="sm:grid sm:grid-cols-4 gap-3 mb-4">
                    // <div className="sm:col-span-1"> */}
                    <button
                      onClick={postCheckin}
                      disabled={disableButton}
                      className="inline-flex justify-end py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-md text-white bg-meny-700 hover:bg-meny-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
                    >
                      Registrer oppmøte
                    </button>
                  </div>
                  {/* // </div>
                    // <div className="sm:col-span-1 sm:col-start-4 mr-0 mt-2"> */}
                  {checkinQuery?.data?.length > 0 ? (
                    <div className="mt-4 flex justify-center">
                      <button
                        onClick={finished}
                        className="inline-flex justify-end py-2 px-10 border border-transparent shadow-sm text-md font-medium rounded-md text-white bg-green-700 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-700"
                      >
                        Fullfør oppmøte
                      </button>
                    </div>
                  ) : (
                    <></>
                  )}
                  {/* </div>
                    <div />
                   </div> */}
                </>
              )}
            </div>
          </div>
        ) : (
          <></>
        )}

        <div className="mt-4">
          <HeaderText text="Vedlegg" />
          <DocumentListSessions sessionId={session.sessionid} />

          <SessionDocuments session={sessionRefetch} />
        </div>
        <div className="flex justify-center">
          <p className="mt-3 max-w-md mx-auto text-base text-gray-600 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
            Gå tilbake til oversikten over{" "}
            <Link
              to="/app/kurs/administrasjon"
              className="text-meny-700 font-bold hover:text-meny-600"
            >
              mine kurs
            </Link>
            .
          </p>
        </div>
      </div>
    </MyPageLayout>
  )
}

const SessionRegistrationList = ({ location }) => {
  const authRequest = {
    ...loginRequest,
  }
  const session = location?.state?.session
  // console.log(location.state)
  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      <SessionRegistrationListContent props={session} />
    </MsalAuthenticationTemplate>
  )
}

export default SessionRegistrationList
