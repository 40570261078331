import {
  ArrowNarrowLeftIcon,
  CheckIcon,
  HomeIcon,
  PaperClipIcon,
  QuestionMarkCircleIcon,
  SearchIcon,
  ThumbUpIcon,
  UserIcon,
} from "@heroicons/react/solid"
import {
  BellIcon,
  MailIcon,
  MenuIcon,
  TrendingUpIcon,
  XIcon,
} from "@heroicons/react/outline"
import {
  MsalAuthenticationTemplate,
  useAccount,
  useMsal,
} from "@azure/msal-react"
import React, { useEffect, useState } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import DocumentList from "../../mypagecomponents/document/DocumentList"
import MyPageLayout from "../../common/layouts/myPageLayout"
import FileUpload from "../../mypagecomponents/document/FileUpload"
import { fetchApi, postGetDocumentApi } from "../../../Utils/Api.utils"
import LoadingData from "../../common/graphichs/LoadingData"
import { classNames } from "../../common/graphichs/misc"
import MessageInbox from "../../mypagecomponents/messages/MessageInbox"
import { Link, navigate } from "gatsby"
import MessageWrite from "../../mypagecomponents/messages/MessageWrite"
import { RemoveUnderScore } from "../../../Utils/FormatHelper"
import { incidentTabs } from "../../../Utils/Tabs"
import MainLayout from "../../common/layouts/mainLayout"
import { Fragment } from "react"
import { Menu, Popover, Transition } from "@headlessui/react"
import TimeLine from "../../mypagecomponents/messages/TimeLine"
import TimeLineComment from "../../mypagecomponents/messages/TimeLineComment"
import { InteractionType } from "@azure/msal-browser"
import { ErrorComponent } from "../../common/graphichs/ErrorComponent"
import { Loading } from "../../../Utils/Loading"
import { loginRequest } from "../../security/authConfig"
import { whiteButton } from "../../../styles/tailwindClasses"

const IncidentContent = ({ location }) => {
  const queryClient = useQueryClient()
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const [documents, setDocuments] = useState([])
  const [showMessages, setShowMessages] = useState(false)
  const [openMessageModal, setOpenMessageModal] = useState(false)
  const [programId, setProgramId] = useState<string>()
  const [activityId, setActivityId] = useState<string>()
  const [educationId, setEducationId] = useState<string>()
  const [program, setProgram] = useState()
  const [incidentInactive, setIncidentInactive] = useState<boolean>(false)
  const [blockMessage, setBlockMessage] = useState<boolean>(false)

  const mutationGetDocumentsa = useMutation(
    () => {
      return postGetDocumentApi(
        process.env.GATSBY_API_URL + "/Documents/Regarding/Get",
        {
          CaseId: location.state.incidentid,
        },
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: data => {
        setDocuments(data)
      },
      onError: _error => {},
      onSettled: () => {},
    }
  )

  useEffect(() => {
    mutationGetDocumentsa.mutate()
  }, [])

  // const incidentProgram = useQuery(
  //   "incidentProgram",
  //   () =>
  //     fetchApi(
  //       process.env.GATSBY_API_URL + "/SpecialistPrograms/" + programId,
  //       account,
  //       inProgress,
  //       instance
  //     ).then(res => res.data),
  //   {
  //     enabled: !!programId,
  //     onSuccess: data => {},
  //     onError: _error => {},
  //   }
  // )

  const userProgramsQuery = useQuery(
    "userProgramsData-" + educationId,
    () =>
      fetchApi(
        process.env.GATSBY_API_URL + "/SpecialistPrograms/me/" + educationId,
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      enabled: !!educationId || !!programId,
      onSuccess: data => {
        let prog = data.find(x => x.id === programId)
        setProgram(prog)
      },
      onError: _error => {},
    }
  )

  const incidentActivity = useQuery(
    "incidentActivity",
    () =>
      fetchApi(
        process.env.GATSBY_API_URL + "/SpecializationActivities/" + activityId,
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      enabled: !!activityId,
      onSuccess: data => {},
      onError: _error => {},
    }
  )

  const userIncidentQuery = useQuery(
    "userIncidentData",
    () =>
      fetchApi(
        process.env.GATSBY_API_URL + "/Incidents/" + location.state.incidentid,
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: data => {
        const today = Date.now()
        if (data.stateName === "Inaktiv") {
          setIncidentInactive(true)
          // compare days
          const diffDays = (today, deactivatedOn) =>
            Math.ceil(Math.abs(deactivatedOn - today) / (1000 * 60 * 60 * 24))
          const deactivatedOn = Date.parse(data.deactivatedOn)
          const difference = diffDays(today, deactivatedOn)
          console.log("Antall dager siden: " + difference)
          // if incident was deactivated for more than 7 days ago
          if (difference > 7) {
            setBlockMessage(true)
          } else {
            setBlockMessage(false)
          }
        } else setIncidentInactive(false)
        if (
          (data.subjectId === "ad3bcfb0-eea5-ec11-983f-000d3aae55a4" ||
            data.subjectId === "eccca80d-dcb0-ec11-9840-000d3abf775d") &&
          data.caseTypeCode === 8
        ) {
          setProgramId(data.specialistProgramId)
          setActivityId(data.activitySpecialistProgramId)
          setEducationId(data.educationId)
        }
      },
      onError: _error => {},
    }
  )

  const mutationGetDocuments = useMutation(
    () => {
      return postGetDocumentApi(
        process.env.GATSBY_API_URL + "/Documents/Regarding/Get",
        {
          CaseId: userIncidentQuery.data.id,
        },
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: data => {
        setDocuments(data)
      },
      onError: _error => {},
      onSettled: () => {},
    }
  )

  const changeTab = tabid => {
    if (tabid === "incident") {
      const indexIncident = incidentTabs.findIndex(
        item => item.id === "incident"
      )
      incidentTabs[indexIncident].current = true
      const indexMessage = incidentTabs.findIndex(item => item.id === "message")
      incidentTabs[indexMessage].current = false
      setShowMessages(false)
    }
    if (tabid === "message") {
      const indexMessage = incidentTabs.findIndex(item => item.id === "message")
      incidentTabs[indexMessage].current = true
      const indexIncident = incidentTabs.findIndex(
        item => item.id === "incident"
      )
      incidentTabs[indexIncident].current = false
      setShowMessages(true)
    }
  }

  const changeTabSelect = e => {
    if (e.target.value === "incident") {
      const indexIncident = incidentTabs.findIndex(
        item => item.id === "incident"
      )
      incidentTabs[indexIncident].current = true
      const indexMessage = incidentTabs.findIndex(item => item.id === "message")
      incidentTabs[indexMessage].current = false
      setShowMessages(false)
    }
    if (e.target.value === "message") {
      const indexMessage = incidentTabs.findIndex(item => item.id === "message")
      incidentTabs[indexMessage].current = true
      const indexIncident = incidentTabs.findIndex(
        item => item.id === "incident"
      )
      incidentTabs[indexIncident].current = false
      setShowMessages(true)
    }
  }

  const openNewMessage = () => setOpenMessageModal(true)

  return userIncidentQuery.isLoading ? (
    // // "Laster inn..."
    <LoadingData />
  ) : userIncidentQuery.isError ? (
    userIncidentQuery.error.message
  ) : (
    <>
      <div>
        {openMessageModal && (
          <MessageWrite
            entityId={location.state.incidentid}
            entityName="incident"
            setOpenMessageModal={setOpenMessageModal}
          />
        )}
      </div>
      <MainLayout props="Sak">
        <div className="min-h-full bg-gray-50">
          <main className="py-10">
            {/* Page header */}
            <div className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
              <button
                type="button"
                className={whiteButton}
                onClick={() => navigate("/app/dialog/minesaker")}
              >
                Tilbake
              </button>
            </div>

            <div className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2">
              <div className="space-y-6 lg:col-start-1 lg:col-span-1">
                {/* Description list*/}
                <section aria-labelledby="applicant-information-title">
                  <div className="bg-white shadow sm:rounded-lg">
                    <div className="bg-meny-700 rounded-t-lg px-4 py-5 sm:px-6">
                      <h2
                        id="applicant-information-title"
                        className="text-lg leading-6 font-medium text-white"
                      >
                        Sak: {userIncidentQuery.data.ticketNumber}
                      </h2>
                      {/* <p className="mt-1 max-w-2xl text-sm text-white">
                        {userIncidentQuery.data.ticketNumber}
                      </p> */}
                    </div>
                    <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                      <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-medium text-gray-600">
                            Emne
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            {userIncidentQuery.data.title}
                          </dd>
                        </div>
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-medium text-gray-600">
                            Type
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            {RemoveUnderScore(
                              userIncidentQuery.data.caseTypeName
                            )}
                          </dd>
                        </div>
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-medium text-gray-600">
                            Status
                          </dt>
                          {userIncidentQuery.data.status === 292460000 ? (
                            <dd className="mt-1 text-sm text-red-600 font-bold">
                              {userIncidentQuery.data.statusName}
                            </dd>
                          ) : userIncidentQuery.data.status === 2 ? (
                            <dd className="mt-1 text-sm text-red-600 font-bold">
                              {RemoveUnderScore(
                                userIncidentQuery.data.statusName
                              )}
                            </dd>
                          ) : (
                            <dd className="mt-1 text-sm text-gray-900">
                              {userIncidentQuery.data.stateName}
                            </dd>
                          )}
                        </div>
                        <div className="sm:col-span-3">
                          <dt className="text-sm font-medium text-gray-600">
                            Beskrivelse
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            {userIncidentQuery.data.description}
                          </dd>
                        </div>
                        {/* Spesialiseringsaktivitet */}
                        {(userIncidentQuery.data.subjectId ===
                          "ad3bcfb0-eea5-ec11-983f-000d3aae55a4" ||
                          userIncidentQuery.data.subjectId ===
                            "eccca80d-dcb0-ec11-9840-000d3abf775d") &&
                        userIncidentQuery.data.caseTypeCode === 8 ? (
                          <div className="sm:col-span-3">
                            <dt
                              className="text-sm hover:cursor-pointer text-meny-700 font-medium hover:text-meny-600"
                              onClick={e =>
                                navigate(
                                  "/app/kurs/specialityeducation/activity",
                                  {
                                    state: {
                                      program: program,
                                      activity: incidentActivity.data,
                                    },
                                  }
                                )
                              }
                            >
                              Lenke til aktiviteten
                            </dt>
                            {/* <dd className="mt-1 text-sm text-gray-900">
                              <dt></dt>
                            </dd> */}
                          </div>
                        ) : (
                          <></>
                        )}
                        <div className="sm:col-span-3">
                          <dt className="text-sm font-medium text-gray-600">
                            Vedlegg
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            <DocumentList
                              documents={documents}
                              mutationGetDocuments={mutationGetDocuments}
                              canDelete={false}
                            />
                          </dd>
                        </div>
                      </dl>
                    </div>
                  </div>
                </section>
              </div>

              <section
                aria-labelledby="timeline-title"
                className="lg:col-span-1"
              >
                <div className="shadow sm:rounded-lg">
                  <div className="divide-y divide-gray-200">
                    <div className="bg-meny-700 rounded-t-lg px-4 py-5 sm:px-6">
                      <h2
                        id="notes-title"
                        className="text-lg font-medium text-white"
                      >
                        Meldinger
                      </h2>
                    </div>
                    <div className="min-w-0 flex-1">
                      <nav className="h-full md:overflow-y-auto overscroll-none">
                        <TimeLine entityId={location.state.incidentid} />
                      </nav>
                    </div>
                  </div>
                  {blockMessage ? (
                    <></>
                  ) : (
                    <div className="bg-gray-50 px-4 py-6 sm:px-6">
                      <div className="flex space-x-3">
                        <div className="flex-shrink-0">
                          <span
                            className={classNames(
                              "bg-meny-500",
                              "h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white"
                            )}
                          >
                            <MailIcon
                              className="w-5 h-5 text-white"
                              aria-hidden="true"
                            />
                          </span>
                        </div>

                        <div className="min-w-0 flex-1">
                          <TimeLineComment
                            entityName={"incident"}
                            incidentInactive={incidentInactive}
                            entityTitle={location.state.incidenttitle}
                            entityId={location.state.incidentid}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </section>
            </div>
          </main>
        </div>
      </MainLayout>
    </>
  )
}

const Incident = ({ location }) => {
  const authRequest = {
    ...loginRequest,
  }
  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      <IncidentContent location={location} />
    </MsalAuthenticationTemplate>
  )
}

export default Incident
