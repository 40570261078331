import React, { Fragment, useState } from "react"
import { Combobox, Transition } from "@headlessui/react"

export type DropdownSearchType = {
  options: Array<optionsType>
  defaultSelect?: optionsType
  onChange: (text: string) => void
  onSelect: (option: optionsType) => void
}

export type optionsType = {
  label: string
  subLabel?: string
  id: string
}

const DropdownSearch = (props: DropdownSearchType) => {
  const [selected, setSelected] = useState<optionsType>(props.options[0])
  const [query, setQuery] = useState("")

  const filteredProps =
    query === ""
      ? props.options
      : props.options.filter(item =>
          item.label
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(query.toLowerCase().replace(/\s+/g, ""))
        )

  const changeQuery = event => {
    setQuery(event.target.value)
    props.onChange(event.target.value)
  }

  const onSelect = (item: optionsType) => {
    setSelected(item)
    props.onSelect(item)
  }

  return (
    <div className="relative  w-full">
      <Combobox value={selected} onChange={onSelect}>
        <div className="relative mt-1">
          <div className="relative w-full cursor-default overflow-hidden bg-white text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
            <Combobox.Input
              className="w-full"
              defaultValue={
                props.defaultSelect ? props.defaultSelect.label : undefined
              }
              displayValue={(item: optionsType) => item?.label || ""}
              onChange={changeQuery}
            />
            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2"></Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setQuery("")}
          >
            <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {filteredProps.length === 0 && query !== "" ? (
                <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                  Nothing found.
                </div>
              ) : (
                filteredProps.map(item => (
                  <Combobox.Option
                    key={item.id}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pl-10 pr-4 ${
                        active ? "bg-teal-600 text-white" : "text-gray-900"
                      }`
                    }
                    value={item}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={`block truncate ${
                            selected ? "font-medium" : "font-normal"
                          }`}
                        >
                          <p>{item.label}</p>
                          <p>{item.subLabel}</p>
                        </span>
                        {selected ? (
                          <span
                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                              active ? "text-white" : "text-teal-600"
                            }`}
                          ></span>
                        ) : null}
                      </>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </div>
  )
}

export default DropdownSearch
