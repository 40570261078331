import React, { useState } from "react"
import { NewEmploymentAsEmployee } from "./NewEmploymentAsEmployee"
import { RadioGroupComponent } from "../../../../common/radioGroup/RadioGroup"
import { employerTypes } from "../../../../../Utils/optionSet"
import { NewEmploymentAsIndependent } from "./NewEmploymentAsIndependent"

function AddNewEmploymentAccordionContent() {
  const [employerType, setEmployerType] = useState<number>(0)

  return (
    <div>
      <div className="max-w-7xl mx-auto pb-6 px-4 sm:px-6 lg:px-8 flex justify-center">
        <div className="max-w-lg">
          <RadioGroupComponent
            label={"Velg et arbeidsforhold"}
            value={employerType}
            onChange={setEmployerType}
            type={employerTypes}
          />
        </div>
      </div>
      <>
        {employerType === 292460000 && <NewEmploymentAsEmployee />}
        {employerType === 292460001 && (
          <div>Hello</div>
          // <NewEmploymentAsIndependent
          //   prevEmploymentData={prevEmploymentData}
          // />
        )}
      </>
    </div>
  )
}

export default AddNewEmploymentAccordionContent
