import { InteractionType } from "@azure/msal-browser"
import {
  useMsal,
  useAccount,
  MsalAuthenticationTemplate,
} from "@azure/msal-react"
import { Switch } from "@headlessui/react"
import { ErrorMessage } from "@hookform/error-message"
import { navigate } from "gatsby"
import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { useQueryClient, useQuery, useMutation } from "react-query"
import { toast } from "react-toastify"
import {
  fetchApi,
  postGetDocumentApi,
  postApi,
  patchApi,
} from "../../../../Utils/Api.utils"
import { Loading } from "../../../../Utils/Loading"
import { NewIncidentVal } from "../../../../Utils/Types"
import { DropDown } from "../../../common/dropDown"
import { ErrorComponent } from "../../../common/graphichs/ErrorComponent"
import LoadingData from "../../../common/graphichs/LoadingData"
import { classNames } from "../../../common/graphichs/misc"
import MyPageLayout from "../../../common/layouts/myPageLayout"
import DocumentList from "../../../mypagecomponents/document/DocumentList"
import FileUpload from "../../../mypagecomponents/document/FileUpload"
import { loginRequest } from "../../../security/authConfig"

const NewIncidentContent = ({ location }) => {
  const queryClient = useQueryClient()
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const [showDocumentComponent, setShowDocumentComponent] = useState(false)
  const [showCaseButtons, setShowCaseButtons] = useState(true)
  const [title, setTitle] = useState("")
  const [description, setDescription] = useState("")
  const [incidentType, setIncidentType] = useState(0)
  // const [incidentStatus, setIncidentStatus] = useState(1)
  const [incidentId, setIncidentId] = useState("")
  const [documents, setDocuments] = useState([])
  const [documentsDownLoad, setDocumentsDownLoad] = useState([])
  const [caseTypeCodes, setCaseTypeCodes] = useState([])

  const [youngerThen, setYoungerThen] = useState<boolean>(false)
  const [uploadEnabled, setUploadEnabled] = useState<boolean>(false)
  const [showLoadingData, setShowLoadingData] = useState<boolean>(false)
  const {
    register,
    handleSubmit,
    reset,
    setError,
    clearErrors,
    resetField,
    formState: { errors, isDirty, isValid },
  } = useForm<NewIncidentVal>()

  const showFileUpload = e => {
    setUploadEnabled(!uploadEnabled)
    if (!uploadEnabled) {
      setYoungerThen(true)
    } else {
      setYoungerThen(false)
    }
  }

  const userInfoQuery = useQuery(
    "userInfo",
    () =>
      fetchApi(
        process.env.GATSBY_API_URL + "/Contacts/me",
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: data => {},
      onError: _error => {},
    }
  )

  const caseTypeCodeQuery = useQuery(
    "userIncidentsData",
    () =>
      fetchApi(
        process.env.GATSBY_API_URL + "/OptionSets/casetypecode/incident",
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: data => {
        //remove all above 10 in value
        data = data.filter(element => element.value < 10)
        data = data.filter(element => element.label !== "Question")
        data = data.filter(element => element.label !== "Problem")
        data = data.filter(element => element.label !== "Request")

        setCaseTypeCodes(data)
      },
      onError: _error => {},
    }
  )

  const mutationGetDocuments = useMutation(
    () => {
      return postGetDocumentApi(
        process.env.GATSBY_API_URL + "/Documents/Regarding/Get",
        {
          CaseId: incidentId,
        },
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: data => {
        setDocumentsDownLoad(data)
      },
      onError: _error => {},
      onSettled: () => {},
    }
  )

  const mutationCreateIncident = useMutation(
    () => {
      return postApi(
        process.env.GATSBY_API_URL + "/Incidents",
        {
          title: "Endringssøknad: Ordinær til Pensjonist",
          retired: true,
          description: description,
          caseTypeCode: 16,
          caseOriginCode: 3,
          status: 1,
          state: 0,
          ContineInsurence: location.state.consentLiabality,
          // Emne = Ordinær til pensjonist
          subjectId: "2f65a1d3-f49d-ec11-b400-000d3aadca3e",
          membershipCategory: "fceaa12d-2d78-ec11-8d21-000d3aa9614b",
          approveStatus: 778380001,
          membershipId: userInfoQuery.data.activeMemebershipId,
        },
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: data => {
        setIncidentId(data)
        if (documents.length > 0) {
          const formData = new FormData()
          Array.from(documents).forEach(file =>
            formData.append("arrayOfFiles", file)
          )

          formData.append("EntityId", data)
          formData.append("EntityName", "incident")

          mutationUploadDocumets.mutate(formData)
        }
        mutationUpdateContact.mutate()

        toast.success(
          "Saken er opprettet! Du vil nå bli videreført til dine saker.",
          {
            position: "top-center",
            autoClose: 5000,
          }
        )
        queryClient.invalidateQueries(["userIncidentData"])
        setTimeout(() => {
          navigate("/app/dialog/minesaker")
        }, 5000)
      },
      onError: _error => {},
      onSettled: () => {},
    }
  )

  const mutationUploadDocumets = useMutation(
    formData => {
      return postApi(
        process.env.GATSBY_API_URL + "/Documents",
        formData,
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: () => {},
      onError: _error => {},
      onSettled: () => {},
    }
  )

  // update field retired = true for contact
  const mutationUpdateContact = useMutation(() => {
    return patchApi(
      process.env.GATSBY_API_URL + "/Contacts/me",
      {
        retired: true,
      },
      account,
      inProgress,
      instance
    ).then(res => res.data)
  })

  const mutationUpdateIncident = useMutation(
    () => {
      return patchApi(
        process.env.GATSBY_API_URL + "/Incidents",
        {
          id: incidentId,
          title: "Ordinær til Pensjonist",
          description: description,
          // Emne = Ordinær til pensjonist
          subjectId: "2f65a1d3-f49d-ec11-b400-000d3aadca3e",
          caseTypeCode: 16,
          caseOriginCode: 3,
          status: 1,
          state: 0,
          membershipCategory: "fceaa12d-2d78-ec11-8d21-000d3aa9614b",
          membershipId: userInfoQuery.data.activeMemebershipId,
          approveStatus: 778380001,
        },
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: data => {
        if (documents.length > 0) {
          const formData = new FormData()
          Array.from(documents).forEach(file =>
            formData.append("arrayOfFiles", file)
          )

          formData.append("EntityId", incidentId)
          formData.append("EntityName", "incident")

          mutationUploadDocumets.mutate(formData)

          mutationUpdateContact.mutate()
        }
        queryClient.invalidateQueries(["userIncidentData"])
        toast.success(
          "Saken er opprettet! Du vil nå bli videreført til dine saker.",
          {
            position: "top-center",
            autoClose: 5000,
          }
        )
        setTimeout(() => {
          navigate("/app/dialog/minesaker")
        }, 5000)
      },
      onError: _error => {
        setShowLoadingData(false)
      },
      onSettled: () => {},
    }
  )

  const toggleAge = e => {
    setShowDocumentComponent(e)
  }

  const submitAction = async e => {
    e.preventDefault()
    if (showDocumentComponent && documents.length < 1) {
      // console.log("No Documents uploaded")

      toast.warning("Vennligst last opp dokumentasjon", {
        position: "top-center",
        autoClose: 10000,
      })
      return
    }

    if (typeof incidentId !== "undefined" && incidentId) {
      setShowLoadingData(true)
      mutationUpdateIncident.mutate()
    } else {
      setShowLoadingData(true)
      mutationCreateIncident.mutate()
    }
  }

  const setIncidentTypeDropDown = incidentTypeValue =>
    setIncidentType(incidentTypeValue)

  return showLoadingData ? (
    // // "Laster inn..."
    <LoadingData subject="Oppretter..." />
  ) : userInfoQuery.isLoading ? (
    <LoadingData />
  ) : (
    <MyPageLayout props="Ordinær til Pensjonist">
      <form
        method="POST"
        onSubmit={submitAction}
        className="space-y-8 divide-y divide-gray-200"
      >
        <div className="space-y-8 divide-y divide-gray-200">
          <div className="pt-8">
            <div>
              <h3 className="text-lg leading-6 font-medium text-meny-700">
                Er du yngre enn 67?
              </h3>

              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <p id="comments-description" className="text-gray-600">
                  For å behandle din søknad ber vi deg dokumentere at du er
                  pensjonist ved dokumentasjon fra NAV eller siste arbeidsgiver.
                </p>
              </div>
              <div className="mr-10 relative flex items-start py-4">
                <Switch
                  checked={showDocumentComponent}
                  onChange={e => toggleAge(e)}
                  className={classNames(
                    showDocumentComponent ? "bg-meny-600" : "bg-gray-900",
                    "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classNames(
                      showDocumentComponent ? "translate-x-5" : "translate-x-0",
                      "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                    )}
                  />
                </Switch>

                <div className="mt-1 sm:mt-0 sm:col-span-1 sm:col-start-3"></div>
              </div>
            </div>

            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div className="sm:col-span-6">
                <label
                  htmlFor="description"
                  className="block text-sm font-medium text-gray-700"
                >
                  Beskrivelse
                </label>
                <p className="mt-2 text-sm text-gray-600">
                  Skriv noen ord om saken.
                </p>
                <div className="mt-1">
                  <textarea
                    {...register("description", {
                      // required: {
                      //   value: true,
                      //   message: "Obligatorisk felt.",
                      // },
                      minLength: {
                        value: 10,
                        message: "Minimum tegn er 10",
                      },
                      maxLength: {
                        value: 2000,
                        message: "Maks tegn er 2000",
                      },
                    })}
                    id="description"
                    name="description"
                    onChange={e => setDescription(e.target.value)}
                    rows={3}
                    // required
                    className="shadow-sm focus:ring-meny-700 focus:border-meny-500 block w-full sm:text-sm border border-gray-300 rounded-md"
                    defaultValue={""}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="description"
                    render={({ message }) => (
                      <p className="text-sm text-red-600">{message}</p>
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {showDocumentComponent ? (
          <div>
            <p className="mt-2 text-sm text-gray-600"></p>
            <DocumentList
              documents={documentsDownLoad}
              mutationGetDocuments={mutationGetDocuments}
              canDelete={true}
            />
            <p className="mt-2 text-sm text-gray-600"></p>
            <FileUpload
              entityId={incidentId}
              entityName={"incident"}
              entityId2={null}
              entityName2={null}
              entityId3={null}
              entityName3={null}
              mutationGetDocuments={null}
              setDocuments={setDocuments}
              isRequired={true}
              isReturn={true}
            />
          </div>
        ) : null}

        <div className="pt-5">
          <div className="flex justify-end">
            <button
              type="submit"
              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
            >
              Send Inn
            </button>
          </div>
        </div>
      </form>
    </MyPageLayout>
  )
}

const PensionerUpload = ({ location }) => {
  const authRequest = {
    ...loginRequest,
  }
  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      <NewIncidentContent location={location} />
    </MsalAuthenticationTemplate>
  )
}

export default PensionerUpload
