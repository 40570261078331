import { useAccount, useMsal } from "@azure/msal-react"
import React, { useEffect, useState } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import {
  deleteApi,
  fetchApi,
  fetchDownLoadFileApi,
  postGetDocumentApi,
} from "../../../Utils/Api.utils"
import { ExpenseReport, ExpenseReportLine } from "../../../Utils/Entities"
import { RemoveUnderScore } from "../../../Utils/FormatHelper"
import LoadingData from "../../common/graphichs/LoadingData"
import { classNames } from "../../common/graphichs/misc"
import ModalWithHeader from "../../common/modal/ModalWithHeader"
import ReportLine from "./ReportLine"

const MyReportLines = ({ reportId, canEdit, length }) => {
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const [repLines, setRepLines] = useState<ExpenseReportLine[]>([])
  // const [isLoading, setIsLoading] = useState<boolean>(true)
  const [documentExist, setDocumentExist] = useState<boolean>(false)

  const [report, setReport] = useState<ExpenseReport>()
  // const [canEdit, setCanEdit] = useState<boolean>(false)

  const reportLineQuery = useQuery(
    "expenserepLines",
    () =>
      fetchApi(
        process.env.GATSBY_API_URL + "/ExpenseReportLines/" + reportId,
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: data => {
        setRepLines(data)
        if (length !== null) {
          length(data?.length)
        }
      },
      onError: _error => {},
    }
  )

  return reportLineQuery.isLoading ? (
    <LoadingData />
  ) : (
    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5">
      <table className="min-w-full divide-y divide-gray-300">
        <thead className="bg-meny-700">
          <tr>
            <th
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-6"
            >
              Navn
            </th>
            <th
              scope="col"
              className="hidden px-3 py-3.5 text-left text-sm font-semibold text-white lg:table-cell"
            >
              Type utlegg
            </th>
            {/* <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-white sm:table-cell"
                  >
                    Kjøretillegg
                  </th> */}
            <th
              scope="col"
              className="hidden px-3 py-3.5 text-left text-sm font-semibold text-white lg:table-cell"
            >
              Antall
            </th>
            <th
              scope="col"
              className=" px-3 py-3.5 text-left text-sm font-semibold text-white "
            >
              Sum
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-white"
            ></th>
            {canEdit ? (
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-white"
              ></th>
            ) : null}
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
          {repLines?.map(line => (
            <ReportLine line={line} canEdit={canEdit} />
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default MyReportLines
