import { InteractionType } from "@azure/msal-browser"
import { MsalAuthenticationTemplate } from "@azure/msal-react"
import React, { useState } from "react"
import { MyEmployments } from "../../../../Utils/Entities"
import { Loading } from "../../../../Utils/Loading"
import { ErrorComponent } from "../../../common/graphichs/ErrorComponent"
import MyPageLayout from "../../../common/layouts/myPageLayout"
import useApi from "../../../hooks/useApi"
import ExistingEmploymentToOrdinary from "../../../mypagecomponents/membership/applications/FlowToOrdinary/ExistingEmploymentToOrdinary"
import { loginRequest } from "../../../security/authConfig"

function EmploymentToOrdinaryContent(props: { location: any }) {
  const employmentQuery = useApi<MyEmployments[]>("/Contacts/Employments")

  return (
    <MyPageLayout props="Arbeidsforhold">
      {!employmentQuery.isSuccess && <Loading />}
      {employmentQuery.isSuccess && (
        <ExistingEmploymentToOrdinary employments={employmentQuery.data!} />
      )}
    </MyPageLayout>
  )
}

const EmploymentToOrdinary = ({ location }) => {
  const authRequest = {
    ...loginRequest,
  }
  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      <EmploymentToOrdinaryContent location={location} />
    </MsalAuthenticationTemplate>
  )
}

export default EmploymentToOrdinary
