import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { useQuery } from "react-query"
import { fetchApi } from "../../../Utils/Api.utils"
import { Subscriptions } from "../../../Utils/Entities"
import MyPageLayout from "../../common/layouts/myPageLayout"
import dateFormat, { i18n } from "dateformat"
import { useAccount, useMsal } from "@azure/msal-react"
import ContentBox from "../../common/inputFields/ContentBox"
import LoadingData from "../../common/graphichs/LoadingData"
import MyJournal from "../../mypagecomponents/journal/MyJournal"

const Journal = () => {
  const subscriptionInfoQuery = useQuery(
    "subscriptionInfo",
    () =>
      fetchApi(
        process.env.GATSBY_API_URL + "/Contacts/Subscriptions",
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: data => {},
      onError: _error => {},
    }
  )
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Subscriptions>()

  //   const startDate = dateFormat(subscription?.startDate, "dd. mmmm yyyy")
  //   const endDate = dateFormat(subscription?.endDate, "dd. mmmm yyyy")

  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  return subscriptionInfoQuery.isLoading ? (
    <LoadingData />
  ) : (
    <MyPageLayout props="Tidsskrift">
      <div>
        {subscriptionInfoQuery.data?.map(journal => {
          if (journal?.subscriptionType === 778380004) {
            return <MyJournal journal={journal} />
          }
        })}
      </div>
    </MyPageLayout>
  )
}

export default Journal
