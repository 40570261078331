import React, { useEffect, useState } from "react"
import { SessionType } from "./EventType"
import { AccordionCheckbox } from "../../common/accordion"
import { AccordionControl } from "../../common/accordion/AccordionType"
import {
  DateTimeToDateTime,
  DateTimeToTime,
  DateTimeToDate,
} from "../../../Utils/DateFormater"
import { TextWithIcon } from "../../common/text"
import {
  attendanceValueToString,
  CityOptionsetValueToString,
} from "./helpers/Event"

const SessionAccoridonCheckbox = (props: {
  session: SessionType
  control: AccordionControl
  defaultChecked?: boolean
}) => {
  const [accordionSubtitle, setAccordionSubtitle] = useState("")
  const [disabled, setDisabled] = useState<boolean>()

  useEffect(() => {
    const startDate = DateTimeToDate(props.session.customFields?.startDate)
    const endDate = DateTimeToDate(props.session.customFields?.endDate)

    if (startDate === endDate) {
      const startTime = DateTimeToTime(props.session.customFields?.startDate)
      const endTime = DateTimeToTime(props.session.customFields?.endDate)

      setAccordionSubtitle(startDate + " " + startTime + " - " + endTime)
    } else {
      const startDateTime = DateTimeToDateTime(
        props.session.customFields?.startDate
      )
      const endDateTime = DateTimeToDateTime(
        props.session.customFields?.endDate
      )

      setAccordionSubtitle(startDateTime + " - " + endDateTime)
    }
  }, [
    props.session.customFields?.startDate,
    props.session.customFields?.endDate,
  ])

  useEffect(() => {
    if (!props.session.customFields?.stopwebsiteregistrationson) return

    const today = Date.now()
    const stopRegistration = Date.parse(
      props.session.customFields?.stopwebsiteregistrationson
    )

    setDisabled(today > stopRegistration)
  }, [props.session.customFields?.stopwebsiteregistrationson])

  return (
    <AccordionCheckbox
      title={props.session.name}
      subTitle={accordionSubtitle}
      defaultChecked={props.defaultChecked}
      control={props.control}
      disabled={disabled}
    >
      <div>
        <div
          dangerouslySetInnerHTML={{
            __html:
              props.session.customFields?.calendarContent ||
              "ingen beskrivelse tilgjengelig",
          }}
        ></div>
        <h4 className="mb-1 pt-6">Detaljer</h4>
        <div className="w-full h-px bg-gray-200 mb-4"></div>
        <div className="flex w-full pb-4">
          <div className="w-full pl-4 pt-4 space-y-4">
            {props.session.customFields?.projectno && (
              <TextWithIcon
                text={"prosjektNr: " + props.session.customFields?.projectno}
              />
            )}
            <TextWithIcon
              text={
                "Start Dato: " +
                DateTimeToDateTime(props.session.customFields?.startDate)
              }
            />
            <TextWithIcon
              text={
                "Slutt Dato: " +
                DateTimeToDateTime(props.session.customFields?.endDate)
              }
            />
          </div>
          <div className="w-full pl-4 pt-4 space-y-4">
            <TextWithIcon
              text={
                "Oppmøte type: " +
                attendanceValueToString(
                  props.session.customFields?.attendance?.value
                )
              }
            />
            <TextWithIcon
              text={
                "By: " +
                (CityOptionsetValueToString(
                  props.session.customFields?.city?.value
                ) || "")
              }
            />
            {/* <TextWithIcon
              text={"Sted: " + (props.session.building?.addressComposite || "")}
            /> */}
            <TextWithIcon
              text={
                "Kursholder: " +
                (props.session.speakers?.map(item => item.name).join(", ") ||
                  "")
              }
            />
          </div>
        </div>
      </div>
    </AccordionCheckbox>
  )
}

export default SessionAccoridonCheckbox
