import React from "react"
import { CardType } from "./CardType"

const Card = (props: CardType) => {
  return (
    <div className="rounded-lg overflow-hidden">
      <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          {props.title}
        </h3>
        <p className="mt-1 text-sm text-gray-600">{props.description}</p>
      </div>
      <div className="bg-white px-4 py-5">{props.children}</div>
    </div>
  )
}

export default Card
