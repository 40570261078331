import React from "react"

const HeaderText = props => {
  const text = props.text
  return (
    <h2 className="text-center font-medium text-md text-meny-700 sm:text-2xl">
      {text}
    </h2>
  )
}

export default HeaderText
