import {
  MsalAuthenticationTemplate,
  useAccount,
  useMsal,
} from "@azure/msal-react"
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ChevronRightIcon,
  MailIcon,
  MailOpenIcon,
  TrendingDownIcon,
  TrendingUpIcon,
} from "@heroicons/react/outline"
import React, { useContext, useState } from "react"
import { useQuery, useQueryClient } from "react-query"
import MessageRead from "../../mypagecomponents/messages/MessageRead"
import LoadingData from "../../common/graphichs/LoadingData"
import MyPageLayout from "../../common/layouts/myPageLayout"
import { fetchApi } from "../../../Utils/Api.utils"
import { MessageContext } from "../../common/customhooks.tsx/MessageContext"
import { RemoveHtml, ShortenInputValue } from "../../../Utils/FormatHelper"
import { classNames } from "../../common/graphichs/misc"
import { loginRequest } from "../../security/authConfig"
import { InteractionType } from "@azure/msal-browser"
import { ErrorComponent } from "../../common/graphichs/ErrorComponent"
import { Loading } from "../../../Utils/Loading"

const MyMessagesContent = () => {
  const queryClient = useQueryClient()
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const [openMessageModal, setOpenMessageModal] = useState(false)
  const [openMessageData, setOpenMessageData] = useState("")
  const [messages, setMessages] = useState([])
  const [allMessages, setAllMessages] = useState([])
  const [hideSome, setHideSome] = useState(false)
  const [showHideViewAllButton, setsHowHideViewAllButton] = useState(false)

  const userMessagessQuery = useQuery(
    "userMessagesData",
    () =>
      fetchApi(
        process.env.GATSBY_API_URL + "/Messages/me",
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: data => {
        setAllMessages(data)
        if (data.length > 10) {
          setsHowHideViewAllButton(true)
          setMessages(data.slice(0, 10))

          if (hideSome) {
            setMessages(allMessages)
          }
        }
        if (data.length < 11) {
          setsHowHideViewAllButton(false)
          setMessages(data)
        }
      },
      onError: _error => {},
    }
  )

  const openMessage = message => {
    setOpenMessageData(message)
    setOpenMessageModal(true)
  }

  const showAll = () => {
    setHideSome(true)
    setMessages(allMessages)
  }

  const showLess = () => {
    setHideSome(false)
    queryClient.invalidateQueries(["userMessagesData"])
    setMessages(messages)
  }

  return userMessagessQuery.isLoading ? (
    // // "Laster inn..."
    <LoadingData />
  ) : userMessagessQuery.isError ? (
    userMessagessQuery.error.message
  ) : (
    <MessageContext.Consumer>
      {({ messageCount, changeMessageCount }) => (
        <MyPageLayout props="Mine meldinger">
          <div>
            <div>
              {openMessageModal && (
                <MessageRead
                  message={openMessageData}
                  setOpenMessageModal={setOpenMessageModal}
                />
              )}
            </div>
            <div className="bg-white shadow sm:rounded-md">
              <ul role="list" className="divide-y divide-gray-200">
                {messages.map((message, index) => (
                  <li key={message.id}>
                    <a
                      id={message.id}
                      className="block hover:bg-gray-50"
                      onClick={e => openMessage(message)}
                    >
                      <div className="flex items-center px-4 py-4 sm:px-6 cursor-pointer hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-meny-700">
                        <div className="min-w-0 flex-1 flex items-center">
                          {message.direction ? (
                            <span
                              className={classNames(
                                "bg-green-500",
                                "h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white"
                              )}
                            >
                              <TrendingDownIcon
                                className="w-5 h-5 text-white"
                                aria-hidden="true"
                              />
                            </span>
                          ) : (
                            <span
                              className={classNames(
                                "bg-gray-500",
                                "h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white"
                              )}
                            >
                              <TrendingUpIcon
                                className="w-5 h-5 text-white"
                                aria-hidden="true"
                              />
                            </span>
                          )}
                          <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-3 md:gap-4">
                            <div>
                              {message.direction ? (
                                <p className="text-sm font-medium text-indigo-600 truncate">
                                  Norsk psykologforening {message.fromName}
                                </p>
                              ) : null}
                              <p className="mt-2 flex items-center text-sm text-gray-600">
                                <MailIcon
                                  className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                                <span className="truncate">
                                  {message.subject}
                                </span>
                              </p>
                            </div>
                            <div>
                              <p className="mt-2 flex items-center text-sm text-gray-600">
                                <span className="truncate">
                                  {ShortenInputValue(message.description, 60)}
                                </span>
                              </p>
                            </div>
                            <div className="hidden md:block">
                              <div>
                                <p className="text-sm text-gray-900">
                                  <time dateTime={message.createdOn}>
                                    {new Intl.DateTimeFormat("nb-NO", {
                                      dateStyle: "medium",
                                      timeStyle: "short",
                                    }).format(new Date(message.createdOn))}
                                  </time>
                                </p>
                                <p className="mt-2 flex items-center text-sm text-gray-600">
                                  {message.direction ? (
                                    <div>
                                      {message.isRead ? (
                                        <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                                          Lest
                                        </span>
                                      ) : (
                                        <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-green-800">
                                          Ikke lest
                                        </span>
                                      )}
                                    </div>
                                  ) : null}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div className="mt-6">
              {showHideViewAllButton ? (
                <>
                  {!hideSome ? (
                    <button
                      type="button"
                      onClick={showAll}
                      className="w-full flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                    >
                      Vis alle
                    </button>
                  ) : (
                    <button
                      type="button"
                      onClick={showLess}
                      className="w-full flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                    >
                      Vis mindre
                    </button>
                  )}
                </>
              ) : null}
            </div>
          </div>
        </MyPageLayout>
      )}
    </MessageContext.Consumer>
  )
}

const MyMessages = () => {
  const authRequest = {
    ...loginRequest,
  }
  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      <MyMessagesContent />
    </MsalAuthenticationTemplate>
  )
}

export default MyMessages
