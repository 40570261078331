import {
  InteractionRequiredAuthError,
  InteractionStatus,
  InteractionType,
} from "@azure/msal-browser"
import {
  MsalAuthenticationTemplate,
  useAccount,
  useIsAuthenticated,
  useMsal,
} from "@azure/msal-react"
import { Disclosure } from "@headlessui/react"
import { ChevronDownIcon } from "@heroicons/react/outline"
import axios from "axios"
import { Link, navigate } from "gatsby"
import React, { useState, useCallback, useEffect } from "react"
import { useQuery } from "react-query"
import { fetchApi, fetchApiAnonymous } from "../../../Utils/Api.utils"
import { Loading } from "../../../Utils/Loading"
import { ErrorComponent } from "../../common/graphichs/ErrorComponent"
import LoadingData from "../../common/graphichs/LoadingData"
import { classNames } from "../../common/graphichs/misc"
import MyPageLayout from "../../common/layouts/myPageLayout"
import HeaderText from "../../common/text/Header"
import { loginRequest } from "../../security/authConfig"
import Samtykker from "../../mypagecomponents/contacinfo/Consent"
import MarketingForm from "./MarketingForm"

const MyPageContent = () => {
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const id = process.env.CONTACT_ID
  const [city, setCity] = useState()
  const isAuthenticated = useIsAuthenticated()
  const [access, setAccess] = useState<boolean>(true)

  const userInfoQuery = useQuery(
    "userInfo",
    () =>
      fetchApi(
        process.env.GATSBY_API_URL + "/Contacts/me",
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: data => { },
      onError: _error => { },
    }
  )

  const webRoleInfo = useQuery(
    "webRoleData",
    () =>
      fetchApi(
        `${process.env.GATSBY_API_URL}/Contacts/Roles`,
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      enabled: !!isAuthenticated,
      onSuccess: data => {
        if (data.length === 0) {
          setAccess(false)
        } else setAccess(true)
      },
      onError: _error => { },
    }
  )

  return userInfoQuery.isLoading ? (
    <LoadingData />
  ) : (
    <>
      <MyPageLayout props="Min Profil">
        <div className="">
          <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
            <div className="max-w-3xl mx-auto divide-y-2 divide-gray-200 mb-6">
              <HeaderText text="Kontaktinformasjon" />
              <dl className="mt-6 space-y-6 divide-y divide-gray-200 bg-white rounded-lg shadow px-5 py-6 sm:px-6">
                <Disclosure as="div" className="pt-6 sm:px-4">
                  {({ open }) => (
                    <>
                      <dt className="text-lg">
                        <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                          <span className="font-medium text-gray-900">
                            {userInfoQuery.data?.firstName}{" "}
                            {userInfoQuery.data?.lastName}
                          </span>
                          <span className="ml-6 h-7 flex items-center">
                            <ChevronDownIcon
                              className={classNames(
                                open ? "-rotate-180" : "rotate-0",
                                "h-6 w-6 transform"
                              )}
                              aria-hidden="true"
                            />
                          </span>
                        </Disclosure.Button>
                      </dt>
                      <Disclosure.Panel as="dd" className="mt-2 pr-2 ">
                        <div className="sm:grid sm:grid-cols-2 gap-3 mb-4">
                          {userInfoQuery.data?.erpNumber !== undefined ? (
                            <p className="text-base font-bold text-red-700 sm:col-span-2">
                              Kunde-/medlemsnummer:{" "}
                              {userInfoQuery.data?.erpNumber}
                            </p>
                          ) : (
                            <></>
                          )}
                          <p className="text-base text-gray-600 sm:col-span-1">
                            HPR nummer: {userInfoQuery.data?.hprNumber}
                          </p>
                          <p className="text-base text-gray-600 sm:col-span-1">
                            Relasjonstype:{" "}
                            {userInfoQuery.data?.customerTypeName?.replaceAll(
                              "_",
                              " "
                            )}
                          </p>
                          <p className="text-base text-gray-600 sm:col-span-1">
                            Epost: {userInfoQuery.data?.emailAddress1}
                          </p>
                          <p className="text-base text-gray-600 sm:col-span-1">
                            C/O: {userInfoQuery.data?.address1Line1}
                          </p>
                          <p className="text-base text-gray-600 sm:col-span-1">
                            Adresse: {userInfoQuery.data?.address1Line2}
                          </p>
                          {userInfoQuery.data?.address1CountryCode !== "NO" ? (
                            <p className="text-base text-gray-600 sm:col-span-1">
                              Ekstrafelt adresse:{" "}
                              {userInfoQuery.data?.address1Line3}
                            </p>
                          ) : (
                            <></>
                          )}
                          <p className="text-base text-gray-600 sm:col-span-1">
                            Postnummer: {userInfoQuery.data?.address1PostalCode}
                          </p>
                          <p className="text-base text-gray-600 sm:col-span-1">
                            Poststed: {userInfoQuery.data?.address1City}
                          </p>
                          <p className="text-base text-gray-600 sm:col-span-1">
                            Land: {userInfoQuery.data?.address1CountryName}
                          </p>
                          <p className="text-base text-gray-600 sm:col-span-1">
                            Kjønn: {userInfoQuery.data?.genderName}
                          </p>
                        </div>
                        {access ? (
                          <Link
                            // type="submit"
                            to="/app/profil/endrebruker/"
                            state={{ origin: "Profil" }}
                            className="ml-0 inline-flex justify-end py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-md text-white bg-meny-700 hover:bg-meny-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
                          >
                            Endre
                          </Link>
                        ) : (
                          <></>
                        )}
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              </dl>
            </div>

            {access ? <Samtykker id={id} /> : <></>}
            {/* <h2 className="text-center text-md text-meny-700 sm:text-2xl">
              Arbeidsforhold
            </h2>
            <Arbeidsforhold id={id} /> */}

            {/* <PageDivider /> */}

            <MarketingForm />
          </div>
        </div>
      </MyPageLayout>
    </>
  )
}

const ContactInfo = ({ data }) => {
  const authRequest = {
    ...loginRequest,
  }
  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      <MyPageContent />
    </MsalAuthenticationTemplate>
  )
}

export default ContactInfo
