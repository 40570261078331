import { InteractionType } from "@azure/msal-browser"
import {
  MsalAuthenticationTemplate,
  useAccount,
  useMsal,
} from "@azure/msal-react"
import nb from "date-fns/locale/nb"
import { navigate } from "gatsby"
import React, { useState } from "react"
import ReactDatePicker, { registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { Controller, useForm } from "react-hook-form"
import { useMutation, useQuery } from "react-query"
import { toast } from "react-toastify"
import { Employment } from "../../../Utils/Entities"
import { fetchApi, patchApi } from "../../../Utils/Api.utils"
import LoadingData from "../../common/graphichs/LoadingData"
import MyPageLayout2 from "../../common/layouts/myPageLayout2"
import EndDateOnEmploymentsForm from "../../mypagecomponents/work/EndDateOnEmploymentsForm"
import { loginRequest } from "../../security/authConfig"
import { ErrorComponent } from "../../common/graphichs/ErrorComponent"
import { Loading } from "../../../Utils/Loading"
import { da } from "date-fns/locale"
registerLocale("nb", nb)

export type formValues = {
  workId: string
  endDate: Date
}

const TerminateEmploymentsContent = () => {
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const [showLoadingData, setShowLoadingData] = useState<boolean>(false)
  const [employments, setEmployments] = useState<Employment[]>([])

  const employmentQuery = useQuery(
    "employments",
    () =>
      fetchApi(
        process.env.GATSBY_API_URL + "/Contacts/Employments",
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: data => {
        setEmployments(data)
      },
      onError: _error => {},
    }
  )

  const employmentsData = (employmentQuery.data as Employment[])?.filter(
    item => !item.endDate || new Date(item.endDate) > new Date()
  )

  const UpdateEmployment = useMutation<unknown, unknown, Partial<Employment>>(
    data => {
      console.log("UpdateEmployment", data)
      return patchApi(
        `${process.env.GATSBY_API_URL}/Employments/${data?.id}`,
        data,
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: data => {
        toast.success("Arbeidsforholdet er oppdatert")
        updateStatus.mutate()
      },
      onError: _error => {
        setShowLoadingData(false)
        toast.error("Det har skjedd en feil, prøv igjen senere", {
          position: "top-center",
          autoClose: 6000,
        })
      },
      onSettled: () => {},
    }
  )

  const updateStatus = useMutation(
    () => {
      return patchApi(
        `${process.env.GATSBY_API_URL}/Contacts/me`,
        {
          professionallyActiveStatus: 292460001,
        },
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: data => {
        window.setTimeout(() => {
          navigate("/app")
        }, 2500)
      },
      onError: _error => {
        setShowLoadingData(false)
        toast.error("Det har skjedd en feil, prøv igjen senere", {
          position: "top-center",
          autoClose: 6000,
        })
      },
      onSettled: () => {},
    }
  )

  return showLoadingData || employmentQuery.isLoading ? (
    <LoadingData />
  ) : (
    <MyPageLayout2 props="Avslutt dine arbeidsforhold">
      <div className="space-y-8 md:space-y-4">
        <EndDateOnEmploymentsForm
          employments={employmentsData}
          onSave={data => {
            UpdateEmployment.mutateAsync(data)
          }}
        />
      </div>
    </MyPageLayout2>
  )
}

const TerminateEmployments = location => {
  const authRequest = {
    ...loginRequest,
  }
  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      <TerminateEmploymentsContent />
    </MsalAuthenticationTemplate>
  )
}

export default TerminateEmployments
