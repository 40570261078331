import { InteractionType } from "@azure/msal-browser"
import {
  MsalAuthenticationTemplate,
  useAccount,
  useMsal,
} from "@azure/msal-react"
import { ErrorMessage } from "@hookform/error-message"
import { Link, navigate } from "gatsby"
import React, { useEffect, useState } from "react"
import ReactDatePicker from "react-datepicker"
import { Controller, useForm } from "react-hook-form"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { toast } from "react-toastify"
import { whiteButton } from "../../../../styles/tailwindClasses"
import {
  fetchApi,
  fetchApiStandard,
  postApi,
} from "../../../../Utils/Api.utils"
import { DateTimeToDateTime } from "../../../../Utils/DateFormater"
import { Project } from "../../../../Utils/Entities"
import { Loading } from "../../../../Utils/Loading"
import { Contact } from "../../../../Utils/Types"
import { ErrorComponent } from "../../../common/graphichs/ErrorComponent"
import LoadingData from "../../../common/graphichs/LoadingData"
import MyPageLayout from "../../../common/layouts/myPageLayout"
import VisualStep from "../../../common/Progress/VisualStep"
import { StepsType } from "../../../common/Steps/StepsType"
import HelperText from "../../../common/text/HelperText"
import AddExpenseReport from "../../../mypagecomponents/expensereport/AddExpenseReport"
import AddExpenseReportLine from "../../../mypagecomponents/expensereport/AddExpenseReportLine"
import { loginRequest } from "../../../security/authConfig"

const NewExpenseReportContent = ({ isDefaultStep2, id }) => {
  const [reportLines, setReportLines] = useState([])
  const [repLinesStartedEditing, setRepLinesStartedEditing] =
    useState<boolean>(false)
  const [step1, setStep1] = useState<boolean>(true)
  const [entityId, setEntityId] = useState<string>(id || "")
  const [firstStep, setFirstStep] = useState<string>("current")
  const [secondStep, setSecondStep] = useState<string>("upcoming")
  const [firstStepCompleted, setFirstStepCompleted] = useState<boolean>(false)
  const [secondStepCompleted, setSecondStepCompleted] = useState<boolean>(false)

  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})

  const steps: StepsType = [
    {
      id: "1",
      name: "Beskrivelse",
      status: firstStep,
      completed: firstStepCompleted,
    },
    {
      id: "2",
      name: "Detaljer",
      status: secondStep,
      completed: secondStepCompleted,
    },
  ]

  const stepsDefault: StepsType = [
    {
      id: "1",
      name: "Beskrivelse",
      status: "completed",
      completed: true,
    },
    {
      id: "2",
      name: "Detaljer",
      status: "current",
      completed: false,
    },
  ]

  return (
    <MyPageLayout props="Nytt utlegg">
      {isDefaultStep2 ? (
        <VisualStep steps={stepsDefault} />
      ) : (
        <VisualStep steps={steps} />
      )}
      {step1 && !isDefaultStep2 ? (
        <>
          {/* <Link
            to="/app/dialog/mineutlegg"
            className={
              whiteButton +
              " inline-flex mb-8 items-center justify-center sm:w-auto "
            }
            activeClassName="active-link"
          >
            Tilbake
          </Link> */}
          <div className="mt-10">
            <AddExpenseReport
              setEntityId={setEntityId}
              setStep1={setStep1}
              setFirstStep={setFirstStep}
              setSecondStep={setSecondStep}
            />
          </div>
        </>
      ) : (
        <div className="mt-10">
          <button
            className={
              whiteButton +
              " inline-flex mb-8 items-center justify-center sm:w-auto "
            }
            onClick={e =>
              navigate("/app/dialog/endreutlegg", {
                state: {
                  id: entityId,
                  showSteps: true,
                },
              })
            }
            // activeClassName="active-link"
          >
            Tilbake
          </button>
          <AddExpenseReportLine entityId={entityId} />{" "}
        </div>
      )}
    </MyPageLayout>
  )
}

const NewExpenseReport = ({ location }) => {
  const authRequest = {
    ...loginRequest,
  }
  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      <NewExpenseReportContent
        isDefaultStep2={location.state.isDefaultStep2}
        id={location.state.reportId}
      />
    </MsalAuthenticationTemplate>
  )
}

export default NewExpenseReport
