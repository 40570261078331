import React from "react"
import { decodeArray } from "use-query-params"
import {
  SessionType,
  SpeakerEngagement,
} from "../../../mypagecomponents/event/EventType"
import { RolesOptionsetToString } from "../../../mypagecomponents/event/helpers/Event"

const CourseInstructorSection = (props: {
  speakerEngagementData: Array<SpeakerEngagement>
  eventSpeakerEngagement: Array<string>
  sessions: Array<SessionType>
}) => {
  const data: { lable: string; value?: string }[] = props.speakerEngagementData
    .filter(item => item.speaker?.contact) // remove all where speaker is null
    .filter(
      item =>
        props.eventSpeakerEngagement.findIndex(item2 => item2 === item.id) !==
        -1
    )
    .map(item => ({
      lable:
        (item.speaker?.contact?.firstName || "") +
        " " +
        (item.speaker?.contact?.lastName || ""),
      value: RolesOptionsetToString(item.role),
    }))

  if (data.length === 0) return null

  return (
    <div>
      <h1 className="mt-8 text-2xl font-bold text-meny-700">Kursholdere</h1>
      <div className="w-full h-px bg-gray-200 mb-4"></div>
      <div className="divide-y divide-gray-200 bg-white rounded-lg px-4">
        {data
          // .filter(item => item.value !== undefined)
          .filter(
            (item, index, arr) =>
              arr.findIndex(
                item2 =>
                  item2.value === item.value && item2.lable === item.lable
              ) === index
          )
          .map((item, index) => (
            <div
              key={index}
              className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt className="text-sm font-medium text-gray-900">
                {item.lable}
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {/* {item.value?.split("\n").map(item2 => (
                  <div key={item2}>{item2}</div>
                ))} */}
                {item.value}
              </dd>
            </div>
          ))}
      </div>
    </div>
  )
}

export default CourseInstructorSection
