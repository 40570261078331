import { useAccount, useMsal } from "@azure/msal-react"
import { Switch } from "@headlessui/react"
import { ErrorMessage } from "@hookform/error-message"
import React, { useContext, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useMutation, useQuery } from "react-query"
import { toast } from "react-toastify"
import { fetchApi, postApi } from "../../../Utils/Api.utils"
import { Countries } from "../../../Utils/Entities"
import { FormVal } from "../../../Utils/Types"
import { classNames } from "../../common/graphichs/misc"
import HelperText from "../../common/text/HelperText"
import { NewMembershipContext } from "../../mypage/membership/NewMember"
import FileUpload from "../document/FileUpload"
import {
  changeMembershipTab,
  changeMembershipTabToNext,
  changeMembershipTabToPrevious,
} from "./helpers"

const EducationInfo = ({
  setEducationData,
  setDocuments,
  setTabId,
  memberTypeParent,
}) => {
  const [membershipNextTabId, setMembershipNextTabId] = useState(0)
  const [membershipPreviousTabId, setMembershipPreviousTabId] = useState(0)
  const [universitySelect, setUniversitySelect] = useState(0)
  const [examYearSelected, setExamYear] = useState("")
  const [examPeriodSelected, setExamPeriod] = useState("")
  const [alternativeUniversitySelect, setAlternativeUniversitySelect] =
    useState("")
  const [alternativePlaceOfEducation, setAlternativePlaceOfEducation] =
    useState(false)
  const [countries, setCountries] = useState<Countries[]>([])
  const [disableCountry, setDisableCountry] = useState(false)
  const [defaultCountryCode, setDefaultCountryCode] = useState<string>()
  const [educations, setEducations] = useState([])
  const currentYear = new Date().getFullYear()
  const [years, setYears] = useState([])

  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const {
    register,
    handleSubmit,
    setError,
    reset,
    control,
    formState: { errors },
  } = useForm<FormVal>()

  useEffect(() => {
    setDefaultCountryCode("NO")
    changeMembershipTabToNext(memberTypeParent.value, setMembershipNextTabId)
    changeMembershipTabToPrevious(
      memberTypeParent.value,
      setMembershipPreviousTabId
    )

    if (memberTypeParent.value === 0) {
      const year = new Date().getFullYear()
      const yearsArr = Array.from(new Array(20), (val, index) => index + year)
      setYears(yearsArr)
    }

    if (memberTypeParent.value === 1) {
      const year = new Date().getFullYear() - 55
      const yearsArr = Array.from(new Array(56), (val, index) => index + year)
      setYears(yearsArr)
    }
  }, [])

  const countriesQuery = useQuery(
    "countries",
    () =>
      fetchApi(
        process.env.GATSBY_API_URL + "/Addresses/Countries",
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: data => {
        setCountries(data)
      },
      onError: _error => { },
    }
  )

  const univercityQuery = useQuery(
    "university",
    () =>
      fetchApi(
        process.env.GATSBY_API_URL + "/Accounts/Educations",
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: data => {
        setEducations(data)
      },
      onError: _error => { },
    }
  )

  const onSelectUniversityChange = e => {
    setUniversitySelect(e.target.value)

    if (e.target.value === 0) setDisableCountry(false)

    if (e.target.value !== 0) {
      setDefaultCountryCode("NO")
      setDisableCountry(true)
    }
  }
  const [diffUni, setDiffUni] = useState<boolean>(false)

  const showEmpAlt = () => {
    setAlternativePlaceOfEducation(!alternativePlaceOfEducation)
    if (!alternativePlaceOfEducation) {
      setDiffUni(true)
      setUniversitySelect(0)
      setDisableCountry(false)
    } else {
      setDiffUni(false)
      setDefaultCountryCode("NO")
      setDisableCountry(true)
    }
  }

  const OnCountryChange = e => setDefaultCountryCode(e.target.value)

  const postIncident = async (data, e) => {
    e.preventDefault()

    if (
      alternativePlaceOfEducation &&
      universitySelect == 0 &&
      alternativeUniversitySelect == ""
    ) {
      setError("custom", {
        type: "custom",
        message: "Vennligst fyll ut Annet universitet! ",
      })
      return
    }

    if (!alternativePlaceOfEducation && universitySelect == 0) {
      setError("custom", {
        type: "custom",
        message: "Vennligst velg universitet! ",
      })
      return
    }

    if (data.examYear === "") data.examYear = years[0]
    if (data.placeOfEducationId === "0" || diffUni)
      data.placeOfEducationId = null

    setEducationData(data)

    changeMembershipTab(membershipNextTabId)
    setTabId(membershipNextTabId)
  }

  return (
    <div>
      <form
        className="space-y-8 divide-y divide-gray-200"
        method="POST"
        onSubmit={handleSubmit(postIncident)}
      >
        <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
          <div>
            <h3 className="text-lg leading-6 font-medium text-meny-700">
              Medlemskapsinformasjon
            </h3>
            {/* <p>Felter markert med * er obligatoriske</p> */}
          </div>

          <div className="pt-6 sm:pt-5">
            <div role="group" aria-labelledby="label-notifications">
              <div>
                <div>
                  <div>
                    <div className="pt-6 sm:pt-5">
                      <h1>Utdanning</h1>
                      <div role="group" aria-labelledby="label-notifications">
                        <div className="sm:grid sm:grid-cols-2 sm:gap-4 sm:items-baseline">
                          <div>
                            <div
                              className="text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700 "
                              id="label-notifications"
                            >
                              Universitet*:
                            </div>
                          </div>
                          <select
                            id="placeOfEducationId"
                            disabled={alternativePlaceOfEducation}
                            {...register("placeOfEducationId")}
                            name="placeOfEducationId"
                            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-meny-700 focus:border-meny-500 sm:text-sm rounded-md"
                            onChange={e => onSelectUniversityChange(e)}
                            value={universitySelect}
                          >
                            <option value={0} key={0}>
                              ---------
                            </option>
                            {educations?.map(U => (
                              <option value={U.id} key={U.id}>
                                {U.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="pt-6 sm:pt-5">
                      <div role="group">
                        <div className="sm:grid sm:grid-cols-2 sm:gap-4 sm:items-baseline">
                          <label
                            htmlFor="alternativePlaceOfEducation"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Mitt universitet står ikke i listen:
                          </label>

                          <div className="mt-1 sm:mt-0 sm:col-span-1 sm:col-start-2">
                            <Switch
                              checked={alternativePlaceOfEducation}
                              onChange={showEmpAlt}
                              className={classNames(
                                alternativePlaceOfEducation
                                  ? "bg-meny-600"
                                  : "bg-gray-900",
                                "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
                              )}
                            >
                              <span
                                aria-hidden="true"
                                className={classNames(
                                  alternativePlaceOfEducation
                                    ? "translate-x-5"
                                    : "translate-x-0",
                                  "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                                )}
                              />
                            </Switch>
                          </div>
                        </div>
                      </div>
                    </div>

                    {diffUni ? (
                      <div>
                        <div className="pt-6 sm:pt-5">
                          <div
                            role="group"
                            aria-labelledby="label-notifications"
                          >
                            <div className="sm:grid sm:grid-cols-2 sm:gap-4 sm:items-baseline">
                              <label
                                htmlFor="alternativePlaceOfEducation"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Annet universitet*:
                              </label>

                              <input
                                {...register("alternativePlaceOfEducation", {
                                  pattern: {
                                    value: /^[-A-Za-zÆØÅæøå ]+$/i,
                                    message:
                                      "Kun bokstaver og bindestrek er tillatt.",
                                  },
                                  minLength: {
                                    value: 2,
                                    message: "Minimum tegn er 2",
                                  },
                                  maxLength: {
                                    value: 100,
                                    message: "Maks tegn er 100",
                                  },
                                })}
                                type="text"
                                name="alternativePlaceOfEducation"
                                id="alternativePlaceOfEducation"
                                onChange={e =>
                                  setAlternativeUniversitySelect(e.target.value)
                                }
                                className="block w-full shadow-sm focus:ring-meny-700 sm:text-sm focus:border-meny-500 border-gray-300 rounded-md"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="pt-6 sm:pt-5">
                          <div
                            role="group"
                            aria-labelledby="label-notifications"
                          >
                            <div className="sm:grid sm:grid-cols-2 sm:gap-4 sm:items-baseline">
                              <label
                                htmlFor=""
                                className="block text-sm font-medium text-gray-700"
                              >
                                Vedlegg:
                                <HelperText helpertext="Alle filer må lastes opp samtidig" />
                              </label>

                              <FileUpload
                                entityId={null}
                                entityName={"incident"}
                                entityId2={null}
                                entityName2={null}
                                entityId3={null}
                                entityName3={null}
                                mutationGetDocuments={null}
                                setDocuments={setDocuments}
                                isReturn={true}
                                isRequired={diffUni}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div></div>
                    )}

                    <div className="pt-6 sm:pt-5">
                      <div role="group">
                        <div className="sm:grid sm:grid-cols-2 sm:gap-4 sm:items-baseline">
                          <div>
                            {memberTypeParent.value === 0 ? (
                              <div
                                className="text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700 "
                                id="label-notifications"
                              >
                                Planlagt eksamensår*:
                              </div>
                            ) : (
                              <div
                                className="text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700 "
                                id="label-notifications"
                              >
                                Eksamensår*
                              </div>
                            )}
                          </div>
                          <div className="sm:grid sm:grid-cols-2 sm:gap-4 sm:items-baseline">
                            <select
                              id="examYear"
                              name="examYear"
                              {...register("examYear")}
                              onChange={e => setExamYear(e.target.value)}
                              // defaultValue={years[0]}
                              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-meny-700 focus:border-meny-500 sm:text-sm rounded-md"
                            >
                              {years.map((year, index) => {
                                return (
                                  <option key={`year${index}`} value={year}>
                                    {year}
                                  </option>
                                )
                              })}
                            </select>
                            <select
                              id="examPeriod"
                              name="examPeriod"
                              {...register("examPeriod")}
                              onChange={e => setExamPeriod(e.target.value)}
                              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-meny-700 focus:border-meny-500 sm:text-sm rounded-md"
                            >
                              <option>Vår</option>
                              <option>Høst</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="pt-6 sm:pt-5">
                      <div role="group" aria-labelledby="label-notifications">
                        <div className="sm:grid sm:grid-cols-2 sm:gap-4 sm:items-baseline">
                          <div>
                            <div
                              className="text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700 "
                              id="label-notifications"
                            >
                              Land*:
                            </div>
                          </div>
                          <select
                            {...register("address1CountryCode")}
                            name="address1CountryCode"
                            id="address1CountryCode"
                            key="address1CountryCode"
                            value={defaultCountryCode}
                            onChange={e => OnCountryChange(e)}
                            disabled={disableCountry}
                            className=" block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                          >
                            {countries.map(country => (
                              <option
                                key={country.countryCode}
                                value={country.countryCode}
                              >
                                {country.countryName}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div {...register("custom")} className="pt-5 flex justify-end">
            <ErrorMessage
              errors={errors}
              name="custom"
              render={({ message }) => (
                <p className="text-sm text-red-600">{message}</p>
              )}
            />

            <button
              type="button"
              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
              onClick={() => {
                changeMembershipTab(membershipPreviousTabId)
                setTabId(membershipPreviousTabId)
              }}
            >
              Tilbake
            </button>

            <button
              type="submit"
              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-meny-700 hover:bg-meny-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
            >
              Neste
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default EducationInfo
