import React, { useContext, useEffect } from "react"
import { EventRegistrationTypeEnum } from "../helpers/Event"
import SessionAccoridon from "../SessionAccordion"
import SessionAccordionCheckboxGroup from "../SessionAccordionCheckboxGroup"
import { InnmeldingContext } from "./dataStorage"

const Kursvelger = () => {
  const InnmeldingData = useContext(InnmeldingContext)
  if (!InnmeldingData) return <div></div>

  useEffect(() => {
    if (
      InnmeldingData.registrationType[0] ===
      EventRegistrationTypeEnum.requireAll
    ) {
      InnmeldingData.addSession("test")
    }
  }, [])

  return (
    <section>
      {InnmeldingData.registrationType[0] ===
        EventRegistrationTypeEnum.requireAll && (
        <>
          <h2>Kurs/Samlinger du vil påmeldt:</h2>
          <div className="w-full h-px bg-gray-200 mb-4"></div>
          <div className="max-w-3xl mx-auto divide-y divide-gray-200 rounded-lg overflow-hidden">
            {(InnmeldingData.sessionQuery || []).map(item => (
              <SessionAccoridon key={item.id} session={item}></SessionAccoridon>
            ))}
          </div>
        </>
      )}
      {InnmeldingData.registrationType[0] ===
        EventRegistrationTypeEnum.optional && (
        <>
          <h2>Velg hvilke kurs du vil melde deg på:</h2>
          <div className="w-full h-px bg-gray-200 mb-4"></div>
          <SessionAccordionCheckboxGroup
            session={InnmeldingData.sessionQuery || []}
            selectedSessions={InnmeldingData.selectedSessions[0]}
            control={{
              id: "temp",
              add: InnmeldingData.addSession,
              remove: InnmeldingData.removeSession,
            }}
          />
        </>
      )}
    </section>
  )
}

export default Kursvelger
