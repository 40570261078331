import React, { useState } from "react"
import { Disclosure } from "@headlessui/react"
import { ChevronDownIcon } from "@heroicons/react/outline"
import { AccordionCheckboxType, AccordionControl } from "./AccordionType"
import { classNames } from "../graphichs/misc"

const AccordionCheckbox = (
  props: AccordionCheckboxType & { control: AccordionControl }
) => {
  const [checked, setChecked] = useState<boolean>(props.defaultChecked || false)

  const onChange = (e: any) => {
    let checkedArr: Array<string> = []
    if (e.target.checked) checkedArr = props.control.add(props.control.id)
    else checkedArr = props.control.remove(props.control.id)

    setChecked(checkedArr.includes(props.control.id))
  }

  return (
    <div className="bg-white shadow">
      <Disclosure as="div">
        {({ open }) => (
          <>
            <div className="text-lg">
              <div className="flex">
                <div className="h-full px-4 py-8 flex justify-center items-center">
                  <input
                    type="checkbox"
                    checked={checked}
                    onChange={onChange}
                    disabled={props.disabled}
                    className="disabled:opacity-30"
                  />
                </div>
                <Disclosure.Button className="pt-6 pb-6 text-left w-full flex justify-between items-start text-gray-400">
                  <div className="justify-start space-x-4 flex flex-col md:flex-row">
                    <h3 className="text-black">{props.title}</h3>
                    <span className="text-base font-normal">
                      {props.subTitle}
                    </span>
                  </div>
                  <span className="ml-6 h-7 flex items-center px-4">
                    <ChevronDownIcon
                      className={classNames(
                        open ? "-rotate-180" : "rotate-0",
                        "h-6 w-6 transform"
                      )}
                      aria-hidden="true"
                    />
                  </span>
                </Disclosure.Button>
              </div>
            </div>
            <Disclosure.Panel as="div">
              <div className="px-5">
                <div className="w-full h-px bg-gray-200 mb-4"></div>
                {props.children}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  )
}

export default AccordionCheckbox
