import { useAccount, useMsal } from "@azure/msal-react"
import React, { useState } from "react"
import { useQuery } from "react-query"
import { fetchApi } from "../../../Utils/Api.utils"
import { ExpenseReport } from "../../../Utils/Entities"
import LoadingData from "../../common/graphichs/LoadingData"

const ExpenseReportDetails = props => {
  const report: ExpenseReport = props.report

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6 bg-meny-700">
        <h3 className="text-lg leading-6 font-medium text-white">
          Beskrivelse
        </h3>
      </div>
      <div>
        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-600">Formål</dt>
              <dd className="mt-1 text-sm text-gray-900">{report?.name}</dd>
            </div>

            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-600">Sum</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {report?.sumAmount}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-600">
                Prosjektnummer
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {report?.project?.projectNo}
              </dd>
            </div>
            <div className="sm:col-span-2">
              <dt className="text-sm font-medium text-gray-600">
                Ekstra opplysninger
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {report?.description}
              </dd>
            </div>
          </dl>
        </div>
      </div>
      <div className="px-4 py-5 sm:px-6 ">
        <h3 className="text-lg leading-6 font-medium ">Detaljer</h3>
      </div>
    </div>
  )
}

export default ExpenseReportDetails
