import { useAccount, useMsal } from "@azure/msal-react"
import { ErrorMessage } from "@hookform/error-message"
import React, { useState } from "react"
import ReactDatePicker from "react-datepicker"
import { useForm } from "react-hook-form"
import { toast } from "react-toastify"
import HelperText from "../../../common/text/HelperText"

const Personal = ({
  program,
  mutationCreateAcitivity,
  setComment,
  setIncidentTitle,
  setShowLoadingData,
  showActivityButtons,
}) => {
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const [activityComment, setActivityComment] = useState("")

  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)

  const {
    register,
    handleSubmit,
    reset,
    control,
    setError,
    formState: { errors },
  } = useForm()

  const postActivity = async (data, e) => {
    setIncidentTitle("Godkjenning av egenaktivitet for spesialistutdanning")
    data.activityType = 292460007 // Egenaktivitet
    data.approveStatus = 778380003 //Påbegynt
    data.specialistProgramId = program?.id
    data.fromDate = startDate
    data.toDate = endDate
    data.comment = activityComment

    // replace , with .
    data.amountHours = data.amountHours.replace(",", ".")

    if (startDate == null || endDate == null) {
      toast.warning("Vennligst velg Fra og Til dato!", {
        position: "top-center",
        autoClose: 10000,
      })

      return
    } else {
      if (startDate > endDate) {
        toast.warning("Fra dato kan ikke være etter til dato!", {
          position: "top-center",
          autoClose: 10000,
        })

        return
      }
    }

    setShowLoadingData(true)
    mutationCreateAcitivity.mutate(data)
  }

  return (
    <div>
      <form method="POST" onSubmit={handleSubmit(postActivity)}>
        <div className="grid grid-cols-6 gap-6">
          <div className="col-span-6 sm:col-span-3">
            <label
              htmlFor="first-name"
              className="block text-sm font-medium text-gray-700"
            >
              Fra dato*
            </label>
            <ReactDatePicker
              id="startDate"
              locale="nb"
              showYearDropdown
              selected={startDate}
              onChange={(date: Date) => setStartDate(date)}
              dateFormat="dd.MM.yyyy"
              className="block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
            />
          </div>

          <div className="col-span-6 sm:col-span-3">
            <label
              htmlFor="last-name"
              className="block text-sm font-medium text-gray-700"
            >
              Til dato*
            </label>
            <ReactDatePicker
              id="endDate"
              locale="nb"
              showYearDropdown
              selected={endDate}
              onChange={(date: Date) => setEndDate(date)}
              dateFormat="dd.MM.yyyy"
              className="block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
            />
          </div>

          <div className="col-span-6 sm:col-span-3">
            <label
              htmlFor="amountHours"
              className="block text-sm font-medium text-gray-700"
            >
              Antall timer*
            </label>
            <input
              {...register("amountHours", {
                required: {
                  value: true,
                  message: "Obligatorisk felt.",
                },
                pattern: {
                  value: /^\d{1,}([\.\,]\d{0,1})?$/,
                  message: "Kun desimaltall med en desimal er tillatt.",
                },
              })}
              type="text"
              name="amountHours"
              id="amountHours"
              className="mt-1 focus:ring-meny-700 focus:border-meny-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            />
            <ErrorMessage
              errors={errors}
              name="amountHours"
              render={({ message }) => (
                <p className="text-sm text-red-600">{message}</p>
              )}
            />
          </div>

          <div className="col-span-6">
            <label
              htmlFor="comment"
              className="block text-sm font-medium text-gray-700"
            >
              Kommentar
            </label>
            <div className="mt-1">
              <textarea
                id="comment"
                {...register("comment", {
                  required: false,
                  maxLength: {
                    value: 2000,
                    message: "Maks antall tegn er 2000",
                  },
                })}
                onChange={e => {
                  setComment(e.target.value)
                  setActivityComment(e.target.value)
                }}
                rows={3}
                className="shadow-sm focus:ring-meny-700 focus:border-meny-500 block w-full sm:text-sm border border-gray-300 rounded-md"
                placeholder="Kommentar"
                defaultValue={""}
              />
              <ErrorMessage
                errors={errors}
                name="comment"
                render={({ message }) => (
                  <p className="text-sm text-red-600">{message}</p>
                )}
              />
            </div>
            <HelperText helpertext="Kort beskrivelse av aktiviteten." />
          </div>
        </div>
        {showActivityButtons ? (
          <div className="mt-8 border-t flex justify-end">
            <button
              type="submit"
              className="ml-3 mt-6 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-meny-600 hover:bg-meny-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
            >
              Last opp dokumenter
            </button>
          </div>
        ) : null}
      </form>
    </div>
  )
}

export default Personal
