import { InteractionType } from "@azure/msal-browser"
import { MsalAuthenticationTemplate } from "@azure/msal-react"
import { Link, navigate } from "gatsby"
import React from "react"
import { Loading } from "../../../../Utils/Loading"
import { ErrorComponent } from "../../../common/graphichs/ErrorComponent"
import MyPageLayout2 from "../../../common/layouts/myPageLayout2"
import HeaderText from "../../../common/text/Header"
import { loginRequest } from "../../../security/authConfig"
import ReducedContingent from "./ReducedContingent"

const RulesApplicationContent = () => {
  return (
    <MyPageLayout2
      props={"Retningslinjer for nedsettelse og betaling av kontingent"}
    >
      {/* <HeaderText text="Retningslinjer for nedsettelse og betaling av kontingent"/> */}

      <div id="container1">
        <div id="hTags">
          <h1>
            (Retningslinjer gjeldende fra 01.01.04 vedtatt av Sentralstyret
            (SST) 08.05.03, se SST-sak 24/03, med endring 27.01.15, se AUsak
            11/15, med endring 12.03.15 se AU-sak 16/15, med endring 03.12.15 se
            SSTsak 71/15)
          </h1>
        </div>

        <br />
        <ul>
          <h2>Innbetaling av kontingent</h2>
          <li>
            1&nbsp;&bull;&nbsp; Betaling av kontingent skjer enten i sin helhet
            ved hovedforfall, eller ved andre avtalte betalingsmåter.
          </li>
          <li>
            2&nbsp;&bull;&nbsp; Studenter betaler 10% av full kontingent. Ved
            utmelding refunderes ikke utfakturert kontingent.
          </li>
          <li>
            3&nbsp;&bull;&nbsp; Uteksaminerte psykologer betaler full kontingent
            fra 1. januar eller 1. juli, avhengig av vitnemålets dato.
          </li>
        </ul>
        <br />

        <div>
          <HeaderText text="Kriterier for nedsatt kontingent" />
          <br />
          <h2>
            Medlemmer som har sterkt redusert inntekt mer enn 6 måneder i et
            kalenderår, kan fremsette dokumentert søknad om redusert kontingent:
          </h2>
          <br />
          <ul>
            <li>
              1&nbsp;&bull;&nbsp; Delvis yrkesaktive pensjonister eller ikke
              yrkesaktive pensjonister kan søke om varig nedsatt kontingent på
              20 % av full kontingent.
            </li>
            <li>
              2&nbsp;&bull;&nbsp; Medlemmer som oppholder seg i utlandet, og som
              er uten norsk arbeidsgiver, betaler 20 % av full kontingent.
              Medlemmet plikter å informere ved flytting til hjemlandet og
              dersom de får norsk arbeidsgiver.
            </li>
            <li>
              3&nbsp;&bull;&nbsp; Medlemmer som blir 100% varig uføre betaler,
              etter at dokumentert søknad er fremsatt, 20% av full kontingent.
            </li>
            <li>
              4&nbsp;&bull;&nbsp; Medlemmer som er ansatt i stipendiatstillinger
              betaler, etter at søknad med kopi av arbeidskontrakt er fremsatt,
              50 % av full kontingent. Nedsettelsen gjelder for hele
              stipendiatperioden, slik det fremkommer av arbeidskontrakten. Dog
              gjelder kravet om redusert inntekt mer enn 6 måneder i et
              kalenderår.
            </li>
            <li>
              5&nbsp;&bull;&nbsp; Medlemmer som har annen sterkt redusert
              inntekt, kan fremsette dokumentert søknad om redusert kontingent.
              Innvilges for ett år av gangen.
            </li>
          </ul>
        </div>
        <br />
        <div id="saksbehandling">
          <HeaderText text="Saksbehandlingsprosess" />
          <br />
          <ul>
            <li>
              1&nbsp;&bull;&nbsp; Søknadsfrist for inneværende kalenderår er 1.
              desember.
            </li>
            <li>
              2&nbsp;&bull;&nbsp; Søknaden må inneholde dokumentert redusert
              arbeidsinntekt.
            </li>
            <li>
              3&nbsp;&bull;&nbsp; Generalsekretær eller den generalsekretæren
              delegerer oppgaven til godkjenner søknader om nedsettelse av
              kontingent.
            </li>
            <li>
              4&nbsp;&bull;&nbsp; Medlemmer med midlertidig redusert inntekt må
              fremlegge søknad årlig.
            </li>
            <li>
              5&nbsp;&bull;&nbsp; Når nedsatt kontingent er innvilget eller
              avslått registreres dette i medlemsregisteret og søknadspapirene
              slettes deretter.
            </li>
          </ul>
        </div>
        <br />
        <div>
          <HeaderText text="Regler for nedsettelse og betaling av kontingent  fra 4.12.2015."/>
        </div>
      </div>
    </MyPageLayout2>
  )
}

const RulesApplication = () => {
  const authRequest = {
    ...loginRequest,
  }
  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      <RulesApplicationContent />
    </MsalAuthenticationTemplate>
  )
}

export default RulesApplication
