import { Link } from "gatsby"
import React, { useEffect, useState } from "react"
import { DateTimeToDate, DateTimeToDateTime } from "../../../Utils/DateFormater"
import Accordion from "../../common/accordion/Accordion"
import { TextWithIcon } from "../../common/text"
import { EventType } from "./EventType"
import { CityOptionsetValueToString } from "./helpers/Event"

const EventAccoridon = (props: EventType) => {
  const [accordionSubtitle, setAccordionSubtitle] = useState("")

  useEffect(() => {
    const startDate = DateTimeToDate(props.startDate)
    const endDate = DateTimeToDate(props.endDate)

    setAccordionSubtitle(
      startDate === endDate ? startDate : startDate + " - " + endDate
    )
  }, [props.startDate, props.endDate])

  return (
    <Accordion title={props.eventName} subTitle={accordionSubtitle}>
      <div>
        <div
          dangerouslySetInnerHTML={{
            __html:
              props.customFields?.calendarContent ||
              "ingen beskrivelse tilgjengelig",
          }}
        ></div>
        <h4 className="mb-1 pt-6">Detaljer</h4>
        <div className="w-full h-px bg-gray-200 mb-4"></div>
        <div className="flex w-full pb-4">
          <div className="w-full pl-4 pt-4 space-y-4">
            {props.customFields?.projectno && (
              <TextWithIcon
                text={"Prosjektnummer : " + props.customFields?.projectno}
              />
            )}

            <TextWithIcon
              text={"Start Dato: " + DateTimeToDateTime(props.startDate)}
            />
            <TextWithIcon
              text={"Slutt Dato: " + DateTimeToDateTime(props.endDate)}
            />
            {props.customFields?.stopWebsiteRegistrationson && (
              <TextWithIcon
                text={
                  "Påmeldingsfrist: " +
                  DateTimeToDateTime(
                    props.customFields?.stopWebsiteRegistrationson
                  )
                }
              />
            )}

            {props.customFields?.stopWebsiteCancellationon && (
              <TextWithIcon
                text={
                  "Avmeldingsfrist: " +
                  DateTimeToDateTime(
                    props.customFields?.stopWebsiteCancellationon
                  )
                }
              />
            )}
          </div>
          <div className="w-full pl-4 pt-4 space-y-4">
            <TextWithIcon
              text={"Kurstype: " + (props.customFields?.eventType?.name || "")}
            />
            <TextWithIcon
              text={
                "By: " +
                CityOptionsetValueToString(props.customFields?.city?.value)
              }
            />
            {/* <TextWithIcon
              text={"Sted: " + (props.building?.addressComposite || "")}
            /> */}
            <TextWithIcon
              text={"Total timer: " + (props.customFields?.amounthours || "")}
            />
          </div>
        </div>

        <div className="flex flex-row py-6">
          <Link
            to={"/app/kurs?id=" + props.readableEventId}
            // state={{ workId: work?.id }}
            className="ml-1 inline-flex justify-end py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-md text-white bg-meny-700 hover:bg-meny-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
          >
            Les mer
          </Link>
        </div>
      </div>
    </Accordion>
  )
}

export default EventAccoridon
