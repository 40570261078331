import React from "react"
import { AccordionControl } from "../../common/accordion/AccordionType"
import { SessionType } from "./EventType"
import SessionAccoridonCheckbox from "./SessionAccordionCheckbox"

const SessionAccordionCheckboxGroup = (props: {
  session: Array<SessionType>
  control: AccordionControl
  selectedSessions: Array<string>
}) => {
  return (
    <div className="max-w-3xl mx-auto divide-y divide-gray-200 rounded-lg overflow-hidden">
      {props.session.map(item => (
        <SessionAccoridonCheckbox
          defaultChecked={props.selectedSessions.includes(item.id)}
          key={item.id}
          session={item}
          control={{
            id: item.id,
            add: props.control.add,
            remove: props.control.remove,
          }}
        ></SessionAccoridonCheckbox>
      ))}
    </div>
  )
}

export default SessionAccordionCheckboxGroup
