import { useQuery } from "react-query"
import { fetchApi } from "../../../Utils/Api.utils"
import { useAccount, useMsal } from "@azure/msal-react"

function useGetEmployers() {
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const bedrift = useQuery("employers", async () =>
    fetchApi(
      `${process.env.GATSBY_API_URL}/Accounts/Employers`,
      account,
      inProgress,
      instance
    ).then(res => res.data)
  )

  return bedrift
}

export default useGetEmployers
