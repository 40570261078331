import React, { useEffect, useState } from "react"
import { DateTimeToNorwegian } from "../../../../Utils/DateFormater"
import { RemoveUnderScore } from "../../../../Utils/FormatHelper"
import { maintenanceActivityTypeTypes } from "../../../../Utils/optionSet"

const PersonalActivityDetail = ({
  activity,
  program,
  documents,
  mutationGetDocuments,
}) => {
  return (
    <div>
      <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          {/* <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-600">Navn</dt>
            <dd className="mt-1 text-sm text-gray-900">{activity.name}</dd>
          </div> */}
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-600">Status</dt>
            {/* Godkjent */}
            {activity.approveStatus === 778380000 ? (
              <dd className="mt-1">
                <span className="inline-flex rounded-full bg-green-300 px-2 text-xs font-semibold leading-5 text-black">
                  {RemoveUnderScore(activity.approveStatusName)}
                </span>
              </dd>
            ) : null}
            {/* Til godkjenning */}
            {activity.approveStatus === 778380001 ? (
              <dd className="mt-1">
                <span className="inline-flex rounded-full bg-yellow-300 px-2 text-xs font-semibold leading-5 text-black">
                  {RemoveUnderScore(activity.approveStatusName)}
                </span>
              </dd>
            ) : null}
            {/* Avslag */}
            {activity.approveStatus === 778380002 ? (
              <dd className="mt-1 ">
                <span className="inline-flex rounded-full bg-red-300 px-2 text-xs font-semibold leading-5 text-black">
                  {RemoveUnderScore(activity.approveStatusName)}
                </span>
              </dd>
            ) : null}
            {/* Påbegynt */}
            {activity.approveStatus === 778380003 ? (
              <dd className="mt-1">
                <span className="inline-flex rounded-full bg-blue-300 px-2 text-xs font-semibold leading-5 text-black">
                  {RemoveUnderScore(activity.approveStatusName)}
                </span>
              </dd>
            ) : null}
            {/* Ikke fullført registrering */}
            {activity.approveStatus === 292460000 ? (
              <dd className="mt-1">
                <span className="inline-flex rounded-full bg-orange-300 px-2 text-xs font-semibold leading-5 text-black">
                  {RemoveUnderScore(activity.approveStatusName)}
                </span>
              </dd>
            ) : null}
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-600">Programnavn</dt>
            <dd className="mt-1 text-sm text-gray-900">
              {program.programType?.name}
            </dd>
          </div>

          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-600">Antall timer</dt>
            <dd className="mt-1 text-sm text-gray-900">
              {activity.amountHours}
            </dd>
          </div>

          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-600">Fra dato</dt>
            <dd className="mt-1 text-sm text-gray-900">
              {DateTimeToNorwegian(activity.fromDate)}
            </dd>
          </div>

          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-600">Til dato</dt>
            <dd className="mt-1 text-sm text-gray-900">
              {DateTimeToNorwegian(activity.toDate)}
            </dd>
          </div>

          <div className="sm:col-span-2">
            <dt className="text-sm font-medium text-gray-600">Kommentar</dt>
            <dd className="mt-1 text-sm text-gray-900">{activity.comment}</dd>
          </div>
        </dl>
      </div>
    </div>
  )
}

export default PersonalActivityDetail
