import React, { useContext, useState } from "react"
import { DropDown } from "../../../common/dropDown"
import {
  CompanyPayerFields,
  CompanyPayerType,
  OtherPayerFields,
  OtherPayerType,
  WorkplaceType,
} from "../EventType"
import { InnmeldingContext } from "./dataStorage"

const Betaling = () => {
  const InnmeldingData = useContext(InnmeldingContext)
  if (!InnmeldingData) return <div></div>

  const [payer, setPayer] = useState<string>(InnmeldingData.payerType[0])

  const payerType = [
    { id: "Privat", label: "Privat" },
    { id: "Arbeidsgiver", label: "Arbeidsgiver" },
    { id: "Annen", label: "Annen" },
  ]

  const setSelected = value => {
    setPayer(value)
    InnmeldingData.payerType[1](value)
  }
  // TODO legg til PO og kontak person i arbeidsgiver
  return (
    <div className="my-6">
      <h2>Betaling</h2>
      <div className="w-full h-px bg-gray-200 mb-4"></div>
      <div className="grid grid-cols-1 md:grid-cols-2 my-6">
        <label className="block text-gray-700 mr-4">Hvem skal betale:</label>
        <div className="relative z-10">
          <DropDown
            options={payerType}
            setParentValue={setSelected}
            parentValue={payer}
          />
        </div>
      </div>
      {payer === "Privat" && <PrivatePayer />}
      {payer === "Arbeidsgiver" && (
        <CompanyPayer workplace={InnmeldingData.workplace} />
      )}
      {payer === "Annen" && <OtherPayer />}
    </div>
  )
}

const PrivatePayer = () => {
  return (
    <div className="py-8">
      <p>Du vil motta en faktura på mail før kurset</p>
    </div>
  )
}
const CompanyPayer = (props: { workplace?: WorkplaceType }) => {
  const InnmeldingData = useContext(InnmeldingContext)
  if (!InnmeldingData) return <div></div>

  const onChange = (id: CompanyPayerFields, e: any) => {
    const newData: CompanyPayerType = { id: id, payload: e.target.value }

    if (InnmeldingData.companyPayerData[0]?.find(item => item.id === id)) {
      // update existing
      InnmeldingData.companyPayerData[1](prev =>
        prev?.map(item => (item.id === id ? newData : item))
      )
    } else {
      // append new
      InnmeldingData.companyPayerData[1](prev => [...prev, newData])
    }
  }

  const labels: Array<lableDataType> = [
    { displayName: "Fakturaref. 1 / PO:*", dataName: "invoiceRef1" },
    { displayName: "Fakturaref. 2/ Kontaktperson:", dataName: "invoiceRef2" },
  ]

  return (
    <div className="space-y-4">
      <div className="grid grid-cols-1 md:grid-cols-2 my-6">
        <label className="block text-gray-700 mr-4">Din arbeidsgiver:</label>
        <div className="relative">
          {props.workplace?.name || (
            <p className="text-red-700 font-semibold">
              legg til arbeidsgiver på profilen din
            </p>
          )}
        </div>
      </div>

      {labels.map(label => (
        <LableDataComp
          key={label.dataName}
          {...label}
          // @ts-ignore
          onChange={onChange}
          defaultValue={
            InnmeldingData.companyPayerData[0].find(
              item => item.id === label.dataName
            )?.payload
          }
        />
      ))}
    </div>
  )
}
const OtherPayer = () => {
  const InnmeldingData = useContext(InnmeldingContext)
  if (!InnmeldingData) return <div></div>

  const onChange = (id: OtherPayerFields, e: any) => {
    const newData: OtherPayerType = { id: id, payload: e.target.value }

    if (InnmeldingData.otherPayerData[0]?.find(item => item.id === id)) {
      // update existing
      InnmeldingData.otherPayerData[1](prev =>
        prev?.map(item => (item.id === id ? newData : item))
      )
    } else {
      // append new
      InnmeldingData.otherPayerData[1](prev => [...prev, newData])
    }
  }

  const labels: Array<lableDataType> = [
    { displayName: "Navn:*", dataName: "name" },
    { displayName: "Org.nr:*", dataName: "orgNr" },
    { displayName: "Fakturaadr:*", dataName: "invoiceAdr" },
    { displayName: "Postnummer:", dataName: "zipCode" },
    { displayName: "Fakturaref. 1 / PO:*", dataName: "invoiceRef1" },
    { displayName: "Fakturaref. 2/ Kontaktperson:", dataName: "invoiceRef2" },
  ]

  return (
    <div className="space-y-4">
      {labels.map(label => (
        <LableDataComp
          key={label.dataName}
          {...label}
          onChange={onChange}
          defaultValue={
            InnmeldingData.otherPayerData[0].find(
              item => item.id === label.dataName
            )?.payload
          }
        />
      ))}
    </div>
  )
}

type lableDataType = {
  displayName?: string
  defaultValue?: string
  dataName: OtherPayerFields | CompanyPayerFields
  onChange?: (id: OtherPayerFields | CompanyPayerFields, e: any) => void
}

const LableDataComp = (props: lableDataType) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2">
      <label className="block text-gray-700 mr-4">{props.displayName}</label>
      <div className="relative">
        <input
          defaultValue={props.defaultValue}
          type="text"
          className="w-full"
          onChange={e => props.onChange!(props.dataName, e)}
        />
      </div>
    </div>
  )
}

export default Betaling
