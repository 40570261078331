import { InteractionType } from "@azure/msal-browser"
import {
  MsalAuthenticationTemplate,
  useAccount,
  useMsal,
} from "@azure/msal-react"
import { ErrorMessage } from "@hookform/error-message"
import { Link, navigate } from "gatsby"
import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useMutation, useQueryClient } from "react-query"
import { toast } from "react-toastify"
import { whiteButton } from "../../../../styles/tailwindClasses"
import { patchApi } from "../../../../Utils/Api.utils"
import { Loading } from "../../../../Utils/Loading"
import { speakerResponse } from "../../../../Utils/optionSet"
import { ErrorComponent } from "../../../common/graphichs/ErrorComponent"
import LoadingData from "../../../common/graphichs/LoadingData"
import MyPageLayout from "../../../common/layouts/myPageLayout"
import { SpeakerRequest } from "../../../mypagecomponents/event/EventType"
import { loginRequest } from "../../../security/authConfig"

const SpeakerRequestResponseContent = props => {
  const queryClient = useQueryClient()
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const request: SpeakerRequest = props.request
  const [response, setResponse] = useState<string>("0")
  const [showLoadingData, setShowLoadingData] = useState<boolean>(false)
  const {
    register,
    handleSubmit,
    reset,
    control,
    setError,
    formState: { errors },
  } = useForm()

  useEffect(() => {
    if (request.response !== undefined) {
      setResponse(request.response.toString())
    }
  }, [props.request])

  const mutationUpdateRequest = useMutation(
    data => {
      return patchApi(
        process.env.GATSBY_API_URL + "/SpeakerRequests",
        data,
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["speakerRequests"])
        setTimeout(() => {
          navigate("/app/kurs/administrasjon")
        }, 3000)
      },
      onError: _error => {
        setShowLoadingData(false)
      },
      onSettled: () => {},
    }
  )

  const patchRequest = data => {
    if (response === "0") {
      toast.warning("Vennligst fyll ut alle obligatoriske felter!", {
        position: "top-center",
        autoClose: 10000,
      })
      return
    }
    const update: SpeakerRequest = {}
    update.id = request.id
    update.response = parseInt(response)
    update.comment = data.comment
    console.log(update)
    setShowLoadingData(true)
    mutationUpdateRequest.mutate(update)
  }

  return (
    <div>
      <div>{showLoadingData && <LoadingData subject="Oppdaterer..." />}</div>
      <MyPageLayout props="Svar på bidragsyterforespørsel">
        <Link
          to="/app/kurs/administrasjon"
          className={
            whiteButton +
            " inline-flex items-center justify-center focus:ring-offset-2 sm:w-auto "
          }
          activeClassName="active-link"
        >
          Tilbake
        </Link>

        <div className="space-y-6 mt-8">
          {/* {selectedActivityType === "292460005" ? ( */}
          {/* <VisualStep steps={steps} /> */}
          {/* ) : null} */}
          <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
            <div className="mt-5 md:mt-0 md:col-span-2">
              <div className="sm:grid sm:grid-cols-2 gap-3 mb-4">
                <p className="text-base text-gray-600 sm:col-span-1">
                  Arrangement: {request?.event?.name}
                </p>
                {request?.sessionId !== undefined ? (
                  <p className="text-base text-gray-600 sm:col-span-1">
                    Kurs: {request?.session?.name}
                  </p>
                ) : null}
                <p className="text-base text-gray-600 sm:col-span-1">
                  Bidragsyter: {request?.speaker?.name}
                </p>
              </div>
              <div>
                <form method="PATCH" onSubmit={handleSubmit(patchRequest)}>
                  {/* <p className="text-sm leading-5 text-gray-600">Her kommer en hjelpe tekst.</p> */}
                  <div className="col-span-6 mb-4">
                    <p
                      // htmlFor="employerAlternative"
                      className="block text-sm italic m-2 font-normal sm:col-span-2 text-meny-700 sm:mt-px sm:pt-2 sm:col-start-3"
                    ></p>
                    <div>
                      <label className="text-base font-medium text-gray-900">
                        Svar*
                      </label>

                      <fieldset className="mt-4">
                        <legend className="sr-only">Notification method</legend>
                        <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                          {speakerResponse.map(resp => (
                            <>
                              {resp.title != "Ingen" ? (
                                <div
                                  key={resp.id}
                                  className="flex items-center"
                                >
                                  <input
                                    id={resp.id}
                                    name="notification-method"
                                    type="radio"
                                    onChange={e => setResponse(e.target.id)}
                                    defaultChecked={
                                      (request.response !== undefined &&
                                        resp.id ===
                                          request.response.toString()) ||
                                      resp.id === "0"
                                    }
                                    className="focus:ring-meny-700 h-4 w-4 text-indigo-600 border-gray-300"
                                  />
                                  <label
                                    htmlFor={resp.id}
                                    className="ml-3 block text-sm font-medium text-gray-700"
                                  >
                                    {resp.title}
                                  </label>
                                </div>
                              ) : null}
                            </>
                          ))}
                        </div>
                      </fieldset>
                    </div>

                    <div className="col-span-6 mt-4">
                      <label
                        htmlFor="comment"
                        className="block text-base font-medium text-gray-700"
                      >
                        Kommentar*
                      </label>
                      <div className="mt-1">
                        <textarea
                          id="comment"
                          {...register("comment", {
                            required: true,
                            // maxLength: 2000,
                          })}
                          rows={3}
                          defaultValue={request.comment}
                          className="shadow-sm focus:ring-meny-700 focus:border-meny-500 block w-full sm:text-sm border border-gray-300 rounded-md"
                          placeholder="Kommentar"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="mt-8 border-t flex justify-end">
                    <button
                      type="submit"
                      className="ml-3 mt-6 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-meny-600 hover:bg-meny-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
                    >
                      Send inn
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}
      </MyPageLayout>
    </div>
  )
}

const SpeakerRequestResponse = ({ location }) => {
  const authRequest = {
    ...loginRequest,
  }

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      <SpeakerRequestResponseContent request={location.state.request} />
    </MsalAuthenticationTemplate>
  )
}

export default SpeakerRequestResponse
