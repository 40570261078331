import { useAccount, useMsal } from "@azure/msal-react"
import { ErrorMessage } from "@hookform/error-message"
import React, { useState } from "react"
import ReactDatePicker from "react-datepicker"
import { useForm } from "react-hook-form"
import { toast } from "react-toastify"
import { Activity } from "../../../../Utils/Types"
import HelperText from "../../../common/text/HelperText"
import { useQueryClient } from "react-query"
import {
  TypePracticeConsultant,
  consultingsCyclePhase,
} from "../../../../Utils/optionSet"

const ConsultantEdit = props => {
  const activity: Activity = props.activity
  const queryClient = useQueryClient()
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const [activityComment, setActivityComment] = useState(activity?.comment)
  const [startDate, setStartDate] = useState(null)
  const [selectedPracticeType, SetSelectedPracticeType] = useState(
    activity.typePracticeConsulting.toString()
  )
  const [cycleChange, setCycleChange] = useState<string>(
    activity.consultingsCyclePhase.toString()
  )
  const [textFieldRequired, setTextFieldRequired] = useState<boolean>(false)

  const {
    register,
    handleSubmit,
    reset,
    control,
    setError,
    formState: { errors },
  } = useForm()

  const patchActivity = async (data, e) => {
    data.id = activity.id
    data.comment = activityComment

    if (selectedPracticeType === "0" || cycleChange === "") {
      toast.warning("Obligatoriske felt mangler!", {
        position: "top-center",
        autoClose: 10000,
      })
      return
    }

    if (selectedPracticeType !== "292460008") {
      data.maintenanceActivityText = null
    }

    data.typePracticeConsulting = selectedPracticeType
    data.consultingsCyclePhase = cycleChange

    props.setShowLoadingData(true)
    props.mutationUpdateActivity.mutate(data)
  }

  const changePracticeType = e => {
    SetSelectedPracticeType(e.target.value)
    if (e.target.value === "292460008") {
      setTextFieldRequired(true)
    } else {
      setTextFieldRequired(false)
    }
  }

  return (
    <div>
      <form method="POST" onSubmit={handleSubmit(patchActivity)}>
        <div className="grid grid-cols-6 gap-6">
          <div className="col-span-6 sm:col-span-3">
            <label
              htmlFor="supervisorNo"
              className="block text-sm font-medium text-gray-700"
            >
              Veileders HPRno*
            </label>
            <input
              {...register("supervisorNo", {
                required: {
                  value: true,
                  message: "Obligatorisk felt.",
                },
                pattern: {
                  value: /^[0-9]+$/,
                  message: "Kun tall er tillatt",
                },
                minLength: {
                  value: 7,
                  message: "HPR nr kan kun være mellom 7 og 9 siffer",
                },
                maxLength: {
                  value: 9,
                  message: "HPR nr kan kun være mellom 7 og 9 siffer",
                },
              })}
              required
              type="text"
              name="supervisorNo"
              id="supervisorNo"
              defaultValue={activity.supervisorNo}
              className="mt-1 focus:ring-meny-700 focus:border-meny-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            />
            <ErrorMessage
              errors={errors}
              name="supervisorNo"
              render={({ message }) => (
                <p className="text-sm text-red-600">{message}</p>
              )}
            />
          </div>

          <div className="col-span-6 sm:col-span-3">
            <label
              htmlFor="supervisorText"
              className="block text-sm font-medium text-gray-700"
            >
              Veileders navn*
            </label>
            <input
              {...register("supervisorText", {
                required: { value: true, message: "Obligatorisk felt" },
                maxLength: { value: 100, message: "Maks tegn er 100" },
              })}
              required
              type="text"
              name="supervisorText"
              id="supervisorText"
              defaultValue={activity.supervisorText}
              className="mt-1 focus:ring-meny-700 focus:border-meny-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            />
            <ErrorMessage
              errors={errors}
              name="supervisorText"
              render={({ message }) => (
                <p className="text-sm text-red-600">{message}</p>
              )}
            />
          </div>

          <div className="col-span-6 ">
            <label
              htmlFor="courseProviderAccountId"
              className="block text-sm font-medium text-gray-700"
            >
              Praksistype*
            </label>
            <select
              id="courseProviderAccountId"
              name="courseProviderAccountId"
              onChange={changePracticeType}
              defaultValue={activity.typePracticeConsulting.toString()}
              value={selectedPracticeType}
              className=" mt-1 block w-full py-2 pl-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-meny-700 focus:border-meny-500 sm:text-sm"
            >
              <option disabled selected value="0">
                --velg--
              </option>

              {TypePracticeConsultant?.map(a => (
                <option key={a.id} value={a.id}>
                  {a.name}
                </option>
              ))}
            </select>
          </div>

          {selectedPracticeType === "292460008" ? (
            <div className="col-span-6">
              <label
                htmlFor="comment"
                className="block text-sm font-medium text-gray-700"
              >
                Annen type aktivitet (Spesifiser her)
              </label>
              <div className="mt-1">
                <textarea
                  id="maintenanceActivityText"
                  {...register("maintenanceActivityText", {
                    required: {
                      value: textFieldRequired,
                      message: "Obligatorisk felt",
                    },
                    maxLength: {
                      value: 100,
                      message: "Maks antall tegn er 100",
                    },
                  })}
                  rows={3}
                  className="shadow-sm focus:ring-meny-700 focus:border-meny-500 block w-full sm:text-sm border border-gray-300 rounded-md"
                  placeholder="Kommentar"
                  defaultValue={activity.maintenanceActivityText}
                />
                <ErrorMessage
                  errors={errors}
                  name="maintenanceActivityText"
                  render={({ message }) => (
                    <p className="text-sm text-red-600">{message}</p>
                  )}
                />
              </div>
            </div>
          ) : null}

          <div className="col-span-6 ">
            <p
              // htmlFor="employerAlternative"
              className="block text-sm italic m-2 font-normal sm:col-span-2 text-meny-700 sm:mt-px sm:pt-2 sm:col-start-3"
            ></p>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Er dette første (midtveis) eller andre (mot slutten av
                utdanningsperioden) konsulentsyklus?
              </label>
              {/* <p className="text-sm leading-5 text-gray-600">Her kommer en hjelpe tekst.</p> */}
              <fieldset className="mt-4">
                <legend className="sr-only">Notification method</legend>
                <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                  {consultingsCyclePhase.map(notificationMethod => (
                    <>
                      {notificationMethod.name != "Ingen" ? (
                        <div
                          key={notificationMethod.id}
                          className="flex items-center"
                        >
                          <input
                            id={notificationMethod.id}
                            name="notification-method"
                            type="radio"
                            onChange={e => setCycleChange(e.target.id)}
                            defaultChecked={
                              notificationMethod.id ===
                              activity.consultingsCyclePhase.toString()
                            }
                            className="focus:ring-meny-700 h-4 w-4 text-indigo-600 border-gray-300"
                          />
                          <label
                            htmlFor={notificationMethod.id}
                            className="ml-3 block text-sm font-medium text-gray-700"
                          >
                            {notificationMethod.name}
                          </label>
                        </div>
                      ) : null}
                    </>
                  ))}
                </div>
              </fieldset>
            </div>
          </div>

          <div className="col-span-6">
            <label
              htmlFor="comment"
              className="block text-sm font-medium text-gray-700"
            >
              Kommentar
            </label>
            <div className="mt-1">
              <textarea
                id="comment"
                {...register("comment", {
                  required: false,
                  maxLength: {
                    value: 2000,
                    message: "Maks antall tegn er 2000",
                  },
                })}
                onChange={e => {
                  setActivityComment(e.target.value)
                }}
                rows={3}
                className="shadow-sm focus:ring-meny-700 focus:border-meny-500 block w-full sm:text-sm border border-gray-300 rounded-md"
                placeholder="Kommentar"
                defaultValue={activity?.comment}
              />
              <ErrorMessage
                errors={errors}
                name="comment"
                render={({ message }) => (
                  <p className="text-sm text-red-600">{message}</p>
                )}
              />
            </div>
            <HelperText helpertext="Kort beskrivelse av aktiviteten." />
          </div>
        </div>

        <div className="mt-8 border-t flex justify-end">
          <button
            type="submit"
            className="ml-3 mt-6 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-meny-600 hover:bg-meny-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
          >
            Oppdater
          </button>
        </div>
      </form>
    </div>
  )
}

export default ConsultantEdit
