import { InteractionType } from "@azure/msal-browser"
import {
  MsalAuthenticationTemplate,
  useAccount,
  useMsal,
} from "@azure/msal-react"
import { ErrorMessage } from "@hookform/error-message"
import { navigate } from "gatsby"
import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { useQuery } from "react-query"
import { fetchApi, patchApi } from "../../../Utils/Api.utils"
import { Loading } from "../../../Utils/Loading"
import { ErrorComponent } from "../../common/graphichs/ErrorComponent"
import LoadingData from "../../common/graphichs/LoadingData"
import { classNames } from "../../common/graphichs/misc"
import MyPageLayout2 from "../../common/layouts/myPageLayout2"
import { Checkin } from "../../mypagecomponents/event/EventType"
import { loginRequest } from "../../security/authConfig"

const EditCheckinContent = props => {
  type FormValues = {
    id: string
    checkinStatus: number
    amountHours: number
    absenceDetails: string
    contactId: string
  }

  const [isLoading, setIsLoading] = useState<boolean>()
  const [defaultHours, setDefaultHours] = useState()

  const checkin = props.props
  console.log(checkin)

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormValues>()

  //   const [absent, setAbsent] = useState<boolean>(
  //     checkin?.checkinstatus === 778380001
  //   )

  const [mandatory, setIsMandatory] = useState<boolean>(
    checkin?.checkinStatus !== 778380000
  )

  console.log(checkin?.checkinStatus)

  const status = [
    { value: 778380000, name: "Oppmøtt" },
    { value: 778380001, name: "Delvis oppmøtt" },
  ]

  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})

  const sessionQuery = useQuery(
    "sessionInfo",
    async () =>
      fetchApi(
        process.env.GATSBY_API_URL +
          `/EventAdmin/singleSession/${checkin?.sessionId}`,
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      enabled: checkin?.sessionId != undefined,
      onSuccess: data => {
        setDefaultHours(data.amountHours)
      },
      onError: _error => {},
    }
  )

  const eventQuery = useQuery(
    "eventInfo",
    async () =>
      fetchApi(
        process.env.GATSBY_API_URL +
          `/EventAdmin/singleEvent/${checkin?.eventId}`,
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      enabled: checkin?.sessionId == undefined,
      onSuccess: data => {
        setDefaultHours(data.amountHours)
      },
      onError: _error => {},
    }
  )

  const editAbsence = e => {
    if (e.target.value == 778380000) {
      setIsMandatory(false)
    } else setIsMandatory(true)
  }
  // console.log(eventQuery?.data)
  const patchCheckin = data => {
    data.contactId = checkin?.contactId
    if (data.checkinStatus == 778380000) {
      data.amountHours = defaultHours

      // console.log(eventQuery?.data)

      data.absenceDetails = ""
    }
    setIsLoading(true)
    patchApi(
      `${process.env.GATSBY_API_URL}/EventAdmin/checkin/${checkin?.id}`,
      data,
      account,
      inProgress,
      instance
    )
      .then(response => {
        window.setTimeout(() => {
          navigate(-1)
        }, 2500)
      })
      .catch(error => {
        setIsLoading(false)
      })
  }
  console.log(mandatory)

  return isLoading ? (
    <LoadingData subject="Oppdaterer" />
  ) : (
    <MyPageLayout2 props="Rediger oppmøte">
      <form
        method="PATCH"
        onSubmit={handleSubmit(patchCheckin)}
        className="space-y-8 "
      >
        <div className="space-y-8 sm:space-y-5">
          <div className="space-y-6sm:space-y-5">
            <div className="space-y-6 sm:space-y-5">
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                <label
                  htmlFor="first-name"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Kontakt:
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    type="text"
                    readOnly={true}
                    id="firstName"
                    autoComplete="given-name"
                    defaultValue={
                      checkin?.contact?.firstName +
                      " " +
                      checkin?.contact?.lastName
                    }
                    className="block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md bg-gray-100"
                  />
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                <label
                  htmlFor="checkinstatus"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Innsjekkingsstatus*:
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <select
                    {...register("checkinStatus")}
                    required
                    name="checkinStatus"
                    id="checkinStatus"
                    key="checkinStatus"
                    onChange={editAbsence}
                    defaultValue={checkin?.checkinStatus}
                    className="block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                  >
                    <option key={status[0].value} value={status[0].value}>
                      {status[0].name}
                    </option>
                    <option key={status[1].value} value={status[1].value}>
                      {status[1].name}
                    </option>
                  </select>
                </div>
              </div>

              {mandatory ? (
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                  <label
                    htmlFor="amounthours"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Antall timer*:
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <input
                      id="amountHours"
                      {...register("amountHours", {
                        pattern: {
                          value: /^[0-9]+$/i,
                          message: "Kun tall er godkjent",
                        },
                        maxLength: {
                          value: 3,
                          message: "Høyeste tall tillatt er 999",
                        },
                      })}
                      required
                      name="amountHours"
                      type="text"
                      readOnly={!mandatory}
                      defaultValue={checkin?.amountHours}
                      className="block w-full shadow-sm focus:ring-meny-700 sm:text-sm focus:border-meny-500 border-gray-300 rounded-md"
                    />
                    <ErrorMessage
                      errors={errors}
                      name="amountHours"
                      render={({ message }) => (
                        <p className="text-sm text-red-600">{message}</p>
                      )}
                    />
                  </div>
                </div>
              ) : (
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                  <label
                    htmlFor="amounthours"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Antall timer:
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <input
                      // id="amountHours"
                      // {...register("amountHours", {
                      //   pattern: {
                      //     value: /^[0-9]+$/i,
                      //     message: "Kun tall er godkjent",
                      //   },
                      //   maxLength: {
                      //     value: 3,
                      //     message: "Høyeste tall tillatt er 999",
                      //   },
                      // })}
                      // required
                      name="amountHours"
                      type="text"
                      readOnly={!mandatory}
                      value={defaultHours}
                      // defaultValue={checkin?.amountHours}
                      className="block bg-gray-100 w-full shadow-sm focus:ring-meny-700 sm:text-sm focus:border-meny-500 border-gray-300 rounded-md"
                    />
                    <ErrorMessage
                      errors={errors}
                      name="amountHours"
                      render={({ message }) => (
                        <p className="text-sm text-red-600">{message}</p>
                      )}
                    />
                  </div>
                </div>
              )}

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                {mandatory ? (
                  <>
                    <label
                      htmlFor="absenceDetails"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Fraværsdetaljer*:
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <textarea
                        id="absenceDetails"
                        {...register("absenceDetails", {
                          maxLength: {
                            value: 420,
                            message: "Maks tegn er 420",
                          },
                        })}
                        required={mandatory}
                        readOnly={!mandatory}
                        name="absenceDetails"
                        defaultValue={checkin?.absenceDetails}
                        className="form-control h-20  block w-full text-base  px-3 py-1.5 shadow-sm focus:ring-meny-700 sm:text-sm focus:border-meny-500 border-gray-300 rounded-md transition ease-in-out m-0"
                      />
                      <ErrorMessage
                        errors={errors}
                        name="absenceDetails"
                        render={({ message }) => (
                          <p className="text-sm text-red-600">{message}</p>
                        )}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <label
                      htmlFor="absenceDetails"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Fraværsdetaljer:
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <textarea
                        required={mandatory}
                        readOnly={!mandatory}
                        name="absenceDetails"
                        value={""}
                        // defaultValue={checkin?.absenceDetails}
                        className="form-control h-20 bg-gray-100  block w-full text-base  px-3 py-1.5 shadow-sm focus:ring-meny-700 sm:text-sm focus:border-meny-500 border-gray-300 rounded-md transition ease-in-out m-0"
                      />
                      <ErrorMessage
                        errors={errors}
                        name="absenceDetails"
                        render={({ message }) => (
                          <p className="text-sm text-red-600">{message}</p>
                        )}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>

          <div className="pt-5 flex justify-end">
            <button
              type="button"
              onClick={() => navigate(-1)}
              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
            >
              Tilbake
            </button>
            <button
              type="submit"
              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-meny-700 hover:bg-meny-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
            >
              Lagre
            </button>
          </div>
        </div>
      </form>
    </MyPageLayout2>
  )
}

const EditCheckin = ({ location }) => {
  const authRequest = {
    ...loginRequest,
  }
  const checkin = location?.state?.checkin
  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      <EditCheckinContent props={checkin} />
    </MsalAuthenticationTemplate>
  )
}

export default EditCheckin
