import React, { useEffect, useState } from "react"
import { DropDown } from "../../../common/dropDown"
import { sortType } from "./FilterType"
import { SortAscendingIcon, SortDescendingIcon } from "@heroicons/react/outline"
import { CityOptionsetValueToString } from "../helpers/Event"

const SortBy = (props: sortType) => {
  const [sortReversed, setSortReversed] = useState<boolean>(false)

  const [eventSort, setEventSort] = useState([...props.events])

  const options: Array<{
    id: string
    label: string
  }> = [
    { id: "date", label: "Dato" },
    { id: "registrationDate", label: "Pmld.frist" },
    { id: "city", label: "By" },
    { id: "name", label: "Navn" },
    { id: "eventCategori", label: "Kurs type" },
  ]

  useEffect(() => {
    const sortFunc = async () => {
      const eventCopy = [...props.events]

      eventCopy.sort((a, b) => {
        if (props.sortOn === "date") {
          return (
            new Date(a.startDate || "").getTime() -
            new Date(b.startDate || "").getTime()
          )
        } else if (props.sortOn === "registrationDate") {
          return (
            new Date(
              a.customFields?.stopWebsiteRegistrationson || ""
            ).getTime() -
            new Date(b.customFields?.stopWebsiteRegistrationson || "").getTime()
          )
        } else if (props.sortOn === "city") {
          return CompareString(
            CityOptionsetValueToString(a.customFields?.city?.value),
            CityOptionsetValueToString(b.customFields?.city?.value)
          )
        } else if (props.sortOn === "name") {
          return CompareString(a.eventName || "", b.eventName || "")
        } else if (props.sortOn === "eventCategori") {
          return (a.eventType || 0) - (b.eventType || 0)
        }
        return 0
      })

      setEventSort(sortReversed ? [...eventCopy].reverse() : [...eventCopy])
    }

    sortFunc()
  }, [props.sortOn, sortReversed, props.resortTrigger])

  const onClick = () => {
    setSortReversed(!sortReversed)
  }

  useEffect(() => {
    props.setEvents(eventSort)
  }, [eventSort])

  return (
    <section className="flex justify-center mb-4">
      <div className="max-w-3xl w-full flex flex-row-reverse ">
        <div className="w-auto flex items-center relative">
          <label className="text-gray-700 w-full mr-2">Sorter etter:</label>
          <div className="min-w-fit z-10">
            <DropDown
              options={options}
              setParentValue={props.setSortOn}
              defaultParent={
                options.find(item => item.id === props.sortOn)?.label
              }
            ></DropDown>
          </div>
          <button
            onClick={onClick}
            className="hover:bg-gray-200 p-2 rounded mx-2"
          >
            {sortReversed ? (
              <SortAscendingIcon className="w-6 h-6" />
            ) : (
              <SortDescendingIcon className="w-6 h-6" />
            )}
          </button>
        </div>
      </div>
    </section>
  )
}

const CompareString = (a: string, b: string) => {
  return a.localeCompare(b, "no", { sensitivity: "base" })
}

export default SortBy
