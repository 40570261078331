import React from "react"
import Modal from "./Modal"
import { ModalWithHeaderType } from "./ModalType"

const ModalWithHeader = (props: ModalWithHeaderType) => {
  return (
    <Modal setModalOpen={props.setModalOpen} className={props.className}>
      <div>
        <h1 className="text-3xl font-bold text-meny-700">{props.header}</h1>
        <div className="w-full h-px bg-gray-200 mb-4"></div>
        {props.children}
      </div>
    </Modal>
  )
}

export default ModalWithHeader
