import { useAccount, useMsal } from "@azure/msal-react"
import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { fetchApi, postApi } from "../../../../Utils/Api.utils"
import ReactDatePicker from "react-datepicker"
import FileUpload from "../../document/FileUpload"
import { navigate } from "gatsby"
import { ErrorMessage } from "@hookform/error-message"
import { Switch } from "@headlessui/react"
import { classNames } from "../../../common/graphichs/misc"
import { maintenanceActivityTypeTypes } from "../../../../Utils/optionSet"
import HelperText from "../../../common/text/HelperText"

const GuidanceActivity = ({
  program,
  mutationCreateAcitivity,
  setComment,
  setIncidentTitle,
  setShowLoadingData,
  showActivityButtons,
}) => {
  const queryClient = useQueryClient()
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const [activityComment, setActivityComment] = useState("")
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [selectedMaintenanceActivity, SetSelectedMaintenanceActivity] =
    useState("0")
  const [selectedCourseProvider, SetSelectedCourseProvider] = useState("0")
  const [courseProviders, setCourseProviders] = useState<any[]>()
  const [alternativeCourceProvider, setAlternativeCourceProvider] =
    useState(false)
  const [altCourseProvider, SetAltCourseProvider] = useState("")
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm()

  useQuery(
    "courseProvidersData",
    () =>
      fetchApi(
        process.env.GATSBY_API_URL + "/Accounts/CourseProvider/all",
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: data => {
        setCourseProviders(data)
      },
      onError: _error => {},
    }
  )

  const OnChangeSupervisorSpeciality = e => SetSelectedMaintenanceActivity(e)

  const showAltCourceProvider = () => {
    setAlternativeCourceProvider(!alternativeCourceProvider)
    SetSelectedCourseProvider("0")
  }

  const OnChangeCourceProvider = e => {
    SetSelectedCourseProvider(e)
    setAlternativeCourceProvider(false)
  }

  const postActivity = async (data, e) => {
    setIncidentTitle(
      "Godkjenning av vedlikeholdsinformasjon for spesialistutdanning"
    )
    data.activityType = 292460006
    data.approveStatus = 292460000 //Ikke fullført registrering
    data.specialistProgramId = program?.id
    data.fromDate = startDate
    data.toDate = endDate
    data.comment = activityComment
    data.educationId = program.specialistEducation?.id

    if (selectedMaintenanceActivity != "0")
      data.maintenanceActivityType = selectedMaintenanceActivity
    if (selectedCourseProvider != "0") {
      data.courseProviderAccountId = selectedCourseProvider
      data.courseProviderAccountName = ""
    }
    // if (selectedCourseProvider == "0")
    //   data.courseProviderAccountName = altCourseProvider

    setShowLoadingData(true)
    mutationCreateAcitivity.mutate(data)
  }

  return (
    <div>
      <form method="POST" onSubmit={handleSubmit(postActivity)}>
        <div className="grid grid-cols-6 gap-6">
          <div className="col-span-6 sm:col-span-3">
            <label
              htmlFor="first-name"
              className="block text-sm font-medium text-gray-700"
            >
              Fra dato
            </label>
            <ReactDatePicker
              id="startDate"
              locale="nb"
              showYearDropdown
              selected={startDate}
              onChange={(date: Date) => setStartDate(date)}
              dateFormat="dd.MM.yyyy"
              className="block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
            />
          </div>

          <div className="col-span-6 sm:col-span-3">
            <label
              htmlFor="last-name"
              className="block text-sm font-medium text-gray-700"
            >
              Til dato
            </label>
            <ReactDatePicker
              id="endDate"
              locale="nb"
              showYearDropdown
              selected={endDate}
              onChange={(date: Date) => setEndDate(date)}
              dateFormat="dd.MM.yyyy"
              className="block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
            />
          </div>

          <div className="col-span-6 sm:col-span-3">
            <label
              htmlFor="amountHours"
              className="block text-sm font-medium text-gray-700"
            >
              Antall timer*
            </label>
            <input
              {...register("amountHours", {
                required: {
                  value: true,
                  message: "Obligatorisk felt.",
                },
                pattern: {
                  value: /^[0-9]+$/,
                  message: "Kun tall er tillatt.",
                },
              })}
              type="text"
              name="amountHours"
              id="amountHours"
              className="mt-1 focus:ring-meny-700 focus:border-meny-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            />
            <ErrorMessage
              errors={errors}
              name="amountHours"
              render={({ message }) => (
                <p className="text-sm text-red-600">{message}</p>
              )}
            />
          </div>

          <div className="col-span-6 sm:col-span-3">
            <label
              htmlFor="courseProviderAccountId"
              className="block text-sm font-medium text-gray-700"
            >
              Vedlikeholdsaktivitet
            </label>
            <select
              id="courseProviderAccountId"
              name="courseProviderAccountId"
              onChange={e => OnChangeSupervisorSpeciality(e.target.value)}
              value={selectedMaintenanceActivity}
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-meny-700 focus:border-meny-500 sm:text-sm"
            >
              <option disabled selected value="0">
                --velg--
              </option>

              {maintenanceActivityTypeTypes?.map(a => (
                <option key={a.id} value={a.id}>
                  {a.name}
                </option>
              ))}
            </select>
          </div>

          <div className="col-span-6 sm:col-span-3">
            {selectedMaintenanceActivity == "292460000" ? (
              <>
                <label
                  htmlFor="courseProviderAccountId"
                  className="block text-sm font-medium text-gray-700"
                >
                  Arrangør
                </label>
                <select
                  id="courseProviderAccountId"
                  name="courseProviderAccountId"
                  onChange={e => OnChangeCourceProvider(e.target.value)}
                  value={selectedCourseProvider}
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-meny-700 focus:border-meny-500 sm:text-sm"
                >
                  <option disabled selected value="0">
                    --velg--
                  </option>

                  {courseProviders?.map(a => (
                    <option key={a.id} value={a.id}>
                      {a.name}
                    </option>
                  ))}
                </select>
              </>
            ) : null}
          </div>

          <div className="col-span-6 sm:col-span-3">
            {selectedMaintenanceActivity == "292460002" ? (
              <>
                <label
                  htmlFor="maintenanceActivityText"
                  className="block text-sm font-medium text-gray-700"
                >
                  Vedlikeholdsaktivitet annen
                </label>
                <input
                  {...register("maintenanceActivityText")}
                  type="text"
                  name="maintenanceActivityText"
                  id="maintenanceActivityText"
                  className="mt-1 focus:ring-meny-700 focus:border-meny-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
              </>
            ) : null}
          </div>

          <div className="col-span-6 sm:col-span-3">
            {selectedMaintenanceActivity == "292460000" ? (
              <>
                <label
                  htmlFor="courseName"
                  className="block text-sm font-medium text-gray-700"
                >
                  Kursnavn
                </label>
                <input
                  {...register("courseName", {
                    maxLength: { value: 100, message: "Maks tegn er 100" },
                  })}
                  type="text"
                  name="courseName"
                  id="courseName"
                  className="mt-1 focus:ring-meny-700 focus:border-meny-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
              </>
            ) : null}
          </div>

          <div className="col-span-6 sm:col-span-3">
            {selectedMaintenanceActivity == "292460000" ? (
              <>
                <p
                  // htmlFor="employerAlternative"
                  className="block text-sm italic m-2 font-normal sm:col-span-2 text-meny-700 sm:mt-px sm:pt-2 sm:col-start-3"
                >
                  Min arrangør står ikke i listen
                </p>
                <Switch
                  checked={alternativeCourceProvider}
                  onChange={showAltCourceProvider}
                  className={classNames(
                    alternativeCourceProvider ? "bg-meny-600" : "bg-gray-900",
                    "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classNames(
                      alternativeCourceProvider
                        ? "translate-x-5"
                        : "translate-x-0",
                      "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                    )}
                  />
                </Switch>
              </>
            ) : null}
          </div>

          {alternativeCourceProvider &&
          selectedMaintenanceActivity == "292460000" ? (
            <div className="col-span-6 sm:col-span-3">
              <label
                htmlFor="courseProviderAccountName"
                className="block text-sm font-medium text-gray-700"
              >
                Alternativ arrangør
              </label>
              <input
                type="text"
                {...register("courseProviderAccountName", {
                  required: false,
                  maxLength: {
                    value: 100,
                    message: "Maks antall tegn er 100",
                  },
                })}
                // onChange={e => SetAltCourseProvider(e.target.value)}
                name="courseProviderAccountName"
                id="courseProviderAccountName"
                autoComplete="courseProviderAccountName"
                className="mt-1 focus:ring-meny-700 focus:border-meny-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
              <ErrorMessage
                errors={errors}
                name="courseProviderAccountName"
                render={({ message }) => (
                  <p className="text-sm text-red-600">{message}</p>
                )}
              />
            </div>
          ) : null}

          <div className="col-span-6">
            <label
              htmlFor="comment"
              className="block text-sm font-medium text-gray-700"
            >
              Kommentar
            </label>
            <div className="mt-1">
              <textarea
                id="comment"
                name="comment"
                {...register("comment", { required: false, maxLength: 2000 })}
                onChange={e => {
                  setComment(e.target.value)
                  setActivityComment(e.target.value)
                }}
                rows={3}
                className="shadow-sm focus:ring-meny-700 focus:border-meny-500 block w-full sm:text-sm border border-gray-300 rounded-md"
                placeholder="Kommentar"
                defaultValue={""}
              />
            </div>
            <HelperText helpertext="Kort beskrivelse av aktiviteten." />
          </div>

          <div className="col-span-6">
            <HelperText
              helpertext={
                "Dersom du har deltatt på kurs digitalt må kursleverandør kunne dokumentere din tilstedeværelse igjennom kurset. Dokumentasjon vedlegges nedenfor."
              }
            />
          </div>
        </div>

        {showActivityButtons ? (
          <div className="mt-8 border-t flex justify-end">
            <button
              type="submit"
              className="ml-3 mt-6 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-meny-600 hover:bg-meny-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
            >
              Last opp dokumenter
            </button>
          </div>
        ) : null}
      </form>
    </div>
  )
}

export default GuidanceActivity
