import { InteractionType } from "@azure/msal-browser"
import {
  MsalAuthenticationTemplate,
  useAccount,
  useMsal,
} from "@azure/msal-react"
import React, { useState } from "react"
import { useQuery, useQueryClient } from "react-query"
import { fetchApi } from "../../../Utils/Api.utils"
import { Loading } from "../../../Utils/Loading"
import { ErrorComponent } from "../../common/graphichs/ErrorComponent"
import LoadingData from "../../common/graphichs/LoadingData"
import MyPageLayout from "../../common/layouts/myPageLayout"
import HeaderText from "../../common/text/Header"
import DocumentList from "../../mypagecomponents/document/DocumentList"
import { loginRequest } from "../../security/authConfig"

const DocumentsContent = () => {
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const queryClient = useQueryClient()
  const [courseCertificates, setCourseCertificates] = useState([])
  const [certificatesAndDiplomas, setCertificatesAndDiplomas] = useState([])
  const [reports, setReports] = useState([])
  const [documents, setDocuments] = useState([])
  const [memberRole, setMemberRole] = useState<boolean>(false)

  const webRoleInfo = useQuery(
    "webRoleData",
    () => {
      return fetchApi(
        process.env.GATSBY_API_URL + "/Contacts/Roles",
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: data => {
        const role = data.some(r => r.role === 292460001)
        setMemberRole(role)
      },
      onError: _error => {},
    }
  )

  const mutationGetDocument = useQuery(
    "documents",
    () => {
      return fetchApi(
        process.env.GATSBY_API_URL + "/Documents/me",
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: data => {
        const courseCertificateData = data.filter(
          item => item.documentType === 292460001
        )
        setCourseCertificates(courseCertificateData)
        const certificatesAndDiplomasData = data.filter(
          item => item.documentType === 292460006 && item.caseId === undefined
        )
        setCertificatesAndDiplomas(certificatesAndDiplomasData)
      },
      onError: _error => {},
      onSettled: () => {},
    }
  )

  const mutationGetDocumentFilter = useQuery(
    "documentsFilter",
    () => {
      return fetchApi(
        process.env.GATSBY_API_URL + "/Documents/filter",
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: data => {
        const reportsData = data.filter(
          item =>
            item.documentType === 292460008 &&
            item.accessLevel === 4 &&
            item.visiblePortal === true
        )
        setReports(reportsData)
      },
      onError: _error => {},
      onSettled: () => {},
    }
  )

  return mutationGetDocument.isLoading ||
    webRoleInfo.isLoading ||
    mutationGetDocumentFilter.isLoading ? (
    <LoadingData />
  ) : (
    <MyPageLayout props="Dokumenter fra Psykologforeningen">
      <div className="w-full flex flex-col items-center">
        <div className="max-w-3xl w-full">
          <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
            <HeaderText text="Spesialistutdanning" />
          </div>
          <h1 className="mt-8 text-2xl font-bold text-meny-700">Kursbevis</h1>
          <div>
            {courseCertificates.length > 0 ? (
              <DocumentList
                documents={courseCertificates}
                mutationGetDocuments={mutationGetDocument}
                canDelete={false}
              />
            ) : (
              <p>Du har ingen kursbevis</p>
            )}
          </div>
          <h1 className="mt-8 text-2xl font-bold text-meny-700">
            Attester og diplom
          </h1>
          <div>
            {certificatesAndDiplomas.length > 0 ? (
              <DocumentList
                documents={certificatesAndDiplomas}
                mutationGetDocuments={mutationGetDocument}
                canDelete={false}
              />
            ) : (
              <p>Du har ingen attester eller diplomer</p>
            )}
          </div>
        </div>
        {memberRole && (
          <div className="max-w-3xl w-full">
            <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
              <HeaderText text="Saksdokumenter / styrereferat" />
            </div>
            {reports.length > 0 ? (
              <DocumentList
                documents={reports}
                mutationGetDocuments={mutationGetDocumentFilter}
                canDelete={false}
              />
            ) : (
              <p className=" text-center">
                Du har ingen Saksdokumenter / styrereferat
              </p>
            )}
          </div>
        )}
      </div>
    </MyPageLayout>
  )
}

const Documents = () => {
  const authRequest = {
    ...loginRequest,
  }
  // console.log(location.state)
  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      <DocumentsContent />
    </MsalAuthenticationTemplate>
  )
}

export default Documents
