import { useAccount, useMsal } from "@azure/msal-react"
import React, { useEffect, useState } from "react"
import { useQuery } from "react-query"
import { StringParam, useQueryParam } from "use-query-params"
import { fetchApi } from "../../../../Utils/Api.utils"
import MyPageLayout from "../../../common/layouts/myPageLayout"
import {
  Checkin,
  EventRegistration,
  MyEventsType,
  SessionRegistrationCrm,
  SpeakerEngagement,
} from "../../../mypagecomponents/event/EventType"
import CheckinRegistrationPage from "./CheckinRegistrationPage"
import EventRegistrationPage from "./EventRegistration"

const MyEventRegistation = () => {
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})

  const registrationid = useQueryParam("id", StringParam)[0]

  const [response, setResponse] = useState<MyEventsType | undefined>(undefined)
  const [myEvents, setMyEvents] = useState<Array<EventRegistration>>([])
  const [checkins, setCheckins] = useState<Array<Checkin>>([])
  const [speakerEngagement, setSpeakerEngagement] = useState<
    Array<SpeakerEngagement>
  >([])

  myEvents.map(item => item.eventid)

  const [myRegistration, setMyRegistration] = useState<
    EventRegistration | Checkin | undefined
  >(undefined)

  const [registrationType, setRegistrationType] = useState<
    "eventRegistation" | "checkin" | undefined
  >(undefined)

  useEffect(() => {
    const fillData = () => {
      setMyEvents(response?.eventRegistration || [])
      setCheckins(response?.checkin || [])
      setSpeakerEngagement(response?.speakerEngagement || [])
    }

    fillData()
  }, [response])

  useEffect(() => {
    const setMyRegistation = () => {
      if (
        (myEvents === undefined && checkins === undefined) ||
        registrationid === undefined
      )
        return

      const myEventReg: EventRegistration | undefined = myEvents.find(
        item => item.eventregistrationid === registrationid
      )
      const myCheckinReg: Checkin | undefined = checkins.find(
        item => item.checkinid === registrationid
      )

      if (myCheckinReg && myCheckinReg.eventRegistration) {
        const eventId = myCheckinReg.event

        const newSessionRegistration: SessionRegistrationCrm[] = checkins
          .filter(item => item.event === eventId)
          .map(item => item.sessionRegistration)
          .filter(item => item !== undefined) as SessionRegistrationCrm[]

        myCheckinReg.eventRegistration.sessionRegistrations =
          newSessionRegistration
      }

      if (myEventReg !== undefined) {
        setMyRegistration(myEventReg)
        setRegistrationType("eventRegistation")
      } else if (myCheckinReg !== undefined) {
        setMyRegistration(myCheckinReg)
        setRegistrationType("checkin")
      }
    }

    setMyRegistation()
  }, [registrationid, myEvents, checkins])

  useQuery<MyEventsType>(
    "myEvents",
    async () =>
      fetchApi(
        process.env.GATSBY_API_URL + `/Events/MyEvents`,
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: data => {
        setResponse(data)
      },
    }
  )

  return (
    <MyPageLayout props={"Mine Kurs"}>
      <div className="w-full flex justify-center">
        <div className="max-w-3xl w-full">
          {registrationType === "eventRegistation" && (
            <div>
              <EventRegistrationPage
                {...myRegistration}
                speakerEngagement={speakerEngagement}
              />
            </div>
          )}
          {registrationType === "checkin" && (
            <div>
              <CheckinRegistrationPage
                {...myRegistration}
                speakerEngagement={speakerEngagement}
              />
            </div>
          )}
        </div>
      </div>
    </MyPageLayout>
  )
}

export default MyEventRegistation
