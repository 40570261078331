import React from "react"
import Footer from "../footers/Footer"
import NavBarSignedIn from "../navbar/NavBarSignedIn"
import { Helmet } from "react-helmet"
import { navigate } from "gatsby"
import { cyanButton, whiteButton } from "../../../styles/tailwindClasses"

const MyPageLayoutEducation = ({ props, children }) => {
  return (
    <div className="font-sans ">
      <Helmet title={props} />
      <NavBarSignedIn />
      <div className="h-full min-h-screen bg-gray-100">
        <div className="h-full min-h-full">
          <div className="bg-gray-100 pb-32">
            <header className="py-10">
              <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <h1 className="text-3xl text-center font-bold text-meny-700">
                  {props}
                </h1>
                <div className="sm:mx-8 mt-4">
                  <button
                    type="button"
                    className={
                      whiteButton + " inline-flex items-center justify-center "
                    }
                    onClick={() =>
                      navigate("/app/kurs/specialityeducation/maler")
                    }
                  >
                    Maler og skjemaer
                  </button>
                </div>
              </div>
            </header>
          </div>

          <main className="-mt-32">
            <div className="max-w-7xl mx-auto pb-12 px-4 sm:px-6 lg:px-8">
              {/* Replace with your content */}
              <div className="sm:mx-8 ">{children}</div>
              {/* <Søknad /> */}

              {/* <div className="h-96 border-4 border-dashed border-gray-200 rounded-lg" /> */}
              {/* /End replace */}
            </div>
          </main>
        </div>
      </div>

      <Footer />
    </div>
  )
}

export default MyPageLayoutEducation
