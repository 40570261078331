import { Link } from "gatsby"
import React from "react"

const LinkText = ({ link, text }) => {
  return (
    <div>
      <Link
        className="hover:cursor-pointer underline hover:text-meny-700 hover:font-bold"
        to={link}
        target="_blank"
        rel="noopener noreferrer"
      >
        {text}
      </Link>
    </div>
  )
}

export default LinkText
