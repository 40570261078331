import { useAccount, useMsal } from "@azure/msal-react"
import React from "react"
import { useMutation, useQueryClient } from "react-query"
import { deleteApi } from "../../../Utils/Api.utils"
import { DateTimeToDateTime } from "../../../Utils/DateFormater"
import { Accordion } from "../../common/accordion"
import { TextWithIcon } from "../../common/text"
import { Checkin, Pass, SessionRegistrationCrm, SessionType } from "./EventType"
import {
  CityOptionsetValueToString,
  StatusAttendee,
  StatusAttendeeToString,
} from "./helpers/Event"

const SessionRegAccordionGroup = (props: {
  SessionReg: Array<SessionRegistrationCrm>
  Sessions?: Array<SessionType>
  checkin?: Checkin
  showCancelButton?: boolean
}) => {
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})

  const queryClient = useQueryClient()

  const deleteSessionRegistation = useMutation<any, any, string>(
    sessionRegistationId => {
      return deleteApi(
        process.env.GATSBY_API_URL +
          "/Events/cancelSessionRegistation/" +
          sessionRegistationId,
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("myEvents")
        window.location.reload()
      },
    }
  )

  const CancelSession = (sessionRegistrationId?: string) => {
    if (!sessionRegistrationId) {
      console.log("Finner ikke registrerings Id")
      return
    }

    deleteSessionRegistation.mutate(sessionRegistrationId)
  }

  return (
    <div className="max-w-3xl mx-auto divide-y divide-gray-200 rounded-lg overflow-hidden mb-6">
      {props.SessionReg.map(item => {
        const accordionSubtitle =
          DateTimeToDateTime(item.sessionCrm?.starttime) +
          " - " +
          DateTimeToDateTime(item.sessionCrm?.endtime)

        const passes: Array<Pass> =
          props.Sessions?.find(session => session.id === item.sessionid)
            ?.passes || []

        return (
          <Accordion
            key={item.sessionid}
            title={item.sessionCrm?.name}
            subTitle={accordionSubtitle}
            tag={StatusAttendeeToString(item.statusattendee as StatusAttendee)}
          >
            <div>
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    item.sessionCrm?.calendarContent ||
                    "ingen beskrivelse tilgjengelig",
                }}
              ></div>
              {/* {passes.length > 0 && (
                <>
                  <h4 className="mb-1 pt-6">Priser</h4>
                  <div className="w-full h-px bg-gray-200 mb-4"></div>
                  {passes.map(pass => {
                    return (
                      <div key={pass.passId}>
                        {pass.passName +
                          ": " +
                          pass.price +
                          pass.currencySymbol}
                      </div>
                    )
                  })}
                </>
              )} */}
              <h4 className="mb-1 pt-6">Detaljer</h4>
              <div className="w-full h-px bg-gray-200 mb-4"></div>
              <div className="flex w-full pb-4">
                <div className="w-full pl-4 pt-4 space-y-4">
                  <TextWithIcon
                    text={"Prosjekt nr: " + item.sessionCrm?.projectno || ""}
                  />
                  <TextWithIcon
                    text={
                      "Start Dato: " +
                      DateTimeToDateTime(item.sessionCrm?.starttime)
                    }
                  />
                  <TextWithIcon
                    text={
                      "Slutt Dato: " +
                      DateTimeToDateTime(item.sessionCrm?.endtime)
                    }
                  />
                </div>
                <div className="w-full pl-4 pt-4 space-y-4">
                  <TextWithIcon
                    text={
                      "By: " + CityOptionsetValueToString(item.sessionCrm?.city)
                    }
                  />
                  <TextWithIcon
                    text={"Sted: " + item.sessionCrm?.building?.name}
                  />
                  <TextWithIcon
                    text={
                      "Total timer: " + (item.sessionCrm?.amountHours || "")
                    }
                  />
                  <TextWithIcon
                    text={
                      "Timer oppmøte: " + (props.checkin?.amounthours || "")
                    }
                  />
                </div>
              </div>
              {passes.length > 0 && (
                <>
                  <p className="mb-1 pt-6 text-md">
                    Pris{" "}
                    <a className="text-black text-md">
                      (Noe prisøkning kan forekomme)
                    </a>
                  </p>

                  <div className="w-full h-px bg-gray-200 mb-4"></div>
                  <div className="flex w-full pb-4">
                    <div className="w-full pl-4 pt-4 space-y-4">
                      {passes.map(pass => (
                        <TextWithIcon
                          text={
                            pass.passName +
                            ": " +
                            pass.price +
                            pass.currencySymbol
                          }
                        />
                      ))}
                    </div>
                  </div>
                </>
              )}

              <div className="py-4">
                {props.showCancelButton && (
                  <button
                    disabled={
                      !!item.sessionCrm?.stopwebsitecancellationon &&
                      Date.parse(item.sessionCrm?.stopwebsitecancellationon) <
                        Date.now()
                    }
                    onClick={() => {
                      CancelSession(item.sessionregistrationid)
                    }}
                    className="disabled:opacity-30 w-fit ml-1 inline-flex justify-end py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-md text-white bg-red-700 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-700"
                  >
                    Avmeld delmøte
                  </button>
                )}
              </div>
            </div>
          </Accordion>
        )
      })}
    </div>
  )
}

export default SessionRegAccordionGroup
