export type useDocumentsType = {
  id: documentMutationType
  disableAutoFetch?: boolean
}

export type documentMutationType = {
  AccountId?: string
  ContactId?: string
  CaseId?: string
  QuoteId?: string
  OpportunityId?: string
  OrderId?: string
  PortalMessageId?: string
  EventId?: string
  CheckinId?: string
  EventRegistrationId?: string
  EducationId?: string
  ActivitySpecialistProgramId?: string
  SessionId?: string
  BuildingId?: string
}

export type documentType = {
  contactList?: Array<{ id: string }>
  accountId?: string
  contactId?: string
  caseId?: string
  quoteId?: string
  opportunityId?: string
  orderId?: string
  portalMessageId?: string
  eventId?: string
  eventRegistrationId?: string
  educationId?: string
  activitySpecialistProgramId?: string
  sessionId?: string
  buildingId?: string
  activityType?: ActivityTypeLevel
  specialtyId?: string
  programtypeId?: string
  documentType?: DocumentTypeEnum
}

export enum DocumentTypeEnum {
  Annen = 292460005,
  DiplomSpesialist = 292460006,
  Kontrakt = 292460004,
  Kursbevis = 292460001,
  Kursevaluering = 292460003,
  Kursmateriell = 292460000,
  MalSpesialistudanning = 292460002,
}

enum ActivityTypeLevel {
  Eksternt_kurs = 292460000,
  Praksisattest = 292460001,
  Skriftlig_arbeid = 292460003,
  Veiledningsattest = 292460004,
  Veiledningskontrakt = 292460002,
  Vedlikeholdsaktivitet = 292460006,
  Godkjenningavveileder = 292460005,
}
