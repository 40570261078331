import React, { useEffect } from "react"
import ReactDatePicker from "react-datepicker"
import { Controller, UseFormReturn } from "react-hook-form"
import {
  FormValues,
  RetiredKeepEmploymentsFormType,
} from "../membership/applications/RetiredKeepEmployments/RetiredKeepEmploymentsType"
import TableText from "../../common/tabel/TableText"
import { ErrorMessage } from "@hookform/error-message"
import { role } from "../../../Utils/Maps"

const EndDateOnEmploymentsRow = (props: {
  employment: RetiredKeepEmploymentsFormType
  formController: UseFormReturn<FormValues, any>
  index: number
}) => {
  const {
    control,
    formState: { errors },
  } = props.formController

  console.log(props.employment)

  console.log(props)

  return (
    <tr key={props.employment?.id}>
      <TableText className="text-left">
        {props.employment?.employeeName?.name}
      </TableText>
      <TableText className="text-left">
        {props.employment?.workplaceName?.name}
      </TableText>
      <TableText className="text-left">
        {role.get(props.employment?.role)}
      </TableText>
      <th className="px-2">
        <Controller
          rules={{ required: { value: true, message: "Mangler sluttdato" } }}
          control={control}
          name={`employments.${props.index}.endDate`}
          render={({ field: { onChange, onBlur, value } }) => (
            <ReactDatePicker
              locale="nb"
              onChange={onChange}
              onBlur={onBlur}
              selected={value ? new Date(value) : undefined}
              dateFormat="dd/MM/yyyy"
              className="block max-w-sm w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
            />
          )}
        />
        <ErrorMessage
          errors={errors}
          name={`employments.${props.index}.endDate`}
          render={({ message }) => (
            <p className="text-sm text-red-600 mb-2">{message}</p>
          )}
        />
      </th>
    </tr>
  )
}

export default EndDateOnEmploymentsRow
