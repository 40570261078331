import React from "react"

const HelperText = ({ helpertext }) => {
  const urlPattern = /(https?:\/\/[^\s]+)/g
  return (
    <div>
      <p className="block text-sm italic font-normal sm:col-span-2 text-meny-700 sm:mt-px sm:pt-2 sm:col-start-3">
        {helpertext.split("\n").map((line, i) => (
          <React.Fragment key={i}>
            {line.split(urlPattern).map((text, index) =>
              urlPattern.test(text) ? (
                <a
                  key={index}
                  href={text}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-meny-700 underline"
                >
                  klikk her
                </a>
              ) : (
                text
              )
            )}
            <br />
          </React.Fragment>
        ))}
      </p>
    </div>
  )
}

export default HelperText
