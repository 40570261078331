import { useAccount, useMsal } from "@azure/msal-react"
import { PaperClipIcon } from "@heroicons/react/outline"
import React from "react"
import { useMutation, useQueryClient } from "react-query"
import { fetchDownLoadFileApi } from "../../../Utils/Api.utils"

const TemplateDocuments = ({ documents }) => {
  const queryClient = useQueryClient()
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  let documentName = "FileName"

  const mutationDownload = useMutation(
    e => {
      documentName = e.title
      return fetchDownLoadFileApi(
        process.env.GATSBY_API_URL + "/Documents/Download/" + e.blobId,
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: blob => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]))
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", documentName)

        // Append to html link element page
        document.body.appendChild(link)

        // Start download
        link.click()

        // Clean up and remove the link
        link.parentNode.removeChild(link)
      },
      onError: _error => {},
      onSettled: () => {},
    }
  )

  const onFileDownLoad = e => mutationDownload.mutate(e)

  return (
    <>
      {documents.length > 0 ? (
        <ul
          role="list"
          className="border border-gray-200 rounded-md divide-y divide-gray-200"
        >
          {documents.map(document => (
            <li
              key={document.blobId}
              className="pl-3 pr-4 py-3 flex items-center justify-between text-sm"
            >
              <div className="w-0 flex-1 flex items-center">
                <PaperClipIcon
                  className="flex-shrink-0 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                <span className="ml-2 flex-1 w-0 truncate">
                  {document.title}
                </span>
              </div>
              <div className="ml-4 flex-shrink-0 flex space-x-4">
                <button
                  type="button"
                  id={document.blobId}
                  name={document.title}
                  onClick={e => onFileDownLoad(document)}
                  className="bg-white rounded-md font-medium text-meny-600 hover:text-meny-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10"
                    />
                  </svg>
                </button>
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <div></div>
      )}
    </>
  )
}

export default TemplateDocuments
