import { useAccount, useMsal } from "@azure/msal-react"
import { Disclosure } from "@headlessui/react"
import { ChevronDownIcon } from "@heroicons/react/solid"
import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { Subscription } from "react-hook-form/dist/utils/createSubject"
import { useQuery } from "react-query"
import { Subscriptions } from "../../../Utils/Entities"
import { fetchApi } from "../../../Utils/Api.utils"
import { DateTimeToNorwegian } from "../../../Utils/DateFormater"

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

const LiabilityInsurance = () => {
  const content = {
    header: "Ansvarsforsikring",
    key: "ansvar",
  }
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Subscriptions>()

  const [subscriptions, setSubscriptions] = useState<Subscriptions[]>()
  const [hide, setHide] = useState<boolean>(true)

  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})

  useEffect(() => {
    const getSubs = () => {
      fetchApi(
        `${process.env.GATSBY_API_URL}/Contacts/Subscriptions`,
        account,
        inProgress,
        instance
      )
        .then(res => {
          setSubscriptions(res.data)
          if (res.data !== undefined && res.data?.length > 0) {
            // setHide(
            //   subscriptions?.find(s =>
            //     s?.subscriptionTypeName?.includes("Ansvarsforsikring")
            //   )
            // )
            let find = res.data?.find(
              s => s?.subscriptionTypeName === "Ansvarsforsikring"
            )
            if (find !== undefined) setHide(false)
          }
        })
        .catch(error => {
          console.log(error)
        })
    }
    getSubs()
  }, [])

  subscriptions?.sort((a, b) => {
    return (
      new Date(b.startDate || "").getTime() -
      new Date(a.startDate || "").getTime()
    )
  })

  return (
    <div>
      {hide ? (
        <>
          <p className="italic flex justify-center mt-3 max-w-md mx-auto text-base text-gray-600 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
            Det finnes ingen oppføringer.
          </p>
        </>
      ) : (
        <Disclosure as="div" key={subscriptions?.[0]?.id} className="pt-6">
          {({ open }) => (
            <div
              className="mt-6
                  space-y-6
                  
                  bg-white
                  rounded-lg
                  shadow
                  px-5
                  py-6
                  sm:px-6"
            >
              <dt className="text-lg">
                <Disclosure.Button
                  key={subscriptions?.[0]?.id}
                  className="text-left w-full flex justify-between items-start text-gray-400"
                >
                  <span className="font-medium text-gray-900">
                    Ansvarsforsikring - Norsk Psykologforening
                  </span>
                  <span className="ml-6 h-7 flex items-center">
                    <ChevronDownIcon
                      className={classNames(
                        open ? "-rotate-180" : "rotate-0",
                        "h-6 w-6 transform"
                      )}
                      aria-hidden="true"
                    />
                  </span>
                </Disclosure.Button>
              </dt>

              <Disclosure.Panel as="dd" className=" mt-2 pr-12">
                {subscriptions?.map(sub => {
                  if (
                    sub?.subscriptionTypeName === "Ansvarsforsikring" &&
                    sub?.parentOrganizationUnit?.id ===
                      "1331728e-2078-ec11-8d21-000d3aa9614b" &&
                    sub?.endDate === undefined
                  )
                    return (
                      <div className="gap-3 mb-4">
                        <p className="text-lg font-bold ml-10 text-meny-700">
                          Aktiv siden: {DateTimeToNorwegian(sub?.startDate)}
                        </p>
                      </div>
                    )
                })}
                {subscriptions?.map(sub => {
                  if (
                    sub?.subscriptionTypeName === "Ansvarsforsikring" &&
                    sub?.parentOrganizationUnit?.id ===
                      "1331728e-2078-ec11-8d21-000d3aa9614b" &&
                    sub?.endDate !== undefined
                  )
                    return (
                      <div className="gap-3 mb-4">
                        <p className="text-base ml-10 text-meny-700">
                          Aktiv i perioden:{" "}
                          {DateTimeToNorwegian(sub?.startDate)} -{" "}
                          {DateTimeToNorwegian(sub?.endDate)}
                        </p>
                      </div>
                    )
                })}
              </Disclosure.Panel>
            </div>
          )}
        </Disclosure>
      )}
    </div>
  )
}

export default LiabilityInsurance
