import React, { useEffect, useState } from "react"
import {
  Checkin,
  SessionRegistrationCrm,
  SessionType,
} from "../../../mypagecomponents/event/EventType"
import SessionRegAccordionGroup from "../../../mypagecomponents/event/SessionRegAccordionGroup"

const SessionList = (props: {
  SessionRegs: Array<SessionRegistrationCrm>
  Sessions?: Array<SessionType>
  checkin?: Checkin
  showCancelButton?: boolean
}) => {
  if (props.SessionRegs.length === 0) return <></>

  const [sessionReg, setSessionReg] = useState<Array<SessionRegistrationCrm>>(
    props.SessionRegs
  )

  useEffect(() => {
    //sort by start date
    setSessionReg(
      props.SessionRegs.sort((a, b) => {
        return (
          Date.parse(a.sessionCrm?.starttime || "") -
          Date.parse(b.sessionCrm?.starttime || "")
        )
      })
    )
  }, [props.SessionRegs])

  return (
    <div>
      <h1 className="mt-8 text-2xl font-bold text-meny-700">Kurs</h1>
      <div className="divide-y divide-gray-200 bg-white rounded-lg px-4"></div>
      <SessionRegAccordionGroup
        SessionReg={sessionReg}
        Sessions={props.Sessions}
        checkin={props.checkin}
        showCancelButton={props.showCancelButton}
      />
    </div>
  )
}

export default SessionList
