import { useAccount, useMsal } from "@azure/msal-react"
import { ArrowRightIcon, CheckIcon } from "@heroicons/react/outline"
import React, { useEffect, useState } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { toast } from "react-toastify"
import { cyanButton } from "../../../styles/tailwindClasses"
import { fetchApi, patchApi, postApi } from "../../../Utils/Api.utils"
import { RemoveUnderScore } from "../../../Utils/FormatHelper"
import ConfirmationDialog from "../../common/graphichs/ConfirmationDialog"
import LoadingData from "../../common/graphichs/LoadingData"
import QuestionMark from "../../common/graphichs/QuestionMark"
import HoverTextBox from "../../common/misc/HoverTextBox"
import HelperText from "../../common/text/HelperText"

const ProgramDetail = ({ program }) => {
  const queryClient = useQueryClient()
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const [sentForApproval, setSentForApproval] = useState(false)
  const [openSendSchema, setOpenSendSchema] = useState(false)
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [confirmationMessage, setConfirmationMessage] = useState(
    "Du har spesialiseringsaktiviteter som ennå ikke er godkjent. Du kan sende programløpet til godkjenning når alle krav til praksis, veiledning, kurs og skriftlig arbeid er oppfylt og godkjent."
  )
  const [comment, setComment] = useState("")
  const [activities, setActivities] = useState<any[]>()
  const [canConfirm, setCanConfirm] = useState(false)
  const [showLoadingData, setShowLoadingData] = useState(false)

  const userInfoQuery = useQuery(
    "userInfo",
    () =>
      fetchApi(
        process.env.GATSBY_API_URL + "/Contacts/me",
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: data => { },
      onError: _error => { },
    }
  )

  const mutationUpdateSpecialityProgram = useMutation(
    () => {
      return patchApi(
        process.env.GATSBY_API_URL + "/SpecialistPrograms",
        {
          id: program.id,
          approveStatus: 778380001, //Til godkjenning
        },
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: data => {
        mutationCreateIncident.mutate()
      },
      onError: _error => { },
      onSettled: () => { },
    }
  )

  const mutationCreateIncident = useMutation(
    () => {
      return postApi(
        process.env.GATSBY_API_URL + "/Incidents",
        {
          title:
            "Godkjenning av deltagelse spesialistprogram for " + program.name,
          subjectId: "1a55f485-eea5-ec11-983f-000d3aae55a4", //Godkjenning deltagelse spesialistprogram
          caseTypeCode: 8,
          caseOriginCode: 3,
          status: 1, //Pågår
          state: 0,
          approveStatus: 778380001, //Til godkjenning
          comment: comment,
          educationId: program.specialistEducation?.id,
          SpecialistProgramId: program.id,
        },
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: data => {
        setSentForApproval(true)
        program.approveStatusName = "Til godkjenning"
        queryClient.invalidateQueries([
          "programSpecializationActivitiesData-" + program.id,
        ])
      },
      onError: _error => { },
      onSettled: () => {
        setShowLoadingData(false)
      },
    }
  )

  const programSpecializationActivitiesQuery = useQuery(
    "programSpecializationActivitiesData-" + program.id,
    () =>
      fetchApi(
        process.env.GATSBY_API_URL +
        "/SpecializationActivities/Program/" +
        program.id,
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      enabled: !!program?.id,
      onSuccess: data => {
        setActivities(data)
      },
      onError: _error => { },
    }
  )

  const onApprovalChange = e => {
    console.log("activities")
    console.log(activities)
    console.log("program.programType.id")
    console.log(program?.programType?.id)
    console.log("program?.id")
    console.log(program?.id)
    if (activities?.length < 1 || activities === undefined || activities === null) {
      if (program.programType.id !== "c0c4d26e-c5a6-ec11-983f-000d3aae55a4") {// not Vedlikehold
        setConfirmationMessage(
          "Du må ha levert spesialiseringsaktiviteter for å kunne søke ditt programløp godkjent. Alle krav til programmet må være levert og godkjent før programmet kan bli godkjent. Du kan dokumentere oppfylte krav ved å legge til aktiviteter på programmet."
        )
        setShowConfirmation(true)
        setCanConfirm(false)
        return
      }
      // if (activities?.length < 1) {
    }

    if (
      activities?.some(
        x =>
          // x.approveStatus == 778380002 ||
          x.approveStatus == 778380003 || x.approveStatus == 778380001 && x.status !== 2
      )
    ) {
      setShowConfirmation(true)
      setCanConfirm(false)
      return
    }

    setCanConfirm(true)
    setShowConfirmation(true)
  }

  const onConfirmation = e => {
    if (e) {
      setShowLoadingData(true)
      mutationUpdateSpecialityProgram.mutate()
    }
  }

  const onOpenSendSchema = e => setOpenSendSchema(true)

  return programSpecializationActivitiesQuery.isLoading ? (
    // // "Laster inn..."
    <LoadingData />
  ) : programSpecializationActivitiesQuery.isError ? (
    programSpecializationActivitiesQuery.error.message
  ) : (
    <div>
      <div>
        {showLoadingData && <LoadingData subject="Sender til godkjenning..." />}
      </div>
      <div>
        {showConfirmation && (
          <ConfirmationDialog
            onConfirmation={onConfirmation}
            setShowConfirmation={setShowConfirmation}
            subject="Bekreft innsending"
            description={confirmationMessage}
            canCondfirm={canConfirm}
            showDesc={false}
          />
        )}
      </div>
      <div className="bg-gray-200 shadow-sm  border border-gray-200 rounded-lg pt-8 pb-12 px-4 sm:pt-8 sm:px-6 lg:bg-none lg:flex lg:items-center lg:justify-end lg:px-0 lg:pl-8 lg:pr-14">
        <div className="max-w-lg mx-auto w-full space-y-8 lg:mx-0">
          {program.programType?.id == "c0c4d26e-c5a6-ec11-983f-000d3aae55a4" ? (
            <div className="sm:grid sm:grid-cols-5 sm:gap-6">
              <>
                <span className="sm:col-span-2">
                  <h1 className="text-3xl  font-extrabold tracking-tight">
                    {program.programType?.name}
                  </h1>
                  <p className="flex flex-col">
                    <span className="flex flex-col">
                      {program?.fromDate !== undefined ? (
                        <>
                          <span className="mt-2 text-base font-medium">
                            Fra:{" "}
                            <span className="text-lg">
                              <time dateTime={program.fromDate}>
                                {new Intl.DateTimeFormat("nb-NO", {
                                  dateStyle: "medium",
                                  // timeStyle: "short",
                                }).format(new Date(program.fromDate))}
                              </time>
                            </span>
                          </span>
                        </>
                      ) : null}
                    </span>
                    <span>
                      <span className="flex flex-col">
                        {program?.toDate !== undefined ? (
                          <>
                            {/* <span className="sr-only">plus</span> */}
                            <span className="mt-2 text-base font-medium">
                              Til:{" "}
                              <span className="text-lg">
                                <time dateTime={program.toDate}>
                                  {new Intl.DateTimeFormat("nb-NO", {
                                    dateStyle: "medium",
                                    // timeStyle: "short",
                                  }).format(new Date(program.toDate))}
                                </time>
                              </span>
                            </span>
                          </>
                        ) : null}
                      </span>
                    </span>
                  </p>
                </span>
                <span className="sm:col-span-3">
                  <HelperText
                    helpertext={
                      program.requirementsSpecialistEducation?.textMaintenance
                    }
                  />
                </span>
              </>
            </div>
          ) : (
            <div>
              <h1 className="text-3xl  font-extrabold tracking-tight">
                {program.programType?.name}
              </h1>
              <p className="flex flex-col">
                <span className="flex flex-col">
                  {program?.fromDate !== undefined ? (
                    <>
                      <span className="mt-2 text-base font-medium">
                        Fra:{" "}
                        <span className="text-lg">
                          <time dateTime={program.fromDate}>
                            {new Intl.DateTimeFormat("nb-NO", {
                              dateStyle: "medium",
                              // timeStyle: "short",
                            }).format(new Date(program.fromDate))}
                          </time>
                        </span>
                      </span>
                    </>
                  ) : null}
                </span>
                {/* <span
                className="pointer-events-none absolute h-12 w-full flex items-center justify-center"
                aria-hidden="true"
              >
                <ArrowRightIcon className="h-6 w-6 " aria-hidden="true" />
              </span> */}
                <span>
                  <span className="flex flex-col">
                    {program?.toDate !== undefined ? (
                      <>
                        {/* <span className="sr-only">plus</span> */}
                        <span className="mt-2 text-base font-medium">
                          Til:{" "}
                          <span className="text-lg">
                            <time dateTime={program.toDate}>
                              {new Intl.DateTimeFormat("nb-NO", {
                                dateStyle: "medium",
                                // timeStyle: "short",
                              }).format(new Date(program.toDate))}
                            </time>
                          </span>
                        </span>
                      </>
                    ) : null}
                  </span>
                </span>
              </p>
            </div>
          )}
          <table className="min-w-full divide-y divide-gray-300 border border-gray-200 ">
            <thead className="divide-y divide-gray-200 bg-meny-700 ">
              <tr>
                <th
                  scope="col"
                  className="divide-x hidden md:table-cell divide-gray-200  px-3 py-3.5 text-md text-white"
                >
                  Aktivitet
                </th>
                <th
                  scope="col"
                  className="divide-x divide-gray-200  px-3 py-3.5 text-md text-white"
                >
                  Dokumentert
                </th>
                <th
                  scope="col"
                  className="divide-x divide-gray-200  px-3 py-3.5 text-md text-white"
                >
                  Krav
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {program.programType?.id ==
                "c0c4d26e-c5a6-ec11-983f-000d3aae55a4" ? (
                <>
                  <tr className="divide-x divide-gray-200">
                    <td className="whitespace-nowrap  hidden md:table-cell py-3 pl-4 pr-3 text-sm font-sm sm:pl-6">
                      Sum vedlikehold kurs og kongress:
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm ">
                      {program.sumCourseParticipation}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm ">
                      {
                        program.requirementsSpecialistEducation
                          ?.maintenanceCourses
                      }
                    </td>
                  </tr>
                  <tr className="divide-x divide-gray-200">
                    <td className="whitespace-nowrap  hidden md:table-cell py-3 pl-4 pr-3 text-sm font-sm  sm:pl-6">
                      Sum vedlikehold andre aktiviteter:
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm ">
                      {program.sumMaintenanceOther}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm ">
                      {
                        program.requirementsSpecialistEducation
                          ?.maintenanceOther
                      }
                    </td>
                  </tr>
                </>
              ) : (
                <>
                  <tr className="divide-x divide-gray-200">
                    <td className="whitespace-nowrap  hidden md:table-cell py-4 pl-4 pr-3 text-sm font-sm sm:pl-6">
                      <div className="flex flex-row space-x-2">
                        <div className="">Ant timer kurs:</div>
                        <div className="">
                          <HoverTextBox
                            text={
                              program.requirementsSpecialistEducation
                                ?.textCources
                            }
                            icon={() => <QuestionMark className="h-5 w-5" />}
                          />
                        </div>
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm ">
                      {program.sumCourseParticipation}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm ">
                      {
                        program.requirementsSpecialistEducation
                          ?.courseParticipation
                      }
                    </td>
                  </tr>
                  <tr className="divide-x divide-gray-200">
                    <td className="whitespace-nowrap  hidden md:table-cell py-4 pl-4 pr-3 text-sm font-sm sm:pl-6">
                      <div className="flex flex-row space-x-2">
                        <div className="">Sum praksis (uker):</div>
                        <div className="">
                          <HoverTextBox
                            text={
                              program.requirementsSpecialistEducation
                                ?.textPractice
                            }
                            icon={() => <QuestionMark className="h-5 w-5" />}
                          />
                        </div>
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm ">
                      {program.sumPracsis}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm ">
                      {program.requirementsSpecialistEducation?.pracsis}
                    </td>
                  </tr>
                  <tr className="divide-x divide-gray-200">
                    <td className="whitespace-nowrap  hidden md:table-cell py-4 pl-4 pr-3 text-sm font-sm sm:pl-6">
                      <div className="flex flex-row space-x-2">
                        <div className="">Sum veiledning:</div>
                        <div className="">
                          <HoverTextBox
                            text={
                              program.requirementsSpecialistEducation
                                ?.textGuidence
                            }
                            icon={() => <QuestionMark className="h-5 w-5" />}
                          />
                        </div>
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm ">
                      {program.sumGuidence}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm ">
                      {program.requirementsSpecialistEducation?.guidence}
                    </td>
                  </tr>

                  <tr className="divide-x divide-gray-200">
                    <td className="whitespace-nowrap  hidden md:table-cell py-3 pl-4 pr-3 text-sm font-sm  sm:pl-6">
                      Sum digital individuell veiledning (t):
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm ">
                      {program.sumHoursDigitalIdividualGuideance}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm ">
                      {
                        program.requirementsSpecialistEducation
                          ?.sumHoursDigitalIdividualGuideance
                      }
                    </td>
                  </tr>
                  <tr className="divide-x divide-gray-200">
                    <td className="whitespace-nowrap  hidden md:table-cell py-4 pl-4 pr-3 text-sm font-sm  sm:pl-6">
                      Sum digital gruppeveiledning (t):
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm ">
                      {program.sumHoursDigitalGroupGuideance}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm ">
                      {
                        program.requirementsSpecialistEducation
                          ?.sumHoursDigitalGroupGuideance
                      }
                    </td>
                  </tr>
                  <tr className="divide-x divide-gray-200">
                    <td className="whitespace-nowrap  hidden md:table-cell py-4 pl-4 pr-3 text-sm font-sm sm:pl-6">
                      Sum fysisk individuell veiledning (t):
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm ">
                      {program.hoursPhysicalIdividualGuideance}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm ">
                      {
                        program.requirementsSpecialistEducation
                          ?.hoursPhysicalIdividualGuideance
                      }
                    </td>
                  </tr>
                  <tr className="divide-x divide-gray-200">
                    <td className="whitespace-nowrap  hidden md:table-cell py-4 pl-4 pr-3 text-sm font-sm sm:pl-6">
                      Sum fysisk gruppeveiledning (t):
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm ">
                      {program.sumHoursPhysicalGroupGuideance}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm ">
                      {
                        program.requirementsSpecialistEducation
                          ?.sumHoursPhysicalGroupGuideance
                      }
                    </td>
                  </tr>
                  {program.programType?.id ==
                    "ee9bb462-c5a6-ec11-983f-000d3aae55a4" &&
                    program.requirementsSpecialistEducationId ==
                    "0d95b3c1-e8d4-ec11-a7b5-000d3a4a5585" ? (
                    <>
                      <tr className="divide-x divide-gray-200">
                        <td className="whitespace-nowrap  hidden md:table-cell py-4 pl-4 pr-3 text-sm font-sm sm:pl-6">
                          <div className="flex flex-row space-x-2">
                            <div className="">Sum egenaktivitet (t):</div>
                            <div className="">
                              <HoverTextBox
                                text={
                                  program.requirementsSpecialistEducation
                                    ?.textOwnActivity
                                }
                                icon={() => (
                                  <QuestionMark className="h-5 w-5" />
                                )}
                              />
                            </div>
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm ">
                          {program.sumOwnActivities}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm ">
                          {
                            program.requirementsSpecialistEducation
                              ?.hoursOwnActivity
                          }
                        </td>
                      </tr>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              )}

              <tr className="divide-x divide-gray-200">
                <td className="whitespace-nowrap  hidden md:table-cell py-4 pl-4 pr-3 text-sm font-medium sm:pl-6">
                  Status: {" " + RemoveUnderScore(program.approveStatusName)}
                </td>
              </tr>
            </tbody>
          </table>
          {(program.approveStatus === 778380003 ||
            program.approveStatus === 778380002) &&
            !sentForApproval ? (
            <div>
              <div className="mt-1.5 relative">
                {!openSendSchema ? (
                  <div className="flex justify-center">
                    <button
                      onClick={e => onOpenSendSchema(e)}
                      type="button"
                      className={
                        cyanButton +
                        " py-4 px-8 flex items-center justify-center text-lg leading-6 font-medium md:px-10 "
                      }
                    >
                      Søk programløp godkjent
                    </button>
                  </div>
                ) : (
                  <div>
                    {/* Obligatorisk program */}
                    {program.programType.id ===
                      "ee9bb462-c5a6-ec11-983f-000d3aae55a4" ? (
                      <p className="mt-5 mb-4 text-lg text-gray-900">
                        Du kan søke programløp godkjent når alle krav til
                        praksis, veiledning, kurs og skriftlig arbeid er oppfylt
                      </p>
                    ) : // Vedlikehold
                      program.programType.id ===
                        "c0c4d26e-c5a6-ec11-983f-000d3aae55a4" ? (
                        <p className="mt-5 mb-4 text-lg text-gray-900">
                          Vedlikeholdsperioden gjelder for 5 år. Du kan søke
                          vedlikehold godkjent før utløp av perioden dersom alle
                          krav er oppfylt. Ny vedlikeholdsperiode vil allikevel
                          gjelde fra nåværende periode sin sluttdato
                        </p>
                      ) : (
                        // Fellesprogram / Valgfritt program
                        <p className="mt-5 mb-4 text-lg text-gray-900">
                          Du kan søke programløp godkjent når alle krav til
                          praksis, veiledning og kurs er oppfylt
                        </p>
                      )}
                    <textarea
                      rows={5}
                      onChange={e => setComment(e.target.value)}
                      name="comment"
                      id="comment"
                      required
                      className="shadow-sm focus:ring-indimenygo-500 focus:border-meny-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      placeholder="Legg til kommentar..."
                      defaultValue={""}
                    />
                    <div className="flex justify-center">
                      <button
                        onClick={e => onApprovalChange(e)}
                        type="button"
                        className={
                          cyanButton +
                          "mt-4 py-4 px-8 flex items-center justify-center text-lg leading-6 font-medium md:px-10 "
                        }
                      >
                        Send til godkjenning
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default ProgramDetail
