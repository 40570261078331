import { InteractionType } from "@azure/msal-browser"
import {
  MsalAuthenticationTemplate,
  useAccount,
  useMsal,
} from "@azure/msal-react"
import { Switch } from "@headlessui/react"
import { ErrorMessage } from "@hookform/error-message"
import { navigate } from "gatsby"
import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { toast } from "react-toastify"
import { cyanButton } from "../../../styles/tailwindClasses"
import {
  fetchApiStandard,
  patchApi,
  postApi,
  postGetDocumentApi,
} from "../../../Utils/Api.utils"
import { Loading } from "../../../Utils/Loading"
import { FormValues, Incidents, NewIncidentVal } from "../../../Utils/Types"
import { DropDown } from "../../common/dropDown"
import { ErrorComponent } from "../../common/graphichs/ErrorComponent"
import LoadingData from "../../common/graphichs/LoadingData"
import { classNames } from "../../common/graphichs/misc"
import MyPageLayout from "../../common/layouts/myPageLayout"
import MyPageLayout2 from "../../common/layouts/myPageLayout2"
import HelperText from "../../common/text/HelperText"
import DocumentList from "../../mypagecomponents/document/DocumentList"
import FileUpload from "../../mypagecomponents/document/FileUpload"
import { loginRequest } from "../../security/authConfig"

const ExternalCourseProviderContent = () => {
  const queryClient = useQueryClient()
  const [zipCode, setZipCode] = useState(null)
  const [zipCodeId, setZipCodeId] = useState(null)
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const [showLoadingData, setShowLoadingData] = useState(false)
  const [title, setTitle] = useState("")
  const [courseTheme, setCourseTheme] = useState("")
  const [description, setDescription] = useState("")
  const [incidentType, setIncidentType] = useState(0)
  const [documents, setDocuments] = useState([])
  const [showDocumentComponent, setShowDocumentComponent] = useState(false)
  const [showIncidentButtons, setShowIncidentButtons] = useState(true)
  const [incidentId, setIncidentId] = useState("")
  const [attendance, setAttendance] = useState<number>(1)
  const [accept, setAccept] = useState<boolean>(false)
  const [camAccept, setCamAccept] = useState<boolean>(false)
  const [participantAccept, setParticipantAccept] = useState<boolean>(false)
  const [city, setCity] = useState<string>()

  const {
    register,
    handleSubmit,
    reset,
    setError,
    clearErrors,
    resetField,
    formState: { errors, isDirty, isValid },
  } = useForm<Incidents>()

  const attendanceRadio = [
    { id: "1", title: "Fysisk" },
    { id: "2", title: "Digitalt" },
  ]

  // const attendanceEdit = e => {
  //   if (e === 0) {
  //     setAttendance(undefined)
  //   } else setAttendance(e)
  // }

  const userZipCodeQuery = useQuery(
    "userZipCode",
    () =>
      fetchApiStandard(
        process.env.GATSBY_API_URL + "/Addresses/PostalCodes/" + zipCode,
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      enabled: !!zipCode,
      onSuccess: data => {
        setZipCodeId(data.id)
        setCity(data.postalPlace)
      },
      onError: _error => {
        setZipCode(null)
        setCity("")
        toast.warning("Vennligst tast inn et gyldig postnr!", {
          position: "top-center",
          autoClose: 10000,
        })
      },
    }
  )

  // const updateAttendance = (e: string) => {
  //   // if (e === "0") setAttendance()
  //   else setAttendance(parseInt(e))
  // }

  const mutationCreateIncident = useMutation(
    data => {
      return postApi(
        process.env.GATSBY_API_URL + "/Incidents",
        data,
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: data => {
        setShowDocumentComponent(true)
        setIncidentId(data)
        setShowIncidentButtons(false)

        queryClient.invalidateQueries(["userIncidentData"])
      },
      onError: _error => {
        setShowDocumentComponent(false)
        setShowIncidentButtons(true)
      },
      onSettled: () => {
        setShowLoadingData(false)
      },
    }
  )

  const postIncident = async (data, e) => {
    if (!accept || (attendance == 2 && (!camAccept || !participantAccept))) {
      toast.warning("Samtykker mangler!", {
        position: "top-center",
        autoClose: 10000,
      })

      return
    }

    e.preventDefault()
    setShowLoadingData(true)
    data.title = "Godkjenning av eksterne vedlikeholdskurs"
    data.caseTypeCode = 19
    data.caseOriginCode = 3
    // Emne = Godkjenning av eksterne vedlikeholdskurs
    data.subjectId = "eccca80d-dcb0-ec11-9840-000d3abf775d"
    data.zipCode = zipCodeId
    data.status = 292460000 //Kladd
    data.state = 0
    data.attendance = attendance
    data.description =
      title.slice(-1) === "."
        ? title + "\n" + courseTheme
        : title + "." + "\n" + courseTheme

    // if (attendance == 0) data.attendance = undefined

    mutationCreateIncident.mutate(data)
  }

  const setPostalPlace = event => {
    if (event.target.value.length === 4) setZipCode(event.target.value)
    else {
      setZipCode(null)
      setCity("")
    }
    // else setCity("")
  }

  const mutationGetDocuments = useMutation(
    () => {
      return postGetDocumentApi(
        process.env.GATSBY_API_URL + "/Documents/Regarding/Get",
        {
          CaseId: incidentId,
        },
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: data => {
        setDocuments(data)
      },
      onError: _error => {},
      onSettled: () => {},
    }
  )

  const mutationUpdateIncident = useMutation(
    () => {
      return patchApi(
        process.env.GATSBY_API_URL + "/Incidents",
        {
          id: incidentId,
          status: 1, //Pågår
        },
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: () => {
        toast.success(
          "Saken er opprettet! Du vil nå bli videreført til dine saker.",
          {
            position: "top-center",
            autoClose: 5000,
          }
        )
        setTimeout(() => {
          navigate("/app/dialog/minesaker")
        }, 5000)
      },
      onError: _error => {},
      onSettled: () => {},
    }
  )

  const PatchIncident = async e => {
    setShowLoadingData(true)
    e.preventDefault()
    if (documents.length == 0) {
      toast.warning("Vennligst last opp en eller flere filer!", {
        position: "top-center",
        autoClose: 10000,
      })

      setShowLoadingData(false)

      return
    }
    mutationUpdateIncident.mutate()
  }

  return (
    <MyPageLayout2 props="Kursleverandør">
      <div>{showLoadingData && <LoadingData subject="Oppretter sak..." />}</div>
      {showIncidentButtons ? (
        <form
          method="POST"
          onSubmit={handleSubmit(postIncident)}
          className="space-y-8 divide-y divide-gray-200"
        >
          <div className="space-y-8 sm:space-y-5">
            <div className="space-y-6sm:space-y-5">
              <div>
                <h3 className="text-lg leading-6 font-medium text-meny-700">
                  Søknad om godkjenning av vedlikeholdskurs
                </h3>
                <p>Felter markert med * er obligatoriske</p>
                <p>Saksbehandlingstid tar vanligvis 1 - 3 uker</p>
              </div>
              <div className="space-y-6 sm:space-y-5">
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                  <label
                    htmlFor="courseTitle"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Tittel på kurset*:
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <input
                      type="text"
                      {...register("courseTitle", {
                        required: true,

                        maxLength: {
                          value: 100,
                          message: "Maks tegn er 100",
                        },
                      })}
                      name="courseTitle"
                      id="courseTitle"
                      required
                      onChange={e => setTitle(e.target.value)}
                      autoComplete="courseTitle"
                      className=" block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                    />
                    <ErrorMessage
                      errors={errors}
                      name="courseTitle"
                      render={({ message }) => (
                        <p className="text-sm text-red-600">{message}</p>
                      )}
                    />
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                  <label
                    htmlFor="courseTheme"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Kursets tema er*:
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <textarea
                      {...register("courseTheme", {
                        required: true,

                        maxLength: {
                          value: 2000,
                          message: "Maks tegn er 2000",
                        },
                      })}
                      name="courseTheme"
                      id="courseTheme"
                      rows={3}
                      required
                      onChange={e => setCourseTheme(e.target.value)}
                      autoComplete="courseTheme"
                      className=" block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                    />
                    <ErrorMessage
                      errors={errors}
                      name="courseTheme"
                      render={({ message }) => (
                        <p className="text-sm text-red-600">{message}</p>
                      )}
                    />
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                  <label
                    htmlFor="courseTargetGroup"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Kursets målgruppe er*:
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <input
                      type="text"
                      {...register("courseTargetGroup", {
                        required: true,

                        maxLength: {
                          value: 200,
                          message: "Maks tegn er 200",
                        },
                      })}
                      name="courseTargetGroup"
                      id="courseTargetGroup"
                      required
                      autoComplete="courseTargetGroup"
                      className=" block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                    />
                    <ErrorMessage
                      errors={errors}
                      name="courseTargetGroup"
                      render={({ message }) => (
                        <p className="text-sm text-red-600">{message}</p>
                      )}
                    />
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                  <label
                    htmlFor="courseGoal"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Målet med kurset er*:
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <textarea
                      {...register("courseGoal", {
                        required: true,

                        maxLength: {
                          value: 2000,
                          message: "Maks tegn er 2000",
                        },
                      })}
                      name="courseGoal"
                      id="courseGoal"
                      rows={3}
                      required
                      autoComplete="courseGoal"
                      className=" block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                    />
                    <ErrorMessage
                      errors={errors}
                      name="courseGoal"
                      render={({ message }) => (
                        <p className="text-sm text-red-600">{message}</p>
                      )}
                    />
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                  <label
                    htmlFor="courseOutcome"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Etter kurset vil deltakerne*:
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <textarea
                      {...register("courseOutcome", {
                        required: true,

                        maxLength: {
                          value: 2000,
                          message: "Maks tegn er 2000",
                        },
                      })}
                      name="courseOutcome"
                      id="courseOutcome"
                      required
                      rows={3}
                      autoComplete="courseOutcome"
                      className=" block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                    />
                    <ErrorMessage
                      errors={errors}
                      name="courseOutcome"
                      render={({ message }) => (
                        <p className="text-sm text-red-600">{message}</p>
                      )}
                    />
                    <HelperText
                      helpertext={
                        "Kort om forventet læringsutbytte for deltakerne."
                      }
                    />
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                  <label
                    htmlFor="courseContent"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Kursets innhold*:
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <textarea
                      {...register("courseContent", {
                        required: true,

                        maxLength: {
                          value: 2000,
                          message: "Maks tegn er 2000",
                        },
                      })}
                      name="courseContent"
                      id="courseContent"
                      required
                      rows={3}
                      autoComplete="courseContent"
                      className=" block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                    />
                    <ErrorMessage
                      errors={errors}
                      name="courseContent"
                      render={({ message }) => (
                        <p className="text-sm text-red-600">{message}</p>
                      )}
                    />
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                  <label
                    htmlFor="companyName"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Firmanavn/Arrangør*:
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <input
                      type="text"
                      {...register("companyName", {
                        required: true,
                        pattern: {
                          value: /^[-'.A-Za-zÆØÅæøå0-9 ]*$/i,
                          message:
                            "Kun bokstaver, tall og tegnene ' . - er tillatt.",
                        },
                        maxLength: {
                          value: 100,
                          message: "Maks tegn er 100",
                        },
                      })}
                      name="companyName"
                      id="companyName"
                      required
                      autoComplete="companyName"
                      className=" block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                    />
                    <ErrorMessage
                      errors={errors}
                      name="companyName"
                      render={({ message }) => (
                        <p className="text-sm text-red-600">{message}</p>
                      )}
                    />
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                  <label
                    htmlFor="courseContactPerson"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Kontaktperson*:
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <input
                      type="text"
                      {...register("courseContactPerson", {
                        required: true,
                        pattern: {
                          value: /^[-'.,A-Za-zÆØÅæøå0-9 ]*$/i,
                          message:
                            "Kun bokstaver, tall og tegnene ' . - , er tillatt.",
                        },
                        maxLength: {
                          value: 100,
                          message: "Maks tegn er 100",
                        },
                      })}
                      name="courseContactPerson"
                      id="courseContactPerson"
                      required
                      autoComplete="courseContactPerson"
                      className=" block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                    />
                    <ErrorMessage
                      errors={errors}
                      name="courseContactPerson"
                      render={({ message }) => (
                        <p className="text-sm text-red-600">{message}</p>
                      )}
                    />
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                  <label
                    htmlFor="last-name"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Organisasjonsnr*:
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <input
                      type="text"
                      {...register("orgNr", {
                        required: true,
                        pattern: {
                          value: /^[0-9]+$/,
                          message: "Kun tall er tillatt",
                        },
                        minLength: {
                          value: 9,
                          message: "Orgnummer skal være 9 siffer",
                        },
                        maxLength: {
                          value: 9,
                          message: "Orgnummer skal være 9 siffer",
                        },
                      })}
                      name="orgNr"
                      id="orgNr"
                      required
                      autoComplete="family-name"
                      className=" block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                    />
                    <ErrorMessage
                      errors={errors}
                      name="orgNr"
                      render={({ message }) => (
                        <p className="text-sm text-red-600">{message}</p>
                      )}
                    />
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                  <label
                    htmlFor="Email"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Epost*:
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <input
                      type="text"
                      {...register("Email", {
                        required: {
                          value: true,
                          message: "Obligatorisk felt.",
                        },
                        pattern: {
                          value:
                            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))+$/i,
                          message: "Feil epostformat",
                        },
                        maxLength: {
                          value: 320,
                          message: "Maks tegn er 320",
                        },
                      })}
                      name="Email"
                      id="Email"
                      required
                      autoComplete="Email"
                      className=" block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                    />
                    <ErrorMessage
                      errors={errors}
                      name="Email"
                      render={({ message }) => (
                        <p className="text-sm text-red-600">{message}</p>
                      )}
                    />
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                  <label
                    htmlFor="Address"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Adresse*:
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <input
                      type="text"
                      {...register("Address", {
                        required: {
                          value: true,
                          message: "Obligatorisk felt.",
                        },
                        pattern: {
                          value: /^[-'.A-Za-zÆØÅæøå0-9 ]*$/i,
                          message:
                            "Kun bokstaver, tall og tegnene ' . - er tillatt.",
                        },
                        maxLength: {
                          value: 220,
                          message: "Maks tegn er 220",
                        },
                      })}
                      name="Address"
                      id="Address"
                      required
                      autoComplete="Address"
                      className=" block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                    />
                    <ErrorMessage
                      errors={errors}
                      name="companyName"
                      render={({ message }) => (
                        <p className="text-sm text-red-600">{message}</p>
                      )}
                    />
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                  <label
                    htmlFor="postal-code"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Postnummer*:
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <input
                      type="text"
                      name="address1PostalCode"
                      id="address1PostalCode"
                      onChange={setPostalPlace}
                      autoComplete="postal-code"
                      className="block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                  <label
                    htmlFor="postal-place"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Poststed:
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <input
                      type="text"
                      name="address1PostalPlace"
                      id="address1PostalPlace"
                      readOnly
                      value={city}
                      className="block w-full shadow-sm bg-gray-100 focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                  <label
                    htmlFor="postal-code"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Undervisningsmetode*:
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <fieldset className="mt-4">
                      <legend className="sr-only">whyDispensation</legend>
                      <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                        {attendanceRadio.map(a => (
                          <>
                            <div key={a.id} className="flex items-center">
                              <input
                                id={a.id}
                                name="YesNo"
                                type="radio"
                                onChange={e =>
                                  setAttendance(parseInt(e.target.id))
                                }
                                defaultChecked={a.id === "1"}
                                className="focus:ring-meny-700 h-4 w-4 text-indigo-600 border-gray-300"
                              />
                              <label
                                htmlFor={a.id}
                                className="ml-3 block text-sm font-medium text-gray-700"
                              >
                                {a.title}
                              </label>
                            </div>
                          </>
                        ))}
                      </div>
                    </fieldset>
                  </div>
                </div>

                {attendance == 2 ? (
                  <div>
                    <div className="mr-10 relative flex items-start py-4">
                      <div className="min-w-0 flex-1 text-sm">
                        <p id="comments-description" className="text-gray-600">
                          Vi krever at kursdeltakerne har sitt web-kamera
                          påslått under kurset.
                        </p>
                      </div>
                      <div className="mt-1 sm:mt-0 sm:col-span-1 sm:col-start-3">
                        <Switch
                          checked={camAccept}
                          onChange={() => {
                            setCamAccept(!camAccept)
                          }}
                          id="liabilityInsurance"
                          className={classNames(
                            camAccept ? "bg-meny-600" : "bg-gray-900",
                            "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
                          )}
                        >
                          <span
                            aria-hidden="true"
                            className={classNames(
                              camAccept ? "translate-x-5" : "translate-x-0",
                              "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                            )}
                          />
                        </Switch>
                      </div>
                    </div>
                    <div className="mr-10 relative flex items-start py-4">
                      <div className="min-w-0 flex-1 text-sm">
                        <p id="comments-description" className="text-gray-600">
                          Vi har et system som kan dokumentere deltakernes
                          digital tilstedeværelse.
                        </p>
                      </div>
                      <div className="mt-1 sm:mt-0 sm:col-span-1 sm:col-start-3">
                        <Switch
                          checked={participantAccept}
                          onChange={() => {
                            setParticipantAccept(!participantAccept)
                          }}
                          id="liabilityInsurance"
                          className={classNames(
                            participantAccept ? "bg-meny-600" : "bg-gray-900",
                            "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
                          )}
                        >
                          <span
                            aria-hidden="true"
                            className={classNames(
                              participantAccept
                                ? "translate-x-5"
                                : "translate-x-0",
                              "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                            )}
                          />
                        </Switch>
                      </div>
                    </div>
                  </div>
                ) : null}

                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                  <label
                    htmlFor="Responsible"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Fagligansvarlig*:
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <input
                      type="text"
                      {...register("Responsible", {
                        required: {
                          value: true,
                          message: "Obligatorisk felt.",
                        },
                        pattern: {
                          value: /^[-A-Za-zÆØÅæøå ]+$/i,
                          message: "Kun bokstaver og bindestrek er tillatt.",
                        },
                        minLength: {
                          value: 2,
                          message: "Minimum tegn er 2",
                        },
                        maxLength: {
                          value: 30,
                          message: "Maks tegn er 30",
                        },
                      })}
                      name="Responsible"
                      id="Responsible"
                      required
                      autoComplete="Responsible"
                      className=" block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                    />
                    <ErrorMessage
                      errors={errors}
                      name="Responsible"
                      render={({ message }) => (
                        <p className="text-sm text-red-600">{message}</p>
                      )}
                    />
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                  <label
                    htmlFor="AmountHours"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Antall timer som søkes godkjent*:
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <input
                      id="AmountHours"
                      {...register("AmountHours", {
                        pattern: {
                          value: /^[0-9]+$/i,
                          message: "Kun tall er godkjent",
                        },
                        maxLength: {
                          value: 3,
                          message: "Høyeste tall tillatt er 999",
                        },
                      })}
                      required
                      name="AmountHours"
                      type="text"
                      className="block w-full shadow-sm focus:ring-meny-700 sm:text-sm focus:border-meny-500 border-gray-300 rounded-md"
                    />
                    <ErrorMessage
                      errors={errors}
                      name="AmountHours"
                      render={({ message }) => (
                        <p className="text-sm text-red-600">{message}</p>
                      )}
                    />
                  </div>
                </div>

                <div className="mr-10 relative flex items-start py-4">
                  <div className="min-w-0 flex-1 text-sm">
                    <p id="comments-description" className="text-gray-600">
                      Det bekreftes at kursets innhold er i tråd med{" "}
                      <a
                        href="https://www.psykologforeningen.no/medlem/evidensbasert-praksis/prinsipperklaering-1-om-evidensbasert-psykologisk-praksis-2"
                        id="comments-description"
                        target="_blank"
                        className="text-meny-700 hover:text-meny-600 hover:font-medium hover:cursor-pointer italic"
                      >
                        Prinsipperklæring om evidensbasert psykologisk praksis
                      </a>{" "}
                      og{" "}
                      <a
                        href="https://www.psykologforeningen.no/medlem/etikk/etiske-prinsipper-for-nordiske-psykologer"
                        id="comments-description"
                        target="_blank"
                        className="text-meny-700 hover:text-meny-600 hover:font-medium hover:cursor-pointer italic"
                      >
                        etiske prinsipper for nordiske psykologer
                      </a>
                      .
                    </p>
                  </div>
                  <div className="mt-1 sm:mt-0 sm:col-span-1 sm:col-start-3">
                    <Switch
                      checked={accept}
                      onChange={() => {
                        setAccept(!accept)
                      }}
                      id="liabilityInsurance"
                      className={classNames(
                        accept ? "bg-meny-600" : "bg-gray-900",
                        "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
                      )}
                    >
                      <span
                        aria-hidden="true"
                        className={classNames(
                          accept ? "translate-x-5" : "translate-x-0",
                          "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                        )}
                      />
                    </Switch>
                  </div>
                </div>

                <div className="mr-4 relative flex items-start py-4">
                  <div className="min-w-0 flex-1 text-sm">
                    <p
                      id="comments-description"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Kriterier for godkjenning av vedlikeholdskurs
                    </p>
                    <p
                      id="comments-description"
                      className="text-gray-700 italic"
                    >
                      (vedtatt av sentralstyret 4. februar 2010)
                    </p>
                    <p id="comments-description" className="text-gray-700 mt-2">
                      1. Kurset/konferansen må være relevant for den aktuelle
                      fordypning/spesialitet og/eller for profesjonsutøvelsen
                      som spesialist.
                    </p>
                    <p
                      id="comments-description"
                      className="text-gray-700 ml-4 mt-1"
                    >
                      a. For metodekurs skal målgruppen være psykologer, leger
                      eller samfunnsvitere med mastergrad i psykologi Innhold må
                      være i henhold til Prinsipperklæring om evidensbasert
                      psykologisk praksis og De etiske prinsipper for nordiske
                      psykologer.
                    </p>
                    <p
                      id="comments-description"
                      className="text-gray-700 ml-4 mt-1"
                    >
                      b. Når det gjelder andre kurs/konferanser skal innholdet
                      være relevant for psykologspesialistens praksis, men det
                      stilles ikke særskilte krav til deltakernes kompetanse.
                    </p>
                    <p id="comments-description" className="text-gray-700 mt-2">
                      2. Omfattende videreutdanningsprogram relevante for
                      fordypningsområdet/ spesialiteten kan godkjennes som
                      vedlikeholdsaktivitet inntil 72 timer.
                    </p>
                    <p id="comments-description" className="text-gray-700 mt-2">
                      3. Omfattende videreutdanningsprogram med begrenset
                      relevans for fordypningsområdet/spesialiteten kan
                      godkjennes med inntil 24 timer.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-8 border-t flex justify-end">
            <button
              type="submit"
              className="ml-3 mt-6 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-meny-600 hover:bg-meny-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
            >
              Opprett
            </button>
          </div>
        </form>
      ) : null}
      {showDocumentComponent ? (
        <div>
          <h4 className="text-lg text-center font-bold mt-3 ">
            Saken er opprettet, last opp dokumenter
          </h4>
          <p className="mt-2 text-sm text-gray-600"></p>
          <p className="mt-2 text-sm text-gray-600"></p>
          <div className="mt-2">
            <DocumentList
              documents={documents}
              mutationGetDocuments={mutationGetDocuments}
              canDelete={true}
            />
          </div>
          <FileUpload
            entityId={incidentId}
            entityName={"incident"}
            entityId2={null}
            entityName2={null}
            entityId3={null}
            entityName3={null}
            mutationGetDocuments={mutationGetDocuments}
            setDocuments={null}
            isRequired={true}
            isReturn={false}
          />
          <div className="pt-5">
            <div className="flex justify-center">
              <button
                type="button"
                onClick={e => PatchIncident(e)}
                className={cyanButton + " inline-flex items-center "}
              >
                Send Inn
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </MyPageLayout2>
  )
}

const ExternalCourseProvider = () => {
  const authRequest = {
    ...loginRequest,
  }
  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      <ExternalCourseProviderContent />
    </MsalAuthenticationTemplate>
  )
}

export default ExternalCourseProvider
