import { useAccount, useMsal } from "@azure/msal-react"
import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { useQuery, useQueryClient } from "react-query"
import { fetchApi, postApi } from "../../../../Utils/Api.utils"
import ReactDatePicker from "react-datepicker"
import FileUpload from "../../document/FileUpload"
import { ErrorMessage } from "@hookform/error-message"
import { toast } from "react-toastify"
import { isSpecialistMethods } from "../../../../Utils/optionSet"
import HelperText from "../../../common/text/HelperText"
import { Activity } from "../../../../Utils/Types"

const GuidanceContractEdit = props => {
  const activity: Activity = props.activity
  const queryClient = useQueryClient()
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const [activityComment, setActivityComment] = useState(activity.comment)
  const [startDate, setStartDate] = useState<Date>(activity.fromDate)
  const [selectedSupervisorSpeciality, SetSelectedSupervisorSpeciality] =
    useState(activity.supervisorSpeciality || "0")
  const [isSpecialist, setIsSpecialist] = useState<boolean>(
    activity.supervisorIsSpecialist == 292460000
  )
  const [superviserSpeciality, setSuperviserSpeciality] = useState<any[]>()
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm()

  useQuery(
    "specialitiesDataActive",
    () =>
      fetchApi(
        process.env.GATSBY_API_URL + "/Specialities/all/active",
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: data => {
        setSuperviserSpeciality(data)
      },
      onError: _error => {},
    }
  )

  const showSpeciality = e => {
    if (e == "292460000") setIsSpecialist(true)
    if (e == "292460001" || e == "292460002") {
      setIsSpecialist(false)
      SetSelectedSupervisorSpeciality("0")
    }
  }

  const OnChangeSupervisorSpeciality = e => SetSelectedSupervisorSpeciality(e)

  const patchActivity = async (data, e) => {
    data.id = activity.id
    data.fromDate = startDate
    data.comment = activityComment

    if (selectedSupervisorSpeciality != "0")
      data.supervisorSpeciality = selectedSupervisorSpeciality
    if (isSpecialist) data.supervisorIsSpecialist = 292460000
    if (!isSpecialist) data.supervisorIsSpecialist = 292460001

    if (startDate == null) {
      toast.warning("Vennligst velg Fra dato!", {
        position: "top-center",
        autoClose: 10000,
      })

      return
    }

    props.setShowLoadingData(true)
    props.mutationUpdateActivity.mutate(data)
  }

  return (
    <div>
      <form method="PATCH" onSubmit={handleSubmit(patchActivity)}>
        <div className="grid grid-cols-6 gap-6">
          <div className="col-span-6 sm:col-span-3">
            <label
              htmlFor="first-name"
              className="block text-sm font-medium text-gray-700"
            >
              Fra dato*
            </label>
            <ReactDatePicker
              id="startDate"
              locale="nb"
              showYearDropdown
              selected={Date.parse(startDate)}
              onChange={(date: Date) => setStartDate(date)}
              dateFormat="dd.MM.yyyy"
              className="block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
            />
          </div>

          <div className="col-span-6 sm:col-span-3">
            <label
              htmlFor="supervisorNo"
              className="block text-sm font-medium text-gray-700"
            >
              Veileders HPRno*
            </label>
            <input
              {...register("supervisorNo", {
                required: {
                  value: true,
                  message: "Obligatorisk felt.",
                },
                pattern: {
                  value: /^[0-9]+$/,
                  message: "Kun tall er tillatt",
                },
                minLength: {
                  value: 7,
                  message: "HPR nr kan kun være mellom 7 og 9 siffer",
                },
                maxLength: {
                  value: 9,
                  message: "HPR nr kan kun være mellom 7 og 9 siffer",
                },
              })}
              required
              type="text"
              name="supervisorNo"
              defaultValue={activity.supervisorNo}
              id="supervisorNo"
              className="mt-1 focus:ring-meny-700 focus:border-meny-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            />
            <ErrorMessage
              errors={errors}
              name="supervisorNo"
              render={({ message }) => (
                <p className="text-sm text-red-600">{message}</p>
              )}
            />
          </div>

          <div className="col-span-6 sm:col-span-3">
            <label
              htmlFor="supervisorText"
              className="block text-sm font-medium text-gray-700"
            >
              Veileders navn*
            </label>
            <input
              {...register("supervisorText", {
                required: { value: true, message: "Obligatorisk felt." },
                maxLength: { value: 100, message: "Maks tegn er 100" },
              })}
              required
              type="text"
              name="supervisorText"
              id="supervisorText"
              defaultValue={activity.supervisorText}
              className="mt-1 focus:ring-meny-700 focus:border-meny-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            />
            <ErrorMessage
              errors={errors}
              name="supervisorText"
              render={({ message }) => (
                <p className="text-sm text-red-600">{message}</p>
              )}
            />
          </div>

          <div className="col-span-6 sm:col-span-3">
            <p
              // htmlFor="employerAlternative"
              className="block text-sm italic m-2 font-normal sm:col-span-2 text-meny-700 sm:mt-px sm:pt-2 sm:col-start-3"
            ></p>

            <div>
              <label className="text-base font-medium text-gray-900">
                Er veileder spesialist?*
              </label>
              {/* <p className="text-sm leading-5 text-gray-600">Her kommer en hjelpe tekst.</p> */}
              <fieldset className="mt-4">
                <legend className="sr-only">Notification method</legend>
                <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                  {isSpecialistMethods.map(notificationMethod => (
                    <>
                      {notificationMethod.title != "Ingen" ? (
                        <div
                          key={notificationMethod.id}
                          className="flex items-center"
                        >
                          <input
                            id={notificationMethod.id}
                            name="notification-method"
                            type="radio"
                            onChange={e => showSpeciality(e.target.id)}
                            defaultChecked={
                              notificationMethod.id ===
                              activity.supervisorIsSpecialist.toString()
                            }
                            className="focus:ring-meny-700 h-4 w-4 text-indigo-600 border-gray-300"
                          />
                          <label
                            htmlFor={notificationMethod.id}
                            className="ml-3 block text-sm font-medium text-gray-700"
                          >
                            {notificationMethod.title}
                          </label>
                        </div>
                      ) : null}
                    </>
                  ))}
                </div>
              </fieldset>
            </div>
          </div>

          <div className="col-span-6">
            {isSpecialist ? (
              <>
                <label
                  htmlFor="courseProviderAccountId"
                  className="block text-sm font-medium text-gray-700"
                >
                  Veileders spesialitet
                </label>
                <select
                  id="courseProviderAccountId"
                  name="courseProviderAccountId"
                  onChange={e => OnChangeSupervisorSpeciality(e.target.value)}
                  value={selectedSupervisorSpeciality}
                  defaultValue={activity?.supervisorSpeciality}
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-meny-700 focus:border-meny-500 sm:text-sm"
                >
                  <option disabled selected value="0">
                    --velg--
                  </option>

                  {superviserSpeciality?.map(a => (
                    <option key={a.id} value={a.id}>
                      {a.name}
                    </option>
                  ))}
                </select>
              </>
            ) : null}
            <div className="">
              <HelperText helpertext="Dersom veileder ikke er spesialist/ikke er spesialist i den spesialiteten du tar/ikke oppfyller kravene til veileder for valgfritt program, må det søkes forhåndsgodkjenning for veileder. Dersom du alt har fått forhåndsgodkjenning laster du opp denne sammen med veiledningskontrakten." />
            </div>
          </div>

          <div className="col-span-6">
            <label
              htmlFor="comment"
              className="block text-sm font-medium text-gray-700"
            >
              Kommentar
            </label>
            <div className="mt-1">
              <textarea
                id="comment"
                {...register("comment", {
                  required: false,
                  maxLength: {
                    value: 2000,
                    message: "Maks antall tegn er 2000",
                  },
                })}
                onChange={e => {
                  setActivityComment(e.target.value)
                }}
                rows={3}
                className="shadow-sm focus:ring-meny-700 focus:border-meny-500 block w-full sm:text-sm border border-gray-300 rounded-md"
                placeholder="Kommentar"
                defaultValue={activity.comment}
              />
              <ErrorMessage
                errors={errors}
                name="comment"
                render={({ message }) => (
                  <p className="text-sm text-red-600">{message}</p>
                )}
              />
            </div>
            <HelperText helpertext="Kort beskrivelse av aktiviteten." />
          </div>
        </div>

        <div className="mt-8 border-t flex justify-end">
          <button
            type="submit"
            className="ml-3 mt-6 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-meny-600 hover:bg-meny-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
          >
            Oppdater
          </button>
        </div>
      </form>
    </div>
  )
}

export default GuidanceContractEdit
