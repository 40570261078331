import { InteractionType } from "@azure/msal-browser"
import {
  MsalAuthenticationTemplate,
  useAccount,
  useMsal,
} from "@azure/msal-react"
import { Link, navigate } from "gatsby"
import React, { useState } from "react"
import { useQuery } from "react-query"
import { cyanButton } from "../../../../styles/tailwindClasses"
import { fetchApi } from "../../../../Utils/Api.utils"
import { ExpenseReport } from "../../../../Utils/Entities"
import {
  RemoveUnderScore,
  ShortenInputValue,
} from "../../../../Utils/FormatHelper"
import { Loading } from "../../../../Utils/Loading"
import { ErrorComponent } from "../../../common/graphichs/ErrorComponent"
import LoadingData from "../../../common/graphichs/LoadingData"
import MyPageLayout from "../../../common/layouts/myPageLayout"
import { loginRequest } from "../../../security/authConfig"
import GetHelpText from "../../../common/text/GetHelpText"

const ExpenseReportContent = () => {
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const [reports, setReports] = useState<ExpenseReport[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const userIncidentsQuery = useQuery(
    "userExpenseReports",
    () =>
      fetchApi(
        process.env.GATSBY_API_URL + "/ExpenseReports/me",
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: data => {
        setReports(data)
        setIsLoading(false)
      },
      onError: _error => {
        setIsLoading(false)
      },
    }
  )

  const statusOrder = [
    "Påbegynt",
    "Levert",
    "Innsendt",
    "På_vent",
    "Til_utbetaling",
    "Utbetalt",
  ]

  const sortedReports = [...reports].sort((a, b) => {
    const statusComparison =
      statusOrder.indexOf(a.statusName) - statusOrder.indexOf(b.statusName)

    if (statusComparison !== 0) {
      // If the statusName is different, sort by statusName
      return statusComparison
    } else {
      // If the statusName is the same, sort by autoNumber in descending order
      return Number(b.autoNumber) - Number(a.autoNumber)
    }
  })

  return isLoading ? (
    <LoadingData />
  ) : (
    <MyPageLayout props={"Mine utlegg"}>
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="flex mb-2 justify-center">
          <GetHelpText number={1008} />
        </div>
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            {/* <h1 className="text-xl font-semibold text-gray-900">Saker</h1> */}
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <Link
              to="/app/dialog/nyttutlegg"
              className={
                cyanButton +
                " inline-flex items-center justify-center sm:w-auto "
              }
              activeClassName="active-link"
            >
              Nytt utlegg
            </Link>
          </div>
        </div>
        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              {reports.length > 0 ? (
                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-meny-700">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-meny-50 sm:pl-6 "
                        >
                          Formål med reisen/utlegget
                        </th>
                        <th
                          scope="col"
                          className="hidden md:table-cell px-3 py-3.5 text-left text-sm text-meny-50 font-semibold"
                        >
                          Referansenummer
                        </th>
                        <th
                          scope="col"
                          className="hidden md:table-cell px-3 py-3.5 text-left text-sm text-meny-50 font-semibold"
                        >
                          Beskrivelse
                        </th>
                        <th
                          scope="col"
                          className="hidden md:table-cell px-3 py-3.5 text-left text-sm text-meny-50 font-semibold"
                        >
                          Sum beløp
                        </th>
                        <th
                          scope="col"
                          className="md:table-cell px-3 py-3.5 text-left text-sm text-meny-50 font-semibold"
                        >
                          Status
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {sortedReports.map(report => (
                        <tr
                          key={report.id}
                          className=" cursor-pointer hover:text-meny-50 hover:bg-gray-200 focus:outline-none focus:ring-2"
                          onClick={e =>
                            navigate("/app/dialog/utlegg", {
                              state: {
                                id: report?.id,
                                reportInfo: report,
                              },
                            })
                          }
                        >
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                            <div className="flex items-center">
                              <div className="ml-4">
                                <div className="font-medium text-gray-900">
                                  {ShortenInputValue(report.name, 50)}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="hidden md:table-cell whitespace-nowrap px-3 py-4 text-sm text-gray-600">
                            {report.autoNumber}
                          </td>
                          <td className="hidden md:table-cell whitespace-nowrap px-3 py-4 text-sm text-gray-600">
                            <div className="text-gray-600">
                              {ShortenInputValue(report.description, 50)}
                            </div>
                          </td>
                          <td className="hidden md:table-cell whitespace-nowrap px-3 py-4 text-sm text-gray-600">
                            {report.sumAmount},-
                          </td>
                          <td className="md:table-cell whitespace-nowrap px-3 py-4 text-sm text-gray-600">
                            {/* {incident.status === 292460000 ||
                          incident.status === 2 ? (
                            <span className="inline-flex rounded-full bg-orange-100 px-2 text-xs font-semibold leading-5 text-green-800">
                              {incident.statusName}
                            </span>
                          ) : ( */}
                            <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                              {RemoveUnderScore(report.statusName)}
                            </span>
                            {/* )} */}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <p className="italic flex justify-center mt-3 max-w-md mx-auto text-base text-gray-600 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
                  Du har ingen utlegg enda.
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </MyPageLayout>
  )
}

const ExpenseReports = () => {
  const authRequest = {
    ...loginRequest,
  }
  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      <ExpenseReportContent />
    </MsalAuthenticationTemplate>
  )
}

export default ExpenseReports
