import React, { useEffect, useState } from "react"
import MyPageLayout from "../../common/layouts/myPageLayout"
import { navigate } from "gatsby"
import { loginRequest } from "../../security/authConfig"
import {
  MsalAuthenticationTemplate,
  useAccount,
  useMsal,
} from "@azure/msal-react"
import { InteractionType } from "@azure/msal-browser"
import { ErrorComponent } from "../../common/graphichs/ErrorComponent"
import { Loading } from "../../../Utils/Loading"
import Activities from "../../mypagecomponents/specialityeducation/Activities"
import ProgramDetail from "../../mypagecomponents/specialityeducation/ProgramDetail"
import MyPageLayoutEducation from "../../common/layouts/MyPageLayoutEducation"
import { whiteButton } from "../../../styles/tailwindClasses"

const SpecialityPogramContent = ({ location }) => {
  const [program, setProgram] = useState({})
  const [spesialistEducationName, setSpesialistEducationName] =
    useState("Spesialistprogram")

  useEffect(() => {
    if (location.state.selectedSpecialityEducation)
      setSpesialistEducationName(
        "Spesialistprogram: " + location.state.program.programType.name
      )

    if (location.state.program) setProgram(location.state.program)
  }, [location])

  return (
    <div>
      <MyPageLayoutEducation props={spesialistEducationName}>
        <button
          type="button"
          className={
            whiteButton +
            " inline-flex mb-4 items-center justify-center px-4 py-2 focus:ring-offset-2 sm:w-auto "
          }
          onClick={() =>
            navigate("/app/kurs/specialityeducation/utdanning")
          }
        >
          Tilbake
        </button>
        <div className="bg-white relative rounded-3xl flex justify-center py-8 px-4">
          {/* <div className="absolute inset-0" aria-hidden="true">
            <div className="absolute inset-y-0 right-0 w-1/2 bg-gray-200 rounded-3xl" />
          </div> */}
          <div className="flex flex-col space-y-2 lg:flex-row lg:space-y-0 lg:space-x-2">
            <Activities
              program={location.state.program}
              specialityEducation={location.state.selectedSpecialityEducation}
            />
            <ProgramDetail program={program} />
          </div>
        </div>
      </MyPageLayoutEducation>
    </div>
  )
}

const SpecialityPogram = ({ location }) => {
  const authRequest = {
    ...loginRequest,
  }
  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      <SpecialityPogramContent location={location} />
    </MsalAuthenticationTemplate>
  )
}

export default SpecialityPogram
