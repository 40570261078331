import {
  ArrowLeftIcon,
  ArrowRightIcon,
  CheckCircleIcon,
  ChevronRightIcon,
  MailIcon,
  ThumbUpIcon,
  TrendingDownIcon,
  TrendingUpIcon,
  UserIcon,
} from "@heroicons/react/outline"
import { useAccount, useMsal } from "@azure/msal-react"
import React, { useEffect, useState } from "react"
import { useQuery, useQueryClient } from "react-query"
import LoadingData from "../../common/graphichs/LoadingData"
import { fetchApi } from "../../../Utils/Api.utils"
import MessageRead from "./MessageRead"
import { RemoveHtml, ShortenInputValue } from "../../../Utils/FormatHelper"
import { classNames } from "../../common/graphichs/misc"

const TimeLine = ({ entityId }) => {
  const queryClient = useQueryClient()
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const [openMessageModal, setOpenMessageModal] = useState(false)
  const [openMessageData, setOpenMessageData] = useState("")

  const userMessagessQuery = useQuery(
    "userMessagesData",
    () =>
      fetchApi(
        process.env.GATSBY_API_URL + "/Messages/Regarding/" + entityId,
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: data => {},
      onError: _error => {},
    }
  )

  const openMessage = message => {
    setOpenMessageData(message)
    setOpenMessageModal(true)
  }

  return userMessagessQuery.isLoading ? (
    // // "Laster inn..."
    <LoadingData />
  ) : userMessagessQuery.isError ? (
    userMessagessQuery.error.message
  ) : (
    <div className="bg-white shadow overflow-hidden sm:rounded-md">
      <div className="bg-white shadow overflow-hidden sm:rounded-md">
        {openMessageModal && (
          <MessageRead
            message={openMessageData}
            setOpenMessageModal={setOpenMessageModal}
          />
        )}
      </div>

      <ul
        role="list"
        className="relative z-0 divide-y divide-gray-200 md:max-h-96"
      >
        {userMessagessQuery.data.map(message => (
          <li key={message.id}>
            <a
              id={message.id}
              className="block hover:bg-gray-50"
              onClick={e => openMessage(message)}
            >
              <div className="flex items-center px-4 py-4 sm:px-6 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-200">
                <div className="min-w-0 flex-1 flex items-center">
                  {message.direction ? (
                    <span
                      className={classNames(
                        "bg-green-500",
                        "h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white"
                      )}
                    >
                      <TrendingDownIcon
                        className="w-5 h-5 text-white"
                        aria-hidden="true"
                      />
                    </span>
                  ) : (
                    <span
                      className={classNames(
                        "bg-gray-500",
                        "h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white"
                      )}
                    >
                      <TrendingUpIcon
                        className="w-5 h-5 text-white"
                        aria-hidden="true"
                      />
                    </span>
                  )}
                  <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-3 md:gap-4">
                    <div>
                      {message.direction ? (
                        <p className="text-sm font-medium text-indigo-600 truncate">
                          Norsk psykologforening {message.fromName}
                        </p>
                      ) : null}
                      <p className="mt-2 flex items-center text-sm text-gray-600">
                        <MailIcon
                          className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                        <span className="truncate">{message.subject}</span>
                      </p>
                    </div>
                    <div>
                      <p className="mt-2 flex items-center text-sm text-gray-600">
                        {ShortenInputValue(message.description, 60)}
                      </p>
                    </div>
                    <div className="hidden md:block">
                      <div>
                        <p className="text-sm text-gray-900">
                          <time dateTime={message.createdOn}>
                            {new Intl.DateTimeFormat("nb-NO", {
                              dateStyle: "medium",
                              timeStyle: "short",
                            }).format(new Date(message.createdOn))}
                          </time>
                        </p>
                        <p className="mt-2 flex items-center text-sm text-gray-600">
                          {message.direction ? (
                            <div>
                              {message.isRead ? (
                                <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                                  Lest
                                </span>
                              ) : (
                                <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-green-800">
                                  Ikke lest
                                </span>
                              )}
                            </div>
                          ) : null}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default TimeLine
