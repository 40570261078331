import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react"
import { navigate } from "gatsby"
import React from "react"
import { useQuery } from "react-query"
import { fetchApi } from "../../../Utils/Api.utils"

export const BaseWithJournalRole = props => {
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const isAuthenticated = useIsAuthenticated()
  const { Component, ...rest } = props

  const today = new Date()

  const webRoleInfo = useQuery(
    "webRoleData",
    () =>
      fetchApi(
        `${process.env.GATSBY_API_URL}/Contacts/Roles`,
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      enabled: !!isAuthenticated,
      onSuccess: data => {
        if (data.length === 0 || data.find(r => r.role === 292460001)) {
          navigate(-1)
          return null
        }
      },
      onError: _error => {},
    }
  )

  const mySubInfo = useQuery(
    "mySubs",
    () =>
      fetchApi(
        `${process.env.GATSBY_API_URL}/Contacts/Subscriptions`,
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      enabled: !!isAuthenticated,
      onSuccess: data => {
        if (
          !data.find(
            item =>
              item?.subscriptionType === 778380004 &&
              (item?.endDate === undefined ||
                new Date(item?.endDate).getTime() > today.getTime())
          )
        ) {
          navigate(-1)
          return null
        }
      },
      onError: _error => {},
    }
  )
  return <Component {...rest} />
}
