import React, { Fragment, useState } from "react"
import { Popover, Transition } from "@headlessui/react"

export type HoverTextBoxType = {
  text: string
  row: string
}

const HoverTextBoxNoIcon = (props: HoverTextBoxType) => {
  const [openDropdown, setOpenDropdown] = useState(false)
  const [mouseOverButton, setMouseOverButton] = useState(false)

  const timeoutDuration = 200
  let timeoutButton

  const show = openDropdown && mouseOverButton

  const onMouseEnterButton = () => {
    clearTimeout(timeoutButton)
    setOpenDropdown(true)
    setMouseOverButton(true)
  }

  const onMouseLeaveButton = () => {
    timeoutButton = setTimeout(() => setMouseOverButton(false), timeoutDuration)
  }

  return (
    <Popover className="relative h-5">
      {({ open }) => (
        <>
          <Popover.Button
            onMouseEnter={onMouseEnterButton}
            onMouseLeave={onMouseLeaveButton}
            className={`
                ${open ? "" : "text-opacity-90"}
                `}
          >
            {props.row}
          </Popover.Button>
          <Transition
            show={show}
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute right-0 z-10  max-w-sm px-4 transform sm:px-0 lg:max-w-1xl">
              <div className="w-80">
                <div className="inline-flex flex-col space-y-2 items-start justify-start h-full">
                  <div className="w-full" />
                  <div className="inline-flex space-x-4 items-start justify-start w-full p-4 bg-white shadow rounded-lg">
                    <div className="flex space-x-3 items-start justify-start flex-1">
                      <div className="inline-flex flex-col space-y-1 items-start justify-start flex-1 pt-0.5">
                        <p className="w-full text-sm leading-tight text-gray-400 text-center">
                          {props.text}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  )
}

export default HoverTextBoxNoIcon
