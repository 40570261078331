import { useAccount, useMsal } from "@azure/msal-react"
import React, { useState } from "react"
import { useQuery } from "react-query"
import { fetchApi } from "../../../Utils/Api.utils"
import HelperText from "./HelperText"

const GetHelpText = props => {
  const [helpText, setHelpText] = useState<string>("")
  const number: number = props.number
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})

  const getHelpText = useQuery(
    "helperText",
    () =>
      fetchApi(
        process.env.GATSBY_API_URL + "/HelperText/" + number,
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: data => {
        setHelpText(data)
      },
      onError: _error => {},
    }
  )

  return (
    <div>
      <HelperText helpertext={helpText} />
    </div>
  )
}

export default GetHelpText
