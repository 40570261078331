import React from "react"
import { classNames } from "../graphichs/misc"
import { RadioGroup } from "@headlessui/react"

export const ButtonRadioGroup = ({ value, onChange, type, label }) => {
  return (
    <>
      <RadioGroup value={value} onChange={onChange} className="mt-2">
        <RadioGroup.Label className="sr-only">{label}</RadioGroup.Label>
        <div className="my-4 flex flex-col sm:flex-row gap-y-6 sm:gap-x-4">
          {type.map(option => (
            <RadioGroup.Option
              key={option.name}
              value={option.value}
              className={({ checked }) =>
                classNames(
                  checked
                    ? "text-white bg-meny-700"
                    : "bg-white text-gray-700 border-gray-300",
                  "ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md  hover:bg-meny-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
                )
              }
            >
              <RadioGroup.Label as="p">{option.name}</RadioGroup.Label>
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>
    </>
  )
}
