import {
  MsalAuthenticationTemplate,
  useAccount,
  useMsal,
} from "@azure/msal-react"
import { Link, navigate } from "gatsby"
import React, { useState } from "react"
import { useQuery, useQueryClient } from "react-query"
import LoadingData from "../../common/graphichs/LoadingData"
import MyPageLayout from "../../common/layouts/myPageLayout"
import { fetchApi } from "../../../Utils/Api.utils"
import {
  RemoveUnderScore,
  ShortenInputValue,
} from "../../../Utils/FormatHelper"
import { InteractionType } from "@azure/msal-browser"
import { loginRequest } from "../../security/authConfig"
import { Loading } from "../../../Utils/Loading"
import { ErrorComponent } from "../../common/graphichs/ErrorComponent"
import { cyanButton } from "../../../styles/tailwindClasses"

const MyIncidentsContent = () => {
  const queryClient = useQueryClient()
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const [incidents, setIncidents] = useState([])

  const userIncidentsQuery = useQuery(
    "userIncidentsData",
    () =>
      fetchApi(
        process.env.GATSBY_API_URL + "/Incidents/me",
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: data => {
        setIncidents(data)
      },
      onError: _error => {},
    }
  )

  return userIncidentsQuery.isLoading ? (
    // // "Laster inn..."
    <LoadingData />
  ) : userIncidentsQuery.isError ? (
    userIncidentsQuery.error.message
  ) : (
    <MyPageLayout props="Mine saker">
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">Saker</h1>
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <Link
              to="/app/dialog/nysak"
              className={
                cyanButton +
                " inline-flex items-center justify-center sm:w-auto "
              }
              activeClassName="active-link"
            >
              Ny sak
            </Link>
          </div>
        </div>
        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-meny-700">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-meny-50 sm:pl-6 "
                      >
                        Emne
                      </th>
                      <th
                        scope="col"
                        className="hidden md:table-cell px-3 py-3.5 text-left text-sm text-meny-50 font-semibold"
                      >
                        Type
                      </th>
                      <th
                        scope="col"
                        className="hidden md:table-cell px-3 py-3.5 text-left text-sm text-meny-50 font-semibold"
                      >
                        Beskrivelse
                      </th>
                      <th
                        scope="col"
                        className="hidden md:table-cell px-3 py-3.5 text-left text-sm text-meny-50 font-semibold"
                      >
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {incidents.map(incident => (
                      <tr
                        key={incident.id}
                        className=" cursor-pointer hover:text-meny-50 hover:bg-gray-200 focus:outline-none focus:ring-2"
                        onClick={e =>
                          navigate("/app/dialog/sak", {
                            state: {
                              incidentid: incident.id,
                              incidenttitle: incident.title,
                            },
                          })
                        }
                      >
                        <td className="whitespace-normal py-4 pl-4 pr-3 text-sm sm:pl-6">
                          <div className="flex items-center">
                            <div className="ml-4">
                              <div className="font-medium text-gray-900">
                                {ShortenInputValue(incident.title, 50)}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="hidden md:table-cell whitespace-nowrap px-3 py-4 text-sm text-gray-600">
                          {RemoveUnderScore(incident.caseTypeName)}
                        </td>
                        <td className="hidden md:table-cell whitespace-nowrap px-3 py-4 text-sm text-gray-600">
                          <div className="text-gray-600">
                            {ShortenInputValue(incident.description, 50)}
                          </div>
                        </td>
                        <td className="hidden md:table-cell whitespace-nowrap px-3 py-4 text-sm text-gray-600">
                          {/* {incident.status === 292460000 ||
                          incident.status === 2 ? (
                            <span className="inline-flex rounded-full bg-orange-100 px-2 text-xs font-semibold leading-5 text-green-800">
                              {incident.statusName}
                            </span>
                          ) : ( */}
                          <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                            {incident.stateName}
                          </span>
                          {/* )} */}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MyPageLayout>
  )
}

const MyIncidents = () => {
  const authRequest = {
    ...loginRequest,
  }
  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      <MyIncidentsContent />
    </MsalAuthenticationTemplate>
  )
}

export default MyIncidents
