import { InteractionType } from "@azure/msal-browser"
import {
  MsalAuthenticationTemplate,
  useAccount,
  useMsal,
} from "@azure/msal-react"
import { Link, navigate } from "gatsby"
import React, { useState } from "react"
import { useMutation, useQuery } from "react-query"
import { cyanButton, whiteButton } from "../../../../styles/tailwindClasses"
import { fetchApi, patchApi } from "../../../../Utils/Api.utils"
import { ExpenseReport, ExpenseReportLine } from "../../../../Utils/Entities"
import { ExpenseTypeEnum, StatusExpenseEnum } from "../../../../Utils/Enums"
import { RemoveUnderScore } from "../../../../Utils/FormatHelper"
import { Loading } from "../../../../Utils/Loading"
import { ErrorComponent } from "../../../common/graphichs/ErrorComponent"
import LoadingData from "../../../common/graphichs/LoadingData"
import { classNames } from "../../../common/graphichs/misc"
import MyPageLayout from "../../../common/layouts/myPageLayout"
import MyReportLines from "../../../mypagecomponents/expensereport/MyReportLines"
import { loginRequest } from "../../../security/authConfig"

const ReportContent = props => {
  const reportId = props?.reportId
  const reportInfo: ExpenseReport = props?.reportInfo
  const [report, setReport] = useState<ExpenseReport>()
  // console.log(report)
  const [canEdit, setCanEdit] = useState<boolean>(false)
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const goToEdit = () => {
    setIsLoading(true)
    mutationUpdateExpense.mutate()
  }

  const mutationUpdateExpense = useMutation(
    () => {
      return patchApi(
        process.env.GATSBY_API_URL + "/ExpenseReports/" + reportId,
        {
          id: reportId,
          status: StatusExpenseEnum.Påbegynt,
        },
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: data => {
        navigate("/app/dialog/endreutlegg", {
          state: {
            id: reportId,
            showSteps: true,
          },
        })
      },
      onError: _error => {
        setIsLoading(false)
        console.log(_error)
      },
    }
  )

  const reportQuery = useQuery(
    "getReport",
    () =>
      fetchApi(
        process.env.GATSBY_API_URL + "/ExpenseReports/" + reportId,
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      // enabled: reportId !== undefined,
      onSuccess: data => {
        setReport(data)
        if (
          data.status === StatusExpenseEnum.Påbegynt ||
          data.status === StatusExpenseEnum.Levert
        )
          setCanEdit(true)
        else setCanEdit(false)
        // setIsLoading(false)
      },
      onError: _error => {
        // setIsLoading(false)
      },
    }
  )

  return isLoading ? (
    <LoadingData />
  ) : (
    <MyPageLayout props="Utlegg">
      <Link
        to="/app/dialog/mineutlegg"
        className={
          whiteButton +
          " inline-flex mb-8 items-center justify-center sm:w-auto "
        }
        activeClassName="active-link"
      >
        Tilbake
      </Link>
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6 bg-meny-700">
          <h3 className="text-lg leading-6 font-medium text-white">
            Beskrivelse
          </h3>
        </div>

        <div>
          <div>
            <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
              <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-600">Status</dt>
                  {/* Godkjent */}
                  {report?.status === 292460002 ? (
                    <dd className="mt-1">
                      <span className="inline-flex rounded-full bg-green-300 px-2 text-xs font-semibold leading-5 text-black">
                        {RemoveUnderScore(report?.statusName)}
                      </span>
                    </dd>
                  ) : null}
                  {/* Utbetalt */}
                  {report?.status === 292460003 ? (
                    <dd className="mt-1">
                      <span className="inline-flex rounded-full bg-green-300 px-2 text-xs font-semibold leading-5 text-black">
                        {RemoveUnderScore(report.statusName)}
                      </span>
                    </dd>
                  ) : null}
                  {/* Til utbetaling */}
                  {report?.status === 292460004 ? (
                    <dd className="mt-1">
                      <span className="inline-flex rounded-full bg-yellow-300 px-2 text-xs font-semibold leading-5 text-black">
                        {RemoveUnderScore(report.statusName)}
                      </span>
                    </dd>
                  ) : null}
                  {/* Levert */}
                  {report?.status === 292460001 ? (
                    <dd className="mt-1">
                      <span className="inline-flex rounded-full bg-yellow-300 px-2 text-xs font-semibold leading-5 text-black">
                        {RemoveUnderScore(report.statusName)}
                      </span>
                    </dd>
                  ) : null}
                  {/* Innsendt */}
                  {report?.status === 2 ? (
                    <dd className="mt-1">
                      <span className="inline-flex rounded-full bg-yellow-300 px-2 text-xs font-semibold leading-5 text-black">
                        {RemoveUnderScore(report.statusName)}
                      </span>
                    </dd>
                  ) : null}

                  {/* Påbegynt */}
                  {report?.status === 1 ? (
                    <dd className="mt-1">
                      <span className="inline-flex rounded-full bg-blue-300 px-2 text-xs font-semibold leading-5 text-black">
                        {RemoveUnderScore(report.statusName)}
                      </span>
                    </dd>
                  ) : null}
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-600">
                    Referansenummer
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {report?.autoNumber}
                  </dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-600">
                    Formål med reisen/utlegget
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">{report?.name}</dd>
                </div>

                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-600">
                    Arrangement
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {reportInfo?.event?.name}
                  </dd>
                </div>

                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-600">Delmøte</dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {reportInfo?.session?.name}
                  </dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-600">
                    Prosjektnummer
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {reportInfo?.project?.projectNo}
                  </dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-600">Sum</dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {report?.sumAmount}
                  </dd>
                </div>
                <div className="sm:col-span-2">
                  <dt className="text-sm font-medium text-gray-600">
                    Ekstra opplysninger
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {report?.description}
                  </dd>
                </div>
              </dl>
            </div>
          </div>
          <div className="px-4 py-5 sm:px-6 ">
            <h3 className="text-lg leading-6 font-medium ">Detaljer</h3>
          </div>
          <MyReportLines reportId={reportId} canEdit={false} length={null} />
          <div className="pt-5 mb-5">
            <div className="flex justify-center">
              {canEdit ? (
                <button
                  onClick={goToEdit}
                  className={cyanButton + " inline-flex items-center mr-2 "}
                  // activeClassName="active-link"
                >
                  Endre
                </button>
              ) : null}
            </div>
          </div>
        </div>
        {/* {showDocumentComponent ? (
          <div>
            <div className="mt-2">
              <DocumentList
                documents={documents}
                mutationGetDocuments={mutationGetDocuments}
                canDelete={false}
              />
            </div>
            <h4 className="text-lg text-center font-bold mt-3 ">
              Last opp flere dokumenter
            </h4>
            <p className="mt-2 text-sm text-gray-600"></p>
            <p className="mt-2 text-sm text-gray-600"></p>
            <FileUpload
              entityId={activity?.id}
              entityName={"pp_activityspecialistprogram"}
              entityId2={null}
              entityName2={null}
              entityId3={location.state.program?.specialistEducation?.id}
              entityName3={"pp_education"}
              mutationGetDocuments={mutationGetDocuments}
              setDocuments={null}
              isRequired={false}
              isReturn={false}
            />
          </div>
        ) : null} */}
      </div>
    </MyPageLayout>
  )
}

const Report = ({ location }) => {
  const authRequest = {
    ...loginRequest,
  }
  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      <ReportContent
        reportId={location?.state?.id}
        reportInfo={location?.state?.reportInfo}
        // name={location?.state?.name}
      />
    </MsalAuthenticationTemplate>
  )
}

export default Report
