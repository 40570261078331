import { Link } from "gatsby"
import React from "react"
import { DateTimeToDate } from "../../../Utils/DateFormater"
import { Accordion } from "../../common/accordion"
import DocumentCheckInDownload from "../document/DocumentCheckInDownload"
import { Checkin, SessionRegistrationCrm } from "./EventType"

type checkinAggregatedType = {
  eventId: string
  eventName: string
  checkinid: Array<string>
  sessionRegistration?: Array<SessionRegistrationCrm>
}

const MyCheckinAccordionGroup = (props: { data: Array<Checkin> }) => {
  const eventIds: Array<string> = props.data
    .map(item => item.event as string)
    .filter((item, index, arr) => arr.indexOf(item) === index)

  const checkinAggregated: Array<checkinAggregatedType> = eventIds.map(
    eventId => {
      const currentEventCheckins = props.data.filter(
        item => item.event === eventId
      )
      const checkin: checkinAggregatedType = {
        eventId: eventId,
        eventName:
          currentEventCheckins[0].eventRegistration?.eventCrm?.name || "",
        checkinid: currentEventCheckins.map(item => item.checkinid as string),
        sessionRegistration: currentEventCheckins
          .map(item => item.sessionRegistration)
          .filter(item => item !== undefined) as Array<SessionRegistrationCrm>,
      }

      return checkin
    }
  )

  return (
    <>
      <div className="max-w-3xl mx-auto divide-y divide-gray-200 rounded-lg overflow-hidden mb-6">
        {checkinAggregated.map((item, index) => (
          <Accordion title={item.eventName} key={index}>
            <>
              {/* <p>Sessions:</p>
              <div>{item.sessionRegistration?.sessionCrm?.name || ""}</div> */}
              <SessionSection data={item.sessionRegistration || []} />
              <div className="flex flex-row py-4">
                <Link
                  to={
                    "/app/kurs/mineKurs/kursRegistrering?id=" +
                    item.checkinid[0]
                  }
                  // state={{ workId: work?.id }}
                  className="ml-1 inline-flex justify-end py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-md text-white bg-meny-700 hover:bg-meny-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
                >
                  Les mer
                </Link>
                {/* TODO: For each checkin, use file DocumentCheckInDownload.tsx, use id item.checkinid  */}
                <DocumentCheckInDownload
                  entityLogicalName="CheckinId"
                  entityId={item.checkinid}
                  buttonText="Kursbevis"
                />
              </div>
            </>
          </Accordion>
        ))}
      </div>
    </>
  )
}

const SessionSection = (props: { data: Array<SessionRegistrationCrm> }) => {
  if (props.data.length === 0) return <></>

  return (
    <>
      <>
        <div className="flex flex-grow">
          <h2 className="w-full">Kurs</h2>
          <h2>Startdato</h2>
        </div>
        <div className="w-full h-px bg-gray-200" />
      </>
      {props.data.map((item, index) => (
        <div key={index}>
          <div className="flex flex-grow">
            <div className="w-full my-1">{item.sessionCrm?.name}</div>
            <span>{DateTimeToDate(item.sessionCrm?.starttime)}</span>
          </div>
          <div className="w-full h-px bg-gray-200" />
        </div>
      ))}
    </>
  )
}

export default MyCheckinAccordionGroup
