import { InteractionType } from "@azure/msal-browser"
import {
  MsalAuthenticationTemplate,
  useAccount,
  useMsal,
} from "@azure/msal-react"
import React, { useEffect, useState } from "react"
import { fetchApi } from "../../../Utils/Api.utils"
import { Subscriptions } from "../../../Utils/Entities"
import MyPageLayout from "../../common/layouts/myPageLayout"
import HeaderText from "../../common/text/Header"
import Medlemskap from "../../mypagecomponents/membership/Membership"
import { loginRequest } from "../../security/authConfig"
import { ErrorComponent } from "../../common/graphichs/ErrorComponent"
import { Loading } from "../../../Utils/Loading"
import LiabilityInsurance from "../../mypagecomponents/membership/LiabilityInsurance"
import { Link } from "gatsby"
import { spesialistActivityTypes } from "../../../Utils/optionSet"
import { DatabaseIcon } from "@heroicons/react/solid"
import { useQuery } from "react-query"
import LoadingData from "../../common/graphichs/LoadingData"
import MyJournal from "../../mypagecomponents/journal/MyJournal"

const MyMembershipContent = () => {
  const [forsikring, setForsikring] = useState<Subscriptions>()
  const [subscriptions, setSubscriptions] = useState<Subscriptions[]>()
  const [inactiveSubs, setInactiveSubs] = useState<Subscriptions[]>([])
  const [activeSubs, setActiveSubs] = useState<Subscriptions[]>([])

  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})

  const [active, setActive] = useState<boolean>()

  const today = new Date()

  const mySubQuery = useQuery(
    "mySubs",
    () =>
      fetchApi(
        `${process.env.GATSBY_API_URL}/Contacts/Subscriptions`,
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: data => {
        let inactive = data
        let active = data
        setSubscriptions(data)
        setInactiveSubs(
          inactive.filter(
            s =>
              s?.subscriptionTypeName === "Medlemskap" &&
              s?.endDate !== undefined &&
              new Date(s?.endDate).getTime() <= today.getTime()
          )
        )
        setActiveSubs(
          active.filter(
            s =>
              s?.subscriptionTypeName === "Medlemskap" &&
              (s?.endDate === undefined ||
                new Date(s?.endDate).getTime() > today.getTime())
          )
        )
        // let active = data
        // setContact(data)
        data?.forEach(s => {
          if (
            s.subscriptionTypeName === "Ansvarsforsikring" &&
            s.parentOrganizationUnit?.id ===
              "1331728e-2078-ec11-8d21-000d3aa9614b" &&
            s.endDate === undefined
          ) {
            setForsikring(s)
          }

          if (s.endDate !== undefined) s.endDate = new Date(s.endDate)
        })
      },
      onError: _error => {},
    }
  )

  // useEffect(() => {
  //   const getSubs = () => {
  //     fetchApi(
  //       `${process.env.GATSBY_API_URL}/Contacts/Subscriptions`,
  //       account,
  //       inProgress,
  //       instance
  //     )
  //       .then(res => {
  //         setSubscriptions(res.data)
  //         res?.data?.forEach(s => {
  //           if (
  //             s.subscriptionTypeName === "Ansvarsforsikring" &&
  //             s.parentOrganizationUnit?.name === "Norsk Psykologforening" &&
  //             s.endDate === undefined
  //           ) {
  //             setForsikring(s)
  //           }

  //           if (s.endDate !== undefined) s.endDate = new Date(s.endDate)
  //         })
  //       })
  //       .catch(error => {
  //         console.log(error)
  //       })
  //   }
  //   getSubs()
  // }, [])

  return mySubQuery.isLoading ? (
    <LoadingData />
  ) : (
    <MyPageLayout props="Mine medlemskap">
      <p className="mt-3 max-w-md mx-auto text-base text-gray-600 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
        Dersom du har spørsmål vedrørende ditt medlemskap eller tidsskrift klikk{" "}
        <Link
          to="/app/dialog/nysak"
          className="text-meny-700 font-bold hover:text-meny-600"
        >
          her
        </Link>
        .
      </p>
      <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
          <HeaderText text="Aktive medlemskap" />

          {/* {subscriptions?.map(sub => {
            if (
              sub?.subscriptionTypeName === "Medlemskap" &&
              sub?.endDate === undefined
            )
              return <Medlemskap subscription={sub} forsikring={forsikring} />
            else if (
              sub?.subscriptionTypeName === "Medlemskap" &&
              new Date(sub?.endDate).getTime() > today.getTime()
            )
              return <Medlemskap subscription={sub} forsikring={forsikring} />
          })} */}
          {activeSubs?.length > 0 ? (
            <>
              {activeSubs?.map(sub => {
                return <Medlemskap subscription={sub} forsikring={forsikring} />
              })}
            </>
          ) : (
            <>
              <p className="italic flex justify-center mt-3 max-w-md mx-auto text-base text-gray-600 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
                Du har ingen aktive medlemskap.
              </p>
            </>
          )}
        </div>
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
          <HeaderText text="Inaktive medlemskap" />
          {/* {(subscriptions?.filter(
            s =>
              s.startDate === "Medlemskap" &&
              s.endDate !== undefined &&
              new Date(s.endDate).getTime() <= today.getTime()
          ))? ? (<></>) : <></>} */}
          {/* {subscriptions?.map(sub => {
            if (
              sub?.subscriptionTypeName === "Medlemskap" &&
              sub?.endDate !== undefined &&
              new Date(sub?.endDate).getTime() <= today.getTime()
            )
              return <Medlemskap subscription={sub} forsikring={null} />
          })} */}
          {inactiveSubs?.length > 0 ? (
            <>
              {inactiveSubs?.map(sub => {
                return <Medlemskap subscription={sub} forsikring={null} />
              })}
            </>
          ) : (
            <>
              <p className="italic flex justify-center mt-3 max-w-md mx-auto text-base text-gray-600 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
                Det finnes ingen oppføringer.
              </p>
            </>
          )}
        </div>
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
          <HeaderText text="Historikk ansvarsforsikring" />

          <LiabilityInsurance />
        </div>
      </div>
    </MyPageLayout>
  )
}

const MyMembership = ({ data }) => {
  const authRequest = {
    ...loginRequest,
  }
  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      <MyMembershipContent />
    </MsalAuthenticationTemplate>
  )
}

export default MyMembership
