import { useAccount, useMsal } from "@azure/msal-react"
import { navigate } from "gatsby"
import { useMutation } from "react-query"
import { toast } from "react-toastify"
import { patchApi } from "../../../Utils/Api.utils"
import useCreateIncident from "./useCreateIncident"

function useUpdateEmploymentStatusIndependent(
  eventQuery,
  clientAccountWithOrgNumber,
  incidentDescription
) {
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const timer = 2500

  const mutationCreateIncident = useCreateIncident(incidentDescription)

  const mutationUpdateEmploymentStatus = useMutation(
    professionallyActiveStatus => {
      return patchApi(
        process.env.GATSBY_API_URL + "/Contacts/me",
        {
          professionallyActiveStatus: professionallyActiveStatus,
        },
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: data => {
        //Check if incident should be created
        if (clientAccountWithOrgNumber === null) {
          mutationCreateIncident.mutate()
        } else {
          if (eventQuery?.data?.eventRegistration?.length > 0) {
            toast.success(
              "Du har en eller flere påmeldinger på kurs, husk å oppdatere fakturamottager på dine deltagelser",
              {
                position: "top-center",
                autoClose: 6000,
              }
            )
          }
          toast.success("Endringene er lagret", {
            position: "top-center",
            autoClose: 6000,
          })
          window.setTimeout(() => {
            navigate("/app/profil/arbeidsforhold")
          }, timer)
        }
      },
      onSettled: () => {},
    }
  )
  return mutationUpdateEmploymentStatus
}

export default useUpdateEmploymentStatusIndependent
