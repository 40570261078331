import React, { useState } from "react"
import { Employment, MyEmployments } from "../../../Utils/Entities"
import { fetchApi } from "../../../Utils/Api.utils"
import { useAccount, useMsal } from "@azure/msal-react"
import LoadingData from "../../common/graphichs/LoadingData"
import { useQuery } from "react-query"
import MainArbeid from "./MainArbeid"

const IncomingWork = ({ incomingMainEmployment }) => {
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})

  return (
    <>
      {incomingMainEmployment?.map((work: Employment) => {
        return (
          <div className="max-w-5xl mx-auto divide-y-2 divide-gray-200">
            <div>
              <MainArbeid work={work} />
            </div>
          </div>
        )
      })}
    </>
  )
}

export default IncomingWork
