import React from "react"
import { SingleChoiceType } from "./InputFieldsType"

const SingleChoice = (props: SingleChoiceType) => {
  const onChange = (e: any) => {
    props.control("update", e.target.value)
  }

  return (
    <div onChange={onChange} defaultValue={props.Choices[0]}>
      <span className="font-bold">
        {props.text + (props.isRequired ? "* " : " ")}{" "}
      </span>

      {props.Choices.map(item => (
        <div key={item}>
          <input
            type="radio"
            value={item}
            name={props.text + props.id}
            defaultChecked={item === props.selected}
          />
          <span> {item} </span>
        </div>
      ))}
      <div className="h-8"></div>
    </div>
  )
}

export default SingleChoice
