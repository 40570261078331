import React from "react"
import { useEffect } from "react"
import { navigate } from "gatsby"

const useEmploymentStatusModal = (
  employmentType,
  newEmploymentType,
  activeEmploymentId,
  activeEmployments,
  professionallyActiveStatus,
  setModalValues,
  setShowModal,
  setNewEmploymentType,
  mutationUpdateEmployment,
  activeEmployment,
  incomingMainEmployment,
  hasIncomingMainEmployment,
  retired
) => {
  useEffect(() => {
    //From yrkesaktiv to ikke yrkesaktiv
    if (employmentType === 292460000 && newEmploymentType === 292460001) {
      setModalValues(
        <>
          <div>
            {/* Check if user has activeEmployments */}
            {activeEmployments === true ? (
              <p className=" text-center mb-2 ">Hva ønsker du å gjøre?</p>
            ) : (
              <p className=" text-center mb-2 ">
                Ønsker du å endre fra{" "}
                <span className="font-semibold">
                  {professionallyActiveStatus.get(employmentType).toLowerCase()}
                </span>{" "}
                til{" "}
                <span className="font-semibold">
                  {professionallyActiveStatus
                    .get(newEmploymentType)
                    .toLowerCase()}
                </span>
              </p>
            )}
          </div>
          <div className="w-full flex flex-wrap flex-row mt-8 justify-evenly  ">
            {/* Become ikke yrkesaktiv with aciveEmployments */}
            {activeEmployments === true ? (
              <>
                <button
                  onClick={() => {
                    setShowModal(false)
                    setNewEmploymentType(employmentType)
                  }}
                  className=" w-fit ml-1 inline-flex justify-end py-2 px-8 border border-gray-200 shadow-sm text-md font-medium rounded-md text-black bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
                >
                  Lukk
                </button>
                <button
                  onClick={() => navigate("/app/profil/avsluttarbeidsforhold/")}
                  className="w-fit ml-1 inline-flex justify-end py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-md text-white bg-meny-700 hover:bg-meny-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
                >
                  Avslutt arbeidsforhold
                </button>
              </>
            ) : (
              <>
                {/* Become ikke yrkesaktiv without aciveEmployments */}
                <button
                  onClick={() => {
                    setShowModal(false)
                    setNewEmploymentType(employmentType)
                  }}
                  className="w-fit ml-1 inline-flex justify-end py-2 px-8 border border-gray-200 shadow-sm text-md font-medium rounded-md text-black bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
                >
                  Nei
                </button>
                <button
                  onClick={() => {
                    mutationUpdateEmployment.mutate()
                    setShowModal(false)
                  }}
                  className="w-fit ml-1 inline-flex justify-end py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-md text-white bg-meny-700 hover:bg-meny-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
                >
                  Ja
                </button>
              </>
            )}
          </div>
        </>
      )
      setShowModal(true)
    }
    //From yrkesaktiv to arbeidssøkende
    else if (employmentType === 292460000 && newEmploymentType === 292460002) {
      setModalValues(
        <>
          <div>
            {/* Check if user has activeEmloyment or hasIncomingEmployment "Hovedarbeidsgiver" */}
            {activeEmployment === true || hasIncomingMainEmployment === true ? (
              <p className=" text-center mb-2 ">Hva ønsker du å gjøre?</p>
            ) : (
              <p className=" text-center mb-2 ">
                Ønsker du å endre fra{" "}
                <span className="font-semibold">
                  {professionallyActiveStatus.get(employmentType).toLowerCase()}
                </span>{" "}
                til{" "}
                <span className="font-semibold">
                  {professionallyActiveStatus
                    .get(newEmploymentType)
                    .toLowerCase()}
                </span>
              </p>
            )}
          </div>
          <div className="w-full flex flex-wrap flex-row mt-8 justify-evenly ">
            {/* Become arbeidssøkende with acitveEmployment or hasIncomingEmployment "Hovedarbeidsgiver"  */}
            {activeEmployment === true || hasIncomingMainEmployment === true ? (
              <>
                <button
                  onClick={() => {
                    setShowModal(false)
                    setNewEmploymentType(employmentType)
                  }}
                  className=" mt-4 w-fit ml-1 inline-flex justify-end py-2 px-8 border border-gray-200 shadow-sm text-md font-medium rounded-md text-black bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
                >
                  Lukk
                </button>
                <button
                  onClick={() =>
                    navigate("/app/profil/endrearbeid/", {
                      state: {
                        workId:
                          incomingMainEmployment === undefined
                            ? activeEmploymentId
                            : incomingMainEmployment?.[0].id,
                        goToConvertToBiEmploymentPage: true,
                      },
                    })
                  }
                  className=" mt-4 w-fit ml-1 inline-flex justify-end py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-md text-white bg-meny-700 hover:bg-meny-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
                >
                  Endre til biarbeidsforhold
                </button>
                <button
                  onClick={() =>
                    navigate("/app/profil/endrearbeid/", {
                      state: {
                        workId:
                          incomingMainEmployment === undefined
                            ? activeEmploymentId
                            : incomingMainEmployment?.[0].id,
                        professionallyActiveStatus: newEmploymentType,
                      },
                    })
                  }
                  className=" mt-4 w-fit ml-1 inline-flex justify-end py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-md text-white bg-meny-700 hover:bg-meny-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
                >
                  Avslutt hovedarbeidsforhold
                </button>
              </>
            ) : (
              <>
                {/* Become arbeidssøkende */}
                <button
                  onClick={() => {
                    setShowModal(false)
                    setNewEmploymentType(employmentType)
                  }}
                  className="w-fit ml-1 inline-flex justify-end py-2 px-8 border border-gray-200 shadow-sm text-md font-medium rounded-md text-black bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
                >
                  Nei
                </button>
                <button
                  onClick={() => {
                    mutationUpdateEmployment.mutate()
                    setShowModal(false)
                  }}
                  className="w-fit ml-1 inline-flex justify-end py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-md text-white bg-meny-700 hover:bg-meny-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
                >
                  Ja
                </button>
              </>
            )}
          </div>
        </>
      )
      setShowModal(true)
    }
    //From arbeidssøkende to ikke yrkesaktiv
    else if (
      employmentType === 292460002 &&
      newEmploymentType === 292460001 &&
      activeEmployments === true
    ) {
      setModalValues(
        <>
          <div>
            <p className=" text-center mb-2 ">
              Du må avslutte dine arbeidsforhold for å gå fra{" "}
              <span className="font-semibold">arbeidssøkende</span> til{" "}
              <span className="font-semibold">ikke yrkesaktiv</span>
            </p>
          </div>
          <div className="w-full flex flex-wrap flex-row mt-8 justify-evenly space-y-4 ">
            <button
              onClick={() => {
                setShowModal(false)
                setNewEmploymentType(employmentType)
              }}
              className="mt-4 w-fit ml-1 inline-flex justify-end py-2 px-8 border border-gray-200 shadow-sm text-md font-medium rounded-md text-black bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
            >
              Lukk
            </button>
            <button
              onClick={() => navigate("/app/profil/avsluttarbeidsforhold/")}
              className="w-fit ml-1 inline-flex justify-end py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-md text-white bg-meny-700 hover:bg-meny-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
            >
              Avslutt arbeidsforhold
            </button>
          </div>
        </>
      )
      setShowModal(true)
    }
    // From ikke yrkesaktiv to arbeidssøkende or arbeidssøkende to ikke yrkesaktiv without any active work
    else if (
      (employmentType === 292460001 && newEmploymentType === 292460002) ||
      (employmentType === 292460002 &&
        newEmploymentType === 292460001 &&
        activeEmployments === false)
    ) {
      setModalValues(
        <>
          <div>
            <p className=" text-center mb-2 ">
              Ønsker du å endre fra{" "}
              <span className="font-semibold">
                {professionallyActiveStatus.get(employmentType).toLowerCase()}
              </span>{" "}
              til{" "}
              <span className="font-semibold">
                {professionallyActiveStatus
                  .get(newEmploymentType)
                  .toLowerCase()}
              </span>
            </p>
          </div>
          <div className="w-full flex flex-row justify-around mt-8">
            <button
              onClick={() => {
                setShowModal(false)
                setNewEmploymentType(employmentType)
              }}
              className="w-fit ml-1 inline-flex justify-end py-2 px-8 border border-gray-200 shadow-sm text-md font-medium rounded-md text-black bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
            >
              Nei
            </button>
            <button
              onClick={() => {
                mutationUpdateEmployment.mutate()
                setShowModal(false)
              }}
              className="w-fit ml-1 inline-flex justify-end py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-md text-white bg-meny-700 hover:bg-meny-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
            >
              Ja
            </button>
          </div>
        </>
      )
      setShowModal(true)
    }
    // As retired if you go from ikke yrkesaktiv to yrkesaktiv
    else if (
      employmentType === 292460001 &&
      newEmploymentType === 292460000 &&
      retired === true
    ) {
      setModalValues(
        <>
          <div>
            <p className=" mb-2 ">
              Ønsker du å endre fra{" "}
              <span className="font-semibold">
                {professionallyActiveStatus.get(employmentType).toLowerCase()}
              </span>{" "}
              til{" "}
              <span className="font-semibold">
                {professionallyActiveStatus
                  .get(newEmploymentType)
                  .toLowerCase()}
              </span>
            </p>
          </div>
          <div className="w-full flex flex-row justify-around mt-8">
            <button
              onClick={() => {
                setShowModal(false)
                setNewEmploymentType(employmentType)
              }}
              className="w-fit ml-1 inline-flex justify-end py-2 px-8 border border-gray-200 shadow-sm text-md font-medium rounded-md text-black bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
            >
              Nei
            </button>
            <button
              onClick={() => {
                navigate("/app/profil/nyttarbeid/", {
                  state: {
                    professionallyActiveStatus: newEmploymentType,
                  },
                })
                setShowModal(false)
              }}
              className="w-fit ml-1 inline-flex justify-end py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-md text-white bg-meny-700 hover:bg-meny-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
            >
              Ja
            </button>
          </div>
        </>
      )
      setShowModal(true)
    }
    // Become yrkesaktiv
    else if (
      employmentType !== 292460000 &&
      employmentType !== newEmploymentType
    ) {
      setModalValues(
        <>
          <div>
            <p className=" mb-2 ">
              Ønsker du å endre fra{" "}
              <span className="font-semibold">
                {professionallyActiveStatus.get(employmentType).toLowerCase()}
              </span>{" "}
              til{" "}
              <span className="font-semibold">
                {professionallyActiveStatus
                  .get(newEmploymentType)
                  .toLowerCase()}
              </span>
            </p>
          </div>
          <div className="w-full flex flex-row justify-around mt-8">
            <button
              onClick={() => {
                setShowModal(false)
                setNewEmploymentType(employmentType)
              }}
              className="w-fit ml-1 inline-flex justify-end py-2 px-8 border border-gray-200 shadow-sm text-md font-medium rounded-md text-black bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
            >
              Nei
            </button>
            <button
              onClick={() => {
                navigate("/app/profil/nyttarbeid/", {
                  state: {
                    role:
                      newEmploymentType === 292460002 ? 125600001 : 125600000,
                    professionallyActiveStatus: newEmploymentType,
                  },
                })
                setShowModal(false)
              }}
              className="w-fit ml-1 inline-flex justify-end py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-md text-white bg-meny-700 hover:bg-meny-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
            >
              Ja
            </button>
          </div>
        </>
      )
      setShowModal(true)
    }
  }, [newEmploymentType])
}

export default useEmploymentStatusModal
