import React, { useState } from "react"
import { useQuery } from "react-query"
import { fetchApiAnonymous } from "../../../../Utils/Api.utils"
import ModalWithHeader from "../../../common/modal/ModalWithHeader"
import {
  EventParticipants,
  EventRegistration,
  Pass,
  SessionType,
  SpeakerEngagement,
} from "../../../mypagecomponents/event/EventType"
import {
  EventRegistrationTypeEnum,
  StatusAttendee,
} from "../../../mypagecomponents/event/helpers/Event"
import { ParticipantsList } from "../../../mypagecomponents/event/myEvents/participantsList"
import CancelEvent from "./CancelEvent"
import CourseInstructorSection from "./CourseInstructorSection"
import DocumentsSection from "./DocumentsSection"
import EventDataSection from "./EventDataSection"
import EventDescriptionSection from "./EventDescriptionSection"
import PayerSection from "./PayerSection"
import PriceSection from "./PriceSection"
import SessionList from "./SessionList"

const EventRegistrationPage = (
  props: EventRegistration & { speakerEngagement: Array<SpeakerEngagement> }
) => {
  const [passes, setPasses] = useState<Array<Pass>>([])
  const [registrationType, setRegistrationType] =
    useState<EventRegistrationTypeEnum>()

  const [sessions, setSessions] = useState<Array<SessionType>>([])
  const [participants, setParticipants] = useState<Array<EventParticipants>>([])
  const [showModal, setShowModal] = useState<boolean>(false)

  useQuery<Array<SessionType>>(
    "session-" + props.eventCrm?.readableeventid,
    async () =>
      fetchApiAnonymous(
        process.env.GATSBY_API_URL +
          `/Events/${props.eventCrm?.readableeventid}/GetSession`
      ).then(res => res.data),
    {
      onSuccess: data => {
        setSessions(data)
      },
    }
  )

  useQuery<{ registrationType: number }>(
    ["registrationType-" + props.eventCrm?.readableeventid],
    async () =>
      fetchApiAnonymous(
        process.env.GATSBY_API_URL +
          `/Events/registrationType/${props.eventCrm?.eventtypeid}`
      ).then(res => res.data),
    {
      onSuccess: data => {
        setRegistrationType(data.registrationType)
      },
    }
  )

  useQuery<Array<Pass>>(
    ["pass-" + props.eventCrm?.readableeventid, registrationType],
    async () => {
      // if (registrationType === EventRegistrationTypeEnum.noSessions) return []
      return fetchApiAnonymous(
        process.env.GATSBY_API_URL +
          `/Events/${props.eventCrm?.readableeventid}/GetPasses`
      ).then(res => res.data)
    },
    {
      // enabled: !!registrationType,
      onSuccess: data => {
        setPasses(data)
      },
    }
  )

  useQuery<Array<EventParticipants>>(
    ["participants-" + props.eventCrm?.readableeventid],
    async () => {
      return fetchApiAnonymous(
        process.env.GATSBY_API_URL +
          `/Events/${props.eventCrm?.readableeventid}/eventParticipants`
      ).then(res => res.data)
    },
    {
      onSuccess: data => {
        setParticipants(data)
      },
    }
  )

  return (
    <>
      {showModal && (
        <ModalWithHeader
          setModalOpen={setShowModal}
          header="Deltagere"
          className="min-w-[70vh]"
        >
          <div className="max-h-[60vh] overflow-y-scroll">
            <ParticipantsList participant={participants} />
          </div>
        </ModalWithHeader>
      )}
      <div className="space-y-6">
        <EventDescriptionSection {...props.eventCrm} />
        <EventDataSection {...props} />
        <CourseInstructorSection
          speakerEngagementData={props.speakerEngagement}
          eventSpeakerEngagement={props.eventCrm?.speakerEngagementIds || []}
          sessions={sessions}
        />
        {participants &&
          participants.length > 0 &&
          props.statusattendee === StatusAttendee.Deltager && (
            <>
              <h1 className="mt-8 text-2xl font-bold text-meny-700">
                Kursdeltagere
              </h1>
              <div className="w-full h-px bg-gray-200 mb-4"></div>
              <button
                onClick={() => setShowModal(true)}
                className="w-fit ml-1 inline-flex justify-end py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-md text-white bg-meny-700 hover:bg-meny-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
              >
                Se deltagere
              </button>
            </>
          )}

        {registrationType === EventRegistrationTypeEnum.noSessions && (
          <PriceSection passes={passes}></PriceSection>
        )}
        <SessionList
          SessionRegs={props.sessionRegistrations || []}
          Sessions={sessions}
          showCancelButton={
            registrationType === EventRegistrationTypeEnum.optional
          }
        ></SessionList>
        <PayerSection {...props} canEdit />

        <DocumentsSection
          eventId={props.eventid}
          sessions={props.sessionRegistrations?.map(item => ({
            sessionId: item.sessionid || "",
            sessionName: item.sessionCrm?.name || "",
          }))}
        />
        {registrationType !== EventRegistrationTypeEnum.optional && (
          <CancelEvent {...props} />
        )}
      </div>
    </>
  )
}

export default EventRegistrationPage
