import React from "react"

const TextWithIcon = ({
  text,
  icon,
  side,
}: {
  text: string
  icon?: any
  side?: "left" | "right"
}) => {
  return <div>{text}</div>
}

export default TextWithIcon
