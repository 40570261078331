import { InteractionType } from "@azure/msal-browser"
import {
  MsalAuthenticationTemplate,
  useAccount,
  useMsal,
} from "@azure/msal-react"
import axios from "axios"
import { navigate } from "gatsby"
import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { useMutation, useQuery, useQueryClient } from "react-query"
import {
  fetchApi,
  patchApi,
  postApi,
  postGetDocumentApi,
} from "../../../../Utils/Api.utils"
import { ErrorComponent } from "../../../common/graphichs/ErrorComponent"
import MyPageLayout from "../../../common/layouts/myPageLayout"
import DocumentList from "../../../mypagecomponents/document/DocumentList"
import FileUpload from "../../../mypagecomponents/document/FileUpload"
import { loginRequest } from "../../../security/authConfig"
import { Loading } from "../../../../Utils/Loading"
import { toast } from "react-toastify"
import { cyanButton } from "../../../../styles/tailwindClasses"
import LoadingData from "../../../common/graphichs/LoadingData"
import { Switch } from "@headlessui/react"
import { classNames } from "../../../common/graphichs/misc"

const ReducedContingent = () => {
  const queryClient = useQueryClient()
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const [incidentId, setIncidentId] = useState("")
  // const [showDocumentComponent, setShowDocumentComponent] = useState(false)
  const [description, setDescription] = useState("")
  const [documents, setDocuments] = useState([])
  const [showDocumentComponent, setShowDocumentComponent] = useState(false)
  const [showCaseButtons, setShowCaseButtons] = useState(true)
  const [title, setTitle] = useState("")
  const [incidentType, setIncidentType] = useState(778380000)
  const [caseTypeCodes, setCaseTypeCodes] = useState([])
  const [showLoadingData, setShowLoadingData] = useState<boolean>(false)
  const [consentLiabality, setConsentLiabality] = useState(false)

  const userInfoQuery = useQuery(
    "userInfo",
    () =>
      fetchApi(
        process.env.GATSBY_API_URL + "/Contacts/me",
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: data => {},
      onError: _error => {},
    }
  )

  const mutationGetDocuments = useMutation(
    () => {
      return postGetDocumentApi(
        process.env.GATSBY_API_URL + "/Documents/Regarding/Get",
        {
          CaseId: incidentId,
        },
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: data => {
        setDocuments(data)
      },
      onError: _error => {},
      onSettled: () => {},
    }
  )

  const mutationUploadDocumets = useMutation(
    formData => {
      return postApi(
        process.env.GATSBY_API_URL + "/Documents",
        formData,
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: () => {},
      onError: _error => {},
      onSettled: () => {},
    }
  )

  // const mutationCreateIncident = useMutation(
  //   () => {
  //     return postApi(
  //       process.env.GATSBY_API_URL + "/Incidents",
  //       {
  //         title: "Nedsatt kontingent",
  //         description: description,
  //         caseTypeCode: 16,
  //         caseOriginCode: 3,
  //         approveStatus: 778380001,
  //         membershipCategory: "8dbbc35c-2d78-ec11-8d21-000d3aa9614b"
  //       },
  //       account,
  //       inProgress,
  //       instance
  //     ).then(res => res.data)
  //   },
  //   {
  //     onSuccess: data => {
  //       setIncidentId(data)
  //       queryClient.invalidateQueries(["userIncidentData"])
  //       navigate("/app/dialog/minesaker")
  //     },
  //     onError: _error => { },
  //     onSettled: () => { },
  //   }
  // )

  const mutationCreateIncidentWithFile = useMutation(
    () => {
      return postApi(
        process.env.GATSBY_API_URL + "/Incidents",
        {
          title: "Endringssøknad: Nedsatt kontingent",
          description: description,
          caseTypeCode: 16,
          caseOriginCode: 3,
          approveStatus: 778380001,
          ContineInsurence: !consentLiabality,
          // Emne = Nedsatt kontigent
          subjectId: "f3766da8-f49d-ec11-b400-000d3aadca3e",
          membershipCategory: "8dbbc35c-2d78-ec11-8d21-000d3aa9614b",
          membershipId: userInfoQuery.data.activeMemebershipId,
        },
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: data => {
        setIncidentId(data)

        if (documents.length > 0) {
          const formData = new FormData()
          Array.from(documents).forEach(file =>
            formData.append("arrayOfFiles", file)
          )

          formData.append("EntityId", data)
          formData.append("EntityName", "incident")

          mutationUploadDocumets.mutate(formData)
        }
        queryClient.invalidateQueries(["userIncidentData"])

        queryClient.invalidateQueries(["userIncidentData"])
        // setShowDocumentComponent(true)
        setShowCaseButtons(false)
        navigate("/app/dialog/minesaker")
      },
      onError: _error => {
        setShowLoadingData(false)
      },
      onSettled: () => {},
    }
  )

  const mutationUpdateIncident = useMutation(
    () => {
      return patchApi(
        process.env.GATSBY_API_URL + "/Incidents/" + incidentId,
        {
          id: incidentId,
          title: title + ": " + account?.name,
          description: description,
          caseTypeCode: 16,
          caseOriginCode: 3,
          approveStatus: 778380001,
        },
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["userIncidentData"])
        navigate("/app/dialog/minesaker")
      },
      onError: _error => {},
      onSettled: () => {},
    }
  )

  // const postIncident = async e => {
  //   e.preventDefault()
  //   mutationCreateIncident.mutate()
  // }

  const postIncidentWithFile = async e => {
    e.preventDefault()
    // setIncidentStatus(292460000)
    if (documents.length == 0) {
      toast.warning("Vennligst last opp dokumenter!", {
        position: "top-center",
        autoClose: 10000,
      })
      return
    }
    setShowLoadingData(true)
    mutationCreateIncidentWithFile.mutate()
  }

  return showLoadingData ? (
    <LoadingData subject="Oppretter..." />
  ) : userInfoQuery.isLoading ? (
    <LoadingData />
  ) : (
    <MyPageLayout props="Søknad om nedsatt Kontingent">
      {/* {!showDocumentComponent ? ( */}

      <form method="POST" onSubmit={postIncidentWithFile}>
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
          <label
            htmlFor="about"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Årsak til nedsatt kontingent
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <textarea
              id="comment"
              name="comment"
              rows={3}
              placeholder="Skriv gjerne årsaken til nedsatt kontingent"
              onChange={e => setDescription(e.target.value)}
              className="max-w-lg shadow-sm block w-full focus:ring-meny-700 focus:border-meny-500 sm:text-sm border border-gray-300 rounded-md"
            />
            {/* <p className="mt-2 text-sm text-gray-600">
              Skriv gjerne årsaken til nedsatt kontingent
            </p> */}
          </div>
        </div>

        <div className="space-y-6 sm:space-y-5">
          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
            <h4 className="mt-4 font-medium text-meny-700">
              Ansvarsforsikring
            </h4>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              <p className="italic text-gray-900 ">
                Ved innmelding er du dekket av en kollektiv ansvarsforsikring.
                Forsikringspremien kommer i tillegg til kontingenten, og betales
                på egen giro/faktura.
              </p>
              <div className="mr-10 relative flex items-start py-4">
                <div className="min-w-0 flex-1 text-sm">
                  <p id="comments-description" className="text-gray-600">
                    Jeg har lest vilkårene, men ønsker å reservere meg fra
                    ansvarsforsikringen.
                  </p>
                </div>
                <div className="mt-1 sm:mt-0 sm:col-span-1 sm:col-start-3">
                  <Switch
                    checked={consentLiabality}
                    onChange={setConsentLiabality}
                    id="liabilityInsurance"
                    className={classNames(
                      consentLiabality ? "bg-meny-600" : "bg-gray-900",
                      "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
                    )}
                  >
                    <span
                      aria-hidden="true"
                      className={classNames(
                        consentLiabality ? "translate-x-5" : "translate-x-0",
                        "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                      )}
                    />
                  </Switch>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <p className="mt-6 text-sm text-gray-600">
            Vennligst dokumenter din inntektssituasjon
          </p>
          <FileUpload
            entityId={incidentId}
            entityName={"incident"}
            entityId2={null}
            entityName2={null}
            entityId3={null}
            entityName3={null}
            mutationGetDocuments={mutationGetDocuments}
            setDocuments={setDocuments}
            isReturn={true}
            isRequired={true}
          />
          {/* <div className="pt-5">
          <div className="flex justify-center">
            <button
              type="button"
              onClick={e => postIncidentWithFile(e)}
              className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
            >
              Send inn
            </button>
          </div>
        </div> */}
        </div>

        {showCaseButtons ? (
          <div className="pt-5">
            <div className="flex justify-end">
              <button type="submit" className={cyanButton}>
                Send inn
              </button>
              {/* <button
                type="button"
                onClick={e => setShowDocumentComponent(true)}
                className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
              >
                Last opp dokumenter
              </button> */}
            </div>
          </div>
        ) : null}
      </form>
      {/* {showDocumentComponent ? ( */}

      {/* ) : null} */}

      {/* )} */}
    </MyPageLayout>
  )
}

const Contingent = () => {
  const authRequest = {
    ...loginRequest,
  }
  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      <ReducedContingent />
    </MsalAuthenticationTemplate>
  )
}

export default Contingent
