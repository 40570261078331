import React from "react"
import Footer from "../footers/Footer"
import NavBarSignedIn from "../navbar/NavBarSignedIn"
import { Helmet } from "react-helmet"

function MainLayout({ props, children }) {
  return (
    <>
      <div>
        <Helmet title={props} />
        <NavBarSignedIn />
        <div className="bg-gray-50">
          <header className="py-10">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <h1 className="text-3xl text-center font-bold text-meny-700">
                {props}
              </h1>
            </div>
          </header>
        </div>
        <div className="min-h-screen">{children}</div>
        <Footer />
      </div>
    </>
  )
}

export default MainLayout
