import { useAccount, useMsal } from "@azure/msal-react"
import React, { useEffect, useState } from "react"
import { useQuery, useQueryClient } from "react-query"
import { fetchApi } from "../../../../Utils/Api.utils"
import { DateTimeToNorwegian } from "../../../../Utils/DateFormater"
import { RemoveUnderScore } from "../../../../Utils/FormatHelper"
import DocumentList from "../../document/DocumentList"

const GuidanceCertificateDetail = ({
  activity,
  program,
  documents,
  mutationGetDocuments,
}) => {
  const queryClient = useQueryClient()
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const [isSpecialist, setIsSpecialist] = useState("Nei")
  const [superviserSpeciality, setSuperviserSpeciality] = useState("")

  useQuery(
    "specialitiesDataActive",
    () =>
      fetchApi(
        process.env.GATSBY_API_URL + "/Specialities/all/active",
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      enabled: !!activity?.supervisorSpeciality,
      onSuccess: data => {
        const supervisorSpecialityObj = data.find(
          r => r.id === activity.supervisorSpeciality
        )
        setSuperviserSpeciality(supervisorSpecialityObj.name)
      },
      onError: _error => {},
    }
  )

  useEffect(() => {
    if (activity?.supervisorIsSpecialist === 292460000) setIsSpecialist("Ja")
  }, [activity])

  return (
    <div>
      <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          {/* <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-600">Navn</dt>
            <dd className="mt-1 text-sm text-gray-900">{activity.name}</dd>
          </div> */}
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-600">Status</dt>
            {/* Godkjent */}
            {activity.approveStatus === 778380000 ? (
              <dd className="mt-1">
                <span className="inline-flex rounded-full bg-green-300 px-2 text-xs font-semibold leading-5 text-black">
                  {RemoveUnderScore(activity.approveStatusName)}
                </span>
              </dd>
            ) : null}
            {/* Til godkjenning */}
            {activity.approveStatus === 778380001 ? (
              <dd className="mt-1">
                <span className="inline-flex rounded-full bg-yellow-300 px-2 text-xs font-semibold leading-5 text-black">
                  {RemoveUnderScore(activity.approveStatusName)}
                </span>
              </dd>
            ) : null}
            {/* Avslag */}
            {activity.approveStatus === 778380002 ? (
              <dd className="mt-1 ">
                <span className="inline-flex rounded-full bg-red-300 px-2 text-xs font-semibold leading-5 text-black">
                  {RemoveUnderScore(activity.approveStatusName)}
                </span>
              </dd>
            ) : null}
            {/* Påbegynt */}
            {activity.approveStatus === 778380003 ? (
              <dd className="mt-1">
                <span className="inline-flex rounded-full bg-blue-300 px-2 text-xs font-semibold leading-5 text-black">
                  {RemoveUnderScore(activity.approveStatusName)}
                </span>
              </dd>
            ) : null}

            {/* Ikke fullført registrering */}
            {activity.approveStatus === 292460000 ? (
              <dd className="mt-1">
                <span className="inline-flex rounded-full bg-orange-300 px-2 text-xs font-semibold leading-5 text-black">
                  {RemoveUnderScore(activity.approveStatusName)}
                </span>
              </dd>
            ) : null}
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-600">Programnavn</dt>
            <dd className="mt-1 text-sm text-gray-900">
              {program.programType?.name}
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-600">Fra dato</dt>
            <dd className="mt-1 text-sm text-gray-900">
              {DateTimeToNorwegian(activity.fromDate)}
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-600">Til dato</dt>
            <dd className="mt-1 text-sm text-gray-900">
              {DateTimeToNorwegian(activity.toDate)}
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-600">
              Veileders HPRno
            </dt>
            <dd className="mt-1 text-sm text-gray-900">
              {activity.supervisorNo}
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-600">
              Veileders navn
            </dt>
            <dd className="mt-1 text-sm text-gray-900">
              {activity.supervisorText}
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-600">
              Er veileder spesialist?
            </dt>
            <dd className="mt-1 text-sm text-gray-900">{isSpecialist}</dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-600">
              Veileders spesialitet
            </dt>
            <dd className="mt-1 text-sm text-gray-900">
              {superviserSpeciality}
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-600">
              Digital gruppeveiledning (t)
            </dt>
            <dd className="mt-1 text-sm text-gray-900">
              {activity.hoursDigitalGroupGuideance}
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-600">
              Fysisk gruppeveiledning (t)
            </dt>
            <dd className="mt-1 text-sm text-gray-900">
              {activity.hoursPhysicalGroupGuideance}
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-600">
              Digital individuell veiledning (t)
            </dt>
            <dd className="mt-1 text-sm text-gray-900">
              {activity.hoursDigitalIdividualGuideance}
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-600">
              Fysisk individuell veiledning (t)
            </dt>
            <dd className="mt-1 text-sm text-gray-900">
              {activity.hoursPhysicalidIvidualGuideance}
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-600">
              Antall direkte observasjon
            </dt>
            <dd className="mt-1 text-sm text-gray-900">
              {activity.directObservation}
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-600">
              Sum veiledning
            </dt>
            <dd className="mt-1 text-sm text-gray-900">
              {activity.hoursGuidence}
            </dd>
          </div>
          <div className="sm:col-span-2">
            <dt className="text-sm font-medium text-gray-600">Kommentar</dt>
            <dd className="mt-1 text-sm text-gray-900">{activity.comment}</dd>
          </div>
          {/* <div className="sm:col-span-2">
            <dt className="text-sm font-medium text-gray-600">Vedlegg</dt>
            <dd className="mt-1 text-sm text-gray-900">
              <DocumentList
                documents={documents}
                mutationGetDocuments={mutationGetDocuments}
                canDelete={false}
              />
            </dd>
          </div> */}
        </dl>
      </div>
    </div>
  )
}

export default GuidanceCertificateDetail
