import React from "react"
import { SmallListLoading } from "../../../common/loading"
import useDocuments from "../../../hooks/useDocuments/useDocuments"
import DocumentList from "../../../mypagecomponents/document/DocumentList"
import { Accordion } from "../../../common/accordion"
import DocumentListSessions from "../../../mypagecomponents/document/DocumentListSessions"

export type DocumentsSectionType = {
  eventId?: string
  sessions?: Array<{
    sessionId: string
    sessionName: string
  }>
}
const DocumentsSection = (props: DocumentsSectionType) => {
  const { documents, loading, mutate } = useDocuments({
    id: { EventId: props.eventId },
  })

  return (
    <div>
      <h2 className="mt-8 text-2xl font-bold text-meny-700">
        Kursmateriell (Arrangement/Kurs)
      </h2>

      {loading && (
        <div className="pb-4">
          <SmallListLoading />
        </div>
      )}
      <DocumentList
        documents={documents}
        mutationGetDocuments={mutate}
        canDelete={false}
      />

      <br />

      {props.sessions?.map(item => {
        return (
          <div key={item.sessionId}>
            <Accordion title={item.sessionName} subTitle="">
              <DocumentListSessions sessionId={item.sessionId} />
            </Accordion>
          </div>
        )
      })}
    </div>
  )
}

export default DocumentsSection
