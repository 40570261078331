import { useAccount, useMsal } from "@azure/msal-react"
import { CloudDownloadIcon, PaperClipIcon } from "@heroicons/react/outline"
import React, { useEffect, useState } from "react"
import { useMutation, useQueryClient } from "react-query"
import { deleteApi, fetchDownLoadFileApi, postGetDocumentApi } from "../../../Utils/Api.utils"

const DocumentCheckInDownload = ({ entityLogicalName, entityId, buttonText }) => {
    const { instance, accounts, inProgress } = useMsal()
    const account = useAccount(accounts[0] || {})
    const [documentToDoanLoad, setDocument] = useState(null)
    let documentName = "FileName"

    const mutationGetDocument = useMutation(
        () => {
            return postGetDocumentApi(
                process.env.GATSBY_API_URL + "/Documents/Regarding/Get",
                {
                    CheckinId: entityId,
                },
                account,
                inProgress,
                instance
            ).then(res => res.data)
        },
        {
            onSuccess: data => {
                if (data.length == 1) setDocument(data[0])
            },
            onError: _error => { },
            onSettled: () => { },
        }
    )

    useEffect(() => {
        mutationGetDocument.mutate()
    }, [])

    const mutationDownload = useMutation(
        e => {
            documentName = e.title
            return fetchDownLoadFileApi(
                process.env.GATSBY_API_URL + "/Documents/Download/" + e.blobId,
                account,
                inProgress,
                instance
            ).then(res => res.data)
        },
        {
            onSuccess: blob => {
                // Create blob link to download
                const url = window.URL.createObjectURL(new Blob([blob]))
                const link = document.createElement("a")
                link.href = url
                link.setAttribute("download", documentName)

                // Append to html link element page
                document.body.appendChild(link)

                // Start download
                link.click()

                // Clean up and remove the link
                link.parentNode.removeChild(link)
            },
            onError: _error => { },
            onSettled: () => { },
        }
    )

    const onFileDownLoad = e => mutationDownload.mutate(e)

    return (
        <>
            {documentToDoanLoad != null ? (
                <div className="ml-4 flex-shrink-0 flex space-x-4">
                    <button
                        type="button"
                        id={documentToDoanLoad.blobId}
                        name={documentToDoanLoad.title}
                        onClick={e => onFileDownLoad(documentToDoanLoad)}
                        className="transparent rounded-md font-medium text-green-600 hover:text-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth={2}
                        >

                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10"
                            />
                        </svg>
                        {buttonText}
                    </button>
                </div>
            ) : null}
        </>
    )
}

export default DocumentCheckInDownload
