import { useAccount, useMsal } from "@azure/msal-react"
import { RadioGroup, Switch } from "@headlessui/react"
import { ErrorMessage } from "@hookform/error-message"
import { Link, navigate } from "gatsby"
import React, { useContext, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { toast } from "react-toastify"
import {
  fetchApi,
  fetchApiAnonymous,
  fetchApiStandard,
  patchApi,
  postApi,
} from "../../../Utils/Api.utils"
import { Consents, Contact, Countries } from "../../../Utils/Entities"
import { genders, MemberType, MemberTypes } from "../../../Utils/optionSet"
import { FormValues } from "../../../Utils/Types"
import LoadingData from "../../common/graphichs/LoadingData"
import { classNames } from "../../common/graphichs/misc"
import validator from "@navikt/fnrvalidator"
import {
  changeMembershipTab,
  changeMembershipTabToNext,
  dnummer,
  hideMembershipTab,
  resetMembershipTab,
} from "./helpers"
import { NewMembershipContext } from "../../mypage/membership/NewMember"

const ProfileInfo = ({ setTabId, setMemberParent, memberTypeParent }) => {
  const queryClient = useQueryClient()
  const [membershipNextTabId, setMembershipNextTabId] = useState(0)
  const [contact, setContact] = useState<Contact>()
  const [consentMemberCondition, setConsentMemberCondition] =
    useState<Consents>()
  const [countries, setCountries] = useState<Countries[]>([])
  const [defaultCountryCode, setDefaultCountryCode] = useState<string>("NO")
  const [norwegian, setNorwegian] = useState<boolean>(false)
  const [city, setCity] = useState<string>()
  const [radio, setRadio] = useState<boolean>(false)
  const [zipCode, setZipCode] = useState(null)
  const [zipCode2, setZipCode2] = useState(null)
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const [memberTypes, setMemberTypes] = useState(memberTypeParent)
  const [consentLiabality, setConsentLiabality] = useState(false)
  const [consent, setConsent] = useState(false)
  const [disableButton, setDisabled] = useState<boolean>(false)

  const [norwegianError, setNorwegianError] = useState<boolean>(false)
  const [utlandError, setUtlandError] = useState<boolean>(false)
  const [foreignPostalCode, setForeignPostalCode] = useState<string>()

  const {
    register,
    handleSubmit,
    reset,
    setError,
    clearErrors,
    resetField,
    getValues,
    formState: { errors, isDirty, isValid },
  } = useForm<FormValues>()

  useEffect(() => {
    changeMembershipTabToNext(1, setMembershipNextTabId)
  }, [])

  const OnMemberTypeChange = e => {
    setMemberTypes(e)
    setMemberParent(e)

    changeMembershipTabToNext(e.value, setMembershipNextTabId)

    if (e.value == 1) {
      resetMembershipTab()
    }

    if (e.value == 2) {
      resetMembershipTab()
      hideMembershipTab(2)
      hideMembershipTab(1)
    }
    if (e.value == 0) {
      resetMembershipTab()
      hideMembershipTab(2)
    }
  }

  const fieldDisabled = event => {
    if (event.target.checked) {
      resetField("socialSecurityNumber")
      setRadio(true)
    } else {
      setRadio(false)
    }
  }

  const allConsentsQuery = useQuery(
    "consents",
    () =>
      fetchApi(
        process.env.GATSBY_API_URL + "/Consents",
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: data => {
        const consentMemberCondition = data.filter(x =>
          x.name.includes("Medlemsbetingelser")
        )

        if (consentMemberCondition?.length == 1)
          setConsentMemberCondition(consentMemberCondition[0])
      },
      onError: _error => {},
    }
  )

  const userZipCodeQuery = useQuery(
    "userZipCode",
    () =>
      fetchApiStandard(
        process.env.GATSBY_API_URL + "/Addresses/PostalCodes/" + zipCode,
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      enabled: !!zipCode,
      onSuccess: data => {
        setCity(data.postalPlace)
      },
      onError: _error => {
        setZipCode(null)
        toast.warning("Vennligst tast inn et gyldig postnr!", {
          position: "top-center",
          autoClose: 10000,
        })
      },
    }
  )

  const userZipCodeQuery2 = useQuery(
    "userZipCode2",
    () =>
      fetchApiStandard(
        process.env.GATSBY_API_URL + "/Addresses/PostalCodes/" + zipCode,
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      enabled: !!zipCode2,
      onSuccess: data => {
        setZipCode2(null)
        if (norwegian) {
          setNorwegian(false)
          setCity(data.postalPlace)
          setNorwegian(true)
        }
        // if (!norwegian) setCity(data.postalPlace)
      },
      onError: _error => {
        setZipCode2(null)
        toast.warning("Vennligst tast inn et gyldig postnr!", {
          position: "top-center",
          autoClose: 10000,
        })
      },
    }
  )

  const userInfoQuery = useQuery(
    "userInfo",
    () =>
      fetchApi(
        process.env.GATSBY_API_URL + "/Contacts/me",
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: data => {
        setContact(data)
        reset(data)
        if (data.address1CountryCode === "NO") {
          setNorwegian(true)
          setZipCode(data.address1PostalCode)
          setDefaultCountryCode(data.address1CountryCode)
        } else if (
          data.address1CountryCode == null ||
          data.address1CountryCode == undefined
        ) {
          setNorwegian(true)
          setDefaultCountryCode("NO")
        } else {
          setNorwegian(false)
          setCity(data.address1City)
          setDefaultCountryCode(data.address1CountryCode)
        }
      },
      onError: _error => {},
    }
  )

  const countriesQuery = useQuery(
    "countries",
    () =>
      fetchApi(
        process.env.GATSBY_API_URL + "/Addresses/Countries",
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: data => {
        setCountries(data)
      },
      onError: _error => {},
    }
  )

  const index: number = countries?.findIndex(
    c => c.countryName === contact?.address1CountryName
  )

  const isNorwegian = event => {
    if (event.target.value === "NO") {
      resetField("address1PostalCode")

      clearErrors("address1PostalCode")
      setNorwegian(true)
      if (getValues("address1PostalCode").length !== 4) setNorwegianError(true)
    } else {
      resetField("address1PostalCode")
      clearErrors("address1PostalCode")
      setNorwegian(false)
    }
  }

  const setPostalPlace = event => {
    if (event.target.value.length === 4) {
      setZipCode(event.target.value)
      setZipCode2(event.target.value)
      // queryClient.invalidateQueries(["userZipCode"])
      // userZipCodeDataQuery.refetch(event.target.value)
      setForeignPostalCode("")
      setNorwegianError(false)
    } else setNorwegianError(true)
  }

  const validateForeignPostal = event => {
    if (event.target.value.length > 20) {
      setZipCode(null)
      setZipCode2(null)
      setForeignPostalCode("")
      setUtlandError(true)
    } else {
      setUtlandError(false)
      setZipCode(null)
      // setZipCode2(null)
      // setZipCode(event.target.value)
      setForeignPostalCode(event.target.value)
    }
  }

  const mutationUpdateConsents = useMutation(
    data => {
      return postApi(
        `${process.env.GATSBY_API_URL}/Consents`,
        data,
        account,
        inProgress,
        instance
      )
    },
    {
      onSuccess: data => {},
      onError: _error => {},
      onSettled: () => {},
    }
  )

  const mutationUpdateContact = useMutation(
    data => {
      return patchApi(
        process.env.GATSBY_API_URL + "/Contacts/me",
        data,
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: data => {
        queryClient.invalidateQueries(["userInfo"])
        changeMembershipTab(membershipNextTabId)
        setTabId(membershipNextTabId)
      },
      onError: _error => {},
      onSettled: () => {},
    }
  )

  const patchContact = async (data, event) => {
    event.preventDefault()
    if (membershipNextTabId == 0)
      changeMembershipTabToNext(1, setMembershipNextTabId)

    if (!radio) {
      const fnr = validator.fnr(data.socialSecurityNumber)
      const dnr = validator.dnr(data.socialSecurityNumber)

      if (dnr.status === "invalid" || fnr.status === "invalid") {
        setError("socialSecurityNumber", {
          type: "custom",
          message: "Personnummeret er ikke gyldig!",
        })
        toast.warning("Personnummeret er ikke gyldig!", {
          position: "top-center",
          autoClose: 10000,
        })
        return
      }
    }

    data.missingNationalID = radio

    if (!consent) {
      setError("consent", {
        type: "custom",
        message: "Du må samtykke for å gå videre!",
      })
      return
    }

    if (norwegian) {
      data.address1City = ""
      data.address1PostalCode = zipCode
    } else {
      data.address1PostalCode = foreignPostalCode
    }

    data.lastLoginOnWeb = ""

    const consentData = {
      consentGiven: consent,
      // Behandle samtykke
      gdprRequestType: 778380001,
      // Portal
      gdprRequestOrigin: 778380003,
      consentStatementId: consentMemberCondition?.id,
    }

    mutationUpdateConsents.mutate(consentData)
    setDisabled(true)
    mutationUpdateContact.mutate(data)
  }

  const LiabilityInsuranceChecked = () => {}

  return userInfoQuery.isLoading ? (
    // // "Laster inn..."
    <LoadingData />
  ) : userInfoQuery.isError ? (
    userInfoQuery.error.message
  ) : (
    <div>
      <form
        method="PATCH"
        onSubmit={handleSubmit(patchContact)}
        className="space-y-8 "
      >
        <div className="space-y-8 sm:space-y-5">
          <div className="space-y-6sm:space-y-5">
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-baseline">
              <div>
                <div
                  className="text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700"
                  id="label-notifications"
                >
                  Medlemskapstype:
                </div>
              </div>
              <div className="sm:col-span-2">
                <div className="max-w-lg">
                  <p className="text-sm text-gray-600">
                    Jeg ønsker å melde inn meg som:
                  </p>
                  <RadioGroup
                    value={memberTypes}
                    onChange={OnMemberTypeChange}
                    className="mt-2"
                  >
                    <RadioGroup.Label className="sr-only">
                      Velg en medlemskapstype
                    </RadioGroup.Label>
                    <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
                      {MemberTypes.map(option => (
                        <RadioGroup.Option
                          key={option.name}
                          value={option}
                          className={({ active, checked }) =>
                            classNames(
                              checked
                                ? "border-meny-500 ring-2 ring-meny-500"
                                : "border-gray-900 outline",
                              active
                                ? "border-meny-500 ring-2 ring-meny-500"
                                : "",
                              "relative bg-white border rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none"
                            )
                          }
                        >
                          <RadioGroup.Label as="p">
                            {option.name}
                          </RadioGroup.Label>
                        </RadioGroup.Option>
                      ))}
                    </div>
                  </RadioGroup>
                </div>
              </div>
            </div>

            <div>
              <h3 className="text-lg leading-6 font-medium text-meny-700">
                Personlig Informasjon
              </h3>
              <p className="text-sm text-gray-600">
                Felter markert med * er obligatoriske
              </p>
            </div>
            <div className="space-y-6 sm:space-y-5">
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                <label
                  htmlFor="first-name"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Fornavn*:
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    type="text"
                    {...register("firstName", {
                      required: {
                        value: true,
                        message: "Obligatorisk felt.",
                      },
                      pattern: {
                        value: /^[-A-Za-zÆØÅæøå ]+$/i,
                        message: "Kun bokstaver og bindestrek er tillatt.",
                      },
                      minLength: {
                        value: 2,
                        message: "Minimum tegn er 2",
                      },
                      maxLength: {
                        value: 30,
                        message: "Maks tegn er 30",
                      },
                    })}
                    name="firstName"
                    id="firstName"
                    required
                    autoComplete="given-name"
                    defaultValue={contact?.firstName}
                    className="block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                  />
                  <ErrorMessage
                    errors={errors}
                    name="firstName"
                    render={({ message }) => (
                      <p className="text-sm text-red-600">{message}</p>
                    )}
                  />
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                <label
                  htmlFor="last-name"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Etternavn*:
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    type="text"
                    {...register("lastName", {
                      required: {
                        value: true,
                        message: "Obligatorisk felt.",
                      },
                      pattern: {
                        value: /^[-A-Za-zÆØÅæøå ]+$/i,
                        message: "Kun bokstaver og bindestrek er tillatt.",
                      },
                      minLength: {
                        value: 2,
                        message: "Minimum tegn er 2",
                      },
                      maxLength: {
                        value: 30,
                        message: "Maks tegn er 30",
                      },
                    })}
                    name="lastName"
                    id="lastName"
                    required
                    autoComplete="family-name"
                    defaultValue={contact?.lastName}
                    className=" block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                  />
                  <ErrorMessage
                    errors={errors}
                    name="lastName"
                    render={({ message }) => (
                      <p className="text-sm text-red-600">{message}</p>
                    )}
                  />
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Epostadresse*:
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    id="emailAddress1"
                    {...register("emailAddress1", {
                      required: {
                        value: true,
                        message: "Obligatorisk felt.",
                      },
                      pattern: {
                        value:
                          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))+$/i,
                        message: "Feil epostformat",
                      },
                      maxLength: {
                        value: 320,
                        message: "Maks tegn er 320",
                      },
                    })}
                    name="emailAddress1"
                    type="text"
                    required
                    autoComplete="email"
                    defaultValue={contact?.emailAddress1}
                    className="block w-full shadow-sm focus:ring-meny-700 sm:text-sm focus:border-meny-500 border-gray-300 rounded-md"
                  />
                  <ErrorMessage
                    errors={errors}
                    name="emailAddress1"
                    render={({ message }) => (
                      <p className="text-sm text-red-600">{message}</p>
                    )}
                  />
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                <label
                  htmlFor="phone-number"
                  className="block text-sm font-medium text-gray-700"
                >
                  Mobil*:
                </label>
                <div className="mt-1 relative rounded-md shadow-sm">
                  <div className="absolute inset-y-0 left-0 flex items-center"></div>
                  <input
                    type="text"
                    name="phone-number"
                    id="phone-number"
                    className="block w-full shadow-sm bg-gray-200 focus:ring-meny-700 sm:text-sm focus:border-meny-500 border-gray-300 rounded-md"
                    readOnly
                    value={contact?.mobilePhone}
                  />
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                <label
                  htmlFor="Dnumber"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Fødselsnummer/d-nummer*:
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  {!radio ? (
                    <input
                      id="socialSecurityNumber"
                      {...register("socialSecurityNumber", {
                        required: {
                          value: true,
                          message: "Obligatorisk felt.",
                        },
                        pattern: {
                          value: /^[0-9]{11,11}$/,
                          message: "Kun 11 tall er tillat",
                        },
                        maxLength: {
                          value: 11,
                          message: "Personnummeret er feil.",
                        },
                      })}
                      defaultValue={contact?.socialSecurityNumber}
                      name="socialSecurityNumber"
                      type="text"
                      autoComplete="idnumber"
                      className="block w-full shadow-sm focus:ring-meny-700 sm:text-sm focus:border-meny-500 border-gray-300 rounded-md"
                      readOnly={radio}
                    />
                  ) : null}

                  <ErrorMessage
                    errors={errors}
                    name="socialSecurityNumber"
                    render={({ message }) => (
                      <p className="text-sm text-red-600">{message}</p>
                    )}
                  />

                  <div className="space-y-5">
                    {dnummer.map(plan => (
                      <div key={plan.id} className="relative flex items-start">
                        <div className="flex items-center h-5">
                          <input
                            id={plan.id}
                            aria-describedby={`${plan.id}-description`}
                            name="plan"
                            type="checkbox"
                            className="focus:ring-meny-700 h-4 w-4 text-meny-600 border-gray-300 rounded"
                            onChange={fieldDisabled}
                          />
                        </div>
                        <div className="ml-3 text-sm">
                          <label
                            htmlFor={plan.id}
                            className="font-medium text-gray-700"
                          >
                            {plan.name}
                          </label>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                <label
                  htmlFor="gender"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Kjønn*:
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <select
                    {...register("gender")}
                    name="gender"
                    id="gender"
                    key="gender"
                    required
                    defaultValue={contact?.gender}
                    className=" block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                  >
                    <option value={genders[0].value}>{genders[0].name}</option>
                    <option value={genders[1].value}>{genders[1].name}</option>
                    <option value={genders[2].value}>{genders[2].name}</option>
                  </select>
                </div>
              </div>

              <div>
                {memberTypes.value !== 0 ? (
                  <div className="pt-6 sm:pt-5">
                    <div role="group" aria-labelledby="label-notifications">
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-baseline">
                        <div>
                          {/* <label htmlFor="contactinfo"className="text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700 ">   Kontakt Informasjon</label> */}
                          <div
                            className="text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700 "
                            id="HPR"
                          >
                            HPR nr*:
                          </div>
                        </div>
                        <input
                          {...register("hprNumber", {
                            required: {
                              value: true,
                              message: "Obligatorisk felt.",
                            },
                            pattern: {
                              value: /^[0-9]+$/,
                              message: "Kun tall er tillatt",
                            },
                            minLength: {
                              value: 7,
                              message:
                                "HPR nr kan kun være mellom 7 og 9 siffer",
                            },
                            maxLength: {
                              value: 9,
                              message:
                                "HPR nr kan kun være mellom 7 og 9 siffer",
                            },
                          })}
                          type="text"
                          name="hprNumber"
                          id="hprNumber"
                          required
                          className=" block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                          placeholder="9 siffer"
                        />
                        <ErrorMessage
                          errors={errors}
                          name="hprNumber"
                          render={({ message }) => (
                            <p className="text-sm text-red-600">{message}</p>
                          )}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>

              {/* {index > -1 ? ( */}
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                <label
                  htmlFor="address1CountryCode"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Land*:
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <select
                    {...register("address1CountryCode")}
                    name="address1CountryCode"
                    id="address1CountryCode"
                    key="address1CountryCode"
                    // defaultValue={defaultCountryCode}
                    onChange={isNorwegian}
                    required
                    className=" block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                  >
                    {countries.map(country => (
                      <option
                        selected={country.countryCode === defaultCountryCode}
                        key={country.countryCode}
                        value={country.countryCode}
                      >
                        {country.countryName}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              {/* ) : null} */}

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                <label
                  htmlFor="street-address"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  C/O:
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    type="text"
                    {...register("address1Line1", {
                      pattern: {
                        value: /^[-'.A-Za-zÆØÅæøå0-9 ]*$/i,
                        message:
                          "Kun bokstaver, tall og tegnene ' . - er tillatt.",
                      },
                      maxLength: {
                        value: 220,
                        message: "Maks tegn er 220",
                      },
                    })}
                    name="address1Line1"
                    id="address1Line1"
                    autoComplete="street-address"
                    defaultValue={contact?.address1Line1}
                    className="block w-full shadow-sm sm:text-sm focus:ring-meny-700 focus:border-meny-500 border-gray-300 rounded-md"
                  />
                  <ErrorMessage
                    errors={errors}
                    name="address1Line1"
                    render={({ message }) => (
                      <p className="text-sm text-red-600">{message}</p>
                    )}
                  />
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                <label
                  htmlFor="street-address"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Adresse*:
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    type="text"
                    {...register("address1Line2", {
                      required: {
                        value: true,
                        message: "Obligatorisk felt.",
                      },
                      pattern: {
                        value: /^[-'.A-Za-zÆØÅæøå0-9 ]*$/i,
                        message:
                          "Kun bokstaver, tall og tegnene ' . - er tillatt.",
                      },
                      maxLength: {
                        value: 220,
                        message: "Maks tegn er 220",
                      },
                    })}
                    name="address1Line2"
                    id="address1Line2"
                    required
                    autoComplete="street-address"
                    defaultValue={contact?.address1Line2}
                    className="block w-full shadow-sm sm:text-sm focus:ring-meny-700 focus:border-meny-500 border-gray-300 rounded-md"
                  />
                  <ErrorMessage
                    errors={errors}
                    name="address1Line2"
                    render={({ message }) => (
                      <p className="text-sm text-red-600">{message}</p>
                    )}
                  />
                </div>
              </div>

              {norwegian ? null : (
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                  <label
                    htmlFor="street-address"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Adresse 2*:
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <input
                      type="text"
                      {...register("address1Line3", {
                        required: {
                          value: true,
                          message: "Obligatorisk felt.",
                        },
                        maxLength: {
                          value: 220,
                          message: "Maks tegn er 220",
                        },
                      })}
                      name="address1Line3"
                      id="address1Line3"
                      autoComplete="street-address"
                      defaultValue={contact?.address1Line3}
                      className="block w-full shadow-sm sm:text-sm focus:ring-meny-700 focus:border-meny-500 border-gray-300 rounded-md"
                    />
                    <ErrorMessage
                      errors={errors}
                      name="address1Line3"
                      render={({ message }) => (
                        <p className="text-sm text-red-600">{message}</p>
                      )}
                    />
                  </div>
                </div>
              )}

              {norwegian ? (
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                  <label
                    htmlFor="postal-code"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Postnummer*:
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <input
                      type="text"
                      // {...register("address1PostalCode", {
                      //   required: {
                      //   value: true,
                      //   message: "Obligatorisk felt.",
                      // },
                      //   pattern: {
                      //     value: /^[0-9]{4}$/i,
                      //     message:
                      //       "Norsk postnummer kan kun inneholde 4 siffer.",
                      //   },
                      // })}
                      name="address1PostalCode"
                      id="address1PostalCode"
                      defaultValue={contact?.address1PostalCode}
                      onChange={setPostalPlace}
                      required={norwegian}
                      autoComplete="postal-code"
                      className="block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                    />
                    <div>
                      {norwegianError ? (
                        <p className="text-sm text-red-600">
                          Norsk postnummer kan kun bestå av fire siffer
                        </p>
                      ) : (
                        <></>
                      )}{" "}
                    </div>
                    {/* <ErrorMessage
                      errors={errors}
                      name="address1PostalCode"
                      render={({ message }) => (
                        <p className="text-sm text-red-600">{message}</p>
                      )}
                    /> */}
                  </div>
                </div>
              ) : (
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                  <label
                    htmlFor="postal-code"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Postnummer*:
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <input
                      type="text"
                      // {...register("address1PostalCode", {
                      //   maxLength: {
                      //     value: 20,
                      //     message:
                      //       "Utenlandsk postnummer kan ikke inneholde mer enn 20 tegn",
                      //   },
                      // })}
                      name="address1PostalCode"
                      id="address1PostalCode"
                      required={!norwegian}
                      defaultValue={contact?.address1PostalCode}
                      onChange={validateForeignPostal}
                      autoComplete="postal-code"
                      className="block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                    />
                    <div>
                      {utlandError ? (
                        <p className="text-sm text-red-600">
                          Utenlandsk postnummer kan inneholde maksimalt 20 tegn
                        </p>
                      ) : (
                        <></>
                      )}{" "}
                    </div>
                    {/* <ErrorMessage
                      errors={errors}
                      name="address1PostalCode2"
                      render={({ message }) => (
                        <p className="text-sm text-red-600">{message}</p>
                      )}
                    /> */}
                  </div>
                </div>
              )}

              {!norwegian ? (
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                  <label
                    htmlFor="city"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    By (utenlandsk):
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <input
                      {...register("address1City", {
                        pattern: {
                          value: /^[-'.A-Za-zÆØÅæøå0-9 ]*$/i,
                          message:
                            "Kun bokstaver, tall og tegnene ' . - er tillatt.",
                        },
                        maxLength: {
                          value: 220,
                          message: "Maks tegn er 220",
                        },
                      })}
                      type="text"
                      name="address1City"
                      id="address1City"
                      defaultValue={city}
                      required={!norwegian}
                      autoComplete="address-level2"
                      className="block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                    />
                    <ErrorMessage
                      errors={errors}
                      name="address1City"
                      render={({ message }) => (
                        <p className="text-sm text-red-600">{message}</p>
                      )}
                    />
                  </div>
                </div>
              ) : (
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                  <label
                    htmlFor="city"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Poststed*:
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <input
                      type="text"
                      readOnly={norwegian}
                      name="address1City"
                      id="address1City"
                      defaultValue={city}
                      value={city}
                      // required={norwegian}
                      autoComplete="address-level2"
                      className={classNames(
                        norwegian ? "bg-gray-200" : "",
                        "block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                      )}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="address1City"
                      render={({ message }) => (
                        <p className="text-sm text-red-600">{message}</p>
                      )}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="space-y-6 sm:space-y-5">
            {memberTypes.value !== 0 ? (
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                <h4 className="mt-4 font-medium text-meny-700">
                  Ansvarsforsikring
                </h4>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <p className="italic text-gray-900 ">
                    Ved innmelding er du dekket av en kollektiv
                    ansvarsforsikring. Forsikringspremien kommer i tillegg til
                    kontingenten, og betales på egen giro/faktura.
                  </p>
                  <div className="mr-10 relative flex items-start py-4">
                    <div className="min-w-0 flex-1 text-sm">
                      <p id="comments-description" className="text-gray-600">
                        Jeg har lest{" "}
                        <Link
                          className="font-medium text-meny-700"
                          to="https://www.storebrand.no/site/akademikerne.nsf/profesjon-psykologforeningen/psykologforeningen"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          vilkårene
                        </Link>
                        , men ønsker å reservere meg fra ansvarsforsikringen.
                      </p>
                    </div>
                    <div className="mt-1 sm:mt-0 sm:col-span-1 sm:col-start-3">
                      <Switch
                        checked={consentLiabality}
                        onChange={setConsentLiabality}
                        id="liabilityInsurance"
                        className={classNames(
                          consentLiabality ? "bg-meny-600" : "bg-gray-900",
                          "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
                        )}
                      >
                        <span
                          aria-hidden="true"
                          className={classNames(
                            consentLiabality
                              ? "translate-x-5"
                              : "translate-x-0",
                            "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                          )}
                        />
                      </Switch>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>

          <div className="space-y-6 sm:space-y-5">
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
              <h4 className="mt-4 font-medium text-meny-700">Samtykke*</h4>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <p className="italic text-gray-900 ">
                  {consentMemberCondition?.name}
                </p>
                <div className="mr-10 relative flex items-start py-4">
                  <div className="min-w-0 flex-1 text-sm">
                    <p id="comments-description" className="text-gray-600">
                      {consentMemberCondition?.description}
                    </p>
                  </div>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <Switch
                      checked={consent}
                      onChange={setConsent}
                      className={classNames(
                        consent ? "bg-meny-600" : "bg-gray-900",
                        "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
                      )}
                    >
                      <span
                        aria-hidden="true"
                        className={classNames(
                          consent ? "translate-x-5" : "translate-x-0",
                          "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                        )}
                      />
                    </Switch>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div {...register("consent")} className="pt-5 flex justify-end">
            <ErrorMessage
              errors={errors}
              name="consent"
              render={({ message }) => (
                <p className="text-sm text-red-600">{message}</p>
              )}
            />
            <button
              type="submit"
              disabled={disableButton}
              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-meny-700 hover:bg-meny-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
            >
              Neste
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default ProfileInfo
