import React from 'react'
import MyPageLayout from '../../../common/layouts/myPageLayout'
import HeaderText from '../../../common/text/Header'
import { loginRequest } from '../../../security/authConfig';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { Loading } from '../../../../Utils/Loading';
import { ErrorComponent } from '../../../common/graphichs/ErrorComponent';



const BenefitsContent = () => {
  return (
    <MyPageLayout props="Medlemskap">
      {/* <HeaderText text="Medlemskap"/> */}
      <div>
        <img src={"https://www.psykologforeningen.no/var/psykol/storage/images/media/images/medlemskap13/662451-1-nor-NO/medlemskap1_size-large.jpg"} />
        <br />
        <h1>Norsk psykologforening er Norges eneste profesjonsforening for autoriserte psykologer i Norge.
          Som medlem hos oss får du en rekke faglige og økonomiske fordeler.
          Ordinært medlemskap i Norsk psykologforening er betinget av godkjenning som psykolog i Norge iflg.
          <br />
          <br />
          Lov om Helsepersonell av 2. juli 1999 nr. 64.
          <br />
          <br />
          Godkjenning foretas av:&nbsp;
          <a href="https://www.helsedirektoratet.no/tema/autorisasjon-og-spesialistutdanning" className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600">
            Helsedirektoratet, Divisjon for kompetanse og personell.
          </a>

        </h1>
        <br />

        <div>
          <HeaderText text="Fordelsavtaler" />
          <br />
          <h2>
            Som medlem i Norsk Psykologforening kan du benytte deg av avtalene til Akademikerne Pluss (A+) Akademikerne Pluss eies av tolv fagforeninger med til sammen over 200.000 medlemmer.
            Vi jobber for at du som medlem skal få de beste fordelene og tilbudene.
            Dette sikrer gode vilkår for deg. Akademikerne Pluss forhandler bank- og forsikringsavtaler samt andre fordeler.
          </h2>
        </div>
        <br />
        <div>
          <HeaderText text="I tillegg til fordelsavtalene hos Akademikerne pluss, tilbyr vi  " />
          <br />

          <ul>
            <li>&bull;&nbsp; Redusert pris på kursene i spesialistutdanningen</li>
            <li>&bull;&nbsp; Redusert pris på andre kurs og konferanser</li>
            <li>&bull;&nbsp; Juridisk rådgivning innen lønn- og arbeidsvilkår, helserett og annen relevant lovgivning</li>
            <li>&bull;&nbsp; Bistand ved lønnsforhandlinger</li>
            <li>&bull;&nbsp; Veiledning og hjelp gjennom kollegastøtteordningen</li>
            <li>&bull;&nbsp; Mulighet til å registrere seg på vår søketjeneste Finn en psykolog , hvor publikum kan finne psykologer over hele landet.</li>
            <li>&bull;&nbsp; Invitasjon til faglige og fagpolitiske møter i lokalavdelingene og interesseforeningene</li>
            <li>&bull;&nbsp; Fond til videre- og etterutdanning</li>
            <li>&bull;&nbsp; Lederstipend</li>
            <li>&bull;&nbsp; Tidsskrift for Norsk psykologforening i posten hver måned</li>
            <li>&bull;&nbsp; Visittkort/timekort</li>
            <li>&bull;&nbsp; Stempler</li>
          </ul>
          <br />
          <p>Har du tips til nye medlemsfordeler? Send gjerne en mail til:&nbsp; <a href="mailto:medlemsservice@psykologforeningen.no" className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600">medlemsservice@psykologforeningen.no</a></p>
        </div>




      </div>
    </MyPageLayout>
  )
}

const MembershipBenefits = () => {
  const authRequest = {
    ...loginRequest
  }
  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      <BenefitsContent />
    </MsalAuthenticationTemplate>
  )
}


export default MembershipBenefits
