import { useAccount, useMsal } from "@azure/msal-react"
import { Link, navigate } from "gatsby"
import React, { useEffect, useState } from "react"
import { useMutation, useQueryClient } from "react-query"
import { RemoveUnderScore } from "../../../Utils/FormatHelper"
import {
  PlusSmIcon as PlusSmIconOutline,
  TrendingUpIcon,
} from "@heroicons/react/outline"
import { patchApi, postApi } from "../../../Utils/Api.utils"
import ReactDatePicker from "react-datepicker"
import { toast } from "react-toastify"
import HelperText from "../../common/text/HelperText"
import { cyanButton } from "../../../styles/tailwindClasses"

const Program = ({
  SelectedSpecialityEducation,
  myPrograms,
  programid,
  name,
  canCreate,
  setCanCreatePrograms
}) => {
  const queryClient = useQueryClient()
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const [program, setProgram] = useState({})
  const [newProgramId, setNewProgramId] = useState("")
  const [startProgramDate, setStartProgramDate] = useState(null)
  const [disableCreateProgram, setDisableCreateProgram] = useState(false)

  useEffect(() => {
    const myProgram = myPrograms?.filter(x => x.programType?.id === programid)
    if (myProgram?.length > 0) {
      setProgram(myProgram[0])
    }
    if (myProgram?.length === 0) setProgram({})
    if (
      myProgram?.length > 0 &&
      programid === "c0c4d26e-c5a6-ec11-983f-000d3aae55a4"
    ) {
      //Vedlikehold
      myProgram.forEach(element => {
        if (element?.approveStatus !== 778380000) {
          // 778380000 = godkjent
          setProgram(element)
        }
      })
    }
  }, [myPrograms])

  const mutationCreateProgram = useMutation(
    data => {
      return postApi(
        process.env.GATSBY_API_URL + "/SpecialistPrograms",
        {
          programTypeId: data.programTypeId,
          educationId: data.educationId,
          approveStatus: data.approveStatus,
          fromDate: data.fromDate,
        },
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: data => {
        queryClient.invalidateQueries([
          "userProgramsData-" + SelectedSpecialityEducation.id,
        ])
        setNewProgramId(data)
      },
      onError: _error => { },
      onSettled: () => {
        setDisableCreateProgram(false)
        setCanCreatePrograms(false)
      },
    }
  )

  const onCreateProgram = e => {
    let data = {
      programTypeId: programid,
      educationId: SelectedSpecialityEducation.id,
      approveStatus: 778380003, //Pågår
    }

    const newDate = new Date()
    if (programid == "ee9bb462-c5a6-ec11-983f-000d3aae55a4") {
      //obligatorisk program
      data = {
        programTypeId: programid,
        educationId: SelectedSpecialityEducation.id,
        approveStatus: 778380003, //Pågår
        fromDate: newDate,
      }
      setStartProgramDate(newDate)
    }

    if (
      startProgramDate == null &&
      programid !== "ee9bb462-c5a6-ec11-983f-000d3aae55a4"
    ) {
      //obligatorisk program
      toast.warning("Vennligst fyll ut startdato!", {
        position: "top-center",
        autoClose: 10000,
      })
      return
    }

    if (
      programid === "ee9bb462-c5a6-ec11-983f-000d3aae55a4" &&
      SelectedSpecialityEducation.speciality === undefined
    ) {
      //Obligatorisk program
      toast.warning("Vennligst velg en spesialitet!", {
        position: "top-center",
        autoClose: 10000,
      })
      return
    }

    setDisableCreateProgram(true)
    mutationCreateProgram.mutate(data)
  }

  return (
    <div>
      <div
        key={programid}
        className=" border border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200"
      >
        <div className="p-6">
          <h2 className="text-lg leading-6 font-medium text-gray-900">
            {name}
          </h2>
          <p className="mt-4 text-sm text-gray-600">
            Startdato:{" "}
            {program?.fromDate !== undefined ? (
              <time dateTime={program.fromDate}>
                {new Intl.DateTimeFormat("nb-NO", {
                  dateStyle: "medium",
                  // timeStyle: "short",
                }).format(new Date(program.fromDate))}
              </time>
            ) : null}
          </p>
          <p className="mt-4 text-sm text-gray-600">
            Sluttdato:{" "}
            {program?.toDate !== undefined ? (
              <time dateTime={program.toDate}>
                {new Intl.DateTimeFormat("nb-NO", {
                  dateStyle: "medium",
                  // timeStyle: "short",
                }).format(new Date(program.toDate))}
              </time>
            ) : null}
          </p>
          <p className="mt-8">
            <span className="text-base font-medium text-gray-600">
              {program?.approveStatus === 778380003 ? (
                <span className="inline-flex rounded-full bg-blue-300 px-2 text-xs font-semibold leading-5 text-black">
                  {RemoveUnderScore(program?.approveStatusName)}
                </span>
              ) : null}
              {program?.approveStatus === 778380000 ? (
                <span className="inline-flex rounded-full bg-green-300 px-2 text-xs font-semibold leading-5 text-black">
                  {RemoveUnderScore(program?.approveStatusName)}
                </span>
              ) : null}
              {program?.approveStatus === 778380001 ? (
                <span className="inline-flex rounded-full bg-yellow-300 px-2 text-xs font-semibold leading-5 text-black">
                  {RemoveUnderScore(program?.approveStatusName)}
                </span>
              ) : null}
              {program?.approveStatus === 778380002 ? (
                <span className="inline-flex rounded-full bg-red-300 px-2 text-xs font-semibold leading-5 text-black">
                  {RemoveUnderScore(program?.approveStatusName)}
                </span>
              ) : null}
            </span>
          </p>
          {Object.keys(program).length > 0 ? (
            <Link
              to="/app/kurs/specialityeducation/program"
              state={{
                program: program,
                selectedSpecialityEducation: program?.specialistEducation,
              }}
              className={
                cyanButton +
                " mt-8 block w-full  py-2 text-sm font-semibold text-center "
              }
              activeClassName="active-link"
            >
              Detaljer
            </Link>
          ) : null}
        </div>
        <div className="pt-6 pb-8 px-6">
          <>
            {canCreate &&
              Object.keys(SelectedSpecialityEducation).length !== 0 &&
              Object.keys(program).length === 0 &&
              programid === "e542c068-c5a6-ec11-983f-000d3aae55a4" ? ( //Valgfritt program
              <HelperText
                helpertext="Valgfritt program opprettes når du melder deg på kurs i valgfritt program.
                                For å få opprettet valgfritt program dersom du deltar på kurs eksternt,
                                ta kontakt med oss ved å sende inn en ny sak med type 'Spesialistutdanning' og med informasjon om hvilket valgfritt
                                program du skal ta."
              />
            ) : null}
            {/* {canCreate &&
              Object.keys(SelectedSpecialityEducation).length !== 0 &&
              Object.keys(program).length === 0 &&
              programid === "ee9bb462-c5a6-ec11-983f-000d3aae55a4" ? ( //Obligatorisk program
              <HelperText
                helpertext="Obligatorisk program opprettes når du melder deg på kurs i obligatorisk program.
                                For å få opprettet obligatorisk program dersom du deltar på kurs eksternt,
                                ta kontakt med oss ved å sende inn en ny sak med type 'Spesialistutdanning' og med informasjon om hvilket obligatorisk
                                program du skal ta."
              />
            ) : null} */}
          </>
          <div className="text-sm font-medium text-gray-900 tracking-wide uppercase">
            {canCreate &&
              Object.keys(SelectedSpecialityEducation).length !== 0 &&
              Object.keys(program).length === 0 ? (
              <>
                {programid === "e542c068-c5a6-ec11-983f-000d3aae55a4" || (programid === "ee9bb462-c5a6-ec11-983f-000d3aae55a4" && SelectedSpecialityEducation?.approveStatus === 778380001) ? ( //Valgfritt program
                  // {programid === "e542c068-c5a6-ec11-983f-000d3aae55a4" || programid === "ee9bb462-c5a6-ec11-983f-000d3aae55a4" ? ( //Valgfritt program || Obligatorisk
                  <div></div>
                ) : (
                  <div>
                    <button
                      type="button"
                      disabled={disableCreateProgram}
                      onClick={e => onCreateProgram(e)}
                      className={
                        cyanButton + " inline-flex font-bold items-center"
                      }
                    >
                      <PlusSmIconOutline
                        className="h-6 w-6"
                        aria-hidden="true"
                      />
                      Start program
                    </button>
                    {/* {programid !== "ee9bb462-c5a6-ec11-983f-000d3aae55a4" ? ( //Obligatorisk program
                      <div className="sm:grid sm:grid-cols-1 sm:gap-4 sm:items-start  sm:pt-5">
                        <label
                          htmlFor="startDate"
                          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                        >
                          Velg startdato:
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <ReactDatePicker
                            id="startDate"
                            locale="nb"
                            showYearDropdown
                            selected={startProgramDate}
                            onChange={(date: Date) => setStartProgramDate(date)}
                            dateFormat="dd.MM.yyyy"
                            className="block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>
                    ) : null} */}
                  </div>
                )}
              </>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Program
