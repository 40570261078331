import React, { useContext, useEffect, useState } from "react"
import { Contact } from "../../../../Utils/Entities"
import { EventRegistrationTypeEnum } from "../helpers/Event"
import { InnmeldingContext } from "./dataStorage"

const Oppsummering = () => {
  const [contact, setContact] = useState<Contact | undefined>(undefined)

  const InnmeldingData = useContext(InnmeldingContext)
  if (!InnmeldingData) return <div></div>

  useEffect(() => {
    setContact(InnmeldingData.contact[0])
  }, [InnmeldingData.contact[0]])

  return (
    <div>
      <h2>Oppsummering</h2>
      <h3 className="mt-3">Kontakt Info</h3>
      <div className="w-full h-px bg-gray-200 mb-4"></div>
      <LabelData
        label="Navn"
        data={(contact?.firstName || "") + " " + (contact?.lastName || "")}
      />
      <LabelData label="Mobil" data={contact?.mobilePhone || ""} />
      <LabelData label="Epost" data={contact?.emailAddress1 || ""} />
      <LabelData
        label="Arbeidsgiver"
        data={InnmeldingData.workplace?.name || ""}
      />
      <KursOppsummering />
      <BetalingOppsummerings />
    </div>
  )
}

const LabelData = (props: { label: string; data: string }) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2">
      <h5 className=" mr-4">{props.label}</h5>
      <label className="text-gray-700 mr-4">{props.data}</label>
    </div>
  )
}

const KursOppsummering = () => {
  const InnmeldingData = useContext(InnmeldingContext)
  if (!InnmeldingData) return <div></div>

  const [data, setData] = useState<Array<{ label: string; data: string }>>([])

  useEffect(() => {
    if (
      InnmeldingData.registrationType[0] ===
      EventRegistrationTypeEnum.noSessions
    ) {
    } else if (
      InnmeldingData.registrationType[0] ===
      EventRegistrationTypeEnum.requireAll
    ) {
      setData(
        InnmeldingData.sessionQuery?.map((session, index) => {
          return {
            label: index === 0 ? "Kurs" : "",
            data: session.name || "",
          }
        }) || []
      )
    } else {
      setData(
        InnmeldingData.selectedSessions[0]?.map((session, index) => {
          return {
            label: index === 0 ? "Kurs" : "",
            // data: session.toString() || "",
            data:
              InnmeldingData.sessionQuery?.find(
                item => item.id === session.toString()
              )?.name || "",
          }
        }) || []
      )
    }
  }, [])

  return (
    <div>
      <h3 className="mt-3">Kurs</h3>
      <div className="w-full h-px bg-gray-200 mb-4"></div>
      <LabelData
        label="Kurs"
        data={InnmeldingData.eventQuery?.eventName || ""}
      />
      {data.map((e, i) => (
        <LabelData key={i} label={e.label} data={e.data} />
      ))}
    </div>
  )
}

const BetalingOppsummerings = () => {
  const InnmeldingData = useContext(InnmeldingContext)
  if (!InnmeldingData) return <div></div>

  const [data, setData] = useState<Array<{ label: string; data: string }>>([])

  useEffect(() => {
    if (InnmeldingData.payerType[0] === "Privat") {
      setData([{ label: "Hvem skal betale", data: "Betale selv" }])
    }
    if (InnmeldingData.payerType[0] === "Arbeidsgiver") {
      setData([
        { label: "Hvem skal betale", data: "Arbeidsgiver" },
        {
          label: "Arbeidsgiver",
          data: InnmeldingData.workplace?.name || "",
        },
        {
          label: "Fakturaref. 1 / PO",
          data:
            InnmeldingData.companyPayerData[0].find(e => e.id === "invoiceRef1")
              ?.payload || "",
        },
        {
          label: "Fakturaref. 2/ Kontaktperson",
          data:
            InnmeldingData.companyPayerData[0].find(e => e.id === "invoiceRef2")
              ?.payload || "",
        },
      ])
    }
    if (InnmeldingData.payerType[0] === "Annen") {
      setData([
        { label: "Hvem skal betale", data: "Annen" },
        {
          label: "Navn",
          data:
            InnmeldingData.otherPayerData[0].find(e => e.id === "name")
              ?.payload || "",
        },
        {
          label: "Org.nr",
          data:
            InnmeldingData.otherPayerData[0].find(e => e.id === "orgNr")
              ?.payload || "",
        },
        {
          label: "Fakturaadr",
          data:
            InnmeldingData.otherPayerData[0].find(e => e.id === "invoiceAdr")
              ?.payload || "",
        },
        {
          label: "Fakturaref. 1 / PO",
          data:
            InnmeldingData.otherPayerData[0].find(e => e.id === "invoiceRef1")
              ?.payload || "",
        },
        {
          label: "Fakturaref. 2",
          data:
            InnmeldingData.otherPayerData[0].find(e => e.id === "invoiceRef2")
              ?.payload || "",
        },
      ])
    }
  }, [
    InnmeldingData.otherPayerData,
    InnmeldingData.payerType,
    InnmeldingData.selectedWorkplace,
  ])

  return (
    <div>
      <h3 className="mt-3">Betaling</h3>
      <div className="w-full h-px bg-gray-200 mb-4"></div>
      {data.map((e, i) => (
        <LabelData key={i} label={e.label} data={e.data} />
      ))}
    </div>
  )
}

export default Oppsummering
