import { useAccount, useMsal } from "@azure/msal-react"
import { useEffect, useState } from "react"
import { useMutation } from "react-query"
import { postGetDocumentApi } from "../../../Utils/Api.utils"
import { useDocumentsType, documentType, documentMutationType } from "./types"

const useDocuments = (props: useDocumentsType) => {
  const [documents, setDocuments] = useState<Array<documentType>>([])
  const [loading, setLoading] = useState<boolean>(false)
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})

  const GetDocuments = useMutation(
    (item: documentMutationType) => {
      return postGetDocumentApi(
        process.env.GATSBY_API_URL + "/Documents/Regarding/Get",
        item,
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: data => {
        setDocuments(data)
      },
      onError: _error => {
        console.error("failed to fetch documents", _error)
      },
      onSettled: () => {
        setLoading(false)
      },
    }
  )

  const mutate = (id: documentMutationType) => {
    setLoading(true)
    GetDocuments.mutate(id)
  }

  useEffect(() => {
    if (props.disableAutoFetch !== true) {
      mutate(props.id)
    }
  }, [])

  return { documents, loading, mutate }
}

export default useDocuments
