import { Disclosure } from "@headlessui/react"
import { ChevronDownIcon } from "@heroicons/react/outline"
import { Link } from "gatsby"
import React, { useEffect, useState } from "react"
import dateFormat, { i18n } from "dateformat"
// import { format as formatDate, formatRelative } from "date-fns"
import { nb } from "date-fns/locale"
import ContentBox from "../../common/inputFields/ContentBox"
import Tillitsvalgt from "./Tillitsvalgt"
import { fetchApi, fetchApiAnonymous } from "../../../Utils/Api.utils"
import { useAccount, useMsal } from "@azure/msal-react"
import { DateTimeToNorwegian } from "../../../Utils/DateFormater"
import ContentBoxWithButton from "../../common/inputFields/ContentBoxWithButton"
import { typePrivatepractitioner, workcategory } from "../../../Utils/Maps"

const Arbeid = props => {
  const work = props?.work

  const [employerName, setEmployerName] = useState<string>()
  const [roleName, setRoleName] = useState<string>()
  const [workPlaceName, setWorkPlaceName] = useState<string>()
  const [position, setPosition] = useState<string>()
  const [tariff, setTariff] = useState<string>()
  const [showTV, setTV] = useState<boolean>(false)
  const [hideWork, setHideWork] = useState<boolean>(false)

  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})

  useEffect(() => {
    if (work?.role == 125600000) setRoleName("Hovedarbeidsgiver")
    else setRoleName("Biarbeidsgiver")

    const getEName = id => {
      // console.log("id til: ")
      fetchApi(
        `${process.env.GATSBY_API_URL}/Accounts/${id}`,
        account,
        inProgress,
        instance
      )
        .then(response => {
          // console.log(response)
          setEmployerName(response.data.name)
          response.data?.tariff?.positions?.forEach(p => {
            if (p.id === work?.position) setPosition(p.jobTitle)
          })
          if (response.data.tariff === null) {
            setHideWork(true)
          } else setHideWork(false)
          setTariff(response.data.tariff.name)
        })
        .catch(error => {
          setHideWork(true)
          setEmployerName("")
        })
    }
    const getPName = async id => {
      fetchApi(
        `${process.env.GATSBY_API_URL}/Accounts/${id}`,
        account,
        inProgress,
        instance
      )
        .then(response => {
          setWorkPlaceName(response.data.name)
        })
        .catch(error => {
          setWorkPlaceName("")
        })
    }
    if (work?.employer != undefined) getEName(work?.employer)
    else if (work?.employerAlternative != undefined)
      setEmployerName(work?.employerAlternative)
    if (work?.workPlace != undefined) getPName(work?.workPlace)
    else if (work?.workPlaceAlternative != undefined)
      setWorkPlaceName(work?.workPlaceAlternative)
    if (work?.tariff?.positions === undefined)
      setPosition(work?.positionAlternative)
  }, [])

  const showUnion = () => {
    if (showTV) setTV(false)
    else setTV(true)
  }
  const startDate = DateTimeToNorwegian(work?.startDate)
  const endDate = DateTimeToNorwegian(work?.endDate)

  const key = work?.employer
  const header = employerName

  const today = new Date()
  let active: boolean = true
  if (work?.endDate != undefined) {
    const endDate = new Date(work?.endDate)
    if (endDate.getTime() < today.getTime()) active = false
  }

  let content = {
    key: key,
    header: header,
    button: (
      <div className=" flex justify-end mb-4 ">
        <Link
          to={"/app/profil/endrearbeid/"}
          state={{ workId: work?.id }}
          className="ml-0 inline-flex justify-end py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-md text-white bg-meny-700 hover:bg-meny-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
        >
          {" "}
          Avslutt arbeidsforhold
        </Link>
      </div>
    ),
    active: active,
  }

  return (
    <div>
      {hideWork ? (
        <div></div>
      ) : (
        <ContentBoxWithButton props={content}>
          {/* Check if workcategory is as "Ansatt" or "undefined"*/}
          {work?.workcategory == 292460000 ||
          work?.workcategory == undefined ? (
            <div>
              <div className="sm:grid sm:grid-cols-2 gap-3 mb-4">
                <p className="text-base text-gray-600 sm:col-span-1">
                  Rolle: {roleName}
                </p>
                <p className="text-base text-gray-600 sm:col-span-1">
                  Arbeidssted: {workPlaceName}
                </p>
                <p className="text-base text-gray-600 sm:col-span-1">
                  Stilling: {position}
                </p>
                <p className="text-base text-gray-600 sm:col-span-1">
                  Prosentvis stilling: {work?.partTimePosition}
                </p>
                {work?.startDate != undefined ? (
                  <p className="text-base text-gray-600 sm:col-span-1">
                    Startdato: {startDate}
                  </p>
                ) : (
                  <p className="text-base text-gray-600 sm:col-span-1">
                    Startdato:
                  </p>
                )}
                {work?.endDate != undefined ? (
                  <p className="text-base text-gray-600 sm:col-span-1">
                    Sluttdato: {endDate}
                  </p>
                ) : (
                  <p className="text-base text-gray-600 sm:col-span-1">
                    Sluttdato:
                  </p>
                )}
              </div>
              {active ? (
                <>
                  <div className="sm:grid sm:grid-cols-2 gap-3 mb-4">
                    {/* <div className="sm:col-span-1 mb-3">
                  <Link
                    to={"/app/profil/endrearbeid/"}
                    state={{ workId: work?.id }}
                    className="ml-0 inline-flex justify-end py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-md text-white bg-meny-700 hover:bg-meny-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
                  >
                    {" "}
                    Endre
                  </Link>
                </div> */}
                    <div className="sm:col-span-1">
                      <button
                        className="ml-0 inline-flex justify-end py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-md text-white bg-meny-700 hover:bg-meny-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
                        onClick={showUnion}
                      >
                        Vis tillitsvalgte{" "}
                      </button>
                    </div>
                  </div>
                  <>
                    {showTV ? (
                      <Tillitsvalgt
                        employer={work?.employer}
                        workPlace={work?.workPlace}
                      />
                    ) : (
                      <div></div>
                    )}
                  </>
                </>
              ) : (
                <div></div>
              )}
            </div>
          ) : (
            <div className="pt-2 pl-2 sm:pl-4">
              <div className="sm:grid sm:grid-cols-2 gap-3 mb-4">
                <p className="text-base text-gray-600 sm:col-span-1">
                  Rolle: {roleName}
                </p>
                <div></div>
                <p className="text-base text-gray-600 sm:col-span-1">
                  Arbeidskategori: {workcategory.get(work?.workcategory)}
                </p>
                <p className="text-base text-gray-600 sm:col-span-1">
                  Type:{" "}
                  {typePrivatepractitioner.get(work?.typePrivatePractitioner)}
                </p>
                <p className="text-base text-gray-600 sm:col-span-1">
                  Prosentvis stilling: {work?.partTimePosition}
                </p>
                <p className="text-base text-gray-600 sm:col-span-1">
                  Avtalespesialist:{" "}
                  {work?.agreementspecialist == true ? "Ja" : "Nei"}
                </p>
                {work?.agreementspecialist == true && (
                  <p className="text-base text-gray-600 sm:col-span-1">
                    Drifttilskudd: {work?.operatingGrants}%
                  </p>
                )}
                {work?.clientsName != undefined && (
                  <p className="text-base text-gray-600 sm:col-span-1">
                    Oppdragsgiver: {work?.clientsName}
                  </p>
                )}
                <p className="text-base text-gray-600 sm:col-span-1">
                  Startdato: {startDate}
                </p>
                <p className="text-base text-gray-600 sm:col-span-1">
                  Sluttdato: {endDate}
                </p>
              </div>
            </div>
          )}
        </ContentBoxWithButton>
      )}
    </div>
  )
}

export default Arbeid
