import React from "react"
import { classNames } from "../graphichs/misc"
import { RadioGroup } from "@headlessui/react"

export const RadioGroupComponent = ({ value, onChange, type, label }) => {
  return (
    <>
      <RadioGroup value={value} onChange={onChange} className="mt-2">
        <RadioGroup.Label className="sr-only">{label}</RadioGroup.Label>
        <div className="mt-4 flex flex-col sm:flex-row gap-y-6 sm:gap-x-4">
          {type.map(option => (
            <RadioGroup.Option
              key={option.name}
              value={option.value}
              className={({ active, checked }) =>
                classNames(
                  checked
                    ? "border-meny-500 ring-2 ring-meny-500"
                    : "border-gray-900 outline text-center",
                  active ? "border-meny-500 ring-2 ring-meny-500" : "",
                  "relative bg-white border rounded-lg shadow-sm p-4 flex justify-center cursor-pointer focus:outline-none"
                )
              }
            >
              <RadioGroup.Label as="p">{option.name}</RadioGroup.Label>
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>
    </>
  )
}
