import React, { useState } from "react"
import { useAccount, useMsal } from "@azure/msal-react"
import { fetchApi } from "../../../Utils/Api.utils"
import { useQuery } from "react-query"
import { DateTimeToNorwegian } from "../../../Utils/DateFormater"

const MyEducation = props => {
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const education = props?.education

  const [educationPlaceName, setPlaceName] = useState<string>()

  return (
    <tr key={education.id}>
      <td className="pl-4 px-3 py-4 text-sm text-gray-600 sm:pl-6">
        {education.levelOfEducationName}
      </td>
      <td className="px-3 py-4 text-sm text-gray-600 table2:hidden">
        {education.titleOfThesis}
      </td>
      <td className="px-3 py-4 text-sm text-gray-600 table1:hidden">
        {education.grade}
      </td>
      <td className="px-3 py-4 text-sm text-gray-600">
        {education?.placeOfEducation?.name}
      </td>
      {/* {education?.startDateOfEducation !== undefined ? ( */}
      <td className=" px-3 py-4 text-sm text-gray-600 table1:hidden">
        {DateTimeToNorwegian(education?.startDateOfEducation)}
      </td>
      {/* ) : (
        <td className=" px-3 py-4 text-sm text-gray-600 table1:hidden"></td>
      )} */}
      {education?.endDateOfEducation !== undefined ? (
        <td className="px-3 py-4 text-sm text-gray-600">
          {DateTimeToNorwegian(education?.endDateOfEducation)}
        </td>
      ) : (
        <td className="px-3 py-4 text-sm text-gray-600"></td>
      )}
      {/* <td className="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                    <a
                      href="#"
                      className="text-indigo-600 hover:text-indigo-900"
                    >
                      Edit
                      <span className="sr-only">
                        , {person.placeOfEducation}
                      </span>
                    </a>
                  </td> */}
    </tr>
  )
}

export default MyEducation
