import React from "react"

const SmallListLoading = () => {
  return (
    <div
      role="status"
      className="p-4 space-y-4 rounded border w-full border-gray-200 divide-y divide-gray-200 shadow animate-pulse md:p-6"
    >
      <div className="flex justify-between items-center">
        <div>
          <div className="h-2.5 bg-gray-300 rounded-full w-24 mb-2.5"></div>
          <div className="w-full h-2 bg-gray-200 rounded-full"></div>
        </div>
        <div className="h-2.5 bg-gray-300 rounded-full w-12"></div>
      </div>
      <span className="sr-only">Loading...</span>
    </div>
  )
}

export default SmallListLoading
