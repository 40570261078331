import {
  InteractionRequiredAuthError,
  InteractionStatus,
} from "@azure/msal-browser"
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react"
import { navigate } from "gatsby"
import React, { Component, useEffect, useState } from "react"
import { useQuery } from "react-query"
import { fetchApi } from "../../../Utils/Api.utils"
import { WebRole } from "../../../Utils/Entities"
import { loginRequest } from "../../security/authConfig"
import { dialog, kurs, medlem, profil } from "./Menu"

export const EventRole = props => {
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  //   const [roles, setRoles] = useState<WebRole[]>([])
  // const webRoles = useContext(WebRoleContext)
  const isAuthenticated = useIsAuthenticated()
  const { Component, ...rest } = props

  const webRoleInfo = useQuery(
    "webRoleData",
    () =>
      fetchApi(
        `${process.env.GATSBY_API_URL}/Contacts/Roles`,
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      enabled: !!isAuthenticated,
      onSuccess: data => {
        // if (isAuthenticated) navigation = GetWebRolesNav()
        const role = data.some(r => r.role === 292460002)

        if (!role) {
          navigate(-1)
          return null
        }
      },
      onError: _error => {},
    }
  )
  return <Component {...rest} />
}
