import React, { ReactChild } from "react"

const Button = (props: {
  children?: ReactChild
  onClick?: (e: any) => void
  disabled?: boolean
}) => {
  return (
    <div className="py-6">
      <button
        onClick={props.onClick}
        disabled={props.disabled || false}
        className="disabled:bg-slate-400 ml-1 inline-flex justify-end py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-md text-white bg-meny-700 hover:bg-meny-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
      >
        {props.children}
      </button>
    </div>
  )
}

export default Button
