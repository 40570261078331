import React, { useEffect, useState } from "react"

const AddGuidanceCertificateLines = ({
  guidanceCertificateLines,
  setGuidanceCertificateLines,
}) => {
  const OnDeleteRow = rowIdToDelete => {
    setGuidanceCertificateLines(
      guidanceCertificateLines.filter(item => item.rowId !== rowIdToDelete)
    )
  }

  return (
    <div>
      <div>
        <div className="-mx-4 mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
          <table className="min-w-full divide-y divide-gray-300">
            <thead className="bg-meny-700">
              <tr>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-6"
                >
                  Fra
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-semibold text-white lg:table-cell"
                >
                  Til
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-white"
                >
                  Sum veiledning
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-white"
                >
                  Programtype
                </th>
                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                  <span className="sr-only">Edit</span>
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {guidanceCertificateLines?.map(lines => (
                <tr key={lines.rowId}>
                  <td className=" px-3 py-4 text-sm text-gray-600">
                    {lines.fromDate !== undefined ? (
                      <time dateTime={lines.fromDate}>
                        {new Intl.DateTimeFormat("nb-NO", {
                          dateStyle: "medium",
                          // timeStyle: "short",
                        }).format(new Date(lines.fromDate))}
                      </time>
                    ) : null}
                  </td>
                  <td className="hidden px-3 py-4 text-sm text-gray-600 lg:table-cell">
                    {lines.toDate !== undefined ? (
                      <time dateTime={lines.toDate}>
                        {new Intl.DateTimeFormat("nb-NO", {
                          dateStyle: "medium",
                          // timeStyle: "short",
                        }).format(new Date(lines.toDate))}
                      </time>
                    ) : null}
                  </td>
                  <td className="px-3 py-4 text-sm text-gray-600 ">
                    {lines.sum}
                  </td>
                  <td className="px-3 py-4 text-sm text-gray-600 ">
                    {lines.specialistProgramName}
                  </td>
                  <td className="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                    <button
                      type="button"
                      onClick={e => OnDeleteRow(lines.rowId)}
                      className="bg-white rounded-md font-medium text-meny-600 hover:text-meny-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                        />
                      </svg>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default AddGuidanceCertificateLines
