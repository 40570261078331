export const RemoveUnderScore = value => {
  if (value !== undefined && value !== null && value !== "")
    return value.replaceAll("_", " ")
  return value
}

export const ShortenInputValue = (value, lenght) => {
  if (value !== undefined && value !== null && value !== "") {
    if (value.length > lenght) {
      const result = RemoveHtml(value)
      return result.slice(0, lenght)
    }
    return value
  }
  return ""
}

export const NumberFormat = value => {
  if (value !== undefined && value !== null && value !== 0) {
    if (value.length > 999 && value.length <= 9999) {
    }

    return value.replaceAll("_", " ")
  }
  return value
}

export const RemoveQuotationMarks = value => {
  if (value !== undefined && value !== null && value !== "")
    return value.replace('"', "")
  return ""
}

export const AddQuotationMarks = value => {
  if (value !== undefined && value !== null && value !== "")
    return '"' + value + '"'
  return ""
}

export const RemoveHtml = value => {
  const regex = /(<([^>]+)>)/gi
  const secondRegEx = /((&nbsp;))*/gim
  var tempResult = value.replace(regex, "")
  return tempResult.replace(secondRegEx, "")
}

export const RemoveHtmlWithLines = value => {
  console.log(value)
  const regex = /(<([^>]+)>)/gi
  const secondRegEx = /((&nbsp;))*/gim
  var tempResult = value.replace(regex, "")
  // return tempResult.replace(secondRegEx, '')
  var tempResult2 = tempResult.replace(secondRegEx, "")
  return value.replace(/<div\s*\\?>/g, "\r\n")
}
