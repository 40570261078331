import { useAccount, useMsal } from "@azure/msal-react"
import React, { useState } from "react"
import { useMutation } from "react-query"
import { postGetDocumentApi } from "../../../Utils/Api.utils"
import HeaderText from "../../common/text/Header"
import FileUpload from "../document/FileUpload"

const SessionDocuments = props => {
  const [documentsDownLoad, setDocumentsDownLoad] = useState([])
  const [documents, setDocuments] = useState([])
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})

  const session = props.session
  // const eventId = props.props.eventId
  // console.log(event)

  const mutationGetDocuments = useMutation(
    () => {
      return postGetDocumentApi(
        process.env.GATSBY_API_URL + "/Documents/Regarding/Get",
        {
          //   EventId: event.id,
          SessionId: session.sessionid,
        },
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: data => {
        console.log(data)
        setDocumentsDownLoad(data)
      },
      onError: _error => {},
      onSettled: () => {},
    }
  )
  return (
    <div>
      {" "}
      <div className="mt-6">
        <HeaderText text={"Last opp kursmateriell"} />
        <p className="mt-2 text-sm text-gray-600"></p>
        {/* <DocumentList
          documents={documentsDownLoad}
          mutationGetDocuments={mutationGetDocuments}
          canDelete={true}
        /> */}
        <p className="mt-2 text-sm text-gray-600"></p>
        <FileUpload
          entityId={session?.sessionid}
          entityName={"msevtmgt_session"}
          entityId2={session?.eventId}
          entityName2={"msevtmgt_event"}
          entityId3={null}
          entityName3={null}
          mutationGetDocuments={mutationGetDocuments}
          setDocuments={setDocuments}
          isRequired={false}
          isReturn={false}
        />
      </div>
    </div>
  )
}

export default SessionDocuments
