import { Link } from "gatsby"
import React, { useEffect, useState } from "react"
import {
  DateTimeToDate,
  DateTimeToDateTime,
  DateTimeToNorwegian,
  DateTimeToTime,
} from "../../../Utils/DateFormater"
import { Accordion } from "../../common/accordion"
import { SpeakerRequest } from "../event/EventType"

const SpeakerRequests = props => {
  const request: SpeakerRequest = props.request
  const [title, setTitle] = useState<string>()
  const [startDate, setStartDate] = useState<string>()
  const [endDate, setEndDate] = useState<string>()
  const [place, setPlace] = useState<string>()

  useEffect(() => {
    if (request.sessionId !== undefined) {
      setTitle(request.session.name)

      setStartDate(DateTimeToDateTime(request?.session.starttime))
      setEndDate(DateTimeToDateTime(request?.session.endtime))
      setPlace(request?.session?.building?.name || "Ikke foreløpig bestemt")
    } else {
      setTitle(request.event.name)

      setStartDate(DateTimeToDateTime(request?.event?.startDate))
      setEndDate(DateTimeToDateTime(request?.event?.endDate))
      setPlace(request?.event?.building?.name || "Ikke foreløpig bestemt")
    }
  }, [props.request])
  return (
    <>
      <Accordion title={title} subTitle={".."}>
        <>
          <div className="sm:grid sm:grid-cols-2 gap-3 mb-4">
            <p className="text-base text-gray-600 sm:col-span-1">
              Arrangement: {request?.event?.name}
            </p>
            {request?.sessionId !== undefined ? (
              <p className="text-base text-gray-600 sm:col-span-1">
                Kurs: {request?.session?.name}
              </p>
            ) : null}
            <p className="text-base text-gray-600 sm:col-span-1 sm:col-start-1">
              Min rolle: {request?.roleName}
            </p>
            <p className="text-base text-gray-600 sm:col-span-1">
              Bidragsyter: {request?.speaker?.name}
            </p>
            <p className="text-base text-gray-600 sm:col-span-1">
              Startdato: {startDate}
            </p>
            <p className="text-base text-gray-600 sm:col-span-1">
              Sluttdato: {endDate}
            </p>

            {request.cityName !== undefined ? (
              <p className="text-base text-gray-600 sm:col-span-1">
                By: {request.cityName}
              </p>
            ) : (
              <p className="text-base text-gray-600 sm:col-span-1">
                By: By ikke valgt
              </p>
            )}

            <p className="text-base text-gray-600 sm:col-span-1 ">
              Sted: {place}
            </p>
            {request.approved ? (
              <p className="text-base text-gray-600 sm:col-span-1">
                Bekreftet: Ja
              </p>
            ) : (
              <p className="text-base text-gray-600 sm:col-span-1">
                Bekreftet: Nei
              </p>
            )}
          </div>

          <div className="sm:grid sm:grid-cols-2 gap-3 mb-4">
            <div className="sm:col-span-1 mb-3">
              <Link
                to={"/app/kurs/administrasjon/foresporsel"}
                state={{ request: request }}
                className="ml-0 inline-flex justify-end py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-md text-white bg-meny-700 hover:bg-meny-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
              >
                {" "}
                Besvar forespørsel
              </Link>
            </div>
          </div>
        </>
      </Accordion>
    </>
  )
}

export default SpeakerRequests
