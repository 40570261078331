import { InteractionType } from "@azure/msal-browser"
import {
  MsalAuthenticationTemplate,
  useAccount,
  useMsal,
} from "@azure/msal-react"
import { Dialog, Disclosure, Menu, Transition } from "@headlessui/react"
import {
  ChevronDownIcon,
  FilterIcon,
  MinusSmIcon,
  PlusSmIcon,
  ViewGridIcon,
  XIcon,
} from "@heroicons/react/outline"
import React, { Fragment, useState } from "react"
import { useQuery, useQueryClient } from "react-query"
import { fetchApi } from "../../../Utils/Api.utils"
import { AddQuotationMarks } from "../../../Utils/FormatHelper"
import { Loading } from "../../../Utils/Loading"
import { spesialistActivityTypes } from "../../../Utils/optionSet"
import { sortOptions } from "../../../Utils/Sorting"
import { BackButton } from "../../common/buttons/BackButton"
import { ErrorComponent } from "../../common/graphichs/ErrorComponent"
import LoadingData from "../../common/graphichs/LoadingData"
import { classNames } from "../../common/graphichs/misc"
import MyPageLayout from "../../common/layouts/myPageLayout"
import TemplateDocuments from "../../mypagecomponents/specialityeducation/TemplateDocuments"
import { loginRequest } from "../../security/authConfig"
import { filterDocuments } from "../../mypagecomponents/specialityeducation/helpers"
import { cyanButton, whiteButton } from "../../../styles/tailwindClasses"

const TemplatesContent = () => {
  const queryClient = useQueryClient()
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const [documents, setDocuments] = useState([])
  const [filteredDocuments, setFilteredDocuments] = useState([])
  const [programs, setPrograms] = useState<any[]>()
  const [specialities, setSpecialities] = useState<any[]>()
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false)
  const [selectedActivityType, setSelectedActivityType] = useState("0")
  const [selectedSpeciality, setSelectedSpeciality] = useState("0")
  const [selectedProgramType, setSelectedProgramType] = useState("0")

  const templateDocumentsQuery = useQuery(
    "templateDocumentsData",
    () =>
      fetchApi(
        process.env.GATSBY_API_URL + "/Documents/Templates",
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: data => {
        setDocuments(data)
        setFilteredDocuments(data)
      },
      onError: _error => { },
    }
  )

  useQuery(
    "specialitiesDataActive",
    () =>
      fetchApi(
        process.env.GATSBY_API_URL + "/Specialities/all/active",
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: data => {
        setSpecialities(data)
      },
      onError: _error => { },
    }
  )

  useQuery(
    "programTypesData",
    () =>
      fetchApi(
        process.env.GATSBY_API_URL + "/ProgramTypes/all",
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: data => {
        setPrograms(data)
      },
      onError: _error => { },
    }
  )

  const onFilter = () => {
    const reslult = filterDocuments(
      documents,
      selectedProgramType,
      selectedSpeciality,
      selectedActivityType
    )
    setFilteredDocuments(reslult)

    if (mobileFiltersOpen) setMobileFiltersOpen(false)
  }

  const ResetFilter = () => {
    setSelectedActivityType("0")
    setSelectedProgramType("0")
    setSelectedSpeciality("0")
    queryClient.invalidateQueries(["templateDocumentsData"])
  }

  return templateDocumentsQuery.isLoading ? (
    // // "Laster inn..."
    <LoadingData />
  ) : templateDocumentsQuery.isError ? (
    templateDocumentsQuery.error.message
  ) : (
    <div>
      <MyPageLayout props="Spesialistutdanning maler">
        <BackButton />
        <div>
          <div className="bg-white rounded-lg shadow px-5 py-6 sm:px-6 mt-10">
            <div>
              {/* Mobile filter dialog */}
              <Transition.Root show={mobileFiltersOpen} as={Fragment}>
                <Dialog
                  as="div"
                  className="relative z-40 lg:hidden"
                  onClose={setMobileFiltersOpen}
                >
                  <Transition.Child
                    as={Fragment}
                    enter="transition-opacity ease-linear duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity ease-linear duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="fixed inset-0 bg-black bg-opacity-25" />
                  </Transition.Child>

                  <div className="fixed inset-0 flex z-40">
                    <Transition.Child
                      as={Fragment}
                      enter="transition ease-in-out duration-300 transform"
                      enterFrom="translate-x-full"
                      enterTo="translate-x-0"
                      leave="transition ease-in-out duration-300 transform"
                      leaveFrom="translate-x-0"
                      leaveTo="translate-x-full"
                    >
                      <div className="ml-auto relative max-w-xs w-full h-full bg-white shadow-xl py-4 pb-12 flex flex-col overflow-y-auto">
                        <div className="px-4 flex items-center justify-between">
                          <h2 className="text-lg font-medium text-gray-900">
                            Filters
                          </h2>
                          <button
                            type="button"
                            className="-mr-2 w-10 h-10 bg-white p-2 rounded-md flex items-center justify-center text-gray-400"
                            onClick={() => setMobileFiltersOpen(false)}
                          >
                            <span className="sr-only">Close menu</span>
                            <XIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>

                        {/* Filters */}
                        <form className="mt-4 border-t border-gray-200">
                          <Disclosure
                            as="div"
                            className="border-t border-gray-200 px-4 py-6"
                          >
                            <div className="mt-1">
                              <label
                                htmlFor="documenttype"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Dokumenttype
                              </label>
                              <div className="mt-1.5 relative">
                                <select
                                  id="documenttype"
                                  name="documenttype"
                                  onChange={e =>
                                    setSelectedActivityType(e.target.value)
                                  }
                                  className="appearance-none block w-full border-gray-300  border-transparent rounded-md pl-3 pr-10 py-2 text-base focus:outline-none focus:ring-meny-700 focus:border-meny-500 sm:text-sm"
                                  value={selectedActivityType}
                                >
                                  <option disabled selected value="0">
                                    --Dokumenttype --
                                  </option>

                                  {spesialistActivityTypes?.map(a => (
                                    <option key={a.id} value={a.id}>
                                      {a.name}
                                    </option>
                                  ))}
                                </select>
                                <div className="pointer-events-none absolute inset-y-0 right-0 px-2 flex items-center">
                                  <ChevronDownIcon
                                    className="h-4 w-4 text-white"
                                    aria-hidden="true"
                                  />
                                </div>
                              </div>
                            </div>
                          </Disclosure>

                          <Disclosure
                            as="div"
                            className="border-t border-gray-200 px-4 py-6"
                          >
                            <div className="mt-1">
                              <label
                                htmlFor="speciality"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Spesialitet
                              </label>
                              <div className="mt-1.5 relative">
                                <select
                                  id="speciality"
                                  name="speciality"
                                  onChange={e =>
                                    setSelectedSpeciality(e.target.value)
                                  }
                                  className="appearance-none block w-full border-gray-300  border-transparent rounded-md pl-3 pr-10 py-2 text-base focus:outline-none focus:ring-meny-700 focus:border-meny-500 sm:text-sm"
                                  value={selectedSpeciality}
                                >
                                  <option disabled selected value="0">
                                    -- Spesialitet --
                                  </option>

                                  {specialities?.map(a => (
                                    <option key={a.id} value={a.id}>
                                      {a.name}
                                    </option>
                                  ))}
                                </select>
                                <div className="pointer-events-none absolute inset-y-0 right-0 px-2 flex items-center">
                                  <ChevronDownIcon
                                    className="h-4 w-4 text-white"
                                    aria-hidden="true"
                                  />
                                </div>
                              </div>
                            </div>
                          </Disclosure>

                          <Disclosure
                            as="div"
                            className="border-t border-gray-200 px-4 py-6"
                          >
                            <div className="mt-1">
                              <label
                                htmlFor="programtype"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Programtype
                              </label>
                              <div className="mt-1.5 relative">
                                <select
                                  id="programtype"
                                  name="programtype"
                                  onChange={e =>
                                    setSelectedProgramType(e.target.value)
                                  }
                                  className="appearance-none block w-full border-gray-300  border-transparent rounded-md pl-3 pr-10 py-2 text-base focus:outline-none focus:ring-meny-700 focus:border-meny-500 sm:text-sm"
                                  value={selectedProgramType}
                                >
                                  <option disabled selected value="0">
                                    -- Programtype --
                                  </option>

                                  {programs?.map(a => (
                                    <option key={a.id} value={a.id}>
                                      {a.name}
                                    </option>
                                  ))}
                                </select>
                                <div className="pointer-events-none absolute inset-y-0 right-0 px-2 flex items-center">
                                  <ChevronDownIcon
                                    className="h-4 w-4 text-white"
                                    aria-hidden="true"
                                  />
                                </div>
                              </div>
                            </div>
                          </Disclosure>

                          <Disclosure
                            as="div"
                            className="border-t border-gray-200 px-4 py-6 flex flex-row"
                          >
                            <button
                              type="button"
                              onClick={onFilter}
                              className={
                                cyanButton + " inline-flex items-center mr-2 "
                              }
                            >
                              <FilterIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                              Filter
                            </button>
                            <button
                              type="button"
                              onClick={ResetFilter}
                              className={
                                whiteButton + " inline-flex items-center mr-2 "
                              }
                            >
                              Nullstill filter
                            </button>
                          </Disclosure>
                        </form>
                      </div>
                    </Transition.Child>
                  </div>
                </Dialog>
              </Transition.Root>

              <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="relative z-10 flex items-baseline justify-between pt-8 pb-6 border-b border-gray-200">
                  <h1 className="text-4xl font-extrabold tracking-tight text-gray-900">
                    Velg mal
                  </h1>
                  <div className="flex items-center">
                    <button
                      type="button"
                      className="p-2 -m-2 ml-4 sm:ml-6 text-gray-400 hover:text-gray-600 lg:hidden"
                      onClick={() => setMobileFiltersOpen(true)}
                    >
                      <span className="sr-only">Filtre</span>
                      <FilterIcon className="w-5 h-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>

                <section
                  aria-labelledby="documents-heading"
                  className="pt-6 pb-24"
                >
                  <h2 id="documents-heading" className="sr-only">
                    Dokumenter
                  </h2>

                  <div className="grid grid-cols-1 lg:grid-cols-4 gap-x-8 gap-y-10">
                    {/* Filters */}
                    <form className="hidden lg:block">
                      <Disclosure
                        as="div"
                        className="border-t border-gray-200 px-4 py-6"
                      >
                        <div className="mt-1">
                          <label
                            htmlFor="actyvitytype"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Aktivitetstype
                          </label>
                          <div className="mt-1.5 relative">
                            <select
                              id="actyvitytype"
                              name="actyvitytype"
                              onChange={e =>
                                setSelectedActivityType(e.target.value)
                              }
                              className="appearance-none block w-full border-gray-300  border-transparent rounded-md pl-3 pr-10 py-2 text-base focus:outline-none focus:ring-meny-700 focus:border-meny-500 sm:text-sm"
                              value={selectedActivityType}
                            >
                              <option disabled selected value="0">
                                -- Aktivitetstype --
                              </option>

                              {spesialistActivityTypes?.map(a => (
                                <option key={a.id} value={a.id}>
                                  {a.name}
                                </option>
                              ))}
                            </select>
                            <div className="pointer-events-none absolute inset-y-0 right-0 px-2 flex items-center">
                              <ChevronDownIcon
                                className="h-4 w-4 text-white"
                                aria-hidden="true"
                              />
                            </div>
                          </div>
                        </div>
                      </Disclosure>

                      <Disclosure
                        as="div"
                        className="border-t border-gray-200 px-4 py-6"
                      >
                        <div className="mt-1">
                          <label
                            htmlFor="speciality"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Spesialitet
                          </label>
                          <div className="mt-1.5 relative">
                            <select
                              id="speciality"
                              name="speciality"
                              onChange={e =>
                                setSelectedSpeciality(e.target.value)
                              }
                              className="appearance-none block w-full border-gray-300  border-transparent rounded-md pl-3 pr-10 py-2 text-base focus:outline-none focus:ring-meny-700 focus:border-meny-500 sm:text-sm"
                              value={selectedSpeciality}
                            >
                              <option disabled selected value="0">
                                -- Spesialitet --
                              </option>

                              {specialities?.map(a => (
                                <option key={a.id} value={a.id}>
                                  {a.name}
                                </option>
                              ))}
                            </select>
                            <div className="pointer-events-none absolute inset-y-0 right-0 px-2 flex items-center">
                              <ChevronDownIcon
                                className="h-4 w-4 text-white"
                                aria-hidden="true"
                              />
                            </div>
                          </div>
                        </div>
                      </Disclosure>

                      <Disclosure
                        as="div"
                        className="border-t border-gray-200 px-4 py-6"
                      >
                        <div className="mt-1">
                          <label
                            htmlFor="programtype"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Prgramtype
                          </label>
                          <div className="mt-1.5 relative">
                            <select
                              id="programtype"
                              name="programtype"
                              onChange={e =>
                                setSelectedProgramType(e.target.value)
                              }
                              className="appearance-none block w-full border-gray-300  border-transparent rounded-md pl-3 pr-10 py-2 text-base focus:outline-none focus:ring-meny-700 focus:border-meny-500 sm:text-sm"
                              value={selectedProgramType}
                            >
                              <option disabled selected value="0">
                                -- Programtype --
                              </option>

                              {programs?.map(a => (
                                <option key={a.id} value={a.id}>
                                  {a.name}
                                </option>
                              ))}
                            </select>
                            <div className="pointer-events-none absolute inset-y-0 right-0 px-2 flex items-center">
                              <ChevronDownIcon
                                className="h-4 w-4 text-white"
                                aria-hidden="true"
                              />
                            </div>
                          </div>
                        </div>
                      </Disclosure>

                      <Disclosure
                        as="div"
                        className="border-t border-gray-200 px-4 py-6 flex flex-row"
                      >
                        <button
                          type="button"
                          onClick={onFilter}
                          className={
                            cyanButton + " inline-flex items-center mr-2 "
                          }
                        >
                          <FilterIcon className="h-6 w-6" aria-hidden="true" />
                          Filter
                        </button>
                        <button
                          type="button"
                          onClick={ResetFilter}
                          className={whiteButton + " inline-flex items-center "}
                        >
                          Nullstill filter
                        </button>
                      </Disclosure>
                    </form>

                    {/* DocumentList */}
                    <div className="lg:col-span-3">
                      <div className="border-4 border-dashed border-gray-200 rounded-lg h-96 lg:h-full">
                        <TemplateDocuments documents={filteredDocuments} />
                      </div>
                    </div>
                  </div>
                </section>
              </main>
            </div>
          </div>
        </div>
      </MyPageLayout>
    </div>
  )
}

const Templates = ({ location }) => {
  const authRequest = {
    ...loginRequest,
  }
  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      <TemplatesContent location={location} />
    </MsalAuthenticationTemplate>
  )
}

export default Templates
