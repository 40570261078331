import { Link } from "gatsby"
import React, { useEffect, useState } from "react"
import { useQuery } from "react-query"
import { StringParam, useQueryParam } from "use-query-params"
import { fetchApiAnonymous } from "../../../Utils/Api.utils"
import { DateTimeToDateTime } from "../../../Utils/DateFormater"
import { BackButton } from "../../common/buttons/BackButton"
import MyPageLayout from "../../common/layouts/myPageLayout"
import { SessionAccoridon } from "../../mypagecomponents/event"
import {
  EventType,
  Pass,
  SessionType,
} from "../../mypagecomponents/event/EventType"
import {
  CityOptionsetValueToString,
  EventRegistrationTypeEnum,
} from "../../mypagecomponents/event/helpers/Event"

const Event = () => {
  const readableEventId = useQueryParam("id", StringParam)[0]
  const [isLoading, setIsLoading] = useState<loadingState>({
    Event: true,
    Session: true,
  })

  const [registrationType, setRegistrationType] =
    useState<EventRegistrationTypeEnum>()

  const [allowRegistration, setAllowRegistration] = useState<boolean>()

  const [passes, setPasses] = useState<Array<Pass>>([])

  const eventQuery = useQuery<EventType>("event-" + readableEventId, async () =>
    fetchApiAnonymous(
      process.env.GATSBY_API_URL + `/Events/${readableEventId}`
    ).then(res => {
      return res.data
    })
  )
  const sessionQuery = useQuery<Array<SessionType>>(
    "session-" + readableEventId,
    async () =>
      fetchApiAnonymous(
        process.env.GATSBY_API_URL + `/Events/${readableEventId}/GetSession`
      ).then(res => res.data)
  )

  useEffect(() => {
    setIsLoading({
      Event: !eventQuery.isSuccess,
      Session: !sessionQuery.isSuccess,
    })
    if (eventQuery.isSuccess && sessionQuery.isSuccess) {
      const stopWebsiteRegistrationson: number = new Date(
        eventQuery.data?.customFields?.stopWebsiteRegistrationson || ""
      ).getTime()

      const currentDate: number = Date.now()

      setAllowRegistration(
        stopWebsiteRegistrationson === NaN ||
          stopWebsiteRegistrationson > currentDate
      )
    }
  }, [eventQuery.isSuccess, sessionQuery.isSuccess])

  useQuery<{ registrationType: number }>(
    [
      "registrationType-" + readableEventId,
      eventQuery.data?.customFields?.eventType?.id,
    ],
    async () =>
      fetchApiAnonymous(
        process.env.GATSBY_API_URL +
          `/Events/registrationType/${eventQuery.data?.customFields?.eventType?.id}`
      ).then(res => res.data),
    {
      enabled: !!eventQuery.data?.customFields?.eventType?.id,
      onSuccess: data => {
        setRegistrationType(data.registrationType)
      },
    }
  )

  useQuery<Array<Pass>>(
    ["pass-" + readableEventId, registrationType],
    async () => {
      // if (registrationType === EventRegistrationTypeEnum.noSessions) return []
      return fetchApiAnonymous(
        process.env.GATSBY_API_URL + `/Events/${readableEventId}/GetPasses`
      ).then(res => res.data)
    },
    {
      // enabled: !!registrationType,
      onSuccess: data => {
        setPasses(data)
      },
    }
  )

  return (
    <MyPageLayout props="Kurskalender">
      <div>
        <div>
          {isLoading.Event ? (
            "loading event..."
          ) : (
            <EventSection event={eventQuery.data} passes={passes} />
          )}
        </div>
        <div className="w-full flex justify-center">
          {isLoading.Session ? (
            "loading session..."
          ) : (
            <div className="w-full max-w-3xl mt-12">
              {passes &&
                registrationType === EventRegistrationTypeEnum.noSessions && (
                  <PriceSection passes={passes} />
                )}
              {(sessionQuery.data || []).length > 0 ? (
                <div className="mt-8 text-2xl font-bold text-meny-700">
                  <h2>Kurs</h2>
                  <div className="w-full h-px bg-gray-200 mb-4 "></div>
                </div>
              ) : null}
              <div className="max-w-3xl mx-auto divide-y divide-gray-200 rounded-lg overflow-hidden">
                {(sessionQuery.data || []).map(item => (
                  <SessionAccoridon
                    session={item}
                    key={item.id}
                  ></SessionAccoridon>
                ))}
              </div>
            </div>
          )}
        </div>
        <div className="flex flex-row py-6 justify-center">
          {allowRegistration ? (
            <>
              <Link
                to={"innmelding?id=" + readableEventId}
                // state={{ workId: work?.id }}
                className="ml-1 inline-flex justify-end py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-md text-white bg-meny-700 hover:bg-meny-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
              >
                Meld meg på
              </Link>
            </>
          ) : (
            <div className="flex items-center flex-col w-auto">
              {allowRegistration === false && (
                <p className="text-red-600">Påmeldingsfrist er passert</p>
              )}
              <div className="w-fit ml-1 inline-flex justify-end py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-md text-white bg-meny-700 opacity-60">
                Meld meg på
              </div>
            </div>
          )}
        </div>
      </div>
    </MyPageLayout>
  )
}

const EventSection = (props: { event?: EventType; passes?: Array<Pass> }) => {
  if (!props.event) return <div>no data</div>

  const data: { lable: string; value?: string }[] = [
    { lable: "Start dato", value: DateTimeToDateTime(props.event.startDate) },
    { lable: "Slutt dato", value: DateTimeToDateTime(props.event.endDate) },
    {
      lable: "Antall timer",
      value: props.event.customFields?.amounthours?.toString(),
    },
    {
      lable: "By",
      value: CityOptionsetValueToString(props.event.customFields?.city?.value),
    },
    { lable: "Postnummer", value: props.event.building?.postalCode },
    { lable: "Prosjektnr", value: props.event.customFields?.projectno },
  ]

  const data2 =
    props.event.eventTypeObject?.registrationType ===
    EventRegistrationTypeEnum.optional
      ? data
      : [
          ...data,
          {
            lable: "Påmeldingsfrist",
            value: DateTimeToDateTime(
              props.event.customFields?.stopWebsiteRegistrationson
            ),
          },
          {
            lable: "Avmeldingsfrist",
            value: DateTimeToDateTime(
              props.event.customFields?.stopWebsiteCancellationon
            ),
          },
        ]

  return (
    <section className="flex flex-col items-center mt-8">
      <div className="max-w-3xl flex flex-col w-full">
        <div className="mb-4">
          <BackButton />
        </div>
        <div>
          <h2 className="text-2xl font-bold text-meny-700">
            {props.event.eventName}
          </h2>
        </div>
        <div className="w-full h-px bg-gray-200 mb-4 "></div>
        <div className="divide-y divide-gray-200 bg-white rounded-lg px-4">
          {data2
            .filter(item => item.value !== undefined)
            .map(item => (
              <div
                key={item.lable}
                className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
              >
                <dt className="text-sm font-medium text-gray-600">
                  {item.lable}
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {item.value}
                </dd>
              </div>
            ))}
        </div>
        <h2 className="mt-8 text-2xl font-bold text-meny-700">Beskrivelse</h2>
        <div className="w-full h-px bg-gray-200 mb-4 "></div>

        <div
          className="divide-y divide-gray-200 bg-white rounded-lg px-4 py-4"
          dangerouslySetInnerHTML={{
            __html:
              props.event.customFields?.calendarContent ||
              "ingen beskrivelse tilgjengelig",
          }}
        ></div>
      </div>
    </section>
  )
}

const PriceSection = (props: { passes: Array<Pass> }) => {
  const data: { lable: string; value?: string }[] = props.passes
    .filter(item => item.price !== 0)
    .map(pass => ({
      lable: pass.passName || "",
      value: pass.price + " " + pass.currencySymbol,
    }))

  if (data.length === 0) return <></>

  return (
    <div>
      <h1 className="mt-8 text-2xl font-bold text-meny-700">Pris</h1>
      <div className="w-full h-px bg-gray-200 mb-4"></div>
      <div className="divide-y divide-gray-200 bg-white rounded-lg px-4">
        {data
          // .filter(item => item.value !== undefined)
          .filter(
            (item, index, arr) =>
              arr.findIndex(
                item2 =>
                  item2.value === item.value && item2.lable === item.lable
              ) === index
          )
          .map((item, index) => (
            <div
              key={index}
              className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt className="text-sm font-medium text-gray-900">
                {item.lable}
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {/* {item.value?.split("\n").map(item2 => (
                <div key={item2}>{item2}</div>
              ))} */}
                {item.value}
              </dd>
            </div>
          ))}
      </div>
    </div>
  )
}

type loadingState = {
  Event: boolean
  Session: boolean
}

export default Event
