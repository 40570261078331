import {
  MsalAuthenticationTemplate,
  useAccount,
  useMsal,
} from "@azure/msal-react"
import { ErrorMessage } from "@hookform/error-message"
import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useMutation, useQuery } from "react-query"
import { fetchApi, postApi } from "../../../../Utils/Api.utils"
import {
  CustomerTypes,
  FormValues,
  NewValueIncident,
} from "../../../../Utils/Types"
import MyPageLayout from "../../../common/layouts/myPageLayout"
import { loginRequest } from "../../../security/authConfig"
import { Loading } from "../../../../Utils/Loading"
import { InteractionType } from "@azure/msal-browser"
import { ErrorComponent } from "../../../common/graphichs/ErrorComponent"
import { idnr } from "@navikt/fnrvalidator"
import Select, { MultiValue } from "react-select"
import { Label } from "@headlessui/react/dist/components/label/label"
import { toast } from "react-toastify"
import { navigate } from "gatsby"
import LoadingData from "../../../common/graphichs/LoadingData"
import { Switch } from "@headlessui/react"
import { classNames } from "../../../common/graphichs/misc"
import HelperText from "../../../common/text/HelperText"

const CloseMembershipContent = () => {
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const [description, setDescription] = useState("")
  const [selectedOption, setSelectedOption] = useState("")
  const [membershipId, setMembershipId] = useState(null)
  const [disable, setDisabled] = useState<boolean>(false)
  const [magazine, setMagazine] = useState<boolean>(true)
  const [membership, setMembership] = useState<boolean>(true)
  const [showLoadingData, setShowLoadingData] = useState(false)
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<NewValueIncident>()

  const postIncident = async (data, event) => {
    // e.preventDefault()

    data.membershipId = userInfoQuery.data?.activeMemebershipId
    data.title =
      "Utmeldingssøknad: " +
      userInfoQuery.data?.firstName +
      " " +
      userInfoQuery.data?.lastName
    data.caseTypeCode = 13
    data.caseOriginCode = 3
    data.approveStatus = 778380001
    data.status = 1
    data.state = 0
    if (selectedOption.length > 0) data.reasonUnsub = selectedOption
    // Emne = Utmeldingssøknad
    data.subjectId = "f3c2b202-fd9d-ec11-b400-000d3aadca3e"

    if (membershipId == null || membershipId == undefined) {
      toast.warning("Medlemskap mangler!", {
        position: "top-center",
        autoClose: 10000,
      })
      // return
    }
    // under utmelding
    else if (
      userInfoQuery.data?.customerTypeCode === CustomerTypes.Under_utmelding
    ) {
      toast.warning("Du er allerede under utmelding!", {
        position: "top-center",
        autoClose: 10000,
      })
    } else {
      setShowLoadingData(true)
      createIncident.mutate(data)
    }

    // } else createIncident.mutate(data)
    // getSubscription.mutate()
  }

  // const createIncident = useMutation(() => {
  //   let obj = {
  //     title: "Utmeldingssøknad: " + account?.name,
  //     caseOriginType: 13,
  //     caseOriginCode: 3,
  //     description: description,
  //     approveStatus: 778380001,
  //     status: 1,
  //     state: 0,
  //     reasonUnsub: selectedOption,
  //   }
  //   console.log(obj)

  //   return postApi(
  //     process.env.GATSBY_API_URL + "/Incidents",
  //     {
  //       title: "Utmeldingssøknad: " + account?.name,
  //       caseOriginType: 13,
  //       caseOriginCode: 3,
  //       description: description,
  //       approveStatus: 778380001,
  //       status: 1,
  //       state: 0,
  //       reasonUnsub: selectedOption,
  //     },
  //     account,
  //     instance,
  //     inProgress
  //   ).then(res => res.data)
  // })

  // const getSubscription = useQuery(
  //   "incident"
  //   () => {
  //     return fetchApi(
  //       process.env.GATSBY_API_URL + "/Get/Contacts/Subscriptions",
  //       // {
  //       //   membershipId: membershipId
  //       // },
  //       account,
  //       inProgress,
  //       instance
  //     ).then(res => res.data)

  //   },
  //   {
  //     onSuccess:
  //   }
  // )

  const userInfoQuery = useQuery(
    "userInfo",
    () =>
      fetchApi(
        process.env.GATSBY_API_URL + "/Contacts/me",
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: data => {
        setMembershipId(userInfoQuery.data?.activeMemebershipId)
      },
      onError: _error => { },
    }
  )

  // const userSubscriptionsQuery = useQuery(
  //   "userSubscriptionsData",
  //   () =>
  //     fetchApi(process.env.GATSBY_API_URL + "/Contacts/Subscriptions", account, inProgress, instance).then((res) => res.data),
  //   {
  //     onSuccess: (data) => {

  //     },
  //     onError: (_error) => {
  //     },
  //   }
  // )

  const createIncident = useMutation(
    data => {
      return postApi(
        process.env.GATSBY_API_URL + "/Incidents",
        data,
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: data => {
        toast.success(
          "Saken er opprettet! Du vil nå bli videreført til dine saker.",
          {
            position: "top-center",
            autoClose: 5000,
          }
        )
        setTimeout(() => {
          navigate("/app/dialog/minesaker")
        }, 5000)
      },
      onError: _error => {
        console.log(_error)
        setShowLoadingData(false)
      },
      onSettled: () => { },
    }
  )

  const test = (e: MultiValue<{ value: number; label: string }>) => {
    let newValue = ""
    for (let v of e) {
      newValue += v.value + ","
    }
    setSelectedOption(newValue.substring(0, newValue.length - 1))
  }

  const multipleChoices = [
    { value: 778380004, label: "Annen årsak" }, // attributevalue	:	778380004
    {
      value: 292460002,
      label: "Blir pensjonist - ønsker ikke videre medlemskap",
    }, // attibutevalue	:	292460002
    { value: 292460001, label: "Flytte til utlandet" }, // attributevalue	:	292460001
    { value: 292460003, label: "For dårlig service fra foreningen" }, // attributevalue	:	292460003
    { value: 292460000, label: "For høy medlemskontingent" }, // attributevalue	:	292460000
    { value: 778380003, label: "Jobber ikke lenger som psykolog" }, // attributevalue	:	778380003
    { value: 778380001, label: "Medlem av Forskerforbundet" }, // attributevalue	:	778380001
    { value: 778380002, label: "Medlemsfordeler" }, // attributevalue	:	778380002
    { value: 778380000, label: "Økonomiske grunner" }, // attributevalue	:	778380000
    { value: 292460004, label: "Uenige i foreningens politikk" }, //attributevalue	:	292460004
    { value: 292460005, label: "Frivillig avkall på lisens/autorisasjon" }, //attributevalue	:	292460005
    { value: 292460006, label: "Permisjon" }, //attributevalue	:	292460006
    { value: 292460007, label: "Sykdom" }, //attributevalue	:	292460007
    { value: 292460008, label: "Ufør" }, //attributevalue	:	292460008
    { value: 292460009, label: "Pensjonist" }, //attributevalue	:	292460009
    { value: 292460010, label: "Oppsagt" }, //attributevalue	:	292460010
  ]

  const MagazineChanged = value => {
    setMagazine(value)
  }

  const MembershipChanged = value => {
    setMembership(value)
  }

  return userInfoQuery.isLoading ? (
    // // "Laster inn..."
    <LoadingData />
  ) : showLoadingData ? (
    <LoadingData subject="Oppretter" />
  ) : (
    <MyPageLayout
      props={
        "Utmeldingssøknad: " +
        userInfoQuery.data?.firstName +
        " " +
        userInfoQuery.data?.lastName
      }
    >
      <br />
      <h1 className="flex justify-center">
        Det er 3 måned oppsigelsestid for ordinære og pensjonist medlemmer.
        {/* <br />
        Navn:{" "}
        {userInfoQuery.data?.firstName + " " + userInfoQuery.data?.lastName} */}
      </h1>
      <br />
      <br />
      {userInfoQuery.data?.erpNumber !== undefined ? (
        <p className="text-base font-bold text-red-700 sm:col-span-2">
          Kunde-/medlemsnummer: {userInfoQuery.data?.erpNumber}
        </p>
      ) : (
        <></>
      )}
      <form method="POST" onSubmit={handleSubmit(postIncident)}>
        <div className="sm:col-span-6">
          <div id="multipleChoiceContainer">
            <label
              htmlFor=""
              className="block text-sm font-medium text-gray-700"
            >
              Årsak til utmelding:
            </label>

            <div className="mt-1 sm:mt-0 sm:col-span-2">
              <Select
                // {...register("reasonUnsubscription")}
                isMulti
                isClearable
                options={multipleChoices}
                onChange={e => test(e)}
              // className="focus:border-meny-500 focus:ring-meny-700"
              />

              {/* <MultiSelectDrop /> */}
            </div>
          </div>
          <br />

          <label
            htmlFor="description"
            className="block text-sm font-medium text-gray-700"
          >
            Beskrivelse*
          </label>
          <HelperText helpertext="Skriv noen ord om saken." />

          <div className="mt-1">
            <textarea
              {...register("description", {
                required: {
                  value: true,
                  message: "Obligatorisk felt.",
                },
                minLength: {
                  value: 10,
                  message: "Minimum tegn er 10",
                },
                maxLength: {
                  value: 2000,
                  message: "Maks tegn er 2000",
                },
              })}
              id="description"
              name="description"
              onChange={e => setDescription(e.target.value)}
              rows={3}
              required
              className="shadow-sm focus:ring-meny-700 focus:border-meny-500 block w-full sm:text-sm border border-gray-300 rounded-md"
              defaultValue={""}
            />
            <ErrorMessage
              errors={errors}
              name="description"
              render={({ message }) => (
                <p className="text-sm text-red-600">{message}</p>
              )}
            />
          </div>

          <div className="mr-10 relative flex items-start pt-4">
            <div className="min-w-0 flex-1 text-sm">
              <p id="comments-description" className="text-gray-600">
                Jeg ønsker å beholde mitt abonnement på tidsskrift etter
                utmelding.
              </p>
            </div>
            <div className="ml-3 flex items-center h-5 mt-2">
              <input
                {...register("subscriptionMagazine")}
                id="subscriptionMagazine"
                key="subscriptionMagazine"
                type="checkbox"
                defaultChecked={false}
                // value={event?.target.checked}
                onChange={MagazineChanged}
                className="text-meny-600 relative justify-end inline-flex flex-shrink-0 h-6 w-11 border-2 border-gray-300 rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
              />
            </div>
          </div>
          <div className="mr-10 relative flex items-start mb-4">
            <div className="min-w-0 flex-1 text-sm">
              <a
                href="https://psykologtidsskriftet.no/abonnement-og-lossalg"
                id="comments-description"
                target="_blank"
                className="text-meny-600 hover:text-meny-500 hover:cursor-pointer italic"
              >
                Se pris og vilkår for privatabonnement her
              </a>
            </div>
          </div>

          <div className="mr-10 relative flex items-start py-4">
            <div className="min-w-0 flex-1 text-sm">
              <p id="comments-description" className="text-gray-600">
                Jeg ønsker å beholde mitt medlemskap i underforeninger etter
                utmelding.
              </p>
            </div>
            <div className="ml-3 flex items-center h-5 mt-2">
              <input
                {...register("continueMembership")}
                id="continueMembership"
                key="continueMembership"
                type="checkbox"
                defaultChecked={false}
                // value={event?.target.checked}
                onChange={MembershipChanged}
                className="text-meny-600 relative justify-end inline-flex flex-shrink-0 h-6 w-11 border-2 border-gray-300 rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
              />
            </div>
          </div>

          <div className="pt-5">
            <div className="flex justify-center">
              <button
                type="submit"
                // onSubmit={createIncident}
                className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-meny-700 hover:bg-meny-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
              >
                Send inn
              </button>
            </div>
          </div>
        </div>
      </form>
    </MyPageLayout>
  )
}

const CloseMembership = () => {
  const authRequest = {
    ...loginRequest,
  }
  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      <CloseMembershipContent />
    </MsalAuthenticationTemplate>
  )
}

export default CloseMembership
