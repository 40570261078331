import { useAccount, useMsal } from "@azure/msal-react"
import React, { useEffect, useState } from "react"
import { useQuery, useQueryClient } from "react-query"
import { fetchApi } from "../../../../Utils/Api.utils"
import { DateTimeToNorwegian } from "../../../../Utils/DateFormater"
import { RemoveUnderScore } from "../../../../Utils/FormatHelper"
import DocumentList from "../../document/DocumentList"

const SupervisorsApprovalDetail = ({
  activity,
  program,
  documents,
  mutationGetDocuments,
}) => {
  const queryClient = useQueryClient()
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const [isApprovedSupervisor, setIsApprovedSupervisor] = useState("Nei")
  const [reasonDispensation, setReasonDispensation] = useState("")
  const [superviserSpeciality, setSuperviserSpeciality] = useState("")
  const [superviserIsSpecialist, setSuperviserIsSpecialist] = useState("")

  useQuery(
    "specialitiesDataActive",
    () =>
      fetchApi(
        process.env.GATSBY_API_URL + "/Specialities/all",
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      enabled: !!activity?.supervisorSpeciality,
      onSuccess: data => {
        const supervisorSpecialityObj = data.find(
          r => r.id === activity.supervisorSpeciality
        )
        setSuperviserSpeciality(supervisorSpecialityObj.name)
      },
      onError: _error => {},
    }
  )

  useEffect(() => {
    if (activity?.approvedSupervisor) setIsApprovedSupervisor("Ja")

    if (activity?.supervisorIsSpecialist == 292460000)
      setSuperviserIsSpecialist("Spesialist")
    if (activity?.supervisorIsSpecialist == 292460001)
      setSuperviserIsSpecialist("Ikke spesialist")
    if (activity?.supervisorIsSpecialist == 292460002)
      setSuperviserIsSpecialist("Delvis fullført spesialitet")

    if (activity?.reasonDispensation == 292460000)
      setReasonDispensation("Ingen spesialister tilgjengelig på arbeidsplassen")
    if (activity?.reasonDispensation == 292460001)
      setReasonDispensation(
        "Ingen spesialister tilgjengelig i nærheten geografisk"
      )
    if (activity?.reasonDispensation == 292460002)
      setReasonDispensation(
        "Ingen spesialister med riktig spesialitet tilgjengelig på arbeidsplassen"
      )
    if (activity?.reasonDispensation == 292460003)
      setReasonDispensation(
        "Ingen spesialister med riktig spesialitet tilgjengelig i nærheten geografisk"
      )
    if (activity?.reasonDispensation == 292460004)
      setReasonDispensation("Andre grunner (spesifiser)")
  }, [activity])

  return (
    <div>
      <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          {/* <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Navn</dt>
            <dd className="mt-1 text-sm text-gray-900">{activity.name}</dd>
          </div> */}
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Status</dt>
            {/* Godkjent */}
            {activity.approveStatus === 778380000 ? (
              <dd className="mt-1">
                <span className="inline-flex rounded-full bg-green-300 px-2 text-xs font-semibold leading-5 text-black">
                  {RemoveUnderScore(activity.approveStatusName)}
                </span>
              </dd>
            ) : null}
            {/* Til godkjenning */}
            {activity.approveStatus === 778380001 ? (
              <dd className="mt-1">
                <span className="inline-flex rounded-full bg-yellow-300 px-2 text-xs font-semibold leading-5 text-black">
                  {RemoveUnderScore(activity.approveStatusName)}
                </span>
              </dd>
            ) : null}
            {/* Avslag */}
            {activity.approveStatus === 778380002 ? (
              <dd className="mt-1 ">
                <span className="inline-flex rounded-full bg-red-300 px-2 text-xs font-semibold leading-5 text-black">
                  {RemoveUnderScore(activity.approveStatusName)}
                </span>
              </dd>
            ) : null}
            {/* Påbegynt */}
            {activity.approveStatus === 778380003 ? (
              <dd className="mt-1">
                <span className="inline-flex rounded-full bg-blue-300 px-2 text-xs font-semibold leading-5 text-black">
                  {RemoveUnderScore(activity.approveStatusName)}
                </span>
              </dd>
            ) : null}

            {/* Ikke fullført registrering */}
            {activity.approveStatus === 292460000 ? (
              <dd className="mt-1">
                <span className="inline-flex rounded-full bg-orange-300 px-2 text-xs font-semibold leading-5 text-black">
                  {RemoveUnderScore(activity.approveStatusName)}
                </span>
              </dd>
            ) : null}
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Programnavn</dt>
            <dd className="mt-1 text-sm text-gray-900">
              {program.programType?.name}
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-600">Antall timer</dt>
            <dd className="mt-1 text-sm text-gray-900">
              {activity.amountHours}
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">
              Årsak dispensasjon
            </dt>
            <dd className="mt-1 text-sm text-gray-900">{reasonDispensation}</dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">
              Årsak dispensasjon text
            </dt>
            <dd className="mt-1 text-sm text-gray-900">
              {activity.reasonDispensationText}
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">
              Veileders Navn
            </dt>
            <dd className="mt-1 text-sm text-gray-900">
              {activity.supervisorText}
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">
              Veileders HPRno
            </dt>
            <dd className="mt-1 text-sm text-gray-900">
              {activity.supervisorNo}
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">
              Er veileder spesialist?
            </dt>
            <dd className="mt-1 text-sm text-gray-900">
              {superviserIsSpecialist}
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">
              Veileder spesialitet
            </dt>
            <dd className="mt-1 text-sm text-gray-900">
              {superviserSpeciality}
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">
              Delvis fullført spesialitet
            </dt>
            <dd className="mt-1 text-sm text-gray-900">
              {activity.supervisorSpecialityText}
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">
              Veileder erfaring
            </dt>
            <dd className="mt-1 text-sm text-gray-900">
              {activity.superVisorExperience}
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">
              Veileder begrunnelse
            </dt>
            <dd className="mt-1 text-sm text-gray-900">
              {activity.reasonForSupervisor}
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">
              Godkjent veileder?
            </dt>
            <dd className="mt-1 text-sm text-gray-900">
              {isApprovedSupervisor}
            </dd>
          </div>
          <div className="sm:col-span-2">
            <dt className="text-sm font-medium text-gray-500">
              Godkjent veileder Info
            </dt>
            <dd className="mt-1 text-sm text-gray-900">
              {activity.approvedSupervisorInfo}
            </dd>
          </div>
          {/* <div className="sm:col-span-2">
                        <dt className="text-sm font-medium text-gray-600">Vedlegg</dt>
                        <dd className="mt-1 text-sm text-gray-900">
                            <DocumentList
                                documents={documents}
                                mutationGetDocuments={mutationGetDocuments}
                                canDelete={false}
                            />
                        </dd>
                    </div> */}
        </dl>
      </div>
    </div>
  )
}

export default SupervisorsApprovalDetail
