import { Link } from "gatsby"
import React, { useEffect, useState } from "react"
import { useQuery } from "react-query"
import { fetchApiAnonymous } from "../../../Utils/Api.utils"
import {
  DateTimeToNorwegian,
  DateTimeToDateTime,
  DateTimeToDate,
  DateTimeToTime,
} from "../../../Utils/DateFormater"
import { Accordion } from "../../common/accordion"
import ContentBox from "../../common/inputFields/ContentBox"
import { SessionType } from "../event/EventType"
import EventSessions from "./EventSessions"

const MyEvent = props => {
  const event = props.event
  const key = event?.id
  const header = event?.name
  const startDate = DateTimeToNorwegian(event?.startDate)
  const endDate = DateTimeToNorwegian(event?.endDate)
  // const [completed, setCompleted] = useState<string>()
  let content = {
    key: key,
    header: header,
  }

  const [accordionSubtitle, setAccordionSubtitle] = useState("")

  const [access, setAccess] = useState<boolean>(false)

  useEffect(() => {
    const today = new Date()

    const startDate = DateTimeToDate(event?.startDate)
    const endDate = DateTimeToDate(event?.endDate)

    if (new Date(event?.endDate) < today) {
      const diffDays = (today, end) =>
        Math.ceil(Math.abs(end - today) / (1000 * 60 * 60 * 24))

      const difference = diffDays(today, new Date(event?.endDate))

      // if session was more than three months ago
      if (difference > 90) {
        setAccess(false)
      } else {
        setAccess(true)
      }
    } else setAccess(true)

    if (startDate === endDate) {
      const startTime = DateTimeToTime(event?.startDate)
      const endTime = DateTimeToTime(event?.endDate)

      setAccordionSubtitle(startDate)
    } else {
      const startDateTime = DateTimeToDate(event?.startDate)
      const endDateTime = DateTimeToDate(event?.endDate)

      setAccordionSubtitle(startDateTime + " - " + endDateTime)
    }
  }, [event?.startDate, event?.endDate])

  return (
    <>
      <Accordion title={event?.name} subTitle={accordionSubtitle}>
        <>
          <div className="sm:grid sm:grid-cols-2 gap-3 mb-4">
            <p className="text-base text-gray-600 sm:col-span-1">
              Type kurs: {event?.eventType?.name}
            </p>
            <p className="text-base text-gray-600 sm:col-span-1">
              Prosjektnummer: {event?.projectNo}
            </p>
            <p className="text-base text-gray-600 sm:col-span-1">
              Min rolle: {event?.myEngagementRole}
            </p>

            {event?.building?.name != undefined ? (
              <p className="text-base text-gray-600 sm:col-span-1">
                Sted: {event?.building?.name}
              </p>
            ) : (
              <p className="text-base text-gray-600 sm:col-span-1">
                Sted: Sted ikke valgt enda
              </p>
            )}

            {event?.startDate != undefined ? (
              <p className="text-base text-gray-600 sm:col-span-1">
                Startdato: {DateTimeToDateTime(event?.startDate)}
              </p>
            ) : (
              <p className="text-base text-gray-600 sm:col-span-1">
                Startdato:
              </p>
            )}

            {event?.city != undefined ? (
              <p className="text-base text-gray-600 sm:col-span-1">
                By: {event?.cityName}
              </p>
            ) : (
              <p className="text-base text-gray-600 sm:col-span-1">
                By: By ikke valgt enda
              </p>
            )}

            {event?.endDate != undefined ? (
              <p className="text-base text-gray-600 sm:col-span-1">
                Sluttdato: {DateTimeToDateTime(event?.endDate)}
              </p>
            ) : (
              <p className="text-base text-gray-600 sm:col-span-1">
                Sluttdato:
              </p>
            )}
            {event?.checkinCompleted ? (
              <p className="text-base text-gray-600 sm:col-span-1">
                Oppmøte utført: Ja
              </p>
            ) : (
              <p className="text-base text-gray-600 sm:col-span-1">
                Oppmøte utført: Nei
              </p>
            )}
          </div>
          <div className="sm:grid sm:grid-cols-2 gap-3 mb-4">
            {/* Kurstype 'Ingen delmøter' */}

            <>
              {/* {access ? ( */}
              <div className="sm:col-span-1 mb-3">
                <Link
                  to={"/app/kurs/administrasjon/kursdeltager"}
                  state={{ event: event }}
                  className="ml-0 inline-flex justify-end py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-md text-white bg-meny-700 hover:bg-meny-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
                >
                  {" "}
                  Les mer
                </Link>
              </div>
              {/* ) : (
                <></>
              )} */}

              {/* <div className="sm:col-span-1">
                <Link
                  to={"/app/kurs/administrasjon/kursdeltager"}
                  className="ml-0 inline-flex justify-end py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-md text-white bg-meny-700 hover:bg-meny-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
                >
                  Kursmateriell{" "}
                </Link>
              </div> */}
            </>
          </div>
        </>
      </Accordion>
    </>
  )
}

export default MyEvent
