import React from "react"
import { DateTimeToDateTime } from "../../../../Utils/DateFormater"
import { EventRegistration } from "../../../mypagecomponents/event/EventType"
import {
  CityOptionsetValueToString,
  StatusAttendeeToString,
} from "../../../mypagecomponents/event/helpers/Event"

const EventDataSection = (props: EventRegistration) => {
  const data: { lable: string; value?: string }[] = [
    {
      lable: "Start dato",
      value: DateTimeToDateTime(props.eventCrm?.eventstartdate),
    },
    {
      lable: "Slutt dato",
      value: DateTimeToDateTime(props.eventCrm?.eventenddate),
    },
    {
      lable: "Påmeldingsfrist",
      value: DateTimeToDateTime(props.eventCrm?.stopwebsiteregistrationson),
    },
    { lable: "Antall timer", value: props.eventCrm?.amounthours?.toString() },
    { lable: "By", value: CityOptionsetValueToString(props.eventCrm?.city) },
    { lable: "Sted", value: props.eventCrm?.building?.name },
    {
      lable: "Deltagerstatus",
      value: StatusAttendeeToString(props.statusattendee),
    },
    {
      lable: "Avmeldingsfrist",
      value: DateTimeToDateTime(props.eventCrm?.stopwebsitecancellationon),
    },
  ]

  return (
    <div>
      <h1 className="mt-8 text-2xl font-bold text-meny-700">Kursinformasjon</h1>
      <div className="w-full h-px bg-gray-200 mb-4"></div>
      <div className="divide-y divide-gray-200 bg-white rounded-lg px-4">
        {data
          .filter(item => item.value !== undefined)
          .map(item => (
            <div
              key={item.lable}
              className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt className="text-sm font-medium text-gray-900">
                {item.lable}
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {item.value?.split("\n").map(item2 => (
                  <div key={item2}>{item2}</div>
                ))}
              </dd>
            </div>
          ))}
      </div>
    </div>
  )
}

export default EventDataSection
