import { InteractionType } from "@azure/msal-browser"
import {
  MsalAuthenticationTemplate,
  useAccount,
  useMsal,
} from "@azure/msal-react"
import { PaperClipIcon } from "@heroicons/react/outline"
import { Link, navigate } from "gatsby"
import React, { useEffect, useState } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { toast } from "react-toastify"
import {
  cyanButton,
  redButton,
  whiteButton,
} from "../../../styles/tailwindClasses"
import {
  fetchApi,
  patchApi,
  postGetDocumentApi,
} from "../../../Utils/Api.utils"
import { RemoveUnderScore } from "../../../Utils/FormatHelper"
import { Loading } from "../../../Utils/Loading"
import { Incidents, Activity } from "../../../Utils/Types"
import { ErrorComponent } from "../../common/graphichs/ErrorComponent"
import LoadingData from "../../common/graphichs/LoadingData"
import { classNames } from "../../common/graphichs/misc"
import MyPageLayout from "../../common/layouts/myPageLayout"
import MyPageLayoutEducation from "../../common/layouts/MyPageLayoutEducation"
import DocumentList from "../../mypagecomponents/document/DocumentList"
import FileUpload from "../../mypagecomponents/document/FileUpload"
import ExternalCourseDetail from "../../mypagecomponents/specialityeducation/activitydetails/ExternalCourseDetail"
import GuidanceActivityDetail from "../../mypagecomponents/specialityeducation/activitydetails/GuidanceActivityDetail"
import GuidanceCertificateDetail from "../../mypagecomponents/specialityeducation/activitydetails/GuidanceCertificateDetail"
import GuidanceContractDetail from "../../mypagecomponents/specialityeducation/activitydetails/GuidanceContractDetail"
import PersonalActivityDetail from "../../mypagecomponents/specialityeducation/activitydetails/PersonalActivityDetail"
import PracticeCertificateDetail from "../../mypagecomponents/specialityeducation/activitydetails/PracticeCertificateDetail"
import SupervisorsApprovalDetail from "../../mypagecomponents/specialityeducation/activitydetails/SupervisorsApprovalDetail"
import WrittenWorkDetail from "../../mypagecomponents/specialityeducation/activitydetails/WrittenWorkDetail"
import { loginRequest } from "../../security/authConfig"
import ConsultantActivityDetail from "../../mypagecomponents/specialityeducation/activitydetails/ConsultantActivityDetail"

const SpecializationActivityContent = ({ location }) => {
  const queryClient = useQueryClient()
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const [activity, setActivity] = useState<Activity>(location.state.activity)
  const [program, setProgram] = useState({})
  const [incident, setIncident] = useState({})
  const [documents, setDocuments] = useState([])
  const [practiceHours, setPracticeHours] = useState([])
  const [showDocumentComponent, setShowDocumentComponent] = useState(false)
  const [activityType, setActivityType] = useState<string>()
  const [showApproval, setShowApproval] = useState(false)
  const [approveStatus, setApproveStatus] = useState<number>()
  const [status, setStatus] = useState<number>()
  const [statusCode, setStatusCode] = useState<number>()
  const [showLoading, setShowLoading] = useState<boolean>(false)
  const [stateCode, setStateCode] = useState<number>()
  const [showCancel, setShowCancel] = useState<boolean>(false)
  const [showEdit, setShowEdit] = useState<boolean>(false)

  useEffect(() => {
    if (activity?.incidentList !== null && activity?.incidentList !== undefined && activity?.incidentList.length > 0) setIncident(activity?.incidentList?.[0])
    if (
      activity.approveStatus == 778380003 ||
      activity.approveStatus == 778380001 ||
      activity.approveStatus == 292460000
    ) {
      setShowDocumentComponent(true)
      setShowCancel(true)
    } else {
      setShowDocumentComponent(false)
      setShowCancel(false)
    }

    // Ikke fullført registrering:
    if (activity.approveStatus == 292460000) {
      setShowApproval(true)
      setShowEdit(true)
    }
    // Til godkjenning
    else if (activity.approveStatus == 778380001) setShowEdit(true)
    else {
      setShowEdit(false)
      setShowApproval(false)
    }

    // if (location.state.activity) setActivity(location.state.activity)
    if (location.state.program) setProgram(location.state.program)

    if (location.state.activity.activityType == "292460000")
      setActivityType("Ekstern kurs")
    if (location.state.activity.activityType == "292460001")
      setActivityType("Praksisattest")
    if (location.state.activity.activityType == "292460003")
      setActivityType("Skriftlig arbeid")
    if (location.state.activity.activityType == "292460004")
      setActivityType("Veiledningsattest")
    if (location.state.activity.activityType == "292460002")
      setActivityType("Veiledningskontrakt")
    if (location.state.activity.activityType == "292460005")
      setActivityType("Godkjenning veileder")
    if (location.state.activity.activityType == "292460006")
      setActivityType("Vedlikeholdsaktivitet")
    if (location.state.activity.activityType == "292460007")
      setActivityType("Egenaktivitet")
    if (location.state.activity.activityType == "292460008")
      setActivityType("Konsulentsyklus")

    mutationGetDocuments.mutate(location.state.activity.id)
  }, [location, activity, program])

  const activityInfo = useQuery(
    "activityInfo",
    () =>
      fetchApi(
        `${process.env.GATSBY_API_URL}/SpecializationActivities/${location.state.activity.id}`,
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: data => {
        setActivity(data)
      },
      onError: _error => { },
    }
  )

  const mutationUpdateIncident = useMutation(
    () => {
      return patchApi(
        process.env.GATSBY_API_URL + "/Incidents/activityIncident",
        {
          activitySpecialistProgramId: activity?.id,
          state: status,
          status: statusCode,
          approveStatus: approveStatus,
        },
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: () => {
        navigate("/app/kurs/specialityeducation/program", {
          state: { program: location.state.program },
        })
      },
      onError: _error => {
        setShowLoading(false)
      },
      onSettled: () => { },
    }
  )

  const mutationUpdateSpesActivity = useMutation(
    data => {
      return patchApi(
        process.env.GATSBY_API_URL + "/SpecializationActivities",
        data,
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: () => {
        // setShowDocumentComponent(false)
        mutationUpdateIncident.mutate()
      },
      onError: _error => {
        setShowLoading(false)
      },
      onSettled: () => { },
    }
  )

  const cancelActivity = () => {
    const activityData = {
      id: activity?.id,
      stateCode: 1,
      status: 2,
    }
    setApproveStatus(undefined)
    setStatus(2) // inaktiv/avbrutt
    setStatusCode(292460001) // Kansellert av portalbruker
    setShowLoading(true)

    mutationUpdateSpesActivity.mutate(activityData)
  }

  const approvalRequest = () => {
    if (documents.length == 0) {
      toast.warning("Vennligst last opp en eller flere filer!", {
        position: "top-center",
        autoClose: 10000,
      })

      return
    }
    const activityData = {
      id: activity?.id,
      approveStatus: 778380001,
    }
    setApproveStatus(778380001) // til godkjenning
    setStatus(undefined)
    setStatusCode(1) // Pågår
    setShowLoading(true)

    mutationUpdateSpesActivity.mutate(activityData)
  }

  const userPracticeHoursQuery = useQuery(
    "userPracticeHoursData",
    () =>
      fetchApi(
        process.env.GATSBY_API_URL +
        "/PracticeHours/" +
        location.state.activity.id,
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: data => {
        setPracticeHours(data)
      },
      onError: _error => { },
    }
  )

  const mutationGetDocuments = useMutation(
    activityid => {
      return postGetDocumentApi(
        process.env.GATSBY_API_URL + "/Documents/Regarding/Get",
        {
          ActivitySpecialistProgramId: location.state.activity.id,
        },
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: data => {
        setDocuments(data)
      },
      onError: _error => { },
      onSettled: () => { },
    }
  )

  return showLoading ? (
    // // "Oppdaterer..."
    <LoadingData subject="Oppdaterer..." />
  ) : activityInfo.isLoading ? (
    <LoadingData />
  ) : (
    <MyPageLayoutEducation props={"Spesialistaktivitet: " + activityType}>
      <Link
        to="/app/kurs/specialityeducation/program"
        state={{ program: location.state.program }}
        className={
          whiteButton +
          " inline-flex mb-8 items-center justify-center sm:w-auto "
        }
        activeClassName="active-link"
      >
        Tilbake
      </Link>
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6 bg-meny-700">
          <h3 className="text-lg leading-6 font-medium text-white">
            Aktivitetsdetaljer
          </h3>
        </div>
        {activity?.activityType == 292460000 ? (
          <ExternalCourseDetail
            activity={activity}
            program={program}
            documents={documents}
            mutationGetDocuments={mutationGetDocuments}
          />
        ) : null}
        {activity?.activityType == 292460003 ? (
          <WrittenWorkDetail
            activity={activity}
            program={program}
            documents={documents}
            mutationGetDocuments={mutationGetDocuments}
          />
        ) : null}
        {activity?.activityType == 292460004 ? (
          <GuidanceCertificateDetail
            activity={activity}
            program={program}
            documents={documents}
            mutationGetDocuments={mutationGetDocuments}
          />
        ) : null}
        {activity?.activityType == 292460002 ? (
          <GuidanceContractDetail
            activity={activity}
            program={program}
            documents={documents}
            mutationGetDocuments={mutationGetDocuments}
          />
        ) : null}
        {activity?.activityType == 292460006 ? (
          <GuidanceActivityDetail
            activity={activity}
            program={program}
            documents={documents}
            mutationGetDocuments={mutationGetDocuments}
          />
        ) : null}
        {activity?.activityType == 292460005 ? (
          <SupervisorsApprovalDetail
            activity={activity}
            program={program}
            documents={documents}
            mutationGetDocuments={mutationGetDocuments}
          />
        ) : null}
        {activity?.activityType == 292460007 ? (
          <PersonalActivityDetail
            activity={activity}
            program={program}
            documents={documents}
            mutationGetDocuments={mutationGetDocuments}
          />
        ) : null}
        {activity?.activityType == 292460008 ? (
          <ConsultantActivityDetail
            activity={activity}
            program={program}
            documents={documents}
            mutationGetDocuments={mutationGetDocuments}
          />
        ) : null}
        {activity?.activityType == 292460001 ? (
          <div>
            <PracticeCertificateDetail
              activity={activity}
              program={program}
              documents={documents}
              mutationGetDocuments={mutationGetDocuments}
            />
            <div className="px-4 py-5 sm:px-6 ">
              <h3 className="text-lg leading-6 font-medium ">
                Praksisdetaljer
              </h3>
            </div>
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-meny-700">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-6"
                    >
                      Fra
                    </th>
                    <th
                      scope="col"
                      className="hidden px-3 py-3.5 text-left text-sm font-semibold text-white lg:table-cell"
                    >
                      Til
                    </th>
                    <th
                      scope="col"
                      className="hidden px-3 py-3.5 text-left text-sm font-semibold text-white sm:table-cell"
                    >
                      Praksis %
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-white"
                    >
                      Ant. uker
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-white"
                    >
                      Sum praksis
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {practiceHours?.map(practiceHour => (
                    <tr
                      key={practiceHour.rowId}
                      className={classNames(
                        showEdit ? "hover:bg-meny-50 hover:cursor-pointer" : ""
                      )}
                      onClick={() => {
                        if (showEdit) {
                          navigate(
                            "/app/kurs/specialityeducation/activity/endrePraksis",
                            {
                              state: {
                                practiceHour: practiceHour,
                                activity: activity,
                                allPracticeHours: practiceHours,
                              },
                            }
                          )
                        }
                      }}
                    >
                      <td className="px-3 pl-4 py-4 text-sm text-gray-600 ">
                        {practiceHour.fromDate !== undefined ? (
                          <time dateTime={practiceHour.fromDate}>
                            {new Intl.DateTimeFormat("nb-NO", {
                              dateStyle: "medium",
                              // timeStyle: "short",
                            }).format(new Date(practiceHour.fromDate))}
                          </time>
                        ) : null}
                      </td>
                      <td className="hidden px-3 py-4 text-sm text-gray-600 lg:table-cell">
                        {practiceHour.toDate !== undefined ? (
                          <time dateTime={practiceHour.toDate}>
                            {new Intl.DateTimeFormat("nb-NO", {
                              dateStyle: "medium",
                              // timeStyle: "short",
                            }).format(new Date(practiceHour.toDate))}
                          </time>
                        ) : null}
                      </td>
                      <td className="hidden px-3 py-4 text-sm text-gray-600 sm:table-cell">
                        {practiceHour.percentagePracticePosition}
                      </td>
                      <td className="px-3 py-4 text-sm text-gray-600">
                        {practiceHour.diffMonths}
                      </td>
                      <td className="px-3 py-4 text-sm text-gray-600">
                        {practiceHour.practice}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="pt-5 mb-5">
              <div className="flex justify-center">
                {showEdit ? (
                  <Link
                    to="/app/kurs/specialityeducation/nyPraksisdetalj"
                    state={{ activity: activity, practiceHours: practiceHours }}
                    className={cyanButton + " inline-flex items-center mr-2 "}
                    activeClassName="active-link"
                  >
                    Legg til
                  </Link>
                ) : null}
              </div>
            </div>
          </div>
        ) : null}
        {showDocumentComponent ? (
          <div>
            <div className="mt-2">
              <DocumentList
                documents={documents}
                mutationGetDocuments={mutationGetDocuments}
                canDelete={false}
              />
            </div>
            <h4 className="text-lg text-center font-bold mt-3 ">
              Last opp flere dokumenter
            </h4>
            <p className="mt-2 text-sm text-gray-600"></p>
            <p className="mt-2 text-sm text-gray-600"></p>
            <FileUpload
              entityId={activity?.id}
              entityName={"pp_activityspecialistprogram"}
              entityId2={incident?.id}
              entityName2={"incident"}
              entityId3={location.state.program?.specialistEducation?.id}
              entityName3={"pp_education"}
              mutationGetDocuments={mutationGetDocuments}
              setDocuments={null}
              isRequired={false}
              isReturn={false}
            />
          </div>
        ) : null}

        <div className="pt-5 mb-5">
          <div className="flex justify-center">
            {showApproval ? (
              <button
                type="button"
                onClick={approvalRequest}
                className={cyanButton + " inline-flex items-center mr-2 "}
              >
                Til godkjenning
              </button>
            ) : null}
            {showEdit ? (
              <Link
                to="/app/kurs/specialityeducation/endreAktivitet"
                state={{ activity: activity, program: program }}
                className={cyanButton + " inline-flex items-center mr-2 "}
                activeClassName="active-link"
              >
                Endre
              </Link>
            ) : null}
            {showCancel ? (
              <button
                type="button"
                onClick={cancelActivity}
                className={redButton + " inline-flex items-center "}
              >
                Kanseller
              </button>
            ) : null}
          </div>
        </div>
      </div>
    </MyPageLayoutEducation>
  )
}

const SpecializationActivity = ({ location }) => {
  const authRequest = {
    ...loginRequest,
  }
  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      <SpecializationActivityContent location={location} />
    </MsalAuthenticationTemplate>
  )
}

export default SpecializationActivity
