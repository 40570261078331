import React from "react"
import { OtherPayerFields } from "../../../mypagecomponents/event/EventType"
import { LableDataComp, lableDataType, LableZipDataComp } from "./LableDataComp"

const OtherPayer = (props: {
  payerData: Array<{ id: string; payload?: string }>
  setPayerData: (data: Array<{ id: string; payload?: string }>) => void
}) => {
  const onChange = (id: OtherPayerFields, e: any) => {
    const newData = { id: id, payload: e.target.value }

    const newPayerData = props.payerData.map(item =>
      item.id === id ? newData : item
    )

    props.setPayerData(newPayerData)
  }

  console.log("payerData", props.payerData)

  const labels: Array<lableDataType> = [
    { displayName: "Navn:*", dataName: "name" },
    { displayName: "Org.nr:*", dataName: "orgNr" },
    { displayName: "Fakturaadr:*", dataName: "invoiceAdr" },
    { displayName: "Postnr:*", dataName: "zipCode" },
    { displayName: "Fakturaref. 1 / PO:*", dataName: "invoiceRef1" },
    { displayName: "Fakturaref. 2/ Kontaktperson:", dataName: "invoiceRef2" },
  ]

  return (
    <div className="space-y-4">
      {labels.map(label => {
        const data = props.payerData.find(item => item.id === label.dataName)
        if (label.dataName === "zipCode") {
          return (
            <LableZipDataComp
              key={data?.id}
              {...label}
              onChange={onChange}
              defaultValue={data?.payload}
            />
          )
        }

        return (
          <LableDataComp
            key={data?.id}
            {...label}
            onChange={onChange}
            defaultValue={data?.payload}
          />
        )
      })}
    </div>
  )
}

export default OtherPayer
