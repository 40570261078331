import { InteractionType } from "@azure/msal-browser"
import {
  MsalAuthenticationTemplate,
  useAccount,
  useMsal,
} from "@azure/msal-react"
import React, { useEffect, useState } from "react"
import { useQuery, useQueryClient } from "react-query"
import { fetchApi } from "../../../Utils/Api.utils"
import { Education } from "../../../Utils/Entities"
import { Loading } from "../../../Utils/Loading"
import { ErrorComponent } from "../../common/graphichs/ErrorComponent"
import LoadingData from "../../common/graphichs/LoadingData"
import MyPageLayout from "../../common/layouts/myPageLayout"
import { loginRequest } from "../../security/authConfig"
import MyEducation from "../../mypagecomponents/education/MyEducation"

const EducationContent = () => {
  const queryClient = useQueryClient()
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const [educations, setEducation] = useState<Education[]>()

  const educationInfoQuery = useQuery(
    "educationInfo",
    () =>
      fetchApi(
        process.env.GATSBY_API_URL + "/Contacts/Educations",
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: data => {},
      onError: _error => {},
    }
  )

  return educationInfoQuery.isLoading ? (
    <LoadingData />
  ) : (
    <>
      <MyPageLayout props="Utdanning">
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-semibold text-meny-800">
                Grunnutdanning og annen utdanning
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                Liste over grunnutdanning og annen utdanning
              </p>
            </div>
            {/* <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <button
              type="button"
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-meny-700 focus:ring-offset-2 sm:w-auto"
            >
              Add user
            </button>
          </div> */}
          </div>
          {educationInfoQuery?.data?.length > 0 ? (
            <div className="-mx-4 mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-meny-700">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 text-left text-sm font-semibold text-meny-50 sm:pl-6"
                    >
                      Akademisk tittel
                    </th>

                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-meny-50 table2:hidden"
                    >
                      Tittel på hovedoppgave
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-meny-50 table1:hidden"
                    >
                      Karakter
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-meny-50"
                    >
                      Utdanningssted
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-meny-50 table1:hidden"
                    >
                      Dato fra
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-meny-50"
                    >
                      Dato til
                    </th>
                    {/* <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                  <span className="sr-only">Edit</span>
                </th> */}
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {educationInfoQuery.data?.map(education => {
                    if (education?.educationType == 1)
                      return <MyEducation education={education} />
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <>
              <p className="italic flex justify-center mt-3 max-w-md mx-auto text-base text-gray-600 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
                Det finnes ingen oppføringer.
              </p>
            </>
          )}
        </div>
      </MyPageLayout>
    </>
  )
}

const Educations = () => {
  const authRequest = {
    ...loginRequest,
  }
  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      <EducationContent />
    </MsalAuthenticationTemplate>
  )
}

export default Educations
