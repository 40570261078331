import { Link } from "gatsby"
import React, { useState } from "react"
import { useQuery } from "react-query"
import { StringParam, useQueryParam } from "use-query-params"
import { fetchApiAnonymous } from "../../../Utils/Api.utils"
import MyPageLayout from "../../common/layouts/myPageLayout"
import { EventType } from "../../mypagecomponents/event/EventType"

const Confirmation = () => {
  const eventId = useQueryParam("id", StringParam)[0]

  const [event, setEvent] = useState<EventType | undefined>(undefined)

  useQuery<EventType>(
    "event-" + eventId,
    async () =>
      fetchApiAnonymous(process.env.GATSBY_API_URL + `/Events/${eventId}`).then(
        res => res.data
      ),
    {
      onSuccess: data => {
        setEvent(data)
      },
    }
  )

  return (
    <MyPageLayout props="Påmeldingsbekreftelse">
      <div>
        <h1>Takk for din påmelding til kurset: {event?.eventName || ""}</h1>
        <div className="w-full h-px bg-gray-200 mb-4"></div>

        <div className="py-6">
          <Link to="/app/kurs/kurskalender">
            <p className="hover:text-blue-600 hover:underline">
              Gå til kurskalender
            </p>
          </Link>
          <Link to="/app/kurs/minekurs">
            <p className="hover:text-blue-600 hover:underline">
              Se kurs du er påmeldt
            </p>
          </Link>
        </div>
      </div>
    </MyPageLayout>
  )
}

export default Confirmation
