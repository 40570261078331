import { useAccount, useMsal } from "@azure/msal-react"
import { useMutation } from "react-query"
import { postApi } from "../../../Utils/Api.utils"
import useNewEmploymentAsIndependent from "./useNewEmploymentAsIndependent"

function useNewAccount(
  prevEmploymentData,
  professionallyActiveStatus,
  setShowLoadingData,
  eventQuery,
  clientAccountWithOrgNumber,
  incidentDescription,
  remainingData
) {
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})

  const newEmploymentAsIndependent = useNewEmploymentAsIndependent(
    prevEmploymentData,
    professionallyActiveStatus,
    setShowLoadingData,
    eventQuery,
    clientAccountWithOrgNumber,
    incidentDescription
  )

  const newAccount = useMutation(
    data => {
      return postApi(
        `${process.env.GATSBY_API_URL}/Accounts`,
        data,
        account,
        inProgress,
        instance
      ).then(res => {
        return res.data
      })
    },
    {
      onSuccess: data => {
        const updatedRemainingData = {
          ...remainingData,
          employer: data,
        }
        newEmploymentAsIndependent.mutate(updatedRemainingData)
      },
      onError: _error => {
        setShowLoadingData(false)
      },
      onSettled: () => {},
    }
  )
  return newAccount
}

export default useNewAccount
