import dateFormat, { i18n } from "dateformat"

i18n.monthNames = [
  "jan",
  "feb",
  "mar",
  "apr",
  "mai",
  "jun",
  "jul",
  "aug",
  "sep",
  "okt",
  "nov",
  "des",
  "januar",
  "februar",
  "mars",
  "april",
  "mai",
  "juni",
  "juli",
  "august",
  "september",
  "oktober",
  "november",
  "desember",
]

//from eg. 2022-05-11T13:04:00 to 11/05/2022 13:04
const DateTimeToDateTime = (dateTime: string | undefined) => {
  if (!dateTime) return ""
  const date = dateTime.split("T")[0].split("-").reverse().join("/")
  const time = dateTime.split("T")[1].split(":").slice(0, 2).join(":")
  return date + " " + time
}

//from eg. 2022-05-11T13:04:00 to 11/05/2022
const DateTimeToDate = (dateTime: string | undefined) => {
  if (!dateTime) return ""
  const date = dateTime.split("T")[0].split("-").reverse().join("/")
  return date
}

//from eg. 2022-05-11T13:04:00 to 13:04
const DateTimeToTime = (dateTime: string | undefined) => {
  if (!dateTime) return ""
  const time = dateTime.split("T")[1].split(":").slice(0, 2).join(":")
  return time
}

//from eg. 2022-05-11T13:04:00 to 11. mai 2022
const DateTimeToNorwegian = (dateTime: Date | undefined) => {
  if (!dateTime) return ""
  return dateFormat(dateTime, "dd. mmmm yyyy")
}

export {
  DateTimeToDateTime,
  DateTimeToDate,
  DateTimeToTime,
  DateTimeToNorwegian,
}
