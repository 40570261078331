import React, { useEffect, useState } from "react"
import { SessionType } from "./EventType"
import { Accordion } from "../../common/accordion"
import {
  DateTimeToDate,
  DateTimeToDateTime,
  DateTimeToTime,
} from "../../../Utils/DateFormater"
import { TextWithIcon } from "../../common/text"
import {
  attendanceValueToString,
  CityOptionsetValueToString,
} from "./helpers/Event"
import { Link } from "gatsby"

const SessionAccoridon = (props: {
  session: SessionType
  link?: { uri: string; linkText: string }
}) => {
  const [accordionSubtitle, setAccordionSubtitle] = useState("")

  useEffect(() => {
    const startDate = DateTimeToDate(props.session.customFields?.startDate)
    const endDate = DateTimeToDate(props.session.customFields?.endDate)

    if (startDate === endDate) {
      const startTime = DateTimeToTime(props.session.customFields?.startDate)
      const endTime = DateTimeToTime(props.session.customFields?.endDate)

      setAccordionSubtitle(startDate + " " + startTime + " - " + endTime)
    } else {
      const startDateTime = DateTimeToDateTime(
        props.session.customFields?.startDate
      )
      const endDateTime = DateTimeToDateTime(
        props.session.customFields?.endDate
      )

      setAccordionSubtitle(startDateTime + " - " + endDateTime)
    }
  }, [
    props.session.customFields?.startDate,
    props.session.customFields?.endDate,
  ])

  return (
    <Accordion title={props.session.name} subTitle={accordionSubtitle}>
      <div>
        <div
          dangerouslySetInnerHTML={{
            __html:
              props.session?.customFields?.calendarContent ||
              "ingen beskrivelse tilgjengelig",
          }}
        ></div>
        {(props.session.passes || []).length > 0 && (
          <>
            <p className="mb-1 pt-6 text-md">
              Pris{" "}
              <a className="text-black text-md">
                (Noe prisøkning kan forekomme)
              </a>
            </p>
            <div className="w-full h-px bg-gray-200 mb-4"></div>
            {(props.session.passes || []).map(pass => {
              return (
                <div className="flex space-x-2 ml-4" key={pass.passId}>
                  <p className="font-medium my-1">{pass.passName + ":"}</p>
                  <p className="my-1">
                    {pass.price + " " + pass.currencySymbol}
                  </p>
                </div>
              )
            })}
          </>
        )}
        <h4 className="mb-1 pt-6">Detaljer</h4>
        <div className="w-full h-px bg-gray-200 mb-4"></div>
        <div className="flex w-full pb-4">
          <div className="w-full pl-4 pt-4 space-y-4">
            {props.session.customFields?.projectno && (
              <TextWithIcon
                text={"Prosjektnr: " + props.session.customFields?.projectno}
              />
            )}
            <TextWithIcon
              text={
                "Start Dato: " +
                DateTimeToDateTime(props.session.customFields?.startDate)
              }
            />
            <TextWithIcon
              text={
                "Slutt Dato: " +
                DateTimeToDateTime(props.session.customFields?.endDate)
              }
            />

            <TextWithIcon
              text={
                "Antall timer: " +
                (props.session.customFields?.amounthours || "")
              }
            />
          </div>
          <div className="w-full pl-4 pt-4 space-y-4">
            <TextWithIcon
              text={
                "Oppmøte type: " +
                attendanceValueToString(
                  props.session.customFields?.attendance?.value
                )
              }
            />
            <TextWithIcon
              text={
                "Kursholder: " +
                (props.session.speakers?.map(item => item.name).join(", ") ||
                  "")
              }
            />
            <TextWithIcon
              text={
                "By: " +
                CityOptionsetValueToString(
                  props.session.customFields?.city?.value
                )
              }
            />
            {/* <TextWithIcon
              text={"Sted: " + (props.session.building?.addressComposite || "")}
            /> */}
          </div>
        </div>
        <div className="flex flex-row py-6">
          {props.link && (
            <Link
              to={props.link?.uri}
              // state={{ workId: work?.id }}
              className="ml-1 inline-flex justify-end py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-md text-white bg-meny-700 hover:bg-meny-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
            >
              {props.link?.linkText || "Les mer"}
            </Link>
          )}
        </div>
      </div>
    </Accordion>
  )
}

export default SessionAccoridon
