import React from "react"
import { SmallListLoading } from "../../common/loading"
import useDocuments from "../../hooks/useDocuments/useDocuments"
import DocumentList from "./DocumentList"

const DocumentListSessions = ({ sessionId }) => {
  const { documents, loading, mutate } = useDocuments({
    id: { SessionId: sessionId },
  })

  if (loading)
    return (
      <div className="pb-4">
        <SmallListLoading />
      </div>
    )

  return (
    <div className="pb-6">
      {documents.length > 0 ? (
        <DocumentList
          documents={documents}
          mutationGetDocuments={mutate}
          canDelete={false}
        />
      ) : (
        <p>Ingen Kursmateriell tilgjengelig</p>
      )}
    </div>
  )
}

export default DocumentListSessions
