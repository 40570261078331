import { useAccount, useMsal } from "@azure/msal-react"
import React, { useEffect, useState } from "react"
import { useQuery } from "react-query"
import { fetchApi } from "../../../Utils/Api.utils"
import MyPageLayout from "../../common/layouts/myPageLayout"
import { ListLoading } from "../../common/loading"
import {
  Checkin,
  EventRegistration,
  MyEventsType,
} from "../../mypagecomponents/event/EventType"
import {
  CheckinType,
  EventRegistrationTypeEnum,
  StatusAttendee,
} from "../../mypagecomponents/event/helpers/Event"
import MyCheckinAccordionGroup from "../../mypagecomponents/event/MyCheckinAccordionGroup"
import MyEventAccordionGroup from "../../mypagecomponents/event/MyEventsAccordionGroup"

const MyEvents = () => {
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})

  const [response, setResponse] = useState<MyEventsType | undefined>(undefined)
  const [myEvents, setMyEvents] = useState<Array<EventRegistration>>([])
  const [cancelledEvents, setCancelledEvents] = useState<
    Array<EventRegistration>
  >([])
  const [myPastEvents, setPastMyEvents] = useState<Array<EventRegistration>>([]) // Past events with no checkins
  const [checkins, setCheckins] = useState<Array<Checkin>>([])

  const myEventsQuery = useQuery<MyEventsType>(
    "myEvents",
    async () =>
      fetchApi(
        process.env.GATSBY_API_URL + `/Events/MyEvents`,
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: data => {
        setResponse(data)
      },
    }
  )

  console.log(response)

  useEffect(() => {
    const fillData = () => {
      // const filterdEventRegistration = response?.eventRegistration?.filter(
      //   item =>
      //     checkinEventRegIds.findIndex(
      //       id => item.eventregistrationid === id
      //     ) === -1
      // )

      // remove eventRegistrations where the endDate is in the past
      const filterdEventRegistration = response?.eventRegistration
        ?.filter(item => {
          if (!item.eventCrm?.eventenddate) return true // No end date
          return new Date(item.eventCrm?.eventenddate) > new Date()
        })
        ?.filter(item => {
          if (item.statusattendee !== StatusAttendee.Avlyst) return true
        })
        // .filter(item => {
        //   // if (
        //   //   item.eventCrm?.eventTypeObject?.registrationType ===
        //   //   EventRegistrationTypeEnum.noSessions
        //   // ) {
        //   //   return true
        //   // }
        //   // return (item.sessionRegistrations || [])?.length > 0
        //   if ((item.sessionRegistrations || [])?.length > 0) {
        //     return item
        //   } else return true
        // })
        .map(item => {
          const item2: EventRegistration = {
            ...item,
            sessionRegistrations: item.sessionRegistrations
              ?.filter(
                item2 =>
                  item2.statusattendee !== StatusAttendee.Ikke_oppmøtt &&
                  item2.statusattendee !==
                    StatusAttendee.Avmeldt_gyldig_fravær &&
                  item2.statusattendee !== StatusAttendee.Oppmøtt
              )
              .filter(item2 => {
                return (
                  Date.parse(item2.sessionCrm?.starttime || "") >= Date.now()
                )
              }),
          }
          return item2
        })

      // ascending
      filterdEventRegistration?.sort((a, b) => {
        return (
          new Date(a?.eventCrm?.eventstartdate || "").getTime() -
          new Date(b?.eventCrm?.eventstartdate || "").getTime()
        )
      })

      // remove filterdCancelledEventRegistration where the start date is in the past
      const filterdCancelledEventRegistration = response?.eventRegistration
        ?.filter(item => {
          if (!item.eventCrm?.eventstartdate) return false // No start date
          return new Date(item.eventCrm?.eventstartdate) > new Date()
        })
        ?.filter(item => {
          if (item.statusattendee === StatusAttendee.Avlyst) return true
        })
      // ?.filter(item => {
      //   if ((item.sessionRegistrations || [])?.length > 0) {
      //     return item.sessionRegistrations?.some(
      //       sessionRegistrations =>
      //         sessionRegistrations.statusattendee === StatusAttendee.Avlyst
      //     )
      //   } else if (item?.statusattendee === StatusAttendee.Avlyst) {
      //     return true
      //   }
      // })

      // ascending
      filterdCancelledEventRegistration?.sort((a, b) => {
        return (
          new Date(a?.eventCrm?.eventstartdate || "").getTime() -
          new Date(b?.eventCrm?.eventstartdate || "").getTime()
        )
      })

      const pastEventRegistration = response?.eventRegistration
        ?.filter(
          item =>
            item.statusattendee === StatusAttendee.Ikke_oppmøtt ||
            item.statusattendee === StatusAttendee.Avmeldt_gyldig_fravær ||
            (item.sessionRegistrations &&
              item.sessionRegistrations.findIndex(
                item2 =>
                  item2.statusattendee === StatusAttendee.Ikke_oppmøtt ||
                  item2.statusattendee === StatusAttendee.Avmeldt_gyldig_fravær
              ) > -1)
        )
        .map(item => {
          const item2: EventRegistration = {
            ...item,
            sessionRegistrations: item.sessionRegistrations?.filter(
              item2 =>
                item2.statusattendee === StatusAttendee.Ikke_oppmøtt ||
                item2.statusattendee === StatusAttendee.Avmeldt_gyldig_fravær
            ),
          }
          return item2
        })
      // descending
      pastEventRegistration?.sort((a, b) => {
        return (
          new Date(b?.eventCrm?.eventstartdate || "").getTime() -
          new Date(a?.eventCrm?.eventstartdate || "").getTime()
        )
      })

      const sortedCheckins = response?.checkin
      sortedCheckins?.sort((a, b) => {
        return (
          new Date(
            b?.eventRegistration?.eventCrm?.eventstartdate || ""
          ).getTime() -
          new Date(
            a?.eventRegistration?.eventCrm?.eventstartdate || ""
          ).getTime()
        )
      })

      setMyEvents(filterdEventRegistration || [])
      setPastMyEvents(pastEventRegistration || [])
      setCheckins(sortedCheckins || [])
      setCancelledEvents(filterdCancelledEventRegistration || [])
    }

    fillData()
  }, [response])

  console.log(myEvents)
  console.log(cancelledEvents)

  return (
    <MyPageLayout props="Mine Kurs">
      <div className="w-full flex flex-col items-center">
        <div className="max-w-3xl w-full">
          <h1 className="mt-8 text-2xl font-bold text-meny-700">
            Kommende kurs
          </h1>
          {myEventsQuery.isLoading && (
            <div className="flex flex-col items-center">
              <div className="max-w-3xl w-full">
                <ListLoading />
              </div>
            </div>
          )}
          {!myEventsQuery.isLoading && (myEvents || []).length === 0 && (
            <div>Du har ingen kommende kurs</div>
          )}
          <MyEventAccordionGroup data={myEvents || []} cancelled={false} />

          <h1 className="mt-8 text-2xl font-bold text-meny-700">
            Avlyste kurs
          </h1>
          {myEventsQuery.isLoading && (
            <div className="flex flex-col items-center">
              <div className="max-w-3xl w-full">
                <ListLoading />
              </div>
            </div>
          )}
          {!myEventsQuery.isLoading && (cancelledEvents || []).length === 0 && (
            <div>Du har ingen avlyste kurs</div>
          )}
          <MyEventAccordionGroup
            data={cancelledEvents || []}
            cancelled={true}
          />

          <h1 className="mt-8 text-2xl font-bold text-meny-700">
            Kursdeltagelser
          </h1>
          {myEventsQuery.isLoading && (
            <div className="flex flex-col items-center">
              <div className="max-w-3xl w-full">
                <ListLoading />
              </div>
            </div>
          )}
          {!myEventsQuery.isLoading && (checkins || []).length === 0 && (
            <div>Du har ingen kursdeltagelser</div>
          )}
          <MyCheckinAccordionGroup
            data={(checkins || []).filter(CheckinFilter)}
          />

          <h1 className="mt-8 text-2xl font-bold text-meny-700">
            Kurs uten oppmøte
          </h1>
          {myEventsQuery.isLoading && (
            <div className="flex flex-col items-center">
              <div className="max-w-3xl w-full">
                <ListLoading />
              </div>
            </div>
          )}
          {!myEventsQuery.isLoading && (myPastEvents || []).length === 0 && (
            <div>Du har ingen kurs som mangler deltagelse</div>
          )}
          <MyEventAccordionGroup data={myPastEvents || []} cancelled={false} />
        </div>
      </div>
    </MyPageLayout>
  )
}

const CheckinFilter = (checkin: Checkin): boolean => {
  return (
    (checkin.eventTypeData.registrationType ===
      EventRegistrationTypeEnum.noSessions &&
      checkin.checkintype === CheckinType.Arrangementsinnsjekking) ||
    (checkin.eventTypeData.registrationType !==
      EventRegistrationTypeEnum.noSessions &&
      checkin.checkintype === CheckinType.Delmøteinnsjekking)
  )

  // return (
  //   checkin.eventRegistration?.eventCrm?.eventtype ===
  //     EventRegistrationTypeEnum.noSessions ||
  //   checkin.checkintype === CheckinType.Delmøteinnsjekking
  // )
}

export default MyEvents
