import { useAccount, useMsal } from "@azure/msal-react"
import React, { Fragment, useEffect, useRef, useState } from "react"
import { Dialog, Transition } from "@headlessui/react"
import { CheckIcon, MailIcon } from "@heroicons/react/outline"
import { useMutation, useQueryClient } from "react-query"
import { patchApi, postApi, postGetDocumentApi } from "../../../Utils/Api.utils"
import DocumentList from "../document/DocumentList"
import FileUpload from "../document/FileUpload"
import { toast } from "react-toastify"
import { cyanButton } from "../../../styles/tailwindClasses"

const MessageWrite = ({ entityName, entityId, setOpenMessageModal }) => {
  const queryClient = useQueryClient()
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const [open, setOpen] = useState(true)
  const cancelButtonRef = useRef(null)
  const [description, setDescription] = useState("")
  const [subject, setSubject] = useState("")
  const [showDocumentComponent, setShowDocumentComponent] = useState(false)
  const [messageId, setMessageId] = useState("")
  const [showMessageButtons, setShowMessageButtons] = useState(true)
  const [documents, setDocuments] = useState([])

  const mutationCreateMessage = useMutation(
    () => {
      return postApi(
        process.env.GATSBY_API_URL + "/Messages",
        {
          subject: subject,
          description: description,
          direction: false,
          regardingObjectIncidentId: entityId,
        },
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: data => {
        setMessageId(data)
        queryClient.invalidateQueries(["userMessagesData"])
        setShowDocumentComponent(false)
        cancelModal()
      },
      onError: _error => { },
      onSettled: () => { },
    }
  )

  const mutationGetDocuments = useMutation(
    () => {
      return postGetDocumentApi(
        process.env.GATSBY_API_URL + "/Documents/Regarding/Get",
        {
          PortalMessageId: messageId,
        },
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: data => {
        setDocuments(data)
      },
      onError: _error => { },
      onSettled: () => { },
    }
  )

  const mutationCreateMessageWithFile = useMutation(
    () => {
      return postApi(
        process.env.GATSBY_API_URL + "/Messages",
        {
          subject: subject,
          description: description,
          direction: false,
          regardingObjectIncidentId: entityId,
        },
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: data => {
        setMessageId(data)
        queryClient.invalidateQueries(["userMessagesData"])
        setShowDocumentComponent(true)
        setShowMessageButtons(false)
      },
      onError: _error => { },
      onSettled: () => { },
    }
  )

  const mutationUpdateMessage = useMutation(
    () => {
      return patchApi(
        process.env.GATSBY_API_URL + "/Messages",
        {
          id: messageId,
          subject: subject,
          description: description,
          direction: false,
          regardingObjectIncidentId: entityId,
        },
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["userMessagesData"])
        cancelModal()
      },
      onError: _error => { },
      onSettled: () => { },
    }
  )

  const postIncident = async e => {
    e.preventDefault()
    mutationCreateMessage.mutate()
  }

  const postMessageWithFile = async e => {
    e.preventDefault()
    if (
      subject == "" ||
      subject == undefined ||
      description == "" ||
      description == undefined
    ) {
      toast.warning("Vennligst fyll ut emne og beskrivelse!", {
        position: "top-center",
        autoClose: 10000,
      })
      return
    }

    mutationCreateMessageWithFile.mutate()
  }

  const patchMessage = async e => {
    e.preventDefault()
    mutationUpdateMessage.mutate()
  }

  const cancelModal = () => {
    setOpen(false)
    setOpenMessageModal(false)
  }

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div>
                  <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                    <MailIcon
                      className="h-6 w-6 text-green-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      Send melding
                    </Dialog.Title>
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                      <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                        <form
                          method="POST"
                          onSubmit={postIncident}
                          className="space-y-6"
                        >
                          <div>
                            <label
                              htmlFor="title"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Emne
                            </label>
                            <div className="mt-1">
                              <input
                                id="title"
                                name="title"
                                type="text"
                                autoComplete="title"
                                onChange={e => setSubject(e.target.value)}
                                required
                                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-meny-700 focus:border-meny-500 sm:text-sm"
                              />
                            </div>
                          </div>

                          <div>
                            <label
                              htmlFor="message"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Melding
                            </label>
                            <div className="mt-1">
                              <textarea
                                id="message"
                                name="message"
                                rows={3}
                                onChange={e => setDescription(e.target.value)}
                                className="max-w-lg shadow-sm block w-full focus:ring-meny-700 focus:border-meny-500 sm:text-sm border border-gray-300 rounded-md"
                                defaultValue={""}
                              />
                            </div>
                          </div>
                          {showMessageButtons ? (
                            <div className="pt-5">
                              <div className="flex justify-end">
                                <button
                                  type="button"
                                  onClick={e => postMessageWithFile(e)}
                                  className={
                                    cyanButton +
                                    " mr-3 inline-flex justify-center "
                                  }
                                >
                                  Last opp dokumenter
                                </button>
                                <button type="submit" className={cyanButton}>
                                  Send melding
                                </button>
                              </div>
                            </div>
                          ) : null}
                        </form>
                        {showDocumentComponent ? (
                          <div>
                            <h4 className="text-lg text-center font-bold text-meny-700">
                              Meldingen {subject} er sendt inn, last opp
                              dokumenter
                            </h4>
                            <p className="mt-2 text-sm text-gray-600"></p>
                            <p className="mt-2 text-sm text-gray-600"></p>
                            <div className="mt-2">
                              <DocumentList
                                documents={documents}
                                mutationGetDocuments={mutationGetDocuments}
                                canDelete={true}
                              />
                            </div>
                            <FileUpload
                              entityId={messageId}
                              entityName={"pp_portalmessage"}
                              entityId2={entityId}
                              entityName2={entityName}
                              entityId3={null}
                              entityName3={null}
                              mutationGetDocuments={mutationGetDocuments}
                              setDocuments={null}
                              isReturn={false}
                            />
                            <div className="pt-5">
                              <div className="flex justify-center">
                                <button
                                  type="button"
                                  onClick={e => patchMessage(e)}
                                  className={
                                    cyanButton + " inline-flex items-center "
                                  }
                                >
                                  Send Inn
                                </button>
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="sm:grid-flow-row-dense">
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700 sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={cancelModal}
                    ref={cancelButtonRef}
                  >
                    Lukk
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )
}

export default MessageWrite
