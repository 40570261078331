import { useAccount, useMsal } from "@azure/msal-react"
import { navigate } from "gatsby"
import { useMutation, useQueryClient } from "react-query"
import { toast } from "react-toastify"
import { patchApi, postApi } from "../../../Utils/Api.utils"
import useUpdateEmploymentStatusIndependent from "./useUpdateEmploymentStatusIndependent"
import useCreateIncident from "./useCreateIncident"

function useNewEmploymentAsIndependent(
  prevEmploymentData,
  professionallyActiveStatus,
  setShowLoadingData,
  eventQuery,
  clientAccountWithOrgNumber,
  incidentDescription
) {
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const timer = 2500

  const queryClient = useQueryClient()

  const mutationUpdateEmploymentStatus = useUpdateEmploymentStatusIndependent(
    eventQuery,
    clientAccountWithOrgNumber,
    incidentDescription
  )

  const mutationCreateIncident = useCreateIncident(incidentDescription)

  const mutationUpdatePrevEmployment = useMutation(
    data => {
      return patchApi(
        `${process.env.GATSBY_API_URL}/Employments/${prevEmploymentData?.id}`,
        data,
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: data => {
        if (professionallyActiveStatus !== undefined) {
          mutationUpdateEmploymentStatus.mutate(professionallyActiveStatus)
        } else if (clientAccountWithOrgNumber === null) {
          mutationCreateIncident.mutate()
        } else {
          if (eventQuery?.data?.eventRegistration?.length > 0) {
            toast.success(
              "Du har en eller flere påmeldinger på kurs, husk å oppdatere fakturamottager på dine deltagelser",
              {
                position: "top-center",
                autoClose: 6000,
              }
            )
          }
          window.setTimeout(() => {
            navigate("/app/profil/arbeidsforhold")
          }, timer)
          console.log("Success")
        }
      },
      onError: _error => {
        setShowLoadingData(false)
        toast.error("Det har skjedd en feil, prøv igjen senere", {
          position: "top-center",
          autoClose: 6000,
        })
      },
      onSettled: () => {},
    }
  )

  const newEmploymentAsIndependent = useMutation(
    data => {
      return postApi(
        `${process.env.GATSBY_API_URL}/Employments`,
        data,
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: data => {
        queryClient.setQueryData("employmentId", data)
        if (eventQuery?.data?.eventRegistration?.length > 0) {
          toast.success(
            "Du har en eller flere påmeldinger på kurs, husk å oppdatere fakturamottager på dine deltagelser",
            {
              position: "top-center",
              autoClose: 6000,
            }
          )
        }
        //Check if the user have a previous employment
        if (prevEmploymentData !== undefined) {
          mutationUpdatePrevEmployment.mutate(prevEmploymentData)
        } else {
          //Change the status of the user
          if (professionallyActiveStatus !== undefined) {
            mutationUpdateEmploymentStatus.mutate(professionallyActiveStatus)
          } else {
            //Check if an incident should be created
            if (clientAccountWithOrgNumber === null) {
              mutationCreateIncident.mutate()
            }
            // Dont change previous employment status, dont change status on user and dont create incident
            else {
              toast.success("Endringene er lagret", {
                position: "top-center",
                autoClose: 6000,
              })
              window.setTimeout(() => {
                navigate("/app/profil/arbeidsforhold")
              }, timer)
            }
          }
        }
      },
      onError: _error => {
        setShowLoadingData(false)
      },
      onSettled: () => {},
    }
  )
  return newEmploymentAsIndependent
}

export default useNewEmploymentAsIndependent
