import { InteractionType } from "@azure/msal-browser"
import {
  MsalAuthenticationTemplate,
  useAccount,
  useMsal,
} from "@azure/msal-react"
import { ErrorMessage } from "@hookform/error-message"
import { navigate } from "gatsby"
import React, { useEffect, useRef, useState } from "react"
import { useForm } from "react-hook-form"
import { useMutation, useQuery } from "react-query"
import { emptyString } from "react-select/dist/declarations/src/utils"
import { toast } from "react-toastify"
import { StringParam, useQueryParam } from "use-query-params"
import {
  fetchApi,
  fetchApiStandard,
  patchApi,
  postApi,
} from "../../../Utils/Api.utils"
import { Contact, Countries } from "../../../Utils/Entities"
import { Loading } from "../../../Utils/Loading"
import { ErrorComponent } from "../../common/graphichs/ErrorComponent"
import LoadingData from "../../common/graphichs/LoadingData"
import MyPageLayout2 from "../../common/layouts/myPageLayout2"
import ModalWithHeader from "../../common/modal/ModalWithHeader"
import { loginRequest } from "../../security/authConfig"

type FormValues = {
  id: string
  firstName: string
  lastName: string
  emailAddress1: string
  gender: number
  customerTypeCode: number
  hprNumber: string
  address1Line1: string
  address1Line2: string
  address1Line3: string
  address1City: string
  address1PostalCode: string
  address1CountryCode: string
}

const genders = [
  { value: 125600000, name: "Kvinne" },
  { value: 125600001, name: "Mann" },
  { value: 125600002, name: "Valgfri" },
]

const EditContactContent = props => {
  const origin = props.origin
  const {
    register,
    handleSubmit,
    reset,
    resetField,
    clearErrors,
    getValues,
    formState: { errors },
  } = useForm<FormValues>()
  const [contact, setContact] = useState<Contact>()
  const [countries, setCountries] = useState<Countries[]>([])
  const [defaultCountryCode, setDefaultCountryCode] = useState<string>("NO")
  const [norwegian, setNorwegian] = useState<boolean>(false)
  const [city, setCity] = useState<string>()
  const [zipCode, setZipCode] = useState(null)
  const [zipCode2, setZipCode2] = useState(null)

  const [firstName, setFirstName] = useState<string>()
  const [lastName, setLastName] = useState<string>()

  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const [disableButton, setDisabled] = useState<boolean>(false)

  const [validatePostal, setValidatePostal] = useState({})

  const [norwegianError, setNorwegianError] = useState<boolean>(false)
  const [utlandError, setUtlandError] = useState<boolean>(false)
  const [foreignPostalCode, setForeignPostalCode] = useState<string>()

  const [showModal, setShowModal] = useState(false)
  const [showLoadingData, setShowLoadingData] = useState(false)

  const [updatedContact, setUpdatedContact] = useState<FormValues>()

  const [loadingTime, setLoadingTime] = useState<number>(1500)

  const cancelButtonRef = useRef(null)

  const redirectToParam = useQueryParam("redirectTo", StringParam)[0]
  const [redirectTo, setRedirectTo] = useState<string | undefined>(undefined)

  const validateNorwegian = {
    pattern: {
      value: /^[0-9]{4}$/i,
      message: "Norsk postnummer kan kun inneholde 4 siffer.",
    },
  }

  useEffect(() => {
    setRedirectTo(redirectToParam || "/app/profil")
  }, [redirectToParam])

  const validateNotNorwegian = {
    maxLength: {
      value: 20,
      message: "Utenlandsk postnummer kan ikke inneholde mer enn 20 tegn",
    },
  }

  const countriesQuery = useQuery(
    "countries",
    () =>
      fetchApi(
        process.env.GATSBY_API_URL + "/Addresses/Countries",
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: data => {
        setCountries(data)
      },
      onError: _error => {},
    }
  )

  const userInfoQuery = useQuery(
    "userInfo",
    () =>
      fetchApi(
        process.env.GATSBY_API_URL + "/Contacts/me",
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: data => {
        setContact(data)
        setFirstName(data?.firstName)
        setLastName(data?.lastName)
        setUpdatedContact(data)
        reset(data)
        if (data.address1CountryCode === "NO") {
          setNorwegian(true)
          setZipCode(data.address1PostalCode)
          // setValidatePostal(validateNorwegian)
          setDefaultCountryCode(data.address1CountryCode)
        } else if (
          data.address1CountryCode == null ||
          data.address1CountryCode == undefined
        ) {
          setNorwegian(true)
          setDefaultCountryCode("NO")
        } else {
          setNorwegian(false)
          setCity(data.address1City)
          // setValidatePostal(validateNotNorwegian)
          setDefaultCountryCode(data.address1CountryCode)
        }
      },
      onError: _error => {},
    }
  )

  const index: number = countries?.findIndex(
    c => c.countryName === contact?.address1CountryName
  )

  const isNorwegian = event => {
    if (event.target.value === "NO") {
      resetField("address1PostalCode")

      clearErrors("address1PostalCode")
      setNorwegian(true)
      if (getValues("address1PostalCode").length !== 4) setNorwegianError(true)
    } else {
      resetField("address1PostalCode")
      clearErrors("address1PostalCode")
      setNorwegian(false)
    }
  }

  const userZipCodeQuery = useQuery(
    "userZipCode",
    () =>
      fetchApiStandard(
        process.env.GATSBY_API_URL + "/Addresses/PostalCodes/" + zipCode,
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      enabled: !!zipCode,
      onSuccess: data => {
        setCity(data.postalPlace)
      },
      onError: _error => {
        setZipCode(null)
        toast.warning("Vennligst tast inn et gyldig postnr!", {
          position: "top-center",
          autoClose: 10000,
        })
      },
    }
  )

  const userZipCodeQuery2 = useQuery(
    "userZipCode2",
    () =>
      fetchApiStandard(
        process.env.GATSBY_API_URL + "/Addresses/PostalCodes/" + zipCode,
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      enabled: !!zipCode2,
      onSuccess: data => {
        setZipCode2(null)
        if (norwegian) {
          setNorwegian(false)
          setCity(data.postalPlace)
          setNorwegian(true)
        }
        if (!norwegian) setCity(data.postalPlace)
      },
      onError: _error => {
        setZipCode2(null)
        toast.warning("Vennligst tast inn et gyldig postnr!", {
          position: "top-center",
          autoClose: 10000,
        })
      },
    }
  )

  const setPostalPlace = event => {
    if (event.target.value.length === 4) {
      setZipCode(event.target.value)
      setZipCode2(event.target.value)
      // queryClient.invalidateQueries(["userZipCode"])
      // userZipCodeDataQuery.refetch(event.target.value)
      setForeignPostalCode("")
      setNorwegianError(false)
    } else setNorwegianError(true)
  }

  const validateForeignPostal = event => {
    if (event.target.value.length > 20) {
      setZipCode(null)
      setZipCode2(null)
      setForeignPostalCode("")
      setUtlandError(true)
    } else {
      setUtlandError(false)
      setZipCode(null)
      // setZipCode(event.target.value)
      setForeignPostalCode(event.target.value)
    }
  }

  const mutationUpdateContact = useMutation(
    data => {
      setDisabled(true)
      return patchApi(
        process.env.GATSBY_API_URL + "/Contacts/me",
        data,
        account,
        inProgress,
        instance
      ).then(res => {
        res.data
      })
    },
    {
      onSuccess: data => {
        // if (origin == 0) {
        if (origin == "Kurs") {
          navigate(-1)
        } else {
          window.setTimeout(() => {
            navigate(redirectTo || "/app/profil")
          }, loadingTime)
        }
      },
      onError: _error => {
        window.setTimeout(() => {
          window.location.reload()
        }, 1500)
      },
      onSettled: () => {},
    }
  )

  const mutationCreateIncident = useMutation(
    () => {
      return postApi(
        process.env.GATSBY_API_URL + "/Incidents",
        {
          title: "HPR nr endret",
          caseTypeCode: 1,
          caseOriginCode: 3,
          // HPR nr registrert
          subjectId: "ef4e8d5f-0be8-ec11-bb3c-6045bd94dd46",
          status: 1,
          state: 0,
        },
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: data => {
        if (origin == "Kurs") {
          navigate(-1)
        } else {
          window.setTimeout(() => {
            navigate(redirectTo || "/app/profil")
          }, loadingTime)
        }
      },
      onError: _error => {},
      onSettled: () => {},
    }
  )

  const onClick = () => {
    setShowModal(false)
    // event.preventDefault()
    if (
      contact?.hprNumber == undefined &&
      updatedContact?.hprNumber?.length > 0
    ) {
      // opprette sak
      setDisabled(true)
      setLoadingTime(3000)
      mutationCreateIncident.mutate()
    }

    if (updatedContact !== undefined) {
      setDisabled(true)
      setShowLoadingData(true)
      mutationUpdateContact.mutate(updatedContact)
    }
  }

  const patchContact = async (data, event) => {
    event.preventDefault()
    if (norwegian) {
      data.address1City = ""
      data.address1PostalCode = zipCode
    } else {
      data.address1PostalCode = foreignPostalCode
    }

    // if (norwegian) data.address1City = ""
    // data.address1PostalCode = zipCode

    setUpdatedContact(data)

    data.lastLoginOnWeb = ""

    if (
      data.firstName !== contact?.firstName ||
      data.lastName !== contact?.lastName
    )
      setShowModal(true)
    else {
      if (contact?.hprNumber == undefined && data.hprNumber.length > 0) {
        // opprette sak
        setLoadingTime(3000)
        mutationCreateIncident.mutate()
      }
      setDisabled(true)
      setShowLoadingData(true)
      mutationUpdateContact.mutate(data)
    }
  }

  return userInfoQuery.isLoading || showLoadingData ? (
    // // "Laster inn..."
    <LoadingData />
  ) : (
    <MyPageLayout2 props="Endre Bruker">
      <div className="">
        <form
          method="PATCH"
          onSubmit={handleSubmit(patchContact)}
          className="space-y-8 "
        >
          <div className="space-y-8 sm:space-y-5">
            <div className="space-y-6sm:space-y-5">
              <div>
                <h3 className="text-lg leading-6 font-medium text-meny-700">
                  Personlig Informasjon
                </h3>
              </div>
              <div className="space-y-6 sm:space-y-5">
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                  <label
                    htmlFor="first-name"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Fornavn*:
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <input
                      type="text"
                      {...register("firstName", {
                        pattern: {
                          value: /^[-A-Za-zÆØÅæøåÖöÄäĄąĆćĘęŁłŃńÓóŚśŹźŻż ]+$/i,
                          message: "Kun bokstaver og bindestrek er tillatt.",
                        },
                        minLength: {
                          value: 2,
                          message: "Minimum tegn er 2",
                        },
                        maxLength: {
                          value: 30,
                          message: "Maks tegn er 30",
                        },
                      })}
                      required
                      name="firstName"
                      id="firstName"
                      autoComplete="given-name"
                      onChange={e => setFirstName(e.target.value)}
                      defaultValue={contact?.firstName}
                      className="block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                    />
                    <ErrorMessage
                      errors={errors}
                      name="firstName"
                      render={({ message }) => (
                        <p className="text-sm text-red-600">{message}</p>
                      )}
                    />
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                  <label
                    htmlFor="last-name"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Etternavn*:
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <input
                      type="text"
                      {...register("lastName", {
                        pattern: {
                          value: /^[-A-Za-zÆØÅæøåÖöÄäĄąĆćĘęŁłŃńÓóŚśŹźŻż ]+$/i,
                          message: "Kun bokstaver og bindestrek er tillatt.",
                        },
                        minLength: {
                          value: 2,
                          message: "Minimum tegn er 2",
                        },
                        maxLength: {
                          value: 30,
                          message: "Maks tegn er 30",
                        },
                      })}
                      required
                      name="lastName"
                      id="lastName"
                      autoComplete="family-name"
                      onChange={e => setLastName(e.target.value)}
                      defaultValue={contact?.lastName}
                      className=" block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                    />
                    <ErrorMessage
                      errors={errors}
                      name="lastName"
                      render={({ message }) => (
                        <p className="text-sm text-red-600">{message}</p>
                      )}
                    />
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                  <label
                    htmlFor="gender"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Kjønn*:
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <select
                      {...register("gender")}
                      required
                      name="gender"
                      id="gender"
                      key="gender"
                      defaultValue={contact?.gender}
                      className=" block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                    >
                      <option value={genders[0].value}>
                        {genders[0].name}
                      </option>
                      <option value={genders[1].value}>
                        {genders[1].name}
                      </option>
                      <option value={genders[2].value}>
                        {genders[2].name}
                      </option>
                    </select>
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Epostadresse*:
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <input
                      id="emailAddress1"
                      {...register("emailAddress1", {
                        pattern: {
                          value:
                            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))+$/i,
                          message: "Feil epostformat",
                        },
                        maxLength: {
                          value: 320,
                          message: "Maks tegn er 320",
                        },
                      })}
                      required
                      name="emailAddress1"
                      type="text"
                      autoComplete="email"
                      defaultValue={contact?.emailAddress1}
                      className="block w-full shadow-sm focus:ring-meny-700 sm:text-sm focus:border-meny-500 border-gray-300 rounded-md"
                    />
                    <ErrorMessage
                      errors={errors}
                      name="emailAddress1"
                      render={({ message }) => (
                        <p className="text-sm text-red-600">{message}</p>
                      )}
                    />
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    HPRnr:
                  </label>
                  {contact?.hprNumber != undefined ? (
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <input
                        id="hprNumber"
                        name="hprNumber"
                        type="text"
                        readOnly={true}
                        defaultValue={contact?.hprNumber}
                        className="block w-full shadow-sm focus:ring-meny-700 sm:text-sm focus:border-meny-500 border-gray-300 rounded-md bg-gray-50"
                      />
                    </div>
                  ) : (
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <input
                        id="hprNumber"
                        {...register("hprNumber", {
                          pattern: {
                            value: /^[0-9]+$/,
                            message: "Kun tall er tillatt",
                          },
                          minLength: {
                            value: 7,
                            message: "HPR nr kan kun være mellom 7 og 9 siffer",
                          },
                          maxLength: {
                            value: 9,
                            message: "HPR nr kan kun være mellom 7 og 9 siffer",
                          },
                        })}
                        name="hprNumber"
                        type="text"
                        // defaultValue={contact?.hprNumber}
                        className="block w-full shadow-sm focus:ring-meny-700 sm:text-sm focus:border-meny-500 border-gray-300 rounded-md"
                      />
                      <ErrorMessage
                        errors={errors}
                        name="hprNumber"
                        render={({ message }) => (
                          <p className="text-sm text-red-600">{message}</p>
                        )}
                      />
                    </div>
                  )}
                </div>

                {/* {index > -1 ? ( */}
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                  <label
                    htmlFor="address1CountryCode"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Land*:
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <select
                      {...register("address1CountryCode")}
                      name="address1CountryCode"
                      id="address1CountryCode"
                      key="address1CountryCode"
                      required
                      // defaultValue={defaultCountryCode}
                      // defaultValue={"NO"}
                      onChange={isNorwegian}
                      className=" block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                    >
                      {countries.map(country => (
                        <option
                          selected={country.countryCode === defaultCountryCode}
                          key={country.countryCode}
                          value={country.countryCode}
                          // defaultChecked={country.countryCode === "NO"}
                        >
                          {country.countryName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                {/* ) : (
                  <div></div>
                )} */}

                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                  <label
                    htmlFor="street-address"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    C/O:
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <input
                      type="text"
                      {...register("address1Line1", {
                        pattern: {
                          value: /^[-'.A-Za-zÆØÅæøå0-9 ]*$/i,
                          message:
                            "Kun bokstaver, tall og tegnene ' . - er tillatt.",
                        },
                        maxLength: {
                          value: 220,
                          message: "Maks tegn er 220",
                        },
                      })}
                      name="address1Line1"
                      id="address1Line1"
                      autoComplete="street-address"
                      defaultValue={contact?.address1Line1}
                      className="block w-full shadow-sm sm:text-sm focus:ring-meny-700 focus:border-meny-500 border-gray-300 rounded-md"
                    />
                    <ErrorMessage
                      errors={errors}
                      name="address1Line1"
                      render={({ message }) => (
                        <p className="text-sm text-red-600">{message}</p>
                      )}
                    />
                  </div>
                </div>
                {/* ) : ( */}
                <div></div>
                {/* )} */}
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                  <label
                    htmlFor="street-address"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Adresse*:
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <input
                      type="text"
                      {...register("address1Line2", {
                        pattern: {
                          value:
                            /^[-'.A-Za-zÆØÅæøåÖöÄäĄąĆćĘęŁłŃńÓóŚśŹźŻż0-9 ]*$/i,
                          message:
                            "Kun bokstaver, tall og tegnene ' . - er tillatt.",
                        },
                        maxLength: {
                          value: 220,
                          message: "Maks tegn er 220",
                        },
                      })}
                      name="address1Line2"
                      id="address1Line2"
                      required
                      autoComplete="street-address"
                      defaultValue={contact?.address1Line2}
                      className="block w-full shadow-sm sm:text-sm focus:ring-meny-700 focus:border-meny-500 border-gray-300 rounded-md"
                    />
                    <ErrorMessage
                      errors={errors}
                      name="address1Line2"
                      render={({ message }) => (
                        <p className="text-sm text-red-600">{message}</p>
                      )}
                    />
                  </div>
                </div>

                {norwegian ? (
                  <div></div>
                ) : (
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                    <label
                      htmlFor="street-address"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Ekstrafelt adresse:
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <input
                        type="text"
                        {...register("address1Line3", {
                          // pattern: {
                          //   value: /^[-'.A-Za-zÆØÅæøå0-9 ]*$/i,
                          //   message:
                          //     "Kun bokstaver, tall og tegnene ' . - er tillatt.",
                          // },
                          maxLength: {
                            value: 220,
                            message: "Maks tegn er 220",
                          },
                        })}
                        name="address1Line3"
                        id="address1Line3"
                        autoComplete="street-address"
                        defaultValue={contact?.address1Line3}
                        // onChange={checkZip}
                        className="block w-full shadow-sm sm:text-sm focus:ring-meny-700 focus:border-meny-500 border-gray-300 rounded-md"
                      />
                      <ErrorMessage
                        errors={errors}
                        name="address1Line3"
                        render={({ message }) => (
                          <p className="text-sm text-red-600">{message}</p>
                        )}
                      />
                    </div>
                  </div>
                )}

                {norwegian ? (
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                    <label
                      htmlFor="postal-code"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Postnummer*:
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <input
                        type="text"
                        // {...register("address1PostalCode", {
                        //   required: {
                        //   value: true,
                        //   message: "Obligatorisk felt.",
                        // },
                        //   pattern: {
                        //     value: /^[0-9]{4}$/i,
                        //     message:
                        //       "Norsk postnummer kan kun inneholde 4 siffer.",
                        //   },
                        // })}
                        name="address1PostalCode"
                        id="address1PostalCode"
                        defaultValue={contact?.address1PostalCode}
                        onChange={setPostalPlace}
                        required={norwegian}
                        autoComplete="postal-code"
                        className="block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                      />
                      {/* <ErrorMessage
                      errors={errors}
                      name="address1PostalCode"
                      render={({ message }) => (
                        <p className="text-sm text-red-600">{message}</p>
                      )}
                    /> */}
                      <div>
                        {norwegianError ? (
                          <p className="text-sm text-red-600">
                            Norsk postnummer kan kun bestå av fire siffer
                          </p>
                        ) : (
                          <></>
                        )}{" "}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                    <label
                      htmlFor="postal-code"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Postnummer*:
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <input
                        type="text"
                        // {...register("address1PostalCode", {
                        //   maxLength: {
                        //     value: 20,
                        //     message:
                        //       "Utenlandsk postnummer kan ikke inneholde mer enn 20 tegn",
                        //   },
                        // })}
                        name="address1PostalCode"
                        id="address1PostalCode"
                        required={!norwegian}
                        defaultValue={contact?.address1PostalCode}
                        onChange={validateForeignPostal}
                        autoComplete="postal-code"
                        className="block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                      />
                      {/* <ErrorMessage
                      errors={errors}
                      name="address1PostalCode2"
                      render={({ message }) => (
                        <p className="text-sm text-red-600">{message}</p>
                      )}
                    /> */}
                      <div>
                        {utlandError ? (
                          <p className="text-sm text-red-600">
                            Utenlandsk postnummer kan inneholde maksimalt 20
                            tegn
                          </p>
                        ) : (
                          <></>
                        )}{" "}
                      </div>
                    </div>
                  </div>
                )}

                {!norwegian ? (
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                    <label
                      htmlFor="city"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      By (utenlandsk):
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <input
                        {...register("address1City", {
                          pattern: {
                            value: /^[-'.A-Za-zÆØÅæøå0-9 ]*$/i,
                            message:
                              "Kun bokstaver, tall og tegnene ' . - er tillatt.",
                          },
                          maxLength: {
                            value: 220,
                            message: "Maks tegn er 220",
                          },
                        })}
                        type="text"
                        name="address1City"
                        id="address1City"
                        defaultValue={city}
                        autoComplete="address-level2"
                        className="block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                      />
                      <ErrorMessage
                        errors={errors}
                        name="address1City"
                        render={({ message }) => (
                          <p className="text-sm text-red-600">{message}</p>
                        )}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                    <label
                      htmlFor="city"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Poststed*:
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <input
                        type="text"
                        readOnly={norwegian}
                        name="address1City"
                        id="address1City"
                        defaultValue={city}
                        value={city}
                        required
                        autoComplete="address-level2"
                        className="bg-gray-200 block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                      />
                      <ErrorMessage
                        errors={errors}
                        name="address1City"
                        render={({ message }) => (
                          <p className="text-sm text-red-600">{message}</p>
                        )}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="pt-5 flex justify-end">
              <button
                type="button"
                onClick={() => navigate(-1)}
                className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
              >
                Tilbake
              </button>
              <button
                type="submit"
                disabled={utlandError || norwegianError || disableButton}
                className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-meny-700 hover:bg-meny-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
              >
                Lagre
              </button>
            </div>

            {showModal && (
              <ModalWithHeader setModalOpen={setShowModal} header="Endre navn">
                <p className="mb-2">
                  Du er nå i ferd med å endre ditt registrerte navn hos oss,
                  vennligst bekreft endringen:
                </p>
                <p className="font-medium">
                  Gammelt navn: "{contact?.firstName} {contact?.lastName}"
                </p>
                <p className="font-medium">
                  Nytt navn: "{firstName} {lastName}"
                </p>
                <div className="w-full flex flex-row justify-center mt-8">
                  <button
                    onClick={() => {
                      setShowModal(false)
                    }}
                    className="w-fit ml-1 inline-flex justify-end py-2 px-8 border border-gray-200 shadow-sm text-md font-medium rounded-md text-black bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
                  >
                    Avbryt
                  </button>
                  <button
                    onClick={onClick}
                    type="submit"
                    className="w-fit ml-1 inline-flex justify-end py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-md text-white bg-red-700 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-700"
                  >
                    Lagre
                  </button>
                </div>
              </ModalWithHeader>
            )}
          </div>
        </form>
      </div>
    </MyPageLayout2>
  )
}

const EditContact = ({ location }) => {
  const authRequest = {
    ...loginRequest,
  }
  const origin = location.state.origin
  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      <EditContactContent origin={origin} />
    </MsalAuthenticationTemplate>
  )
}

export default EditContact
