import React, { useEffect, useState } from "react"
import ReactDatePicker from "react-datepicker"
import { useForm } from "react-hook-form"
import {
  PlusSmIcon as PlusSmIconOutline,
  TrashIcon,
} from "@heroicons/react/outline"
import { toast } from "react-toastify"
import { cyanButton } from "../../../styles/tailwindClasses"
import GetHelpText from "../../common/text/GetHelpText"
import { useQuery, useQueryClient } from "react-query"
import { fetchApi } from "../../../Utils/Api.utils"
import { useAccount, useMsal } from "@azure/msal-react"
import { SpecialistProgram } from "../../../Utils/Types"
import ModalWithHeader from "../../common/modal/ModalWithHeader"
import Modal from "../../common/modal/Modal"

const AddPracticeHours = ({
  practiceHours,
  setPracticeHours,
  CalculateSumPractice,
  sumPractice,
  setSumPractice,
  setEarliestDate,
  setLatestDate,
  earliestDate,
  latestDate,
  setPracticeHoursStartedEditing,
  program,
  allMyPrograms,
}) => {
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [percentagePracticePosition, setPercentagePracticePosition] =
    useState("")
  const [diffMonths, setDiffMonths] = useState("")
  const [count, setCount] = useState(0)
  const [sumLocalPractice, setSumLocalPractice] = useState(0)
  const [prevStartDate, setPrevStartDate] = useState(null)
  const [prevEndDate, setPrevEndDate] = useState(null)
  const [exceededPracsis, setExceededPracsis] = useState(false)
  const [selectedProgram, setSelectedProgram] = useState(program.id)
  const [showModal, setShowModal] = useState(false)
  const [programName, setProgramName] = useState<string>(
    program?.programType?.name
  )
  const queryClient = useQueryClient()
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})

  const programs: SpecialistProgram[] = allMyPrograms

  console.log(program)
  console.log(programs)
  console.log(selectedProgram)

  // console.log((percentagePracticePosition * diffMonths) / 100 + sumPractice)
  // console.log(
  //   (percentagePracticePosition * diffMonths) / 100 + sumPractice >
  //     program.requirementsSpecialistEducation.pracsis
  //     ? "Over summen"
  //     : "Mindre eller lik"
  // )
  // console.log(program.requirementsSpecialistEducation.pracsis)

  let currentProgram = allMyPrograms?.find(
    program => program.id === selectedProgram
  )
  // console.log(program)
  // console.log(program?.sumPracsis)
  // console.log(program.requirementsSpecialistEducation.pracsis)
  // console.log(practiceHours)
  // console.log(allMyPrograms)
  // console.log(currentProgram)

  useEffect(() => {}, [selectedProgram])

  const {
    register,
    handleSubmit,
    reset,
    control,
    setError,
    formState: { errors },
  } = useForm()

  const OnSetStartDate = date => {
    setPracticeHoursStartedEditing(true)
    setStartDate(date)
    if (prevStartDate !== null) {
      if (
        date < prevStartDate ||
        (earliestDate !== null && earliestDate > date)
      ) {
        setEarliestDate(date)
      }
    } else {
      setEarliestDate(date)
    }

    if (endDate != null) {
      const numberOfWeeks = Math.round(
        (endDate - date) / (7 * 24 * 60 * 60 * 1000)
      )
      setDiffMonths(numberOfWeeks.toString())
      OnCalculatePercentagePracticePosition2(
        percentagePracticePosition,
        numberOfWeeks.toString()
      )
    }
  }

  const OnSetEndDate = date => {
    setPracticeHoursStartedEditing(true)
    setEndDate(date)
    if (prevEndDate !== null) {
      if (date > prevEndDate || (latestDate !== null && latestDate < date)) {
        setLatestDate(date)
      }
    } else {
      setLatestDate(date)
    }
    if (startDate != null) {
      const numberOfWeeks = Math.round(
        (date - startDate) / (7 * 24 * 60 * 60 * 1000)
      )
      setDiffMonths(numberOfWeeks.toString())
      OnCalculatePercentagePracticePosition2(
        percentagePracticePosition,
        numberOfWeeks.toString()
      )
    }
  }

  const OnCalculatePercentagePracticePosition = value => {
    setPracticeHoursStartedEditing(true)
    setPercentagePracticePosition(value)
    if (diffMonths != "") {
      const sumPractice = value * parseFloat(diffMonths)
      setSumLocalPractice(sumPractice / 100)
    }
  }

  const OnCalculatePercentagePracticePosition2 = (
    percentagePracticePosition2,
    diffMonths2
  ) => {
    setPracticeHoursStartedEditing(true)
    setPercentagePracticePosition(percentagePracticePosition2)
    if (diffMonths2 != "") {
      const sumPractice = percentagePracticePosition2 * diffMonths2
      setSumLocalPractice(sumPractice / 100)
    }
  }

  const OnDeleteRow = trId => {
    setPracticeHours(practiceHours.filter(x => x.rowId !== trId))
    let sumPractice = 0
    let prevFrom = null
    let prevTo = null
    practiceHours.forEach(practiceHour => {
      if (practiceHour.rowId != trId) {
        sumPractice +=
          (practiceHour.percentagePracticePosition * practiceHour.diffMonths) /
          100

        if (prevFrom != null && prevTo != null) {
          if (practiceHour.fromDate < prevFrom)
            setEarliestDate(practiceHour.fromDate)
          if (practiceHour.toDate > prevTo) setLatestDate(practiceHour.toDate)
        } else {
          setEarliestDate(practiceHour.fromDate)
          setLatestDate(practiceHour.toDate)
        }

        prevFrom = practiceHour.fromDate
        prevTo = practiceHour.toDate
      }
    })

    setSumPractice(sumPractice)
  }

  useEffect(() => {
    const index = programs?.findIndex(i => i.id === selectedProgram)
    console.log("programid valgt: " + selectedProgram)
    if (index > -1) setProgramName(programs[index]?.programType?.name)
  }, [selectedProgram])

  const postPracticeHour = e => {
    const data = {
      rowId: count,
      fromDate: startDate,
      toDate: endDate,
      percentagePracticePosition: parseFloat(percentagePracticePosition),
      diffMonths: parseFloat(diffMonths),
      practice: parseFloat(sumLocalPractice),
      sumLocalPractice: sumLocalPractice,
      programName: programName,
      programId: selectedProgram,
    }

    console.log(data)

    if (
      startDate == null ||
      endDate == null ||
      percentagePracticePosition == "" ||
      diffMonths == "" ||
      percentagePracticePosition === undefined ||
      diffMonths === undefined
    ) {
      toast.warning("Vennligst fyll ut alle obligatoriske felter!", {
        position: "top-center",
        autoClose: 10000,
      })
      return
    }

    if (
      parseInt(percentagePracticePosition) > 100 ||
      parseInt(percentagePracticePosition) < 50
    ) {
      toast.warning("Praksis % stilling skal være mellom 50 og 100!", {
        position: "top-center",
        autoClose: 10000,
      })
      return
    }

    const practiceWeeks = [...practiceHours, data]

    const currentSum = practiceWeeks.reduce((acc, curr) => {
      if (curr.programId === selectedProgram) return acc + curr.sumLocalPractice

      return acc
    }, 0)

    // console.log(currentSum)
    // console.log(currentProgram?.sumPracsis)
    // console.log(currentSum + currentProgram?.sumPracsis)
    // console.log(currentProgram?.requirementsSpecialistEducation?.pracsis)

    // if (
    //   currentSum + program?.sumPracsis <=
    //   program.requirementsSpecialistEducation.pracsis
    // ) {
    //   const sumPractice = percentagePracticePosition * diffMonths
    //   CalculateSumPractice(sumPractice / 100)

    //   setPracticeHoursStartedEditing(false)
    //   setPracticeHours(practiceWeeks)
    //   setCount(prev => prev + 1)

    //   //Resett form
    //   setPrevStartDate(startDate)
    //   setPrevEndDate(endDate)
    //   setStartDate(null)
    //   setEndDate(null)
    //   setPercentagePracticePosition("")
    //   setDiffMonths("")
    //   setSumLocalPractice(0)
    // }

    if (
      currentSum + currentProgram?.sumPracsis >
      currentProgram?.requirementsSpecialistEducation?.pracsis
      //    &&
      // program.id === selectedProgram
    ) {
      setShowModal(true)
    } else {
      const sumPractice = percentagePracticePosition * diffMonths
      CalculateSumPractice(sumPractice / 100)

      setPracticeHoursStartedEditing(false)
      setPracticeHours(practiceWeeks)
      setCount(prev => prev + 1)

      //Resett form
      setPrevStartDate(startDate)
      setPrevEndDate(endDate)
      setStartDate(null)
      setEndDate(null)
      setPercentagePracticePosition("")
      setDiffMonths("")
      setSumLocalPractice(0)
    }
  }

  //Post the practice hours if user agrees in the modal.
  const postPracticeHourAfterCheck = e => {
    const data = {
      rowId: count,
      fromDate: startDate,
      toDate: endDate,
      percentagePracticePosition: parseFloat(percentagePracticePosition),
      diffMonths: parseFloat(diffMonths),
      practice: parseFloat(sumLocalPractice),
      sumLocalPractice: sumLocalPractice,
      programName: programName,
      programId: selectedProgram,
    }

    if (percentagePracticePosition != undefined && diffMonths != undefined) {
      const sumPractice = percentagePracticePosition * diffMonths
      CalculateSumPractice(sumPractice / 100)
    }

    setPracticeHoursStartedEditing(false)
    setPracticeHours(practiceHours => [...practiceHours, data])
    setCount(count + 1)

    //Resett form
    setPrevStartDate(startDate)
    setPrevEndDate(endDate)
    setStartDate(null)
    setEndDate(null)
    setPercentagePracticePosition("")
    setDiffMonths("")
    setSumLocalPractice(0)
    setShowModal(false)
  }

  return (
    <div>
      {showModal && (
        <Modal setModalOpen={setShowModal}>
          <p>
            Du registrerer nå praksis som går ut over kravet til dette
            programmet. Dersom noe av denne praksis skal dokumenteres til andre
            program må du gå tilbake og fordele praksisukene før du leverer.
          </p>
          <div className="w-full flex flex-row justify-around mt-8">
            <button
              onClick={() => {
                setShowModal(false)
              }}
              className="w-fit ml-1 inline-flex justify-end py-2 px-8 border border-gray-200 shadow-sm text-md font-medium rounded-md text-black bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
            >
              Fordel praksisuker
            </button>
            <button
              onClick={postPracticeHourAfterCheck}
              className="w-fit ml-1 inline-flex justify-end py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-md text-white bg-meny-700 hover:bg-meny-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
            >
              Gå videre
            </button>
          </div>
        </Modal>
      )}
      <div className="sm:flex-auto">
        <h1 className="mt-8 text-xl font-semibold text-gray-900">
          Praksisdetaljer
        </h1>
      </div>
      <GetHelpText number={1000} />
      <div>
        <div className="-mx-4 mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
          <table className="min-w-full divide-y divide-gray-300">
            <thead className="bg-meny-700">
              <tr>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-6"
                >
                  Fra
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-semibold text-white lg:table-cell"
                >
                  Til
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-white"
                >
                  Sum praksis
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-white"
                >
                  Programtype
                </th>
                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                  <span className="sr-only">Edit</span>
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {practiceHours?.map(practiceHour => (
                <tr key={practiceHour.rowId}>
                  <td className=" px-3 py-4 text-sm text-gray-600">
                    {practiceHour.fromDate !== undefined ? (
                      <time dateTime={practiceHour.fromDate}>
                        {new Intl.DateTimeFormat("nb-NO", {
                          dateStyle: "medium",
                          // timeStyle: "short",
                        }).format(new Date(practiceHour.fromDate))}
                      </time>
                    ) : null}
                  </td>
                  <td className="hidden px-3 py-4 text-sm text-gray-600 lg:table-cell">
                    {practiceHour.toDate !== undefined ? (
                      <time dateTime={practiceHour.toDate}>
                        {new Intl.DateTimeFormat("nb-NO", {
                          dateStyle: "medium",
                          // timeStyle: "short",
                        }).format(new Date(practiceHour.toDate))}
                      </time>
                    ) : null}
                  </td>
                  <td className="px-3 py-4 text-sm text-gray-600 ">
                    {practiceHour.sumLocalPractice}
                  </td>
                  <td className="px-3 py-4 text-sm text-gray-600 ">
                    {practiceHour.programName}
                  </td>
                  <td className="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                    <button
                      type="button"
                      onClick={e => OnDeleteRow(practiceHour.rowId)}
                      className="bg-white rounded-md font-medium text-meny-600 hover:text-meny-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                        />
                      </svg>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="mt-8  justify-end">
          <form>
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="first-name"
                  className="block text-sm font-medium text-gray-700"
                >
                  Fra dato*
                </label>
                <ReactDatePicker
                  id="startDate"
                  locale="nb"
                  showYearDropdown
                  selected={startDate}
                  onChange={(date: Date) => OnSetStartDate(date)}
                  dateFormat="dd.MM.yyyy"
                  className="block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                />
              </div>

              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="last-name"
                  className="block text-sm font-medium text-gray-700"
                >
                  Til dato*
                </label>
                <ReactDatePicker
                  id="endDate"
                  locale="nb"
                  showYearDropdown
                  selected={endDate}
                  onChange={(date: Date) => OnSetEndDate(date)}
                  dateFormat="dd.MM.yyyy"
                  className="block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                />
              </div>

              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="percentagePracticePosition"
                  className="block text-sm font-medium text-gray-700"
                >
                  Praksis % stilling*
                </label>
                <input
                  {...register("percentagePracticePosition", {
                    required: {
                      value: true,
                      message: "Obligatorisk felt.",
                    },
                    pattern: {
                      value: /^[0-9]$/,
                      message: "Kun tall fra 50 til 100 er tillatt.",
                    },
                    // min: 50,
                    // max: 100,
                  })}
                  type="text"
                  onChange={e =>
                    OnCalculatePercentagePracticePosition(e.target.value)
                  }
                  value={percentagePracticePosition}
                  name="percentagePracticePosition"
                  id="percentagePracticePosition"
                  className="mt-1 focus:ring-meny-700 focus:border-meny-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
              </div>

              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="diffMonths"
                  className="block text-sm font-medium text-gray-700"
                >
                  Ant uker praksis
                </label>
                <input
                  {...register("diffMonths")}
                  readOnly
                  onChange={e => setDiffMonths(e.target.value)}
                  value={diffMonths}
                  type="text"
                  name="diffMonths"
                  id="diffMonths"
                  className="mt-1 bg-gray-300 focus:ring-meny-700 focus:border-meny-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
              </div>

              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="sumPractice"
                  className="block text-sm font-medium text-gray-700"
                >
                  Sum praksis
                </label>
                <input
                  {...register("sumPractice")}
                  readOnly
                  onChange={e => setDiffMonths(e.target.value)}
                  value={sumLocalPractice}
                  type="text"
                  name="sumPractice"
                  id="sumPractice"
                  className="mt-1 bg-gray-300 focus:ring-meny-700 focus:border-meny-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
              </div>

              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="courseProviderAccountId"
                  className="block text-sm font-medium text-gray-700"
                >
                  Programtype
                </label>
                <select
                  id="courseProviderAccountId"
                  name="courseProviderAccountId"
                  onChange={e => {
                    setSelectedProgram(e.target.value)
                  }}
                  value={selectedProgram}
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-meny-700 focus:border-meny-500 sm:text-sm"
                >
                  <option disabled selected value="0">
                    --velg--
                  </option>

                  {programs?.map(a => (
                    <option key={a.id} value={a.id}>
                      {a.programType.name}
                    </option>
                  ))}
                </select>
              </div>

              {/* <div className="col-span-6 sm:col-span-3">
                                <label htmlFor="practice" className="block text-sm font-medium text-gray-700">
                                    Egen vurdering
                                </label>
                                <input
                                    {...register("practice")}
                                    onChange={e => setPractice(e.target.value)}
                                    defaultValue={sumLocalPractice}
                                    value={practice}
                                    type="text"
                                    name="practice"
                                    id="practice"
                                    className="mt-1 focus:ring-meny-700 focus:border-meny-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                />
                            </div> */}
            </div>
            <div className="mt-8 flex justify-end w-full">
              <button
                type="button"
                onClick={e => postPracticeHour(e)}
                className={cyanButton + " inline-flex items-center "}
              >
                Legg til
                <PlusSmIconOutline className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default AddPracticeHours
