import React, { useState, useEffect } from "react"
import { useForm, Controller } from "react-hook-form"
import { Account, Employment } from "../../../Utils/Entities"
import { ErrorMessage } from "@hookform/error-message"
import { Link, navigate } from "gatsby"
import ReactDatePicker, { registerLocale } from "react-datepicker"
import nb from "date-fns/locale/nb"
import "react-datepicker/dist/react-datepicker.css"
import { useAccount, useMsal } from "@azure/msal-react"
import { fetchApi, patchApi, postApi } from "../../../Utils/Api.utils"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { toast } from "react-toastify"

registerLocale("nb", nb)
import Select from "react-select"
import TreeViewComponent from "../../common/treeview/TreeViewComponent"
import useNewEmployment from "../../hooks/newEmploymentAsEmployee/useNewEmployment"

type FormValues = Employment

export const NewEmploymentAsEmployee = ({
  roleOption,
  professionallyActiveStatus,
  prevEmploymentData,
  setShowLoadingData,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<FormValues>()

  const queryClient = useQueryClient()

  const [bedrift, setAccounts] = useState()
  const [parent, setParent] = useState<string>()
  const [position, setPos] = useState<string>()
  const [workplaces, setWorkPlaces] = useState<any[]>([])

  const [positions, setPositions] = useState<any[]>()

  // const [invalidDate, setInvalidDate] = useState<boolean>(false)
  const [smedlem, setSMedlem] = useState<boolean>(false)

  const [disableButton, setDisabled] = useState<boolean>(false)

  const [workPlace, setWorkPlace] = useState<string>("")

  const [employerAndWorkplace, setEmployerAndWorkplace] =
    useState<boolean>(false)

  const [treeViewLoading, setTreeViewLoading] = useState<boolean>(false)
  const [notAWorkplace, setNotAWorkplace] = useState(false)
  const [lastSelectedItem, setLastSelectedItem] = useState(null)
  const [lastSelectedItemId, setLastSelectedItemId] = useState(null)
  const [employerName, setEmployerName] = useState(null)
  const [employerId, setEmployerId] = useState(null)
  const [workplaceError, setWorkplaceError] = useState(false)
  const [startDate, setStartDate] = useState<Date>(() => {
    const endDate = new Date(prevEmploymentData?.endDate)
    endDate.setDate(endDate.getDate() + 1)
    return endDate
  })

  const [originalStartDate, setOriginalStartDate] = useState<Date>(() => {
    const endDate = new Date(prevEmploymentData?.endDate)
    endDate.setDate(endDate.getDate() + 1)
    return endDate
  })

  const [role, setRole] = useState(roleOption === undefined ? 125600000 : 0)

  const treeViewProps = {
    notAWorkplace,
    lastSelectedItem,
    lastSelectedItemId,
    employerName,
    employerId,
  }

  const setTreeViewProps = {
    setNotAWorkplace,
    setLastSelectedItem,
    setLastSelectedItemId,
    setEmployerName,
    setEmployerId,
  }

  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})

  const eventQuery = useQuery(
    "myEvents",
    async () =>
      fetchApi(
        process.env.GATSBY_API_URL + `/Events/MyEvents`,
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {}
  )

  const employersQuery = useQuery(
    "employers",
    async () =>
      fetchApi(
        `${process.env.GATSBY_API_URL}/Accounts/Employers`,
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: data => {
        setAccounts(data)
      },
    }
  )

  useEffect(() => {
    if (parent !== undefined) {
      const workPlaceQuery = async () => {
        fetchApi(
          `${process.env.GATSBY_API_URL}/Accounts/tree/` + parent,
          account,
          inProgress,
          instance
        ).then(res => {
          setWorkPlaces(res.data)
          setTreeViewLoading(false)
        })
      }
      workPlaceQuery() // Call the async function correctly
    }
  }, [parent])

  useEffect(() => {
    const getSub = async () => {
      fetchApi(
        `${process.env.GATSBY_API_URL}/Contacts/Subscriptions`,
        account,
        inProgress,
        instance
      ).then(response => {
        response?.data.forEach(sub => {
          if (
            sub?.parentOrganizationUnit?.name == "Norsk Psykologforening" &&
            sub?.subscriptionTypeName == "Medlemskap" &&
            sub?.stateCode == 0 &&
            sub?.membershipCategory?.name == "Student"
          ) {
            setSMedlem(true)
          }
        })
      })
    }
    getSub()
  }, [])

  const updateWorkPlace = selectedOption => {
    setTreeViewLoading(true)
    setWorkPlace(selectedOption.label)
    setParent(selectedOption.value)
    setWorkplaceError(false)
  }

  const changePos = event => {
    setPos(event.target.value)
  }

  const mutationNewEmployment = useNewEmployment(
    prevEmploymentData,
    professionallyActiveStatus,
    setShowLoadingData,
    eventQuery
  )

  const postWork = async (data, event) => {
    event.preventDefault()

    data.startDate = startDate

    data.workplace = employerAndWorkplace ? parent : lastSelectedItemId
    data.employer = employerId === null ? parent : employerId

    data.workcategory = 292460000

    //Set statuscode 292460002 if start date is in the future so it can become "Kommende" as statuscode
    if (data.startDate > new Date()) {
      data.statecode = 0
      data.statuscode = 292460002
    }

    if (employerAndWorkplace) {
      data.workPlace === data.employer
    }

    if (roleOption !== undefined) {
      data.role = roleOption
    }

    if (data.workplace === null || data.workplace === undefined) {
      setWorkplaceError(true)
    } else {
      setWorkplaceError(false)
      if (eventQuery?.data?.eventRegistration?.length > 0) {
        setDisabled(true)
      } else setDisabled(true)
      setShowLoadingData(true)
      mutationNewEmployment.mutate(data)
    }
  }

  useEffect(() => {
    if (treeViewProps.employerId !== null) {
      fetchApi(
        `${process.env.GATSBY_API_URL}/Accounts/${treeViewProps.employerId}`,
        account,
        inProgress,
        instance
      )
        .then(response => {
          if (response.data.tariff.positions.length > 0) {
            setPositions(response.data.tariff.positions)
          }
        })
        .catch(error => {
          toast.error("Det har skjedd en feil, prøv igjen senere", {
            position: "top-center",
            autoClose: 6000,
          })
        })
    }
  }, [treeViewProps.employerId])

  console.log(treeViewProps)

  return (
    <div>
      <div>
        <p className="block text-sm italic font-normal sm:col-span-2 text-meny-700 sm:mt-px sm:pt-2 sm:col-start-3">
          Hvis du ikke finner riktig arbeidsgiver, arbeidsted eller stilling,{" "}
          <Link
            to={"/app/dialog/nysak/"}
            state={{
              presetValues: {
                incidentType: 20,
                optionText: "Arbeidsforhold",
                title: "Mangler arbeidsgiver eller arbeidssted",
                description: `Rolle: ${
                  roleOption === undefined
                    ? role === 125600000
                      ? "Hovedarbeidsgiver"
                      : "Biarbeidsgiver"
                    : roleOption === 125600000
                    ? "Hovedarbeidsgiver"
                    : "Biarbeidsgiver"
                } \nArbeidsgiver: \nArbeidssted: \nAdresse på arbeidssted: \nPostnummer på arbeidssted: \nAvdeling på arbeidssted: \nOrgnummer: \nStilling: \nProsentvis stilling: \nStart dato:`,
              },
            }}
            className="text-meny-700 underline"
          >
            {" "}
            opprett sak her
          </Link>
        </p>
      </div>

      <div className="">
        <form
          method="POST"
          onSubmit={handleSubmit(postWork)}
          className="space-y-8 "
        >
          <div className="space-y-8 sm:space-y-5">
            <div className="space-y-6sm:space-y-5">
              <div className="space-y-6 sm:space-y-5">
                {smedlem ? (
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                    <label
                      htmlFor="role"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Rolle:
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <select
                        {...register("role")}
                        name="role"
                        id="role"
                        key="role"
                        disabled={true}
                        className=" block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md bg-gray-50"
                      >
                        {/* <option value={125600000}>Hovedarbeidsgiver</option> */}
                        <option value={125600001}>Biarbeidsgiver</option>
                      </select>
                    </div>
                  </div>
                ) : (
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                    <label
                      htmlFor="role"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Rolle:
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <select
                        {...register("role")}
                        name="role"
                        id="role"
                        key="role"
                        disabled={roleOption === undefined ? false : true}
                        onChange={e => setRole(parseInt(e.target.value))}
                        className=" disabled:bg-gray-200 block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                      >
                        {roleOption === undefined ? (
                          <>
                            <option value={125600000}>Hovedarbeidsgiver</option>
                            <option value={125600001}>Biarbeidsgiver</option>
                          </>
                        ) : (
                          <option value={roleOption}>
                            {roleOption === 125600000
                              ? "Hovedarbeidsgiver"
                              : "Biarbeidsgiver"}
                          </option>
                        )}
                      </select>
                    </div>
                  </div>
                )}

                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                  <label
                    htmlFor="employer"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Arbeidsgiver:
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <Select
                      {...register("employer")}
                      name="employer"
                      id="employer"
                      key="employer"
                      isSearchable
                      options={bedrift?.map(a => ({
                        value: a.id,
                        label: a.name,
                        isEmployerAndWorkplace: a.employerAndWorkplace,
                      }))}
                      // defaultValue={defaultCountryCode}
                      // onChange={isNorwegian}
                      placeholder="--Arbeidsgiver--"
                      value={
                        parent === null || parent === undefined
                          ? null
                          : bedrift?.find(option => option.name === parent)
                      }
                      onChange={option => {
                        if (option.isEmployerAndWorkplace) {
                          setEmployerAndWorkplace(true)
                        } else {
                          setEmployerAndWorkplace(false)
                        }
                        updateWorkPlace(option)
                      }}
                      className=" block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                    ></Select>
                  </div>
                </div>

                {workPlace !== "" && workplaces !== undefined && (
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                    <label
                      htmlFor="workPlace"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Arbeidssted:
                    </label>
                    {treeViewLoading === false ? (
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        {employerAndWorkplace ? (
                          <p>{workPlace}</p>
                        ) : (
                          <div>
                            {workplaces !== undefined && (
                              <TreeViewComponent
                                workplaces={workplaces}
                                props={treeViewProps}
                                setProps={setTreeViewProps}
                              />
                            )}
                          </div>
                        )}
                      </div>
                    ) : (
                      <p>Henter arbeidssteder...</p>
                    )}
                  </div>
                )}

                {treeViewProps.employerId !== null && (
                  <div>
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                      <label
                        htmlFor="position"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        Stilling:
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <select
                          {...register("position")}
                          required
                          name="position"
                          id="position"
                          key="position"
                          value={position}
                          onChange={changePos}
                          className=" block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                        >
                          <option disabled selected value="">
                            {" "}
                            -- Stilling --{" "}
                          </option>

                          {positions?.map(p => (
                            <option key={p.id} value={p.id}>
                              {p.jobTitle}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                )}

                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                  <label
                    htmlFor="partTimePosition"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Prosentvis stilling:
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <input
                      type="text"
                      {...register("partTimePosition", {
                        pattern: {
                          value:
                            roleOption === 125600000 || role === 125600000
                              ? /^[5-9][0-9]?$|^100$/i
                              : /^[1-9][0-9]?$|^100$/i,
                          message:
                            roleOption === 125600000 || role === 125600000
                              ? "Kun tall mellom 50-100 er tillatt"
                              : "Kun tall mellom 1-100 er tillatt",
                        },
                      })}
                      name="partTimePosition"
                      placeholder={
                        roleOption === 125600000 || role === 125600000
                          ? "50-100"
                          : "1-100"
                      }
                      defaultValue={0}
                      id="partTimePosition"
                      className="block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                    />
                    <ErrorMessage
                      errors={errors}
                      name="partTimePosition"
                      render={({ message }) => (
                        <p className="text-sm text-red-600">{message}</p>
                      )}
                    />
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                  <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                    Start dato:
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <ReactDatePicker
                      locale="nb"
                      required
                      onChange={date => setStartDate(date)}
                      defaultValue={originalStartDate}
                      selected={
                        startDate === undefined
                          ? new Date()
                          : Date.parse(startDate)
                      }
                      dateFormat="dd/MM/yyyy"
                      minDate={originalStartDate}
                      maxDate={(() => {
                        const endDate = new Date(prevEmploymentData?.endDate)
                        return new Date(
                          endDate.getFullYear(),
                          endDate.getMonth() + 4,
                          endDate.getDate()
                        )
                      })()}
                      className=" block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>
              </div>
              <div className="pt-5 flex justify-end">
                <button
                  type="button"
                  // disabled={disableButton}
                  onClick={() => navigate("/app/profil/arbeidsforhold")}
                  className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
                >
                  Tilbake
                </button>
                <button
                  type="submit"
                  disabled={disableButton || notAWorkplace}
                  className="disabled:bg-gray-200 ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-meny-700 hover:bg-meny-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
                >
                  Lagre
                </button>
              </div>
            </div>
          </div>
        </form>
        <div className="mb-2 flex justify-end">
          {(notAWorkplace || workplaceError) && (
            <p className="text-red-500">Velg et gyldig arbeidssted.</p>
          )}
        </div>
      </div>
    </div>
  )
}
