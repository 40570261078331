import React from "react"
import { BooleanChoiceType } from "./InputFieldsType"

const BooleanChoice = (props: BooleanChoiceType) => {
  const onChange = (e: any) => {
    props.control("update", e.target.value)
  }

  return (
    <div onChange={onChange} className="my-2">
      <span className="font-bold">
        {props.text + (props.isRequired ? "*: " : ": ")}
      </span>
      <input
        type="radio"
        value="ja"
        name={props.groupName || props.text}
        defaultChecked={props.selected}
      />
      <span> Ja </span>
      <input
        type="radio"
        value="nei"
        name={props.groupName || props.text}
        defaultChecked={props.selected === false}
      />
      <span> Nei </span>
      <div className="h-8"></div>
    </div>
  )
}

export default BooleanChoice
