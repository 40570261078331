import { useAccount, useMsal } from "@azure/msal-react"
import { Link, navigate } from "gatsby"
import React, { useContext, useEffect, useState } from "react"
import { useMutation, useQuery } from "react-query"
import { fetchApi, postApi } from "../../../Utils/Api.utils"
import { NewMembershipContext } from "../../mypage/membership/NewMember"
import { changeMembershipTab, changeMembershipTabToPrevious } from "./helpers"

const SendMembership = ({
  educationData,
  documents,
  employmentData,
  setTabId,
  memberTypeParent,
}) => {
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const [membershipPreviousTabId, setMembershipPreviousTabId] = useState(0)
  const [workSituation, setWorkSituation] = useState(778380002)
  const [disableSend, setDisableSend] = useState(false)

  useEffect(() => {
    changeMembershipTabToPrevious(
      memberTypeParent.value,
      setMembershipPreviousTabId
    )

    if (memberTypeParent.value === 0) setWorkSituation(778380000)
    if (memberTypeParent.value === 1) setWorkSituation(778380002)
    if (memberTypeParent.value === 2) setWorkSituation(778380003)
  }, [])

  const userInfoQuery = useQuery(
    "userInfo",
    () =>
      fetchApi(
        process.env.GATSBY_API_URL + "/Contacts/me",
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: data => {},
      onError: _error => {},
    }
  )

  const mutationCreateIncident = useMutation(
    () => {
      return postApi(
        process.env.GATSBY_API_URL + "/Incidents",
        {
          title:
            "Innmeldingssøknad: " +
            userInfoQuery.data?.firstName +
            " " +
            userInfoQuery.data?.lastName,
          subjectId: "6d3779f5-fc9d-ec11-b400-000d3aadca3e",
          invoiceFrequency: 778380001, //Kvartalsvis
          caseTypeCode: 14,
          caseOriginCode: 3,
          approveStatus: 778380001,
          subscriptionType: 778380000,
          OrganizationId: process.env.GATSBY_ORG_GUID,
          ContineInsurence: true,
          WorkSituation: workSituation,
        },
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: data => {
        if (documents.length > 0) {
          const formData = new FormData()
          Array.from(documents).forEach(file =>
            formData.append("arrayOfFiles", file)
          )

          formData.append("EntityId", data)
          formData.append("EntityName", "incident")

          mutationUploadDocumets.mutate(formData)
        }

        if (memberTypeParent.value !== 2) mutationCreateEducation.mutate()
        if (memberTypeParent.value === 2) navigate("/app/dialog/minesaker")
      },
      onError: _error => {
        setDisableSend(false)
      },
      onSettled: () => {},
    }
  )

  const mutationCreateEducation = useMutation(
    () => {
      return postApi(
        process.env.GATSBY_API_URL + "/Educations",
        educationData,
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: data => {
        if (memberTypeParent.value === 1) mutationCreateEmployments.mutate()
        if (memberTypeParent.value !== 1) navigate("/app/dialog/minesaker")
      },
      onError: _error => {
        setDisableSend(false)
      },
      onSettled: () => {},
    }
  )

  const mutationCreateEmployments = useMutation(
    () => {
      return postApi(
        process.env.GATSBY_API_URL + "/Employments",
        employmentData,
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: data => {
        navigate("/app/dialog/minesaker")
      },
      onError: _error => {
        setDisableSend(false)
      },
      onSettled: () => {},
    }
  )

  const mutationUploadDocumets = useMutation(
    formData => {
      return postApi(
        process.env.GATSBY_API_URL + "/Documents",
        formData,
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: () => {},
      onError: _error => {
        setDisableSend(false)
      },
      onSettled: () => {},
    }
  )

  const sendInnSchema = () => {
    setDisableSend(true)
    mutationCreateIncident.mutate()
  }

  return (
    <div>
      <div>
        <div>
          <p className="mt-1 text-lg text-gray-900">
            *Betaling av kontingent (kun til info): 4 fakturaer per år /
            Kvartalsvis
          </p>
          <p className="mt-1 text-md text-gray-700">
            Se mer informasjon om betaling av kontingent på
          </p>
          <p className="mt-1 text-md text-meny-700">
            <Link
              to="https://www.psykologforeningen.no/medlem/medlemsfordeler/fakturainformasjon"
              target="_blank"
              rel="noopener noreferrer"
              className=" break-words"
            >
              https://www.psykologforeningen.no/medlem/medlemsfordeler/fakturainformasjon
            </Link>
          </p>
        </div>
      </div>
      <div className="pt-5 flex justify-end">
        <button
          type="button"
          className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
          onClick={() => {
            changeMembershipTab(membershipPreviousTabId)
            setTabId(membershipPreviousTabId)
          }}
        >
          Tilbake
        </button>

        <button
          type="button"
          disabled={disableSend}
          onClick={sendInnSchema}
          className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-meny-700 hover:bg-meny-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
        >
          Send
        </button>
      </div>
    </div>
  )
}

export default SendMembership
