import { useAccount, useMsal } from "@azure/msal-react"
import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { fetchApi, postApi } from "../../../../Utils/Api.utils"
import ReactDatePicker from "react-datepicker"
import FileUpload from "../../document/FileUpload"
import { navigate } from "gatsby"
import { Switch } from "@headlessui/react"
import { classNames } from "../../../common/graphichs/misc"
import { ErrorMessage } from "@hookform/error-message"
import { toast } from "react-toastify"
import HelperText from "../../../common/text/HelperText"
import GetHelpText from "../../../common/text/GetHelpText"

const ExternalCourse = ({
  program,
  mutationCreateAcitivity,
  setComment,
  setIncidentTitle,
  setShowLoadingData,
  showActivityButtons,
}) => {
  const queryClient = useQueryClient()
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const [activityComment, setActivityComment] = useState("")
  const [alternativeCourceProvider, setAlternativeCourceProvider] =
    useState(false)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [selectedCourseProvider, SetSelectedCourseProvider] = useState("0")
  const [altCourseProvider, SetAltCourseProvider] = useState("")
  const [courseProviders, setCourseProviders] = useState<any[]>()
  const {
    register,
    handleSubmit,
    reset,
    control,
    setError,
    formState: { errors },
  } = useForm()

  useQuery(
    "courseProvidersData",
    () =>
      fetchApi(
        process.env.GATSBY_API_URL + "/Accounts/CourseProvider/all",
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: data => {
        setCourseProviders(data)
      },
      onError: _error => {},
    }
  )

  const showAltCourceProvider = () => {
    setAlternativeCourceProvider(!alternativeCourceProvider)
    SetSelectedCourseProvider("0")
  }

  const OnChangeCourceProvider = e => {
    SetSelectedCourseProvider(e)
    setAlternativeCourceProvider(false)
  }

  const postActivity = async (data, e) => {
    setIncidentTitle(
      "Godkjenning av ekstern kursdeltagelse for spesialistutdanning"
    )
    data.activityType = 292460000
    data.approveStatus = 292460000 //Ikke fullført registrering
    // Emne = Godkjenning av eksterne vedlikeholdskurs
    data.subjectId = "eccca80d-dcb0-ec11-9840-000d3abf775d"
    data.specialistProgramId = program?.id
    data.fromDate = startDate
    data.toDate = endDate
    data.comment = activityComment
    data.educationId = program.specialistEducation?.id

    if (selectedCourseProvider != "0") {
      data.courseProviderAccountId = selectedCourseProvider
      data.courseProviderAccountName = ""
    }
    // if (selectedCourseProvider == "0")
    //   data.courseProviderAccountName = altCourseProvider

    if (startDate == null || endDate == null) {
      toast.warning("Vennligst velg Fra og Til dato!", {
        position: "top-center",
        autoClose: 10000,
      })

      return
    }

    setShowLoadingData(true)
    mutationCreateAcitivity.mutate(data)
  }

  return (
    <div>
      <form method="POST" onSubmit={handleSubmit(postActivity)}>
        <div className="grid grid-cols-6 gap-6">
          <div className="col-span-6">
            <GetHelpText number={1006} />
          </div>
          <div className="col-span-6 sm:col-span-3">
            <label
              htmlFor="first-name"
              className="block text-sm font-medium text-gray-700"
            >
              Fra dato*
            </label>
            <ReactDatePicker
              id="startDate"
              locale="nb"
              showYearDropdown
              selected={startDate}
              onChange={(date: Date) => setStartDate(date)}
              dateFormat="dd.MM.yyyy"
              className="block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
            />
          </div>

          <div className="col-span-6 sm:col-span-3">
            <label
              htmlFor="last-name"
              className="block text-sm font-medium text-gray-700"
            >
              Til dato*
            </label>
            <ReactDatePicker
              id="endDate"
              locale="nb"
              showYearDropdown
              selected={endDate}
              onChange={(date: Date) => setEndDate(date)}
              dateFormat="dd.MM.yyyy"
              className="block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
            />
          </div>

          <div className="col-span-6 sm:col-span-3">
            <label
              htmlFor="courseName"
              className="block text-sm font-medium text-gray-700"
            >
              Kursnavn*
            </label>
            <input
              {...register("courseName", {
                required: {
                  value: true,
                  message: "Obligatorisk felt.",
                },
                maxLength: { value: 100, message: "Maks tegn er 100" },
              })}
              type="text"
              required
              name="courseName"
              id="courseName"
              className="mt-1 focus:ring-meny-700 focus:border-meny-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            />
            <ErrorMessage
              errors={errors}
              name="courseName"
              render={({ message }) => (
                <p className="text-sm text-red-600">{message}</p>
              )}
            />
          </div>

          <div className="col-span-6 sm:col-span-3">
            <label
              htmlFor="amountHours"
              className="block text-sm font-medium text-gray-700"
            >
              Antall timer*
            </label>
            <input
              type="number"
              {...register("amountHours", {
                required: {
                  value: true,
                  message: "Obligatorisk felt.",
                },
                // pattern: {
                //   value: /^[0-9]+$/,
                //   message: "Kun tall er tillatt.",
                // },
                min: {
                  value: 0,
                  message: "Kun tall fra 0 til 200 er tillatt.",
                },
                max: {
                  value: 200,
                  message: "Kun tall fra 0 til 200 er tillatt.",
                },

                // validate: {
                //   positiveNumber: value => parseFloat(value) > 0,
                //   lessThanHundred: value => parseFloat(value) < 200,
                // },
              })}
              name="amountHours"
              id="amountHours"
              className="mt-1 focus:ring-meny-700 focus:border-meny-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            />
            <ErrorMessage
              errors={errors}
              name="amountHours"
              render={({ message }) => (
                <p className="text-sm text-red-600">{message}</p>
              )}
            />
          </div>

          <div className="col-span-6 sm:col-span-3">
            <label
              htmlFor="courseProviderAccountId"
              className="block text-sm font-medium text-gray-700"
            >
              Kursleverandør
            </label>
            <select
              id="courseProviderAccountId"
              name="courseProviderAccountId"
              onChange={e => OnChangeCourceProvider(e.target.value)}
              value={selectedCourseProvider}
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-meny-700 focus:border-meny-500 sm:text-sm"
            >
              <option disabled selected value="0">
                --velg--
              </option>

              {courseProviders?.map(a => (
                <option key={a.id} value={a.id}>
                  {a.name}
                </option>
              ))}
            </select>
          </div>

          <div className="col-span-6 sm:col-span-3">
            <p
              // htmlFor="employerAlternative"
              className="block text-sm italic m-2 font-normal sm:col-span-2 text-meny-700 sm:mt-px sm:pt-2 sm:col-start-3"
            >
              Min kursleverandør står ikke i listen
            </p>
            <Switch
              checked={alternativeCourceProvider}
              onChange={showAltCourceProvider}
              className={classNames(
                alternativeCourceProvider ? "bg-meny-600" : "bg-gray-900",
                "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
              )}
            >
              <span
                aria-hidden="true"
                className={classNames(
                  alternativeCourceProvider ? "translate-x-5" : "translate-x-0",
                  "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                )}
              />
            </Switch>
          </div>

          {alternativeCourceProvider ? (
            <div className="col-span-6 sm:col-span-3">
              <label
                htmlFor="courseProviderAccountName"
                className="block text-sm font-medium text-gray-700"
              >
                Alternativ kursleverandør
              </label>
              <input
                type="text"
                {...register("courseProviderAccountName", {
                  required: false,
                  maxLength: {
                    value: 100,
                    message: "Maks antall tegn er 100",
                  },
                })}
                // onChange={e => SetAltCourseProvider(e.target.value)}
                name="courseProviderAccountName"
                id="courseProviderAccountName"
                autoComplete="courseProviderAccountName"
                className="mt-1 focus:ring-meny-700 focus:border-meny-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
              <ErrorMessage
                errors={errors}
                name="courseProviderAccountName"
                render={({ message }) => (
                  <p className="text-sm text-red-600">{message}</p>
                )}
              />
            </div>
          ) : null}

          <div className="col-span-6">
            <label
              htmlFor="comment"
              className="block text-sm font-medium text-gray-700"
            >
              Kommentar
            </label>
            <div className="mt-1">
              <textarea
                id="comment"
                {...register("comment", {
                  required: false,
                  maxLength: {
                    value: 2000,
                    message: "Maks antall tegn er 2000",
                  },
                })}
                onChange={e => {
                  setComment(e.target.value)
                  setActivityComment(e.target.value)
                }}
                rows={3}
                className="shadow-sm focus:ring-meny-700 focus:border-meny-500 block w-full sm:text-sm border border-gray-300 rounded-md"
                placeholder="Kommentar"
                defaultValue={""}
              />
              <ErrorMessage
                errors={errors}
                name="comment"
                render={({ message }) => (
                  <p className="text-sm text-red-600">{message}</p>
                )}
              />
            </div>
            <HelperText helpertext="Kort beskrivelse av aktiviteten." />
          </div>
        </div>
        {showActivityButtons ? (
          <div className="mt-8 border-t flex justify-end">
            <button
              type="submit"
              className="ml-3 mt-6 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-meny-600 hover:bg-meny-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
            >
              Last opp dokumenter
            </button>
          </div>
        ) : null}
      </form>
    </div>
  )
}

export default ExternalCourse
