import { Link, navigate } from "gatsby"
import React from "react"
import HeaderText from "../../../common/text/Header"

const Søknad = () => {
  return (
    <div className="max-w-3xl mx-auto divide-y-2 divide-gray-200">
      <HeaderText text="Søknad om nedsatt kontingent" />
      <form
        className="mt-6
                  space-y-6
                  
                  bg-white
                  rounded-lg
                  shadow
                  px-5
                  py-6
                  sm:px-6"
        method="POST"
      >
        <h1 className="flex justify-center">
          <p>Retningslinjer for nedsettelse og betaling av kontingent: </p>
        </h1>

        <h2 className="flex justify-center">
          {/* 
        <a href="https://www.psykologforeningen.no/foreningen/vedtekter-og-retningslinjer/retningslinjer-for-nedsettelse-og-betaling-av-kontingent">
            {" "}
            Du kan lese våre retningslinjer ved å{" "}
            <a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600">
              klikke her
            </a>
          </a> */}
          Du kan lese våre retningslinjer ved å &nbsp;
          {/* <a 

          href={() => navigate("/app/medlemskap/applications/rules")}
          onClick={() => navigate("/app/medlemskap/applications/rules")} className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600">
            klikke her
          </a> */}
          <Link
            to="/app/medlemskap/applications/rules"
            className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
          >
            klikk her
          </Link>
        </h2>
        <div className="flex justify-center py-4">
          <Link
            className="ml-1 inline-flex justify-center py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-md text-white bg-meny-700 hover:bg-meny-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-600"
            to="/app/medlemskap/applications/nedsattkontigent"
          >
            Søk her
          </Link>
        </div>
      </form>
    </div>
  )
}

export default Søknad
