import React, { useState } from "react"
import { useQuery } from "react-query"
import { fetchApiAnonymous } from "../../../../Utils/Api.utils"
import DocumentCheckInDownload from "../../../mypagecomponents/document/DocumentCheckInDownload"
import {
  Checkin,
  Pass,
  SessionType,
  SpeakerEngagement,
} from "../../../mypagecomponents/event/EventType"
import { EventRegistrationTypeEnum } from "../../../mypagecomponents/event/helpers/Event"
import DocumentsSection from "./DocumentsSection"
import EventDataSection from "./EventDataSection"
import EventDescriptionSection from "./EventDescriptionSection"
import PayerSection from "./PayerSection"
import PriceSection from "./PriceSection"
import SessionList from "./SessionList"

const CheckinRegistrationPage = (
  props: Checkin & { speakerEngagement: Array<SpeakerEngagement> }
) => {
  const [sessions, setSessions] = useState<Array<SessionType>>([])
  const [passes, setPasses] = useState<Array<Pass>>([])
  const [registrationType, setRegistrationType] =
    useState<EventRegistrationTypeEnum>()

  useQuery<Array<SessionType>>(
    "session-" + props.eventRegistration?.eventCrm?.readableeventid,
    async () =>
      fetchApiAnonymous(
        process.env.GATSBY_API_URL +
          `/Events/${props.eventRegistration?.eventCrm?.readableeventid}/GetSession`
      ).then(res => res.data),
    {
      onSuccess: data => {
        setSessions(data)
      },
    }
  )

  useQuery<{ registrationType: number }>(
    ["registrationType-" + props.eventRegistration?.eventCrm?.readableeventid],
    async () =>
      fetchApiAnonymous(
        process.env.GATSBY_API_URL +
          `/Events/registrationType/${props.eventRegistration?.eventCrm?.eventtypeid}`
      ).then(res => res.data),
    {
      onSuccess: data => {
        setRegistrationType(data.registrationType)
      },
    }
  )

  useQuery<Array<Pass>>(
    [
      "pass-" + props.eventRegistration?.eventCrm?.readableeventid,
      registrationType,
    ],
    async () => {
      if (registrationType === EventRegistrationTypeEnum.noSessions) return []
      return fetchApiAnonymous(
        process.env.GATSBY_API_URL +
          `/Events/${props.eventRegistration?.eventCrm?.readableeventid}/GetPasses`
      ).then(res => res.data)
    },
    {
      enabled: !!registrationType,
      onSuccess: data => {
        setPasses(data)
      },
    }
  )

  return (
    <div>
      <DocumentCheckInDownload
        entityLogicalName="CheckinId"
        entityId={props.checkinid}
        buttonText="Kursbevis"
      />

      <EventDescriptionSection {...props.eventRegistration?.eventCrm} />
      <EventDataSection {...props.eventRegistration} />
      {registrationType === EventRegistrationTypeEnum.noSessions && (
        <PriceSection passes={passes}></PriceSection>
      )}
      <SessionList
        checkin={props}
        SessionRegs={
          props.eventRegistration?.sessionRegistrations
            ? props.eventRegistration?.sessionRegistrations
            : []
        }
        Sessions={sessions}
      ></SessionList>
      <PayerSection {...props.eventRegistration} />

      <DocumentsSection
        eventId={props.event}
        sessions={sessions.map(item => ({
          sessionId: item.id,
          sessionName: item.name || "",
        }))}
      />
    </div>
  )
}

export default CheckinRegistrationPage
