import { useAccount, useMsal } from "@azure/msal-react"
import React, { Fragment, useEffect, useRef, useState } from "react"
import { Dialog, Transition } from "@headlessui/react"
import { CheckIcon, MailIcon } from "@heroicons/react/outline"
import { useMutation, useQueryClient } from "react-query"
import { patchApi, postApi, postGetDocumentApi } from "../../../Utils/Api.utils"
import DocumentList from "../document/DocumentList"
import FileUpload from "../document/FileUpload"
import { useForm } from "react-hook-form"
import { TimeLineCommentVal } from "../../../Utils/Types"
import { ErrorMessage } from "@hookform/error-message"
import { toast } from "react-toastify"
import { cyanButton } from "../../../styles/tailwindClasses"
import LoadingData from "../../common/graphichs/LoadingData"

const TimeLineComment = ({
  entityName,
  entityTitle,
  entityId,
  incidentInactive,
  // blockMessage,
}) => {
  const queryClient = useQueryClient()
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const [description, setDescription] = useState("")
  const [subject, setSubject] = useState(entityTitle)
  const [showDocumentComponent, setShowDocumentComponent] = useState(false)
  const [messageId, setMessageId] = useState("")
  const [showMessageButtons, setShowMessageButtons] = useState(true)
  const [documents, setDocuments] = useState([])
  const [disableButton, setDisableButton] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const {
    register,
    handleSubmit,
    reset,
    setError,
    clearErrors,
    resetField,
    formState: { errors, isDirty, isValid },
  } = useForm<TimeLineCommentVal>()
  console.log(incidentInactive)
  const mutationUpdateIncident = useMutation(
    () => {
      return patchApi(
        process.env.GATSBY_API_URL + "/Incidents",
        {
          id: entityId,
          state: 0, //Aktiv
        },
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["userIncidentData"])
      },
      onError: _error => {},
      onSettled: () => {},
    }
  )

  const mutationCreateMessage = useMutation(
    () => {
      return postApi(
        process.env.GATSBY_API_URL + "/Messages",
        {
          subject: subject,
          description: description,
          direction: false,
          regardingObjectIncidentId: entityId,
        },
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: data => {
        if (incidentInactive) mutationUpdateIncident.mutate()
        setMessageId(data)
        setDisableButton(false)
        queryClient.invalidateQueries(["userMessagesData"])
        setShowDocumentComponent(false)
        setSubject(entityTitle)
        setIsLoading(false)
        setDescription("")
      },
      onError: _error => {
        setDisableButton(false)
        setIsLoading(false)
      },
      onSettled: () => {},
    }
  )

  const mutationGetDocuments = useMutation(
    () => {
      return postGetDocumentApi(
        process.env.GATSBY_API_URL + "/Documents/Regarding/Get",
        {
          PortalMessageId: messageId,
        },
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: data => {
        setDocuments(data)
      },
      onError: _error => {},
      onSettled: () => {},
    }
  )

  const mutationCreateMessageWithFile = useMutation(
    () => {
      return postApi(
        process.env.GATSBY_API_URL + "/Messages",
        {
          subject: subject,
          description: description,
          direction: false,
          regardingObjectIncidentId: entityId,
        },
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: data => {
        setMessageId(data)
        queryClient.invalidateQueries(["userMessagesData"])
        setIsLoading(false)
        setDisableButton(false)
        setShowDocumentComponent(true)
        setShowMessageButtons(false)
      },
      onError: _error => {},
      onSettled: () => {},
    }
  )

  const mutationUpdateMessage = useMutation(
    () => {
      return patchApi(
        process.env.GATSBY_API_URL + "/Messages",
        {
          id: messageId,
          subject: subject,
          description: description,
          direction: false,
          regardingObjectIncidentId: entityId,
        },
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["userMessagesData"])
        setSubject(entityTitle)
        setDescription("")
        setShowDocumentComponent(false)
      },
      onError: _error => {},
      onSettled: () => {
        setShowMessageButtons(true)
      },
    }
  )

  const postIncident = async e => {
    e.preventDefault()
    setDisableButton(true)
    setIsLoading(true)
    mutationCreateMessage.mutate()
  }

  const postMessageWithFile = async e => {
    e.preventDefault()
    if (
      subject == "" ||
      subject == undefined ||
      description == "" ||
      description == undefined
    ) {
      toast.warning("Vennligst fyll ut emne og beskrivelse!", {
        position: "top-center",
        autoClose: 10000,
      })
      return
    }
    setIsLoading(true)
    setDisableButton(true)
    mutationCreateMessageWithFile.mutate()
  }

  const patchMessage = async e => {
    e.preventDefault()
    mutationUpdateMessage.mutate()
  }

  return isLoading ? (
    <LoadingData subject="Oppretter" />
  ) : (
    <div>
      <form method="POST" onSubmit={postIncident} className="space-y-6">
        <div>
          <label
            htmlFor="title"
            className="block text-sm font-medium text-gray-700"
          >
            Emne
          </label>
          <div className="mt-1">
            <input
              {...register("title", {
                required: {
                  value: true,
                  message: "Obligatorisk felt.",
                },
                minLength: {
                  value: 3,
                  message: "Minimum tegn er 3",
                },
                maxLength: {
                  value: 100,
                  message: "Maks tegn er 100",
                },
              })}
              id="title"
              name="title"
              type="text"
              autoComplete="title"
              onChange={e => setSubject(e.target.value)}
              value={subject}
              required
              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-meny-700 focus:border-meny-500 sm:text-sm"
            />
            <ErrorMessage
              errors={errors}
              name="title"
              render={({ message }) => (
                <p className="text-sm text-red-600">{message}</p>
              )}
            />
          </div>
        </div>

        <div>
          <label
            htmlFor="message"
            className="block text-sm font-medium text-gray-700"
          >
            Melding
          </label>
          <div className="mt-1">
            <textarea
              {...register("message", {
                required: {
                  value: true,
                  message: "Obligatorisk felt.",
                },
                minLength: {
                  value: 10,
                  message: "Minimum tegn er 10",
                },
                maxLength: {
                  value: 2000,
                  message: "Maks tegn er 2000",
                },
              })}
              id="message"
              name="message"
              rows={3}
              required
              onChange={e => setDescription(e.target.value)}
              className="max-w-lg shadow-sm block w-full focus:ring-meny-700 focus:border-meny-500 sm:text-sm border border-gray-300 rounded-md"
              value={description}
            />
            <ErrorMessage
              errors={errors}
              name="message"
              render={({ message }) => (
                <p className="text-sm text-red-600">{message}</p>
              )}
            />
          </div>
        </div>
        {showMessageButtons ? (
          <div className="pt-5">
            <div className="flex justify-end">
              <button
                type="button"
                onClick={e => postMessageWithFile(e)}
                className={cyanButton + "mr-3 inline-flex justify-center "}
              >
                Last opp dokumenter
              </button>
              <button type="submit" className={cyanButton}>
                Send melding
              </button>
            </div>
          </div>
        ) : null}
      </form>
      {showDocumentComponent ? (
        <div>
          <h4 className="text-lg text-center font-bold mt-3 ">
            Meldingen {subject} er sendt inn, last opp dokumenter
          </h4>
          <p className="mt-2 text-sm text-gray-600"></p>
          <p className="mt-2 text-sm text-gray-600"></p>
          <div className="mt-2">
            <DocumentList
              documents={documents}
              mutationGetDocuments={mutationGetDocuments}
              canDelete={true}
            />
          </div>
          <FileUpload
            entityId={messageId}
            entityName={"pp_portalmessage"}
            entityId2={entityId}
            entityName2={entityName}
            entityId3={null}
            entityName3={null}
            mutationGetDocuments={mutationGetDocuments}
            setDocuments={null}
            isRequired={false}
            isReturn={false}
          />
          <div className="pt-5">
            <div className="flex justify-center">
              <button
                type="button"
                onClick={e => patchMessage(e)}
                className={cyanButton + " inline-flex items-center "}
              >
                Send Inn
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default TimeLineComment
