import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react"
import { navigate } from "gatsby-link"
import React from "react"
import { useQuery } from "react-query"
import { fetchApi } from "../../../Utils/Api.utils"

export const NotMemberRole = props => {
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const isAuthenticated = useIsAuthenticated()
  const { Component, ...rest } = props

  const webRoleInfo = useQuery(
    "webRoleData",
    () =>
      fetchApi(
        `${process.env.GATSBY_API_URL}/Contacts/Roles`,
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      enabled: !!isAuthenticated,
      onSuccess: data => {
        // if (isAuthenticated) navigation = GetWebRolesNav()
        const role = data.some(r => r.role === 292460001)

        if (data.length === 0 || role) {
          navigate(-1)
          return null
        }
      },
      onError: _error => {},
    }
  )
  return <Component {...rest} />
}
