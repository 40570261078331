import { useAccount, useMsal } from "@azure/msal-react"
import React, { useEffect, useRef, useState } from "react"
import { useMutation, useQueryClient } from "react-query"
import { toast } from "react-toastify"
import { postApi } from "../../../Utils/Api.utils"
import LoadingData from "../../common/graphichs/LoadingData"

type fileUploadProps = {
  entityId
  entityName
  entityId2
  entityName2
  entityId3
  entityName3
  mutationGetDocuments
  setDocuments
  isRequired
  isReturn
  setFileIsInvalid?
}

const FileUpload = (props: fileUploadProps) => {
  const queryClient = useQueryClient()
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const [selectedFiles, setSelectedFiles] = useState([])
  const drop = useRef(null)
  const [disableButton, setDisableButton] = useState<boolean>(false)
  const [isDocumentRequired, setIsDocumentRequired] = useState<boolean>(
    props.isRequired
  )
  const [showLoadingData, setShowLoadingData] = useState(false)
  // const [invalidFileType]

  const mutationUploadDocumets = useMutation(
    formData => {
      return postApi(
        process.env.GATSBY_API_URL + "/Documents",
        formData,
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([props.entityId + "-" + props.entityName])
        setSelectedFiles([])

        if (props.mutationGetDocuments !== null)
          props.mutationGetDocuments.mutate()
        if (
          props.entityName == "msevtmgt_session" ||
          props.entityName == "msevtmgt_event"
        ) {
          toast.success("Kursmateriellet er lastet opp!", {
            position: "top-center",
            autoClose: 3000,
          })
          window.setTimeout(() => {
            window.location.reload()
          }, 3000)
        }
        setDisableButton(false)
      },
      onError: _error => {
        setDisableButton(false)
      },
      onSettled: () => {
        setShowLoadingData(false)
      },
    }
  )

  const UploadFiles = () => {
    if (selectedFiles.length == 0) {
      toast.warning("Vennligst velg en eller flere filer!", {
        position: "top-center",
      })
      return
    }

    const formData = new FormData()
    Array.from(selectedFiles).forEach(file =>
      formData.append("arrayOfFiles", file)
    )

    formData.append("EntityId", props.entityId)
    formData.append("EntityName", props.entityName)

    if (props.entityId2 !== null && props.entityId2 !== undefined)
      formData.append("EntityId2", props.entityId2)
    if (props.entityName2 !== null)
      formData.append("entityName2", props.entityName2)
    if (props.entityId3 !== null && props.entityId3 !== undefined)
      formData.append("EntityId3", props.entityId3)
    if (props.entityName3 !== null)
      formData.append("entityName3", props.entityName3)

    setDisableButton(true)
    setShowLoadingData(true)
    mutationUploadDocumets.mutate(formData)
  }

  const onFileChange = e => {
    if (props.entityName === "expenseReportLine") {
      const fileList = Array.from(e.target.files)
      fileList?.forEach(file => {
        const filename = file.name.toLowerCase()
        if (
          !filename.endsWith(".pdf") &&
          !filename.endsWith(".jpg") &&
          !filename.endsWith(".jpeg") &&
          !filename.endsWith(".png")
        ) {
          toast.warning("Kvitteringen må være pdf, jpg eller png-format!", {
            position: "top-center",
          })
          props.setFileIsInvalid(true)
          return
        } else props.setFileIsInvalid(false)
      })
    }
    setSelectedFiles(Array.from(e.target.files))

    if (props.isReturn) {
      props.setDocuments(Array.from(e.target.files))
      return
    }
  }

  const handleDragOver = e => {
    e.preventDefault()
    e.stopPropagation()
    if (props.entityName === "expenseReportLine") {
      const fileList = Array.from(e.dataTransfer.files)
      fileList?.forEach(file => {
        const filename = file.name.toLowerCase()
        if (
          !filename.endsWith(".pdf") &&
          !filename.endsWith(".jpg") &&
          !filename.endsWith(".jpeg") &&
          !filename.endsWith(".png")
        ) {
          toast.warning("Kvitteringen må være pdf, jpg eller png-format!", {
            position: "top-center",
          })
          props.setFileIsInvalid(true)
          return
        } else props.setFileIsInvalid(false)
      })
    }
    setSelectedFiles(Array.from(e.dataTransfer.files))
  }

  const handleDrop = e => {
    e.preventDefault()
    e.stopPropagation()
    if (props.entityName === "expenseReportLine") {
      const fileList = Array.from(e.dataTransfer.files)
      fileList?.forEach(file => {
        const filename = file.name.toLowerCase()
        if (
          !filename.endsWith(".pdf") &&
          !filename.endsWith(".jpg") &&
          !filename.endsWith(".jpeg") &&
          !filename.endsWith(".png")
        ) {
          toast.warning("Kvitteringen må være pdf, jpg eller png-format!", {
            position: "top-center",
          })
          props.setFileIsInvalid(true)
          return
        } else props.setFileIsInvalid(false)
      })
    }
    setSelectedFiles(Array.from(e.dataTransfer.files))
    if (e.dataTransfer.files.length > 0) {
      setIsDocumentRequired(false)
      if (props.isReturn) props.setDocuments(Array.from(e.dataTransfer.files))
      return
    }
  }

  useEffect(() => {
    drop?.current?.addEventListener("dragover", handleDragOver)
    drop?.current?.addEventListener("drop", handleDrop)

    return () => {
      drop?.current?.removeEventListener("dragover", handleDragOver)
      drop?.current?.removeEventListener("drop", handleDrop)
    }
  }, [])

  return (
    <div>
      <div>
        {showLoadingData && <LoadingData subject="Laster opp fil(er)..." />}
      </div>
      <div className="sm:col-span-6" ref={drop}>
        <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
          <div className="space-y-1 text-center">
            {selectedFiles.length > 0 ? (
              <ul
                role="list"
                className="border border-gray-200 rounded-md divide-y divide-gray-200"
              >
                {selectedFiles.map(file => (
                  <li
                    key={file.name}
                    className="pl-3 pr-4 py-3 flex items-center justify-between text-sm"
                  >
                    <div className="ml-4 flex-shrink-0 flex space-x-4">
                      <p>Filnavn: {file.name}</p>
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              <svg
                className="mx-auto h-12 w-12 text-gray-400"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 48 48"
                aria-hidden="true"
              >
                <path
                  d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )}
            <div className="flex text-sm text-gray-600">
              <label
                htmlFor="file-upload"
                className="relative cursor-pointer bg-meny-700 rounded-md font-medium text-white hover:bg-meny-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-meny-600"
              >
                <span>Last opp fil(er)</span>
                <input
                  // hidden
                  required={isDocumentRequired}
                  id="file-upload"
                  name="file-upload"
                  type="file"
                  multiple
                  onChange={e => onFileChange(e)}
                  className="sr-only"
                />
              </label>
              <p className="pl-1">eller dra og slip</p>
            </div>
            {props.entityName === "expenseReportLine" ? (
              <p className="text-xs text-gray-600">
                PDF, PNG eller JPG opp til 30MB
              </p>
            ) : (
              <p className="text-xs text-gray-600">
                TXT, PDF, DOCX, XLSX, PPTX opp til 30MB
              </p>
            )}
          </div>
        </div>
      </div>
      {!props.isReturn ? (
        <div className="flex p-2 space-x-4 justify-end">
          <button
            className="px-4 py-2 text-white bg-green-500 rounded-md "
            type="button"
            disabled={disableButton}
            onClick={UploadFiles}
          >
            Last opp
          </button>
        </div>
      ) : null}
    </div>
  )
}

export default FileUpload
