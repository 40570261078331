import React, { useEffect, useState } from "react"
import { useQueries, useQuery } from "react-query"
import { fetchApiAnonymous } from "../../../Utils/Api.utils"
import MyPageLayout from "../../common/layouts/myPageLayout"
import { ListLoading } from "../../common/loading"
import { EventAccordionGroup } from "../../mypagecomponents/event"
import { EventType, SessionType } from "../../mypagecomponents/event/EventType"
import { FilterBy, SortBy } from "../../mypagecomponents/event/filter"
import { CityOptionsetEnum } from "../../mypagecomponents/event/helpers/Event"
import SessionAccordionGroup from "../../mypagecomponents/event/SessionAccordionGroup"
import SessionAccordionGroupFiltered from "../../mypagecomponents/event/SessionAccordionGroupFiltered"

const EventCalendar = () => {
  const [events, setEvents] = useState<Array<EventType> | undefined>(undefined)
  const [filteredEvents, setFilteredEvents] = useState<
    Array<EventType> | undefined
  >(undefined)
  const [triggerResort, setTriggerResort] = useState<boolean>(false)
  const [triggerRefilter, setTriggerRefilter] = useState<boolean>(false)
  const [sortOn, setSortOn] = useState<string>("date")
  const [session, setSession] = useState<
    Array<{ readableEventId: string; session: Array<SessionType> }> | undefined
  >(undefined)
  const [filteredSession, setFilteredSession] = useState<
    Array<{ readableEventId: string; session: Array<SessionType> }> | undefined
  >(undefined)
  const [categoris, setCategoris] = useState<Array<string>>(["alle"])
  const [cities, setCities] = useState<Array<string>>(["alle"])
  const [categoriFilter, setCategoriFilter] = useState<string>("alle")
  const [phaseFilter, setPhaseFilter] = useState<string>("alle")
  const [allSessions, setAllSessions] = useState<Array<SessionType>>([])
  const [filteredAllSessions, setFilteredAllSessions] = useState<
    Array<SessionType> | undefined
  >([])

  const eventQuery = useQuery<Array<EventType>>(
    "event",
    async () =>
      fetchApiAnonymous(process.env.GATSBY_API_URL + `/Events`).then(
        res => res.data
      ),
    {
      onSuccess: data => {
        setEvents(data)
        setTriggerResort(prev => !prev)
        setTriggerRefilter(prev => !prev)
        setFilteredEvents(data)
        const cat = [
          ...categoris,
          ...data.map(e => e.customFields?.eventType?.name || "undefined"),
        ].filter(
          (item, index, self) =>
            item !== "undefined" && self.indexOf(item) === index
        ) // remove duplicates and undefined
        // const c = [
        //   ...cities,
        //   ...CityOptionsetEnum
        // ]
        setCategoris(cat)
      },
    }
  )

  const sessionQueries = useQueries<
    Array<{ readableEventId: string; session: Array<SessionType> }>
  >(
    events?.map(item => {
      return {
        enabled: !!events,
        queryKey: ["readableEventId", item.readableEventId],
        queryFn: (): Promise<{
          readableEventId: string
          session: Array<SessionType>
        }> =>
          fetchApiAnonymous(
            process.env.GATSBY_API_URL +
              `/Events/${item.readableEventId || ""}/GetSession`
          ).then(res => {
            const data: {
              readableEventId: string
              session: Array<SessionType>
            } = {
              readableEventId: item.readableEventId,
              session: res.data,
            }
            let array: SessionType[] = []
            data.session.forEach(s => {
              array.push(s)
            })

            setAllSessions(array)
            setFilteredAllSessions(array)

            return data
          }),
      }
    }) || []
  )

  useEffect(() => {
    const isDoneLoading =
      sessionQueries.every(item => item.isSuccess) && (events || []).length > 0

    if (isDoneLoading && session === undefined) {
      const data = sessionQueries
        .filter(item => item.isSuccess)
        .map(item => item.data)

      // @ts-ignore
      setSession([...data])
      // @ts-ignore
      setFilteredSession([...data])
      setTriggerRefilter(prev => !prev)
      setTriggerResort(prev => !prev)
    }
  }, [sessionQueries])

  return (
    <MyPageLayout props="Kurskalender">
      <FilterBy
        filteredEvents={filteredEvents || []}
        setFilteredEvents={setFilteredEvents}
        events={events || []}
        setEvent={setEvents}
        refilterTrigger={triggerRefilter}
        setCategoriFilter={setCategoriFilter}
        // setPhaseFilter={setPhaseFilter}
        // setCityFilter={setCityFilter}
        session={session || []}
        filteredSession={filteredSession}
        setFilteredSession={setFilteredSession}
        setFilteredAllSessions={setFilteredAllSessions}
        filteredAllSessions={filteredAllSessions || []}
        allSessions={allSessions || []}
      />
      {categoriFilter === "alle" && (
        <SortBy
          events={filteredEvents || []}
          setEvents={setFilteredEvents}
          sortOn={sortOn}
          setSortOn={setSortOn}
          resortTrigger={triggerResort}
        />
      )}
      {eventQuery.isLoading && (
        <div className="flex flex-col items-center">
          <div className="max-w-3xl w-full">
            <ListLoading />
          </div>
        </div>
      )}
      {categoriFilter === "alle" && (
        <EventAccordionGroup
          sortOn={sortOn}
          events={[...(filteredEvents || [])]}
        />
      )}

      {categoriFilter !== "alle" && (
        <SessionAccordionGroup
          sortOn={sortOn}
          events={[...(filteredEvents || [])]}
          sessions={filteredSession || []}
        />
      )}

      {/* {phaseFilter !== "alle" && (
        // <SessionAccordionGroup
        <SessionAccordionGroupFiltered
          sortOn={sortOn}
          events={[...(filteredEvents || [])]}
          sessions={filteredSession || []}
        />
      )} */}
    </MyPageLayout>
  )
}

export default EventCalendar
