import { InteractionType } from "@azure/msal-browser"
import { MsalAuthenticationTemplate } from "@azure/msal-react"
import React from "react"
import { Loading } from "../../../../Utils/Loading"
import { ErrorComponent } from "../../../common/graphichs/ErrorComponent"
import MyPageLayout from "../../../common/layouts/myPageLayout"
import AddExpenseReportLine from "../../../mypagecomponents/expensereport/AddExpenseReportLine"
import { loginRequest } from "../../../security/authConfig"

const AddNewLinesContent = ({ entityId }) => {
  return (
    <MyPageLayout props="Legg til reiseregningslinjer">
      <AddExpenseReportLine entityId={entityId} />
    </MyPageLayout>
  )
}
const AddNewLines = ({ location }) => {
  const authRequest = {
    ...loginRequest,
  }
  const id = location.state.entityId
  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      <AddNewLinesContent entityId={id} />
    </MsalAuthenticationTemplate>
  )
}
export default AddNewLines
