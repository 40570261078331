import React from "react"

const TableHeader = (props: {
  children: React.ReactNode
  className?: string
}) => (
  <th
    className={
      "text-sm font-bold text-gray-700 sm:mt-px sm:pt-2  " + props.className
    }
  >
    {props.children}
  </th>
)

export default TableHeader
