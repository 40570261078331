import { useAccount, useMsal } from "@azure/msal-react"
import { PaperClipIcon } from "@heroicons/react/outline"
import React, { useEffect, useState } from "react"
import { useMutation, useQueryClient } from "react-query"
import { deleteApi, fetchDownLoadFileApi } from "../../../Utils/Api.utils"
import DocumentsSection from "../../mypage/event/MyEventRegistration/DocumentsSection"

const DocumentListMultipleActivities = ({
  documents,
  mutationGetDocuments,
  canDelete,
  documentsWithId,
  setShowLoadingData,
}) => {
  const queryClient = useQueryClient()
  const [showDeleteButton, setShowDeleteButton] = useState(canDelete)
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  let documentName = "FileName"

  const mutationDownload = useMutation(
    e => {
      documentName = e.title
      return fetchDownLoadFileApi(
        process.env.GATSBY_API_URL + "/Documents/Download/" + e.blobId,
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: blob => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]))
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", documentName)

        // Append to html link element page
        document.body.appendChild(link)

        // Start download
        link.click()

        // Clean up and remove the link
        link.parentNode.removeChild(link)
      },
      onError: _error => {},
      onSettled: () => {},
    }
  )

  const mutationDelete = useMutation(
    blobId => {
      return deleteApi(
        process.env.GATSBY_API_URL + "/Documents/Portal/Delete/" + blobId,
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: () => {
        mutationGetDocuments.mutate()
        setShowLoadingData(false)
      },
      onError: _error => {},
      onSettled: () => {},
    }
  )

  const onFileDownLoad = e => mutationDownload.mutate(e)

  const onFileDelete = clickedDocument => {
    // Find the document with the clicked blobId
    const document = documentsWithId.find(
      doc => doc.blobId === clickedDocument.blobId
    )

    if (document) {
      // Get the count value of the document
      const countValue = document.count
      const titleValue = document.title

      // Filter documentsWithId to get all objects with the same count value
      const documentsToDelete = documentsWithId.filter(
        doc => doc.count === countValue && doc.title === titleValue
      )

      // Run mutationDelete on each of these objects
      documentsToDelete.forEach(doc => mutationDelete.mutate(doc.blobId))
      setShowLoadingData(true)
    }
  }

  return (
    <>
      {documents.length > 0 ? (
        <ul
          role="list"
          className="border border-gray-200 rounded-md divide-y divide-gray-200"
        >
          {documents.map(document => (
            <li
              key={document.blobId}
              className="pl-3 pr-4 py-3 flex items-center justify-between text-sm"
            >
              <div className="w-0 flex-1 flex items-center">
                <PaperClipIcon
                  className="flex-shrink-0 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                <span className="ml-2 flex-1 w-0 truncate">
                  {document.title}
                </span>
              </div>
              <div className="ml-4 flex-shrink-0 flex space-x-4">
                <button
                  type="button"
                  id={document.blobId}
                  name={document.title}
                  onClick={e => onFileDownLoad(document)}
                  className={
                    "rounded-md font-medium text-meny-600 hover:text-meny-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700 "
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10"
                    />
                  </svg>
                </button>
                {showDeleteButton ? (
                  <div>
                    <span className="text-gray-300" aria-hidden="true">
                      |
                    </span>
                    <button
                      type="button"
                      name={document.blobId}
                      onClick={e => onFileDelete(document)}
                      className={
                        "rounded-md font-medium text-meny-600 hover:text-meny-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700 "
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M9 13h6m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                        />
                      </svg>
                    </button>
                  </div>
                ) : null}
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <div></div>
      )}
    </>
  )
}

export default DocumentListMultipleActivities
