import React from "react"
import { Disclosure } from "@headlessui/react"
import { ChevronDownIcon } from "@heroicons/react/outline"
import { AccordionType } from "./AccordionType"
import { classNames } from "../graphichs/misc"

const Accordion = (props: AccordionType) => {
  return (
    <div className="bg-white shadow px-5 sm:px-6">
      <Disclosure as="div">
        {({ open }) => (
          <>
            <div className="text-lg">
              <Disclosure.Button className="pt-6 pb-6 text-left w-full flex justify-between items-start text-gray-400">
                <div className="justify-start space-x-4 flex flex-col md:flex-row">
                  <h3 className="text-black">{props.title}</h3>
                  <span className="text-base font-normal">
                    {props.subTitle}
                  </span>
                </div>
                <span className="ml-6 h-7 flex items-center">
                  {props.tag && (
                    <>
                      <div className="bg-meny-700 mx-4 text-white text-base px-4 rounded-xl truncate">
                        {props.tag}
                      </div>
                    </>
                  )}

                  <ChevronDownIcon
                    className={classNames(
                      open ? "-rotate-180" : "rotate-0",
                      "h-6 w-6 transform"
                    )}
                    aria-hidden="true"
                  />
                </span>
              </Disclosure.Button>
            </div>
            <Disclosure.Panel as="div">
              <div className="w-full h-px bg-gray-200 mb-4"></div>
              {props.children}
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  )
}

export default Accordion
