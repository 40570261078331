import React, { useEffect } from 'react'
import HeaderText from '../../common/text/Header';

const SOURCE = process.env.GATSBY_MARKETING_SOURCE
const WEBSITE_ID = process.env.GATSBY_MARKETING_WEBSITE_ID
const HOSTNAME = process.env.GATSBY_MARKETING_HOSTNAME

const MarketingForm = () => {
    useEffect(loadDynamics, []);
    function loadDynamics() {
        const script = document.createElement('script');
        script.src = SOURCE;
        script.async = true;
        document.body.insertBefore(script, document.body.childNodes[0]);

        return () => {
            document.body.removeChild(script);
            // @ts-ignore
            window.MsCrmMkt = undefined;
            const iframe = document.querySelector('iframe[src*="dynamics.com"]');
            if (iframe) iframe.remove();
        };
    }

    return (
        <div className="max-w-3xl mx-auto divide-y-2 divide-gray-200 mb-6">
            {/* <HeaderText text="Nyhetsbrev" /> */}
            <div
                className="d365-mkt-config"
                style={{ display: 'none' }}
                data-website-id={WEBSITE_ID}
                data-hostname={HOSTNAME}
                data-no-submit="true"
            />
        </div>
    )
}

export default MarketingForm