import { Link } from "gatsby"
import React, { useContext, useEffect } from "react"
import { InnmeldingContext } from "./dataStorage"

const Persona = () => {
  const InnmeldingData = useContext(InnmeldingContext)
  if (!InnmeldingData) return <div></div>

  return (
    <div>
      <h2>Kontaktinfo</h2>
      <div className="w-full h-px bg-gray-200 mb-4"></div>
      <div className="divide-y divide-gray-200 bg-white rounded-lg px-4">
        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt className="text-sm font-medium text-gray-600">Navn</dt>
          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            {InnmeldingData.contact[0]?.firstName}{" "}
            {InnmeldingData.contact[0]?.lastName}
          </dd>
        </div>
        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt className="text-sm font-medium text-gray-600">Mobil</dt>
          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            {InnmeldingData.contact[0]?.mobilePhone}
          </dd>
        </div>
        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt className="text-sm font-medium text-gray-600">Email</dt>
          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            {InnmeldingData.contact[0]?.emailAddress1}
          </dd>
        </div>
        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt className="text-sm font-medium text-gray-600">Arbeidsgiver</dt>
          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            {InnmeldingData.workplace?.name}
          </dd>
        </div>
        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt className="text-sm font-medium text-gray-600">HPR nummer</dt>
          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            {InnmeldingData.contact[0]?.hprNumber !== undefined &&
              InnmeldingData.contact[0]?.hprNumber.length > 0 ? (
              <p>{InnmeldingData.contact[0]?.hprNumber}</p>
            ) : (
              <p className="text-red-600">
                Oppgi HPR number på din profil for og gå videre
              </p>
            )}
          </dd>
        </div>
      </div>
      <div className="flex flex-row py-6 justify-center">
        <Link
          to={
            "/app/profil/endrebruker?redirectTo=/app/kurs/innmelding?id=" +
            InnmeldingData.eventId
          }
          state={{ origin: "Kurs" }}
          className="ml-1 inline-flex justify-end py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-md text-white bg-meny-700 hover:bg-meny-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
        >
          Rediger kontaktinfo
        </Link>
      </div>
    </div>
  )
}

export default Persona
