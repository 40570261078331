import { Link } from "gatsby"
import React from "react"
import { DateTimeToDateTime } from "../../../Utils/DateFormater"
import { EventType, SessionType } from "./EventType"
import {
  CityOptionsetValueToString,
  EventRegistrationTypeEnum,
} from "./helpers/Event"
import SessionAccoridon from "./SessionAccordion"

const SessionAccordionGroup = (props: {
  sortOn: string
  events: Array<EventType>
  sessions: Array<{ readableEventId: string; session: Array<SessionType> }>
}) => {
  const events = props.events
    .sort(
      (a, b) =>
        new Date(a.startDate || "").getTime() -
        new Date(b.startDate || "").getTime()
    )
    .filter(item => {
      if (
        item.eventTypeObject?.registrationType ===
        EventRegistrationTypeEnum.noSessions
      ) {
        return true
      }

      const sessions = props.sessions.find(
        session => session.readableEventId === item.readableEventId
      )?.session

      return sessions && sessions.length > 0
    })

  return (
    <div>
      {events.map(eventItem => {
        const currentSessions = props.sessions.find(
          item => item.readableEventId === eventItem.readableEventId
        )

        return (
          <div key={eventItem.eventId}>
            <div className="flex flex-col items-center">
              <div className="max-w-3xl w-full">
                <Link
                  to={"/app/kurs?id=" + eventItem.readableEventId}
                  className="mt-8 text-2xl font-bold text-meny-700 hover:underline"
                >
                  {eventItem.eventName}
                </Link>
                {eventItem.eventTypeObject?.registrationType !==
                  EventRegistrationTypeEnum.optional && (
                  <p>
                    <span className="mr-4">
                      Påmeldingsfrist:{" "}
                      {DateTimeToDateTime(
                        eventItem.customFields?.stopWebsiteRegistrationson
                      )}
                    </span>
                    {eventItem.eventTypeObject?.registrationType ===
                    EventRegistrationTypeEnum.noSessions ? (
                      <span className="mr-4">
                        Startdato: {DateTimeToDateTime(eventItem.startDate)}
                      </span>
                    ) : (
                      <span className="mr-4">
                        Avmeldingsfrist:{" "}
                        {DateTimeToDateTime(
                          eventItem.customFields?.stopWebsiteCancellationon
                        )}
                      </span>
                    )}
                    <span className="mr-4">
                      By:{" "}
                      {CityOptionsetValueToString(
                        eventItem.customFields?.city?.value
                      )}
                    </span>
                  </p>
                )}
                <div className="w-full max-w-3xl h-px bg-gray-200 mb-4"></div>
              </div>
            </div>
            <div className="max-w-3xl mx-auto divide-y divide-gray-200 rounded-lg overflow-hidden mb-2">
              {currentSessions?.session &&
                currentSessions?.session?.length > 0 &&
                currentSessions?.session.map(item => {
                  return <SessionAccoridon key={item.id} session={item} />
                })}
            </div>
            <div className="max-w-3xl mx-auto mb-6">
              <Link
                to={"/app/kurs?id=" + eventItem.readableEventId}
                // state={{ workId: work?.id }}
                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700 sm:mt-0 sm:w-auto"
              >
                Les mer
              </Link>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default SessionAccordionGroup
