import { useAccount, useMsal } from "@azure/msal-react"
import React, { useEffect, useRef, useState } from "react"
import { useForm } from "react-hook-form"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { fetchApi, postApi } from "../../../../Utils/Api.utils"
import ReactDatePicker from "react-datepicker"
import FileUpload from "../../document/FileUpload"
import { navigate } from "gatsby"
import { ErrorMessage } from "@hookform/error-message"
import AddPracticeHours from "../AddPracticeHours"
import { toast } from "react-toastify"
import HelperText from "../../../common/text/HelperText"
import NotificationDialog from "../../../common/graphichs/NotificationDialog"
import ConfirmationDialog from "../../../common/graphichs/ConfirmationDialog"
import { cyanButton } from "../../../../styles/tailwindClasses"
import { Activity, Incidents, SpecialistProgram } from "../../../../Utils/Types"
import { ApproveStatusEnum, StateEnum } from "../../../../Utils/Enums"
import Modal from "../../../common/modal/Modal"

const PracticeCertificate = ({
  program,
  setSuccess,
  setDisableActivityDeselect,
  // mutationCreateIncident,
  setListOfIds,
  setShowDocumentComponent,
  // mutationCreatePracticeHours,
  // setComment,
  // setIncidentTitle,
  setShowLoadingData,
  // showActivityButtons,
}) => {
  const queryClient = useQueryClient()
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const [activityComment, setActivityComment] = useState("")
  const [startDate, setStartDate] = useState(null)
  const [practiceTypes, setPracticeTypes] = useState<any[]>()
  const [selectePracticeType, SetSelectedPracticeType] = useState("0")
  const [practiceHours, setPracticeHours] = useState([])
  const [programIds, setProgramIds] = useState([])
  const [readyToPost, setReadyToPost] = useState<boolean>(false)
  // const [programIds, setProgramIds] = useState([])

  const [practiceHoursStartedEditing, setPracticeHoursStartedEditing] =
    useState(false)
  const [earliestDate, setEarliestDate] = useState(null)
  const [latestDate, setLatestDate] = useState(null)
  const [sumPractice, setSumPractice] = useState(0)
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [onConfirmationOk, setOnConfirmationOk] = useState(false)
  const [programs, setPrograms] = useState<SpecialistProgram[]>()
  const [showModal, setShowModal] = useState(false)
  const buttonRef = useRef<HTMLButtonElement>()

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm()

  const handleModalButtonClick = () => {
    if (buttonRef.current) {
      buttonRef.current.click()
    }
  }

  // const [practiceHours1, setPracticeHours1] = useState([])
  // const [practiceHours2, setPracticeHours2] = useState([])
  // const [practiceHours3, setPracticeHours3] = useState([])
  let practiceHours1 = []
  let practiceHours2 = []
  let practiceHours3 = []

  // console.log("PracticeHours", practiceHours)
  // console.log("PracticeHour1", practiceHours1)
  // console.log("PracticeHour2", practiceHours2)
  // console.log("PracticeHour3", practiceHours3)

  let obligSum = practiceHours.reduce(
    (total, obj) =>
      obj.programName === "Obligatorisk program" ? total + obj.practice : total,
    0
  )

  let obligSumPracsis = programs?.find(
    obj => obj?.programType?.id === "ee9bb462-c5a6-ec11-983f-000d3aae55a4"
  )?.sumPracsis

  let obligRequirement = programs?.find(
    obj => obj?.programType?.id === "ee9bb462-c5a6-ec11-983f-000d3aae55a4"
  )?.requirementsSpecialistEducation?.pracsis

  let fellesSum = practiceHours.reduce(
    (total, obj) =>
      obj.programName === "Fellesprogram" ? total + obj.practice : total,
    0
  )

  let fellesSumPracsis = programs?.find(
    obj => obj?.programType?.id === "7a958356-c5a6-ec11-983f-000d3aae55a4"
  )?.sumPracsis

  let fellesRequirement = programs?.find(
    obj => obj?.programType?.id === "7a958356-c5a6-ec11-983f-000d3aae55a4"
  )?.requirementsSpecialistEducation?.pracsis

  let valgfrittSum = practiceHours.reduce(
    (total, obj) =>
      obj.programName === "Valgfritt program" ? total + obj.practice : total,
    0
  )

  let valgfrittSumPracsis = programs?.find(
    obj => obj?.programType?.id === "e542c068-c5a6-ec11-983f-000d3aae55a4"
  )?.sumPracsis

  let valgfrittRequirement = programs?.find(
    obj => obj?.programType?.id === "e542c068-c5a6-ec11-983f-000d3aae55a4"
  )?.requirementsSpecialistEducation?.pracsis

  const myPrograms = useQuery(
    "myPrograms",
    () =>
      fetchApi(
        `${process.env.GATSBY_API_URL}/SpecialistPrograms/me/${program.specialistEducation?.id}`,
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: data => {
        setPrograms(data)
      },
      onError: _error => {},
    }
  )

  useQuery(
    "specialitiesDataActive",
    () =>
      fetchApi(
        process.env.GATSBY_API_URL + "/Specialities/all/active",
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      enabled: !!program?.specialityId,
      onSuccess: data => {
        if (data.length > 0 && program?.specialityId !== undefined) {
          const filteredData = data.filter(x => x.id == program?.specialityId)
          if (filteredData[0].practiceTypeList?.length > 0) {
            const practiceTypeList = filteredData[0].practiceTypeList.sort(
              (a, b) => a.name.localeCompare(b.name)
            )
            setPracticeTypes(practiceTypeList)
          }
        }
      },
      onError: _error => {},
    }
  )

  useQuery(
    "practiceTypesData",
    () =>
      fetchApi(
        process.env.GATSBY_API_URL + "/PracticeTypes/all",
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: data => {
        if (program?.specialityId == undefined) {
          const practiceTypeList = data.sort((a, b) =>
            a.name.localeCompare(b.name)
          )
          setPracticeTypes(practiceTypeList)
        }
      },
      onError: _error => {},
    }
  )

  const CalculateSumPractice = value => {
    const sum = sumPractice + value
    setSumPractice(parseFloat(sum))
  }

  const onConfirmation = e => {
    if (e) {
      setOnConfirmationOk(true)
    }
  }

  const prePostActivity = async (data, e) => {
    if (practiceHoursStartedEditing && !onConfirmationOk) {
      setShowConfirmation(true)
      return
    }

    if (
      (obligSum + obligSumPracsis > obligRequirement ||
        fellesSum + fellesSumPracsis > fellesRequirement ||
        valgfrittSum + valgfrittSumPracsis > valgfrittRequirement) &&
      showModal === false
    ) {
      setShowModal(true)
      return
    } else {
      postActivity(data)
    }
  }

  type progActInc = {
    programId: string
    activityId: string
    incidentId: string
  }

  const mutationCreateActivity = useMutation(
    data => {
      return postApi(
        process.env.GATSBY_API_URL + "/SpecializationActivities/practiceCert",
        data,
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: data => {
        // createIncidents(data)
        const listOfIds: progActInc = data
        setListOfIds(data)
        console.log("id list: ", data)
        setDisableActivityDeselect(true)
        setShowDocumentComponent(true)
        setSuccess(true)
        // props.setFirstStep("complete")
        // props.setSecondStep("current")

        queryClient.invalidateQueries([
          "programSpecializationActivitiesData-" + program?.id,
        ])
        queryClient.invalidateQueries([
          "educationSpecializationActivitiesData-" +
            program.specialityEducation?.id,
        ])

        setShowLoadingData(false)
      },
      onError: _error => {
        setShowLoadingData(false)
      },
      onSettled: () => {},
    }
  )

  useEffect(() => {
    if (programs !== undefined) {
      practiceHours.forEach(ph => {
        if (ph?.programId == programs[0].id) {
          practiceHours1.push(ph)
        } else if (programs.length > 1 && ph?.programId == programs[1].id) {
          practiceHours2.push(ph)
        } else if (programs.length > 2 && ph?.programId == programs[2].id) {
          practiceHours3.push(ph)
        }
      })
    }
  }, [practiceHours, practiceHours1, practiceHours2, practiceHours3])

  useEffect(() => {}, [practiceHours1, practiceHours2, practiceHours3])

  const postActivity = () => {
    let activityData: Activity = {}
    activityData.activityType = 292460001
    activityData.approveStatus = 292460000 //Ikke fullført registrering
    activityData.comment = activityComment

    activityData.educationId = program.specialistEducation?.id
    // if (earliestDate != null) activityData.fromDate = earliestDate
    // if (latestDate != null) activityData.toDate = latestDate

    if (selectePracticeType != "0")
      activityData.practiceTypeId = selectePracticeType

    if (selectePracticeType == "0") {
      toast.warning("Vennligst velg praksistype!", {
        position: "top-center",
        autoClose: 10000,
      })
      return
    }

    if (practiceHours.length == 0) {
      toast.warning("Vennligst legg til praksisdetaljer!", {
        position: "top-center",
        autoClose: 10000,
      })
      return
    }

    if (practiceHours1.length > 0) {
      let startDate1
      let endDate1
      let prevStart1 = null
      let prevEnd1 = null
      activityData.practiceHours1 = practiceHours1
      practiceHours1.forEach(ph => {
        if (prevStart1 !== null) {
          if (new Date(ph?.fromDate) < new Date(prevStart1)) {
            startDate1 = ph?.fromDate
          }
          if (new Date(ph?.toDate) > new Date(prevEnd1)) {
            endDate1 = ph?.toDate
          }
        } else {
          startDate1 = ph?.fromDate
          endDate1 = ph?.toDate
        }
        prevStart1 = ph?.fromDate
        prevEnd1 = ph?.toDate
      })
      activityData.startDate1 = new Date(startDate1)
      activityData.endDate1 = new Date(endDate1)
    }
    if (practiceHours2.length > 0) {
      let startDate2
      let endDate2
      let prevStart2 = null
      let prevEnd2 = null
      activityData.practiceHours2 = practiceHours2
      practiceHours2.forEach(ph => {
        if (prevStart2 !== null) {
          if (new Date(ph?.fromDate) < new Date(prevStart2)) {
            startDate2 = ph?.fromDate
          }
          if (new Date(ph?.toDate) > new Date(prevEnd2)) {
            endDate2 = ph?.toDate
          }
        } else {
          startDate2 = ph?.fromDate
          endDate2 = ph?.toDate
        }
        prevStart2 = ph?.fromDate
        prevEnd2 = ph?.toDate
      })
      activityData.startDate2 = new Date(startDate2)
      activityData.endDate2 = new Date(endDate2)
    }
    if (practiceHours3.length > 0) {
      activityData.practiceHours3 = practiceHours3
      let startDate3
      let endDate3
      let prevStart3 = null
      let prevEnd3 = null
      practiceHours3.forEach(ph => {
        if (prevStart3 !== null) {
          if (new Date(ph?.fromDate) < new Date(prevStart3)) {
            startDate3 = ph?.fromDate
          }
          if (new Date(ph?.toDate) > new Date(prevEnd3)) {
            endDate3 = ph?.toDate
          }
        } else {
          startDate3 = ph?.fromDate
          endDate3 = ph?.toDate
        }
        prevStart3 = ph?.fromDate
        prevEnd3 = ph?.toDate
      })
      activityData.startDate3 = new Date(startDate3)
      activityData.endDate3 = new Date(endDate3)
    }
    setShowLoadingData(true)
    // console.log("aktivitet: ", activityData)
    mutationCreateActivity.mutate(activityData)
  }

  return (
    <div>
      <div>
        {showConfirmation && (
          <ConfirmationDialog
            onConfirmation={onConfirmation}
            setShowConfirmation={setShowConfirmation}
            subject="Obs!"
            description="Du har en påbegynt en registrering av praksisdetaljer som ikke er lagret. Klikk Bekreft for å sende inn eller avbryt for å fullføre registrering av praksisdetaljer."
            canCondfirm={true}
            showDesc={true}
          />
        )}
        {showModal && (
          <Modal setModalOpen={setShowModal}>
            <div>
              <p className=" mb-2 ">
                Du registrerer nå praksis som går ut over kravet til dette
                programmet. Dersom noe av denne praksis skal dokumenteres til
                andre program må du gå tilbake og fordele praksisukene før du
                leverer. Dette gjelder:
                <br />
              </p>
              {obligSum + obligSumPracsis > obligRequirement ? (
                <span className=" font-bold ">
                  Obligatorisk program
                  <br />
                </span>
              ) : null}
              {fellesSum + fellesSumPracsis > fellesRequirement ? (
                <span className=" font-bold ">
                  Fellesprogram
                  <br />
                </span>
              ) : null}
              {valgfrittSum + valgfrittSumPracsis > valgfrittRequirement ? (
                <span className=" font-bold ">
                  Valgfritt program
                  <br />
                </span>
              ) : null}
            </div>
            <div className="w-full flex flex-row justify-around mt-8">
              <button
                onClick={() => {
                  setShowModal(false)
                }}
                className="w-fit ml-1 inline-flex justify-end py-2 px-8 border border-gray-200 shadow-sm text-md font-medium rounded-md text-black bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
              >
                Fordel praksisuker
              </button>
              <button
                onClick={handleModalButtonClick}
                className="w-fit ml-1 inline-flex justify-end py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-md text-white bg-meny-700 hover:bg-meny-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
              >
                Gå videre
              </button>
            </div>
          </Modal>
        )}
      </div>
      <form method="POST" onSubmit={handleSubmit(prePostActivity)}>
        <div className="grid grid-cols-6 gap-6">
          <div className="col-span-6 sm:col-span-3">
            <label
              htmlFor="courseProviderAccountId"
              className="block text-sm font-medium text-gray-700"
            >
              Praksistype*
            </label>
            <select
              id="courseProviderAccountId"
              name="courseProviderAccountId"
              onChange={e => SetSelectedPracticeType(e.target.value)}
              value={selectePracticeType}
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-meny-700 focus:border-meny-500 sm:text-sm"
            >
              <option disabled selected value="0">
                --velg--
              </option>

              {practiceTypes?.map(a => (
                <option key={a.id} value={a.id}>
                  {a.name}
                </option>
              ))}
            </select>
          </div>

          <div className="col-span-6 sm:col-span-3">
            <label
              htmlFor="sumPractice"
              className="block text-sm font-medium text-gray-700"
            >
              Sum praksis
            </label>
            <input
              // {...register("sumPractice")}
              readOnly
              type="text"
              value={sumPractice}
              name="sumPractice"
              id="sumPractice"
              className="mt-1 bg-gray-300 focus:ring-meny-700 focus:border-meny-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            />
          </div>

          <div className="col-span-6">
            <label
              htmlFor="comment"
              className="block text-sm font-medium text-gray-700"
            >
              Kommentar
            </label>
            <div className="mt-1">
              <textarea
                id="comment"
                {...register("comment", {
                  required: false,
                  maxLength: {
                    value: 2000,
                    message: "Maks antall tegn er 2000",
                  },
                })}
                onChange={e => {
                  // setComment(e.target.value)
                  setActivityComment(e.target.value)
                }}
                rows={3}
                className="shadow-sm focus:ring-meny-700 focus:border-meny-500 block w-full sm:text-sm border border-gray-300 rounded-md"
                placeholder="Kommentar"
                defaultValue={""}
              />
              <ErrorMessage
                errors={errors}
                name="comment"
                render={({ message }) => (
                  <p className="text-sm text-red-600">{message}</p>
                )}
              />
            </div>

            <HelperText helpertext="Skriv inn type praksis dersom du ikke finner den i listen over praksistyper." />
          </div>

          <div className="col-span-6 mt-8 border-t  justify-end">
            <AddPracticeHours
              practiceHours={practiceHours}
              setPracticeHours={setPracticeHours}
              earliestDate={earliestDate}
              latestDate={latestDate}
              setEarliestDate={setEarliestDate}
              setLatestDate={setLatestDate}
              CalculateSumPractice={CalculateSumPractice}
              sumPractice={sumPractice}
              setSumPractice={setSumPractice}
              setPracticeHoursStartedEditing={setPracticeHoursStartedEditing}
              program={program}
              allMyPrograms={programs}
            />
          </div>
        </div>

        {/* {showActivityButtons ? ( */}
        <div className="mt-8 border-t flex justify-end">
          <button
            ref={buttonRef}
            type="submit"
            className="ml-3 mt-6 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-meny-600 hover:bg-meny-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
          >
            Last opp dokumenter
          </button>
        </div>
        {/* ) : null} */}
      </form>
    </div>
  )
}

export default PracticeCertificate
