import React, { useEffect, useState } from "react"
import {
  PlusSmIcon as PlusSmIconOutline,
  TrashIcon,
} from "@heroicons/react/outline"
import { Controller, useForm } from "react-hook-form"
import { cyanButton, whiteButton } from "../../../styles/tailwindClasses"
import {
  ExpenseReport,
  ExpenseReportLine,
  MileageRate,
} from "../../../Utils/Entities"
import { loginRequest } from "../../security/authConfig"
import {
  MsalAuthenticationTemplate,
  useAccount,
  useMsal,
} from "@azure/msal-react"
import { InteractionType } from "@azure/msal-browser"
import { ErrorComponent } from "../../common/graphichs/ErrorComponent"
import { Loading } from "../../../Utils/Loading"
import HelperText from "../../common/text/HelperText"
import { ErrorMessage } from "@hookform/error-message"
import { navigate } from "gatsby"
import { ExpenseTypeEnum, StatusExpenseEnum } from "../../../Utils/Enums"
import { useMutation, useQuery, useQueryClient } from "react-query"
import {
  fetchApi,
  patchApi,
  postApi,
  postGetDocumentApi,
} from "../../../Utils/Api.utils"
import ReactDatePicker from "react-datepicker"
import FileUpload from "../document/FileUpload"
import { toast } from "react-toastify"
import MyReportLines from "./MyReportLines"
import LoadingData from "../../common/graphichs/LoadingData"
import ConfirmationDialog from "../../common/graphichs/ConfirmationDialog"
import ExpenseReportDetails from "./ExpenseReportDetails"
import { classNames } from "../../common/graphichs/misc"
import { Navigate } from "react-router-dom"

const AddExpenseReportLine = ({ entityId }) => {
  const {
    register,
    handleSubmit,
    reset,
    control,
    setError,
    formState: { errors },
  } = useForm()
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const queryClient = useQueryClient()

  const [reportLines, setReportLines] = useState<ExpenseReportLine[]>([])
  const [expenseType, setExpenseType] = useState<string>("")
  const [mileageRates, setMileageRates] = useState<MileageRate[]>([])
  const [rateId, setRate] = useState<string>("")
  const [ratePrice, setRatePrice] = useState<string>("")
  const [ratePriceSum, setRatePriceSum] = useState<number>(0)
  const [otherSum, setOtherSum] = useState<number>(0)
  const [mileageAmount, setMileageAmount] = useState<string>("")
  const [unitPrice, setUnitPrice] = useState<string>("")
  const [sum, setSum] = useState<number>(0)
  const [invalidSum, setInvalidSum] = useState<boolean>(false)
  const [needReceipt, setNeedReceipt] = useState<boolean>(true)
  const [expenseLineId, setExpenseLineId] = useState<string>()
  const [documents, setDocuments] = useState([])
  const [showLoadingData, setShowLoadingData] = useState<boolean>(false)
  const [name, setName] = useState<string>("")
  const [date, setDate] = useState<Date>()
  const [lineStartedEditing, setLineStartedEditing] = useState<boolean>(false)
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false)
  const [onConfirmationOk, setOnConfirmationOk] = useState<boolean>(false)
  const [fileIsInvalid, setFileIsInvalid] = useState<boolean>(false)
  const [reportLineLength, setReportLineLength] = useState<number>(0)

  // const [totalSum, setTotalSum] = useState<number>(0)

  const [report, setReport] = useState<ExpenseReport>()

  const reportQuery = useQuery(
    "getReport",
    () =>
      fetchApi(
        process.env.GATSBY_API_URL + "/ExpenseReports/" + entityId,
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      // enabled: reportId !== undefined,
      onSuccess: data => {
        setReport(data)

        // setIsLoading(false)
      },
      onError: _error => {
        // setIsLoading(false)
      },
    }
  )

  const mileageRateQuery = useQuery(
    "mileageRateQuery",
    () =>
      fetchApi(
        process.env.GATSBY_API_URL + "/MileageRate/all",
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: data => {
        setMileageRates(data)
      },
      onError: _error => {},
    }
  )

  const OnSetDate = date => {
    setLineStartedEditing(true)
    setDate(date)
  }

  const mutationGetDocuments = useMutation(
    () => {
      return postGetDocumentApi(
        process.env.GATSBY_API_URL + "/Documents/Regarding/Get",
        {
          ExpenseReportLineId: expenseLineId,
        },
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: data => {
        setDocuments(data)
      },
      onError: _error => {},
      onSettled: () => {},
    }
  )

  const type = [
    { id: "292460000", name: "Flyreise" },
    { id: "292460001", name: "Kilometergodtgjørelse" },
    { id: "292460002", name: "Andre transportmidler" },
    { id: "292460003", name: "Andre utlegg" },
  ]

  const changeType = event => {
    setExpenseType(event.target.value)
    if (event.target.value === "292460001") {
      setNeedReceipt(false)
      setUnitPrice("0")
      setOtherSum(0)

      if (rateId !== "") {
        var index = mileageRates.findIndex(x => x.id === rateId)
        if (index !== -1) {
          setRatePrice(mileageRates[index].rate.toString())
        }
        setRatePriceSum(0)
      } else {
        setRatePriceSum(0)
      }
    } else {
      setNeedReceipt(true)
      setMileageAmount("0")
      setRatePriceSum(0)
    }
  }

  const changeRate = event => {
    setRate(event.target.value)

    var index = mileageRates.findIndex(x => x.id === event.target.value)
    if (index !== -1) {
      setRatePrice(mileageRates[index].rate.toString())
      if (parseFloat(mileageAmount) > 0)
        setRatePriceSum(mileageRates[index].rate * parseFloat(mileageAmount))
    }
  }

  const updateSumMileage = e => {
    setLineStartedEditing(true)
    if (e.target.value.length > 0) {
      // if (e.target.value !== undefined || e.target.value > 0) {
      if (parseFloat(ratePrice) > 0)
        setRatePriceSum(parseFloat(e.target.value) * parseFloat(ratePrice))
    } else setRatePriceSum(0)

    setMileageAmount(e.target.value)
  }

  const updateOtherSum = e => {
    setLineStartedEditing(true)
    if (e.target.value.length > 0) {
      // if (e.target.value !== undefined || e.target.value > 0) {

      setOtherSum(parseFloat(e.target.value))
    } else setOtherSum(0)

    setUnitPrice(e.target.value)
  }

  const mutationUploadDocumets = useMutation(
    formData => {
      return postApi(
        process.env.GATSBY_API_URL + "/Documents",
        formData,
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: () => {
        setShowLoadingData(false)
      },
      onError: _error => {
        setShowLoadingData(false)
      },
      onSettled: () => {},
    }
  )

  const mutationCreateLineWithFile = useMutation(
    data => {
      return postApi(
        process.env.GATSBY_API_URL + "/ExpenseReportLines",

        data,
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: data => {
        setExpenseLineId(data)
        if (documents.length > 0) {
          const formData = new FormData()
          Array.from(documents).forEach(file =>
            formData.append("arrayOfFiles", file)
          )

          formData.append("EntityId", data)
          formData.append("EntityName", "pp_expensereportline")
          formData.append("EntityId2", report?.id)
          formData.append("EntityName2", "pp_expensereport")

          mutationUploadDocumets.mutate(formData)
        }
        queryClient.invalidateQueries(["expenserepLines"])
        queryClient.invalidateQueries(["getReport"])
      },
      onError: _error => {},
      onSettled: () => {},
    }
  )
  const mutationCreateLine = useMutation(
    data => {
      return postApi(
        process.env.GATSBY_API_URL + "/ExpenseReportLines",

        data,
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: data => {
        queryClient.invalidateQueries(["expenserepLines"])
        queryClient.invalidateQueries(["getReport"])
        setExpenseLineId(data)
        setShowLoadingData(false)
      },
      onError: _error => {
        setShowLoadingData(false)
      },
      onSettled: () => {},
    }
  )

  const onConfirmation = e => {
    if (e) {
      setOnConfirmationOk(true)
    }
  }

  const postRepLine = data => {
    // setTotalSum(tot => tot + sum)
    if (fileIsInvalid) {
      toast.warning("Kvitteringen må være pdf, jpg eller png-format!", {
        position: "top-center",
      })
      return
    }
    if (expenseType === "292460001") {
      if (rateId === "") {
        toast.warning("Du må velge kjøretilleggssats!", {
          position: "top-center",
        })
        return
      }
      data.mileageRate = parseFloat(ratePrice)
      data.mileageAmount = parseFloat(mileageAmount)
    } else {
      data.mileageAmount = 1
      data.mileageRate = parseFloat(unitPrice)
    }
    data.expenseType = parseInt(expenseType)
    console.log(data)
    data.expenseReportId = entityId
    // data.sumAmount = ratePriceSum
    data.projectId = report?.projectId
    // if (mileageAmount == 0) data.mileageAmount = null

    // if (sum == 0) data.sumAmount = null

    if (needReceipt) {
      if (documents.length == 0) {
        toast.warning("Vennligst last opp kvittering!", {
          position: "top-center",
          autoClose: 10000,
        })
        return
      }
      setShowLoadingData(true)
      mutationCreateLineWithFile.mutate(data)
    } else {
      setShowLoadingData(true)
      mutationCreateLine.mutate(data)
    }

    //reset form
    setLineStartedEditing(false)
    // setName("")
    // setDate(null)
    setExpenseType("")
    setUnitPrice("0")
    setOtherSum(0)
    // setSum(0)
    setRatePrice("")
    setMileageAmount("")
    setRatePriceSum(0)
    setRate("")
    reset()
  }

  const mutationUpdateExpense = useMutation(
    () => {
      return patchApi(
        process.env.GATSBY_API_URL + "/ExpenseReports/" + entityId,
        {
          id: entityId,
          status: StatusExpenseEnum.Levert,
        },
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: data => {
        navigate("/app/dialog/mineutlegg")
      },
      onError: _error => {
        setShowLoadingData(false)
        console.log(_error)
      },
    }
  )

  const finished = () => {
    if (lineStartedEditing && !onConfirmationOk) {
      setShowConfirmation(true)
      return
    }
    if (report?.status !== StatusExpenseEnum.Levert) {
      setShowLoadingData(true)
      mutationUpdateExpense.mutate()
    } else {
      navigate("/app/dialog/mineutlegg")
    }
  }
  return showLoadingData ? (
    <LoadingData subject="Oppretter" />
  ) : (
    <div>
      <div className="sm:flex-auto mt-2"></div>
      <div>
        <ExpenseReportDetails report={report} />
        <MyReportLines
          reportId={entityId}
          canEdit={true}
          length={setReportLineLength}
        />

        <div className="mt-8  justify-end">
          <div>
            {showConfirmation && (
              <ConfirmationDialog
                onConfirmation={onConfirmation}
                setShowConfirmation={setShowConfirmation}
                subject="Obs!"
                description="Du har startet utfylling av en reiseregningslinje som ikke er lagret. Klikk Bekreft for å gå tilbake til mine utlegg eller avbryt for å fullføre registreringen."
                canCondfirm={true}
                showDesc={true}
              />
            )}
          </div>

          <form method="POST" onSubmit={handleSubmit(postRepLine)}>
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-700"
                >
                  Beskrivelse*
                </label>
                <input
                  {...register("name", {
                    required: { value: true, message: "Obligatorisk felt." },
                  })}
                  type="text"
                  name="name"
                  id="name"
                  onChange={e => {
                    setLineStartedEditing(true)
                    // setName(e.target.value)
                  }}
                  // value={name}
                  className="mt-1 focus:ring-meny-700 focus:border-meny-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
                <ErrorMessage
                  errors={errors}
                  name="name"
                  render={({ message }) => (
                    <p className="text-sm text-red-600">{message}</p>
                  )}
                />
              </div>

              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="date"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Dato*
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <Controller
                    control={control}
                    name="date"
                    // defaultValue={new Date()}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <ReactDatePicker
                        id="date"
                        locale="nb"
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={value}
                        required={true}
                        dateFormat="dd/MM/yyyy"
                        className="block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                      />
                    )}
                  />
                </div>
              </div>

              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="expenseType"
                  className="block text-sm font-medium text-gray-700"
                >
                  Type utlegg
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <select
                    {...register("expenseType")}
                    name="expenseType"
                    id="expenseType"
                    key="expenseType"
                    //   disabled={disableP}
                    //   value={selectedWrokLocation}
                    // onChange={isNorwegian}
                    onChange={changeType}
                    value={expenseType}
                    //   onChange={updateEmployer}
                    className=" block w-full shadow-sm focus:ring-meny-500 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                  >
                    <option disabled selected value={""}>
                      {" "}
                      -- Type utlegg --{" "}
                    </option>

                    {type?.map(a => (
                      <option
                        key={a.id}
                        value={a.id}
                        // onSelect={() => setExpenseType(a.id)}
                      >
                        {a.name}
                      </option>
                    ))}
                    {/* <option value="Annet">Annet</option> */}
                  </select>
                </div>
              </div>

              {expenseType === "292460001" ? (
                <>
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="mileageRateId"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Kjøretilleggssats
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <select
                        {...register("mileageRateId")}
                        name="mileageRateId"
                        id="mileageRateId"
                        key="mileageRateId"
                        //   disabled={disableP}
                        //   value={selectedWrokLocation}
                        // onChange={isNorwegian}
                        onChange={changeRate}
                        value={rateId}
                        //   onChange={updateEmployer}
                        className=" block w-full shadow-sm focus:ring-meny-500 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                      >
                        <option disabled selected value={""}>
                          {" "}
                          -- Kjøretilleggssats --{" "}
                        </option>

                        {mileageRates?.map(a => (
                          <option
                            key={a.id}
                            value={a.id}
                            // onSelect={() => setExpenseType(a.id)}
                          >
                            {a.name}
                          </option>
                        ))}
                        {/* <option value="Annet">Annet</option> */}
                      </select>
                    </div>
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="mileageAmount"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Antall kilometer
                    </label>
                    <input
                      {...register("mileageAmount", {
                        // required: true,
                        pattern: {
                          value: /^[0-9]+$/i,
                          message: "Kun tall er tillatt.",
                        },
                        // min: 50,
                        // max: 100,
                      })}
                      type="text"
                      //   onChange={e =>
                      //     // OnCalculatePercentagePracticePosition(e.target.value) ()
                      //   }
                      //   value={percentagePracticePosition}
                      name="mileageAmount"
                      id="mileageAmount"
                      value={mileageAmount}
                      onChange={updateSumMileage}
                      className="mt-1 focus:ring-meny-700 focus:border-meny-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                    <ErrorMessage
                      errors={errors}
                      name="mileageAmount"
                      render={({ message }) => (
                        <p className="text-sm text-red-600">{message}</p>
                      )}
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3 ">
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Kilometersats pris
                    </label>{" "}
                    <input
                      type="text"
                      name="ratePrice"
                      readOnly
                      value={ratePrice + " kr"}
                      id="ratePrice"
                      className="mt-1 bg-gray-100 focus:ring-meny-700 focus:border-meny-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3 sm:col-start-4">
                    <label
                      htmlFor="sumAmount"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Sum beløp kilometer
                    </label>
                    <input
                      // {...register("sumAmount", {
                      //   pattern: {
                      //     value: /^\d*\.?\d*$/,
                      //     message: "Kun desimaltall er tillatt",
                      //   },
                      // })}
                      type="text"
                      name="sumAmount"
                      readOnly
                      value={ratePriceSum + " kr"}
                      id="sumAmount"
                      className="mt-1 bg-gray-100 focus:ring-meny-700 focus:border-meny-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </>
              ) : (
                <>
                  {/* {invalidSum ? (
                    <p className="text-red-500 text-sm">
                      Sum beløp kan ikke være mindre enn sum kilometer.
                    </p>
                  ) : null} */}

                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="mileageRate"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Enhetspris
                    </label>
                    <input
                      {...register("unit", {
                        // required: true,
                        pattern: {
                          value: /^[0-9]+$/i,
                          message: "Kun tall er tillatt.",
                        },
                        // min: 50,
                        // max: 100,
                      })}
                      type="text"
                      //   onChange={e =>
                      //     // OnCalculatePercentagePracticePosition(e.target.value) ()
                      //   }
                      //   value={percentagePracticePosition}
                      name="mileageRate"
                      id="mileageRate"
                      value={unitPrice}
                      onChange={updateOtherSum}
                      className="mt-1 focus:ring-meny-700 focus:border-meny-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                    <ErrorMessage
                      errors={errors}
                      name="unit"
                      render={({ message }) => (
                        <p className="text-sm text-red-600">{message}</p>
                      )}
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-3 sm:col-start-4">
                    <label
                      htmlFor="sumAmount"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Sum beløp
                    </label>
                    <input
                      // {...register("sumAmount", {
                      //   pattern: {
                      //     value: /^\d*\.?\d*$/,
                      //     message: "Kun desimaltall er tillatt",
                      //   },
                      // })}
                      type="text"
                      name="sumAmount"
                      readOnly
                      value={otherSum + " kr"}
                      id="sumAmount"
                      className="mt-1 bg-gray-100 focus:ring-meny-700 focus:border-meny-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </>
              )}

              {needReceipt ? (
                <div className="col-span-6">
                  <div>
                    <p className="mt-6 text-sm text-gray-600">
                      Vennligst last opp kvittering
                    </p>
                    <FileUpload
                      entityId={expenseLineId}
                      entityName={"expenseReportLine"}
                      entityId2={entityId}
                      entityName2={"expenseReport"}
                      entityId3={null}
                      entityName3={null}
                      mutationGetDocuments={mutationGetDocuments}
                      setDocuments={setDocuments}
                      isReturn={true}
                      isRequired={needReceipt}
                      setFileIsInvalid={setFileIsInvalid}
                    />
                  </div>
                  {fileIsInvalid ? (
                    <p className="text-red-500">
                      Kvitteringen må være PDF, JPG eller PNG-format.
                    </p>
                  ) : (
                    <div></div>
                  )}
                </div>
              ) : null}
            </div>

            <div className="mt-8 flex justify-end w-full">
              <button
                type="submit"
                disabled={fileIsInvalid}
                className={classNames(
                  fileIsInvalid ? whiteButton : cyanButton,
                  " inline-flex items-center"
                )}
              >
                Lagre
              </button>
            </div>
          </form>
          {/* <div className="mt-8 border-t">
            <label
              htmlFor="name"
              className="block mt-2 text-md font-medium text-gray-700"
            >
              Sum totalt: {report?.sumAmount} kr
            </label>
          </div> */}
          {reportLineLength > 0 ? (
            <div className=" flex justify-end">
              <button
                type="submit"
                onClick={finished}
                className="ml-3 mt-6 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-meny-600 hover:bg-meny-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
              >
                Ferdig
              </button>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default AddExpenseReportLine
