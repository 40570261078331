import { useAccount, useMsal } from "@azure/msal-react"
import { Switch } from "@headlessui/react"
import { navigate } from "gatsby"
import React, { useState } from "react"
import { useMutation } from "react-query"
import { toast } from "react-toastify"
import { patchApi, patchApiNoObject } from "../../../Utils/Api.utils"
import { DateTimeToNorwegian } from "../../../Utils/DateFormater"
import { Subscriptions } from "../../../Utils/Entities"
import { classNames } from "../../common/graphichs/misc"
import ContentBox from "../../common/inputFields/ContentBox"
import ModalWithHeader from "../../common/modal/ModalWithHeader"

const MyJournal = props => {
  const [showModal, setShowModal] = useState<boolean>(false)
  const [showPostalModal, setShowPostalModal] = useState<boolean>(false)
  const [isCancel, setIsCancel] = useState<boolean>(false)
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const journal: Subscriptions = props.journal
  const [activeText, setActiveText] = useState<boolean>(
    journal?.subscriptionMagazine
  )

  const content = {
    header: `Tidsskrift - ${journal?.parentOrganizationUnit?.name}`,
    key: journal?.id,
  }
  const mutationCancelSub = useMutation(
    () => {
      return patchApi(
        process.env.GATSBY_API_URL + "/Subscriptions/" + journal?.id,
        {
          endDate: new Date(),
          statecode: 1,
        },
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: () => {
        toast.success("Abonnementet er nå avsluttet.", {
          position: "top-center",
          autoClose: 3000,
        })
        setTimeout(() => {
          navigate("/app")
        }, 3000)
      },
      onError: _error => {},
      onSettled: () => {},
    }
  )

  const mutationUpdateSub = useMutation(
    () => {
      return patchApi(
        process.env.GATSBY_API_URL + "/Subscriptions/" + journal?.id,
        {
          subscriptionMagazine: activeText,
        },
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: () => {
        toast.success("Abonnementet er oppdatert!", {
          position: "top-center",
          autoClose: 3000,
        })
      },
      onError: _error => {},
      onSettled: () => {},
    }
  )

  const openModal = () => {
    setShowPostalModal(true)
    setActiveText(!activeText)
  }

  const updatePostal = () => {
    setShowPostalModal(false)
    mutationUpdateSub.mutate()
  }

  const cancelSub = () => {
    setShowModal(false)

    mutationCancelSub.mutate()
  }
  return (
    <>
      {showModal && (
        <ModalWithHeader
          setModalOpen={setShowModal}
          header="Avslutt abonnement"
        >
          <p>
            Ønsker du å avslutte ditt tidsskrift abonnement med virkning fra i
            dag?
          </p>
          <div className="w-full flex flex-row justify-center mt-8">
            <button
              onClick={() => {
                setShowModal(false)
              }}
              className="w-fit ml-1 inline-flex justify-end py-2 px-8 border border-gray-200 shadow-sm text-md font-medium rounded-md text-black bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
            >
              Avbryt
            </button>
            <button
              onClick={cancelSub}
              className="w-fit ml-1 inline-flex justify-end py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-md text-white bg-red-700 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-700"
            >
              Bekreft
            </button>
          </div>
        </ModalWithHeader>
      )}

      {showPostalModal && (
        <ModalWithHeader
          setModalOpen={setShowPostalModal}
          header="Tidsskrift i post"
        >
          {activeText ? (
            <p>Ønsker du å få tidsskrift i post?</p>
          ) : (
            <p>Ønsker du ikke å få tidsskrift i post?</p>
          )}

          <div className="w-full flex flex-row justify-center mt-8">
            <button
              onClick={() => {
                setShowPostalModal(false)
                setActiveText(!activeText)
              }}
              className="w-fit ml-1 inline-flex justify-end py-2 px-8 border border-gray-200 shadow-sm text-md font-medium rounded-md text-black bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
            >
              Avbryt
            </button>
            <button
              onClick={updatePostal}
              className="w-fit ml-1 inline-flex justify-end py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-md text-white bg-meny-700 hover:bg-meny-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
            >
              Bekreft
            </button>
          </div>
        </ModalWithHeader>
      )}

      <ContentBox props={content}>
        <div className="sm:grid sm:grid-cols-2 gap-3 mb-4">
          <p className="text-base text-gray-600 sm:col-span-2">
            Type abonnement: {journal?.product?.name}
          </p>
          <p className="text-base text-gray-600 sm:col-span-1">
            Antall: {journal?.amount}
          </p>
          {journal?.startDate !== undefined ? (
            <p className="text-base text-gray-600 sm:col-span-1">
              Start dato: {DateTimeToNorwegian(journal?.startDate)}
            </p>
          ) : (
            <p className="text-base text-gray-600 sm:col-span-1">Start dato:</p>
          )}
          <p className="text-base text-gray-600 sm:col-span-1">
            Annen fakturamottaker: {journal?.invoiceCustomer?.name}
          </p>
          <p className="text-base text-gray-600 sm:col-span-1">
            Fakturahyppighet: {journal?.invoiceFrequency}
          </p>
          <div className="mr-10 relative flex items-start pb-4 sm:col-start-2">
            <div className="min-w-0 mr-4 text-sm">
              <p className="text-gray-600 text-base">Tidsskrift i post</p>
            </div>
            <div className="mt-1 sm:mt-0 ">
              <Switch
                checked={activeText}
                onChange={openModal}
                id="liabilityInsurance"
                className={classNames(
                  activeText ? "bg-meny-600" : "bg-gray-900",
                  "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
                )}
              >
                <span
                  aria-hidden="true"
                  className={classNames(
                    activeText ? "translate-x-5" : "translate-x-0",
                    "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                  )}
                />
              </Switch>
            </div>
          </div>
        </div>

        <>
          {/* <div className="sm:grid sm:grid-cols-2 gap-3 mb-4"> */}

          {/* </div> */}
        </>
        {journal?.endDate != undefined ? (
          <></>
        ) : (
          <div className="sm:justify center">
            <button
              onClick={() => {
                setShowModal(true)
              }}
              className="w-fit ml-1 py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-md text-white bg-red-700 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-700"
            >
              Avslutt abonnement
            </button>
          </div>
        )}
      </ContentBox>
    </>
  )
}

export default MyJournal
