import { InteractionType } from "@azure/msal-browser"
import { MsalAuthenticationTemplate } from "@azure/msal-react"
import React from "react"
import { Loading } from "../../../Utils/Loading"
import { ErrorComponent } from "../../common/graphichs/ErrorComponent"
import MyPageLayout from "../../common/layouts/myPageLayout"
import Søknad from "../../mypagecomponents/membership/applications/Application"
import ChangePensioner from "../../mypagecomponents/membership/applications/ChangePensioner"
import EndringStudent from "../../mypagecomponents/membership/applications/ChangeStudent"
import Unsubscribe from "../../mypagecomponents/membership/applications/Unsubscribe"
import { loginRequest } from "../../security/authConfig"
import CloseMembership from "./applications/CloseMembership"

const ChangeMembership = () => {
  return (
    <MyPageLayout props={"Endre medlemskap"}>
      <div>
        <Søknad />
        <br />
        <EndringStudent />
        <br />
        <ChangePensioner />
        <br />
        <Unsubscribe />
      </div>
    </MyPageLayout>
  )
}

const msalMembership = () => {
  const authRequest = {
    ...loginRequest,
  }
  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      <ChangeMembership />
    </MsalAuthenticationTemplate>
  )
}

export default msalMembership
