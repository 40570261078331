export const filterDocuments = (documents, selectedProgramType, selectedSpeciality, selectedActivityType) => {
    let result = []

    if (selectedProgramType != 0 && selectedSpeciality != 0 && selectedActivityType != 0)
        result = documents.filter(x =>
            x.programtypeId == selectedProgramType && x.specialtyId == selectedSpeciality && x.activityType == selectedActivityType)

    if (selectedProgramType != 0 && selectedSpeciality != 0 && selectedActivityType == 0)
        result = documents.filter(x => x.programtypeId == selectedProgramType && x.specialtyId == selectedSpeciality)

    if (selectedProgramType != 0 && selectedSpeciality == 0 && selectedActivityType != 0)
        result = documents.filter(x => x.programtypeId == selectedProgramType && x.activityType == selectedActivityType)

    if (selectedProgramType == 0 && selectedSpeciality != 0 && selectedActivityType != 0)
        result = documents.filter(x => x.specialtyId == selectedSpeciality && x.activityType == selectedActivityType)

    if (selectedProgramType == 0 && selectedSpeciality == 0 && selectedActivityType != 0)
        result = documents.filter(x => x.activityType == selectedActivityType)

    if (selectedProgramType == 0 && selectedSpeciality != 0 && selectedActivityType == 0)
        result = documents.filter(x => x.specialtyId == selectedSpeciality)

    if (selectedProgramType != 0 && selectedSpeciality == 0 && selectedActivityType == 0)
        result = documents.filter(x => x.programtypeId == selectedProgramType)

    return result
}