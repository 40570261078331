import React, { ReactChild, useContext, useEffect, useState } from "react"
import LoadingData from "../../../common/graphichs/LoadingData"
import { Steps } from "../../../common/Steps"
import Betaling from "./Betaling"
import { InnmeldingContext } from "./dataStorage"
import Kursspm from "./Kursspm"
import Kursvelger from "./Kursvelger"
import Oppsummering from "./Oppsummering"
import Persona from "./Persona"
import { toast } from "react-toastify"
import Grunn from "./Grunn"

const InnmeldingSection = () => {
  const [pageId, setPageId] = useState<string>("")
  const [isNextAvailabled, setIsNextAvailabled] = useState(false)
  const [buttonDisabled, setButtonDisabled] = useState(false)
  const [allQuestionAnswered, setAllQuestionAnswered] = useState(false)

  const InnmeldingData = useContext(InnmeldingContext)
  if (!InnmeldingData) return <div></div>

  useEffect(() => {
    setPageId(
      InnmeldingData.stepsData[0].filter(step => step.status === "enabled")[
        InnmeldingData.currentStepIndex[0]
      ].id
    )
  }, [InnmeldingData.currentStepIndex[0]])

  useEffect(() => {
    setIsNextAvailabled(InnmeldingData.stepsControl.isNextEnabled())
  }, [InnmeldingData.stepsData[0], InnmeldingData.currentStepIndex[0]])

  useEffect(() => {
    if (InnmeldingData.registrationFieldsQuery?.length === 0) {
      setAllQuestionAnswered(true)
      return
    }

    const requiredQuestionsIds = InnmeldingData.registrationFieldsQuery
      ?.filter(item => item.isRequired)
      .map(item => item.customRegistrationFieldId)

    if (requiredQuestionsIds?.length === 0 || !requiredQuestionsIds) {
      setAllQuestionAnswered(true)
      return
    }

    const allRequiredQuestionsAnswared = requiredQuestionsIds.every(
      requiredQuestionId => {
        const keys = Array.from(InnmeldingData.questionnaireAnswers[0].keys())

        if (keys.length === 0) {
          return false
        }

        const something = keys.every(key => {
          return InnmeldingData.questionnaireAnswers[0]
            .get(key)
            ?.some(item => item.id === requiredQuestionId)
        })

        return something
      }
    )

    setAllQuestionAnswered(allRequiredQuestionsAnswared)
  }, [
    InnmeldingData.registrationFieldsQuery,
    InnmeldingData.questionnaireAnswers[0],
  ])

  return (
    <>
      {InnmeldingData.submitingData[0] && <LoadingData />}

      <div className="w-full  flex justify-center">
        <div className="max-w-3xl w-full space-y-12">
          <Steps
            steps={InnmeldingData.stepsData[0].filter(
              item => item.status === "enabled"
            )}
            currentStepIndex={InnmeldingData.currentStepIndex[0]}
          ></Steps>
          <div className="min-h-[128px] ">
            {pageId === "persona" && <Persona />}
            {pageId === "kursvelger" && <Kursvelger />}
            {pageId === "kursspm" && <Kursspm />}
            {pageId === "betaling" && <Betaling />}
            {pageId === "Grunn" && <Grunn />}
            {pageId === "oppsummering" && <Oppsummering />}
          </div>

          <div className="flex justify-evenly">
            <div
              className={
                InnmeldingData.currentStepIndex[0] === 0 ? "hidden" : "block"
              }
            >
              <Button
                onClick={() =>
                  InnmeldingData.currentStepIndex[1](prev => prev - 1)
                }
              >
                Forrige
              </Button>
            </div>
            <div>
              {pageId !== "oppsummering" && pageId !== "Grunn" && (
                <Button
                  disabled={
                    !isNextAvailabled ||
                    !InnmeldingData.registrationFieldsQueryStatus.isSuccess ||
                    !InnmeldingData.sessionQueryStatus.isSuccess ||
                    (pageId === "kursspm" && !allQuestionAnswered)
                  }
                  onClick={() =>
                    InnmeldingData.currentStepIndex[1](prev => prev + 1)
                  }
                >
                  Neste
                </Button>
              )}

              {(pageId === "oppsummering" || pageId === "Grunn") && (
                <Button
                  disabled={buttonDisabled}
                  onClick={e => {
                    const newE = {
                      ...e,
                      callback: () => {
                        if (pageId === "Grunn") {
                          toast.error(
                            "Du kan ikke ha mer enn to kurspåmeldinger med fremtidig start. Ta kontakt med kursavdelingen for bistand ved spørsmål eller feil"
                          )
                        } else {
                          InnmeldingData.currentStepIndex[1](prev => prev + 1)
                        }
                        console.log("callback")
                        setButtonDisabled(false)
                      },
                    }

                    InnmeldingData.Submit(newE)
                    setButtonDisabled(true)
                  }}
                >
                  Meld på
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const Button = (props: {
  children?: ReactChild
  onClick?: (e: any) => void
  disabled?: boolean
}) => {
  return (
    <div className="py-6">
      <button
        onClick={props.onClick}
        disabled={props.disabled || false}
        className="disabled:bg-slate-400 ml-1 inline-flex justify-end py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-md text-white bg-meny-700 hover:bg-meny-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
      >
        {props.children}
      </button>
    </div>
  )
}

export default InnmeldingSection
