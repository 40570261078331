import { InteractionType } from "@azure/msal-browser"
import {
  MsalAuthenticationTemplate,
  useAccount,
  useMsal,
} from "@azure/msal-react"
import React, { useEffect, useState } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import {
  fetchApi,
  fetchApiAnonymous,
  patchApi,
  postGetDocumentApi,
} from "../../../Utils/Api.utils"
import { Loading } from "../../../Utils/Loading"
import { ErrorComponent } from "../../common/graphichs/ErrorComponent"
import LoadingData from "../../common/graphichs/LoadingData"
import MyPageLayout from "../../common/layouts/myPageLayout"
import HeaderText from "../../common/text/Header"
import HeaderHideShowChild from "../../common/text/HeaderHideShowChild"
import DocumentList from "../../mypagecomponents/document/DocumentList"
import {
  EventAdminCRM,
  EventCrm,
  SessionCRM,
  SpeakerRequest,
} from "../../mypagecomponents/event/EventType"
import MyEvent from "../../mypagecomponents/eventAdmin/MyEvent"
import MySession from "../../mypagecomponents/eventAdmin/MySession"
import SpeakerRequests from "../../mypagecomponents/eventAdmin/SpeakerRequests"
import { loginRequest } from "../../security/authConfig"
import { cyanButton } from "../../../styles/tailwindClasses"

const EventAdminContent = () => {
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const queryClient = useQueryClient()
  const [myPreviousEvents, setMyPrevEvents] = useState<EventAdminCRM[]>()
  const [myPreviousSessions, setMyPreviousSessions] = useState<SessionCRM[]>()
  const [myFutureSessions, setMyFutureSessions] = useState<SessionCRM[]>()
  const [myFutureEvents, setMyFutureEvents] = useState<EventAdminCRM[]>()
  const [accountNo, setAccountNo] = useState<string>("")
  const [loadingData, setLoadingData] = useState<boolean>(false)
  const [showButton, setShowButton] = useState<boolean>(false)
  const [settlementMethod, setSettlementMethod] = useState<number>()
  const [invalidAccountNo, setInvalidAccountNo] = useState<boolean>(false)

  const [myRequests, setMyRequests] = useState<SpeakerRequest[]>()

  const [documents, setDocuments] = useState([])

  const mutationGetDocument = useQuery(
    "contractDocuments",
    () => {
      return postGetDocumentApi(
        process.env.GATSBY_API_URL + "/Documents/GetContract",
        {
          ContactId: "",
        },
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: data => {
        setDocuments(data)
      },
      onError: _error => {},
      onSettled: () => {},
    }
  )

  const today = new Date()

  const eventAdminQuery = useQuery(
    "eventAdmin",
    () =>
      fetchApi(
        process.env.GATSBY_API_URL + "/EventAdmin/allMySingleEvents",
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: data => {
        let previous = data
        let future = data
        setMyFutureEvents(
          future.filter(
            x =>
              x?.startDate !== undefined &&
              new Date(x?.startDate).getTime() >= today.getTime()
          )
        )
        setMyPrevEvents(
          previous.filter(
            x =>
              x?.startDate !== undefined &&
              new Date(x?.startDate).getTime() < today.getTime()
          )
        )
      },
      onError: _error => {},
    }
  )

  const sessionAdminQuery = useQuery(
    "sessionAdmin",
    () =>
      fetchApi(
        process.env.GATSBY_API_URL + "/EventAdmin/allMySessions",
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: data => {
        let previous = data
        let future = data
        setMyPreviousSessions(
          previous.filter(
            x =>
              x?.starttime !== undefined &&
              new Date(x?.starttime).getTime() < today.getTime()
          )
        )
        setMyFutureSessions(
          future.filter(
            x =>
              x?.starttime !== undefined &&
              new Date(x?.starttime).getTime() >= today.getTime()
          )
        )
      },
      onError: _error => {},
    }
  )

  // ascending
  myFutureEvents?.sort((a, b) => {
    return (
      new Date(a.startDate || "").getTime() -
      new Date(b.startDate || "").getTime()
    )
  })

  // descending
  myPreviousEvents?.sort((a, b) => {
    return (
      new Date(b.startDate || "").getTime() -
      new Date(a.startDate || "").getTime()
    )
  })

  // descending
  myPreviousSessions?.sort((a, b) => {
    return (
      new Date(b.starttime || "").getTime() -
      new Date(a.starttime || "").getTime()
    )
  })

  // ascending
  myFutureSessions?.sort((a, b) => {
    return (
      new Date(a.starttime || "").getTime() -
      new Date(b.starttime || "").getTime()
    )
  })

  myRequests?.sort((a, b) => {
    if (a.sessionId !== undefined && b.sessionId !== undefined) {
      return (
        new Date(a?.session?.starttime || "").getTime() -
        new Date(b?.session?.starttime || "").getTime()
      )
    } else if (a.sessionId !== undefined && b.sessionId === undefined) {
      return (
        new Date(a?.session?.starttime || "").getTime() -
        new Date(b?.event?.startDate || "").getTime()
      )
    } else {
      return (
        new Date(a?.event?.startDate || "").getTime() -
        new Date(b?.event?.startDate || "").getTime()
      )
    }
  })

  const speakerRequestQuery = useQuery(
    "speakerRequests",
    () =>
      fetchApi(
        process.env.GATSBY_API_URL + "/SpeakerRequests",
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: data => {
        setMyRequests(data)
      },
      onError: _error => {},
    }
  )

  const userContact = useQuery(
    "userInfo",
    () =>
      fetchApi(
        process.env.GATSBY_API_URL + "/Contacts/me",
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: data => {
        setAccountNo(data?.accountNo)
      },
      onError: _error => {},
    }
  )

  const mutationUpdateContactAccountNo = useMutation(
    () => {
      return patchApi(
        `${process.env.GATSBY_API_URL}/Contacts/me`,
        {
          accountNo: accountNo,
        },
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: data => {
        queryClient.invalidateQueries(["userInfo"])
        setLoadingData(false)
      },
      onError: _error => {
        setLoadingData(false)
      },
    }
  )

  const speakerInfo = useQuery(
    "speakerInfo",
    () =>
      fetchApi(
        process.env.GATSBY_API_URL + "/Speaker/me",
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: data => {
        setSettlementMethod(data?.[0]?.settlementMethod)
      },
      onError: _error => {},
    }
  )

  const mutationUpdateSpeakerInfo = useMutation(
    () => {
      return patchApi(
        `${process.env.GATSBY_API_URL}/Speaker`,
        {
          id: speakerInfo?.data?.[0]?.id,
          settlementMethod: settlementMethod,
        },
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: data => {
        setLoadingData(false)
        queryClient.invalidateQueries(["speakerInfo"])
      },
      onError: _error => {
        setLoadingData(false)
      },
    }
  )

  const changeAccountNo = event => {
    const acNo = event.target.value.replaceAll(".", "")
    const acNo2 = acNo.replaceAll(" ", "")
    setAccountNo(acNo2)
  }

  const updateAccountInfo = () => {
    setLoadingData(true)
    if (
      accountNo !== userContact?.data?.accountNo &&
      (accountNo.length === 11 || accountNo.length === 0)
    ) {
      mutationUpdateContactAccountNo.mutate()
    }

    if (settlementMethod !== speakerInfo?.data?.[0]?.settlementMethod) {
      mutationUpdateSpeakerInfo.mutate()
    }
  }

  useEffect(() => {
    if (
      (accountNo !== userContact?.data?.accountNo ||
        settlementMethod !== speakerInfo?.data?.[0]?.settlementMethod) &&
      invalidAccountNo === false
    ) {
      setShowButton(true)
    } else {
      setShowButton(false)
    }
  }, [accountNo, settlementMethod, userContact, speakerInfo])

  useEffect(() => {
    if (
      accountNo?.length !== 11 &&
      accountNo?.length !== 0 &&
      accountNo !== undefined
    )
      setInvalidAccountNo(true)
    else {
      setInvalidAccountNo(false)
    }
  }, [accountNo])

  return eventAdminQuery.isLoading ||
    sessionAdminQuery.isLoading ||
    speakerRequestQuery.isLoading ||
    loadingData ? (
    <LoadingData />
  ) : (
    <MyPageLayout props="Kursadministrasjon">
      <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
        <section className="mb-4">
          {speakerInfo?.data?.[0]?.settlementMethod === 292460000 ? (
            <div>
              <h3 className="text-xl text-center font-bold text-meny-700 mb-2">
                Betalingsinformasjon
              </h3>
              <div className="flex flex-col sm:flex-row justify-center items-start gap-4 mb-4 text-lg ">
                <div className=" flex items-center">
                  <label className=" font-medium text-gray-700">Honorar</label>
                  <input
                    className="ml-2"
                    type="checkbox"
                    checked={settlementMethod === 292460000}
                    onChange={e =>
                      setSettlementMethod(
                        e.target.checked ? 292460000 : 292460001
                      )
                    }
                  />
                </div>
                <div className=" flex gap-2 items-center ">
                  <p className="font-medium text-gray-700">Bankkontonummer:</p>
                  <input
                    className="w-full h-6 shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                    value={accountNo}
                    onChange={changeAccountNo}
                  />
                </div>
                {invalidAccountNo ? (
                  <p className="text-red-500 text-sm">
                    Kontonummer må inneholde 11 siffer.
                  </p>
                ) : (
                  <div></div>
                )}
                {showButton ? (
                  <button
                    className={
                      cyanButton + " inline-flex items-center mx-auto sm:mx-0 "
                    }
                    onClick={updateAccountInfo}
                  >
                    Lagre endringer
                  </button>
                ) : null}
              </div>
            </div>
          ) : (
            <div className="flex flex-col items-center">
              <h3 className="text-xl text-center font-bold text-meny-700 mb-2">
                Betalingsinformasjon
              </h3>
              <p className="font-medium text-gray-700">
                Betalingsmåte er faktura
              </p>
            </div>
          )}
        </section>
        <HeaderText text="Kurs jeg er kursleder/foredragsholder for" />

        <div className="max-w-5xl mx-auto">
          <HeaderHideShowChild props={"Fremtidige enkeltstående kurs"}>
            {/* <p>Fremtidige enkeltstående arrangement</p> */}
            <div className="mt-2">
              {myFutureEvents?.map(event => {
                return <MyEvent event={event} />
              })}
            </div>
          </HeaderHideShowChild>
        </div>

        <div className="max-w-5xl mx-auto">
          <HeaderHideShowChild props={"Fremtidige kurs i et program"}>
            <div className="mt-2">
              {myFutureSessions?.map(session => {
                return <MySession session={session} />
              })}
            </div>
          </HeaderHideShowChild>
        </div>

        <div className="max-w-5xl mx-auto">
          <HeaderHideShowChild props={"Tidligere enkeltstående kurs"}>
            <div className="mt-2">
              {myPreviousEvents?.map(event => {
                return <MyEvent event={event} />
              })}
            </div>
          </HeaderHideShowChild>
        </div>

        <div className="max-w-5xl mx-auto mb-4">
          <HeaderHideShowChild props={"Tidligere kurs i et program"}>
            <div className="mt-2">
              {myPreviousSessions?.map(session => {
                return <MySession session={session} />
              })}
            </div>
          </HeaderHideShowChild>
        </div>
        <div className="mt-4">
          <HeaderText text="Signerte kontrakter" />
          <div className="my-4">
            <DocumentList
              documents={documents}
              mutationGetDocuments={mutationGetDocument}
              canDelete={false}
            />
          </div>
        </div>
        <HeaderText text="Bidragsyterforespørsler" />
        <div className="mt-8">
          {myRequests?.map(request => {
            return <SpeakerRequests request={request} />
          })}
        </div>
      </div>
    </MyPageLayout>
  )
}

const EventAdmin = () => {
  const authRequest = {
    ...loginRequest,
  }
  // console.log(location.state)
  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      <EventAdminContent />
    </MsalAuthenticationTemplate>
  )
}

export default EventAdmin
