import React from "react"
import { useFieldArray, useForm } from "react-hook-form"
import { Employment } from "../../../../../Utils/Entities"
import TableHeader from "../../../../common/tabel/TableHeader"
import RetiredKeepEmploymentsTableRow from "./RetiredKeepEmploymentsTableRow"
import {
  FormValues,
  RetiredKeepEmploymentsProps,
} from "./RetiredKeepEmploymentsType"

const RetiredKeepEmploymentsForm = (props: RetiredKeepEmploymentsProps) => {
  const formController = useForm<FormValues>({
    defaultValues: { employments: props.employments },
  })

  const fieldController = useFieldArray({
    control: formController.control,
    name: "employments",
  })

  const OnSubmit = (data: FormValues) => {
    console.log(data)

    data.employments.map(item => {
      const submitData: Partial<Employment> = {
        id: item.id,
        partTimePosition: item.newPositionPercentage,
        endDate: item.endDate ? new Date(item.endDate) : undefined,
        role: 125600001, // 125600001 = "Biarbeidsgiver"
      }

      props.onSave(submitData)
    })
  }

  return (
    <form onSubmit={formController.handleSubmit(OnSubmit)}>
      <div className="flex overflow-x-scroll">
        <table className="w-full mb-4">
          <thead>
            <tr className="">
              <TableHeader className="w-56 text-left">Arbeidsgiver</TableHeader>
              <TableHeader className="w-56 text-left">Arbeidssted</TableHeader>
              <TableHeader className="w-20">Stillings%</TableHeader>
              <TableHeader className="w-20">Avslutt arbeidsforhold</TableHeader>
              <TableHeader className="w-28">Dato for avslutting</TableHeader>
            </tr>
          </thead>
          <tbody>
            {fieldController.fields.map((item, index) => (
              <RetiredKeepEmploymentsTableRow
                key={item.id}
                employment={item}
                formController={formController}
                index={index}
              />
            ))}
          </tbody>
        </table>
      </div>

      <div className="flex w-full mt-12 justify-center ">
        <button
          type="submit"
          className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-meny-700 hover:bg-meny-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
        >
          Oppdater arbeidsforhold
        </button>
      </div>
    </form>
  )
}

export default RetiredKeepEmploymentsForm
