import { InteractionType } from "@azure/msal-browser"
import {
  MsalAuthenticationTemplate,
  useAccount,
  useMsal,
} from "@azure/msal-react"
import { ErrorMessage } from "@hookform/error-message"
import { navigate } from "gatsby"
import React, { useState } from "react"
import ReactDatePicker from "react-datepicker"
import { useForm } from "react-hook-form"
import { useMutation, useQuery } from "react-query"
import { toast } from "react-toastify"
import { cyanButton, whiteButton } from "../../../styles/tailwindClasses"
import { patchApi } from "../../../Utils/Api.utils"
import { Loading } from "../../../Utils/Loading"
import { Activity, PracticeHour } from "../../../Utils/Types"
import { ErrorComponent } from "../../common/graphichs/ErrorComponent"
import LoadingData from "../../common/graphichs/LoadingData"
import MyPageLayout from "../../common/layouts/myPageLayout"
import MyPageLayoutEducation from "../../common/layouts/MyPageLayoutEducation"
import GetHelpText from "../../common/text/GetHelpText"
import { loginRequest } from "../../security/authConfig"

const EditPracticeHourContent = props => {
  const activity: Activity = props?.activity
  const practiceHour: PracticeHour = props?.practiceHour
  const allPracticeHours: Array<PracticeHour> = props?.allPracticeHours
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const [startDate, setStartDate] = useState(practiceHour?.fromDate)
  const [endDate, setEndDate] = useState(practiceHour?.toDate)
  const [percentagePracticePosition, setPercentagePracticePosition] =
    useState<number>(practiceHour?.percentagePracticePosition)
  const [diffMonths, setDiffMonths] = useState<number>(practiceHour?.diffMonths)
  const [count, setCount] = useState(0)
  const [sumLocalPractice, setSumLocalPractice] = useState(
    practiceHour.practice
  )
  const [prevStartDate, setPrevStartDate] = useState<Date>(activity?.fromDate)
  const [prevEndDate, setPrevEndDate] = useState<Date>(activity?.toDate)
  const [newStartDate, setNewStartDate] = useState(null)
  const [newEndDate, setNewEndDate] = useState(null)

  // const [earliestDate, setEarliestDate] = useState()
  // const [latestDate, setLatestDate] = useState()
  const [showLoadingData, setShowLoadingData] = useState<boolean>(false)

  const {
    register,
    handleSubmit,
    reset,
    control,
    setError,
    formState: { errors },
  } = useForm()

  //   console.log(allPracticeHours)

  const mutationUpdateActivity = useMutation(
    data => {
      return patchApi(
        process.env.GATSBY_API_URL + "/SpecializationActivities",
        data,
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: () => {
        navigate(-1)
        // setShowDocumentComponent(false)
        // mutationUpdateIncident.mutate()
      },
      onError: _error => {
        console.log("Feil: " + _error)
      },
      onSettled: () => {},
    }
  )

  const mutationUpdatePracticeHours = useMutation(
    data => {
      return patchApi(
        process.env.GATSBY_API_URL + "/PracticeHours",
        data,
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: data => {
        if (newStartDate !== null || newEndDate !== null) {
          let activityData: Activity = activity
          if (newStartDate !== null) activityData.fromDate = newStartDate
          if (newEndDate !== null) activityData.toDate = newEndDate

          mutationUpdateActivity.mutate(activityData)
        } else {
          navigate(-1)
        }
      },
      onError: _error => {},
      onSettled: () => {},
    }
  )

  const OnSetStartDate = date => {
    setStartDate(date)
    let prevDate: Date
    let earlyDate
    if (activity?.fromDate !== null) {
      if (date < new Date(activity?.fromDate)) {
        setNewStartDate(date)
      } else {
        allPracticeHours.forEach(ph => {
          if (activity?.fromDate == practiceHour?.fromDate) {
            if (ph.id !== practiceHour?.id) {
              if (prevDate !== undefined) {
                if (ph.fromDate < prevDate) {
                  earlyDate = ph.fromDate
                }
              } else {
                earlyDate = ph.fromDate
              }
              prevDate = ph.fromDate
            }
          }
        })
        if (date < new Date(earlyDate)) setNewStartDate(date)
        else setNewStartDate(earlyDate)
      }
    }

    if (endDate != null) {
      const numberOfWeeks = (start, end) =>
        Math.round((end - start) / (7 * 24 * 60 * 60 * 1000))
      const diffWeek = numberOfWeeks(date, new Date(endDate))
      setDiffMonths(diffWeek)
      OnCalculatePercentagePracticePosition2(
        percentagePracticePosition,
        diffWeek
      )
    }
  }

  const OnSetEndDate = date => {
    let prevDate: Date
    let lateDate
    setEndDate(date)
    if (activity?.toDate !== null) {
      if (date > new Date(activity?.toDate)) {
        setNewEndDate(date)
      } else {
        allPracticeHours.forEach(ph => {
          if (activity?.toDate == practiceHour?.toDate) {
            if (ph.id !== practiceHour?.id) {
              if (prevDate !== undefined) {
                if (ph.toDate > prevDate) {
                  lateDate = ph.toDate
                }
              } else {
                lateDate = ph.toDate
              }
              prevDate = ph.toDate
            }
          }
        })
        if (date > new Date(lateDate)) setNewEndDate(date)
        else setNewEndDate(lateDate)
      }
    }
    if (startDate != null) {
      const numberOfWeeks = (start, end) =>
        Math.round((end - start) / (7 * 24 * 60 * 60 * 1000))
      const diffWeek = numberOfWeeks(new Date(startDate), date)

      setDiffMonths(diffWeek)
      OnCalculatePercentagePracticePosition2(
        percentagePracticePosition,
        diffWeek
      )
    }
  }

  const OnCalculatePercentagePracticePosition = value => {
    if (Number.isNaN(value)) {
      setPercentagePracticePosition(0)
    } else {
      setPercentagePracticePosition(value)
      if (diffMonths != 0) {
        const sumPractice = value * diffMonths
        setSumLocalPractice(sumPractice / 100)
      }
    }
  }

  const OnCalculatePercentagePracticePosition2 = (
    percentagePracticePosition2,
    diffMonths2
  ) => {
    // setPercentagePracticePosition(percentagePracticePosition2)
    if (diffMonths2 != 0 && percentagePracticePosition2 !== undefined) {
      const sumPractice = percentagePracticePosition2 * diffMonths2
      setSumLocalPractice(sumPractice / 100)
    }
  }

  console.log(percentagePracticePosition)
  const patchPracticeHour = e => {
    let UpdatedPracticeHour: PracticeHour = practiceHour
    UpdatedPracticeHour.fromDate = startDate
    UpdatedPracticeHour.toDate = endDate
    UpdatedPracticeHour.percentagePracticePosition = percentagePracticePosition

    UpdatedPracticeHour.diffMonths = diffMonths
    UpdatedPracticeHour.practice = sumLocalPractice
    // UpdatedPracticeHour.sumLocalPractice = sumLocalPractice

    if (
      startDate == undefined ||
      endDate == undefined ||
      percentagePracticePosition == undefined ||
      diffMonths == undefined
    ) {
      toast.warning("Vennligst fyll ut alle obligatoriske felter!", {
        position: "top-center",
        autoClose: 10000,
      })
      return
    }

    if (percentagePracticePosition > 100 || percentagePracticePosition < 50) {
      toast.warning("Praksis % stilling skal være mellom 50 og 100!", {
        position: "top-center",
        autoClose: 10000,
      })
      return
    }

    setShowLoadingData(true)

    // UpdatedPracticeHour.activitySpecialistProgramId = activity?.id
    mutationUpdatePracticeHours.mutate(UpdatedPracticeHour)

    // if (percentagePracticePosition != undefined && diffMonths != undefined) {
    //   const sumPractice = percentagePracticePosition * diffMonths
    //   CalculateSumPractice(sumPractice / 100)
    // }

    // setPracticeHours([...practiceHours, data])
    // setPracticeHours(practiceHours => [...practiceHours, data])
    // setCount(count + 1)
  }

  return (
    <div>
      <div>{showLoadingData && <LoadingData subject="Oppdaterer..." />}</div>
      <MyPageLayout props="Endre Praksisdetaljer">
        <button
          onClick={() => navigate(-1)}
          className={
            whiteButton +
            " inline-flex items-center justify-center focus:ring-offset-2 sm:w-auto "
          }
        >
          Tilbake
        </button>
        <div className="space-y-6 mt-8">
          <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
            <div className="mt-5 md:mt-0 md:col-span-2">
              <div className="mt-8  justify-end">
                <form method="PATCH" onSubmit={handleSubmit(patchPracticeHour)}>
                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="first-name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Fra dato*
                      </label>
                      <ReactDatePicker
                        id="startDate"
                        locale="nb"
                        showYearDropdown
                        selected={Date.parse(startDate)}
                        onChange={(date: Date) => OnSetStartDate(date)}
                        dateFormat="dd.MM.yyyy"
                        className="block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="last-name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Til dato*
                      </label>
                      <ReactDatePicker
                        id="endDate"
                        locale="nb"
                        showYearDropdown
                        selected={Date.parse(endDate)}
                        onChange={(date: Date) => OnSetEndDate(date)}
                        dateFormat="dd.MM.yyyy"
                        className="block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="percentagePracticePosition"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Praksis % stilling*
                      </label>
                      <input
                        {...register("percentagePracticePosition", {
                          required: {
                            value: true,
                            message: "Obligatorisk felt.",
                          },
                          pattern: {
                            value: /^[5-9][0-9]?$|^100$/,
                            message: "Kun tall fra 50 til 100 er tillatt.",
                          },
                          // min: 50,
                          // max: 100,
                        })}
                        type="text"
                        onChange={e =>
                          OnCalculatePercentagePracticePosition(
                            parseInt(e.target.value)
                          )
                        }
                        value={percentagePracticePosition}
                        defaultValue={percentagePracticePosition}
                        name="percentagePracticePosition"
                        id="percentagePracticePosition"
                        className="mt-1 focus:ring-meny-700 focus:border-meny-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                      <ErrorMessage
                        errors={errors}
                        name="percentagePracticePosition"
                        render={({ message }) => (
                          <p className="text-sm text-red-600">{message}</p>
                        )}
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="diffMonths"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Ant uker praksis
                      </label>
                      <input
                        {...register("diffMonths")}
                        readOnly
                        onChange={e =>
                          setDiffMonths(parseFloat(e.target.value))
                        }
                        value={diffMonths}
                        type="text"
                        name="diffMonths"
                        id="diffMonths"
                        className="mt-1 bg-gray-300 focus:ring-meny-700 focus:border-meny-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="sumPractice"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Sum praksis
                      </label>
                      <input
                        {...register("sumPractice")}
                        readOnly
                        onChange={e =>
                          setSumLocalPractice(parseFloat(e.target.value))
                        }
                        value={sumLocalPractice}
                        type="text"
                        name="sumPractice"
                        id="sumPractice"
                        className="mt-1 bg-gray-300 focus:ring-meny-700 focus:border-meny-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  </div>
                  <div className="mt-8 flex justify-end w-full">
                    <button
                      type="submit"
                      className={cyanButton + " inline-flex items-center "}
                    >
                      Oppdater
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </MyPageLayout>
    </div>
  )
}

const EditPracticeHour = ({ location }) => {
  const authRequest = {
    ...loginRequest,
  }
  const activity: Activity = location.state.activity
  const practiceHour: PracticeHour = location.state.practiceHour
  const allPracticeHours: Array<PracticeHour> = location.state.allPracticeHours
  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      <EditPracticeHourContent
        activity={activity}
        practiceHour={practiceHour}
        allPracticeHours={allPracticeHours}
      />
    </MsalAuthenticationTemplate>
  )
}

export default EditPracticeHour
