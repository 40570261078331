import { useAccount, useMsal } from "@azure/msal-react"
import React, { useEffect, useMemo, useState } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { fetchApi, patchApi } from "../../../../Utils/Api.utils"
import { DropDown } from "../../../common/dropDown"
import {
  EventRegistration,
  UpsertPayerType,
  WorkplaceType,
} from "../../../mypagecomponents/event/EventType"
import Button from "./Button"
import CompanyPayer from "./CompanyPayer"
import OtherPayer from "./OtherPayer"

const PayerSection = (props: EventRegistration & { canEdit?: boolean }) => {
  const [editMode, setEditMode] = useState(false)
  const [workplace, setWorkplace] = useState<WorkplaceType | undefined>(
    undefined
  )
  const [paymentObject, setPaymentObject] = useState<
    UpsertPayerType | undefined
  >(undefined)

  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})

  const queryClient = useQueryClient()

  const customerName = props.customerContact
    ? (props.customerContact?.firstname || "") +
      " " +
      (props.customerContact?.lastname || "")
    : props.customerAccount
    ? props.customerAccount.name
    : "ingen betaler registrert"

  const data: { lable: string; value?: string }[] = [
    { lable: "Betaler", value: customerName },
    { lable: "Firmanavn", value: props.invoicecompanyname },
    { lable: "Orgnr", value: props.invoiceorganizationno },
    { lable: "Fakturaadresse", value: props.invoiceaddressline1 },
    {
      lable: "Postnr",
      value: props.zipCode?.postalCode
        ? props.zipCode?.postalCode + " " + props.zipCode?.postalPlace
        : undefined,
    },
    { lable: "PO-/ Bestillingsnummer", value: props.customerpurchaseno },
    { lable: "Fakturaref/ Kontaktperson", value: props.billtoContactname },
  ]

  const payerType = [
    { id: "Privat", label: "Privat" },
    { id: "Arbeidsgiver", label: "Arbeidsgiver" },
    { id: "Annen", label: "Annen" },
  ]

  useQuery<WorkplaceType>(
    ["workplace"],
    async () =>
      fetchApi(
        process.env.GATSBY_API_URL + `/Contacts/Employer`,
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: data => {
        setWorkplace(data)
      },
    }
  )

  const customerType =
    props.customerContact?.firstname || props.customerContact?.lastname
      ? "Privat"
      : props.customerAccount
      ? "Arbeidsgiver"
      : "Annen"

  const [payer, setPayer] = useState<string>(customerType)

  const initPayerData: Array<{ id: string; payload?: string }> = [
    { id: "account", payload: props.customerAccount?.name },
    { id: "name", payload: props.invoicecompanyname },
    { id: "orgNr", payload: props.invoiceorganizationno },
    { id: "invoiceAdr", payload: props.invoiceaddressline1 },
    { id: "invoiceRef1", payload: props.customerpurchaseno },
    { id: "invoiceRef2", payload: props.billtoContactname },
    { id: "zipCode", payload: props.zipCode?.postalCode },
  ]

  const [payerData, setPayerData] = useState(initPayerData)

  const setSelected = value => {
    setPayer(value)
  }

  const memoPayerData = useMemo(() => {
    const newPayerData = payerData.map(item =>
      item.id === "name"
        ? {
            id: "name",
            payload:
              payer === "Annen" ? props.invoicecompanyname : workplace?.name,
          }
        : item
    )
    setPayerData(newPayerData)
    return newPayerData
  }, [workplace, payer])

  const mutationPaymentInfo = useMutation<any, any, UpsertPayerType>(
    data => {
      return patchApi(
        process.env.GATSBY_API_URL +
          "/Events/updateMyEventPayment/" +
          props.eventregistrationid,
        data,
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onError: _error => {},
      onSettled: () => {
        queryClient.invalidateQueries("myEvents")
        setEditMode(false)
      },
    }
  )

  const savePayerData = () => {
    if (!paymentObject) return
    mutationPaymentInfo.mutate(paymentObject)
  }

  const updatePaymentObject = () => {
    const newPaymentObject: UpsertPayerType =
      payer === "Privat"
        ? { paymentType: 0 }
        : payer === "Arbeidsgiver"
        ? {
            paymentType: 1,
            billto_accont: workplace?.id,
            customerpurchaseno:
              payerData.find(item => item.id === "invoiceRef1")?.payload || "",
            billto_name:
              payerData.find(item => item.id === "invoiceRef2")?.payload || "",
          }
        : {
            paymentType: 2,
            invoiceaddressline1:
              payerData.find(item => item.id === "invoiceAdr")?.payload || "",
            invoicecompanyname:
              payerData.find(item => item.id === "name")?.payload || "",
            invoiceorganizationno:
              payerData.find(item => item.id === "orgNr")?.payload || "",
            customerpurchaseno:
              payerData.find(item => item.id === "invoiceRef1")?.payload || "",
            billto_name:
              payerData.find(item => item.id === "invoiceRef2")?.payload || "",
            zip: payerData.find(item => item.id === "zipCode")?.payload || "",
          }

    setPaymentObject(newPaymentObject)
  }

  useEffect(() => {
    updatePaymentObject()
  }, [payerData, payer, workplace])

  return (
    <div>
      <h2 className="mt-8 text-2xl font-bold text-meny-700">Betaling:</h2>
      {!editMode && (
        <>
          <div className="w-full h-px bg-gray-200 mb-4"></div>
          <div className="divide-y divide-gray-200 bg-white rounded-lg px-4">
            {data
              .filter(item => item.value !== undefined)
              .map(item => (
                <div
                  key={item.lable}
                  className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
                >
                  <dt className="text-sm font-medium text-gray-900">
                    {item.lable}
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {item.value}
                  </dd>
                </div>
              ))}
          </div>
          {props.canEdit === true && (
            <div className="w-full flex justify-end mt-2">
              <button
                onClick={() => setEditMode(true)}
                className="w-fit ml-1 inline-flex justify-end py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-md text-white bg-meny-700 hover:bg-meny-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
              >
                Endre fakturamottaker
              </button>
            </div>
          )}
        </>
      )}
      {editMode && (
        <>
          <div>
            <div className="my-6">
              <div className="grid grid-cols-1 md:grid-cols-2 my-6">
                <label className="block text-gray-700 mr-4">
                  Hvem skal betale:
                </label>
                <div className="relative z-10">
                  <DropDown
                    options={payerType}
                    setParentValue={setSelected}
                    parentValue={payer}
                  />
                </div>
              </div>
              {payer === "Privat" && (
                <div className="py-8">
                  <p>Du vil motta en faktura på mail før kurset</p>
                </div>
              )}
              {payer === "Arbeidsgiver" && (
                <CompanyPayer
                  payerData={payerData}
                  setPayerData={setPayerData}
                />
              )}
              {payer === "Annen" && (
                <OtherPayer
                  payerData={memoPayerData}
                  setPayerData={setPayerData}
                />
              )}
            </div>
          </div>
          <p className="text-red-600">
            Dette vil endre fakturamottaker på kurs/samlinger som ennå ikke er
            fakturert
          </p>
          <div className="w-full flex justify-end mt-2">
            <Button onClick={() => setEditMode(false)}>
              <div className="w-12">Avbryt</div>
            </Button>
            <Button
              onClick={savePayerData}
              disabled={
                payer === "Arbeidsgiver" &&
                (workplace === undefined || workplace.id === undefined)
              }
            >
              <div className="w-12">Lagre</div>
            </Button>
          </div>
        </>
      )}
    </div>
  )
}

export default PayerSection
