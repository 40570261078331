import * as React from "react"
import { EventCrm } from "../../../mypagecomponents/event/EventType"

const EventDescriptionSection = (props: EventCrm) => {
  return (
    <div>
      <h1 className="mt-8 text-2xl font-bold text-meny-700">{props.name}</h1>
      <div className="w-full h-px bg-gray-200 mb-4"></div>
      <div
        className="divide-y divide-gray-200 bg-white rounded-lg px-4 py-4"
        dangerouslySetInnerHTML={{
          __html: props.calendarContent || "ingen beskrivelse tilgjengelig",
        }}
      ></div>
    </div>
  )
}

export default EventDescriptionSection
