import React from "react"
import NavBarSignedIn from "../navbar/NavBarSignedIn"
import { Helmet } from "react-helmet"

function HomeLayout({ children }) {
  return (
    <div>
      <Helmet title="Min side" />
      <NavBarSignedIn />
      <div>{children}</div>
    </div>
  )
}

export default HomeLayout
