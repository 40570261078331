import { useAccount, useMsal } from "@azure/msal-react"
import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { fetchApi, postApi } from "../../../../Utils/Api.utils"
import ReactDatePicker from "react-datepicker"
import FileUpload from "../../document/FileUpload"
import { navigate } from "gatsby"
import { ErrorMessage } from "@hookform/error-message"
import { classNames } from "../../../common/graphichs/misc"
import { Switch } from "@headlessui/react"
import { toast } from "react-toastify"
import { isSpecialistMethods } from "../../../../Utils/optionSet"
import HelperText from "../../../common/text/HelperText"
import GetHelpText from "../../../common/text/GetHelpText"
import { Activity } from "../../../../Utils/Types"

const GuidanceCertificateEdit = props => {
  const activity: Activity = props.activity
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const [activityComment, setActivityComment] = useState(activity.comment)
  const [startDate, setStartDate] = useState(activity.fromDate)
  const [endDate, setEndDate] = useState(activity.toDate)
  const [selectedSupervisorSpeciality, SetSelectedSupervisorSpeciality] =
    useState(activity.supervisorSpeciality)
  const [isSpecialist, setIsSpecialist] = useState(
    activity.supervisorIsSpecialist.toString()
  )
  const [superviserSpeciality, setSuperviserSpeciality] = useState<any[]>()
  const [hoursDigitalGroupGuideance, setHoursDigitalGroupGuideance] =
    useState<string>(activity.hoursDigitalGroupGuideance.toString())
  const [hoursPhysicalGroupGuideance, setHoursPhysicalGroupGuideance] =
    useState<string>(activity.hoursPhysicalGroupGuideance.toString())
  const [hoursDigitalIdividualGuideance, setHoursDigitalIdividualGuideance] =
    useState<string>(activity.hoursDigitalIdividualGuideance.toString())
  const [hoursPhysicalidIvidualGuideance, setHoursPhysicalidIvidualGuideance] =
    useState<string>(activity.hoursPhysicalidIvidualGuideance.toString())
  const [directObservation, setDirectObservation] = useState(
    activity.directObservation.toString()
  )
  const [sumGuidance, setSumGuidance] = useState("0")
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm()

  useQuery(
    "specialitiesDataActive",
    () =>
      fetchApi(
        process.env.GATSBY_API_URL + "/Specialities/all/active",
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: data => {
        setSuperviserSpeciality(data)
      },
      onError: _error => {},
    }
  )

  const showSpeciality = e => {
    setIsSpecialist(e)
    SetSelectedSupervisorSpeciality("0")
  }

  useEffect(() => {
    const sum =
      parseFloat(hoursDigitalGroupGuideance) +
      parseFloat(hoursPhysicalGroupGuideance) +
      parseFloat(hoursDigitalIdividualGuideance) +
      parseFloat(hoursPhysicalidIvidualGuideance)
    setSumGuidance(sum.toString())
  }, [
    hoursDigitalGroupGuideance,
    hoursPhysicalGroupGuideance,
    hoursDigitalIdividualGuideance,
    hoursPhysicalidIvidualGuideance,
    directObservation,
  ])

  const SumOfGuidance = e => {
    const sum =
      parseFloat(hoursDigitalGroupGuideance) +
      parseFloat(hoursPhysicalGroupGuideance) +
      parseFloat(hoursDigitalIdividualGuideance) +
      parseFloat(hoursPhysicalidIvidualGuideance)
    setSumGuidance(sum.toString())
  }

  const OnChangeSupervisorSpeciality = e => SetSelectedSupervisorSpeciality(e)

  const patchActivity = async (data, e) => {
    data.id = activity.id
    data.fromDate = startDate
    data.toDate = endDate
    data.comment = activityComment
    data.hoursDigitalGroupGuideance = hoursDigitalGroupGuideance
    data.hoursPhysicalGroupGuideance = hoursPhysicalGroupGuideance
    data.hoursDigitalIdividualGuideance = hoursDigitalIdividualGuideance
    data.hoursPhysicalidIvidualGuideance = hoursPhysicalidIvidualGuideance
    data.directObservation = directObservation

    if (selectedSupervisorSpeciality != "0")
      data.supervisorSpeciality = selectedSupervisorSpeciality
    if (selectedSupervisorSpeciality === "0" && isSpecialist === "292460000") {
      toast.warning("Obligatoriske felt mangler!", {
        position: "top-center",
        autoClose: 10000,
      })
      return
    }
    data.supervisorIsSpecialist = isSpecialist

    if (startDate == null || endDate == null) {
      toast.warning("Vennligst velg Fra og Til dato!", {
        position: "top-center",
        autoClose: 10000,
      })

      return
    }

    props.setShowLoadingData(true)
    props.mutationUpdateActivity.mutate(data)
  }

  const OnChangeHoursDigitalGroupGuideance = value => {
    if (value.charAt(0) === "0") value = value.slice(1)
    setHoursDigitalGroupGuideance(value)
    if (value == "") setHoursDigitalGroupGuideance("0")
  }

  const OnChangeHoursPhysicalGroupGuideance = value => {
    if (value.charAt(0) === "0") value = value.slice(1)
    setHoursPhysicalGroupGuideance(value)
    if (value == "") setHoursPhysicalGroupGuideance("0")
  }

  const OnChangeHoursDigitalIdividualGuideance = value => {
    if (value.charAt(0) === "0") value = value.slice(1)
    setHoursDigitalIdividualGuideance(value)
    if (value == "") setHoursDigitalIdividualGuideance("0")
  }

  const OnChangeHoursPhysicalidIvidualGuideance = value => {
    if (value.charAt(0) === "0") value = value.slice(1)
    setHoursPhysicalidIvidualGuideance(value)
    if (value == "") setHoursPhysicalidIvidualGuideance("0")
  }
  const OnChangeDirectObservation = value => {
    if (value.charAt(0) === "0") value = value.slice(1)
    setDirectObservation(value)
    if (value == "") setDirectObservation("0")
  }

  return (
    <div>
      <form method="PATCH" onSubmit={handleSubmit(patchActivity)}>
        <div className="grid grid-cols-6 gap-6">
          <div className="col-span-6 sm:col-span-3">
            <label
              htmlFor="first-name"
              className="block text-sm font-medium text-gray-700"
            >
              Fra dato*
            </label>
            <ReactDatePicker
              id="startDate"
              locale="nb"
              showYearDropdown
              selected={Date.parse(startDate)}
              onChange={(date: Date) => setStartDate(date)}
              dateFormat="dd.MM.yyyy"
              className="block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
            />
          </div>

          <div className="col-span-6 sm:col-span-3">
            <label
              htmlFor="last-name"
              className="block text-sm font-medium text-gray-700"
            >
              Til dato*
            </label>
            <ReactDatePicker
              id="endDate"
              locale="nb"
              showYearDropdown
              selected={Date.parse(endDate)}
              onChange={(date: Date) => setEndDate(date)}
              dateFormat="dd.MM.yyyy"
              className="block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
            />
          </div>

          <div className="col-span-6 sm:col-span-3">
            <label
              htmlFor="supervisorNo"
              className="block text-sm font-medium text-gray-700"
            >
              Veileders HPRno*
            </label>
            <input
              {...register("supervisorNo", {
                required: {
                  value: true,
                  message: "Obligatorisk felt.",
                },
                pattern: {
                  value: /^[0-9]+$/,
                  message: "Kun tall er tillatt",
                },
                minLength: {
                  value: 7,
                  message: "HPR nr kan kun være mellom 7 og 9 siffer",
                },
                maxLength: {
                  value: 9,
                  message: "HPR nr kan kun være mellom 7 og 9 siffer",
                },
              })}
              type="text"
              name="supervisorNo"
              defaultValue={activity.supervisorNo}
              id="supervisorNo"
              className="mt-1 focus:ring-meny-700 focus:border-meny-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            />
            <ErrorMessage
              errors={errors}
              name="supervisorNo"
              render={({ message }) => (
                <p className="text-sm text-red-600">{message}</p>
              )}
            />
          </div>

          <div className="col-span-6 sm:col-span-3">
            <label
              htmlFor="supervisorText"
              className="block text-sm font-medium text-gray-700"
            >
              Veileders navn*
            </label>
            <input
              {...register("supervisorText", {
                required: { value: true, message: "Obligatorisk felt." },
                maxLength: { value: 100, message: "Maks tegn er 100" },
              })}
              type="text"
              name="supervisorText"
              id="supervisorText"
              defaultValue={activity.supervisorText}
              className="mt-1 focus:ring-meny-700 focus:border-meny-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            />
            <ErrorMessage
              errors={errors}
              name="supervisorText"
              render={({ message }) => (
                <p className="text-sm text-red-600">{message}</p>
              )}
            />
          </div>

          <div className="col-span-6 sm:col-span-3">
            <p
              // htmlFor="employerAlternative"
              className="block text-sm italic m-2 font-normal sm:col-span-2 text-meny-700 sm:mt-px sm:pt-2 sm:col-start-3"
            ></p>

            <div>
              <label className="text-base font-medium text-gray-900">
                Er veileder spesialist?
              </label>
              {/* <p className="text-sm leading-5 text-gray-600">Her kommer en hjelpe tekst.</p> */}
              <fieldset className="mt-4">
                <legend className="sr-only">Notification method</legend>
                <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                  {isSpecialistMethods.map(notificationMethod => (
                    <>
                      {notificationMethod.title != "Ingen" ? (
                        <div
                          key={notificationMethod.id}
                          className="flex items-center"
                        >
                          <input
                            id={notificationMethod.id}
                            name="notification-method"
                            type="radio"
                            onChange={e => showSpeciality(e.target.id)}
                            defaultChecked={
                              notificationMethod.id ===
                              activity.supervisorIsSpecialist.toString()
                            }
                            className="focus:ring-meny-700 h-4 w-4 text-indigo-600 border-gray-300"
                          />
                          <label
                            htmlFor={notificationMethod.id}
                            className="ml-3 block text-sm font-medium text-gray-700"
                          >
                            {notificationMethod.title}
                          </label>
                        </div>
                      ) : null}
                    </>
                  ))}
                </div>
              </fieldset>
            </div>
          </div>
          <div className="col-span-6 sm:col-span-3">
            {isSpecialist == "292460000" ? (
              <>
                <label
                  htmlFor="courseProviderAccountId"
                  className="block text-sm font-medium text-gray-700"
                >
                  Veileders spesialitet
                </label>
                <select
                  id="courseProviderAccountId"
                  name="courseProviderAccountId"
                  onChange={e => OnChangeSupervisorSpeciality(e.target.value)}
                  value={selectedSupervisorSpeciality}
                  defaultValue={activity?.supervisorSpeciality}
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-meny-700 focus:border-meny-500 sm:text-sm"
                >
                  <option disabled selected value="0">
                    --velg--
                  </option>

                  {superviserSpeciality?.map(a => (
                    <option key={a.id} value={a.id}>
                      {a.name}
                    </option>
                  ))}
                </select>
              </>
            ) : null}
          </div>

          <div className="col-span-6">
            <GetHelpText number={1001} />
          </div>

          <div className="col-span-6 sm:col-span-3 lg:col-span-2">
            <label
              htmlFor="hoursDigitalGroupGuideance"
              className="block text-sm font-medium text-gray-700"
            >
              Digital gruppeveiledning (t)
            </label>
            <input
              {...register("hoursDigitalGroupGuideance", {
                required: {
                  value: true,
                  message: "Obligatorisk felt.",
                },
                pattern: {
                  value: /^\d{1,}(\.\d{0,4})?$/,
                  message: "Kun desimaltall er tillatt.",
                },
              })}
              type="text"
              value={hoursDigitalGroupGuideance}
              // onClick={e => setHoursDigitalGroupGuideance("0")}

              onChange={e => {
                OnChangeHoursDigitalGroupGuideance(e.target.value),
                  SumOfGuidance(e.target.value)
              }}
              name="hoursDigitalGroupGuideance"
              id="hoursDigitalGroupGuideance"
              className="mt-1 focus:ring-meny-700 focus:border-meny-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            />
            <ErrorMessage
              errors={errors}
              name="hoursDigitalGroupGuideance"
              render={({ message }) => (
                <p className="text-sm text-red-600">{message}</p>
              )}
            />
          </div>

          <div className="col-span-6 sm:col-span-3 lg:col-span-2">
            <label
              htmlFor="hoursPhysicalGroupGuideance"
              className="block text-sm font-medium text-gray-700"
            >
              Fysisk gruppeveiledning (t)
            </label>
            <input
              {...register("hoursPhysicalGroupGuideance", {
                required: {
                  value: true,
                  message: "Obligatorisk felt.",
                },
                pattern: {
                  value: /^\d{1,}(\.\d{0,4})?$/,
                  message: "Kun desimaltall er tillatt.",
                },
              })}
              type="text"
              value={hoursPhysicalGroupGuideance}
              // onClick={e => setHoursPhysicalGroupGuideance("0")}

              onChange={e => {
                OnChangeHoursPhysicalGroupGuideance(e.target.value),
                  SumOfGuidance(e.target.value)
              }}
              name="hoursPhysicalGroupGuideance"
              id="hoursPhysicalGroupGuideance"
              className="mt-1 focus:ring-meny-700 focus:border-meny-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            />
            <ErrorMessage
              errors={errors}
              name="hoursPhysicalGroupGuideance"
              render={({ message }) => (
                <p className="text-sm text-red-600">{message}</p>
              )}
            />
          </div>

          <div className="col-span-6 sm:col-span-3 lg:col-span-2">
            <label
              htmlFor="hoursDigitalIdividualGuideance"
              className="block text-sm font-medium text-gray-700"
            >
              Digital individuell veiledning (t)
            </label>
            <input
              {...register("hoursDigitalIdividualGuideance", {
                required: {
                  value: true,
                  message: "Obligatorisk felt.",
                },
                pattern: {
                  value: /^\d{1,}(\.\d{0,4})?$/,
                  message: "Kun desimaltall er tillatt.",
                },
              })}
              type="text"
              value={hoursDigitalIdividualGuideance}
              // onClick={e => setHoursDigitalIdividualGuideance("0")}
              onChange={e => {
                OnChangeHoursDigitalIdividualGuideance(e.target.value),
                  SumOfGuidance(e.target.value)
              }}
              name="hoursDigitalIdividualGuideance"
              id="hoursDigitalIdividualGuideance"
              className="mt-1 focus:ring-meny-700 focus:border-meny-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            />
            <ErrorMessage
              errors={errors}
              name="hoursDigitalIdividualGuideance"
              render={({ message }) => (
                <p className="text-sm text-red-600">{message}</p>
              )}
            />
          </div>

          <div className="col-span-6 sm:col-span-3 lg:col-span-2">
            <label
              htmlFor="hoursPhysicalidIvidualGuideance"
              className="block text-sm font-medium text-gray-700"
            >
              Fysisk individuell veiledning (t)
            </label>
            <input
              {...register("hoursPhysicalidIvidualGuideance", {
                required: {
                  value: true,
                  message: "Obligatorisk felt.",
                },
                pattern: {
                  value: /^\d{1,}(\.\d{0,4})?$/,
                  message: "Kun desimaltall er tillatt.",
                },
              })}
              type="text"
              value={hoursPhysicalidIvidualGuideance}
              // onClick={e => setHoursPhysicalidIvidualGuideance("0")}
              onChange={e => {
                OnChangeHoursPhysicalidIvidualGuideance(e.target.value),
                  SumOfGuidance(e.target.value)
              }}
              name="hoursPhysicalidIvidualGuideance"
              id="hoursPhysicalidIvidualGuideance"
              className="mt-1 focus:ring-meny-700 focus:border-meny-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            />
            <ErrorMessage
              errors={errors}
              name="hoursPhysicalidIvidualGuideance"
              render={({ message }) => (
                <p className="text-sm text-red-600">{message}</p>
              )}
            />
          </div>

          <div className="col-span-6 sm:col-span-3 lg:col-span-2">
            <label
              htmlFor="directObservation"
              className="block text-sm font-medium text-gray-700"
            >
              Sum veiledning
            </label>
            <input
              type="text"
              readOnly
              value={sumGuidance}
              onChange={e => {
                OnChangeDirectObservation(e.target.value),
                  SumOfGuidance(e.target.value)
              }}
              name="directObservation"
              id="directObservation"
              className="mt-1 bg-gray-300 focus:ring-meny-700 focus:border-meny-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            />
            <ErrorMessage
              errors={errors}
              name="directObservation"
              render={({ message }) => (
                <p className="text-sm text-red-600">{message}</p>
              )}
            />
          </div>

          <div className="col-span-6 sm:col-span-3 lg:col-span-2">
            <label
              htmlFor="directObservation"
              className="block text-sm font-medium text-gray-700"
            >
              Antall direkte observasjon
            </label>
            <input
              {...register("directObservation", {
                // required: {
                //   value: true,
                //   message: "Obligatorisk felt.",
                // },
                pattern: {
                  value: /^\d{1,}(\.\d{0,4})?$/,
                  message: "Kun desimaltall er tillatt.",
                },
              })}
              type="text"
              value={directObservation}
              // onClick={e => setDirectObservation("0")}
              onChange={e => {
                OnChangeDirectObservation(e.target.value)
              }}
              name="directObservation"
              id="directObservation"
              className="mt-1 focus:ring-meny-700 focus:border-meny-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            />
            <ErrorMessage
              errors={errors}
              name="directObservation"
              render={({ message }) => (
                <p className="text-sm text-red-600">{message}</p>
              )}
            />
          </div>

          <div className="col-span-6">
            <label
              htmlFor="comment"
              className="block text-sm font-medium text-gray-700"
            >
              Kommentar
            </label>
            <div className="mt-1">
              <textarea
                id="comment"
                {...register("comment", {
                  required: false,
                  maxLength: {
                    value: 2000,
                    message: "Maks antall tegn er 2000",
                  },
                })}
                onChange={e => {
                  setActivityComment(e.target.value)
                }}
                rows={3}
                className="shadow-sm focus:ring-meny-700 focus:border-meny-500 block w-full sm:text-sm border border-gray-300 rounded-md"
                placeholder="Kommentar"
                defaultValue={activity.comment}
              />
              <ErrorMessage
                errors={errors}
                name="comment"
                render={({ message }) => (
                  <p className="text-sm text-red-600">{message}</p>
                )}
              />
            </div>
            <HelperText helpertext="Kort beskrivelse av aktiviteten." />
          </div>
        </div>

        <div className="mt-8 border-t flex justify-end">
          <button
            type="submit"
            className="ml-3 mt-6 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-meny-600 hover:bg-meny-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
          >
            Oppdater
          </button>
        </div>
      </form>
    </div>
  )
}

export default GuidanceCertificateEdit
